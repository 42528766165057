import React from 'react';
import { useState, useEffect } from "react";
import { ReactComponent as Delete_icon } from '../../images/delete-icon.svg';
import { ReactComponent as Add_icon_plus } from '../../images/Add_icon_plus.svg';
import { postPackageCategoryData } from ".././store/nfr.actions"
import { useMsal } from "@azure/msal-react";
import { useDispatch, useSelector } from "react-redux";
import {

  uploadFile,
  validateFile,
  deleteAction,
} from "../../common/BLOBStorageConfig";
import IconUpload from '../CommanMethods/IconUpload';

function PackageCategoryEntry() {

  const [uploadedBlob, setUploadedBlob] = useState();

  const { instance, accounts } = useMsal();
  const username = accounts[0] && accounts[0].username;
  const dispatch = useDispatch();
  const [parameterslist, setparameterslist] = useState([]);
  const [flag, setFlag] = useState(false)

  //fetching project types
  const ProjectData = useSelector(
    (state) => state.commonReducer.prodtypedata.prodTypeData.prodtypedata);
  const [prodtypedata, setContentData] = useState(ProjectData);
  useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);

  const deleteParameters = (index) => {

    parameterslist.splice(index, 1)
    setFlag(true)

  }

  useEffect(() => {
    setFlag(false)
  }, [flag])





  const handleParameter = (event) => {

    setparameterslist([...parameterslist, document.querySelector("#ParameterName").value])
    setFlag(true)
    document.querySelector("#ParameterName").value = ""

  }



  const handlenfrPost = () => {

    let newparam = []

    let name = document.querySelector("#name").value;
    let desc = document.querySelector("#description").value;
    var sI = document.querySelector('#selectProjectType').selectedIndex;
    var getID = document.querySelector('#selectProjectType').options[sI].value
    newparam = parameterslist
    if (document.querySelector("#ParameterName").value !== "")
      newparam.push(document.querySelector("#ParameterName").value)


    if (name === "" || desc === "" || sI === 0 || uploadedBlob === undefined || newparam?.length === 0 || parameterslist?.length === 0) {
      alert("All Fields are mandatory")
    }
    else {
      var body = {
        "icon": uploadedBlob?.imageURL,
        "title": name,
        "description": desc,
        "projecttypeid": getID,
        "createdby": username,
        "parameters": newparam,
        "isactive": true
      }

      if (instance.getAllAccounts()[0] !== undefined) {
        dispatch(postPackageCategoryData({ data: body, instance: instance })).then((res) => {
          if (res.payload) {
            alert("Package category added successfully")
            window.location = "/packages"
          }

          else
            alert("Form not submitted")
        }
        )
      }
    }
  };
  return (
    <>
      {/* <h1>Package Category Entry</h1> */}
      <div class="file_name_container">
        <p class="m-0 font_18 font_regular">Add Package Category</p>
      </div>
      <div class="file_container">
        <div class="row">
         

         
         <IconUpload uploadedBlob={uploadedBlob} setUploadedBlob={setUploadedBlob}/>
          <div class="col-lg-3">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Package Category Name</label>
              <input type="email" class="form-control" id="name" placeholder="" />
            </div>
          </div>
          <div class="col-lg-3">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Project Type</label>
              <div class="select_box">
                <select class="form-select outlined" aria-label="Default select example" id="selectProjectType">
                  <option selected>Open this select menu</option>
                  {prodtypedata.map((prodtypedata, index) => (
                    <option value={prodtypedata.id} id="projectid">{prodtypedata.title}</option>
                  ))}
                </select>
              </div>
            </div>
          </div>
        </div>
        <div class="row">

          <div class="col-lg-9">
            <div class="mb-3">
              <label for="exampleFormControlInput1" class="form-label">Description</label>
              <textarea class="form-control" id="description" rows="5"></textarea>
            </div>
          </div>
        </div>
        <div class="row align-items-center">

          <>
            <div class="col-lg-4">
              <div class="mb-3">

                <label for={"Parameterlabel"} class="form-label">Parameter </label>
                <input
                  type="text"
                  class="form-control"
                  id="ParameterName"
                  placeholder="Add Parameters"


                  name=""

                />
              </div>
            </div>
            {
              <div class="col-auto" onClick={(event) => { handleParameter(event); }}>
                <div class="Addnewbtn">
                  <Add_icon_plus class="me-2" />Add
                </div>
              </div>

            }

            {
              parameterslist.map((list, index) => (

                <>
                  <div class="col-lg-4">
                    <div class="mb-3">

                      <label for={index - "Parameterlabel"} class="form-label">Parameter </label>
                      <input
                        type="text"
                        class="form-control"
                        id={index - "ParameterName"}
                        placeholder="Add Parameters"

                        value={list}
                        name=""
                        key={index}
                      />
                    </div>
                  </div>


                  {
                    <div class="col-auto" id={index} onClick={() => { deleteParameters(index) }}>
                      <div class="Addnewbtn">
                        <Delete_icon class="me-2" />
                      </div>
                    </div>
                  }
                </>
              ))

            }
          </>

        </div>
        <div class="row mt-4">
          <div class="col-lg-12">
            <button type="button" class="btn btn-outline-secondary btn_custom_secondary" onClick={() => { window.location = "/packages" }}>Cancel</button>
            <button type="button" class="btn btn-primary btn_custom_primary" onClick={handlenfrPost}>Save</button></div>
        </div>
      </div>
    </>
  )
}

export default PackageCategoryEntry

import { createSlice } from "@reduxjs/toolkit";
import { fetchDropAreaData , fetchAllDataSource, fetchDetailDropAreaData} from "./dropArea.actions";

const DropAreaData = createSlice({
    name: "dropareastore",
    initialState: {
        dropAreaData: { dropAreaData: [], loading: true},  
        dropAreaDataDetails: { dropAreaDataDetails: [], loading: true}, 
        datasource:{datasource:[], loading:true}      

    },

    reducers: {
        // setEditLayoutTypeDataName: (state, actions) => {
        //     state.currentlayoutTypeData.layouttype.name = actions.payload;
        // },
    },

    extraReducers: {
        [fetchDropAreaData.pending]: (state) => {
            state.dropAreaData.loading = false;
        },
        [fetchDropAreaData.fulfilled]: (state,actions) => {
            state.dropAreaData.dropAreaData = actions.payload?.dropAreaData;
            state.dropAreaData.loading = actions.payload?.loading;
        },
        [fetchAllDataSource.fulfilled]: (state,actions) => {
            state.datasource.datasource = actions.payload?.datasource;
            state.datasource.loading = actions.payload?.loading;
        },
        [fetchDetailDropAreaData.fulfilled]: (state,actions) => {
            state.dropAreaDataDetails.dropAreaDataDetails = actions.payload?.dropAreaDataDetails;
            state.dropAreaDataDetails.loading = actions.payload?.loading;
        }
    },
});

export default DropAreaData.reducer;
import { createAsyncThunk } from "@reduxjs/toolkit";
import wizardService from "../../services/wizardService";

export const fetchWizardData = createAsyncThunk("wizardstore/fetchWizardData", async(data) => {
    
   //   
    const fetchData = async () => {
        return wizardService.getWizardData(data.data, data.instance) 
    }
    try{
        const rawData = await fetchData();
        const nfrTypeData = [];
        for (const nfrtype of rawData) {
            const cardData = {
                
                id: nfrtype?._id,
                steps: nfrtype?.steps,
               description:nfrtype?.description,
               projecttypeid:nfrtype?.projecttypeid
            };
            nfrTypeData.push(cardData);
        }
        //
        return {
            wizard: nfrTypeData?.length !==0 ? nfrTypeData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }
  }
);


import { X } from 'react-bootstrap-icons';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem } from 'react-pro-sidebar';
import ChatBotMainScreen from './ChatBotMainScreen';
import { NavLink, Link ,Outlet} from 'react-router-dom';

function ChatBotSidebar({dialogues, setDialogue}){
//
    const handleClickMenu = (dialogue) => {
        //
        
        setDialogue(dialogue.dialogueId);
    }

    let menuItem = <></>
    if(dialogues !== undefined){
        menuItem = <> <Menu>
        { 
             dialogues?.map((dialogue, index) => {
                return (
                    <MenuItem key= {index} id={index} >
                    <Link className='navcustomlink' to={`/botStudio/${dialogue.id}`}>
                        {dialogue?.dialoguename}
                    </Link>
                    </MenuItem>
                )
            })
        }
    </Menu></>
    }
    return (
        <>
        <div class="Folderstructure_menu flex-fill">
        <div className='sidebar_main d-flex flex-column h-100'>
        <div class="Plusmenu_right d-flex flex-column h-100">
            <div class="d-flex insertdiv bordercolor mb-3">
                <div className='font_13 tabs font_regular active'>All Dialogue</div>
                <X className='react_icon_small ms-auto' />
            </div>
           
            <div class="d-flex h-100 mt-3 plus_menus explorermaindiv ">
                <ProSidebarProvider>
                    <Sidebar>
                       {menuItem}
                    </Sidebar>
                </ProSidebarProvider>
            </div>
        </div>
        </div>
        </div>
        <Outlet />
       </>
    )
}

export default ChatBotSidebar;

import { createSlice } from "@reduxjs/toolkit";
import { fetchDeploymentData} from "./deployment.actions";
const LayoutTypeData = createSlice({
    name: "deplymentstore",
    initialState: {
        deploymentTypeData: { deploymentdata: [], loading: true},
        currentdeploymentTypeData : {
            deploymenttype: {
                id: "",
                name:"",
            },
        },
        currentdeploymentTypeDataCopy: {
            deploymenttype: {
                id: "",
                name:"",
            },
        },
        inProcessDialog: { props: {open: false, text: ""} },
    },
    reducers: {
        setEditDeploymentTypeDataName: (state, actions) => {
            state.currentdeploymentTypeData.deploymenttype.name = actions.payload;
        },
    },
    extraReducers: {
        [fetchDeploymentData.pending]: (state) => {
            state.deploymentTypeData.loading = false;
        },
        [fetchDeploymentData.fulfilled]: (state,actions) => {
            state.deploymentTypeData.deploymentdata = actions.payload?.deploymentdata;
            state.deploymentTypeData.loading = actions.payload?.loading;
        },        
    },
});
export const { setEditLayoutTypeDataName } = LayoutTypeData.actions;
export default LayoutTypeData.reducer;
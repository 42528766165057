import React from 'react'
import { fetchProductTypeData, fetchProjectVersionData } from "../store/productType.actions";
import { fetchPackagesTypeGridData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { fetchEditThemeData, fetchThemeGridData, putPackageCompThemeData, putPackageThemeData, putThemeData } from "../store/theme.actions";
import Package from '../CommanMethods/PackageMethods';
import IconUpload from '../CommanMethods/IconUpload';


function EditThemes() {
    let myval = 0
    let success = 0
    var res1 = ""
    const [result, setResult] = useState();
    let flag = 1

    var myindex
    const { instance, accounts, inProgress } = useMsal();
    const email = accounts[0] && accounts[0].username;

    const location = useLocation();
    const [getid, setgetid] = useState();
    const [projectType, setprojectType] = useState();
    const [value, setValue] = useState(0);
    const [uploadedBlob, setUploadedBlob] = useState();
    const [after, setAfter] = useState(false)
    const [val, setVal] = useState()
    //
    const [mediaArr, setmediaArr] = useState([]);
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [imageurl, setimageurl] = useState("");
    const [editdata, setEditdata] = useState({});
    const [pkgtype, setpkgtype] = useState();
    const [flandmark, setFlandmark] = useState();
    //
    const dispatch = useDispatch();
    let search = location.search;

    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProductTypeData({ data: "", instance: instance }));
            dispatch(fetchPackagesTypeGridData(instance));
            dispatch(fetchEditThemeData({ search, instance: instance }))
            dispatch(fetchThemeGridData({ dispatch: dispatch, search, instance: instance })).then((res) => {
                //
                setEditdata({
                    themename: res.payload.themegrid[0]?.themename,
                    projecttypename: res.payload.themegrid[0]?.projecttypename,
                    projectversion: res.payload.themegrid[0]?.projectversion,
                    projecttypeid: res.payload.themegrid[0]?.projecttypeid,
                    projectversionid: res.payload.themegrid[0]?.projectversionid,
                    description: res.payload.themegrid[0]?.description,
                    iconpath: res.payload.themegrid[0]?.iconpath,
                    themeid: res.payload.themegrid[0]?.themeid,
                    id: res.payload.themegrid[0]?.id,
                    //filepath:res.payload.themeedit[0]?.filepath
                })
            });
        }
    }, [dispatch, instance.getAllAccounts()[0] !== undefined])


    const editdata1 = useSelector(
        (state) => state.commonReducer.themeedit?.editTheme?.themeedit);

    useEffect(() => {
        setUploadedBlob({
            imageURL: editdata?.iconpath,
            name: editdata?.iconpath?.replace(process.env.REACT_APP_CDN_PATH + "/" + process.env.REACT_APP_BLOB_CONTAINER + "/", "")
        });
    }, [editdata])

   

    const [inputFields, setInputFields] = useState([
        Object.entries(editdata1).map(
            ([key, data]) => {
                return (
                    {
                        _id: data._id,
                        packagecomponentid: data.packagecomponentid,
                        packagetypename: data.typename,
                        packagetypeid: data.typeid,
                        filename: data.filename,
                        filepath: data.filepath,
                        landmark: data.landmark,
                        isafter: data.isafter,
                        codesnippet: data.codesnippet,
                        isreplace: data.isreplace,

                    })
            }
        )
    ])
    
  

    const handleSave = async () => {



        {
            try {
                let themeData = {
                    "_id": editdata.themeid,
                    // "useremail": email,
                    "projecttypeid": editdata.projecttypeid,
                    "iconpath": editdata.iconpath,
                    "title": editdata.themename,
                    "description": editdata.description,

                }

                if (instance.getAllAccounts()[0] !== undefined) {
                    dispatch(putThemeData({ data: themeData, instance: instance })).then((res) => {


                        if (res.payload)
                            success = 1
                        else
                            alert("Form not submitted 11")



                        let packagethemedata = {
                            "_id": editdata.id,
                            "projecttypename": editdata.projecttypename,
                            "projecttypeid": editdata.projecttypeid,
                            "projectversion": editdata.projectversion,
                            "projectversonid": editdata.projectversionid,
                            "themeid": editdata.themeid,
                            "themename": editdata.themename,
                            "createdby": email,
                            "layoutid": null,
                            "layoutname": null,
                        }

                        dispatch(putPackageThemeData({ data: packagethemedata, instance: instance })).then((res) => {


                            if (res.payload)
                                success = 1
                            else
                                alert("Form not submitted")


                            inputFields.flat(1).map((data, index) => {

                                return (dispatch(putPackageCompThemeData({
                                    data: {
                                        _id: editdata1[0]?._id,
                                        packagecomponentid: data.packagecomponentid,
                                        typename: data.packagetypename,
                                        typeid: data.packagetypeid,
                                        filename: data.filename,
                                        filepath: data.filepath,
                                        landmark: data.landmark,
                                        isafter: data.isafter,
                                        codesnippet: data.codesnippet,
                                        isreplace: data.isreplace,
                                    }, instance: instance
                                })
                                ).then((res) => {

                                    if (success == 1) {
                                        if (res.payload) {
                                            alert("Theme edited successfully")
                                            window.location = "/themes"
                                        }
                                        else
                                            alert("Form not submitted1")
                                    }
                                    else
                                        alert("Form not submitted2")

                                })
                                )

                            })
                        })
                    })
                }

            } catch (err) {

            }
        }

    }


    const handlechange1 = () => {
        let name1 = document.querySelector("#fpath").value;
        setEditdata({ ...editdata, themename: name1 });
    }
    const handlechange2 = (event) => {
        let name2 = document.querySelector("#snippet").value
        setEditdata({ ...editdata, description: name2 });
    }




    return (
        <>
            <div class="screen_two">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>Edit Theme</p>
                </div>
                <div class="file_container Addpackage">
                    <div class="row">
                        <IconUpload uploadedBlob={uploadedBlob} setUploadedBlob={setUploadedBlob} />


                        <div class="col-lg-4">
                            <div class=" mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Theme Name</label>
                                <input class="form-control outlined" id="fpath" type="text" value={editdata.themename} onChange={handlechange1} />
                            </div>

                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea class="form-control" id="snippet" rows="3" value={editdata.description} onChange={handlechange2}></textarea>
                        </div>

                    </div>

                    <Package handleSave={handleSave} inputFields={inputFields} setInputFields={setInputFields} editdata1={editdata1}
                        value={value} setValue={setValue} cancelLoc={"/themes"} />
                </div>
            </div>


        </>

    )
}

export default EditThemes

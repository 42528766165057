import { Handle, Position} from 'reactflow';

function ParentCustomNode(props) {
 // 
  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Top} />
            <div className="card bg-secondary text-white" style={{borderRadius: "inherit"}}>
                <div className="card-header">{props.data.label}</div>
                <div className="card-body">
                   <label>{props.data.message}</label>
                </div> 
            </div>
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
}

export default ParentCustomNode;


// CommitCodeModal.js
import React, { useState } from 'react';
import { Modal, Button, Form } from 'react-bootstrap';
import { commitToGitLab } from '../services/gitLabService'; // Ensure this service is implemented

const CommitCodeModal = ({ show, onHide, fileContent, treeURL, getContents, accessToken }) => {
    const [filename, setFilename] = useState('');
    const [filepath, setFilepath] = useState('');

    const handleCommit = async () => {
        try {
            await commitToGitLab(filepath, filename, fileContent, treeURL, accessToken);
            await getContents();
            onHide();
        } catch (error) {
            console.error('Error committing code:', error);
        }
    };

    return (
        <Modal show={show} onHide={onHide}>
            <Modal.Header closeButton>
                <Modal.Title>Commit Code to GitLab</Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <Form.Group controlId="formFileName">
                    <Form.Label>Filename</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the filename"
                        value={filename}
                        onChange={(e) => setFilename(e.target.value)}
                    />
                </Form.Group>
                <Form.Group controlId="formFilePath">
                    <Form.Label>File Path</Form.Label>
                    <Form.Control
                        type="text"
                        placeholder="Enter the file path"
                        value={filepath}
                        onChange={(e) => setFilepath(e.target.value)}
                    />
                </Form.Group>
            </Modal.Body>
            <Modal.Footer>
                <Button variant="secondary" onClick={onHide}>
                    Cancel
                </Button>
                <Button variant="primary" onClick={handleCommit}>
                    Commit
                </Button>
            </Modal.Footer>
        </Modal>
    );
};

export default CommitCodeModal;

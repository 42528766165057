import { createAsyncThunk } from "@reduxjs/toolkit";
import deploymentService from "../../services/deploymentService";
import {Link} from "react-router-dom";
export const fetchDeploymentData = createAsyncThunk("deplymentstore/fetchDeploymentData", async(data) => {
    //
    const fetchData = async () => {
        return deploymentService.getDeploymentData(data.data, data.instance) 
    }
    try{
        const rawData = await fetchData();
        const deploymentData = [];
        for (const deployment of rawData) {
            const cardData = {
                id: deployment?._id,
                title: deployment?.title,
                description: deployment?.description,
                parameters: deployment?.parameters,
                // icon: deployment?.iconpath,
            };
            deploymentData.push(cardData);
        }
        //
        return {
            deploymentdata: deploymentData?.length !==0 ? deploymentData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }
  }
  );
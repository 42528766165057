import React, { useEffect, useState } from 'react';
import axios from "axios";
import { Link } from 'react-router-dom';
import uuid from 'react-uuid';
import { fetchAllDataSource } from '../store/dropArea.actions';


var ResultData,  filteredResults;
var getApiList = []

export function addComponents (props) {
//
//


  const editor = props.editor;
  let modalShow = false;
  let modalData = [];
  let tblHeader, getDatasourcePath, getDatasourceName;
  var putResponse = [], inputArray = [];
  var putDataList = {};
  var EditLink;
  const apiList = [
    { name: 'Posts', link: 'https://jsonplaceholder.typicode.com/posts', source: 'posts' },
    { name: 'Albums', link: 'https://jsonplaceholder.typicode.com/albums', source: 'albums' },
    { name: 'Users', link: 'https://dummyjson.com/todos', source: 'users' },
    { name: 'Photos', link: 'https://jsonplaceholder.typicode.com/photos', source: 'photos' },
    { name: 'Comments', link: 'https://jsonplaceholder.typicode.com/comments', source: 'comments' },
    { name: 'Quotes', link: 'https://dummyjson.com/quotes', source: 'quotes' },
    { name: 'Todos', link: 'https://jsonplaceholder.typicode.com/todos', source: 'todos' }
  ]
  const generateTable = `<div class="tableWrapper">
  <h4>Table Title <span data-gjs-type="tlb-import" style="margin: 0 10px"><svg class="svg-icon" style="width: 1em; height: 1em;vertical-align: middle;fill: currentColor;overflow: hidden;" viewBox="0 0 1024 1024" version="1.1" xmlns="http://www.w3.org/2000/svg"><path d="M554.666667 562.133333l138.368-138.325333 60.330666 60.330667L512 725.504l-241.365333-241.365333 60.330666-60.330667L469.333333 562.133333V85.333333h85.333334v476.8zM170.666667 682.666667h85.333333v170.666666h512v-170.666666h85.333333v170.666666c0 46.933333-38.4 85.333333-85.333333 85.333334H256c-46.933333 0-85.333333-41.088-85.333333-85.333334v-170.666666z" fill="#000000" /></svg></span></h4> 
  <table data-gjs-type="table">
    <tbody>
      <tr>
        <th></th>
        <th></th>
        <th></th>
        <th></th>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      <tr>
        <td></td>
        <td></td>
        <td></td>
        <td></td>
      </tr>
      </tbody>
      </table></div>
      <style>
      .tableWrapper{position:relative;padding:15px;background:#eef5f9;margin-bottom:15px;border-radius:3px;}
      .tableWrapper table {width: 100%; height: 200px;border-collapse: collapse; display:table; border: 1px solid #eee}
      .tableWrapper th {background:#2B3138;border: 1px solid #dddddd;padding:4px;color:#fff;}
      .tableWrapper td {background-color: #f5f5f5;border: 1px solid #dddddd;padding:4px;color:#000}
      </style>`;
// ${props.projectData?.projectname}
  const footer1 = `<div class='footer' style="background-color: #000;color:#fff">
    <div class="col">
      <span>&copy; 2013 Copyright </span>
    </div>
    <div class="col">
    <ul data-gjs-type="socialLinkPopover" style="padding: 0; position: relative">
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="Twitter" data_link="https://twitter.com/" href="https://twitter.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/> </svg>
      </a>
    </li>
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="Facebook" data_link="https://www.facebook.com/" href="https://www.facebook.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
      </a>
    </li>
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="LinkedIn" data_link="https://in.linkedin.com/" href="https://in.linkedin.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
      </a>
    </li>
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="Instagram" data_link="https://www.instagram.com/"  href="https://www.instagram.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/> </svg>
      </a>
    </li>
  </ul>
    </div>
    <div class="col">
      <span>All Rights Reserved</span>
    </div> 
  </div>
  <style>
  .footerWrapper{
    padding: 10px 20px;
    background:#eef5f9;margin-bottom:15px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin:8px;
  }
  .flexitems{
    display:flex;
    align-items:center;
    justify-content:center;list-style:none;
  }
  .flexitems li{
    margin-right:5px;
  }
  .footer .col {
    width: 33%;display: inline-flex;justify-content:center
  }
  .footer .socialLink {
    padding: 5px 9px 5px;
    background-color: #0a0a0a;
    border-radius: 50%;
  }
  .footer .socialLinkList {
    list-style: none;display: inline-flex;margin: 0 5px;
  }
  .footer ul[data-gjs-type='socialLinkPopover'] {
    list-style: none;display: inline-flex;margin: 20px 0px;
  }
  .footer ul li[data-gjs-type='socialLinkList'] {
    display: inline-block;
  }
  </style>`;
  const socialLinks = ['https://twitter.com/','https://www.facebook.com/', 'https://in.linkedin.com/', 'https://www.instagram.com/'];
  const footer2 = `<footer class="footerWrapper">
  <div class="col">
  <div data-gjs-type="text" contenteditable="true" class='title'>Add Title</div>
  <address data-gjs-type="text" contenteditable="true">A1B1 Aaa Street<br/>
  New York, NY 535022<br/>
  United States</address>
  <div data-gjs-type="text" contenteditable="true">Phone: 112 232 2321</div>
  <div data-gjs-type="text" contenteditable="true">Email: project@gmail.com</div>    
  </div>
  <div class="col">
  <div data-gjs-type="text" contenteditable="true" class='title'>Add Title</div>
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Home</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> About Us</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Services</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Award</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Contact Us</div>  
  </div>
  <div class="col">
  <div data-gjs-type="text" contenteditable="true" class='title'>Add Title</div>
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Design</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Development</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Management</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Marketing</div>  
  <div data-gjs-type="text" contenteditable="true" class='menuItem'><svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"><path d="M7.33 24l-2.83-2.829 9.339-9.175-9.339-9.167 2.83-2.829 12.17 11.996z"/></svg> Graphics Design</div>  
  </div>
  <div class="col">
  <div data-gjs-type="text" contenteditable="true" class='title'>Add Title</div>
  <div data-gjs-type="text" contenteditable="true">It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout. The point of using Lorem Ipsum is that it has a more-or-less normal distribution of letters, as opposed to using 'Content here, content here', making it look like readable English</div>
  <div class="col">
  <ul data-gjs-type="socialLinkPopover" style="padding: 0; position: relative">
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="Twitter" data_link="https://twitter.com/" href="https://twitter.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-twitter" viewBox="0 0 16 16"> <path d="M5.026 15c6.038 0 9.341-5.003 9.341-9.334 0-.14 0-.282-.006-.422A6.685 6.685 0 0 0 16 3.542a6.658 6.658 0 0 1-1.889.518 3.301 3.301 0 0 0 1.447-1.817 6.533 6.533 0 0 1-2.087.793A3.286 3.286 0 0 0 7.875 6.03a9.325 9.325 0 0 1-6.767-3.429 3.289 3.289 0 0 0 1.018 4.382A3.323 3.323 0 0 1 .64 6.575v.045a3.288 3.288 0 0 0 2.632 3.218 3.203 3.203 0 0 1-.865.115 3.23 3.23 0 0 1-.614-.057 3.283 3.283 0 0 0 3.067 2.277A6.588 6.588 0 0 1 .78 13.58a6.32 6.32 0 0 1-.78-.045A9.344 9.344 0 0 0 5.026 15z"/> </svg>
      </a>
    </li>
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="Facebook" data_link="https://www.facebook.com/" href="https://www.facebook.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24"><path d="M9 8h-3v4h3v12h5v-12h3.642l.358-4h-4v-1.667c0-.955.192-1.333 1.115-1.333h2.885v-5h-3.808c-3.596 0-5.192 1.583-5.192 4.615v3.385z"/></svg>
      </a>
    </li>
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="LinkedIn" data_link="https://in.linkedin.com/" href="https://in.linkedin.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" viewBox="0 0 24 24"><path d="M4.98 3.5c0 1.381-1.11 2.5-2.48 2.5s-2.48-1.119-2.48-2.5c0-1.38 1.11-2.5 2.48-2.5s2.48 1.12 2.48 2.5zm.02 4.5h-5v16h5v-16zm7.982 0h-4.968v16h4.969v-8.399c0-4.67 6.029-5.052 6.029 0v8.399h4.988v-10.131c0-7.88-8.922-7.593-11.018-3.714v-2.155z"/></svg>
      </a>
    </li>
    <li data-gjs-type="socialLinkList">
      <a class="socialLink" data-gjs-type="socialLink" data-name="Instagram" data_link="https://www.instagram.com/"  href="https://www.instagram.com/">
        <svg xmlns="http://www.w3.org/2000/svg" width="15" height="15" fill="currentColor" class="bi bi-instagram" viewBox="0 0 16 16"> <path d="M8 0C5.829 0 5.556.01 4.703.048 3.85.088 3.269.222 2.76.42a3.917 3.917 0 0 0-1.417.923A3.927 3.927 0 0 0 .42 2.76C.222 3.268.087 3.85.048 4.7.01 5.555 0 5.827 0 8.001c0 2.172.01 2.444.048 3.297.04.852.174 1.433.372 1.942.205.526.478.972.923 1.417.444.445.89.719 1.416.923.51.198 1.09.333 1.942.372C5.555 15.99 5.827 16 8 16s2.444-.01 3.298-.048c.851-.04 1.434-.174 1.943-.372a3.916 3.916 0 0 0 1.416-.923c.445-.445.718-.891.923-1.417.197-.509.332-1.09.372-1.942C15.99 10.445 16 10.173 16 8s-.01-2.445-.048-3.299c-.04-.851-.175-1.433-.372-1.941a3.926 3.926 0 0 0-.923-1.417A3.911 3.911 0 0 0 13.24.42c-.51-.198-1.092-.333-1.943-.372C10.443.01 10.172 0 7.998 0h.003zm-.717 1.442h.718c2.136 0 2.389.007 3.232.046.78.035 1.204.166 1.486.275.373.145.64.319.92.599.28.28.453.546.598.92.11.281.24.705.275 1.485.039.843.047 1.096.047 3.231s-.008 2.389-.047 3.232c-.035.78-.166 1.203-.275 1.485a2.47 2.47 0 0 1-.599.919c-.28.28-.546.453-.92.598-.28.11-.704.24-1.485.276-.843.038-1.096.047-3.232.047s-2.39-.009-3.233-.047c-.78-.036-1.203-.166-1.485-.276a2.478 2.478 0 0 1-.92-.598 2.48 2.48 0 0 1-.6-.92c-.109-.281-.24-.705-.275-1.485-.038-.843-.046-1.096-.046-3.233 0-2.136.008-2.388.046-3.231.036-.78.166-1.204.276-1.486.145-.373.319-.64.599-.92.28-.28.546-.453.92-.598.282-.11.705-.24 1.485-.276.738-.034 1.024-.044 2.515-.045v.002zm4.988 1.328a.96.96 0 1 0 0 1.92.96.96 0 0 0 0-1.92zm-4.27 1.122a4.109 4.109 0 1 0 0 8.217 4.109 4.109 0 0 0 0-8.217zm0 1.441a2.667 2.667 0 1 1 0 5.334 2.667 2.667 0 0 1 0-5.334z"/> </svg>
      </a>
    </li>
  </ul>
</div>  </div></footer>

<style>
.footerWrapper{
  padding: 10px 20px;
}
.footerWrapper .col {
  width: 25%;display: inline-block;justify-content:center;vertical-align: top;
}
.footerWrapper .col div{
  display: block;
  width: 100%;
}
.footerWrapper .col svg{
  width: 10px;
  display: inline-block;
  vertical-align:middle;
  color: #fff;
}
.title {
  font-size: 18px;
  font-weight: 900;
  padding-bottom: 10px;
}
.footerWrapper address {
  padding-bottom: 20px;
}
.footerWrapper .menuItem {
  padding-bottom: 10px;
}
.footerWrapper .socialLink {
  padding: 5px 9px 5px;
  background-color: #0a0a0a;
  border-radius: 50%;
}
.footerWrapper ul[data-gjs-type='socialLinkPopover'] {
  list-style: none;display: inline-flex;margin: 20px 0px;
}
.footerWrapper ul li[data-gjs-type='socialLinkList'] {
  display: inline-block;
}
</style>`;

  const column28 = `<div  class='gjs-row' data-gjs-droppable='.gjs-cell' data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":0,\"bl\":0,\"br\":0,\"minDim\":1}' data-gjs-name='Row'>
  <div  class='gjs-cell' data-gjs-draggable='.gjs-row' data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name='Cell' style='width: 20%;'></div>
  <div  class='gjs-cell' data-gjs-draggable='.gjs-row' data-gjs-resizable='{\"tl\":0,\"tc\":0,\"tr\":0,\"cl\":0,\"cr\":1,\"bl\":0,\"br\":0,\"minDim\":1,\"bc\":0,\"currentUnit\":1,\"step\":0.2}' data-gjs-name='Cell' style='width: 80%;'></div>
  </div>
  <style>
  .gjs-row {display: table;padding: 10px;width: 100%;}@media (max-width: 768px) {.gjs-cell, .gjs-cell20, .gjs-cell80 {  width: 100%;  display: inline-block;}}    .gjs-cell {width: 8%;display: table-cell;height: 75px;vertical-align:top!important;}  
  .gjs-cell button{background: #5151ff;border: 0px;border-radius: 5px;padding: 6px 18px;color: #fff;margin-top:10px;width:100px;}
  .gjs-cell input {border: 1px solid #ccc;padding: 8px;border-radius: 3px;}
  </style>`;
  const searchTable = generateTable;
  const searchdata = `<section class="wrapper_section">
    <div style="display: flex;flex-direction:column;padding: 20px 10px;">
    <h4>Search Filter</h4>
    <input data-gjs-type="input" style="margin-right: 15px" placeholder="Enter text here"></input><button data-gjs-type="SearchButton" type="submit">Search</button></div>
    ${searchTable}
  </section>
  <style>
    .wrapper_section{padding:15px;background:#eef5f9;margin-bottom:15px;}
  </style>
  `;

  const sidenarbar = `<nav class="leftSideBar">
  <div class="logo"><div data-gjs-type="image"></div></div>
  <div data-gjs-highlightable="true" id="i3mej" data-gjs-type="default" draggable="true" data-gjs="navbar-items" class="navbar-items-c"><nav data-gjs-highlightable="true" id="il9r5" data-gjs-type="default" draggable="true" class="navbar-menu"><a data-gjs-highlightable="true" id="i8as5" data-gjs-type="socialLink" class="navbar-menu-link">Home</a><a data-gjs-highlightable="true" id="i4v3k" data-gjs-type="socialLink" class="navbar-menu-link">About</a><a data-gjs-highlightable="true" id="im27y" data-gjs-type="socialLink" class="navbar-menu-link">Contact</a></nav></div>
  </nav>
  <style>
  .logo{
    margin: 20px 0;
    border-bottom: 1px solid rgb(238 238 238 / 28%);
    text-align: center;
  }
  .logo img{
    max-width: 120px;
  }
  .leftSideBar {
    height: calc(100%);
    min-height:100vh
    width: 200px;
    display: inline-block;
    background-color: rgb(238, 245, 249);
    color: #000;    
    padding:10px;
    border-radius:3px;
    margin:0px 8px;
  }
  .leftSideBar .navbar-menu-link {
    display: block;
    text-align: center;
    padding: 10px;    
  }
  </style>`;

  //Form control div
  const generateForm = `<div >
    <label>Select any Datasource</label>
   </div>`

   
  //Div control for figma 
  const generateFigmaDiv = `<div style="width: 15em;display: flex;flex-direction:column;padding:10px;background-color:rgb(238, 245, 249);">Select any Datasource</div>`
  let jQuery;
  editor?.onReady(() => {
    jQuery = document.querySelector('iframe').contentWindow.$;
  })

  //Add Figma div in contol list  class: "fa fa-check-square" 
  
  editor?.BlockManager?.add("Figmadiv", {
    label: "Div",
    attributes: {},
    category: "Advanced",
    activate: 1,
    content: {
      content: generateFigmaDiv,
      type: 'figmalabel'
    },
    media: `<svg id="Group_12380" data-name="Group 12380" xmlns="http://www.w3.org/2000/svg" width="27.723" height="15.492" viewBox="0 0 27.723 15.492">
    <g id="Rectangle_10197" data-name="Rectangle 10197" transform="translate(0 0)" fill="none" stroke="#adadad" stroke-width="1">
      <rect width="27.723" height="15.492" stroke="none"/>
      <rect x="0.5" y="0.5" width="26.723" height="14.492" fill="none"/>
    </g>
    <rect id="Rectangle_11396" data-name="Rectangle 11396" width="27" height="0.815" transform="translate(0.154 7.528)" fill="#adadad"/>
  </svg>
  `

  });
  //Add form control in contol list fa fa-columns
  let FormCom = editor?.BlockManager?.add("div", {
    label: "Form",
    attributes: {},
    category: "Advanced",
    activate: 1,
    content: {
      content: generateForm,
      type: 'testlabel'
    },
    media: `<svg id="Group_12380" data-name="Group 12380" xmlns="http://www.w3.org/2000/svg" width="27.723" height="15.492" viewBox="0 0 27.723 15.492">
    <g id="Rectangle_10197" data-name="Rectangle 10197" transform="translate(0 0)" fill="none" stroke="#adadad" stroke-width="1">
      <rect width="27.723" height="15.492" stroke="none"/>
      <rect x="0.5" y="0.5" width="26.723" height="14.492" fill="none"/>
    </g>
    <rect id="Rectangle_11398" data-name="Rectangle 11398" width="5" height="1" rx="0.5" transform="translate(10.983 11.185)" fill="#adadad"/>
    <g id="Rectangle_11408" data-name="Rectangle 11408" transform="translate(7.13 4.189)" fill="none" stroke="#adadad" stroke-width="0.5">
      <rect width="13" height="2" stroke="none"/>
      <rect x="0.25" y="0.25" width="12.5" height="1.5" fill="none"/>
    </g>
    <g id="Rectangle_11409" data-name="Rectangle 11409" transform="translate(7.13 7.189)" fill="none" stroke="#adadad" stroke-width="0.5">
      <rect width="13" height="2" stroke="none"/>
      <rect x="0.25" y="0.25" width="12.5" height="1.5" fill="none"/>
    </g>
  </svg>
  `
  });

   
  // Block Manager to add control in the right hand side
  editor?.BlockManager?.add("table", {
    label: "Table",
    attributes: {},
    category: "Advanced",
    activate: 1,
    content: generateTable,
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="26" height="15.493" viewBox="0 0 26 15.493">
    <g id="Group_12380" data-name="Group 12380" transform="translate(0 0)">
      <g id="Rectangle_10197" data-name="Rectangle 10197" transform="translate(0 0)" fill="none" stroke="#adadad" stroke-width="1">
        <rect width="26" height="15.492" stroke="none"/>
        <rect x="0.5" y="0.5" width="25" height="14.492" fill="none"/>
      </g>
      <rect id="Rectangle_11396" data-name="Rectangle 11396" width="14.492" height="1" transform="translate(9.246 0) rotate(90)" fill="#adadad"/>
      <rect id="Rectangle_11407" data-name="Rectangle 11407" width="24.492" height="1" transform="translate(0.763 3.746)" fill="#adadad"/>
      <rect id="Rectangle_11403" data-name="Rectangle 11403" width="14.492" height="1" transform="translate(17.772 0) rotate(90)" fill="#adadad"/>
      <rect id="Rectangle_11397" data-name="Rectangle 11397" width="3" height="0.815" transform="translate(2.983 7.185)" fill="#adadad"/>
      <rect id="Rectangle_11402" data-name="Rectangle 11402" width="3" height="0.815" transform="translate(11.509 7.185)" fill="#adadad"/>
      <rect id="Rectangle_11406" data-name="Rectangle 11406" width="3" height="0.815" transform="translate(20.034 7.185)" fill="#adadad"/>
      <rect id="Rectangle_11398" data-name="Rectangle 11398" width="3" height="0.815" transform="translate(2.983 9.185)" fill="#adadad"/>
      <rect id="Rectangle_11401" data-name="Rectangle 11401" width="3" height="0.815" transform="translate(11.509 9.185)" fill="#adadad"/>
      <rect id="Rectangle_11405" data-name="Rectangle 11405" width="3" height="0.815" transform="translate(20.034 9.185)" fill="#adadad"/>
      <rect id="Rectangle_11399" data-name="Rectangle 11399" width="3" height="0.815" transform="translate(2.983 11.185)" fill="#adadad"/>
      <rect id="Rectangle_11400" data-name="Rectangle 11400" width="3" height="0.815" transform="translate(11.509 11.185)" fill="#adadad"/>
      <rect id="Rectangle_11404" data-name="Rectangle 11404" width="3" height="0.815" transform="translate(20.034 11.185)" fill="#adadad"/>
    </g>
  </svg>
  `
  });
  editor?.BlockManager?.add("modal", {
    label: "Modal",
    attributes: { class: "fa fa-window-maximize" },
    category: "Advanced",
    activate: 1,
    content: { type: 'modalButton' },
    select: true,
  });
  editor?.BlockManager?.add("footer1", {
    label: "Small Footer",
    attributes: {},
    category: "Extra",
    content: footer1,
    select: true,
    media: `<svg id="Group_12380" data-name="Group 12380" xmlns="http://www.w3.org/2000/svg" width="27.723" height="15.492" viewBox="0 0 27.723 15.492">
    <g id="Rectangle_10197" data-name="Rectangle 10197" transform="translate(0 0)" fill="none" stroke="#adadad" stroke-width="1">
      <rect width="27.723" height="15.492" stroke="none"/>
      <rect x="0.5" y="0.5" width="26.723" height="14.492" fill="none"/>
    </g>
    <rect id="Rectangle_10198" data-name="Rectangle 10198" width="5" height="0.815" transform="translate(12.154 8.338)" fill="#adadad"/>
    <rect id="Rectangle_11396" data-name="Rectangle 11396" width="10" height="0.815" transform="translate(12.154 10.528)" fill="#adadad"/>
    <circle id="Ellipse_136" data-name="Ellipse 136" cx="2" cy="2" r="2" transform="translate(5.13 8.344)" fill="#adadad"/>
  </svg>`
  });  
  editor?.BlockManager?.add("footer2", {
    label: "Large Footer",
    attributes: {},
    category: "Extra",
    content: footer2,
    select: true,
    media: `<svg id="Group_12380" data-name="Group 12380" xmlns="http://www.w3.org/2000/svg" width="27.723" height="15.492" viewBox="0 0 27.723 15.492">
    <g id="Rectangle_10197" data-name="Rectangle 10197" transform="translate(0 0)" fill="none" stroke="#adadad" stroke-width="1">
      <rect width="27.723" height="15.492" stroke="none"/>
      <rect x="0.5" y="0.5" width="26.723" height="14.492" fill="none"/>
    </g>
    <rect id="Rectangle_10198" data-name="Rectangle 10198" width="5" height="0.815" transform="translate(12.154 8.338)" fill="#adadad"/>
    <rect id="Rectangle_11396" data-name="Rectangle 11396" width="10" height="0.815" transform="translate(12.154 10.528)" fill="#adadad"/>
    <circle id="Ellipse_136" data-name="Ellipse 136" cx="2" cy="2" r="2" transform="translate(5.13 8.344)" fill="#adadad"/>
  </svg>`    
  });   
  editor?.BlockManager?.add("column2/8", {
    label: "Column 2/8",
    attributes: {},
    category: 'Basic',
    content: column28,
    "media": "<svg viewBox=\"0 0 24 24\"><path fill=\"currentColor\" d=\"M2 20h5V4H2v16Zm-1 0V4a1 1 0 0 1 1-1h5a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H2a1 1 0 0 1-1-1ZM10 20h12V4H10v16Zm-1 0V4a1 1 0 0 1 1-1h12a1 1 0 0 1 1 1v16a1 1 0 0 1-1 1H10a1 1 0 0 1-1-1Z\"/></svg>",    
  });
  editor?.BlockManager?.add('search', {
    label: "Search Data",
    attributes: { class: "fa fa-search"},
    category: 'Advanced',
    content: searchdata,    
  })
  editor?.BlockManager?.add('sideNar', {
    label: "Leftside Navbar",
    attributes: {},
    category: 'Extra',
    content: sidenarbar,  
    media: `<svg xmlns="http://www.w3.org/2000/svg" width="27.723" height="15.493" viewBox="0 0 27.723 15.493">
    <g id="Group_12380" data-name="Group 12380" transform="translate(0 0)">
      <g id="Rectangle_10197" data-name="Rectangle 10197" transform="translate(0 0)" fill="none" stroke="#adadad" stroke-width="1">
        <rect width="27.723" height="15.492" stroke="none"/>
        <rect x="0.5" y="0.5" width="26.723" height="14.492" fill="none"/>
      </g>
      <rect id="Rectangle_11396" data-name="Rectangle 11396" width="14.492" height="1" transform="translate(9.246 0) rotate(90)" fill="#adadad"/>
      <rect id="Rectangle_11397" data-name="Rectangle 11397" width="3" height="0.815" transform="translate(2.983 3.185)" fill="#adadad"/>
      <rect id="Rectangle_11398" data-name="Rectangle 11398" width="3" height="0.815" transform="translate(2.983 5.185)" fill="#adadad"/>
      <rect id="Rectangle_11399" data-name="Rectangle 11399" width="3" height="0.815" transform="translate(2.983 7.185)" fill="#adadad"/>
    </g>
  </svg>
  `
  })  
  editor?.DomComponents?.addType('td', {
    isComponent: el => el.tagName === 'TD',
    model: {
      defaults: {
        tagName: 'td',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
  });
  editor?.DomComponents?.addType('th', {
    isComponent: el => el.tagName === 'TH',
    model: {
      defaults: {
        tagName: 'th',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    }
  });
  editor?.DomComponents?.addType('tr', {
    isComponent: el => el.tagName === 'TR',
    model: {
      defaults: {
        tagName: 'tr',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    }
  });
  editor?.Components?.addType('modalButton', {
    isComponent: el => el.tagName === 'BUTTON',
    model: {
      defaults: {
        tagName: 'button',
        attributes: {
          id: 'modalBtn'
        },
        content: 'Click'
      },
    },
    view: {
      events: {
        'click': e => {
          let getBtnId = e.target.getAttribute('id');
          let getData;
          if (modalData.length > 0) {
            getData = modalData.filter((data) => {
              if (data.modalId === getBtnId) return data;
            })
          }
          //
          modalShow = getData[0].modalId === getBtnId ? true : false;
          props.handleModal(getData[0], modalShow, getBtnId);
          let appendModal = `<div class="fade modal-backdrop"></div><div role="dialog" aria-modal="true" class="fade modal" tabindex="-1" aria-labelledby="contained-modal-title-vcenter" style="display: block;"><div modalinfo="${getData[0].title}" id="${getBtnId}" class="modal-dialog modal-lg modal-dialog-centered"><div class="modal-content"><div class="modal-header"><div class="modal-title h4" id="contained-modal-title-vcenter">${getData[0].title}</div><button type="button" class="btn-close" aria-label="Close"></button></div><div class="modal-body">${getData[0].content}</div><div class="modal-footer"><button type="button" class="btn btn-primary">Close</button></div></div></div></div>`;
          props.appendModalData(appendModal);
        }
      },
      onActive() {
        editor?.DomComponents?.addType('title', {
          isComponent: el => el.tagName === 'INPUT',
          model: {
            defaults: {
              tagName: 'input',
              editable: true,
              draggable: false,
              removable: false,
              droppable: false,
              resizable: false,
              copyable: false,
            }
          }
        })
        editor?.DomComponents?.addType('content', {
          isComponent: el => el.tagName === 'TEXTAREA',
          model: {
            defaults: {
              tagName: 'textarea',
              editable: true,
              draggable: false,
              removable: false,
              droppable: false,
              resizable: false,
              copyable: false,
            }
          }
        })
        editor?.DomComponents?.addType('modalInfo', {
          isComponent: el => el.tagName === 'FORM',
          model: {
            defaults: {
              tagName: 'form',
              draggable: false,
              removable: false,
              droppable: false,
              resizable: false,
              copyable: false,
            }
          }
        })
        editor?.DomComponents?.addType('infoButton', {
          isComponent: e => e.tagName === 'BUTTON',
          model: {
            defaults: {
              tagName: 'button',
              draggable: false,
              removable: false,
              droppable: false,
              resizable: false,
              copyable: false,
            }
          },
          view: {
            events: {
              'click': e => {
                let input = {};
                let sibling = editor?.DomComponents?.getWrapper()?.findType('modalButton')
                let getId = sibling[0].attributes.attributes.id;
                input = {
                  modalId: getId,
                  title: e.target.parentNode.children[0].value,
                  content: e.target.parentNode.children[1].value
                }
                modalData.push(input);
                editor?.getSelected()?.parent()?.remove()
              }
            }
          }
        })
        const setModalInfo = `<form id='modal' data-gjs-type='modalInfo' style='width: 400px; box-shadow: 1px 2px 6px 2px rgb(51 51 51 / 57%);border-radius: 5px;'><input id='modalTitle' data-gjs-type="title" type='text' style="width: 100%"/><textarea id='modalContent' style="width: 100%" data-gjs-type="content"></textarea><button type='submit' data-gjs-type='infoButton'>Save</button></form>`;
        editor?.getSelected()?.parent()?.append(setModalInfo, { at: 1 });
      }
    }
  })

  // Adding popover on click on import icon
  editor?.DomComponents?.addType('dataSourcePopOver', {
    isComponent: el => el.tagName === 'UL',
    model: {
      defaults: {
        tagName: 'ul',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    }
  });
  editor?.DomComponents?.addType('dataSourceList', {
    isComponent: el => el.tagName === 'LI',
    model: {
      defaults: {
        tagName: 'li',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
        selectable: false,
      }
    },
    view: {
      events: {
        'click': e => {
          getDatasourcePath = e.target.getAttribute('data-attr');
          getDatasourceName = e.target.getAttribute('data-attr-source');

          axios(getDatasourcePath).then((res) => {
            ResultData = res.data;
            let rowCount = ResultData.length;
            let columnCount;
            let rowData = [];
            let loadTableData = ``;
            let loadTableHead = ``;
            var loadTableRows = ``
            var searchbox = "";
            ResultData.map((user) => {
              tblHeader = Object.keys(user);
              columnCount = tblHeader.length;
              rowData.push(user);
            })
            let columnHead = [];
            tblHeader.map((item) => {
              loadTableHead += `<th>${item}</th>`;
              columnHead.push({ data: item })
            })
            ResultData.length = 5;
            let randomId = uuid().split('-');
            randomId = randomId[randomId.length -1];
            let table = `<table id="jdataTable${randomId}" data-attr-link="${getDatasourcePath}" class="display nowrap" style={{'width':'100%'}}>
            <thead>
            <tr>
              ${loadTableHead}
            </tr>
            </thead>
            </table>`
            editor?.getSelected()?.parent().components(table);
            let jid = '#jdataTable'+randomId;
            let s = jQuery(jid).DataTable({
              ajax: {
                url: getDatasourcePath,
                type: 'GET',
                dataSrc: ''
              },
              columns: columnHead,
              scrollY: 200,
              scrollX: true,
            });

          })
        }
      }
    }
  });

  // click action to import icon
  var Getlist = [];
  //
props.datasource?.map((data)=>{
  //
  if(data.httpobject?.length === 1) {
    //
    Getlist.push(data)
  }
  //
})
 
   var list = Getlist?.filter(x=>x.httpobject[0]?.httpverb?.toLowerCase() === "get").map((item)=>{
      //
    return `<li class="apiLists" data-gjs-type='dataSourceList' data-attr="${item.httpobject[0]?.url}" data-attr-source="${item.httpobject[0]?.httpverb}">${item?.datasourcename}</li>`
    })
  
  editor?.DomComponents.addType('tlb-import', {
    model: {},
    view: {
      events: {
        'click': () => {
          editor?.getSelected().append(`<ul data-gjs-type="dataSourcePopOver">${list.toString().replaceAll(',', '')}</ul>`);   
          editor.Css.setRule('.tableWrapper ul', {
            'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
            'list-style': 'none',
            'font-size': '16px',
            'border-radius': '5px',
            'height': '200px',
            'overflow-y': 'auto',
            'z-index':'99',
          })            
        }
      }
    }
    // run(editor) {
    // }
  })


  // editor?.Commands.add('tlb-import', {
  //   run(editor) {
  //     editor?.getSelected().append(`<ul data-gjs-type="dataSourcePopOver">${list.toString().replaceAll(',', '')}</ul>`);
  //     editor?.Css.setRule('.tableWrapper ul', {
  //       'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
  //       'list-style': 'none',
  //       'font-size': '16px',
  //       'border-radius': '5px',
  //       'height': '200px',
  //       'overflow-y': 'auto',
  //       'z-index':'99',
  //     })      
  //   }    
  // })

  //onClick of search button
  editor?.DomComponents.addType('SearchButton',{
    isComponent: el => el.tagName === 'button',
    model:{
      defaults: {
        tagName: 'button',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      } 
    },
    view:{
      events:{
        'click': e=>{
          
           //?.parent())
           //
          
          

          var tempp = e.target.parentElement
          var inputTypee = tempp.getElementsByTagName('input')
          var arrTag = Array.from(inputTypee)
          //

          var searchInput = arrTag[0].value
          //
         if(searchInput !== ""){
          const filteredData = ResultData.filter((item)=>{
            return Object.values(item).join('').toLowerCase().includes(searchInput.toLowerCase())
          })
          ResultData = filteredData
          //
         }else {
          //
          ResultData = ResultData
         }
         
         let rowCount = ResultData.length;

         let columnCount;
         let rowData = [];
         let loadTableData = ``;
         let loadTableHead = ``;
         ResultData.map((user) => {
           tblHeader = Object.keys(user);
           columnCount = tblHeader.length;
           rowData.push(user);
         })
         //
         for (let j = 0; j < tblHeader.length; j++) {
           loadTableHead += `<th>${tblHeader[j]}</th>`
         }
         for (let i = 0; i < rowCount; i++) {
           loadTableData += `<tr ${i % 2 === 0 ? 'style="background-color: #f5f5f5;padding:4px;color:#000"' : 'style="background-color: #f5f5f5;padding:4px;color:#000"'}>
                     <td style="border: 1px solid #dddddd;">${Object.values(rowData[i])[0]}</td>
                     <td style="border: 1px solid #dddddd;">${Object.values(rowData[i])[1]}</td>
                     <td style="border: 1px solid #dddddd;">${Object.values(rowData[i])[2]}</td>
                     <td style="border: 1px solid #dddddd;">${Object.values(rowData[i])[3]}</td>
                     <td style="border: 1px solid #dddddd;"><button data-gjs-type="RoutePage"  data-idd=${rowData[i].id} >Edit</button></td>
                     </tr>`
         }
         let table = `<table style="width: 100%; height: 200px;border-collapse: collapse; display:inline-block; overflow: auto;border: 1px solid #eee"><tbody>${loadTableHead} ${loadTableData}</tbody></table>`;
         let parentss = editor?.getSelected()?.parent()
          let ParentClas = parentss?.parent()
          let childElem = ParentClas?.getChildAt(1)
          //)
        childElem?.components(table);
        }
      }
    }
  })
  // Route to another page on click of edit
  editor?.DomComponents?.addType('RoutePage', {
    isComponent: el => el.tagName === 'button',
    model: {
      defaults: {
        tagName: 'button',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view: {
      events: {
        'click': e => {
          
          //Create new page
          var id = editor?.Pages.getAll().length;
          editor?.Pages.add({
            id: "Page" + id + ".js"
          })
          //)
          var temp = editor.Pages.getAll();

          let arr = temp?.map((el, index) => {
            var res;
            el.attributes.type === "main" ?
              res = "home.js" : res = el.id
            return res;
          });
          var select = document.getElementById("displayPage")
          var selectOptionn
          //
          select.options.length = 0
          arr.map((element, index) => {
            selectOptionn = select.add(new Option(element, element))
            //

          });
          //)
          var lastelem = editor?.Pages.getAll()[editor?.Pages.getAll().length - 1]
          //
          var NewPageId = lastelem.id
          //

          //)
          var Idd = e.target.getAttribute('data-idd')

          //Switch to newly created page
          editor?.Pages.select(NewPageId);
          document.getElementById('displayPage').value = NewPageId
          EditLink = getDatasourcePath + "/" + Idd

          const res = axios.get(EditLink).then((res) => {
            //
            putResponse.push(res.data)
            //
          }).then((res) => {

            var returnVar = "";
            {
              Object.entries(putResponse[0]).filter(x => !x.includes("id") && !x.includes("Id")).map(([key, value]) => {
                var temp = uuid();
                var obj = { id: temp, key: key, value: value }
                //
                inputArray.push(obj)
                returnVar += `<div class="mb-3 col-lg-12">
                  <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                  <div class="d-flex align-items-center" style="display:flex;flex-direction:row;" id=${key}>
                      <textarea   placeholder=${key} id=${temp} data-name=${key} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px;resize: none;font" name=${key}>${value}</textarea>                        
                  </div>                  
              </div>`
              })
            }
            var mainelem;
            if (returnVar !== undefined) {
              mainelem = `<div >
              ${returnVar}
              
                <button type='button' data-gjs-type="gridEditSubmit" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>
             
                </div>`
            }
            //
            //
            //", editor?.Pages.getAll())
            var pageSelect = editor?.Pages.getAll()
            //  var getPage = pageSelect.filter(x=>{return x.id === "Page1.js"})
            //   

            editor?.getWrapper().components(mainelem)

          })

        }
      }
    }
  });

  editor?.DomComponents?.addType('gridEditSubmit', {
    isComponent: el => el.tagName === 'button',
    model: {
      defaults: {
        tagName: 'button',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view: {
      events: {
        'click': e => {
          
          //
          var tempp = e.target.parentElement
          var tagnamee = tempp.getElementsByTagName('textarea')

          var arrTag = Array.from(tagnamee)
          //

          arrTag && arrTag.length > 0 && arrTag?.map(element => {
            putDataList = {
              ...putDataList,
              //  [editor.DomComponents.getWrapper().find('#'+element.id)[0].attributes.attributes.name]
              [element.name]: element.value
            }
          })
          //

          const res = axios.put(EditLink, {
            body: putDataList
          }).then((res) => {
            //
            if (res.status === 200) {
              alert("Data updated successfully")
            }
          })
        }
      }
    }
  })
  
  editor?.DomComponents?.addType('socialLink', {
    isComponent: e => e.tagName === 'A',
    model: {
      defaults: {
        tagName: 'a',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view: {
      events: {
        'click': e => {
          let getPage = e.target?.getAttribute('data-pg-name');
          //)
          if(getPage){
              let showPage = editor.Pages.get(getPage+".js");
              //
              props?.setCurrentPage(showPage);
              editor.Pages.select(showPage);
              document.getElementById('displayPage').value = getPage+".js"

          }
          //)
          
          let getLink = e.target.getAttribute('data_link');
          let getparent = editor?.getWrapper().findType('socialLinkPopover');
          getparent[0].append(`<div data-gjs-type="backdrop" style="position: fixed;top: 0;bottom: 0;left: 0;right: 0;background-color: rgb(243 243 243 / 18%);z-index: 9999981;"></div><input data-gjs-type="slink" type="text" placeholder="${getLink}" style="z-index: 9999999;position: absolute;background-color: #fff;padding: 10px 20px;top: 40px;">`);
        }
      }
    }
  })
  editor?.DomComponents.addType('backdrop', {
    view: {
      events: {
        'click': e => {
          let findParent = editor?.getWrapper().findType('socialLinkPopover');
          let getChild = findParent[0].getLastChild();
          getChild.remove();
          let b = editor?.getWrapper().findType('backdrop');
          b[0].remove();
        }
      }
    }
  })
  editor?.DomComponents.addType('slink', {
    view: {
      events: {
        'change': e => {
          let index = e.target.getAttribute('placeholder');
          let icons = editor?.getWrapper().findType('socialLinkList');
          icons.map(i => {
            if(i.getChildAt(0).getAttributes().data_link === index) {
              let m = i.getChildAt(0).getAttributes();
              Object.keys(m).map(i => {
                if(i === 'href') m[i] = e.target.value;
              })
              i.getChildAt(0).setAttributes(m);
            }
          })
        }
      }
    }
  })
  // const tablet = editor.Panels.getButton('devices-c', 'set-device-tablet');
  // const mobile = editor.Panels.getButton('devices-c', 'set-device-mobile');
  editor?.Commands.add('set-device-tablet', {
    run (editor) {
      editor?.Devices.select('tablet');
      document.getElementsByClassName('gjs-frame-wrapper')[0].className = 'gjs-frame-wrapper tablet';
    },
    stop (editor) {
      document.getElementsByClassName('gjs-frame-wrapper')[0].className = 'gjs-frame-wrapper';
    }
  }
  )
  editor?.Commands.add('set-device-mobile', {
    run(editor) {
      editor?.Devices.select('mobilePortrait');           
      document.getElementsByClassName('gjs-frame-wrapper')[0].className = 'gjs-frame-wrapper mobile';
    },
    stop (editor) {
      document.getElementsByClassName('gjs-frame-wrapper')[0].className = 'gjs-frame-wrapper';
    }
  })  
  //

}
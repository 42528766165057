import { createSlice } from "@reduxjs/toolkit";
import { fetchGitURL,getProjectNameGitUrl } from "./userProject.actions";
import {getAllProjectsGridData,getAllProjectsGridData1,getProjectDetails,getDownloadZipLink,getAllProjectsNames,fetchProjects5, getAllComponents, fetchAllPageComponents} from '../store/userProject.actions'
const UserProjectData = createSlice({
    name: "userprojectstore",
    initialState: {
        AllProjects:{ allProjects: [], loading: true, allappscol:[]}, //Recent 5 apps 
        AllProjects1:{ allProjects1: [], loading: true, recentappscol:[]}, //AllApps
        ProjectDetails:{ projectdetails: [], loading: true},
        AllProjectsNames:{allProjectsNames: [], loading: true},
        giturl: {giturl:"" ,downloadLink:"",loading: true, length: 0},
        singleProjectName: {singleProjectName: [],loading: true},
        Projects5:{ projects5: [], loading: true, projectscolumnsdata:[]},
        componentData: {allComponents: [], loading: true},
        AllPageCompList:{allPageCompList:[],loadng:true},

        currentUserProjectData : {
            UserProject: {
                id: "",
                name:"",
            },
        },
        currentUserProjectDataCopy: {
            UserProject: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditUserProjectDataName: (state, actions) => {
            state.currentUserProjectData.UserProject.name = actions.payload;
        },
    },

    extraReducers: {
        [getAllProjectsGridData.pending]: (state) => {
            state.AllProjects.loading = false;
        },
        [getAllProjectsGridData.fulfilled]: (state,actions) => {
            state.AllProjects.allProjects = actions.payload?.allProjects;
            state.AllProjects.allappscol = actions.payload?.allappscol;
            state.AllProjects.loading = actions.payload?.loading;
        },
        [getAllProjectsGridData1.pending]: (state) => {
            state.AllProjects1.loading = false;
        },
        [getAllProjectsGridData1.fulfilled]: (state,actions) => {
            state.AllProjects1.allProjects1 = actions.payload?.allProjects1;
            state.AllProjects1.recentappscol = actions.payload?.recentappscol;
            state.AllProjects1.loading = actions.payload?.loading;
        },
            [fetchGitURL.fulfilled]: (state,actions) => {
            state.giturl.giturl = actions.payload?.giturl;
            state.giturl.downloadLink = actions.payload?.dowloadLinkUrl;
            state.giturl.loading = actions.payload?.loading;
          },
          [getProjectNameGitUrl.fulfilled]: (state,actions) => {
            state.singleProjectName.singleProjectName = actions.payload?.singleProjectName;
            state.singleProjectName.loading = actions.payload?.loading;
        },

        [getProjectDetails.pending]: (state) => {
            state.ProjectDetails.loading = false;
        },
        [getProjectDetails.fulfilled]: (state,actions) => {
            //
            state.ProjectDetails.projectdetails = actions.payload?.projectdetails;
            state.ProjectDetails.loading = actions.payload?.loading;
        },

        [getAllProjectsNames.pending]: (state) => {
            state.AllProjectsNames.loading = false;
        },
        [getAllProjectsNames.fulfilled]: (state,actions) => {
            state.AllProjectsNames.allProjectsNames = actions.payload?.allProjectsNames;
            state.AllProjectsNames.loading = actions.payload?.loading;
        },

        //Projects top 5
        [fetchProjects5.pending]: (state) => {
            state.Projects5.loading = false;
        },
        [fetchProjects5.fulfilled]: (state,actions) => {
            state.Projects5.projects5 = actions.payload?.projects5;
            state.Projects5.projects5 = actions.payload?.projectscolumnsdata;
            state.Projects5.loading = actions.payload?.loading;
        },
        [getAllComponents.fulfilled]: (state, actions) => {
            state.componentData.allComponents = actions.payload;
            state.componentData.loading = false;
        },
        [getAllComponents.pending]: (state) => {
            state.componentData.loading = true;
        }   ,
        [fetchAllPageComponents.fulfilled]: (state, actions) => {
            state.componentData.AllPageCompList = actions.payload?.allPageCompList;
            state.componentData.loading = false;
        },
        [fetchAllPageComponents.pending]: (state) => {
            state.componentData.loading = true;
        }        
    },
});

export const { setEditUserProjectDataName } = UserProjectData.actions;
export default UserProjectData.reducer;
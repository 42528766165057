import React from 'react'
import { fetchProductTypeData, fetchProjectVersionData, fetchAllVersions } from "../store/productType.actions";
import { fetchNfrTypeData, fetchPackagesTypeGridData, postNewPackageData, postNewPackageDetailData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import { ReactComponent as DeleteIcon } from '../../images/DeleteIconred.svg';
import { ReactComponent as Add_icon_plus } from '../../images/Add_icon_plus.svg';




function MasterFields({
    inputFields, setInputFields, value, setValue, customDiv, editdata1
}) {
    let myval = 0
    let success = 0
    var res1 = ""
    const [result, setResult] = useState();


    const { instance } = useMsal();

    const [getid, setgetid] = useState();
    const [projectType, setprojectType] = useState();


    const [flag1, setFlag] = useState(false)
    const [pkgtype, setpkgtype] = useState();
    const [isafterinput,setisafterinput]=useState(editdata1?.[0]?.isafter!==undefined?editdata1?.[0]?.isafter:null)
    const [isreplaceinput,setisreplaceinput]=useState(editdata1?.[0]?.isreplace)


    useEffect(()=>{
        setprojectType(editdata1?.[0]?.projecttypename)

        setpkgtype(editdata1?.[0]?.typename)
        setInputFields([
            {
                packagecomponentid:editdata1?.[0]?.packagecomponentid,
                packagetypename: editdata1?.[0]?.typename,
                packagetypeid:editdata1?.[0]?.typeid,
                filename: editdata1?.[0]?.filename,
                filepath: editdata1?.[0]?.filepath,
                landmark: editdata1?.[0]?.landmark,
                isafter: editdata1?.[0]?.isafter,
                codesnippet: editdata1?.[0]?.codesnippet,
                isreplace:editdata1?.[0]?.isreplace,
                // editdata1
            }
        ])
    },[editdata1])
    const dispatch = useDispatch();

    var value1
    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProductTypeData({ data: "", instance: instance }));
            dispatch(fetchAllVersions(instance))
            dispatch(fetchPackagesTypeGridData(instance));
            setprojectType(projectType)
        }
    }, [dispatch, projectType, instance.getAllAccounts()[0] !== undefined])

    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProjectVersionData({ data: getid, instance: instance }));
            dispatch(fetchNfrTypeData({ data: getid, instance: instance }))
        }

    }, [projectType, getid, instance.getAllAccounts()[0] !== undefined])

    // all versions

    const allVersionsContent = useSelector((state) => state.commonReducer.allversion.AllVersionData.allversion);
    const [allversion, setallversion] = useState(allVersionsContent);
    useEffect(() => { setallversion(allVersionsContent) }, [dispatch, allVersionsContent])


    //fetching project types
    const ProjectData = useSelector(
        (state) => state.commonReducer.prodtypedata.prodTypeData.prodtypedata);
    const [prodtypedata, setContentData] = useState(ProjectData);
    useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);

    //fetching project versions
    const version = useSelector(
        (state) => state.commonReducer.prodtypedata.projVersionData.projversiondata);
    const [projversiondata, setprojversiondata] = useState(version);
    useEffect(() => { setprojversiondata(version); }, [dispatch, version]);



    //NFR
    const nfrcontent = useSelector(
        (state) => state.commonReducer.nfr.nfrTypeData.nfrdata);
    const [nfrdata, setnfrData] = useState(nfrcontent);
    useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);


    const deleteParameters = (e) => {

        inputFields.splice(e.currentTarget.id, 1)
        setFlag(true)

    }
    useEffect(() => {

        setFlag(false)

    }, [flag1])

    const handleAdd = () => {


        setInputFields([
            ...inputFields,
            {
                packagecomponentid: "",
                packagetypename: "",
                packagetypeid: "",
                filename: "",
                filepath: "",
                landmark: "",
                isafter: false,
                codesnippet: "",
                isreplace: false,
            }
        ])
    }

    const handleChange = (event, index) => {

        var tI = document.querySelector('#selectType').selectedIndex;
        var typeid = document.querySelector('#selectType').options[tI].value
        var typetxt = document.querySelector('#selectType').options[tI].label
        setpkgtype(typetxt)

        const values = [...inputFields]

        values[index][event.target.name] = event.target.value;
        if (event.target.name === "packagetypename")
            values[index]["packagetypeid"] = packagetypeshow?.filter(x => x.name === event.target.value)[0]?.id

        if (event.target.name === "isafter")
            values[index]["isafter"] = event.target.checked
        if (event.target.name === "isreplace")
            values[index]["isreplace"] = event.target.checked



        setInputFields(values)

        if(document.querySelector("#isafter")?.checked!==null)
        setisafterinput(document.querySelector("#isafter")?.checked)
        if(document.querySelector("#isreplace")?.checked!==null)
        setisreplaceinput(document.querySelector("#isreplace")?.checked)

    }
    //For fetching versions as per project type
    const addOptionID = async (e) => {


        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var sprojType = document.querySelector('#selectProjectType').options[sI].label

        setprojectType(sprojType)

        var getID = document.querySelector('#selectProjectType').options[sI].value
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProjectVersionData({ data: getID, instance: instance }))
        }

        setgetid(getID)

    }




    //fetching package grid data
    const packagetype = useSelector((state) => state.commonReducer.nfr.packageTypeGrid.typegrid);
    const [packagetypeshow, setpackagetypeshow] = useState(packagetype);
    useEffect(() => { setpackagetypeshow(packagetype) }, [dispatch, packagetype])


    const addOptionID1 = async (e) => {

        setValue(1)
        myval = 1


    }


    return (
        <>

            <div class="row">
                <div class="col-lg-4 text-start">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Project Type</label>
                        <div class="select_box">

                            <select id="selectProjectType" onChange={() => {
                                addOptionID();
                                if (instance.getAllAccounts()[0] !== undefined) {
                                    dispatch(fetchProjectVersionData({ data: getid, instance: instance }));
                                    dispatch(fetchNfrTypeData({ data: getid, instance: instance }))
                                }
                            }}
                                class="form-select outlined" aria-label="Default select example">
                                  
                              
                                {prodtypedata?.map((prodtypedata, index) => (
                                    <option value={prodtypedata?.id} id="projectid" selected={editdata1?.[0]?.projecttypeid===prodtypedata?.id}>{prodtypedata?.title}</option>
                                ))}
                            </select>

                        </div>
                    </div>
                </div>
                <div class="col-lg-4 text-start">
                    <div class="mb-3">
                        <label for="exampleFormControlInput1" class="form-label">Project version</label>
                        <div class="select_box">
                            <select id="selectVersion" onChange={addOptionID1} class="form-select outlined" aria-label="Default select example">
                            <option >Open this select menu</option>:

                                                       {allversion?.filter(x => x.projecttype?.includes(projectType)).map((prodtypedata, index) => (
                                    <option selected={editdata1?.[0]?.projectversionid===prodtypedata?.id} value={prodtypedata?.id}>{prodtypedata?.version}</option>
                                ))}
                            </select>
                        </div>
                    </div>
                </div>
                {customDiv ? customDiv : <></>}


            </div>
            {inputFields.map((item, index) => {

                return (
                    <div class="row mt-3 Addnewdiv">
                        <div class="col-lg-12">
                            <div class="bg_grey_container">
                                <div class="row">
                                    <div class="col-lg-4">
                                        <div class=" mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">Package Type</label>

                                            <div class="select_box">
                                                <select class="form-select outlined" aria-label="Default select example"
                                                    id="selectType"
                                                    onChange={(event) => { handleChange(event, index) }}
                                                    value={item.packagetypename}

                                                    // value={editdata1===undefined?item.packagetypename:editdata1[0]?.typeid}
                                                    name="packagetypename"
                                                    key={index}
                                                >
                                                                                            <option >Open this select menu</option>:

                                                   
                                                    {packagetypeshow.map((packagetypeshow, index1) => (

                                                        <option value={packagetypeshow.name}
                                                        //  selected={editdata1?.[0]?.typename===packagetypeshow.name} 
                                                         id="typeid" >{packagetypeshow.name}</option>

                                                    ))}
                                                </select>


                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class=" mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">File Name</label>
                                            <input class="form-control outlined"
                                                id={index - "fname"}
                                                placeholder="Enter file name"
                                                onChange={(event) => { handleChange(event, index); }}
                                                value={item.filename}
                                                name="filename"
                                                key={index}
                                            />
                                        </div>
                                    </div>
                                    <div class="col-lg-4">
                                        <div class=" mb-3">
                                            <label for="exampleFormControlInput1" class="form-label">File Path</label>
                                            <input class="form-control outlined"
                                                id={index - "fpath"}
                                                placeholder="Enter Path"
                                                onChange={(event) => { handleChange(event, index); }}
                                                value={item.filepath}
                                                name="filepath"
                                                key={index}
                                            />
                                        </div>
                                    </div>
                                    {
                                        packagetypeshow.map((packagetypeshow, index1) => {

                                            // 
                                            return (
                                                <>
                                                    {packagetypeshow?.name === pkgtype && packagetypeshow?.dynamicfields?.length === 3 ?

                                                        <><div class="col-lg-4">
                                                            <div class=" mb-3">
                                                                <label for="exampleFormControlInput1" class="form-label">Landmark</label>
                                                                <input class="form-control outlined"
                                                                    id={index - "landmark"}
                                                                    placeholder="Enter landmark"
                                                                    onChange={(event) => { handleChange(event, index); }}
                                                                    value={item.landmark}
                                                                    name="landmark"
                                                                    key={index}
                                                                />
                                                            </div>
                                                        </div>
                                                            <div class="col-auto">
                                                                <div class="mb-3">
                                                                    <label for="exampleFormControlInput1" class="form-label"></label>
                                                                    <div class="form-check pt-3">
                                                                        <input class="form-check-input"
                                                                            type="checkbox"
                                                                            id="isafter"
                                                                            onChange={(event) => handleChange(event, index)}
                                                                            //value={item.isafter}
                                                                            checked={item.isafter}

                                                                            name="isafter"
                                                                            key={index}
                                                                        />
                                                                        <label class="form-check-label" for="flexCheckDefault">
                                                                            Is Afterr
                                                                        </label>
                                                                    </div>
                                                                    <div class="form-check pt-3">
                                                                        <input class="form-check-input"
                                                                            type="checkbox"
                                                                            id="isreplace"
                                                                            onChange={(event) => handleChange(event, index)}
                                                                            //value={item.isafter}
                                                                            checked={item.isreplace}


                                                                            name="isreplace"
                                                                            key={index}
                                                                        />
                                                                        <label class="form-check-label" for="flexCheckDefault">
                                                                            Is Replace
                                                                        </label>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </> : packagetypeshow?.name === pkgtype && packagetypeshow?.dynamicfields?.length === 2 ?
                                                            <><div class="col-lg-4">
                                                                <div class=" mb-3">
                                                                    <label for="exampleFormControlInput1" class="form-label">Landmark</label>
                                                                    <input class="form-control outlined"
                                                                        id={index - "landmark"}
                                                                        placeholder="Enter landmark"
                                                                        onChange={(event) => { handleChange(event, index); }}
                                                                        value={item.landmark}
                                                                        name="landmark"
                                                                        key={index}
                                                                    />
                                                                </div>
                                                            </div>
                                                                <div class="col-auto">
                                                                    <div class="mb-3">
                                                                        <label for="exampleFormControlInput1" class="form-label"></label>
                                                                        <div class="form-check pt-3">
                                                                            <input class="form-check-input"
                                                                                type="checkbox"
                                                                                id="isafter"
                                                                                onChange={(event) => handleChange(event, index)}
                                                                                //value={item.isafter}
                                                                                checked={item.isafter}

                                                                                name="isafter"
                                                                                key={index}
                                                                            />
                                                                            <label class="form-check-label" for="flexCheckDefault">
                                                                                Is After
                                                                            </label>
                                                                        </div>
                                                                    </div></div></> : <></>

                                                    }
                                                </>
                                            )

                                        })
                                    }


                                    <div class="col-12">
                                        <div class="mb-3">
                                            <label for="exampleFormControlTextarea1" class="form-label">Code Snippet</label>
                                            <textarea class="form-control"
                                                rows="3"
                                                id={index - "snippet"}
                                                placeholder="Enter Code snippet"
                                                onChange={(event) => handleChange(event, index)}
                                                value={item.codesnippet}
                                                name="codesnippet"
                                                key={index}
                                            ></textarea>
                                        </div>
                                    </div>

                                   

                                    { editdata1 === undefined && (inputFields.length - 1) === index &&
                                        <div class="col-12 my-3" onClick={() => {

                                            handleAdd(result);
                                        }}>
                                            <span class="cursor-pointer"><Add_icon_plus class="me-2" />Add</span>
                                        </div>
                                    }
                                    { editdata1 === undefined && (inputFields.length - 1) !== index &&
                                        <div class="col-12 my-3" onClick={deleteParameters} >
                                            <span class="cursor-pointer"><DeleteIcon class="me-2" />Remove</span>
                                        </div>
                                    }
                                </div>

                            </div>
                        </div>
                    </div>
                )

            })}



        </>

    )
}

export default MasterFields

import { LogLevel } from "@azure/msal-browser";
// 
export const msalConfig = {
  auth: {
    clientId: process.env.REACT_APP_CLIENT_ID,
    authority: process.env.REACT_APP_AUTHORITY,
    redirectUri: window.location.origin,
    postLogoutRedirectUri: window.location.origin,
    // knownAuthorities: [process.env.REACT_APP_AUTHORITY_DOMAIN],
    navigateToLoginRequestUrl: false,
  },
  cache: {
    cacheLocation: 'localStorage', // Configures cache location. "sessionStorage" is more secure, but "localStorage" gives you SSO between tabs.
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge  
  },
  system: {
    allowRedirectInIframe: true,
    loggerOptions: {
      loggerCallback: (level, message, containesPII) => {
        if (containesPII) {
          return;
        }
        switch (level) {
          case LogLevel.Error:
           // console.error(message);
            return;
          case LogLevel.Info:
           // console.info(message);
            return;
          case LogLevel.Verbose:
            //console.debug(message);
            return;
          case LogLevel.Warning:
           // console.warn(message);
            return;
        }
      },
      logLevel: "Info",
    }
  }
};


export const loginRequest = {
 scopes:  [process.env.REACT_APP_API_CONFIGS_SCOPE]
};


export const graphConfig = {
    graphMeEndpoint: "https://graph.microsoft.com"
};
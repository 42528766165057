import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });

class DeploymentService extends RequestHandler {
    //fetch all product type data
    async getDeploymentData(data, instance) {
        // 
        var res
        //const res = await client.get("/listdeployment?projecttype="+data);
        if(data===""||data===undefined||data==="undefined")
        {
         res = await this.requestHandler(instance).get("/deployment");
        }
        else
        {
         res = await this.requestHandler(instance).get("/deployment?projecttype="+data);
        }
        //
        return res.data
    }
}
export default new DeploymentService;
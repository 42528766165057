import React, { useEffect, useState } from 'react';
import {useDispatch,useSelector} from "react-redux";
import { putpackcategoryData, fetchPackagesComponentData } from '../../store/nfr.actions';
import { fetchProductTypeData} from "../../store/productType.actions";
import { useLocation } from "react-router-dom";
import {  useMsal } from "@azure/msal-react";
import { ReactComponent as Delete_icon } from '../../../images/delete-icon.svg';
import { ReactComponent as Add_icon_plus } from '../../../images/Add_icon_plus.svg';
import IconUpload from '../../CommanMethods/IconUpload';


function EditPackageCategory() {

    const dispatch = useDispatch();
    const location = useLocation();
    var uid=location.search.replace("?id=","")
    const [flag,setFlag]= useState(false)
    const {instance} = useMsal();

    //UseEffect to dispatch action methods  
    useEffect(()=>{    
      if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchPackagesComponentData({instance:instance,dispatch:dispatch}));
        dispatch(fetchProductTypeData({data: "", instance: instance}))
      }
    },[dispatch,uid, instance.getAllAccounts()[0] !== undefined])

    const ProjectData = useSelector(
        (state) => state.commonReducer.prodtypedata.prodTypeData.prodtypedata);
    const [prodtypedata, setContentData] = useState(ProjectData);
    useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);
    

    const maincontent = useSelector((state)=>state.commonReducer?.nfr?.gridComponent?.packagecomponent);
    const [editpackagecategory, seteditpackagecategory] = useState(maincontent);
    useEffect(()=>{seteditpackagecategory(maincontent.filter(x=>x.id?.includes(uid) ))},[dispatch,maincontent,uid])
    

   
    const [imageinput,setimageinput]=useState({imageURL:editpackagecategory[0]?.icon, 
      name:editpackagecategory[0]?.icon.replace(process.env.REACT_APP_CDN_PATH+"/"+process.env.REACT_APP_BLOB_CONTAINER+"/","") })
    const [packagecategoryinput,setpackagecategoryinput]=useState(editpackagecategory[0]?.title)
    const [projecttypeinput,setprojecttypeinput]=useState(editpackagecategory[0]?.projecttypeid)
    const [descriptioninput,setdescriptioninput]=useState(editpackagecategory[0]?.description)
    const [paramtersinput,setparamtersinput]=useState(editpackagecategory[0]?.parameters)
    let [parameterslist, setparameterslist] = useState(paramtersinput);
    
    useEffect (()=>{
        setimageinput({imageURL:editpackagecategory[0]?.icon, 
          name:editpackagecategory[0]?.icon.replace(process.env.REACT_APP_CDN_PATH+"/"+process.env.REACT_APP_BLOB_CONTAINER+"/","") });
        setpackagecategoryinput(editpackagecategory[0]?.title);
        setprojecttypeinput(editpackagecategory[0]?.projecttypeid);
        setdescriptioninput(editpackagecategory[0]?.description);
        setparamtersinput(editpackagecategory[0]?.parameters);
        setparameterslist(paramtersinput)
    },[
        editpackagecategory[0]?.icon,
        editpackagecategory[0]?.title,
        editpackagecategory[0]?.projecttypeid,
        editpackagecategory[0]?.description,
        editpackagecategory[0]?.parameters,
        paramtersinput
    ])
    

    
       const handleChange = () => {
        let name = document.querySelector("#name").value;
        let desc = document.querySelector("#description").value;
        setpackagecategoryinput(name)
        setdescriptioninput(desc)
        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var getID = document.querySelector('#selectProjectType').options[sI].value
        setprojecttypeinput(getID)

        
       }
       const handleParameter = () => {
            
        setparameterslist([...parameterslist, document.querySelector("#ParameterName").value])
        setFlag(true)
        document.querySelector("#ParameterName").value=""
        
      }

      const handlenfrEdit = () => {
        
        let newparam=[]
        let name = document.querySelector("#name").value;
        let desc = document.querySelector("#description").value;
        var sI = document.querySelector('#selectProjectType').selectedIndex;
        newparam= document.querySelector("#ParameterName").value!==""?newparam.push(document.querySelector("#ParameterName").value):parameterslist
        newparam=parameterslist
        if(document.querySelector("#ParameterName").value!=="")
          newparam.push(document.querySelector("#ParameterName").value)
        
      
        {
          var body = {
            "_id": uid,
            "title": name,

            "icon": imageinput,
            "packageComponentName": packagecategoryinput,
            "description": desc,
            "projecttypeid":projecttypeinput ,
           "parameters": newparam
          }
          
          dispatch(putpackcategoryData(body)).then((res) => {
            if (res.payload)
            {
              alert("Package category updated successfully")
              window.location = "/packages"
            }
              
            else
              alert("Form not submitted")
          }
          )
        }
      };

      const deleteParameters =(index)=>
      {
        
        setparameterslist(parameterslist.filter(x=>x!=parameterslist[index]))
        setFlag(true)
        
      }
      useEffect(()=>{
       
        setFlag(false)
        
      },[flag,imageinput])

  return (
    <>
    <div class="file_name_container">
      <p class="m-0 font_18 font_regular">Edit Package Category</p>
    </div>
    <div class="file_container">
      <div class="row">
        <IconUpload
        uploadedBlob={imageinput}
        setUploadedBlob={setimageinput}
        />
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Package Category Name</label>
            <input  class="form-control" id="name" value={packagecategoryinput} onChange={handleChange} />
          </div>
        </div>
        <div class="col-lg-3">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Project Type</label>
            <div class="select_box">
            <select class="form-select outlined" aria-label="Default select example" id="selectProjectType" onChange={handleChange}>
            {prodtypedata?.map((prodtypedata, index) => {
                return(
                    <>
                    {  prodtypedata?.id===projecttypeinput? <> 
                    <option selected value={projecttypeinput}>{prodtypedata?.title}</option> </> :""
                    }
                    { prodtypedata?.id!==projecttypeinput? <>  
                     <option value={prodtypedata?.id} id="projectid">{prodtypedata?.title}</option></>:""
                    }                                     
                    </>
                    )
                })}
            </select>
            </div>
          </div>
        </div>
      </div>
      <div class="row">

        <div class="col-lg-9">
          <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Description</label>
            <textarea class="form-control" id="description" rows="5" value={descriptioninput} onChange={handleChange}></textarea>
          </div>
        </div>
      </div>
      <div class="row align-items-center">
             
        <>
        <div class="col-lg-4">
                <div class="mb-3">

                  <label for={"Parameterlabel"} class="form-label">Parameter </label>
                  <input
                    type="text"
                    class="form-control"
                    id="ParameterName"
                    placeholder="Add Parameters"
                    
                    
                    name=""
                   
                  />
                </div>
              </div>
              {
                <div class="col-auto"  onClick={(event) =>{ 
                    handleParameter(event);
                    }}>
                  <div class="Addnewbtn">
                    <Add_icon_plus class="me-2" />Add
                  </div>
                </div>
                
              }
             
          {
          parameterslist?.map((list, index) => (
            
            <>
              <div class="col-lg-4">
                <div class="mb-3">

                  <label for={index - "Parameterlabel"} class="form-label">Parameter </label>
                  <input
                    type="text"
                    class="form-control"
                    id={index+"ParameterName"}
                    placeholder="Add Parameters"
                    onChange={handleChange}
                    value={list}
                    name=""
                    key={index}
                  />
                </div>
              </div>
             
             
              { 
                <div class="col-auto" id={index} onClick={()=>{
                    deleteParameters(index)
                }}>
                  <div class="Addnewbtn">
                    <Delete_icon class="me-2"  />
                  </div>
                </div>
              }
            </>
          ))
      
        }
        </>

      </div>
      <div class="row mt-4">
        <div class="col-lg-12">
          <button type="button" class="btn btn-outline-secondary btn_custom_secondary" onClick={()=>{window.location = "/packages"}}>Cancel</button>
          <button type="button" class="btn btn-primary btn_custom_primary" onClick={handlenfrEdit}>Save</button></div>
      </div>
    </div>
  </>
  )
}

export default EditPackageCategory

import React, { memo, useEffect, useState } from 'react';
import { FaCode, FaRegCalendarAlt, FaRocketchat, FaFile } from 'react-icons/fa';
import { X } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import { Form, InputGroup, Row } from 'react-bootstrap';
import ModalDialog from 'react-bootstrap/ModalDialog'
import { ReactComponent as Cloud_icon } from './images/cloud_upload_icon.svg';
import { ReactComponent as File_menu } from './images/file_menu.svg';
import { ReactComponent as Plus_menu } from './images/plus_menu.svg';
import { ReactComponent as Api_menu } from './images/api_menu.svg';
import { ReactComponent as Sourcecode_menu } from './images/sourcedode_menu.svg';
import { ReactComponent as Message_menu } from './images/message_menu.svg';
import { ReactComponent as Search_icon } from './images/search_icon.svg';
import { ReactComponent as File_small_icon } from './images/file_small_icon.svg';
import { ReactComponent as Three_dots } from './images/three_dots.svg';
import { ReactComponent as Edit_icon } from './images/edit_icon.svg';
import { ReactComponent as Home_menu } from './images/home_icon.svg';
import { ReactComponent as Project_menu } from './images/projects_icon.svg';
import { ReactComponent as GreenPlus_icon } from './images/greenplus_icon.svg';
import { ReactComponent as Play_icon } from './images/play_icon.svg';
import { ReactComponent as Green_tick } from './images/green_tick_progress.svg';
import { ReactComponent as AddPlus } from './images/Add_icon_plus.svg';
import Dotnet_icon from './images/dotnet_icon.png';
import React_icon from './images/react_icon.png';
import React_android from './images/react_andriod.png';
import React_ios from './images/react_ios.png';
import Ai_bot from './images/Ai_bot.png';
import Ai_botdotnet from './images/Ai_botdotnet.png';
import { Search, Plus, XLg } from 'react-bootstrap-icons';
import Pagination from 'react-bootstrap/Pagination';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { useDispatch, useSelector } from "react-redux";
import { fetchProductTypeData, fetchProjectRepoData, fetchProjectVersionData } from "./main/store/productType.actions";
import { fetchNfrTypeData } from "./main/store/nfr.actions";
import { fetchThemeData } from "./main/store/theme.actions";
import { fetchLayoutData } from "./main/store/layout.actions"
import PackageGrid from './PackageGrid';
import RecentappsGrid from './RecentappsGrid';
import { postUserProjectData, putUserProjectData, postUserProjectidData } from "./main/store/userProject.actions"
import { Link } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import Loader from './images/loader.gif';
import Accordion from 'react-bootstrap/Accordion';
import { useNavigate, useLocation } from "react-router-dom";
import UserProjectDialogueForm from "./main/home/UserProjectDialogueForm";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

var firststepstatus;
let count = 0;
var setTitle = []
let vac = 0;
let vacdep= 0;
let set1 = 0;
let setdep1=0;
let sizeset = 0
let CrossSymbol,CrossSymboltheme, CrossSymbolnfr, CrossSymbollayout, search;;
function UserProjectEntryPopup(
    { handleClose1,
        projectType,
        show1,
        setprojectType,
        handleClose,
        setCurrent,
        reset,
        figmafile,
        figmaToken,
        setfigmafile,
        setfigmatoken,
        modalsize,
        current,
        service,
        setService,
        projectTypeDisplay,
        Spinner,
        prodtypedata,
        setproductid,
        setgetid,
        hiddenval,
        addVersionID,
        projversiondata,
        addOptionID,
        nfrcount,
        selected,
        setSelected,
        selectedid,
        setSelectedid,
        themecount,
        setthemeid,
        themedata,
        setactivetheme,
        setmodalsize,
        projrepodata,
        nfrdata,
        activetheme,
        layoutcount,
        setactivelayout,
        activelayout,
        setlayoutid,
        completed,
        percentage,
        handlePrevious,
        layoutdata,
        handleSlide,
        val,
        setMenuVisibile,
        handlePostUserProjectData,
        handlePutUserProjectData,
        handlePutThemeData,
        handlePutLayoutData,
        handlePutPackageComponentsData,
        errorbox,
        seterrorbox,
        wizardSteps,
        socket,
        userprojid,
        status,
        progressbar,
        setprogressbar,
        handlePutDialoguesData,
        repoid,
        setrepoid,
        productid,
        getid,
        setaiparam,
        aiparam,
        setthemeselectedlist,
        setlayoutselectedlist,
        themeselectedlist,
        layoutselectedlist,
        setTargetid,
        targetid,
        maincontent,
        setContentData,
        setthemeData,
        themecontent,
        setlayoutData,
        layoutcontent,
        setnfrData,
        nfrcontent,
        allversion,
        setallversion,
        setuserprojid,
        deploymentdata,
        setDeploymentData,
        activedeployment,
        setactivedeployment,
        setDeploymentid,
        deploymentid,
    }) {

       // 
    let newlocation
    let KEY = "";
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const location = useLocation();
    const [projname, setprojname] = useState();
    const {instance, accounts, inProgress} = useMsal();
    
    let title;
    //
        if(current===4)
        {
    if (selected.length > 0) {
        document.querySelector('#SelectedNFR').hidden = false;

    }
}
if(wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&current===2)
{
    document.querySelector('#SelectedNFR1').hidden = false;
}

    //for all projects
    const [progress, setprogress] = useState([])
    const getParams = (e) => {
        // 
        // 
        // .value)
        // .value)
    }

    /**START for Add Dialogue fields */
    const [inputFields, setInputFields] = useState([
        {
            dialoguename: "",
            description: "",
        }
    ])

    const handleAdd = () => {
        setInputFields([
            ...inputFields,
            {
                dialoguename: "",
                description: "",
            }
        ])

    }

    const handleChange = (event, index) => {
        const values = [...inputFields]
        // 
        values[index][event.target.name] = event.target.value;

        setInputFields(values)

       // 
    }
    /**END for Add Dialogue fields */

    useEffect(() => {

        if (socket !== undefined) {

            socket.on('receive_message', (data) => {

               // 
                if (data.message !== "Connected SOCKET") {
                    
                    setprogress([...progress, data])
                    status.push(data)
                    setprogressbar(status.filter(x => x.status === "Completed").length)


                }



            });

            return () => socket.off('receive_message');

        }





    }, [socket])

    // 
    // 
    // 
    //.length)

    const maincontent11 = useSelector((state) => state.commonReducer.allProjects1.AllProjects1.allProjects1);
    const [packagegrid11, setpackagegrid1] = useState(maincontent11);
    useEffect(() => { setpackagegrid1(maincontent11) }, [dispatch, maincontent11])
    //
    const handleTitle = () => {
        title = document.querySelector('#ProjectName').value;
        setprojname(title)
        // if (current===0)
        // {
        //     packagegrid11.map((data,index)=>(

        //         data.projectname===projectN?setNewval(2):setNewval(1)

        //         ))

        //         
        //     }

        //         //validation for access token

    }
    //do not clear commented code

    //SEARCH IMPLEMENTATION

    // 1.search project type
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [value, setValue] = useState("");
     const [ver, setVer] = useState("false");
     const [SEARCH, setSearch] = useState("false");

     let ls, SearchHome = ""
     //
     if(location.search !== ''){
         ls = location.search  //'?&search=XR'
        // ls = "?&search=Azure%20Server/VM%20migration"
        if(ls.includes("?&search") === true){
            SearchHome = ls.substring((ls.indexOf("search")+7),ls.split("&", ls.indexOf("=")).join("=").length)
        }else{
            SearchHome = ls.includes("&search")?ls.substring((ls.indexOf("search")+7),ls.split("&", ls.lastIndexOf("&")).join("&").length) : "";
        }
     }
     //

    //  useEffect(() => {
    //     // 
    //     if(document.querySelector('#keyWord')?.value !== undefined && SearchHome !== ""){
    //         SearchHome = SearchHome.replaceAll("%20"," ");
    //         setValue(SearchHome);
    //         // document.querySelector('#keyWord').value = SearchHome
    //     }
    //   }, [document.querySelector('#keyWord')?.value]);

     //on cllick of cross icon
     const endsearch = () =>{
        // 
        location.search = ''
        newlocation = ""
        document.querySelector('#keyWordproj').value = ''
        setVer("false")
     setSearch("false")
        // navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
        setContentData(maincontent)
        // dispatch(fetchProductTypeData(newlocation))
        setValue("");
        setSuggestions([]);
        setSuggestionsActive(false);
     }

    //on change of search bar
    const handleChangeprojtype = (e) => {

        //.includes(e.target.value)),e.target.value)
        setContentData(prodtypedata.filter(x=>x.title.toLowerCase().includes(e.target.value)))

        if(e.target.value.length === 0){
            setContentData(maincontent)
        }
        if((e.target.value.length > 0) || (e.target.value.length === 1)){  
            setVer("true")             
            setSearch("false") 
            // 
        } else 
        if(e.target.value.length === 0 )
        {
        CrossSymbol = <span></span>
        setVer("false")
        }
        const query = e.target.value.toLowerCase();
        setValue(query);
        if (query.length > 2) {
            // 
        let  data1 = prodtypedata.map(x => x.title)
          // 
          const filterSuggestions = data1.filter(
            (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
            );
          setSuggestions(filterSuggestions);
          setSuggestionsActive(true);

        } else {
          setSuggestionsActive(false);
        }

      };

      const handleKeyDownprojtype = (e) => {
        // 
        // UP ARROW
        if (e.keyCode === 38) {
          if (suggestionIndex === 0) {
            return;
          }
          setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
          if (suggestionIndex - 1 === suggestions.length) {
            // 
            return;
          }
          setSuggestionIndex(suggestionIndex + 1);
        }
        // ENTER
        else if (e.keyCode === 13) {
          setValue(suggestions[suggestionIndex]);
          setSuggestionIndex(0);
          setSuggestionsActive(false);
          setSearch("false")
          
          let EnterKey = suggestions[suggestionIndex]
          if(EnterKey === "" || EnterKey === undefined ){
              EnterKey = document.querySelector('#keyWordproj').value
          }
    if(value === ''){
            newlocation = ""
    }else{
       // 
          newlocation = "?search=" + EnterKey;
        //   navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
            setSearch("true")
            // dispatch(fetchProductTypeData(newlocation)) 
        }

        }
      };

      //on click of the search icon
    const onsearchprojtype = () =>{
        
        setSearch("true")
      search = location.search;
     KEY = document.querySelector('#keyWordproj').value
    //  let newloc = search.includes("&search")? search.substring("&search",search.indexOf("&search")) : search;
     if(document.querySelector('#keyWordproj').value === ''){
        newlocation = ""
     }else{
      setSearch("false") 
      newlocation = "?search=" + document.querySelector('#keyWordproj').value;
     }
    //  navigate({
    //  pathname: '/search',
    //  search: newlocation
    //  })
     setSearch("true")
    //  dispatch(fetchProductTypeData(newlocation)) 

     setSuggestions([]);
    setSuggestionsActive(false);
     }

     const handleClick = (e) => {
        setSuggestions([]);
        setValue(e.target.innerText);
        //
        setSuggestionsActive(false);
      };

      const Suggestions = () => {
        return (
          <ul className="suggestions mb-0">
            {suggestions?.map((suggestion, index) => {
              return (
                <li
                  className={index === suggestionIndex ? "active" : ""}
                  key={index}
                  onClick={handleClick}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      };

      if(ver==='true' || location.search.includes("&search=") === true ){
        CrossSymbol = <XLg className='me-3 cursor-pointer' size={18} onClick={endsearch}/>
        //  
    }else if(location.search.includes("&search=") === false){
        CrossSymbol = <span></span>
    }else{
        CrossSymbol = <span></span>
    }


    // 2. Theme Search Code
    const [valuetheme, setValuetheme] = useState("");
    const endthemesearch = () =>{
        // 
        location.search = ''
        newlocation = ""
        document.querySelector('#keyWordthemes').value = ''
        setVer("false")
     setSearch("false")
        // navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
        setthemeData(themecontent)
        // dispatch(fetchThemeData(newlocation))
        setValuetheme("");
        setSuggestions([]);
        setSuggestionsActive(false);
     }

     const handleChangethemetype = (e) => {

        setthemeData(themedata.filter(x=>x.title.toLowerCase().includes(e.target.value)))

        if(e.target.value.length === 0){
            setthemeData(themecontent)
        }

        if((e.target.value.length > 0) || (e.target.value.length === 1)){  
            setVer("true")             
            setSearch("false") 
            // 
        } else 
        if(e.target.value.length === 0 )
        {
        CrossSymboltheme = <span></span>
        setVer("false")
        }
        const query = e.target.value.toLowerCase();
        setValuetheme(query);
        if (query.length > 2) {
            // 
        let  data1 = themedata.map(x => x.title)
          // 
          const filterSuggestions = data1.filter(
            (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
            );
          setSuggestions(filterSuggestions);
          setSuggestionsActive(true);

        } else {
          setSuggestionsActive(false);
        }
      };

      const handleKeyDownthemetype = (e) => {
        // 
        // UP ARROW
        if (e.keyCode === 38) {
          if (suggestionIndex === 0) {
            return;
          }
          setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
          if (suggestionIndex - 1 === suggestions.length) {
            // 
            return;
          }
          setSuggestionIndex(suggestionIndex + 1);
        }
        // ENTER
        else if (e.keyCode === 13) {
          setValuetheme(suggestions[suggestionIndex]);
          setSuggestionIndex(0);
          setSuggestionsActive(false);
          setSearch("false")
          
          let EnterKey = suggestions[suggestionIndex]
          if(EnterKey === "" || EnterKey === undefined ){
              EnterKey = document.querySelector('#keyWordthemes').value
          }
    if(value === ''){
            newlocation = ""
    }else{
        //
          newlocation = "?search=" + EnterKey;
        //   navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
            setSearch("true")
            // dispatch(fetchThemeData(newlocation)) 
        }

        }
      };

      const onsearchthemetype = () =>{
        setSearch("true")
      search = location.search;
     KEY = document.querySelector('#keyWordthemes').value
     if(document.querySelector('#keyWordthemes').value === ''){
        newlocation = ""
     }else{
      setSearch("false") 
      newlocation = "?search=" + document.querySelector('#keyWordthemes').value;
     }
     setSearch("true")
    //  dispatch(fetchThemeData(newlocation)) 

     setSuggestions([]);
    setSuggestionsActive(false);
     }

     const handleClicktheme = (e) => {
        setSuggestions([]);
        setValuetheme(e.target.innerText);
        //
        setSuggestionsActive(false);
      };

      const Suggestionstheme = () => {
        return (
          <ul className="suggestions mb-0">
            {suggestions?.map((suggestion, index) => {
              return (
                <li
                  className={index === suggestionIndex ? "active" : ""}
                  key={index}
                  onClick={handleClicktheme}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      };

      if(ver==='true' || location.search.includes("&search=") === true ){
        CrossSymboltheme = <XLg className='me-3 cursor-pointer' size={18} onClick={endthemesearch}/>
        //  
    }else if(location.search.includes("&search=") === false){
        CrossSymboltheme = <span></span>
    }else{
        CrossSymboltheme = <span></span>
    }

    //Search for package component nfr 

    const [valuenfr, setValuenfr] = useState("");
    const endnfrsearch = () =>{
        
        location.search = ''
        newlocation = getid
        document.querySelector('#keyWordnfr').value = ''
        setVer("false")
     setSearch("false")
        // navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
        setnfrData(nfrcontent)
        // dispatch(fetchNfrTypeData(newlocation))
        setValuenfr("");
        setSuggestions([]);
        setSuggestionsActive(false);
     }

     const handleChangenfrtype = (e) => {

        setnfrData(nfrdata.filter(x=>x.title.toLowerCase().includes(e.target.value)))

        if(e.target.value.length === 0){
            setnfrData(nfrcontent)
        }

        if((e.target.value.length > 0) || (e.target.value.length === 1)){  
            setVer("true")             
            setSearch("false") 
            // 
        } else 
        if(e.target.value.length === 0 )
        {
        CrossSymbolnfr = <span></span>
        setVer("false")
        }
        const query = e.target.value.toLowerCase();
        setValuenfr(query);
        if (query.length > 2) {
            // 
        let  data1 = nfrdata.map(x => x.title)
          // 
          const filterSuggestions = data1.filter(
            (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
            );
          setSuggestions(filterSuggestions);
          setSuggestionsActive(true);

        } else {
          setSuggestionsActive(false);
        }
      };

      const handleKeyDownnfrtype = (e) => {
        // 
        // UP ARROW
        if (e.keyCode === 38) {
          if (suggestionIndex === 0) {
            return;
          }
          setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
          if (suggestionIndex - 1 === suggestions.length) {
            // 
            return;
          }
          setSuggestionIndex(suggestionIndex + 1);
        }
        // ENTER
        else if (e.keyCode === 13) {
          setValuenfr(suggestions[suggestionIndex]);
          setSuggestionIndex(0);
          setSuggestionsActive(false);
          setSearch("false")
          
          let EnterKey = suggestions[suggestionIndex]
          if(EnterKey === "" || EnterKey === undefined ){
              EnterKey = document.querySelector('#keyWordnfr').value
          }
    if(value === ''){
            newlocation = getid
    }else{
        //
          newlocation = getid + "&search=" + EnterKey;
        //   navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
            setSearch("true")
            // dispatch(fetchNfrTypeData(newlocation)) 
        }

        }
      };

      const onsearchnfrtype = () =>{
        
        setSearch("true")
      search = location.search;
     KEY = document.querySelector('#keyWordnfr').value
     if(document.querySelector('#keyWordnfr').value === ''){
        newlocation = getid
     }else{
      setSearch("false") 
      newlocation = getid + "&search=" + document.querySelector('#keyWordnfr').value;
     }
     setSearch("true")
    //  dispatch(fetchNfrTypeData(newlocation)) 

     setSuggestions([]);
    setSuggestionsActive(false);
     }

     const handleClicknfr = (e) => {
        setSuggestions([]);
        setValuenfr(e.target.innerText);
        //
        setSuggestionsActive(false);
      };

      const Suggestionsnfr = () => {
        return (
          <ul className="suggestions mb-0">
            {suggestions.map((suggestion, index) => {
              return (
                <li
                  className={index === suggestionIndex ? "active" : ""}
                  key={index}
                  onClick={handleClicknfr}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      };

      if(ver==='true' || location.search.includes("&search=") === true ){
        CrossSymbolnfr = <XLg className='me-3 cursor-pointer' size={18} onClick={endnfrsearch}/>
        //  
    }else if(location.search.includes("&search=") === false){
        CrossSymbolnfr = <span></span>
    }else{
        CrossSymbolnfr = <span></span>
    }

    

    //Search for layout page

    const [valuelayout, setValuelayout] = useState("");
    const endlayoutsearch = () =>{
        
        location.search = ''
        newlocation = ""
        document.querySelector('#keyWordlayout').value = ''
        setVer("false")
     setSearch("false")
        // navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
        setlayoutData(layoutcontent)
        // dispatch(fetchLayoutData(newlocation))
        setValuelayout("");
        setSuggestions([]);
        setSuggestionsActive(false);
     }

     const handleChangelayouttype = (e) => {

        setlayoutData(layoutdata.filter(x=>x.title.toLowerCase().includes(e.target.value)))

        if(e.target.value.length === 0){
            setlayoutData(layoutcontent)
        }

        if((e.target.value.length > 0) || (e.target.value.length === 1)){  
            setVer("true")             
            setSearch("false") 
            // 
        } else 
        if(e.target.value.length === 0 )
        {
        CrossSymbolnfr = <span></span>
        setVer("false")
        }
        const query = e.target.value.toLowerCase();
        setValuelayout(query);
        if (query.length > 2) {
            // 
        let  data1 = layoutdata.map(x => x.title)
          // 
          const filterSuggestions = data1.filter(
            (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
            );
          setSuggestions(filterSuggestions);
          setSuggestionsActive(true);

        } else {
          setSuggestionsActive(false);
        }
      };

      const handleKeyDownlayouttype = (e) => {
        // 
        // UP ARROW
        if (e.keyCode === 38) {
          if (suggestionIndex === 0) {
            return;
          }
          setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
          if (suggestionIndex - 1 === suggestions.length) {
            // 
            return;
          }
          setSuggestionIndex(suggestionIndex + 1);
        }
        // ENTER
        else if (e.keyCode === 13) {
          setValuelayout(suggestions[suggestionIndex]);
          setSuggestionIndex(0);
          setSuggestionsActive(false);
          setSearch("false")
          
          let EnterKey = suggestions[suggestionIndex]
          if(EnterKey === "" || EnterKey === undefined ){
              EnterKey = document.querySelector('#keyWordlayout').value
          }
    if(value === ''){
            newlocation = getid
    }else{
       // 
          newlocation = "?search=" + EnterKey;
        //   navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
            setSearch("true")
            // dispatch(fetchLayoutData(newlocation)) 
        }

        }
      };

      const onsearchlayouttype = () =>{
        
        setSearch("true")
      search = location.search;
     KEY = document.querySelector('#keyWordlayout').value
     if(document.querySelector('#keyWordlayout').value === ''){
        newlocation = ""
     }else{
      setSearch("false") 
      newlocation ="?search=" + document.querySelector('#keyWordlayout').value;
     }
     setSearch("true")
    //  dispatch(fetchLayoutData(newlocation)) 

     setSuggestions([]);
    setSuggestionsActive(false);
     }

     const handleClicklayout = (e) => {
        setSuggestions([]);
        setValuelayout(e.target.innerText);
        //
        setSuggestionsActive(false);
      };

      const Suggestionslayout = () => {
        return (
          <ul className="suggestions mb-0">
            {suggestions.map((suggestion, index) => {
              return (
                <li
                  className={index === suggestionIndex ? "active" : ""}
                  key={index}
                  onClick={handleClicklayout}>
                  {suggestion}
                </li>
              );
            })}
          </ul>
        );
      };

      if(ver==='true' || location.search.includes("&search=") === true ){
        CrossSymbollayout = <XLg className='me-3 cursor-pointer' size={18} onClick={endlayoutsearch}/>
        //  
    }else if(location.search.includes("&search=") === false){
        CrossSymbollayout = <span></span>
    }else{
        CrossSymbollayout = <span></span>
    }

    let label =<></>
   // label=targetid===1?<div>Access Token : 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</div>:<div><div>storage connection string : DefaultEndpointsProtocol=https;AccountName=rapidxdevportal;AccountKey=cSNe3w9LIIz7nqnIJgC+wfrJoJ9Fk2hltY4vfJ51JmZx1YChdWYfNEpDxWr+cKX7TPy3PahBkU+U+AStpYimnQ==;EndpointSuffix=core.windows.net</div><div>container name : test</div></div>
    // if(targetid===1&&(projectType==="PWA React"||projectType==="PWA .NET"||projectType==="AI Bot .NET"))
    // {
    //     label=<>
    //     <div class="mb-2">
    //         <span>Azure Deployment </span><input type="checkbox" id="deployment" /><br/>
    //     </div>
    //     <div>Access Token : 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</div>
    //     </>

    // }
    // else 
    if(targetid===1)
    {
        label=<>
        <div>Access Token : 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</div>
        <div>Organisation Name : CodeAssistant-Repo</div>
        </>
    }
    if(targetid===2)
    {
        label=<div><div>storage connection string : DefaultEndpointsProtocol=https;AccountName=BitCraft;AccountKey=cSNe3w9LIIz7nqnIJgC+wfrJoJ9Fk2hltY4vfJ51JmZx1YChdWYfNEpDxWr+cKX7TPy3PahBkU+U+AStpYimnQ==;EndpointSuffix=core.windows.net</div><div>container name : test</div></div>
    }

    return (
        <>
            <ToastProvider placement="top-center" >
                <Modal show={show1} onHide={() => { handleClose1(); handleClose(); setCurrent(0); setprojectType(""); seterrorbox(0);setuserprojid("") }} scrollable backdrop="static"
                    //keyboard={false} centered dialogClassName={(reset !== 1) ? (modalsize === 1 && current === 0) ? "modal-85w" : (modalsize === 1 && current === 4||modalsize === 1 && current === 3||( selected.length>0) ) ? "modal-95w" : "modal-75w" : (modalsize === 1 && current === 0) ? "modal-75w" : (modalsize === 1 && current === 4) ? "modal-95w" : "modal-75w"}>
                    keyboard={false} centered dialogClassName={(modalsize === 1 && current === 0) ? "modal-85w" : (current === 3 && activelayout === "Figma" ||!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&current === 4 && selected.length > 0||wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&current===2&&selected.length>0) ? "modal-95w" : "modal-75w"}>

                    {/* <Modal.Header closeButton hidden={current === 0 ? false : true}> */}
                    {projectTypeDisplay}
                    {/* </Modal.Header> */}
                    <Modal.Header closeButton={document.querySelector("#spinnerdiv")?.hidden === false ? false : true} hidden={current === 0 ? true : false}>
                        <Modal.Title className='font_18 font_regular'>{`${projectType} - ${projname}`}</Modal.Title>
                    </Modal.Header>
                    <Modal.Body className='p-0 position-relative'>
                        <div class="h-100 w-100 bg_grey_2 resultscreen" id="spinnerdiv" hidden={true}>
                            <div class="row g-0 h-100">
                                <div class="col-lg-8 p-3 bg_grey_1">
                                    <div class="row m-0 p-2" id="5" 
                                    // hidden={wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true && current === 4 ? false :! wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true && current === 6?false:true }
                                    >
                                    {/* <div class="row m-0 p-2" id="5" hidden={laststep === 5?false:true }> */}
                                       
                                        <div class="col-12 pb-3 myprofile_progreebar">
                                            <div class="desktop_progress_align">
                                                <div class="d-flex pt-3 pb-2">
                                                    <div className='font_14 font_regular text-white'><span style={{ color: "#72B266" }}> {wizardSteps?.length>0?((progressbar * Math.ceil(100/wizardSteps[0]?.steps.filter(x=>x.description!=="").length)) > 100 ? 100 : progressbar * Math.ceil(100/wizardSteps[0]?.steps.filter(x=>x.description!=="").length)):0}% Update in progress…</span> </div>
                                                    <div className='font_14 font_regular ms-auto'><span> {progressbar}</span> / <span>{wizardSteps?.length>0?wizardSteps[0]?.steps.filter(x=>x.description!=="").length:0}</span> Completed</div>
                                                </div>
                                                <div class="progress">
                                                    <div style={{ width: `${wizardSteps?.length>0?progressbar *  Math.ceil(100/wizardSteps[0]?.steps.filter(x=>x.description!=="").length):0}%` }} class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 ">
                                            <ul class="list_progress">
                                                
                                                    {wizardSteps?.map(x=>x.steps).map((wizard)=>(
                                                        
                                                        <>
                                                        {wizard.filter(t=> t.description !="").map((wiz)=>(
                                                             selectedid.length===0&&wiz.description==="Packaged Component"?null:
                                                             deploymentid===undefined&&wiz.description==="Deployment Configuration"?null:
                                                            <>
                                                                <li class="mb-2">
                                                                <div class="d-flex flex-row align-items-center">
                                                            
                                                                {
                                                     
                                                                status?.filter(x => x.message === wiz.stepname && x?.status === "Completed").length > 0 ?
                                                                  
                                                                   <>
                                                                        <div class="d-flex flex-row align-items-center">
                                                                            <div class="">
                                                                                <Green_tick />
                                                                            </div>
                                                                          
                                                                            <div class="ms-2 font_14 font_regular">{wiz.description}</div>
                                                                        </div>
                                                                        <div class="ms-auto font_14 text-success">Completed</div>
                                                                        </>
                                                                    
                                                                    :
                                                                    status?.filter(x => x.message === wiz.stepname && x?.status === "In progress").length > 0 ?
                                                                        <><div class="d-flex flex-row align-items-center">
                                                                            <div class="">
                                                                                <Spinner animation="border" variant="primary" />
                                                                            </div>

                                                                            <div class="ms-2 font_14 font_regular">{wiz.description}</div>
                                                                        </div>
                                                                        {/* <div class="ms-2 font_14 font_regular">3 secs</div> */}
                                                                            <div class="ms-auto font_14 text-info">(Approx time - {wiz.approxtime}) In progress</div></>
                                                                        :
                                                                        <>
                                                                            <div class="d-flex flex-row align-items-center">
                                                                                <div class="">
                                                                                    <div class="notstarted_div"></div>
                                                                                </div>
                                                                                <div class="ms-2 font_14 font_regular">{wiz.description}</div>
                                                                            </div>
                                                                            {/* <div class="ms-2 font_14 font_regular">3 secs</div> */}
                                                                            <div class="ms-auto font_14 text-warning">(Approx time - {wiz.approxtime}) Not Started</div>

                                                                        </>
                                                                       
                                                                        
                                                                }
                                                            
                                                            </div>
                                                           </li>
                                                            </>

                                                   
                                                        ))}
                                                        </>
                                                    ))}
                                                        {
                                                            // status?.filter(x => x.message === "Project Selection" && x?.status === "Completed").length > 0 ?
                                                            //     <>
                                                            //         <div class="d-flex flex-row align-items-center">
                                                            //             <div class="">
                                                            //                 <Green_tick />
                                                            //             </div>
                                                            //             <div class="ms-2 font_14 font_regular">Project Selection</div>
                                                            //         </div>
                                                            //         <div class="ms-auto font_14 text-success">Completed</div>

                                                            //     </>

                                                            //     :
                                                            //     status?.filter(x => x.message === "Project Selection" && x?.status === "In progress").length > 0 ?
                                                            //         <><div class="d-flex flex-row align-items-center">
                                                            //             <div class="">
                                                            //                 <Spinner animation="border" variant="primary" />
                                                            //             </div>
                                                            //             <div class="ms-2 font_14 font_regular">Project Selection</div>
                                                            //         </div>
                                                            //         {/* <div class="ms-2 font_14 font_regular">3 secs</div> */}
                                                            //             <div class="ms-auto font_14 text-info">(Approx time - 3s) In progress</div></>
                                                            //         :

                                                            //         <>
                                                            //             <div class="d-flex flex-row align-items-center">
                                                            //                 <div class="">
                                                            //                     <div class="notstarted_div"></div>
                                                            //                 </div>
                                                            //                 <div class="ms-2 font_14 font_regular">Project Selection</div>
                                                            //             </div>
                                                            //             {/* <div class="ms-2 font_14 font_regular">3 secs</div> */}
                                                            //             <div class="ms-auto font_14 text-warning">(Approx time - 3s) Not Started</div>

                                                            //         </>


                                                        }
                                                    
                                                {/* list deleted */}

                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 p-3 align-items-center d-flex">
                                    <img className='img-fluid' src={Loader}></img>
                                </div>
                            </div>

                            {/* <Spinner animation="grow" variant="success" />
                            <Spinner animation="grow" variant="danger" />
                            <Spinner animation="grow" variant="warning" /> */}
                        </div>
                        <div class="h-100" id="nonspinnerdiv" hidden={false}>
                            <div class="row h-100 m-0" id="modal1" hidden={current === 0 ? false : true}>
                                <div class="col p-3 left_position_realtive">
                                    <div class="row">
                                        <div className='col-lg-12'>
                                            <div className='d-flex'>
                                                {/* <p><span>{projcount}</span> Projects</p> */}
                                                <div class="ms-auto d-flex align-items-center">
                                                    <div class="custom_inputbtn">
                                                        <InputGroup >                                   
                                                        <div tabIndex="0"
                                                            className={'autocomplete flex-fill'+ " " + ((document.querySelector('#keyWord')?.value === '' && SEARCH === 'true')? '' : '')} 
                                                        >
                                                            <input type="text" class="form-control" value={value}  onChange={handleChangeprojtype} 
                                                            onKeyDown={handleKeyDownprojtype} 
                                                            id="keyWordproj" placeholder="Search" />
                                                            {/* {suggestionsActive && <Suggestions />} */}
                                                        </div>
                                                        <InputGroup.Text  className='ms-auto justify-content-center px-3'>
                                                            {CrossSymbol}
                                                            <div class=""><Search className="cursor-pointer" onClick={onsearchprojtype}/></div>
                                                        </InputGroup.Text>
                                                        </InputGroup>
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                        <div class="row px-2 g-0 row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4" >
                                        
                                            {prodtypedata?.filter(x=>x.id!=="667694ba9963e60ab8d43860")?.length===0?<h3>No Data</h3>:""}
                                            {prodtypedata?.filter(x=>x.id!=="667694ba9963e60ab8d43860")?.map((prodtypedata, index) => (
                                                <div class="col mb-3 px-2 " 
                                                    onClick={() => { document.querySelector('#Input').hidden = false; 
                                                    document.querySelector('#buttons').hidden = false; 
                                                    setproductid(prodtypedata.id); 
                                                    setmodalsize(1); 
                                                    setprojectType(prodtypedata.title); 
                                                    setService(false); 
                                                   
                                                    setgetid(prodtypedata.id); 
                                                    setSelected([]);
                                                    if(instance.getAllAccounts()[0] !== undefined){
                                                    dispatch(fetchProjectVersionData({data:prodtypedata.id,instance:instance})); 
                                                    dispatch(fetchNfrTypeData({data:prodtypedata.id,instance:instance}));
                                                    dispatch(fetchThemeData({data:prodtypedata.id, instance:instance})) }}
                                                    }
                                                    >
                                                    <div class={`projects_main h-100 text-center ${projectType === prodtypedata.title ? "active" : null}`} >
                                                      
                                                        <img src={prodtypedata.icon} className="img-fluid mb-3" />
                                                        {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                        <h4 className='font_14 font_regular'>{prodtypedata.title}</h4>
                                                        {/* <p className='font_12  mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                        <p className='content_size font_12  mb-0 font_light'>{prodtypedata.description}</p>
                                                    </div>
                                                </div>
                                            ))}

                                        </div>
                                        {/* <div class="row left_position_absolute">
                                <div class="col-12 d-flex justify-content-end">
                                    <Pagination className="m-0">
                                        <Pagination.Prev />
                                        <Pagination.Item>{1}</Pagination.Item>
                                        <Pagination.Ellipsis />

                                        <Pagination.Item>{10}</Pagination.Item>
                                        <Pagination.Item>{11}</Pagination.Item>
                                        <Pagination.Item active>{12}</Pagination.Item>
                                        <Pagination.Item>{13}</Pagination.Item>
                                        <Pagination.Item disabled>{14}</Pagination.Item>

                                        <Pagination.Ellipsis />
                                        <Pagination.Item>{20}</Pagination.Item>
                                        <Pagination.Next />
                                    </Pagination>
                                </div>
                            </div> */}

                                    </div>

                                </div>
                                <div class="col-lg-3 border-start" id='Input' hidden={hiddenval === 1 ? true : false}>
                                    <div class="right_selectelements h-100 py-3 px-2 ">
                                        <div class="d-flex flex-column">
                                            <p className='font_14 font_regular'>Add Project Details</p>

                                            <div class="mb-2">
                                                <input type="text" class={`form-control ${errorbox === 1 ? "border-danger" : null}`} id="ProjectName" placeholder="Project Name" onChange={handleTitle} />
                                                {/* <label id="titlenameErr" class="error-label"></label> */}
                                            </div>
                                            <div class="mb-2">
                                                <textarea class={`form-control textareaform ${errorbox === 2 ? "border-danger" : null}`} placeholder='Description' id="ProjectDescription" rows="4"></textarea>
                                            </div>
                                            <select class="form-select mb-2 " aria-label="Default select example" id="selectversion" onChange={addVersionID}>
                                                <option selected>Select Version</option>
                                                {allversion?.filter(x=>x.projecttype?.includes(projectType)).map((prodtypedata, index) => (
                                                    <option value={prodtypedata.id} id="versionid">{prodtypedata.version}</option>
                                                ))}
                                                {/* {projectVersionDisplay} */}
                                            </select>
                                            <div class="mb-2">
                                             <span> Public  </span><input type="checkbox" id="myCheck" defaultChecked/><br/>
                                            </div>
                                            <div class="mb-2" hidden={ projectType=== "Android App" || projectType=== "IOS App" ? false : true}>
                                                <div class="mb-2" hidden={ projectType=== "Android App" ? true : false}>
                                                    <span>Include Android  </span><input type="checkbox" id="myCheck1" /><br/>
                                                </div>
                                                <div class="mb-2"  hidden={ projectType=== "IOS App" ? true : false} >
                                                    <span>Include IOS  </span><input type="checkbox" id="myCheck2" />
                                                </div>
                                            </div>
                                            
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="row h-100 m-0" hidden={current === 0 || current === 6 ? true : false}>
                                <div class="col-12 p-0">
                                    <div class="d-flex h-100">
                                        <div class="tabs_index" id="modal2">
                                            <ul className='timeline_vertical'>
                                                {wizardSteps?.map((wizardSteps, index) => (
                                                    <>
                                                        {wizardSteps?.steps?.map((step) => (

                                                            <li class={current === step.stepnumber - 1 ? "active" : current > step.stepnumber - 1 ? "completed" : ""}>
                                                                {(step.stepname === "Add Dialogues") ? setService(true) : ""}

                                                                <div class="d-flex align-items-center">
                                                                    <> <div class="left_nos">{step.stepnumber}</div>
                                                                        <div class="right_title ms-2"><p className='mb-0 font_14 font_regular'>{step.stepname}</p></div></>
                                                                </div>
                                                            </li>
                                                        ))}
                                                    </>
                                                ))}

                                            </ul>
                                        </div>
                                        <div class="tabs_main flex-fill">
                                            <div class="row m-0" id="modal3" hidden={true}>
                                                <div class="col ps-4 pe-4 py-3">
                                                    <div class="page1" id="1" hidden={current === 1 ? false : true}>
                                                        <div class="row">
                                                            <div class="col-lg-6">
                                                                <p className='font_14 font_regular'>Target</p>
                                                                <div class="mb-3">
                                                                    <select class="form-select" aria-label="Default select example" id="selectrepoid" onChange={addOptionID}>
                                                                        <option selected>Open this select menu</option>
                                                                        {projrepodata?.map((data, index) => (
                                                                            <option id="optionid" value={data.id}>{data.reponame}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div class="mb-3">
                                                                {projrepodata?.map((data, index) => (
                                                                    data.id===repoid?
                                                                    data.parameters?.map((param, index) => (
                                                                        <div style={{paddingBottom: '15px'}}>
                                                                         <input type="text" class={`form-control ${errorbox === 3 ? "border-danger" : null}`} id={(data.reponame + param).replace(/ /g, "")} placeholder={param} />
                                                                        </div>
                                                                    )):<></>
                                                                    // : <input type="text" class={`form-control ${errorbox === 3 ? "border-danger" : null}`} id="accessToken" placeholder="" value="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" />
                                                                    // 
                                                                    ))}
                                                                    </div>
                                                                    { label}
                                                                    
                                                                {/* <Button variant="primary me-3" onClick={addOptionID}>
                                                                    Access Token
                                                                </Button> */}



                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div hidden={wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues") ? true : false}>


                                                        <div class="page2" id="4" hidden={current === 4 ? false : true} >
                                                            <div class="row">
                                                                <div className='col-lg-12'>
                                                                    <div className='d-flex align-items-center flex-wrap'>
                                                                        <p className='mb-0 font_14 font_light'><span className='font_16 font_regular'>{nfrcount}</span> Packaged Components</p>
                                                                        <p className='mb-0 font_14 font_light ms-4'><span className='font_16 font_regular'>{selected.length}</span> Selected</p>
                                                                        <div class="ms-auto d-flex align-items-center">
                                                                            <div class="custom_inputbtn">
                                                                            <InputGroup >
                                                                            <div tabIndex="0"
                                                                                className={'autocomplete flex-fill'+ " " + ((document.querySelector('#keyWordnfr')?.value === '' && SEARCH === 'true')? '' : '')} 
                                                                            >
                                                                                <input type="text" class="form-control" 
                                                                                id="keyWordnfr" 
                                                                                onKeyDown={handleKeyDownnfrtype}
                                                                                value={valuenfr}
                                                                                onChange={handleChangenfrtype}
                                                                                placeholder="Search" />
                                                                                {/* {suggestionsActive && <Suggestionsnfr />} */}
                                                                            </div>
                                                                                 <InputGroup.Text  className='ms-auto justify-content-center'>
                                                                                {CrossSymbolnfr}
                                                                                <div class=""><Search onClick={onsearchnfrtype} /></div>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div class="row row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4" hidden={current === 4 ? false : true}>
                                                                {nfrdata?.filter(x=>x.isactive===true)?.map((nfrdata, index) => (

                                                                    <div class="col px-2 mb-3" onClick={() => {
                                                                        ;
                                                                        vacdep=1;
                                                                       
                                                                        if (selected.length > 0) {
                                                                            document.querySelector('#SelectedNFR').hidden = false;

                                                                        }
                                                                        document.querySelector('#SelectedNFR').hidden = false;
                                                                        setmodalsize(1);
                                                                        selected.includes(nfrdata.title) ? setSelected(selected.filter(x => x !== nfrdata.title)) :
                                                                            setSelected([...selected, nfrdata.title])
                                                                        setTitle.push(nfrdata.title);
                                                                        selectedid.includes(nfrdata.id) ? setSelectedid(selectedid.filter(x => x !== nfrdata.id)) :
                                                                            setSelectedid([...selectedid, nfrdata.id])
                                                                        setTitle.push(nfrdata.id);
                                                                        
                                                                        //
                                                                    }}>
                                                                        {/* {}
                                                                        {)} */}
                                                                        <div className={selected.includes(nfrdata.title) ? "projects_main h-100 text-center active" : "projects_main h-100 text-center"}>
                                                                            <img src={nfrdata.icon} className="img-fluid mb-3" />
                                                                            {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                                            <h4 className='font_14 font_regular'>{nfrdata.title}</h4>
                                                                            {/* <p className='font_12 mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                                            <p className='font_12 content_size mb-0 font_light'>{nfrdata.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>

                                                        <div class="page2" id="2" hidden={current === 2 ? false : true}>
                                                            <div class="row">
                                                                <div className='col-lg-12'>
                                                                    <div className='d-flex align-items-center flex-wrap'>
                                                                        <p className='mb-0 font_14 font_light'><span className='font_16 font_regular'>{themecount}</span> Theme</p>
                                                                        {/* <p className='mb-0 font_14 font_light ms-4'><span className='font_16 font_regular'>1</span> Selected</p> */}
                                                                        <div class="ms-auto d-flex align-items-center">
                                                                            <div class="custom_inputbtn">
                                                                            <InputGroup >  
                                                                            <div tabIndex="0"
                                                                                className={'autocomplete flex-fill'+ " " + ((document.querySelector('#keyWord')?.value === '' && SEARCH === 'true')? '' : '')} 
                                                                            >
                                                                                <input type="text" class="form-control" 
                                                                                id="keyWordthemes" 
                                                                                onKeyDown={handleKeyDownthemetype}
                                                                                value={valuetheme}
                                                                                onChange={handleChangethemetype}
                                                                                placeholder="Search" />
                                                                                {/* {suggestionsActive && <Suggestionstheme />} */}
                                                                            </div>
                                                                                <InputGroup.Text  className='ms-auto justify-content-center'>
                                                                                {CrossSymboltheme}
                                                                                <div class=""><Search onClick={onsearchthemetype} /></div>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div class="row row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4">
                                                                {themedata?.filter((x=>x.projecttypeid?.includes(getid)))?.map((themedata, index) => (
                                                                    <div class="col px-2 mb-3" onClick={() => { setthemeid(themedata.id); setactivetheme(themedata.title) }}>
                                                                        <div class={`projects_main h-100 text-center ${activetheme === themedata.title ? "active" : null}`}>
                                                                            <img src={themedata.icon} className="img-fluid mb-3" />
                                                                            {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                                            <h4 className='font_14 font_regular'>{themedata.title}</h4>
                                                                            {/* <p className='font_12 mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                                            <p className='font_12 content_size mb-0 font_light'>{themedata.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>
                                                        <div class="page2" id="3" hidden={current === 3 ? false : true}>
                                                            <div class="row">
                                                                <div className='col-lg-12'>
                                                                    <div className='d-flex align-items-center flex-wrap'>
                                                                        <p className='mb-0 font_14 font_light'><span className='font_16 font_regular'>{layoutcount}</span> Layout</p>
                                                                        {/* <p className='mb-0 font_14 font_light ms-4'><span className='font_16 font_regular'>1</span> Selected</p> */}
                                                                        <div class="ms-auto d-flex align-items-center">
                                                                            <div class="custom_inputbtn">
                                                                            <InputGroup >
                                                                            <div tabIndex="0"
                                                                                className={'autocomplete flex-fill'+ " " + ((document.querySelector('#keyWordlayout')?.value === '' && SEARCH === 'true')?''  : '')} 
                                                                            >
                                                                                <input type="email" class="form-control" 
                                                                                id="keyWordlayout" 
                                                                                placeholder="Search"
                                                                                onKeyDown={handleKeyDownlayouttype}
                                                                                value={valuelayout}
                                                                                onChange={handleChangelayouttype}
                                                                                 />
                                                                                 {/* {suggestionsActive && <Suggestionslayout />} */}
                                                                            </div>
                                                                                <InputGroup.Text  className='ms-auto justify-content-center'>
                                                                                {CrossSymbollayout}
                                                                                <div class=""><Search  onClick={onsearchlayouttype}/></div>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div class="row row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4">
                                                                {layoutdata?.filter((x=>x.projecttypeid?.includes(getid)))?.map((layoutdata, index) => (
                                                                    <div class="col px-2 mb-3" onClick={() => { setactivelayout(layoutdata.title); layoutdata.isExpand === true ? vac = 1 : vac = 0; layoutdata.title === "Figma" ? setmodalsize(1) : setmodalsize(0); if (layoutdata.title === "Figma") { sizeset = 1 } }}>
                                                                        <div class={`projects_main h-100 text-center ${activelayout === layoutdata.title ? "active" : null}`} onClick={() => {
                                                                             setlayoutid(layoutdata.id); }}>
                                                                            <img src={layoutdata.icon} className="img-fluid mb-3" />
                                                                            {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                                            <h4 className='font_14 font_regular'>{layoutdata.title}</h4>
                                                                            {/* <p className='font_12 mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                                            <p className='font_12 content_size mb-0 font_light'>{layoutdata.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>



                                                        </div>

                                                        {/* Deployment div */}
                                                        <div class="page2" id="4" hidden={current === 5 ? false : true} >
                                                            <div class="row">
                                                                <div className='col-lg-12'>
                                                                    <div className='d-flex align-items-center flex-wrap'>
                                                                        <p className='mb-0 font_14 font_light'><span className='font_16 font_regular'>{deploymentdata?.length}</span> Deployment</p>
                                                                        {/* <p className='mb-0 font_14 font_light ms-4'><span className='font_16 font_regular'>0</span> Selected</p> */}
                                                                        <div class="ms-auto d-flex align-items-center">
                                                                            <div class="custom_inputbtn">
                                                                            <InputGroup >
                                                                            <div tabIndex="0"
                                                                                className={'autocomplete flex-fill'+ " " + ((document.querySelector('#keyWorddeployment')?.value === '' && SEARCH === 'true')? '' : '')} 
                                                                            >
                                                                                <input type="text" class="form-control" 
                                                                                id="keyWorddeployment" 
                                                                                onKeyDown=""
                                                                                // value=""
                                                                                onChange=""
                                                                                placeholder="Search" />
                                                                                
                                                                            </div>
                                                                                 <InputGroup.Text  className='ms-auto justify-content-center'>
                                                                                {/* {CrossSymboldeployment} */}
                                                                                <div class=""><Search onClick="" /></div>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div class="row row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4" hidden={current === 5 ? false : true}>
                                                                {deploymentdata?.map((deploymentdata, index) => (

                                                                    <div class="col px-2 mb-3" onClick={() => {
                                                                        setactivedeployment(deploymentdata?.title)
                                                                       
                                                                        document.querySelector('#SelectedDeployment').hidden = false;
                                                                        setmodalsize(1);   
                                                                        setDeploymentid(deploymentdata?.id)                                                              
                                                                    }}>                                                                    
                                                                        <div className={activedeployment?.includes(deploymentdata?.title) ? "projects_main h-100 text-center active" : "projects_main h-100 text-center"}>
                                                                            <img src="" className="img-fluid mb-3" />
                                                                            {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                                            <h4 className='font_14 font_regular'>{deploymentdata?.title}</h4>
                                                                            {/* <p className='font_12 mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                                            <p className='font_12 content_size mb-0 font_light'>{deploymentdata?.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div hidden={!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues") ? true : false}>
                                                        <div class="page2 " id="2" hidden={current === 2 ? false : true} >
                                                            <div class="row">
                                                                <div className='col-lg-12'>
                                                                    <div className='d-flex align-items-center flex-wrap'>
                                                                        <p className='mb-0 font_14 font_light'><span className='font_16 font_regular'>{nfrcount}</span> Packaged Components</p>
                                                                        <p className='mb-0 font_14 font_light ms-4'><span className='font_16 font_regular'>{selected.length}</span> Selected</p>
                                                                        <div class="ms-auto d-flex align-items-center">
                                                                            <div class="custom_inputbtn">
                                                                                <input type="text" class="form-control" id="" placeholder="Search" />
                                                                                <div class=""><Search /></div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div class="row row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4" hidden={current === 2 ? false : true}>
                                                                {nfrdata?.filter(x=>x.isactive===true)?.map((nfrdata, index) => (
                                                                    <div class="col px-2 mb-3" onClick={() => {
                                                                        ;
                                                                       
                                                                        document.querySelector('#SelectedNFR1').hidden = false;
                                                                        setmodalsize(1);
                                                                        selected.includes(nfrdata.title) ? setSelected(selected.filter(x => x !== nfrdata.title)) :
                                                                            setSelected([...selected, nfrdata.title])
                                                                        setTitle.push(nfrdata.title);
                                                                        selectedid.includes(nfrdata.id) ? setSelectedid(selectedid.filter(x => x !== nfrdata.id)) :
                                                                            setSelectedid([...selectedid, nfrdata.id])
                                                                        setTitle.push(nfrdata.id);
                                                                        set1 = 1;
                                                                        //
                                                                    }}>
                                                                        {/* {}
                                                                        {)} */}
                                                                        <div className={selected.includes(nfrdata.title) ? "projects_main h-100 text-center active" : "projects_main h-100 text-center"}>
                                                                            <img src={nfrdata.icon} className="img-fluid mb-3" />
                                                                            {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                                            <h4 className='font_14 font_regular'>{nfrdata.title}</h4>
                                                                            {/* <p className='font_12 mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                                            <p className='font_12 content_size mb-0 font_light'>{nfrdata.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>

                                                        </div>
                                                        {/* Deployment div for bot*/}
                                                        <div class="page2 d-none" id="4" hidden={current === 4 ? false : true} >
                                                            <div class="row">
                                                                <div className='col-lg-12'>
                                                                    <div className='d-flex align-items-center flex-wrap'>
                                                                        <p className='mb-0 font_14 font_light'><span className='font_16 font_regular'>{deploymentdata?.length}</span> Deployment</p>
                                                                        {/* <p className='mb-0 font_14 font_light ms-4'><span className='font_16 font_regular'>0</span> Selected</p> */}
                                                                        <div class="ms-auto d-flex align-items-center">
                                                                            <div class="custom_inputbtn">
                                                                            <InputGroup >
                                                                            <div tabIndex="0"
                                                                                className={'autocomplete flex-fill'+ " " + ((document.querySelector('#keyWorddeployment')?.value === '' && SEARCH === 'true')?  '': '')} 
                                                                            >
                                                                                <input type="text" class="form-control" 
                                                                                id="keyWorddeployment" 
                                                                                onKeyDown=""
                                                                                // value=""
                                                                                onChange=""
                                                                                placeholder="Search" />
                                                                                
                                                                            </div>
                                                                                 <InputGroup.Text  className='ms-auto justify-content-center'>
                                                                                {/* {CrossSymboldeployment} */}
                                                                                <div class=""><Search onClick="" /></div>
                                                                                </InputGroup.Text>
                                                                            </InputGroup>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>

                                                            </div>
                                                            <div class="row row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-3 row-cols-xl-4 list_projects_div mt-4" hidden={current === 3 ? false : true}>
                                                                {deploymentdata?.map((deploymentdata, index) => (

                                                                    <div class="col px-2 mb-3" onClick={() => {
                                                                        ;
                                                                        setdep1=1;
                                                                        setactivedeployment(deploymentdata?.title)
                                                                       
                                                                        document.querySelector('#SelectedDeployment').hidden = false;
                                                                        setmodalsize(1);  
                                                                        setDeploymentid(deploymentdata?.id) 
                                                                                                                                     
                                                                    }}>                                                                    
                                                                        <div className={activedeployment?.includes(deploymentdata?.title) ? "projects_main h-100 text-center active" : "projects_main h-100 text-center"}>
                                                                            <img src="" className="img-fluid mb-3" />
                                                                            {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                                            <h4 className='font_14 font_regular'>{deploymentdata?.title}</h4>
                                                                            {/* <p className='font_12 mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                                            <p className='font_12 content_size mb-0 font_light'>{deploymentdata?.description}</p>
                                                                        </div>
                                                                    </div>
                                                                ))}

                                                            </div>
                                                        </div>
                                                        {/*START for Add Dialogue page  */}
                                                        {
                                                            inputFields?.map((item, index) => {
                                                                return (
                                                                    <div key={index}>
                                                                        <UserProjectDialogueForm
                                                                            inputFields={inputFields}
                                                                            index={index}
                                                                            item={item}
                                                                            handleChange={handleChange}
                                                                            currentVal={current}
                                                                            handleAdd={handleAdd}
                                                                            inputFieldsCount={inputFields.length}
                                                                            key="userDialogue"
                                                                        />
                                                                    </div>
                                                                )
                                                            })
                                                        }                                                                
                                                    </div>  

                                                    
                                                    
                                                </div>

                                                {/* selected package components divs */}
                                                <div class={!wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&selected.length>0?"col-lg-3 border-start":"col-lg-3 border-start d-none"} id='SelectedNFR' 
                                                hidden={wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&current !== 4 ? true : !wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&vacdep === 1 &&current===4? false : true}
                                                >
                                                    <div class="right_selectelements h-100 py-3 px-2 ">
                                                        <div class="d-flex flex-column "  >
                                                            <p className='font_14 font_regular'>Selected Packaged Components</p>                        
                                                        </div>
                                                        <div class="d-flex custom_accordian_wd">
                                                            <Accordion defaultActiveKey={['1']} alwaysOpen>
                                                                {nfrdata?.map((nfrdata, index) => (
                                                                    selected.includes(nfrdata.title) ? (
                                                                        <Accordion.Item id="item1" eventKey={index}>

                                                                            <Accordion.Header>{nfrdata.title}</Accordion.Header>
                                                                            <Accordion.Body id="accordian">
                                                                                {/* { )} */}
                                                                                {nfrdata.parameters?.map((parameters, index) => (
                                                                                   
                                                                                   
                                                                                   <div class="mb-2">
                                                                                    {/* {(nfrdata.title + parameters).replace(/ /g, "")} */}
                                                                                       <input type="text" class="form-control" id={(nfrdata.title +parameters).replace(/ /g, "")} placeholder={parameters} onBlur={(e)=>{let obj={id:(nfrdata.title +parameters).replace(/ /g, ""),value:e.target.value};setaiparam([...aiparam,obj])}}/>
                                                                                   </div>

                                                                                ))}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    ) : null
                                                                ))}
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class={wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&selected.length>0?"col-lg-3 border-start":"col-lg-3 border-start d-none"} id='SelectedNFR1' hidden={set1 === 1 ? current === 2 ? false : true : true}>
                                                    <div class="right_selectelements h-100 py-3 px-2 ">
                                                        <div class="d-flex flex-column">
                                                            <p className='font_14 font_regular'>Selected Packaged Components</p>                                            
                                                              </div>
                                                        <div class="d-flex custom_accordian_wd">
                                                            <Accordion defaultActiveKey={['1']} alwaysOpen>
                                                                {nfrdata?.map((nfrdata, index) => (
                                                                    selected.includes(nfrdata.title) ? (
                                                                        <Accordion.Item eventKey={index}>

                                                                            <Accordion.Header>{nfrdata.title}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                             
                                                                            {nfrdata.parameters?.map((parameters,index) => (
                                                                                   
                                                                                  
                                                                                   <div class="mb-2">
                                                                                    {/* {(nfrdata.title + parameters).replace(/ /g, "")} */}
                                                                                       <input type="text" class="form-control" id={(nfrdata.title +parameters).replace(/ /g, "")} placeholder={parameters} onBlur={(e)=>{let obj={id:(nfrdata.title +parameters).replace(/ /g, ""),value:e.target.value};setaiparam([...aiparam,obj])}}/>
                                                                                   </div>

                                                                               ))} 
                                                                            </Accordion.Body>




                                                                        </Accordion.Item>
                                                                    ) : null
                                                                ))}                                                    
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                {/* Selected Deployment wizard Step */}
                                                <div class={!wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&activedeployment?.length>0?"col-lg-3 border-start":"col-lg-3 border-start d-none"} id='SelectedDeployment'  hidden={true}>
                                                    <div class="right_selectelements h-100 py-3 px-2 ">
                                                        <div class="d-flex flex-column "  >
                                                            <p className='font_14 font_regular'>Selected Deployment</p>                        
                                                        </div>
                                                        <div class="d-flex custom_accordian_wd">
                                                            <Accordion defaultActiveKey={['1']} alwaysOpen>
                                                                {deploymentdata?.map((deploymentdata, index) => (
                                                                    activedeployment?.includes(deploymentdata.title) ? (
                                                                        <Accordion.Item id="item1" eventKey={index}>

                                                                            <Accordion.Header>{deploymentdata?.title}</Accordion.Header>
                                                                            <Accordion.Body id="accordian">
                                                                                {/* { )} */}
                                                                                {deploymentdata?.parameters?.map((parameters, index) => (                                                                                                                                                                      
                                                                                   <div class="mb-2">                                                                                    
                                                                                       <input type="text" class="form-control" id={(deploymentdata.title +parameters).replace(/ /g, "")} placeholder={parameters} onBlur={(e)=>{let obj={id:(deploymentdata.title +parameters).replace(/ /g, ""),value:e.target.value};setaiparam([...aiparam,obj])}}/>
                                                                                   </div>

                                                                                ))}
                                                                            </Accordion.Body>
                                                                        </Accordion.Item>
                                                                    ) : null
                                                                ))}
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class={wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&activedeployment?.length>0?"col-lg-3 border-start":"col-lg-3 border-start d-none"} id='SelectedNFR1' hidden={setdep1 === 1 ? current === 3 ? false : true : true}>
                                                    <div class="right_selectelements h-100 py-3 px-2 ">
                                                        <div class="d-flex flex-column">
                                                            <p className='font_14 font_regular'>Selected Deployment</p>                                            
                                                              </div>
                                                        <div class="d-flex custom_accordian_wd">
                                                            <Accordion defaultActiveKey={['1']} alwaysOpen>
                                                                {deploymentdata?.map((deploymentdata, index) => (
                                                                    activedeployment?.includes(deploymentdata.title) ? (
                                                                        <Accordion.Item eventKey={index}>

                                                                            <Accordion.Header>{deploymentdata?.title}</Accordion.Header>
                                                                            <Accordion.Body>
                                                                              
                                                                            {deploymentdata?.parameters?.map((parameters,index) => (
                                                                                   
                                                                                  
                                                                                   <div class="mb-2">
                                                                                    {/* {(nfrdata.title + parameters).replace(/ /g, "")} */}
                                                                                       <input type="text" class="form-control" id={(deploymentdata.title +parameters).replace(/ /g, "")} placeholder={parameters} onBlur={(e)=>{let obj={id:(deploymentdata.title +parameters).replace(/ /g, ""),value:e.target.value};setaiparam([...aiparam,obj])}}/>
                                                                                   </div>

                                                                               ))} 
                                                                            </Accordion.Body>




                                                                        </Accordion.Item>
                                                                    ) : null
                                                                ))}                                                    
                                                            </Accordion>
                                                        </div>
                                                    </div>
                                                </div>
                                                

                                                {/* figma rightside controls */}
                                               
                                                <div class="col-lg-3 border-start " id="figma" hidden={wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&current !== 3 ? true : !wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true&&vac === 1 &&current===3? false : true}>

                                                    <div class="right_selectelements h-100 py-3 px-2 ">
                                                        <div class="d-flex flex-column">
                                                            <p className='font_14 font_regular'>Figma</p>

                                                            <div class="mb-2">
                                                                <input type="email" class="form-control" id="figmatoken" value="figd_3Kc-2pKHetf4gj4i1yXwY24nuBTeLIE0lw2JlV1W" />
                                                            </div>
                                                            <div class="mb-2">
                                                                <input type="email" class="form-control" id="figmafile" />
                                                            </div>
                                                            <div class="mb-2">
                                                                <input type="checkbox" id="checkid" />Has header and footer
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                
                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* added progress screen of popup */}

                    </Modal.Body>
                    <Modal.Footer id="buttons" hidden={hiddenval === 1 || wizardSteps?.map(x => x.steps?.map(t => t.stepname))[0]?.includes("Add Dialogues")===true && current === 4 ? true :!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true && current === 5 && !wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Deployment")===true ?true:!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true && current === 6? true:false }>
                        <div class="" >
                            <Button variant="primary me-3" onClick={() => { handlePrevious(); if (current !== 4) { setmodalsize(0) }; if (count === 3 && activelayout === "Figma") { setmodalsize(1) } }} disabled={current == 0 ? true : false}>
                                Previous
                            </Button>
                            <Button variant="primary"
                                onClick={() => {
                                    handleSlide();
                                    if (current !== 4) { setmodalsize(0) };
                                    if (val === 1) {
                                        setMenuVisibile(1)
                                    }
                                    if (current === 0) { handlePostUserProjectData() };//each
                                    //if (current === 1) { handlePutUserProjectData() };//calling this method fron nextslide method
                                    // if (current === 2 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false) { handlePutThemeData() };//other calling this method fron nextslide method
                                    if (current === 2 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true) { handlePutPackageComponentsData() };//AI projects
                                    // if (current === 3 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false) { handlePutLayoutData() };//other calling this method fron nextslide method
                                    // if (current === 4 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false) { handlePutPackageComponentsData() };//other
                                   
                                    if (current === 3 && !wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues") === false)//AI projects
                                     {
                                        handlePutDialoguesData(inputFields)

                                    };
                                 }}
                                disabled={current == 7 ? true : false} >
                                Next
                            </Button>
                        </div>
                    </Modal.Footer>
                    <Modal.Footer id="buttons" hidden={current === 6 ? false : true}>
                        {/* <div class="" >
                        <a href = {repolink} download>
                        <Button id="downloadbtn" 
                                disabled={true} variant="primary me-3">
                            Download Code
                        </Button>
                        </a>
                        <Link to = "/explorer">
                        <Button id="explorebtn" disabled={true}  variant="primary" >
                            Enter Studio
                        </Button>
                        </Link>
                    </div> */}
                    </Modal.Footer>
                </Modal>
            </ToastProvider>
        </>
    )
}

export default UserProjectEntryPopup;
import { compress } from "compress-json";
import uuid from "react-uuid";
import {
  putUserProjectData,
  postCodeGeneration,
  postPageScreenComp,
} from "../../main/store/userProject.actions";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
export function Panels(props) {
  const editor = props.editor;
  var pagecount = 1;
  var projid = props.projid;
  var newpages = props.newpages;
  var dispatch = props.dispatch;
  var pages = props.pages;
  //

  editor.DomComponents.addType("table", {
    model: {
      defaults: {
        // other model properties
        toolbar: [
          {
            attributes: { class: "fa fa-arrows" },
            command: "tlb-move",
          },
          {
            attributes: { class: "fa fa-clone" },
            command: "tlb-clone",
          },
          {
            attributes: { class: "fa fa-arrow-up" },
            command: "tlb-arrowUp",
          },
          {
            attributes: { class: "fa fa-trash-o" },
            command: "tlb-delete",
          },
        ],
      },
    },
  });

  //search data
  editor.DomComponents.addType("search", {
    model: {
      defaults: {
        // other model properties
        toolbar: [
          {
            attributes: { class: 'fa fa-plus' },
            command: "frm-plus",
          },
          {
            attributes: { class: "fa fa-arrows" },
            command: "tlb-move",
          },
          {
            attributes: { class: "fa fa-clone" },
            command: "tlb-clone",
          },
          {
            attributes: { class: "fa fa-arrow-up" },
            command: "tlb-arrowUp",
          },
          {
            attributes: { class: "fa fa-trash-o" },
            command: "tlb-delete",
          },
        ],
      },
    },
  });

  //
  // editor.Pages.add({
  //     id: projid+"PROJ"+'default'
  //   })
  var temp = editor.Pages.getAll();
  // temp.shift();
  let arr = temp?.map((el, index) => {
    return el.id;
  });
  //
  //);

  var bindChild = arr?.map((option, index) => {
   // ), "")
    //     .replace("PROJ", "")
    // );
    return `<option value=${option}>${option
      .replace(option.substring(0, option.indexOf("PROJ")), "")
      .replace("PROJ", "")}</option>`;
  });
  var bindElment = `<select id='displayPage'>${bindChild}</select>`;
  //
  editor.Panels.addPanel({
    id: "gjs-pn-commands",
    el: ".gjs-pn-commands .gjs-pn-buttons",
    buttons: [
      {
        id:"loader",
        className: 'btn-page-button',
        label: `<div class="spinner-border text-primary" role="status"></div>
        `,
      },
      {
        id: "pages",
        className: "btn-page-button",
        label: bindElment,
      }
      
    ],
  });
  //);
  
  editor.Panels.addButton("options", {
    id: "comppopup",
    className: "fa fa-plus",
    attributes: {title: 'Insert Component'},
    label: "",
    command: {
      run: function (editor) {
        props.handleShow();
      },
    },
  });
  editor.Panels.addButton("options", {
    id: "getCode",
    className: "fa fa-code-fork",
    attributes: {title: 'Code Commit'},
    label: "",
    command: {
      run: function (editor) {
        props.codecommit();
      },
    },
  });
  editor.Panels.addButton("options", {
    id: "addPages",
    className: "fa fa-file-o",
    attributes: {title: 'Add Page'},
    command: {
      run: function (editor) {
        ;
        //
        props.handleShowPage()
      
      },
    },
  });
}
export function AddPages(props) {
  const editor = props.editor;  
  //

    ;
    //);
    editor.Pages.add({
      id: props.pageName
    });
    var temp = editor.Pages.getAll();
    let arr = temp?.map((el, index) => {
      var res;
      el.attributes.type === "main" ? (res = "home.js") : (res = el.id);
      return res;
    });
    
   
    var select = document.getElementById("displayPage");
    //
    select.options.length = 0;
    arr.map((element, index) => {
      select.add(new Option(element, element));
      if(element===props.pageName)
      select.selectedIndex = index
    });
    //.options);
    let showPage = editor.Pages.get(props.pageName);
    props.setCurrentPage(showPage);
    editor.Pages.select(showPage);  
    if(props.ProjectLayout!=="") {
      AddLayout(editor,props.ProjectLayout)
    }
    //
     
}

export async function PageSave(props){
  const {instance, accounts, inProgress} = useMsal();
      const{editor, version, dispatch, projid} = props;
      //.id)
      let currentPage = editor?.Pages?.getSelected();
      //
      currentPage.attributes.type === "main"? currentPage.id = "home.js":currentPage.id=currentPage.id;
      let currentPageName = currentPage.id;
      let component = currentPage.getMainComponent();
      let html = editor.getHtml({ component });
      let htmlObject = document.createElement('div');
      htmlObject.innerHTML = html;
      //)
      let allcomp = htmlObject?.querySelectorAll("[data-comp]")
      allcomp?.forEach((x,index)=>{
        html = html.replace(x.outerHTML,"");
        let compdata = {
          userprojectid:projid,
          componentid:uuid(),
          componenthtml:compress(x.outerHTML),
          page:currentPageName,
          componentversion:version
        }
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(postPageScreenComp({data:compdata,instance:instance}))
        if(index===allcomp.length-1){
          dispatch(postPageScreenComp({data:html,instance:instance}))
        }
      }
      })

      version++;
      //
                        
                         
}
export async function AddPagesDrropDown(props){
  const{pages} = props;


}
export function AddLayout(editor,layoutdata){
    // const {editor} = props;
    //
    editor.addComponents(layoutdata.html)
    editor.setStyle(layoutdata.css)
}
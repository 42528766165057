import { createSlice } from "@reduxjs/toolkit";
import { fetchControlData, fetchControlDetailData } from "./inputControl.actions";

const ControlTypeData = createSlice({
    name: "controlstore",
    initialState: {
        controlTypeData: { controldata: [], loading: true},
        controlDetailData: { controldetaildata: [], loading: true},
        currentcontrolTypeData : {
            controltype: {
                id: "",
                name:"",
            },
        },
        currentcontrolTypeDataCopy: {
            controltype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditControlTypeDataName: (state, actions) => {
            state.currentcontrolTypeData.controltype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchControlData.pending]: (state) => {
            state.controlTypeData.loading = false;
        },
        [fetchControlData.fulfilled]: (state,actions) => {
            state.controlTypeData.controldata = actions.payload?.controldata;
            state.controlTypeData.loading = actions.payload?.loading;
        },
        [fetchControlDetailData.pending]: (state) => {
            state.controlDetailData.loading = false;
        },
        [fetchControlDetailData.fulfilled]: (state,actions) => {
            state.controlDetailData.controldetaildata = actions.payload?.controldetaildata;
            state.controlDetailData.loading = actions.payload?.loading;
        },
    },
});

export const { setEditControlTypeDataName } = ControlTypeData.actions;
export default ControlTypeData.reducer;
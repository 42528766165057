import {useState, useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ReactComponent as Home_logo} from '.././images/home_logo.svg';
import { FaRegUserCircle } from 'react-icons/fa';
import { ReactComponent as Bell_icon } from '.././images/bell_icon.svg';
import { ReactComponent as User_icon } from '.././images/user_icon_static.svg'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link ,Outlet} from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-common';
import {loginRequest} from "../../authConfig";
import Axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {Person} from "react-bootstrap-icons";
import { Modal } from "react-bootstrap";
import Left_mask from '.././images/left_mask.png';

const { REACT_APP_MARKETPLACE_URL } = process.env;


function LoginPopup() {
    const dispatch = useDispatch();
    const [isActive,setActive]=useState("");
    const [imageUrl, setImageUrl] = useState(null)
    const [show, setShow] = useState(true); 
    const handleClose = () => setShow(false);
  
    // Azure B2C
  
    const {instance, accounts, inProgress} = useMsal();
    const isAuthAuthenticated =  useIsAuthenticated();
    const [accessToken, setAccessToken] = useState(null);
    const name = accounts[0] && accounts[0].name;
    const userFirstName = name && name.charAt(0);
    const userLastName = name && name.substring(name.lastIndexOf(' ')+1).charAt(0);
    const randomColor = name && name.split(' ').join('').length%10;
    const useremail = accounts[0] && accounts[0].username;
    const homeAccountID = accounts[0] && accounts[0].homeAccountId;
    const userDomain = useremail?.substring(useremail.indexOf("@")+1,useremail.indexOf(".com"))
  
    useEffect(() => {
      if(!isAuthAuthenticated) {
        const request = {
          scopes: loginRequest.scopes,
          account: useremail,
        };      
      }
    }, [])
  
    useEffect(() => {
      if (accounts.length > 0) {
        RequestAccessToken()
        .then((res)=>{
          setAccessToken(res);
        })
        .catch(err=>{
          //
        })
      }    
      if(accessToken !== null) {
        let api = process.env.REACT_APP_BASE_URL + "/auth/microsoft/callback?access_token="+accessToken.replace(/^Bearer\s/, '')
        Axios.get(api).then(o => {
        localStorage.setItem("apiJWTToken",o.data.jwt)
      })
  
      Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { 'Authorization': accessToken },
        responseType: 'blob',
      }).then(o => {
        const blobUrl = URL.createObjectURL(o.data);
        setImageUrl(blobUrl)
      })
    }
    }, [accessToken, accounts])
  
  
    localStorage.setItem("userDomain", userDomain);
  
  
  //
    function handleLogin(instance) {
      instance.loginRedirect(loginRequest).catch(e => {
        console.error("checkerror1",e);
      })
    }  
  
    function handleLogout(instance) {
      localStorage.setItem("apiJWTToken","")
      localStorage.setItem('myprofilepopup',"")
      localStorage.setItem('myProfileLoggedInID',"")
      const currentAccount = instance.getAccountByHomeId(homeAccountID);
      const logoutHint = currentAccount.idTokenClaims.login_hint;
      instance.logoutRedirect({logoutHint: logoutHint}).catch(e => {
        //console.error(e);
      });
    }
  
    async function RequestAccessToken() {
      const request = {
        scopes: loginRequest.scopes,
        account: accounts[0],
      };
      // Silently acquires an access token which is then attached to a request for Microsoft Graph data
      const accessTokenResponse = await instance.acquireTokenSilent(request);
      return `Bearer ${accessTokenResponse.accessToken}`;
    }  
  return (
    <>
      <Modal className='mt-4 start_modal' show={show}  backdrop="static"
                keyboard={false} centered scrollable dialogClassName="modal-75w" id="label" hidden={false}>

                <Modal.Header closeButton>

                </Modal.Header>
                <Modal.Body className='p-0'>

                    <div class="row g-0 h-100 align-items-center">
                        <div class="col-lg-6">
                            <img className='img-fluid' src={Left_mask}></img>
                        </div>
                        <div class="col-lg-6 text-center px-5 justify-content-center">
                            <h4 className='font_36 font_light mb-4'>Welcome to</h4>
                            <h2 className='font_36 font_medium text_blue_color mb-5 px-4'>BitCraft Studio</h2>
                            <p className='font_15 font_light'>By clicking “Get Started” you agree to these terms and conditions and allow BitCraft Studio to get your user and tenant details.</p>
                            <p className='font_16 font_regular text_blue_color'>
                            <Nav.Link target="_blank" href={REACT_APP_MARKETPLACE_URL}>
                                BitCraft Privacy Statement
                            </Nav.Link></p>
                            <button class="btn btn-primary" onClick={() => handleLogin(instance)}>Get Started</button>
                        </div>
                    </div>
                </Modal.Body>
            </Modal>
    </>
  )
}

export default LoginPopup

import React, { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';
import myPlugin from 'grapesjs-blocks-basic';
import webPlugin from 'grapesjs-preset-webpage';
import gjsForms from 'grapesjs-plugin-forms';
import gjsNavbar from 'grapesjs-navbar';
import axios from "axios";
import { addComponents } from '../pageConfig/addComponents';
import { Panels } from '../pageConfig/panels';
import { useDispatch, useSelector } from 'react-redux';
// import {putUserProjectData , postCodeGeneration} from '../../main/store/userProject.actions';
import {postComponentData} from '../../main/store/userProject.actions';
import { fetchAllDataSource } from '../../main/store/dropArea.actions';
import ModalPopup from '../pageConfig/ModalPopup';
import uuid from 'react-uuid';
import Modal from 'react-bootstrap/Modal';
import Button from 'react-bootstrap/Button';
import OutlinedInput from '@mui/material/OutlinedInput';
import { compress, decompress } from 'compress-json'
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

var url = process.env.REACT_APP_BASE_URL;
let status=[],dropData = []
var lists , getPath,GetDatasourceList,ID, secondSelect="",postDataVar 
var setRes = [], mainResList = [], dropdownKeys = [], inputArray = []
var putDataList ={},postDataList={}

var socket = undefined;
let ds =``;
let modalData = '';
var imagesArr;
var testArr=[]
var divchildren = "";
var csspage="";
function ComponentStudio(props) {
  const dispatch = useDispatch();
  const {instance, accounts, inProgress} = useMsal();
  const remoteData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails[0]?.screencomponents);
   useEffect(() => {
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch(fetchAllDataSource({data:null, instance:instance}))
    }
  }, [dispatch,instance.getAllAccounts()[0] !== undefined])
  const datasource = useSelector((state) => state.commonReducer.droparea.datasource.datasource);
  //

  //useEffect for datasource post/put popup
  useEffect(() => {
    lists = datasource && datasource.filter(x => x.httpverb?.toLowerCase() === "post" || x.httpverb?.toLowerCase() === "put").map((data, index) => {
      return `<li data-attr="${data.url}" data-gjs-type="PopUpli" data-verb="${data.httpverb}">${data.datasourcename}</li>`
    })
  }, [datasource])

   //useEffect for datasource get popup
   useEffect(() => {
    GetDatasourceList = datasource && datasource.filter(x => x.httpverb?.toLowerCase() === "get" ).map((data, index) => {
      return `<li data-attr="${data.url}" data-gjs-type="DropdownGetPopup" data-verb="${data.httpverb}">${data.datasourcename}</li>`
    })
  }, [datasource])

  //useEffect for imagess get popup
  useEffect(() => {
    GetDatasourceList = datasource && datasource.filter(x => x.httpverb?.toLowerCase() === "get" ).map((data, index) => {
      return `<li data-img-attr="${data.url}" data-gjs-type="ImagesGetPopup" data-verb="${data.httpverb}">${data.datasourcename}</li>`
    })
  }, [datasource])

  var  firstArr = []
 
  const [modalInfo, setModalInfo] = useState([]);	
  const [modalShow, setModalShow] = useState(false);  
 
    const [editor, setEditor] = useState(null);
    const[ popupshow, setShowPopup] = useState(false)
    const [currentPage, setCurrentPage] = useState();
    let [Responsee, setResponsee] = useState([])
    const [popupInfo, setPopupInfo] = useState();
    const [pages, setPages] = useState([]);
    const [getDataSource, setDataSource] =  useState([]);

    useEffect(() => {
      if(datasource?.length > 0) {
        // setDataSource(datasource);
        addComponents({editor: editor, handleModal: handleModal, appendModalData: appendModalData, datasource: datasource,dispatch:dispatch});
      }
    }, [datasource])

  //input controls
  const [insertControlsData, setInsertControlsData] = useState([
    {
      "id": 1,
      "name": "Button"
    },

    {
      "id": 2,
      "name": "Text Input"
    },

    {
      "id": 3,
      "name": "Drop down"
    },

    {
      "id": 4,
      "name": "Date Picker"
    },

    {
      "id": 5,
      "name": "Check Box"
    }

  ]);
    const [newpages, setNewPages] = useState([]);
    const [modalBtnId, setModalBtnId] = useState();
    const [appendData, setModalData] = useState('');
    const [componentName, setComponentName] = useState('');
    const [description, setDescription] = useState('');
    const [compData, setCompData] =  useState('');
    
    //Map Data will be replaced later by Data Source
    var s="Dentel AI"
    s=  encodeURI(s)

    var pagecount = 1;
    function rgbToHex(r, g, b) {
      var r = r*255
      var g = g*255
      var b = b*255
      const color = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
    
      if (color.length > 7) {    
        return color.slice(0, 7);
      }
      return color;
    };
    function rgbaToHex (r,g,b,a) {
      var outParts = [
        r.toString(16),
        g.toString(16),
        b.toString(16),
        Math.round(a * 255).toString(16).substring(0, 2)
      ];
    
      // Pad single-digit output values
      outParts.forEach(function (part, i) {
        if (part.length === 1) {
          outParts[i] = '0' + part;
        }
      })
    
      return ('#' + outParts.join(''));
    }
    const getStyles=(element,parent)=>{
      var css = "";
      var id = "g"+element.id.replace(":","");
      css = `#`+id+`{`;
      css  += `height:`+element.size.y+`px;`
      css  +=  `width:`+element.size.x+`px;`
      var fills = element.fills;
      css  += element.type!=="TEXT" && element.type!=="VECTOR" && fills.length>0 && fills[0].type === "SOLID"?
      `background:`+rgbToHex(fills[0].color.r,fills[0].color.g,fills[0].color.b,fills[0].color.a)+`;`: ""
      css  += element.type==="TEXT" && fills.length>0 && fills[0].type === "SOLID"?
      `color:`+rgbToHex(fills[0].color.r,fills[0].color.g,fills[0].color.b,fills[0].color.a)+`;`: ""
      //css += `border-style: dotted;`
      css += `position: absolute;`
     
      var posx = parent!==undefined? parent.relativeTransform[0][2]-element.relativeTransform[0][2]:element.relativeTransform[0][2];
      var posy = parent!==undefined? parent.relativeTransform[1][2]-element.relativeTransform[1][2]:element.relativeTransform[1][2];
      css += element.id.includes("i")? "":`transform: translateX(`+posx+`px) translateY(`+posy+`px);`
      var dum;
      if(element?.strokes?.length>0){
        //
        dum = `border:`;
        dum += element.strokeWeight +"px "
       // 
        dum += element.strokes[0].type + " ";
        //
        dum += rgbToHex(element.strokes[0].color.r,element.strokes[0].color.g,element.strokes[0].color.b,element.strokes[0].color.a)
        //
      }
      css += dum
      css  += '}'
      csspage += css;
      //

    }

    useEffect(() => {
        var editor = grapesjs.init({
                container: '#componentStudio',
                showOffsets: 1,
                noticeOnUnload: 0,
                keepUnusedStyles: true,               
                styleManager : {
                    sectors: [{
                        name: 'General',
                        open: false,
                        buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
                      },{
                        name: 'Flex',
                        open: false,
                        buildProps: ['flex-direction', 'flex-wrap', 'justify-content', 'align-items', 'align-content', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self']
                      },{
                        name: 'Dimension',
                        open: false,
                        buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
                      },{
                        name: 'Typography',
                        open: false,
                        buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-shadow'],
                      },{
                        name: 'Decorations',
                        open: false,
                        buildProps: ['border-radius-c', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
                      },{
                        name: 'Extra',
                        open: false,
                        buildProps: ['transition', 'perspective', 'transform'],
                      }
                    ],
                  },                         
                width: '100%',
                plugins: [myPlugin, webPlugin, gjsForms, gjsNavbar],
                dragMode: 'translate',
                canvas: {
                  scripts: ['https://code.jquery.com/jquery-3.5.1.js', 'https://cdn.datatables.net/1.13.4/js/jquery.dataTables.min.js', 'https://cdn.datatables.net/1.13.4/js/dataTables.jqueryui.min.js', 'https://cdn.datatables.net/scroller/2.1.1/js/dataTables.scroller.min.js','https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js'],
                  styles: ['https://code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css', 'https://cdn.datatables.net/1.13.4/css/dataTables.jqueryui.min.css', 'https://cdn.datatables.net/scroller/2.1.1/css/scroller.jqueryui.min.css',                      'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css'
                ],
                },              
                storageManager: {
                  id: 'gjs-',
                  type: 'remote',
                //   autosave: true,
                  storeComponents: true,
                  storeStyles: true,
                  storeHtml: true,
                  storeCss: true,
                  storeAssets: true,
                  options: {
                    remote: {
                      urlStore: ``,
                      urlLoad: ``,
                      onStore: (data, editor) => {
                        const getComp = editor.Pages.getSelected();
                        let component = getComp.getMainComponent();
                        let htmlTemp = editor.getHtml({ component });
                        htmlTemp = htmlTemp.replace('<body>','<div data-comp>')
                        htmlTemp = htmlTemp.replace('</bod>','</div>')
                        //
                        let x = <div data-comp>{htmlTemp}</div>
                        //)
                        let componentData = {
                            componenthtml: compress(htmlTemp),
                            componentcss: compress(editor.getCss({ component })),
                            createdby:accounts?.[0]?.username
                        }      
                        //
                        setCompData(componentData);
                        // componentname: componentName,
                        // componentdesc: description,

                        //   screencomponents:allPages.map((p,index) =>{
                        //     p.attributes.type === "main"? p.id = "sample.js":p.id=p.id;
                        //     newpages.push(p.id)
                        //     //setNewPages([...newpages,p.id.replace(p.id.substring(0,p.id.indexOf("PROJ")),"").replace("PROJ","")])
                        //     
                        //     let component = p.getMainComponent();
                        //     let appendData = editor.getHtml({ component }) + modalData;
                        //     
                        //     return {
                        //       //_id:data.replace("?_id=",""),
                        //       scid:uuid(),
                        //       filename: p.id,
                        //       filepath:"src/"+p.id,
                        //       html : compress(appendData),
                        //       css: index===allPages.length-1?compress(editor.getCss({ component })) : ""
                        //   }
                        //    } )
                    //   }
                    //            
                      
                      // const pagesHtml = editor.Pages.getAll().map(page => {
                      //     const component = page.getMainComponent();
                      //     return {
                      //       html: editor.getHtml({ component }),
                      //       css: editor.getCss({ component })
                      //     }
                      //      } )

                      // }
                      // 
                      // dispatch( putUserProjectData(data1) ).then((res)=>{
                      //   
                      // })         
                      
                      // // const pagesHtml = editor.Pages.getAll().map(page => {
                      // //     const component = page.getMainComponent();
                      // //     return {
                      // //       html: editor.getHtml({ component }),
                      // //       css: editor.getCss({ component })
                      // //     }
                      // //   });
                      //   //return { id: projid, data, pagesHtml };
                      },
                      // If on load, you're returning the same JSON from above...
                      onLoad: result => result.data,
                    }
                  },                  
                },                
              })
            setEditor(editor);            
            addComponents({editor: editor, handleModal: handleModal, appendModalData: appendModalData, datasource: getDataSource,dispatch:dispatch});
            Panels({editor:editor, dispatch:dispatch});              
            let getbutton = editor.Panels.getButton('options', 'sw-visibility');
            getbutton.set('active', 1);
            editor.Panels.addButton("options", {
                id: "saveComp",
                className: "fa fa-floppy-o",
                attributes: {title: 'Save Component'},
                label: "",
                command: {
                  run: function (editor) {
                      setModalShow(true);
                  },
                },
              });            
          const domc = editor.DomComponents;
          domc.addType('map', {
            // Update the model, if you need
            model: {
              defaults: {
                tagName: 'iframe', // Overrides the old one
                someNewProp: '',
                type: 'map',
                src: '',
                void: false,
                mapUrl: 'https://maps.google.com/maps',
                tagName: 'iframe',
                mapType: 'q',
                address:  s,
                zoom: '5',
                attributes: { frameborder: 0 },
              },              
            },
          });

        //Add type for form
           editor.DomComponents.addType('testlabel', {
            model: {
              defaults: {
                // other model properties
                
                toolbar: [{
                  attributes: { class: 'fa fa-plus' },
                  command: 'frm-plus',
                },
                {
                  attributes: { class: 'fa fa-clone' },
                  command: 'frm-clone',
                },
                {
                  attributes: { class: 'fa fa-arrow-up' },
                  command: 'frm-arrowUp',
                },
                {
                  attributes: { class: 'fa fa-trash-o' },
                  command: 'tlb-delete',
                },
                ],
              }
            }
          })

           //append li to ul for datasource popup
    editor.Commands.add('frm-plus', {
      run(editor) {
        editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${lists.toString().replaceAll(',', '')}</ul></div>`);
        editor.Css.setRule('.tableWrapper ul', {
          'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
          'list-style': 'none',
          'font-size': '16px',
          'border-radius': '5px',
          'height': '200px',
          'overflow-y': 'auto'
        })
      }
    })
    // Adding style to table and popover
    editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
    editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
    editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })

   

  //Render form onclick of popup datasource
  editor.DomComponents.addType('PopUpli', {
    isComponent: el => el.tagName === 'LI',
    model: {
      defaults: {
        tagName: 'li',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view: {
      events: {
        'click': e => {
          editor.Css.setRule('.tableWrapper ul', {
            'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
            'list-style': 'none',
            'font-size': '16px',
            'border-radius': '5px',
            'height': '200px',
            'overflow-y': 'auto'
          })
          dropdownKeys = []
           getPath = e.target.getAttribute('data-attr');
          let getVerb = e.target.getAttribute('data-verb')
          if (getVerb === "Post") {
            const res = axios(getPath).then((res) => {
              setRes = res.data
              mainResList = [{ setRes }]
              for (var key in mainResList[0]?.setRes[0]) {
                if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                  dropdownKeys.push(key)
                }
              }
            }).then((res) => {
              var returnVar = "";
                dropdownKeys.filter(x => !x.includes("id")).forEach(key => {
                  var temp = uuid();
                  var obj = {id:temp,key:key}
                  inputArray.push(obj)
                 returnVar += `<div class="mb-3 col-lg-12">
                      <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                      <div class="d-flex align-items-center" data-gjs-type="defaultPostControl" style="display:flex;flex-direction:row;" id=${key}>
                        <input type='text' placeholder=${key} id=${temp} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key}  ></input>
                        <div data-gjs-type="editBtn" style="margin-left:15px;"  ><button data-gjs-type="buttonEdit">Edit</button></div>
                      </div>
                    </div>`
                  
                })
                var mainelem;
                if(returnVar !== undefined) {
              mainelem = `<div >
              ${returnVar}              
                <button type='button' data-gjs-type="PostsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>            
                </div>`
            }
              var parents = editor.getSelected().parents();
              parents[2].components(mainelem)
            })
          } else {
            const res = axios.get(getPath).then((res) => {
              Responsee.push(res.data)
              for (var key in Responsee[0]) {
                if (Responsee[0]?.hasOwnProperty(key)) {
                  dropdownKeys.push(key)
                }
              }
            }).then((res) => {
              var returnVar = "";
                  {Object.entries(Responsee[0]).filter(x => !x.includes("id")).map(([key, value]) => {
                    var temp = uuid();
                    var obj = { id: temp, key: key, value: value }
                    inputArray.push(obj)
                    returnVar +=   `<div class="mb-3 col-lg-12">
                                        <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                                        <div class="d-flex align-items-center" style="display:flex;flex-direction:row;" id=${key}>
                                            <input type='text' placeholder=${key} id=${temp} data-name=${key} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key} value=${value} ></input>                        
                                         <div data-gjs-type="editBtn" style="margin-left:15px;"> <button >Edit</button></div>
                                        </div>                  
                                    </div>`                     
                  })}
                  var mainelem;
                  if(returnVar !== undefined){
                    mainelem = `<div >
              ${returnVar}
              
                <button type='button' data-gjs-type="PutsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>
             
                </div>`
                  }
              var parents = editor.getSelected().parents();
              parents[2].components(mainelem);
            });
            
          }
        }
      }

    }
  });

  //Open insertcontrols popup onclick of Edit button
  editor.DomComponents.addType('editBtn', {
    isComponent: el => el.tagName === 'div',
    model: {
      defaults: {
        tagName: 'div',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view: {
      events: {
        'click': e => {
          editor.Css.setRule('.tableWrapper ul', {
            'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
            'list-style': 'none',
            'font-size': '16px',
            'border-radius': '5px',
            'height': '200px',
            'overflow-y': 'auto'
          })
          var idd = uuid()
          var dropDownList =
          <div class="tableWrapper">
            <ul >
              {insertControlsData.map((item, index) => (
                <li data-gjs-type="DropLi" data-attr={item.name} data-id={idd}>{item.name}</li>
              ))}
            </ul>
            </div>
           editor.Css.setRule('.tableWrapper ul', {
            'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
            'list-style': 'none',
            'font-size': '16px',
            'border-radius': '5px',
            'height': '200px',
            'overflow-y': 'auto'
          })
          var editList = editor.getWrapper().find("#" + e.target.id)[0];
          editList.append(dropDownList)
        }
      }
    }
  })

  //change input control from insertcontrols popup
  editor.DomComponents.addType('DropLi', {
    isComponent: el => el.tagName === 'li',
    model: {
      defaults: {
        tagName: 'li',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view: {
      events: {
        'click': e => {
           ID = e.target.getAttribute('data-id')
          let getID = e.target.getAttribute('data-attr')
          if (getID === "Button") {
            var newelem = (
              <div class="mb-3 col-lg-12">
                <div class="d-flex align-items-center" style="display:flex;flex-direction:row;">
                  <button
                    className="btn btn-primary"
                    id={ID}
                  >
                    Primary
                  </button>
                  <div data-gjs-type="editBtn" style="margin-left:15px" ><button>Edit</button></div>
                </div>
              </div>
            )
          }
          else if (getID == "Text Input") {
            var newelem = (<div class="mb-3 col-lg-12">
              <div class="d-flex align-items-center" data-gjs-type="inputChange" style="display:flex;flex-direction:row;">
                <input
                  type="text"
                  id={ID}
                  style="flex:1;margin-bottom:15px"
                  class="form-control flex-fill me-3"
                  name="abc"
                  placeholder="text input" >
                </input>
                <div data-gjs-type="editBtn" style="margin-left:15px;" ><button>Edit</button></div>
              </div>
            </div>)
          }
          else if (getID == "Drop down") {
            var newelem = (`<div style="flex:1">
              <div style="display:flex;flex-direction:row;">        
                <select class="form-select mt-3" id=${ID} data-gjs-type="selectOptins" style="flex:1;margin-bottom:15px"  >
                <option selected>Select any option</option>
                </select>
                <div data-gjs-type="FirstDropdownBtn" class="fa fa-edit">+</div>
                <div data-gjs-type="editBtn" style="margin-left:15px" ><button>Edit</button></div>
              </div>
            </div>`)
          }
          else if (getID == "Date Picker") {
            var newelem = (<div class="mb-3 col-lg-12">
              <div class="d-flex align-items-center" style="display:flex;flex-direction:row;">
                <input
                  id={ID}
                  type="date"
                  class="form-control flex-fill me-3"
                  name="abcd"
                  style="flex:1;margin-bottom:15px"
                  placeholder="date picker"
                />
                <div data-gjs-type="editBtn" style="margin-left:15px;" ><button>Edit</button></div>
              </div>
            </div>)
          }
          else if (getID == "Check Box") {
            var newelem = (<div class="mb-3 col-lg-12">
              <div class="d-flex align-items-center" style="display:flex;flex-direction:row;">
                <input
                  class="form-check-input"
                  type="checkbox"
                  id={ID}
                  placeholder="checkbox"
                  name="checkbox"
                  style={{ height: "1.5rem", width: "1.5rem" }}
                >
                </input>
                <div data-gjs-type="editBtn" style="margin-left:15px;" ><button>Edit</button></div>
              </div>
            </div>
            )
          }
          var parents = editor.getSelected().parents()
          parents[1].remove()
          parents[2].append(newelem,{at:1})
        }
      }
    }
  })

  //on click of plus button in dropdown open popup 
  editor.DomComponents.addType('FirstDropdownBtn',{
    isComponent: el => el.tagName === 'div',
    model:{
      defaults: {
        tagName: 'div',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view:{
      events:{
        'click':e=>{
         var droplists= editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${GetDatasourceList.toString().replaceAll(',', '')}</ul></div>`);
          editor.Css.setRule('.tableWrapper ul', {
            'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
            'list-style': 'none',
            'font-size': '16px',
            'border-radius': '5px',
            'height': '200px',
            'overflow-y': 'auto'
          })
      var editList = editor.getWrapper().find("#" + e.target.id)[0];
            editList.append(droplists)
        }
      }
    }
  })

  //onclick of get datasource popup li show 1st select dropdown
  editor.DomComponents.addType('DropdownGetPopup',{
    isComponent: el => el.tagName === 'LI',
    model: {
      defaults: {
        tagName: 'li',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view:{
      events:{
        'click':e => {
          dropdownKeys = []
          var dataUrl = e.target.getAttribute('data-attr')
          const res = axios(dataUrl).then((res)=>{
            setRes = res.data
            mainResList = [{setRes}]
            for (var keyss in mainResList[0]?.setRes[0]){
              if (mainResList[0]?.setRes[0]?.hasOwnProperty(keyss)) {
                  dropdownKeys.push(keyss)
              }  
          }

      var newelem = (`<div style="flex:1">
      <div style="display:flex;flex-direction:row;">        
        <select class="form-select mt-3"  data-gjs-type="AttributesList" style="flex:1;margin-bottom:15px;margin-right:15px"  >
        <option selected >Select any attribute</option>
        </select>
      </div>
      
          </div>`
      
     
    )
    var parentss = editor.getSelected().parents()
    parentss[3].components(newelem)
          })
          

        }
      }
    }
  })

  //onclick of 1st select bind attributes in dropdown
  editor.DomComponents.addType('AttributesList',{
    isComponent: el => el.tagName === 'div',
    model:{
      defaults: {
        tagName: 'div',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
      }
    },
    view:{
      events:{
        'click': e=>{
           dropData = []
        var optionList = editor.getWrapper().find("#"+e.target.id)[0]
          optionList.append(dropdownKeys.map((item,index)=>(
            `<option data-gjs-type="AttributeValues">${item}</option>
            `
          )))

            dropdownKeys = []
            var attributeVal = []
            attributeVal=  e.target.value
            setRes.map((key)=>{
              dropData.push({"selectedAttribute":key[attributeVal],"id":key.id})
           })
        secondSelect =  `<div style="flex:1">
       <div style="display:flex;flex-direction:row;">        
         <select class="form-select mt-3" data-gjs-type="AttributesValues"  style="flex:1;margin-bottom:15px"  >
         <option selected >Select any Value</option>
         </select>
       </div>
       </div>`
       editor.getSelected().parent().append(secondSelect, {at: 1});
        }
      },
    }
  })

//Bind attribute values in 2nd dropdown
editor.DomComponents.addType('AttributesValues',{
  isComponent: el => el.tagName === 'div',
  model:{
    defaults: {
      tagName: 'div',
      draggable: false,
      removable: false,
      droppable: false,
      resizable: false,
      copyable: false,
    }
  },
  view: {
    events:{
      'click':e => {
        var optionList = editor.getWrapper().find("#"+e.target.id)[0]
        optionList.append(dropData.map((item,index)=>(
          `<option>${item.selectedAttribute}</option>
          `
        ))) 
            
      }
    }
  }
})

//Post call on click of submit button
editor.DomComponents.addType('PostsubmitBtn',{
  isComponent: el => el.tagName === 'button',
  model:{
    defaults:{
      tagName: 'button',
      draggable: false,
      removable: false,
      droppable: false,
      resizable: false,
      copyable: false,
    }
  },
  view:{
    events:{
      'click':e=>{
        postDataList ={}
        var tempp = e.target.parentElement
       var tagnamee =  tempp.getElementsByTagName('input')
       var arrTag = Array.from(tagnamee)
        arrTag && arrTag.length>0 && arrTag?.map(element => {
          postDataList={
            ...postDataList,
            [element.name ] : element.value
        }
    }) 
       const res = axios.post(getPath,{
          body : postDataList
       }).then((res)=>{
        if(res.status === 201){
          alert("Form submitted successfully")
        arrTag && arrTag.length>0 && arrTag?.map(element => {
          element.value = ""
        })
        }
        
       })
      }
    }
  }
})

//Put call on click of submit button
editor.DomComponents.addType('PutsubmitBtn',{
  isComponent: el => el.tagName === 'button',
  model:{
    defaults:{
      tagName: 'button',
      draggable: false,
      removable: false,
      droppable: false,
      resizable: false,
      copyable: false,
    }
  },
  view:{
    events:{
      'click':e=>{
        var tempp = e.target.parentElement
       var tagnamee =  tempp.getElementsByTagName('input')
       var arrTag = Array.from(tagnamee)
        arrTag && arrTag.length>0 && arrTag?.map(element => {
          putDataList={
            ...putDataList,
            [element.name ] : element.value
        }
    }) 
       const res = axios.put(getPath,{
          body : putDataList
       }).then((res)=>{
        if(res.status === 200){
          alert("Form updated successfully")

        }
        
       })
      }
    }
  }
})


//Add type for figma div
editor.DomComponents.addType('figmalabel', {
  model: {
    defaults: {
      // other model properties
      
      toolbar: [{
        attributes: { class: 'fa fa-plus' },
        command: 'div-plus',
      },
      {
        attributes: { class: 'fa fa-clone' },
        command: 'frm-clone',
      },
      {
        attributes: { class: 'fa fa-arrow-up' },
        command: 'frm-arrowUp',
      },
      {
        attributes: { class: 'fa fa-trash-o' },
        command: 'tlb-delete',
      },
      ],
    }
  }
})

//open datasource popup on click of plus
editor.Commands.add('div-plus', {
  run(editor) {
    editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${GetDatasourceList.toString().replaceAll(',', '')}</ul></div>`);
    editor.Css.setRule('.tableWrapper ul', {
      'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
      'list-style': 'none',
      'font-size': '16px',
      'border-radius': '5px',
      'height': '200px',
      'overflow-y': 'auto'
    })
  }
})
// Adding style to table and popover
editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })

//onclick of image datasource render images
editor.DomComponents.addType('ImagesGetPopup',{
  isComponent: el => el.tagName === 'LI' ,
  model:{
    defaults:{
      tagName: 'li',
        draggable: false,
        removable: false,
        droppable: false,
        resizable: false,
        copyable: false,
    }
  },
  view:{
    events:{
      'click':e=>{
        var tempArr=[]
        var imageURL = e.target.getAttribute('data-img-attr')
        const res = axios.get(imageURL).then((res)=>{
          for(var i=0;i<4;i++){          
               tempArr.push(res.data[i])
            }        
        }).then((res)=>{
          var renderList="";
           renderList =    
              `<div style="text-align: center;display:grid;
              grid-template-columns: 200px 200px; ">
             
              <div style=" width: 150px; height: 100px;float:left;
              clear:none">
                <div><img src=${tempArr[0].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
                <div>${tempArr[0].title}</div>
              </div>

              <div style=" width: 150px; height: 100px;float:left;
              clear:none">
                <div><img src=${tempArr[1].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
                <div>${tempArr[1].title}</div>
              </div>

              <div style=" width: 150px; height: 200px;float:left;
              clear:none">
                <div><img src=${tempArr[2].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
                <div>${tempArr[2].title}</div>
              </div>

              <div style=" width: 150px; height: 200px;float:left;
              clear:none">
                <div><img src=${tempArr[3].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
                <div>${tempArr[3].title}</div>
              </div>

              </div>`
          var parentss = editor.getSelected().parents();
          parentss[3].components(renderList)
        })
       

      }
    }
  }

})
          
    },[])
    useEffect(() => {
      if(editor !== null && Object.keys(editor).length > 0) {
        //
      editor.Css.setRule("button[data-gjs-type='button']", {
          'background': 'rgb(7 58 120)!important',
          'color': '#fff',
          'border': '1px solid #ccc',
          'padding': '5px 15px',
          'margin': '8px 8px',
        })
        editor.Css.setRule("button[data-gjs-type='modalButton']", {
          'background': 'rgb(7 58 120)!important',
          'color': '#fff',
          'border': '1px solid #ccc',
          'padding': '5px 15px',
          'margin': '8px 8px',
        })  
        editor.Css.setRule("button[data-gjs-type='SearchButton']", {
          'background': 'rgb(7 58 120)!important',
          'color': '#fff',
          'border': '1px solid #ccc',
          'padding': '5px 15px',
          'margin': '8px 8px',
          'display':'inline-block',
          'width':'150px',
        })
        editor.Css.setRule("select[data-gjs-type='select']",{
          'background-color': 'transparent',
          'color': '#000',
          'border': '1px solid #ccc',
          'padding': '5px 15px 5px 5px',
          'margin': '8px 8px',
          'min-width':'200px',
        })
        editor.Css.setRule("input[data-gjs-type='input']",{
          'background-color': 'transparent',
          'color': '#000',
          'border': '1px solid #ccc',
          'padding': '5px 15px 5px 5px',
          'margin': '8px 8px',
        })
        editor.Css.setRule("input[data-gjs-type='SearchInput']",{
          'background-color': 'transparent',
          'color': '#000',
          'border': '1px solid #ccc',
          'padding': '5px 15px 5px 5px',
          'margin': '8px 8px',
      })
        editor.Css.setRule("textarea[data-gjs-type='textarea']",{
          'background-color': 'transparent',
          'color': '#000',
          'border': '1px solid #ccc',
          'padding': '5px 15px 5px 5px',
          'margin': '8px 8px',
          'min-height':'70px',
          'min-width':'200px',
        })
        editor.Css.setRule("label[data-gjs-type='label']",{
          
          'color': '#000',
          'padding': '5px 15px 5px 5px',
          'margin': '8px 8px 8px 0px',
          'min-width': '150px',
          'font-size': '13px',
          'font-weight': 'bold',
        })
        editor.Css.setRule("input[data-gjs-type='checkbox']",{
          'border': '1px solid #ccc',
          'width': '15px',
          'height': '15px',
        })
        editor.Css.setRule("input[data-gjs-type='radio']",{
          'border': '1px solid #ccc',
          'width': '15px',
          'height': '15px',
        })
        editor.Css.setRule("blockquote[data-gjs-type='text']",{
          'background-color': 'rgb(238, 245, 249)',
          'margin': '8px',
          'padding': '10px',
        })
        
        editor.Css.setRule(".bdg-sect",{
          'background-color': 'rgb(238, 245, 249)',
          'margin': '8px',
          'padding': '10px',
        })
        editor.Css.setRule(".bdg-sect .heading",{
          'margin': '0px',
          'padding': '5px 10px',
          'font-size':'18px',
          'font-weight':'bold',
        })
        editor.Css.setRule(".bdg-sect .paragraph",{
          'margin': '0px',
          'padding': '5px 10px',
          'font-size':'14px',
          'font-weight':'regular',
        })
        editor.Css.setRule("[data-gjs-type='navbar'].navbar",{
          'background': 'rgb(7 58 120)!important',
        })
        editor.Css.setRule("form[data-gjs-type='form'] [data-gjs-type='default']",{
          'display': 'flex',
          'align-items': 'center',
        })
        editor.Css.setRule("form[data-gjs-type='form'] [data-gjs-type='label']",{
          'max-width': '250px!important',
        })
        editor.Css.setRule(".wrapper_section [data-gjs-type='input']", {
          'background-color': 'transparent',
          'color': '#000',
          'border': '1px solid #ccc',
          'padding': '5px 15px 5px 5px',
          'margin': '8px 8px',
        })
          editor.Css.setRule("button[data-gjs-type='CreateNewEntry']", {
          'background': 'rgb(7 58 120)!important',
          'color': '#fff',
          'border': '1px solid #ccc',
          'padding': '5px 15px',
          'margin': '8px 8px',
        })
        editor.Css.setRule("button[data-gjs-type='TableEditButton']", {
          'background': 'rgb(7 58 120)!important',
          'color': '#fff',
          'border': '1px solid #ccc',
          'padding': '5px 15px',
          'margin': '8px 8px',
        })
        editor.Css.setRule("button[data-gjs-type='TableDeleteButton']", {
          'background': 'transparent',
          'color': '#fff',
          'border': '1px solid #ccc',
          'padding': '5px 15px',
          'margin': '8px 8px',
        })
        editor.Css.setRule("tableWrapper", {'position':'relative','padding':'15px','background':'#eef5f9','margin-bottom':'15px','border-radius':'3px'})  
        editor.Css.setRule(".tableWrapper table", {'width': '100%', 'height': '200px','border-collapse': 'collapse', 'overflow': 'auto','border': '1px solid #eee'})
        editor.Css.setRule(".tableWrapper table tbody tr th",{'background':'#186ac9!important','border': '1px solid #dddddd','padding':'4px','color':'#fff'})
        editor.Css.setRule(".tableWrapper table tbody tr td", {'background-color': '#f5f5f5!important','border': '1px solid #dddddd','padding':'4px','color':'#000'})
        editor.Css.setRule(".tableWrapper table.display", {'height': 'auto','background':'#186ac9!important'})
        editor.Css.setRule('.tableWrapper ul', {
          'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
          'list-style': 'none',
          'font-size': '16px',
          'border-radius': '5px',
          'height': '200px',
          'overflow-y': 'auto'
        })   
        editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
        editor.Css.setRule('.tableWrapper ul', {
          'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
          'list-style': 'none',
          'font-size': '16px',
          'border-radius': '5px',
          'height': '200px',
          'overflow-y': 'auto',
          'z-index':'99',
        })                  
        editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
        editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })
      
    } 
    }, [editor])

  function handleModal(data, display, getBtnId) {
    setModalInfo(data);
    setModalShow(display);
    setModalBtnId(getBtnId);
  }
  function appendModalData(mdata) {
    //
    modalData = mdata;
    setPopupInfo(mdata);
  }

//   dispatch( putUserProjectData(data1) ).then((res)=>{
//     
//   })         
 
  function saveComponent() {
   
    setModalShow(false);
    let dataComp = compData;
    dataComp.componentname = componentName;
    dataComp.componentdesc = description;
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch( postComponentData({data:dataComp,instance:instance})).then((res)=>{
       // 
    })  }  
  }

  return (
    <>
      <div id="componentStudio" className='webstudioo' style={{'height':'auto !important' }}></div>  
      <Modal
    show={modalShow}	
    onHide={() => setModalShow(false)}
      size="md"
      aria-labelledby="contained-modal-title-vcenter"
      centered
    >
      <Modal.Header closeButton>
        <Modal.Title className='font_18 font_regular' id="contained-modal-title-vcenter">
          Save Component
        </Modal.Title>
      </Modal.Header>
      <Modal.Body>
          <div class="mb-3">
            <input type="email" class="form-control" onChange={(e) => {setComponentName(e.target.value)}} placeholder="Component Name"/>
          </div>
          <div class="mb-0">
          <textarea placeholder="Description" class="form-control" onChange={(e) => {setDescription(e.target.value)}}  rows="3"></textarea>
          </div>          
      </Modal.Body>
      <Modal.Footer className='border-0'>
        <Button disabled={compData===""?true:false} onClick={() => saveComponent()}>Save</Button>
      </Modal.Footer>
    </Modal>    
    </>
  )
}

export default ComponentStudio;
import { createAsyncThunk } from "@reduxjs/toolkit";
import React, { useEffect, useState } from "react";
import userProjectService from "../../services/userProjectService";
import ComponentScreenService from "../../services/componentScreenService";
import moment from "moment";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as Edit } from "../../images/edit.svg";
import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as Download_icon } from "../../images/Icon_download.svg";
import { useDispatch } from "react-redux";
import { NavLink, Link, Outlet } from "react-router-dom";
import { confirmAlert } from "react-confirm-alert";
import { ReactComponent as Info_icon } from "../../images/info_icon.svg";
import ProjectDetails from "../../ProjectDetails";
import "react-confirm-alert/src/react-confirm-alert.css";
import { useLocation, useNavigate, Route } from "react-router-dom";
import axios from "axios";

//Post for postUserProjectData
export const postUserProjectData = createAsyncThunk(
  "userprojectstore/postUserProjectData",
  async (userProjData) => {
    //    
    
    const postData = async () => {
      return userProjectService.postUserProjData(userProjData.data,userProjData.instance);
    };
    try {
      const rawData = await postData();
      
      return rawData;
    } catch (err) {
      
    }
  }
);
export const postUserProjectidData = createAsyncThunk(
  "userprojectstore/postUserProjectidData",
  async (userProjidData) => {
    //    
    const postData = async () => {
      return userProjectService.postUserProjidData(userProjidData);
    };
    try {
      const rawData = await postData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
//Put for User Project Data
export const putUserProjectData = createAsyncThunk(
  "userprojectstore/putUserProjectData",
  async (userProjdata) => {
    
    const putData = async () => {
      return userProjectService.putUserProjData(userProjdata.data,userProjdata.instance);
    };
    try {
      const rawData = await putData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);

export const postComponentData = createAsyncThunk(
  "postcomponent/postComponentData",
  async (compData) => {
    const postData = async () => {
      return ComponentScreenService.postComponentData(compData.data,compData.instance);
    };
    try {
      const rawData = await postData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);

export const getAllComponents = createAsyncThunk(
  "componentData/getAllComponents",
  async (data) => {
    ;
    const fetchData = async () => {
      return ComponentScreenService.getAllComponents(data.instance);
    };
    try {
      const rawData = await fetchData();
      const allData = [];
      const nfrTypeData = [];
      for (const all of rawData) {
        const compList = {
          id: all._id,
          componentname: all?.componentname,
          componentdesc: all?.componentdesc
        };
        allData.push(compList);
      }
      //
      const columns = [
        {
          field: "componentname",
          flex: 1,
          headerName: "Component Name",
          flex: 1,
          minWidth: 100,
        },
        { field: "componentdesc", headerName: "Description", flex: 1, width: 300 },
        { field: "updatedon", headerName: "Modified On", flex: 1, width: 200 },
        { field: "createdby", headerName: "Created by", flex: 1, width: 200 },
      ];
      for (const nfrtype of rawData) {
        const cardData = {
          id: nfrtype._id,
          createdby: nfrtype?.createdby,
          componentdesc: nfrtype?.componentdesc,
          componentname: nfrtype?.componentname,
          updatedon: new Date(nfrtype.updatedon).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          }),
        };
        //
        nfrTypeData.push(cardData);
      }
      
      const actionsColumn = getTop5AppsActionsColumn(data, nfrTypeData);
      columns.push(actionsColumn);      
      return {
        allCompList: nfrTypeData?.length !== 0 ? nfrTypeData : [],
        loading: false,
        column: columns,
      };
    } catch (err) {
      //
    }
  }
);

//Confirmation popup for delete
const confirmAlertFun = (ev, data, id) => {
  confirmAlert({
    title: "Confirm alert",
    message: "Are you sure you want to delete this project?",
    buttons: [
      {
        label: "Yes",
        onClick: () => {
          data.dispatch(deleteProject(id)).then(() => {
            data.dispatch(getAllProjectsGridData(data));
          });
          ev.stopPropagation();
          ev.preventDefault();
        },
      },
      {
        label: "No",
        // onClick: () => alert('Click No')
      },
    ],
  });
};
//actions column for top 5 apps
const getAllAppsActionsColumn = (data) => {
  //const dispatch = useDispatch();
  return {
    field: "Actions",
    headerName: "Actions",
    hideable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: (n) => {
      const id = n.row.id;
      //
      //

      let show;

      //       const location = useLocation();
      // 
      //   //var id=location.search.replace("?id=","")

      return (
        <>
          <Link className="navcustomlink" to={"/webStudio?_id=" + id}>
            <div size="large" className="me-4 cursor-pointer">
              <Edit />
            </div>
          </Link>
          <div size="large" className="me-4 cursor-pointer">
            <Delete
              onClick={(ev) => {
                confirmAlertFun(ev, data, id);
              }}
            />
          </div>

          <Link className="navcustomlink" to={"/projectDetails?_id=" + id}>
            <div size="large" className="me-4 cursor-pointer">
              <Info_icon />
            </div>
          </Link>
        </>
      );
    },
  };
};
//Recent 5 apps
export const getAllProjectsGridData = createAsyncThunk(
  "userprojectstore/getAllProjectsGridData",
  async (data) => {
    // 
    const fetchData = async () => {
      return userProjectService.getAllProjectsGridData(data.name,data.instance);
    };
    try {
      const packagetypecolumns = [
        {
          field: "projectname",
          flex: 1,
          headerName: "Projects",
          flex: 1,
          minWidth: 100,
          renderCell: (params) =>{}
            //,
        },
        { field: "useremail", headerName: "Modified By", flex: 1, width: 300 },
        { field: "updatedon", headerName: "Modified On", flex: 1, width: 200 },
      ];
      const rawData = await fetchData();
      const nfrTypeData = [];
      for (const nfrtype of rawData) {
        const cardData = {
          id: nfrtype._id,
          useremail: nfrtype?.useremail,
          projectname: nfrtype?.projectname,
          // updatedon:moment(nfrtype.updatedon).fromNow(),
          updatedon: new Date(nfrtype.updatedon).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          }),
          //updatedon:nfrtype.updatedon,
        };
        nfrTypeData.push(cardData);
      }
      //
      const actionsColumn = getAllAppsActionsColumn(data);
      packagetypecolumns.push(actionsColumn);
      return {
        allProjects: nfrTypeData?.length !== 0 ? nfrTypeData : [],
        loading: false,
        allappscol: packagetypecolumns,
      };
    } catch (err) {
      //
    }
  }
);
//Actions column for all apps
const getTop5AppsActionsColumn = (data, nfrTypeData) => {
  //
  //
  var checkFlag = false;
  return {
    field: "Actions",
    headerName: "Actions",
    hideable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: (n) => {
      const id = n.row.id;
      var projName = n.row.projectname;
      // 
      // 

      return (
        <>
          {nfrTypeData?.map((data) => {
            return (
              <>
                {id === data.id && data.codelock === true ? (
                  <>
                    <div size="large" className="me-4 cursor-pointer">
                      <Download_icon
                        onClick={() => {
                          handleDownload(projName, data);
                        }}
                      />
                    </div>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}

          {nfrTypeData?.map((data) => {
            return (
              <>
                {id === data.id && data.codelock !== true ? (
                  <>
                    {" "}
                    <Link className="navcustomlink" to={data.projecttype!=="React-Native-Expo"?"/webStudio?_id=" + id:"/mobileStudio?_id="+id}>
                      {" "}
                      <div size="large" className="me-4 cursor-pointer">
                        <Edit />
                      </div>
                    </Link>
                  </>
                ) : (
                  <></>
                )}
              </>
            );
          })}

          <div
            // onClick={(ev) => {
            //   
            //   
            //   // let idd={
            //   //   "projectid": id
            //   // }
            //     data.dispatch(deleteProject(id)).then(()=>{
            //       data.dispatch(getAllProjectsGridData1(data))
            //     })
            //   ev.stopPropagation();
            //   ev.preventDefault();
            //   // confirmAlertFun(ev,data,id)
            // }}
            size="large"
            className="me-4 cursor-pointer"
          >
            <Delete
              onClick={(ev) => {
                confirmAlertFun(ev, data, id);
              }}
            />
          </div>

          {/* <Link className='navcustomlink' to={{pathname:"/projectDetails?id="+id, state: { prevPath: "/allApps" }}} > */}
          <Link
            className="navcustomlink"
            to={"/allApps/projectDetails?_id=" + id}
          >
            <div size="large" className="me-4 cursor-pointer">
              <Info_icon />
            </div>
          </Link>
        </>
      );
    },
  };
};

//download project
const handleDownload = (projName, data) => {
  ;
  //
  var downlink = "";
  axios
    .post(
      "http://localhost:8081/downloadzip",
      {
        reponame: projName,
        token: data.accessToken[0]?.accesstoken,
      }
    )
    .then((res) => {
      //
      downlink = res.data;
      const anchor = document.createElement("a");
      anchor.href = res.data;
      anchor.setAttribute("download", res.data);
      document.body.appendChild(anchor);
      anchor.click();
      //
    });
};

//delete project
export const deleteProject = createAsyncThunk(
  "userprojectstore/deleteProject",
  async (id) => {
    ;
    const deleteData = async () => {
      return userProjectService.deleteProject(id);
    };
    try {
      const rawData = await deleteData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
//All Apps
export const getAllProjectsGridData1 = createAsyncThunk(
  "userprojectstore/getAllProjectsGridData1",
  async (data) => {
    // 
    const fetchData = async () => {
      return userProjectService.getAllProjectsGridData1(data.name,data.instance);
    };
    try {
      const packagetypecolumns = [
        {
          field: "projectname",
          flex: 1,
          headerName: "Projects",
          flex: 1,
          minWidth: 100,
          renderCell: (params) =>
            params.row.deploymentUrl !== undefined &&
            params.row.codelock === true ? (
              // <Link to={params.row.deploymentUrl} target="_blank">
              //   {params.row.projectname}
              // </Link>
              <a href={params.row.deploymentUrl} target="_blank">{params.row.projectname}</a>
            ) : (
              params.row.projectname
            ),
        },
        {field: "projecttype", headerName: "Project Type", flex: 1, width: 200},
        { field: "useremail", headerName: "Created By", flex: 1, width: 200 },
        { field: "updatedon", headerName: "Modified On", flex: 1, width: 200 },
      ];
      const rawData = await fetchData();
      const nfrTypeData = [];
      for (const nfrtype of rawData) {
        const cardData = {
          id: nfrtype._id,
          useremail: nfrtype?.useremail,
          projecttype: nfrtype?.projecttypeid?.title,
          projectname: nfrtype?.projectname,
          updatedon: new Date(nfrtype.updatedon).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          }),
          // updatedon:nfrtype.updatedon,
          codelock: nfrtype?.codelock,
          accessToken: nfrtype?.targetParam,
          deploymentUrl: nfrtype?.deploymenturl,
        };

        //
        nfrTypeData.push(cardData);
      }
      ;
      //
      const actionsColumn = getTop5AppsActionsColumn(data, nfrTypeData);
      packagetypecolumns.push(actionsColumn);
      //
      return {
        allProjects1: nfrTypeData?.length !== 0 ? nfrTypeData : [],
        loading: false,
        recentappscol: packagetypecolumns,
      };
    } catch (err) {
      //
    }
  }
);
//Fetch github api link
export const fetchGitURL = createAsyncThunk(
  "nfrstore/fetchGitURL",
  async (data) => {
    // 
    try {
      var fetchGitUrlData = data.gitUrl;
      var downloadUrl = data.downloadLink;
      //
      return {
        giturl: fetchGitUrlData,
        dowloadLinkUrl: downloadUrl,
        loading: false,
      };
    } catch (err) {
      //
    }
  }
);
//Get github link w.r.t project name
export const getProjectNameGitUrl = createAsyncThunk(
  "userprojectstore/getProjectNameGitUrl",
  async (projData) => {
    ;
    //
    //data = data.replaceAll(/\s/g,"%20");
    let newId = projData?.data.trim();
    
    const fetchData = async () => {
      return userProjectService.getProjectNameGitUrl(newId, projData?.instance);
    };
    try {
      const rawData = await fetchData();
      const nfrTypeData = [];
      for (const nfrtype of rawData) {
        const cardData = {
          id: nfrtype?._id,
          projectname: nfrtype?.projectname,
          treeURL: nfrtype?.repotreeurl,
          gitURL: nfrtype?.repourl,
          accessToken: nfrtype?.targetParam?.[0]?.accesstoken,
          deploymenturl: nfrtype?.deploymenturl,
          downloadUrl:nfrtype?.downloadurl,
          contentUrl:nfrtype?.contenturl,
          projectTypeDetails:nfrtype?.projecttypeidEP ? nfrtype?.projecttypeidEP:nfrtype?.projecttypeid,
          projecttypeid:nfrtype?.projecttypeid,
          projecttypeidEP:nfrtype?.projecttypeidEP,


        };
        nfrTypeData.push(cardData);
      }
      //
      return {
        singleProjectName: nfrTypeData?.length !== 0 ? nfrTypeData : [],
        loading: false,
      };
    } catch (err) {
      //
    }
  }
);

//Put for User Project Data
export const putTreeUrl = createAsyncThunk(
  "userprojectstore/putTreeUrl",
  async (userProjdata) => {
    // ;
    const putData = async () => {
      return userProjectService.putTreeUrl(userProjdata.data,userProjdata.instance);
    };
    try {
      const rawData = await putData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);

export const getProjectDetails = createAsyncThunk(
  "userprojectstore/getProjectDetails",
  async (data) => {
    //
    const fetchData = async () => {
      return userProjectService.getProjectDetails(data.data,data.instance);
    };
    try {
      const rawData = await fetchData();
      //
      const nfrTypeData = [];
      for (const temp of rawData) {
        const cardData = {
          id: temp._id,
          projectname: temp?.projectname,
          description: temp?.description,
          ProjectType: temp?.projecttypeid?.title,
          ProjectTypeicon: temp?.projecttypeid?.icon,
          Theme: temp?.themeid?.title,
          Themeicon: temp?.themeid?.iconpath,
          Layout: temp?.layoutid?.title,
          Layouticon: temp?.layoutid?.iconpath,
          PackageCategory: temp?.packagecategoryid,
          // updatedon:moment(nfrtype.updatedon).fromNow(),
          updatedon: new Date(temp.updatedon).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          }),
          //updatedon:nfrtype.updatedon,
          dialogues: temp?.dialogues,
          projecttypeid: temp?.projecttypeid,
          themeid: temp?.themeid,
          figmatoken: temp?.figmatoken,
          figmafile: temp?.figmafile,
          themeid: temp?.themeid,
          pagecontrols: temp?.pagecontrols,
          screencomponents: temp?.screencomponents,
        };
        nfrTypeData.push(cardData);
      }
      //

      return {
        projectdetails: nfrTypeData?.length !== 0 ? nfrTypeData : [],
        loading: false,
      };
    } catch (err) {
      //
    }
  }
);

//START for Storing the user Nodes and edges-Bot studio Page
export const putUserNodesData = createAsyncThunk(
  "userprojectstore/putUserNodesData",
  async (data) => {
    // ;
    //
    const putNodeData = async () => {
      return userProjectService.putUserNodesData(data.data,data.instance);
    };
    try {
      const rawData = await putNodeData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
//END for Storing the user Nodes and edges-Bot studio Page

//AllApps Grid
export const getAllProjectsNames = createAsyncThunk(
  "userprojectstore/getAllProjectsNames",
  async (instance) => {
    ;
    const fetchData = async () => {
      return userProjectService.getAllProjectsNames(instance);
    };
    try {
      const rawData = await fetchData();
      const allData = [];
      //
     // const cardData;
      for (const all of rawData) {
        const cardData = {

          id: all._id,
          projectname: all?.projectname,
          projecttypeid: all?.projecttypeid,
          ProjectType: all?.projecttypeid?.title
        };
        //
        allData.push(cardData);
      }
      ////
      //

      return {
        allProjectsNames: allData?.length !== 0 ? allData : [],
        loading: false,
      };
    } catch (err) {
      //
    }
  }
);
//START for Storing the user Nodes and edges-Bot studio Page
export const putuserpagedata = createAsyncThunk(
  "userprojectstore/putuserpagedata",
  async (data) => {
    // ;
    const putData = async () => {
      return userProjectService.putuserpagedata(data);
    };
    try {
      const rawData = await putData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);

const getActionsColumn5 = (data) => {
  //const dispatch = useDispatch();
  return {
    field: "Actions",
    headerName: "Actions",
    hideable: false,
    sortable: false,
    filterable: false,
    flex: 1,
    maxWidth: 250,
    renderCell: (n) => {
      const id = n.row.id;
      //
      //

      let show;

      //       const location = useLocation();
      // 
      //   //var id=location.search.replace("?id=","")

      return (
        <>
          <Link className="navcustomlink" to={"/webStudio?_id=" + id}>
            <div size="large" className="me-4 cursor-pointer">
              <Edit />
            </div>
          </Link>
          <div size="large" className="me-4 cursor-pointer">
            <Delete
              onClick={(ev) => {
                confirmAlertFun(ev, data, id);
              }}
            />
          </div>

          <Link className="navcustomlink" to={"/projectDetails?_id=" + id}>
            <div size="large" className="me-4 cursor-pointer">
              <Info_icon />
            </div>
          </Link>
        </>
      );
    },
  };
};

//show only 5 recent  projects
export const fetchProjects5 = createAsyncThunk(
  "userprojectstore/fetchProjects5 ",
  async (data) => {
    // 
    const fetchData = async () => {
      return userProjectService.getPackagesGridData(data.name);
    };

    try {
      const Projectscolumnsdata = [
        {
          field: "projectname",
          flex: 1,
          headerName: "Projects",
          flex: 1,
          minWidth: 100,
        },
        { field: "useremail", headerName: "Modified By", flex: 1, width: 300 },
        { field: "updatedon", headerName: "Modified On", flex: 1, width: 200 },
      ];

      const rawData = await fetchData();
      const gridPackage = [];

      for (const temp of rawData) {
        const cardData = {
          id: temp?._id,
          useremail: temp?.useremail,
          projectname: temp?.projectname,
          updatedon: new Date(temp.updatedon).toLocaleString("en-US", {
            timeZone: "Asia/Kolkata",
          }),
        };
        gridPackage.push(cardData);
      }
      //

      const actionsColumn = getActionsColumn5(data);
      Projectscolumnsdata.push(actionsColumn);
      return {
        projects5: gridPackage?.length !== 0 ? gridPackage : [],
        loading: false,
        projectscolumnsdata: Projectscolumnsdata,
      };
    } catch (err) {
      //
    }
  }
);

//code generation
export const postCodeGeneration = createAsyncThunk(
  "userprojectstore/postCodeGeneration",
  async (userProjData) => {
    //    
    const postData = async () => {
      return userProjectService.postCodeGeneration(userProjData);
    };
    try {
      const rawData = await postData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
export const postPageScreenComp = createAsyncThunk(
  "postcomponent/postPageScreenComp",
  async (compData) => {
    const postData = async () => {
      return ComponentScreenService.postPageScreenComp(compData.data,compData.instance);
    };
    try {
      const rawData = await postData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
export const postPageScreenCompCss = createAsyncThunk(
  "postcomponent/postPageScreenComp",
  async (compData) => {
    const postData = async () => {
      return ComponentScreenService.postPageScreenCompCSS(compData.data,compData.instance);
    };
    try {
      const rawData = await postData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
export const fetchAllPageComponents = createAsyncThunk(
  "componentData/fetchAllPageComponents",
  async (data) => {
    ;
    const fetchData = async () => {
      return ComponentScreenService.getAllPageComponents(data.instance, data.data);
    };
    try {
      const rawData = await fetchData();
      const allData = [];
      const nfrTypeData = [];
      for (const all of rawData) {
        const compList = {
          id: all._id,
          page:all.page,
          componentid:all.componentid,
          componenthtml:all.componenthtml,
          userproj:all.userprojectid
          
        };
        allData.push(compList);
      }
    
      return {
        allPageCompList: allData?.length !== 0 ? allData : [],
        loading: false,
      };
    } catch (err) {
      //
    }
  }
);
export const fetchAllPageComponentsCss = createAsyncThunk(
  "componentData/fetchAllPageComponentsCss",
  async (data) => {
    ;
    const fetchData = async () => {
      return ComponentScreenService.getAllPageComponentsCss(data.instance, data.data);
    };
    try {
      const rawData = await fetchData();
      const allData = [];
      const nfrTypeData = [];
      for (const all of rawData) {
        const compList = {
          id: all._id,
          page:all.page,
          componentid:all.componentid,
          componenthtml:all.componenthtml,
          userproj:all.userprojectid
          
        };
        allData.push(compList);
      }
    
      return {
        allPageCompList: allData?.length !== 0 ? allData : [],
        loading: false,
      };
    } catch (err) {
      //
    }
  }
);
//delete pagescreencomp
export const deletepagescreencomp = createAsyncThunk(
  "deletecomponentData/deletepagescreencomp",
  async (id) => {
    ;
    const deleteData = async () => {
      return ComponentScreenService.deletepagescreencomp(id,id.instance);
    };
    try {
      const rawData = await deleteData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
//update pagescreencss
export const updatepagescreencss = createAsyncThunk(
  "updatecomponentData/updatepagescreencss",
  async (id) => {
    ;
    const updateData = async () => {
      return ComponentScreenService.updatepagescreencss(id,id.instance);
    };
    try {
      const rawData = await updateData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
);
import React, { memo, useEffect, useState } from 'react';
import { FaCode, FaRegCalendarAlt, FaRocketchat, FaFile } from 'react-icons/fa';
import { X } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import { Form, InputGroup, Row } from 'react-bootstrap';
import { Search, Plus, XLg } from 'react-bootstrap-icons';
import 'react-circular-progressbar/dist/styles.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { fetchComponentData } from '../store/component.actions';
import { useDispatch, useSelector } from "react-redux";
import componentIcon1 from '../../images/componentIcon.svg';
import { compress, decompress } from 'compress-json'
import { AddPages,Panels } from './panels'

function AddPagePopup(
    {
        handleClose,
        show,       
        handleShow,
        editor,
        currentPage,
        setCurrentPage,
        ProjectLayout
    }) {

    const dispatch = useDispatch();
    const { addToast } = useToasts();
    const[PageName, setPageName]= useState("");
    const[Description, setDescription]= useState("");
    const validate = () =>{

        if(editor?.Pages?.getAll().filter(x=>x.id ===PageName).length>0){
            addToast("Page already exists")
        }else{
            AddPages({editor:editor, pageName:PageName, Description:Description, currentPage: currentPage, setCurrentPage: setCurrentPage,ProjectLayout})
            handleClose();

        }
    }
    return (
        <>
            <ToastProvider placement="top-center" >

            <Modal
            show={show}	
            onHide={() => handleClose()}
            size="md"
            aria-labelledby="contained-modal-title-vcenter"
            centered
            >
            <Modal.Header closeButton>
                <Modal.Title className='font_18 font_regular' id="contained-modal-title-vcenter">
                Add Page
                </Modal.Title>
            </Modal.Header>
            <Modal.Body>
                <div class="mb-3">
                    <input type="email" class="form-control" onChange={(e) => {setPageName(e.target.value+".js")}} placeholder="Page Name"/>
                </div>
                <div class="mb-0">
                <textarea placeholder="Description" class="form-control" onChange={(e) => {setDescription(e.target.value)}}  rows="3"></textarea>
                </div>          
            </Modal.Body>
            <Modal.Footer className='border-0'>
                <Button onClick={() => { validate();}}>Save</Button>
            </Modal.Footer>
            </Modal>   
            </ToastProvider>
 
        </>
    )
}

export default AddPagePopup;
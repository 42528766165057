import React, { useEffect, useState } from 'react';
import grapesjs from 'grapesjs';
import myPlugin from 'grapesjs-blocks-basic';
import webPlugin from 'grapesjs-preset-webpage';
import gjsForms from 'grapesjs-plugin-forms';
import gjsNavbar from 'grapesjs-navbar';
import axios from "axios";
import { addComponents } from './pageConfig/addComponents';
import { Panels, PageSave, AddLayout } from './pageConfig/panels';
import uuid from 'react-uuid';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from "react-router-dom";
import { ReactComponent as Check_in } from '../images/checkin.svg';
import io from 'socket.io-client';
import { putUserProjectData, postCodeGeneration, postPageScreenComp, getAllComponents, fetchAllPageComponentsCss, fetchAllPageComponents } from '../main/store/userProject.actions';
import CommitPopup from '../components/commitPopup';
import { fetchAllDataSource } from '../main/store/dropArea.actions';
import { compress, decompress } from 'compress-json'
import ModalPopup from './pageConfig/ModalPopup';
import PackageComponent from './home/packages/PackageComponent';
import ComponentPopup from './home/ComponentPopup';
import AddPagePopup from './pageConfig/AddPagePopup';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { fetchComponentData } from './store/component.actions';

var url = process.env.REACT_APP_BASE_URL;
let status = [], dropData = []
var lists, getPath, GetDatasourceList, ID, EntityObject, CreatePages, PostPage, PostMainElem, ResultData, tblHeader, UrlForPost, PutLinkofSubmit, IdForID, AllPagess, PostPageName, GetPageName,
  secondSelect = "", postDataVar, EntityName = ""
var setRes = [], mainResList = [], dropdownKeys = [], inputArray = []
var putDataList = {}, postDataList = {}, lastPagess = []
let tdata;

let jQuery;
var socket = undefined;
let ds = ``;
let modalData = '';
var imagesArr;
//var finalFigPages=[];
var testArr = []
var divchildren = "";
var csspage = "";
var tableFlag = false;
// const generateFigmaDiv = `<div></div>`
const codeTemp = {
  main: `<body> content </body>`,
  col1: `  <div class="gjs-row" id="ID">
  <div class="gjs-cell">
  content
  </div>
</div>`,
  navContainer: ` <div class="navbar">
<div class="navbar-container" data-gjs="navbar">
content
</div></div>`,
  navItems: `<div class="navbar-items-c" data-gjs="navbar-items">
<nav class="navbar-menu">content</div></div>`,
  navItem: `<div id=id> <a class="navbar-menu-link">content</a></div>`,
  img: `<img id="ID" src="srclink" />`,
  div: `<div id="ID">content</div>`,
  group: `<div id="ID">group</div>`,
  map: `<iframe frameborder="0" id="ID" src="https://maps.google.com/maps?&q=Hexaware%20Technologies&z=5&t=q&output=embed"></iframe>`,
  table: `table`
}
let version = 0;
function WebStudio(props) {
  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.search;
  const projid = data.replace("?_id=", "")
  //
  const ProjectData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails?.[0]);
  const figmatoken = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails?.[0]?.figmatoken);
  const figmafile = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails?.[0]?.figmafile)
  const remoteData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails?.[0]?.screencomponents);
  //
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  const { instance, accounts, inProgress } = useMsal();
  // const[UrlForPost, setUrlForPost] = useState("")
  const [showPage, setShowPage] = useState(false);
  const handleClosePage = () => setShowPage(false);
  const handleShowPage = () => setShowPage(true);
  const [ProjectLayout, setProjectLayout] = useState("");
  const [initLayout, setInitLayout] = useState(false);

  useEffect(() => {
    const beforeUnloadListener = (event) => {
      event.preventDefault();
      if (window.confirm("where yo going?")) {
        // redirect
      }
    };
    window.addEventListener("beforeunload", (event) =>
      beforeUnloadListener(event)
    );
  }, []);

  // useEffect(()=>{
  //   return ()=>  this.alert("hi")
  // },[])
  //const remoteData = props.screencomponents
  //datasource data
  useEffect(() => {
    if (instance.getAllAccounts()[0] !== undefined) {
      //[0])
      dispatch(fetchAllDataSource({ data: null, instance: instance }))
      dispatch(fetchComponentData(instance))
      if (projid !== undefined) {
        //
        dispatch(fetchAllPageComponents({ data: projid, instance: instance }))
        dispatch(fetchAllPageComponentsCss({ data: projid, instance: instance }))
      }


    }
  }, [dispatch, instance.getAllAccounts()[0] !== undefined, projid !== undefined])

  const pageData = useSelector((state) => state.commonReducer?.component);
  //

  const datasource = useSelector((state) => state.commonReducer?.droparea?.datasource?.datasource);
  //

  //
  const allComponentsList = useSelector((state) => state.commonReducer?.component?.componentData?.component);
  useEffect(() => {
    //
    let temp = allComponentsList?.filter(x => x.componentname === ProjectData?.Layout)[0]
    //
    if (temp !== null && temp !== undefined && temp !== null) {
      if (temp?.componenthtml?.length > 0 && temp?.componentcss?.length > 0) {
        let layoutdata = { html: decompress(temp?.componenthtml), css: decompress(temp?.componentcss) }
        setProjectLayout(layoutdata)
      }
    }
  }, [ProjectData, allComponentsList])
  //

  //useEffect for datasource post/put popup
  useEffect(() => {
    //
    //
    lists = datasource && datasource?.filter(x => x.httpobject[0]?.httpverb?.toLowerCase() === "post" || x.httpobject[0]?.httpverb?.toLowerCase() === "put").map((data, index) => {
      return `<li data-attr="${data.httpobject[0]?.url}" data-gjs-type="PopUpli" data-verb="${data.httpobject[0]?.httpverb}">${data.datasourcename}</li>`
      //

    })
    if (datasource?.length > 0) {
      addComponents({ editor: editor, handleModal: handleModal, appendModalData: appendModalData, projectData: ProjectData, datasource: datasource, setCurrentPage: setCurrentPage });

    }
    //
  }, [datasource])

  //useEffect for datasource get popup
  useEffect(() => {
    //
    //
    GetDatasourceList = datasource && datasource.filter(x => x.httpobject[0]?.httpverb?.toLowerCase() === "get").map((data, index) => {
      return `<li data-attr="${data.httpobject[0]?.url}" data-gjs-type="DropdownGetPopup" data-verb="${data.httpobject[0]?.httpverb}">${data.datasourcename}</li>`
    })

    //
    //?.replaceAll(',', ''))
  }, [datasource])

  //useEffect for creating CRUD pages




  //useEffect for imagess get popup
  // useEffect(() => {
  //   
  //   
  //   GetDatasourceList = datasource && datasource.filter(x => x.httpverb?.toLowerCase() === "get" ).map((data, index) => {
  //     return `<li data-img-attr="${data.url}" data-gjs-type="ImagesGetPopup" data-verb="${data.httpverb}">${data.datasourcename}</li>`
  //   })
  //   
  // }, [datasource])

  var firstArr = []

  const [modalInfo, setModalInfo] = useState([]);
  const [modalShow, setModalShow] = useState(false);

  //
  const [editor, setEditor] = useState(null);
  const [popupshow, setShowPopup] = useState(false)
  const [pageCreated, setPageCreated] = useState();
  const [pageControl, setPageControl] = useState();
  const [currentPage, setCurrentPage] = useState();
  let [Responsee, setResponsee] = useState([])
  const [popupInfo, setPopupInfo] = useState();
  const [pages, setPages] = useState([]);
  const [setfigitem, setsetfigitem] = useState(false);
  const [startStoring, setStartStoring] = useState(false);
  const [finalFigPages, setfinalFigPages] = useState([]);
  const [loaderdiv, setLoaderDiv] = useState(
    <>
      <div class="spinner-border text-primary" role="status">
      </div>
      <span>Loading </span>
    </>

  )

  //input controls
  const [insertControlsData, setInsertControlsData] = useState([
    {
      "id": 1,
      "name": "Button"
    },

    {
      "id": 2,
      "name": "Text Input"
    },

    {
      "id": 3,
      "name": "Drop down"
    },

    {
      "id": 4,
      "name": "Date Picker"
    },

    {
      "id": 5,
      "name": "Check Box"
    }

  ]);
  const [newpages, setNewPages] = useState([]);
  const [modalBtnId, setModalBtnId] = useState();
  const [appendData, setModalData] = useState('');

  //Map Data will be replaced later by Data Source
  var s = "Dentel AI"
  s = encodeURI(s)

  var pagecount = 1;
  function rgbToHex(r, g, b) {
    var r = r * 255
    var g = g * 255
    var b = b * 255
    const color = '#' + ((1 << 24) + (r << 16) + (g << 8) + b)?.toString(16)?.slice(1);

    if (color.length > 7) {
      //

      return color.slice(0, 7);
    }
    return color;
  };
  function rgbaToHex(r, g, b, a) {
    var outParts = [
      r.toString(16),
      g.toString(16),
      b.toString(16),
      Math.round(a * 255).toString(16).substring(0, 2)
    ];

    // Pad single-digit output values
    outParts.forEach(function (part, i) {
      if (part.length === 1) {
        outParts[i] = '0' + part;
      }
    })

    return ('#' + outParts.join(''));
  }
  const getStyles = (element, parent) => {
    var css = "";
    var id = "g" + element.id.replace(":", "");
    css = `#` + id + `{`;
    css += `height:` + element.size.y + `px;`
    css += `width:` + element.size.x + `px;`
    var fills = element.fills;
    // if(fills.length>0 && fills[0].type === "SOLID")
    // var hex = colorcolor('rgb('+fills[0].color.r+','+fills[0].color.g+','+fills[0].color.b+','+fills[0].color.g+',)', 'hex');
    // css  += element.type!=="TEXT" && fills.length>0 && fills[0].type === "SOLID"?
    // `background:`+rgbaToHex(fills[0].color.r,fills[0].color.g,fills[0].color.b,fills[0].color.a)+`;`: ""
    // css  += element.type==="TEXT" && fills.length>0 && fills[0].type === "SOLID"?
    // `color:`+rgbaToHex(fills[0].color.r,fills[0].color.g,fills[0].color.b,fills[0].color.a)+`;`: ""
    css += element.type !== "TEXT" && element.type !== "VECTOR" && fills.length > 0 && fills[0].type === "SOLID" ?
      `background:` + rgbToHex(fills[0].color.r, fills[0].color.g, fills[0].color.b, fills[0].color.a) + `;` : ""
    css += element.type === "TEXT" && fills.length > 0 && fills[0].type === "SOLID" ?
      `color:` + rgbToHex(fills[0].color.r, fills[0].color.g, fills[0].color.b, fills[0].color.a) + `;` : ""
    //css += `border-style: dotted;`
    css += `position: absolute;`

    var posx = parent !== undefined ? parent.relativeTransform[0][2] - element.relativeTransform[0][2] : element.relativeTransform[0][2];
    var posy = parent !== undefined ? parent.relativeTransform[1][2] - element.relativeTransform[1][2] : element.relativeTransform[1][2];
    css += element.id.includes("i") ? "" : `transform: translateX(` + posx + `px) translateY(` + posy + `px);`
    var dum;
    if (element?.strokes?.length > 0) {
      //
      dum = `border:`;
      dum += element.strokeWeight + "px "
      //
      dum += element.strokes[0].type + " ";
      //
      dum += rgbToHex(element.strokes[0].color.r, element.strokes[0].color.g, element.strokes[0].color.b, element.strokes[0].color.a)
      //
    }
    css += dum
    css += '}'
    csspage += css;
    //

  }
  async function renderFigmaGroups(children, html, divchildren) {
    //
    try {
      return new Promise(async (resolve, reject) => {

        if (children.type === "GROUP" || children.type === "FRAME") {

          if (children.children.length > 0) {

            var newchildren = "";
            for (const [index, g] of children.children.entries()) {
              var array = children.children;
              //
              //index===0?html = html.replace("content",codeTemp.navItem):
              var tempdiv = codeTemp.div.replace("ID", "g" + g.id.replace(":", ""));
              var replacement = "";
              if (g.children === undefined) {
                await renderFigmaNonGroupElem(g).then((replacement) => {
                  //
                  tempdiv = tempdiv.replace("content", replacement)
                  //divchildren = divchildren+tempdiv
                  newchildren = newchildren + tempdiv;
                  //

                  if (index === array.length - 1) {
                    //
                    if (divchildren.includes("group"))
                      divchildren = divchildren.replace("group", newchildren)
                    else
                      divchildren = divchildren + newchildren
                    //
                    //resolve();

                  }
                })

              }
              else {
                //
                var cuurentdivgroup = "";
                //divchildren = divchildren + codeTemp.group.replace("ID",uuid());
                //
                await renderFigmaGroups(g, html, divchildren).then((replacement) => {
                  //
                  tempdiv = tempdiv.replace("content", replacement)
                  //divchildren = divchildren+tempdiv
                  //divchildren = tempdiv;
                  cuurentdivgroup = cuurentdivgroup + tempdiv;
                  //


                  newchildren = newchildren + tempdiv;
                  //

                  if (index === array.length - 1) {
                    //
                    if (divchildren.includes("group"))
                      divchildren = divchildren.replace("group", newchildren)
                    else
                      divchildren = divchildren + newchildren
                    //
                    //resolve();

                  }



                })


              }
            }
            //
            testArr.push(divchildren)
            if (children.children.filter(x => x.name.startsWith("filler")).length > 0) {
              //
              var e = children.children.filter(x => x.name.startsWith("filler"))[0]
              getStyles(e, children)
              var tempp = codeTemp.col1.replace("ID", "g" + e.id.replace(":", ""))
              divchildren = tempp.replace("content", divchildren)
              //
            } else {
              //
              getStyles(children)
              var tempp = codeTemp.col1.replace("ID", "g" + children.id.replace(":", ""))
              divchildren = tempp.replace("content", divchildren)
              //
            }
            resolve(divchildren)

            // var loop = new Promise((resolve, reject)=>{children.children.forEach((g,index,array)=>{
            //   //
            //   //index===0?html = html.replace("content",codeTemp.navItem):
            //   var tempdiv = codeTemp.div.replace("ID",uuid());
            //   var replacement="";
            //   if(g.children === undefined){
            //     renderFigmaNonGroupElem(g).then((replacement)=>{
            //       
            //       tempdiv = tempdiv.replace("content",replacement)
            //       divchildren = divchildren+tempdiv
            //       

            //       if(index === array.length - 1){
            //         
            //         resolve();

            //       }
            //     })

            //   }
            //   else{
            //     
            //      renderFigmaGroups(g, html, divchildren).then((replacement)=>{
            //       
            //       tempdiv = tempdiv.replace("content",replacement)
            //       divchildren = divchildren+tempdiv
            //       

            //       if(index === array.length - 1){
            //         
            //         resolve();

            //       }
            //     })


            //   }

            // })})
            // 
            // loop.then(()=>{
            //   var htmlbeforereolcae = html;
            //   //html = html.replace("content",divchildren)
            //   
            //   
            //   //return html
            //   testArr.push(divchildren)
            //   resolve(divchildren)

            // })
          }
        } else {
          resolve();
        }
        // else{
        //   resolve()
        // }
      })
    } catch (error) {
      //
    }

  }
  async function renderFigmaNonGroupElem(element) {
    //
    getStyles(element)
    try {
      return new Promise(async (resolve, reject) => {
        var code;
        if (element.type === "RECTANGLE" && element.fills.length > 0 && element.fills[0].type === "IMAGE") {
          element.id = "i" + element.id;
          //
          if (element.name.startsWith("map")) {
            code = codeTemp.map.replace("ID", `g` + element.id.replace(":", ""))
          } else {
            let imageRef = element.fills[0].imageRef;
            //
            code = codeTemp.img.replace("ID", `g` + element.id.replace(":", "")).replace("srclink", imagesArr[imageRef])
          }

          getStyles(element)
          resolve(code)

        } else if (element.type === 'TEXT' && !element.name.startsWith("btnlbl")) {
          code = element.characters
          resolve(code)

        } else if (element.type === 'VECTOR') {
          code = "v"
          resolve(code)
        } else if (!element.name.startsWith("filler") && element.type === "RECTANGLE") {
          //code = element.name
          code = "";
          resolve(code)
        } else {
          code = ""
          resolve(code)
        }
        //

        //return code;
      })
    }
    catch (Error) {
      //
    }

  }
  //
  async function renderfigmaelements(figmatoken, figmafile) {

    var list1 = [];
    var figPagedata = [];
    finalFigPages.length = 0;
    setsetfigitem(true)
    const options = {

      headers: { 'X-Figma-Token': figmatoken }

    };
    //
    axios.get('https://api.figma.com/v1/files/' + figmafile + '?geometry=paths', options).

      then(response => {

        let main = response.data;
        axios.get('https://api.figma.com/v1/files/' + figmafile + '/images', options).

          then(images => {

            imagesArr = images.data.meta.images;


            setPages(main["document"]["children"].map(x => x.name))

            //)
            //
            var top = main["document"]["children"]
            var loop = new Promise((resolve, reject) => {
              top.forEach((subpages, index1, array1) => {
                //)

                var pages1 = subpages.children.map(x => x.children)[0].sort((a, b) => {
                  //
                  if (a.absoluteBoundingBox.y === b.absoluteBoundingBox.y) {

                    return a.absoluteBoundingBox.x < b.absoluteBoundingBox.x ? -1 : 1

                  } else {

                    return a.absoluteBoundingBox.y < b.absoluteBoundingBox.y ? -1 : 1

                  }
                })

                var pagename = subpages.name;

                //
                var html = codeTemp.main;
                var looppages = new Promise((resolve, reject) => {
                  pages1.forEach((children, index, array) => {
                    //

                    //

                    if (children.children?.length > 0) {
                      var divchildren = ``
                      testArr = [];
                      divchildren = "";
                      csspage = "";
                      renderFigmaGroups(children, html, divchildren, subpages).then((res) => {


                        //html = res;
                        //
                        //html = html.replace("content",testArr.join(","))
                        html = html.replace("content", res)
                        //

                        let obj = {}
                        obj.name = pagename;
                        obj.html = html
                        obj.css = csspage;
                        finalFigPages.push(obj)
                        //
                        if (index === pages1.length - 1)
                          resolve(html);
                      })
                    } else {

                    }


                  })




                })
                looppages.then((res) => {
                  //
                  if (index1 === array1.length - 1)
                    resolve();

                })

              })


            })

            loop.then(() => {

              //add pages
              ;
              //
              let onj = {}
              var select = document.getElementById("displayPage")
              select.options.length = 0;

              finalFigPages.forEach((x) => {
                editor?.Pages?.add({
                  id: x.name + ".js",
                  // styles:x.css,
                  // component:x.html
                  styles: x.css,
                  component: x.html

                })
                select.add(new Option(x.name + ".js", x.name + ".js"));
                // let main = editor.Pages.getAll().filter(y=>y.id ===  x.name+".js")[0].getMainComponent();
                // main.setStyle(x.css);
                // //
              })

              editor?.Pages?.select(finalFigPages[0]?.name + ".js");
              //)
              setStartStoring(true)


            })
          })

      })

    return list1;

  }
  useEffect(() => {
    //)
    if (finalFigPages.length > 0 && startStoring === true && editor !== undefined) {
      //)
      //.filter(x=>x.id==="home.js"))
      if (editor?.Pages?.getAll().filter(x => x.id === "home.js").length > 0)
        editor?.Pages?.remove("home.js")
      //.filter(x=>x.id==="home.js"))
      finalFigPages.forEach((x) => {
        editor?.Pages?.add({
          id: x.name + ".js",
          // styles:x.css,
          // component:x.html
          styles: x.css,
          component: x.html

        })
      })
      editor?.Pages?.select(finalFigPages[0]?.name + ".js");
      setLoaderDiv("")
      editor?.Panels?.removeButton('gjs-pn-commands', 'loader');
      //)
    }
  }, [editor?.Pages?.getAll()])
  //,editor?.Pages?.getAll())

  const codecommit = () => {
    //e.preventDefault()
    socket = io.connect(url);
    setShowPopup(true);
    socket.emit('join', { projid: data.replace("?_id=", "") });
    //dispatch( postCodeGeneration(data.replace("?_id=","")) )       
  }


  useEffect(() => {
    var editor = grapesjs.init({
      container: '#webBuilder',
      showOffsets: 1,
      noticeOnUnload: 0,
      //protectedCss: '',
      keepUnusedStyles: true,
      allowScripts: 1,
      pageManager: {
        pages: [
          {
            id: 'home.js',
            name: 'home.js'
          }
        ]
      },
      styleManager: {
        sectors: [{
          name: 'General',
          open: false,
          buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
        }, {
          name: 'Flex',
          open: false,
          buildProps: ['flex-direction', 'flex-wrap', 'justify-content', 'align-items', 'align-content', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self']
        }, {
          name: 'Dimension',
          open: false,
          buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
        }, {
          name: 'Typography',
          open: false,
          buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-shadow'],
        }, {
          name: 'Decorations',
          open: false,
          buildProps: ['border-radius-c', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
        }, {
          name: 'Extra',
          open: false,
          buildProps: ['transition', 'perspective', 'transform'],
        }
        ],
      },
      width: '100%',
      plugins: [myPlugin, webPlugin, gjsForms, gjsNavbar],
      dragMode: 'translate',

      canvas: {
        scripts: ['https://code.jquery.com/jquery-3.5.1.js', 'https://cdn.datatables.net/1.13.4/js/jquery.dataTables.min.js', 'https://cdn.datatables.net/1.13.4/js/dataTables.jqueryui.min.js', 'https://cdn.datatables.net/scroller/2.1.1/js/dataTables.scroller.min.js', 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/js/bootstrap.bundle.min.js'],
        styles: ['https://code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css', 'https://cdn.datatables.net/1.13.4/css/dataTables.jqueryui.min.css', 'https://cdn.datatables.net/scroller/2.1.1/css/scroller.jqueryui.min.css', 'https://cdn.jsdelivr.net/npm/bootstrap@5.2.0-beta1/dist/css/bootstrap.min.css'
        ],
      },
      storageManager: {
        id: 'gjs-',
        type: 'remote',
        autosave: true,
        storeComponents: true,
        storeStyles: true,
        storeHtml: true,
        storeCss: true,
        storeAssets: true,
        options: {
          remote: {
            // Enrich the store call
            urlStore: ``,
            urlLoad: ``,
            onStore: (data, editor) => {
              // let allcomp = document.querySelectorAll("[data-comp]");
              // 
              //PageSave(editor,projid,version,dispatch)                   

              //
              const allPages = editor.Pages.getAll();
              newpages.length = 0;
              // if(allPages,allPages.filter(x=>x.attributes.type !== 'main').length>0)
              // allPages.shift()
              let data1 = {
                _id: projid,
                screencomponents: allPages.map((p, index) => {

                  //

                  p.attributes.type === "main" ? p.id = "home.js" : p.id = p.id;
                  newpages.push(p.id)
                  //setNewPages([...newpages,p.id.replace(p.id.substring(0,p.id.indexOf("PROJ")),"").replace("PROJ","")])
                  //
                  let component = p.getMainComponent();
                  let html = editor.getHtml({ component });
                  let htmlObject = document.createElement('div');
                  htmlObject.innerHTML = html;

                  //
                  let temp = htmlObject.querySelectorAll(".navbar-custom")
                  //
                  if (temp.length > 0)
                    htmlObject?.removeChild(temp[0])
                  //html = html.replace(temp,"")
                  let temp1 = htmlObject.querySelectorAll(".footer-custom")
                  //html = html.replace(temp1,"")
                  if (temp1.length > 0)
                    htmlObject?.removeChild(temp1[0])
                  //
                  let appendData = htmlObject.innerHTML + modalData;
                  //
                  const css = editor.CodeManager.getCode(component, 'css', { cssc: editor.CssComposer });
                  //
                  return {
                    //_id:data.replace("?_id=",""),
                    scid: uuid(),
                    filename: p.id,
                    filepath: "src/" + p.id,
                    html: compress(appendData),
                    css: index === allPages.length - 1 ? compress(editor.getCss({ component })) : ""
                  }
                })
              }

              let putData = { instance: instance, data: data1 }
              dispatch(putUserProjectData(putData)).then((res) => {
                //
              })

              // const pagesHtml = editor.Pages.getAll().map(page => {
              //     const component = page.getMainComponent();
              //     return {
              //       html: editor.getHtml({ component }),
              //       css: editor.getCss({ component })
              //     }
              //      } )

              // }
              // //
              // dispatch( putUserProjectData(data1) ).then((res)=>{
              //   
              // })         

              // // const pagesHtml = editor.Pages.getAll().map(page => {
              // //     const component = page.getMainComponent();
              // //     return {
              // //       html: editor.getHtml({ component }),
              // //       css: editor.getCss({ component })
              // //     }
              // //   });
              //   //return { id: projid, data, pagesHtml };
            },
            // If on load, you're returning the same JSON from above...
            //onLoad: result => result.data,
          }
        },
      },
      pluginsOpts: {
        myPlugin: {
        },
        webPlugin: {},
        gjsForms: {}
      }

    })
    //) 




    editor.onReady(() => {
      jQuery = document.querySelector('iframe').contentWindow.$;
    });
    var pageDefaultt = editor?.Pages.getAll()
    var pgeNeww = pageDefaultt[1]
    //
    setEditor(editor);

    //editor.setComponents('<div class="cls">New component</div>');
    //)
    addComponents({ editor: editor, handleModal: handleModal, appendModalData: appendModalData, projectData: ProjectData, datasource: datasource, dispatch: dispatch, setCurrentPage: setCurrentPage });
    Panels({
      editor: editor, projid: projid, codecommit: codecommit,
      newpages: newpages, dispatch: dispatch, pages: props.pages,
      currentPage: currentPage, setCurrentPage: setCurrentPage, loaderdiv: loaderdiv, handleShow: handleShow, handleShowPage: handleShowPage
    });



    // editor.Panels.addButton('button',
    // {
    //   id: 'button',
    //   className: 'btn-page-button',
    //   label: 'Deploy',
    //   command: { 
    //     run: function (editor) {
    //       // editor.getHtml()
    //       ",editor.getHtml())       
    //       let obj = {
    //         //_id:data.replace("?_id=",""),
    //         scid:uuid(),
    //         filename:"default.js",
    //         filepath:"src/default.js",
    //         html : editor.getHtml(),
    //         css: editor.getCss()
    //     }
    //     if(editor.getHtml() !== undefined){
    //       firstArr.push(obj)
    //       let data1 = {
    //           _id:data.replace("?_id=",""),
    //           screencomponents:firstArr
    //       }
    //       dispatch( putUserProjectData(data1) ).then((res)=>{
    //         
    //         codecommit()
    //       })
    //   }
    //     }
    //   }
    // });

    const domc = editor.DomComponents;
    domc.addType('map', {
      // You can update the isComponent logic or leave the one from `some-component`
      // isComponent: (el) => false,

      // Update the model, if you need
      model: {
        // The `defaults` property is handled differently
        // and will be merged with the old `defaults`
        defaults: {
          tagName: 'iframe', // Overrides the old one
          someNewProp: '',
          type: 'map',
          src: '',
          void: false,
          mapUrl: 'https://maps.google.com/maps',
          tagName: 'iframe',
          mapType: 'q',
          address: s,
          zoom: '5',
          attributes: { frameborder: 0 },
          // @ts-ignore
          // toolbar: defs.toolbar,
          // Add new property
        },

      },
      // Update the view, if you need
      view: {
        // ComponentMapView
      },
    });



    //Add type for form
    editor.DomComponents.addType('testlabel', {
      model: {
        defaults: {
          // other model properties

          toolbar: [{
            attributes: { class: 'fa fa-plus' },
            command: 'frm-plus',
          },
          {
            attributes: { class: 'fa fa-clone' },
            command: 'frm-clone',
          },
          {
            attributes: { class: 'fa fa-arrow-up' },
            command: 'frm-arrowUp',
          },
          {
            attributes: { class: 'fa fa-trash-o' },
            command: 'tlb-delete',
          },
          ],
        }
      }
    })

    //append li to ul for datasource popup
    editor.Commands.add('frm-plus', {
      run(editor) {
        
        //  //)
        //?.replaceAll(',', ''))

        //)

        editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${lists?.toString()?.replaceAll(',', '')}</ul></div>`);
        editor.Css.setRule('.tableWrapper ul', {
          'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
          'list-style': 'none',
          'font-size': '16px',
          'border-radius': '5px',
          'height': '200px',
          'overflow-y': 'auto'
        })
      }
    })
    // Adding style to table and popover
    editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
    editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
    editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })

    //Render form onclick of popup datasource
    editor.DomComponents.addType('PopUpli', {
      isComponent: el => el.tagName === 'LI',
      model: {
        defaults: {
          tagName: 'li',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            editor.Css.setRule('.tableWrapper ul', {
              'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
              'list-style': 'none',
              'font-size': '16px',
              'border-radius': '5px',
              'height': '200px',
              'overflow-y': 'auto'
            })
            //)
            dropdownKeys = []
            getPath = e.target.getAttribute('data-attr');
            let getVerb = e.target.getAttribute('data-verb')
            //

            if (getVerb === "Post") {
              const res = axios(getPath).then((res) => {
                //
                setRes = res.data
                mainResList = [{ setRes }]

                for (var key in mainResList[0]?.setRes[0]) {
                  if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                    //
                    dropdownKeys.push(key)
                    //
                  }
                }
              }).then((res) => {
                var returnVar = "";
                dropdownKeys.filter(x => !x.includes("id")).forEach(key => {
                  //
                  var temp = uuid();

                  var obj = { id: temp, key: key }
                  inputArray.push(obj)
                  //
                  returnVar += `<div class="mb-3 col-lg-12">
                      <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                      <div class="d-flex align-items-center" data-gjs-type="defaultPostControl" style="display:flex;flex-direction:row;" id=${key}>
                        <input type='text' placeholder=${key} id=${temp} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key}  ></input>
                        <div data-gjs-type="editBtn" style="margin-left:15px;"  ><button data-gjs-type="buttonEdit">Edit</button></div>
                      </div>
                    </div>`

                })
                //
                var mainelem;
                if (returnVar !== undefined) {
                  mainelem = `<div >
              ${returnVar}
              
                <button type='button' data-gjs-type="PostsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>
             
                </div>`
                }
                // <button type='button' data-gjs-type="CancelBtn" style="margin-left:15px" className="btn btn-secondary btn_custom_secondary">Cancel</button>

                //", editor.getSelected())

                var parents = editor.getSelected().parents();
                //
                parents[2].components(mainelem)

              })
            } else {
              const res = axios.get(getPath).then((res) => {
                //
                Responsee.push(res.data)
                //

                for (var key in Responsee[0]) {
                  if (Responsee[0]?.hasOwnProperty(key)) {
                    //
                    dropdownKeys.push(key)
                    //
                  }
                }
              }).then((res) => {
                var returnVar = "";
                {
                  Object.entries(Responsee[0]).filter(x => !x.includes("id")).map(([key, value]) => {
                    var temp = uuid();
                    var obj = { id: temp, key: key, value: value }
                    inputArray.push(obj)
                    returnVar += `<div class="mb-3 col-lg-12">
                                        <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                                        <div class="d-flex align-items-center" style="display:flex;flex-direction:row;" id=${key}>
                                            <input type='text' placeholder=${key} id=${temp} data-name=${key} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key} value=${value} ></input>                        
                                         <div data-gjs-type="editBtn" style="margin-left:15px;"> <button >Edit</button></div>
                                        </div>                  
                                    </div>`
                  })
                }
                var mainelem;
                if (returnVar !== undefined) {
                  mainelem = `<div >
              ${returnVar}
              
                <button type='button' data-gjs-type="PutsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>
             
                </div>`
                }
                //
                var parents = editor.getSelected().parents();
                //
                parents[2].components(mainelem);
              });

            }
          }
        }

      }
    });

    //Open insertcontrols popup onclick of Edit button
    editor.DomComponents.addType('editBtn', {
      isComponent: el => el.tagName === 'div',
      model: {
        defaults: {
          tagName: 'div',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            
            //)
            editor.Css.setRule('.tableWrapper ul', {
              'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
              'list-style': 'none',
              'font-size': '16px',
              'border-radius': '5px',
              'height': '200px',
              'overflow-y': 'auto'
            })
            var idd = uuid()
            //)
            //)

            var dropDownList =

              <div class="tableWrapper">
                <ul >
                  {insertControlsData.map((item, index) => (
                    <li data-gjs-type="DropLi" data-attr={item.name} data-id={idd}>{item.name}</li>
                  ))}
                </ul>
              </div>
            editor.Css.setRule('.tableWrapper ul', {
              'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
              'list-style': 'none',
              'font-size': '16px',
              'border-radius': '5px',
              'height': '200px',
              'overflow-y': 'auto'
            })

            //.find("#" + e.target.id)[0])
            var editList = editor.getWrapper().find("#" + e.target.id)[0];
            editList.append(dropDownList)
            //.parents())
            //   editor.getSelected().components(dropDownList)
            //  //.components(dropDownList))
            // )

          }
        }
      }
    })

    //change input control from insertcontrols popup
    editor.DomComponents.addType('DropLi', {
      isComponent: el => el.tagName === 'li',
      model: {
        defaults: {
          tagName: 'li',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            
            //)
            //)
            ID = e.target.getAttribute('data-id')
            let getID = e.target.getAttribute('data-attr')


            if (getID === "Button") {
              var newelem = (
                <div class="mb-3 col-lg-12">
                  <div class="d-flex align-items-center" style="display:flex;flex-direction:row;">
                    <button
                      className="btn btn-primary"
                      id={ID}
                    >
                      Primary
                    </button>
                    <div data-gjs-type="editBtn" style="margin-left:15px" ><button>Edit</button></div>
                  </div>
                </div>
              )
            }
            else if (getID == "Text Input") {
              var newelem = (<div class="mb-3 col-lg-12">
                <div class="d-flex align-items-center" data-gjs-type="inputChange" style="display:flex;flex-direction:row;">
                  <input
                    type="text"
                    id={ID}
                    style="flex:1;margin-bottom:15px"
                    class="form-control flex-fill me-3"
                    name="abc"
                    placeholder="text input" >
                  </input>
                  <div data-gjs-type="editBtn" style="margin-left:15px;" ><button>Edit</button></div>
                </div>
              </div>)
            }
            else if (getID == "Drop down") {
              var newelem = (`<div style="flex:1">
              <div style="display:flex;flex-direction:row;">        
                <select class="form-select mt-3" id=${ID} data-gjs-type="selectOptins" style="flex:1;margin-bottom:15px"  >
                <option selected>Select any option</option>
                </select>
                <div data-gjs-type="FirstDropdownBtn" class="fa fa-edit">+</div>
                <div data-gjs-type="editBtn" style="margin-left:15px" ><button>Edit</button></div>
              </div>
            </div>`)
            }
            else if (getID == "Date Picker") {
              var newelem = (<div class="mb-3 col-lg-12">
                <div class="d-flex align-items-center" style="display:flex;flex-direction:row;">
                  <input
                    id={ID}
                    type="date"
                    class="form-control flex-fill me-3"
                    name="abcd"
                    style="flex:1;margin-bottom:15px"
                    placeholder="date picker"
                  />
                  <div data-gjs-type="editBtn" style="margin-left:15px;" ><button>Edit</button></div>
                </div>
              </div>)
            }
            else if (getID == "Check Box") {
              var newelem = (<div class="mb-3 col-lg-12">
                <div class="d-flex align-items-center" style="display:flex;flex-direction:row;">
                  <input
                    class="form-check-input"
                    type="checkbox"
                    id={ID}
                    placeholder="checkbox"
                    name="checkbox"
                    style={{ height: "1.5rem", width: "1.5rem" }}
                  >
                  </input>
                  <div data-gjs-type="editBtn" style="margin-left:15px;" ><button>Edit</button></div>
                </div>
              </div>
              )
            }

            //.parents())

            var parents = editor.getSelected().parents()
            parents[1].remove()
            parents[2].append(newelem, { at: 1 })
          }
        }
      }
    })


    //on click of plus button in dropdown open popup 
    editor.DomComponents.addType('FirstDropdownBtn', {
      isComponent: el => el.tagName === 'div',
      model: {
        defaults: {
          tagName: 'div',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            
            //.find("#"+e.target.id)[0])
            var droplists = editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${GetDatasourceList?.toString()?.replaceAll(',', '')}</ul></div>`);
            editor.Css.setRule('.tableWrapper ul', {
              'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
              'list-style': 'none',
              'font-size': '16px',
              'border-radius': '5px',
              'height': '200px',
              'overflow-y': 'auto'
            })
            //.parents())
            var editList = editor.getWrapper().find("#" + e.target.id)[0];
            editList.append(droplists)

          }
        }
      }

    })

    //onclick of get datasource popup li show 1st select dropdown
    editor.DomComponents.addType('DropdownGetPopup', {
      isComponent: el => el.tagName === 'LI',
      model: {
        defaults: {
          tagName: 'li',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            
            //
            dropdownKeys = []
            var dataUrl = e.target.getAttribute('data-attr')
            //
            const res = axios(dataUrl).then((res) => {
              //
              setRes = res.data
              mainResList = [{ setRes }]
              // 

              for (var keyss in mainResList[0]?.setRes[0]) {
                if (mainResList[0]?.setRes[0]?.hasOwnProperty(keyss)) {
                  //
                  dropdownKeys.push(keyss)
                  //
                }
              }

              var newelem = (`<div style="flex:1">
      <div style="display:flex;flex-direction:row;">        
        <select class="form-select mt-3"  data-gjs-type="AttributesList" style="flex:1;margin-bottom:15px;margin-right:15px"  >
        <option selected >Select any attribute</option>
        </select>
      </div>
      
          </div>`


              )

              //.parents())
              var parentss = editor.getSelected().parents()
              parentss[3].components(newelem)
            })


          }
        }
      }
    })

    //onclick of 1st select bind attributes in dropdown
    editor.DomComponents.addType('AttributesList', {
      isComponent: el => el.tagName === 'div',
      model: {
        defaults: {
          tagName: 'div',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {


            //) 
            dropData = []
            //.find("#"+e.target.id)[0])
            //
            var optionList = editor.getWrapper().find("#" + e.target.id)[0]
            optionList.append(dropdownKeys.map((item, index) => (
              `<option data-gjs-type="AttributeValues">${item}</option>
            `
            )))

            dropdownKeys = []
            //
            var attributeVal = []
            attributeVal = e.target.value
            //
            setRes.map((key) => {
              dropData.push({ "selectedAttribute": key[attributeVal], "id": key.id })
              //     
            })

            //.parent().index())
            secondSelect = `<div style="flex:1">
       <div style="display:flex;flex-direction:row;">        
         <select class="form-select mt-3" data-gjs-type="AttributesValues"  style="flex:1;margin-bottom:15px"  >
         <option selected >Select any Value</option>
         </select>
       </div>
       </div>`
            //.parent().getChildAt(1)) 

            //    var childElem = editor.getSelected().parent().getChildAt(1) 
            //  if(Object.values(childElem).length > 0){
            //   childElem.remove()
            //  }

            editor.getSelected().parent().append(secondSelect, { at: 1 });


          }
        },
      }
    })

    //Bind attribute values in 2nd dropdown
    editor.DomComponents.addType('AttributesValues', {
      isComponent: el => el.tagName === 'div',
      model: {
        defaults: {
          tagName: 'div',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            

            //
            var optionList = editor.getWrapper().find("#" + e.target.id)[0]
            optionList.append(dropData.map((item, index) => (
              `<option>${item.selectedAttribute}</option>
          `
            )))

          }
        }
      }
    })

    //Post call on click of submit button
    editor.DomComponents.addType('PostsubmitBtn', {
      isComponent: el => el.tagName === 'postBUTTON',
      model: {
        defaults: {
          tagName: 'postbutton',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            
            postDataList = {}
            //

            //
            var tempp = e.target.parentElement
            var tagnamee = tempp.getElementsByTagName('input')
            //)
            var arrTag = Array.from(tagnamee)
            //


            arrTag && arrTag.length > 0 && arrTag?.map(element => {
              postDataList = {
                ...postDataList,
                //  [editor.DomComponents.getWrapper().find('#'+element.id)[0].attributes.attributes.name]
                [element.name]: element.value
              }
            })

            //

            const res = axios.post(getPath, {
              body: postDataList
            }).then((res) => {
              if (res.status === 201) {
                alert("Form submitted successfully")
                //
                //.parents())

                arrTag && arrTag.length > 0 && arrTag?.map(element => {
                  element.value = ""
                })
              }

            })
          }
        }
      }
    })

    //Put call on click of submit button
    editor.DomComponents.addType('PutsubmitBtn', {
      isComponent: el => el.tagName === 'button',
      model: {
        defaults: {
          tagName: 'button',
          draggable: false,
          removable: false,
          droppable: false,
          resizable: false,
          copyable: false,
        }
      },
      view: {
        events: {
          'click': e => {
            
            //

            //
            var tempp = e.target.parentElement
            var tagnamee = tempp.getElementsByTagName('input')

            var arrTag = Array.from(tagnamee)
            //

            arrTag && arrTag.length > 0 && arrTag?.map(element => {
              putDataList = {
                ...putDataList,
                //  [editor.DomComponents.getWrapper().find('#'+element.id)[0].attributes.attributes.name]
                [element.name]: element.value
              }
            })
            //

            const res = axios.put(getPath, {
              body: putDataList
            }).then((res) => {
              //
              if (res.status === 200) {
                alert("Form updated successfully")

              }

            })
          }
        }
      }
    })


    //Add type for figma div
    editor.DomComponents.addType('figmalabel', {
      model: {
        defaults: {
          // other model properties

          toolbar: [{
            attributes: { class: 'fa fa-plus' },
            command: 'div-plus',
          },
          {
            attributes: { class: 'fa fa-clone' },
            command: 'frm-clone',
          },
          {
            attributes: { class: 'fa fa-arrow-up' },
            command: 'frm-arrowUp',
          },
          {
            attributes: { class: 'fa fa-trash-o' },
            command: 'tlb-delete',
          },
          ],
        }
      }
    })

    //open datasource popup on click of plus
    editor.Commands.add('div-plus', {
      run(editor) {
        
        //  //)
        //

        //)

        editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${GetDatasourceList?.toString()?.replaceAll(',', '')}</ul></div>`);
        editor.Css.setRule('.tableWrapper ul', {
          'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
          'list-style': 'none',
          'font-size': '16px',
          'border-radius': '5px',
          'height': '200px',
          'overflow-y': 'auto'
        })
      }
    })
    // Adding style to table and popover
    editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
    editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
    editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })

    //onclick of image datasource render images
    // editor.DomComponents.addType('ImagesGetPopup',{
    //   isComponent: el => el.tagName === 'LI' ,
    //   model:{
    //     defaults:{
    //       tagName: 'li',
    //         draggable: false,
    //         removable: false,
    //         droppable: false,
    //         resizable: false,
    //         copyable: false,
    //     }
    //   },
    //   view:{
    //     events:{
    //       'click':e=>{
    //         
    //         var tempArr=[]
    //         
    //         var imageURL = e.target.getAttribute('data-img-attr')
    //         const res = axios.get(imageURL).then((res)=>{
    //           

    //           for(var i=0;i<4;i++){          
    //                tempArr.push(res.data[i])
    //             }        
    //           
    //         }).then((res)=>{
    //           var renderList="";
    //           // tempArr.map((item,index)=>{
    //            renderList =    
    //               `<div style="text-align: center;display:grid;
    //               grid-template-columns: 200px 200px; ">

    //               <div style=" width: 150px; height: 100px;float:left;
    //               clear:none">
    //                 <div><img src=${tempArr[0].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
    //                 <div>${tempArr[0].title}</div>
    //               </div>

    //               <div style=" width: 150px; height: 100px;float:left;
    //               clear:none">
    //                 <div><img src=${tempArr[1].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
    //                 <div>${tempArr[1].title}</div>
    //               </div>

    //               <div style=" width: 150px; height: 200px;float:left;
    //               clear:none">
    //                 <div><img src=${tempArr[2].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
    //                 <div>${tempArr[2].title}</div>
    //               </div>

    //               <div style=" width: 150px; height: 200px;float:left;
    //               clear:none">
    //                 <div><img src=${tempArr[3].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
    //                 <div>${tempArr[3].title}</div>
    //               </div>

    //               </div>`
    //             // })


    //             
    //           var parentss = editor.getSelected().parents();
    //           parentss[3].components(renderList)
    //           
    //         })


    //       }
    //     }
    //   }

    // })


    //Add components for table

  }, [])

  useEffect(() => {
    if (editor !== null && Object.keys(editor).length > 0) {
      //
      editor.Css.setRule("button[data-gjs-type='button']", {
        'background': 'rgb(7 58 120)!important',
        'color': '#fff',
        'border': '1px solid #ccc',
        'padding': '5px 15px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("button[data-gjs-type='modalButton']", {
        'background': 'rgb(7 58 120)!important',
        'color': '#fff',
        'border': '1px solid #ccc',
        'padding': '5px 15px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("button[data-gjs-type='SearchButton']", {
        'background': 'rgb(7 58 120)!important',
        'color': '#fff',
        'border': '1px solid #ccc',
        'padding': '5px 15px',
        'margin': '8px 8px',
        'display': 'inline-block',
        'width': '150px',
      })
      editor.Css.setRule("select[data-gjs-type='select']", {
        'background-color': 'transparent',
        'color': '#000',
        'border': '1px solid #ccc',
        'padding': '5px 15px 5px 5px',
        'margin': '8px 8px',
        'min-width': '200px',
      })
      editor.Css.setRule("input[data-gjs-type='input']", {
        'background-color': 'transparent',
        'color': '#000',
        'border': '1px solid #ccc',
        'padding': '5px 15px 5px 5px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("input[data-gjs-type='SearchInput']", {
        'background-color': 'transparent',
        'color': '#000',
        'border': '1px solid #ccc',
        'padding': '5px 15px 5px 5px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("textarea[data-gjs-type='textarea']", {
        'background-color': 'transparent',
        'color': '#000',
        'border': '1px solid #ccc',
        'padding': '5px 15px 5px 5px',
        'margin': '8px 8px',
        'min-height': '70px',
        'min-width': '200px',
      })
      editor.Css.setRule("label[data-gjs-type='label']", {

        'color': '#000',
        'padding': '5px 15px 5px 5px',
        'margin': '8px 8px 8px 0px',
        'min-width': '150px',
        'font-size': '13px',
        'font-weight': 'bold',
      })
      editor.Css.setRule("input[data-gjs-type='checkbox']", {
        'border': '1px solid #ccc',
        'width': '15px',
        'height': '15px',
      })
      editor.Css.setRule("input[data-gjs-type='radio']", {
        'border': '1px solid #ccc',
        'width': '15px',
        'height': '15px',
      })
      editor.Css.setRule("blockquote[data-gjs-type='text']", {
        'background-color': 'rgb(238, 245, 249)',
        'margin': '8px',
        'padding': '10px',
      })

      editor.Css.setRule(".bdg-sect", {
        'background-color': 'rgb(238, 245, 249)',
        'margin': '8px',
        'padding': '10px',
      })
      editor.Css.setRule(".bdg-sect .heading", {
        'margin': '0px',
        'padding': '5px 10px',
        'font-size': '18px',
        'font-weight': 'bold',
      })
      editor.Css.setRule(".bdg-sect .paragraph", {
        'margin': '0px',
        'padding': '5px 10px',
        'font-size': '14px',
        'font-weight': 'regular',
      })
      editor.Css.setRule("[data-gjs-type='navbar'].navbar", {
        'background': 'rgb(7 58 120)!important',
      })
      editor.Css.setRule("form[data-gjs-type='form'] [data-gjs-type='default']", {
        'display': 'flex',
        'align-items': 'center',
      })
      editor.Css.setRule("form[data-gjs-type='form'] [data-gjs-type='label']", {
        'max-width': '250px!important',
      })
      editor.Css.setRule(".wrapper_section [data-gjs-type='input']", {
        'background-color': 'transparent',
        'color': '#000',
        'border': '1px solid #ccc',
        'padding': '5px 15px 5px 5px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("button[data-gjs-type='CreateNewEntry']", {
        'background': 'rgb(7 58 120)!important',
        'color': '#fff',
        'border': '1px solid #ccc',
        'padding': '5px 15px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("button[data-gjs-type='TableEditButton']", {
        'background': 'rgb(7 58 120)!important',
        'color': '#fff',
        'border': '1px solid #ccc',
        'padding': '5px 15px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("button[data-gjs-type='TableDeleteButton']", {
        'background': 'transparent',
        'color': '#fff',
        'border': '1px solid #ccc',
        'padding': '5px 15px',
        'margin': '8px 8px',
      })
      editor.Css.setRule("tableWrapper", { 'position': 'relative', 'padding': '15px', 'background': '#eef5f9', 'margin-bottom': '15px', 'border-radius': '3px' })
      editor.Css.setRule(".tableWrapper table", { 'width': '100%', 'height': '200px', 'border-collapse': 'collapse', 'overflow': 'auto', 'border': '1px solid #eee' })
      editor.Css.setRule(".tableWrapper table tbody tr th", { 'background': '#186ac9!important', 'border': '1px solid #dddddd', 'padding': '4px', 'color': '#fff' })
      editor.Css.setRule(".tableWrapper table tbody tr td", { 'background-color': '#f5f5f5!important', 'border': '1px solid #dddddd', 'padding': '4px', 'color': '#000' })
      editor.Css.setRule(".tableWrapper table.display", { 'height': 'auto', 'background': '#186ac9!important' })
      editor.Css.setRule('.tableWrapper ul', {
        'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
        'list-style': 'none',
        'font-size': '16px',
        'border-radius': '5px',
        'height': '200px',
        'overflow-y': 'auto'
      })
      editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
      editor.Css.setRule('.tableWrapper ul', {
        'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
        'list-style': 'none',
        'font-size': '16px',
        'border-radius': '5px',
        'height': '200px',
        'overflow-y': 'auto',
        'z-index': '99',
      })
      editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
      editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })

    }
  }, [editor])

  useEffect(() => {
    //
    var nameee = props.Entity
    EntityName = props.Entity
    //[0]?.httpobject)
    EntityObject = datasource && datasource?.filter(x => x.datasourcename === EntityName)[0]?.httpobject
    //
    var GetURL = "", PostURL = "", PutURL = "", DeleteURL = ""
    if (EntityObject !== undefined) {
      GetURL = EntityObject.filter(x => x.httpverb.toLowerCase() === "get")[0].url
      //
      PostURL = EntityObject.filter(x => x.httpverb.toLowerCase() === "post")[0].url
      PutURL = EntityObject.filter(x => x.httpverb.toLowerCase() === "put")[0].url
      DeleteURL = EntityObject.filter(x => x.httpverb.toLowerCase() === "delete")[0].url
    }

    
    if (EntityName !== "") {
      for (var i = 0; i < 2; i++) {
        var id = editor.Pages.getAll().length;
        if (i === 0) {
          editor.Pages.add({
            id: EntityName + "List.js",
          });
        } else {
          editor.Pages.add({
            id: EntityName + ".js",
          });
        }

        CreatePages = editor.Pages.getAll();
        //
        let AllPagess = CreatePages?.map((el, index) => {
          //
          var res;
          res = el.id
          return res;
        });
        var select = document.getElementById("displayPage");
        //
        select.options.length = 0;
        AllPagess.map((element, index) => {
          select.add(new Option(element, element));
        });
        //);
        //
      }

      //

      //   axios.get(GetURL).then((res)=>{

      //     ResultData = res.data
      //     let columnCount;
      //        let rowData = [];
      //        let loadTableData = ``;
      //        let loadTableHead = ``;
      //        var loadTableRows =``
      //        ResultData.map((user) => {
      //         tblHeader = Object.keys(user);
      //        // tblHeader.push("Action")
      //         columnCount = tblHeader.length;
      //         
      //         rowData.push(user);
      //       })
      //         let columnHead = [];
      //         tblHeader.map((item) => {
      //           loadTableHead += `<th>${item}</th>`;
      //           columnHead.push({ data: item })
      //         })
      //         ResultData.length = 5;
      //         let randomId = uuid().split('-');
      //          randomId = randomId[randomId.length -1];
      //         let table = `<table id="jdataTableStudio${randomId}" class="display nowrap" style={{'width':'100%'}}>
      //         <thead>
      //         <tr>
      //           ${loadTableHead}
      //         </tr>
      //         </thead>
      //         </table>`
      //                         editor.Pages.select("sample.js")
      //                         editor.Components.addComponent(table)
      //         let jid = '#jdataTableStudio'+randomId;
      //         let s = jQuery(jid).DataTable({
      //           ajax: {
      //             url: GetURL,
      //             type: 'GET',
      //             dataSrc: ''
      //           },
      //           columns: columnHead,
      //           scrollY: 200,
      //           scrollX: true,
      //         });

      // 
      //   })

      function renderTable() {
        
        const getRes = axios.get(GetURL).then((res) => {

          UrlForPost = EntityObject[0]?.url
          ResultData = res.data;
          let rowCount = ResultData.length;

          let columnCount;
          let rowData = [];
          let loadTableData = ``;
          let loadTableHead = ``;
          var loadTableRows = ``;
          var searchbox = "";
          ResultData.map((user) => {
            tblHeader = Object.keys(user);
            columnCount = tblHeader.length;
            //
            rowData.push(user);
          })
          var tablelenn = tblHeader.length - 1
          tblHeader.push("Actions")
          //
          tblHeader.map((item) => {
            //   style="background:#186ac9!important;border: 1px solid #dddddd;padding:4px;color:#fff;"
            loadTableHead += `<th>${item}</th>`
          })
          ResultData.length = 5;
          ResultData.forEach((record, index) => {
            //

            let col = Object.keys(record)
            loadTableRows = ``
            //
            col.map((val, index) => {
              //   style="background-color: #f5f5f5!important;border: 1px solid #dddddd;padding:4px;color:#000"
              loadTableRows += `<td>${record[val]}</td>`
            })
            loadTableData += `<tr>
         ${loadTableRows}
         <td style="background-color: #f5f5f5!important;min-width:150px">
         
         <span data-gjs-type="TableEditButton" style="margin-left:50px;" data-attr-Id="${record.id}" data-attr-link="${GetURL}">
         <svg class="svg-icon" style="fill:currentColor;overflow:hidden" xmlns="http://www.w3.org/2000/svg" width="12.852" height="12.852" viewBox="0 0 12.852 12.852">
          <g id="Icon_feather-edit" data-name="Icon feather-edit" transform="translate(-6.523 150.777)">
            <path id="Path_1792" data-name="Path 1792" d="M20.812,3.339A1.78,1.78,0,0,1,23.33,5.857L15.357,13.83,12,14.67l.839-3.357Z" transform="translate(-4.977 -153.095)" fill="none" stroke="#6c757d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          </g>
        </svg>
        </span>
       
        <span data-gjs-type="TableDeleteButton" style="margin-left:20px;" data-attr-DeleteID="${record.id}" data-attr-Deletelink="${DeleteURL}">
        <svg class="svg-icon" style="fill:currentColor;overflow:hidden" xmlns="http://www.w3.org/2000/svg" width="16.339" height="18.043" viewBox="0 0 16.339 18.043">
        <g id="Icon_feather-trash-2" data-name="Icon feather-trash-2" transform="translate(0.5 0.5)">
          <path id="Path_1793" data-name="Path 1793" d="M4.5,9H19.839" transform="translate(-4.5 -5.034)" fill="none" stroke="#6c757d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path id="Path_1794" data-name="Path 1794" d="M18.873,6.409v11.93a1.666,1.666,0,0,1-1.625,1.7H9.125a1.666,1.666,0,0,1-1.625-1.7V6.409m2.437,0V4.7A1.666,1.666,0,0,1,11.562,3h3.249a1.666,1.666,0,0,1,1.625,1.7v1.7" transform="translate(-5.517 -3)" fill="none" stroke="#6c757d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path id="Path_1795" data-name="Path 1795" d="M15,16.5v5.948" transform="translate(-9.035 -9.368)" fill="none" stroke="#6c757d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
          <path id="Path_1796" data-name="Path 1796" d="M21,16.5v5.948" transform="translate(-11.626 -9.368)" fill="none" stroke="#6c757d" stroke-linecap="round" stroke-linejoin="round" stroke-width="1"/>
        </g>
        </svg>
        </span>
        
         

        </td>
         </tr>`
          })
          //<button data-gjs-type="TableEditButton" style="margin-right:15px;" data-attr-Id="${record.id}" data-attr-link="${GetURL}">Edit</button>
          // <button data-gjs-type="TableDeleteButton"  data-attr-DeleteID="${record.id}" data-attr-Deletelink="${DeleteURL}">Delete</button>

          //


          let table = `<button data-gjs-type="CreateNewEntry" style="margin-top:10px;margin-bottom:10px;margin-left:10px">Create</button>
      <style>
      .tableWrapper{position:relative;padding:15px;background:#eef5f9;margin-bottom:15px;border-radius:3px;}
      .tableWrapper table {width: 100%; height: 200px;border-collapse: collapse; display:table; border: 1px solid #eee}
      .tableWrapper table tbody tr th {background:#2B3138!important;border: 1px solid #dddddd;padding:4px;color:#fff;}
      .tableWrapper table tbody tr td {background-color: #f5f5f5!important;border: 1px solid #dddddd;padding:4px;color:#000}
      </style> 
      <div class="tableWrapper">
       <table data-gjs-type='table' tbl-crud="true" data-attr-getlink="${GetURL}"><tbody>${loadTableHead} ${loadTableData}</tbody></table>
       </div>
       
       `;




          //  let getParent = editor?.getSelected()?.parent();
          //  
          //  getParent.components(table);
          // var pagee =   editor.Pages.get(CreatePages[0]?.id)
          //   var pagee =   editor.Pages.get(CreatePages[0]?.id)
          //   
          //  

          var theSelect = document.getElementById('displayPage');
          GetPageName = theSelect.options[theSelect.options.length - 2].value;
          //
          editor.Pages.select(GetPageName)
          editor.Components.clear()

          if(ProjectLayout.html){
            var html = ""
            html = ProjectLayout.html
            var htmlObject = ""
            htmlObject = document.createElement('div');
  
            htmlObject.innerHTML = html;
  
            let navbarcomp = htmlObject?.getElementsByTagName("ul")
            let htmll = navbarcomp[0]?.innerHTML
            let menuElem = `<li>
                    <a class="nav-link" data-nav-menu="${GetPageName}" href="#">${GetPageName}</a>
                    </li>`
            let FinalLayout = ""
            FinalLayout = htmll + menuElem
  
            if (navbarcomp[0]?.innerHTML && navbarcomp[0]?.innerHTML !== null) {
              navbarcomp[0].innerHTML = FinalLayout
            }
  
            let layoutdata = { html: htmlObject.innerHTML, css: ProjectLayout.css }
            setProjectLayout(layoutdata)
  
  
  
            // if (ProjectLayout !== "") {
            //   AddLayout(editor, layoutdata)
            // }
          }
         

          let element = document.getElementById("displayPage");
          element.value = GetPageName;
          editor?.getWrapper()?.append(table, { at: 1 })
          //

          //navbarcomp.append(currentPageName)
          //document.getElementsByClassName('footer-custom')[0].prepend(table)
          // editor.Components.addComponent(`<div>hi</div>`)

        })

      }

      //Initial render of table
      renderTable()

      //POST screen
      dropdownKeys = []
      const postRes = axios.get(PostURL).then((res) => {
        //
        setRes = res.data
        mainResList = [{ setRes }]

        for (var key in mainResList[0]?.setRes[0]) {
          if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
            //
            dropdownKeys.push(key)
            //
          }
        }
      }).then((res) => {
        var returnVar = "";
        dropdownKeys.filter(x => !x.includes("id")).forEach(key => {
          //
          var tempforPost = uuid();

          var obj = { id: tempforPost, key: key }
          inputArray.push(obj)
          //
          returnVar += `<div class="mb-3 col-lg-12">
                <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px;margin-left:10px;margin-bottom:10px">${key}</label>
                <div class="d-flex align-items-center" data-gjs-type="defaultPostControl" style="display:flex;flex-direction:row;margin-left:10px" id=${key}>
                  <input type='text' data-gjs-type='input' placeholder=${key} id=${tempforPost} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key}  ></input>
                </div>
              </div>`

        })
        var mainelem;
        if (returnVar !== undefined) {
          PostMainElem = `<div >
              ${returnVar}
              
                <button type='button' data-gjs-type="TablePostsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px; margin-right:15px">Submit</button>
                <button type='button' data-gjs-type="TablePostCancelBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Cancel</button>
             
                </div>`
        }
        var theSelect = document.getElementById('displayPage');
        PostPageName = theSelect.options[theSelect.options.length - 1].value;
        //
        editor.Pages.select(PostPageName)
        // if (ProjectLayout.html && ProjectLayout.html !== "" && ProjectLayout.html !== "undefined") {
        //   AddLayout(editor, ProjectLayout)
        // }
        let element = document.getElementById("displayPage");
        element.value = PostPageName
        editor.Components.addComponent(PostMainElem)
        editor.Pages.select(GetPageName)
        let Getelement = document.getElementById("displayPage");
        Getelement.value = GetPageName;

      })

      //Edit button of table 
      editor.DomComponents.addType('TableEditButton', {
        isComponent: el => el.tagName === 'EditBUTTON',
        model: {
          defaults: {
            tagName: 'editbutton',
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          }
        },
        view: {
          events: {
            'click': e => {
              
              PutLinkofSubmit = PutURL
              //
              IdForID = e.target.getAttribute('data-attr-Id')
              //
              var Linkk = e.target.getAttribute('data-attr-link')
              //
              var EditLink = Linkk + '/' + IdForID
              //
              Responsee = []
              const PutRes = axios.get(EditLink).then((res) => {
                Responsee.push(res.data)
                //
                for (var key in Responsee[0]) {
                  if (Responsee[0]?.hasOwnProperty(key)) {
                    //
                    dropdownKeys.push(key)
                    //
                  }
                }
              }).then((res) => {
                var returnVar = "";
                {
                  Object.entries(Responsee[0]).filter(x => !x.includes("id")).map(([key, value]) => {
                    var temp = uuid();
                    var obj = { id: temp, key: key, value: value }
                    inputArray.push(obj)
                    returnVar += `<div class="mb-3 col-lg-12">
                                            <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                                            <div class="d-flex align-items-center" style="display:flex;flex-direction:row;width:50%" id=${key}>
                                                <textarea rows="2" data-gjs-type='textarea' placeholder=${key} id=${temp} data-name=${key} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px;resize: none" name=${key}>${value}</textarea>                        
                                            </div>                  
                                        </div>`
                  })
                }
                var mainelem;
                if (returnVar !== undefined) {
                  mainelem = `<div >
                ${returnVar}
                
                  <button type='button' data-gjs-type="PutTableSubmitButton" className="btn btn-primary btn_custom_primary" style="margin-top:15px ; margin-right:15px">Submit</button>
                  <button type='button' data-gjs-type="TablePostCancelBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Cancel</button>
               
                  </div>`
                }
                //
                editor.Pages.select(PostPageName)
                editor.Components.clear()
                // if (ProjectLayout !== "") {
                //   //
                //   AddLayout(editor, ProjectLayout)
                // }
                let element = document.getElementById("displayPage");
                element.value = PostPageName;
                editor.Components.addComponent(mainelem)
              })

            }
          }
        }
      })

      //Delete a record from table
      editor.DomComponents.addType('TableDeleteButton', {
        isComponent: el => el.tagName === 'DeleteBUTTON',
        model: {
          defaults: {
            tagName: 'deletebutton',
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          }
        },
        view: {
          events: {
            'click': e => {
              
              var getidd = e.target.getAttribute('data-attr-DeleteID')
              //
              var Linkk = e.target.getAttribute('data-attr-Deletelink')
              //
              var DeleteLink = Linkk + '/' + getidd
              //

              const DeleteRes = axios.delete(DeleteLink).then((res) => {
                //
                if (res.status === 200) {
                  alert("Record deleted successfully")
                  renderTable()
                }
              })
            }
          }
        }
      })



      //Create new record form
      editor.DomComponents.addType('CreateNewEntry', {
        isComponent: el => el.tagName === 'CreateBUTTON',
        model: {
          defaults: {
            tagName: 'createbutton',
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          }
        },
        view: {
          events: {
            'click': e => {
              
              //      
              //

              dropdownKeys = []
              const res = axios.get(PostURL).then((res) => {

                //
                setRes = res.data
                mainResList = [{ setRes }]

                for (var key in mainResList[0]?.setRes[0]) {
                  if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                    //
                    dropdownKeys.push(key)
                    //
                  }
                }
              }).then((res) => {
                var returnVar = "";
                dropdownKeys.filter(x => !x.includes("id")).forEach(key => {
                  //
                  var temp = uuid();

                  var obj = { id: temp, key: key }
                  inputArray.push(obj)
                  //
                  returnVar += `<div class="mb-3 col-lg-12">
                        <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                        <div class="d-flex align-items-center" data-gjs-type="defaultPostControl" style="display:flex;flex-direction:row;" id=${key}>
                          <input type='text' placeholder=${key} id=${temp} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key}  ></input>
                        </div>
                      </div>`

                })
                //
                var mainelem;
                if (returnVar !== undefined) {
                  mainelem = `<div >
                
                ${returnVar}
                
                  <button type='button' data-gjs-type="TablePostsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px ; margin-right:10px">Submit</button>
                  <button type='button' data-gjs-type="TablePostCancelBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Cancel</button>
                  </div>`
                }

                editor.Pages.select(PostPageName)
                editor.Components.clear()
                // if (ProjectLayout !== "") {
                //   AddLayout(editor, ProjectLayout)
                // }
                let element = document.getElementById("displayPage");
                element.value = PostPageName;
                editor.Components.addComponent(mainelem)
              })

            }
          }
        }
      })

      //Submit button of Post in createEntry and post page
      editor.DomComponents.addType('TablePostsubmitBtn', {
        isComponent: el => el.tagName === 'TablePostButton',
        model: {
          defaults: {
            tagName: 'tablepostbutton',
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          }
        },
        view: {
          events: {
            'click': e => {
              
              postDataList = {}
              //
              //
              var tempp = e.target.parentElement
              var tagnamee = tempp.getElementsByTagName('input')
              //)
              var arrTag = Array.from(tagnamee)
              //

              //

              arrTag && arrTag.length > 0 && arrTag?.map(element => {
                postDataList = {
                  ...postDataList,
                  [element.name]: element.value
                }
              })

              //
              const res = axios.post(UrlForPost, {
                body: postDataList
              }).then((res) => {
                if (res.status === 201) {
                  alert("Form submitted successfully")
                  //
                  //.parents())

                  arrTag && arrTag.length > 0 && arrTag?.map(element => {
                    element.value = ""
                  })
                }

              })
            }
          }
        }
      })

      //Submit button for Edit action form in table
      editor.DomComponents.addType('PutTableSubmitButton', {
        isComponent: el => el.tagName === 'PutBUTTON',
        model: {
          defaults: {
            tagName: 'putbutton',
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          }
        },
        view: {
          events: {
            'click': e => {
              
              //
              // var getidd = e.target.getAttribute('data-attr-Id')
              //
              var linkToSubmit = PutLinkofSubmit + "/" + IdForID
              //
              //
              var tempp = e.target.parentElement
              var tagnamee = tempp.getElementsByTagName('textarea')

              var arrTag = Array.from(tagnamee)
              //

              arrTag && arrTag.length > 0 && arrTag?.map(element => {
                putDataList = {
                  ...putDataList,
                  [element.name]: element.value
                }
              })
              //

              const res = axios.put(linkToSubmit, {
                body: putDataList
              }).then((res) => {
                //
                if (res.status === 200) {
                  alert("Form updated successfully")
                  renderTable()

                }

              })
            }
          }
        }
      })

      //Cancel Button for Post and Delete Form
      editor.DomComponents.addType('TablePostCancelBtn', {
        isComponent: el => el.tagName === 'cancelBUTTON',
        model: {
          defaults: {
            tagName: 'cancelbutton',
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          }
        },
        view: {
          events: {
            'click': e => {
              
              // editor?.Pages?.select(GetPageName)
              // let element = document.getElementById("displayPage");
              // element.value = GetPageName;
              renderTable()
            }
          }
        }
      })
    }
  }, [datasource])


  document?.getElementById("displayPage")?.addEventListener("change", function (e) {
    //
    let getPage = e.target.value;
    let showPage = editor.Pages.get(getPage);
    setCurrentPage(showPage);
    editor.Pages.select(showPage);
    // 
    //", editor.Pages.getSelected());
  });
  useEffect(() => {
    if (modalData !== '') {
      pageComponent(editor);
    }
  }, [modalData])

  //editor.setComponents('<div class="cls">New component</div>');
  //) 

  useEffect(() => {
    //)
    var select = document.getElementById("displayPage")
    if (ProjectData && ProjectData?.projecttypeid === "637de439efe9bf2ab4b102c5") {
      // navigate("/")
      alert("This is not a Web project");
      // navigate("/mobileStudio?_id=" + ProjectData.id);  
    }
    else if (editor !== null && remoteData?.length > 0) {
      setCurrentPage(remoteData[0].filename)
      //)
      if (select !== null) {
        //
        //setStartStoring(true)
        editor.Pages.remove("home.js");
        //)
        //)
        select.options.length = 0
        remoteData.map((element, index) => {
          select.add(new Option(element.filename, element.filename))
        });
        remoteData?.forEach((x) => {
          //editor.CssComposer.getAll().reset();
          //,"demooo ",editor.CssComposer.getAll())
          //
          editor.CssComposer.clear();  // Clear styles
          editor.Pages.add({
            id: x.filename,
            styles: x.css && x.css !== "" ? decompress(x.css) : "",
            component: decompress(x.html)

          })
          let main = editor.Pages.getAll().filter(y => y.id === x.filename)[0].getMainComponent();
          //main.setStyle(x.css);
          //
          //)


        })
        editor.Pages.select(remoteData[0].filename);
        //AddLayout(editor)
        setLoaderDiv("")
        editor?.Panels?.removeButton('gjs-pn-commands', 'loader');
        let getbutton = editor.Panels.getButton('options', 'sw-visibility');
        getbutton.set('active', 1);
        // 

        // editor?.Pages?.getAll().map((p)=>{
        //   let component = p.getMainComponent();
        //   )

        // })

      }
    } else if (figmatoken !== undefined && figmafile !== undefined &&
      figmatoken !== "" && figmafile !== "") {
      //
      //
      renderfigmaelements(figmatoken, figmafile)
      //)



    } else {
      //
      if (ProjectData !== undefined) {
        //
        if (ProjectData.Layout === "Header and Footer with Top Navigation" && allComponentsList?.length > 0 && ProjectLayout !== "" && initLayout === false) {
          //
          setInitLayout(true)
          //AddLayout(editor, ProjectLayout)
        }
        setLoaderDiv("")
        editor?.Panels?.removeButton('gjs-pn-commands', 'loader');

      }

    }


  }, [remoteData, figmatoken, figmafile, ProjectData, editor])
  //)
  //[0],editor?.Pages?.getSelected())
  useEffect(() => {

    //

    // if(currentPage?.id===undefined)
    // editor?.Pages?.select(editor?.Pages?.getAll()[0].id);
    // else if(currentPage?.id!=="")
    // editor?.Pages?.select(currentPage?.id);
  }, [currentPage])
  //
  const assignPagedata = () => {
    if (editor !== null) {
      //
      // editor.setComponents(remoteData[1]?.html);
      // editor.setStyle(remoteData[1]?.css);

    }
  }

  function handleModal(data, display, getBtnId) {
    setModalInfo(data);
    setModalShow(display);
    setModalBtnId(getBtnId);
  }
  function appendModalData(mdata) {
    //
    modalData = mdata;
    setPopupInfo(mdata);
  }

  function pageComponent(e) {
    //
    const allPages = e.Pages.getAll();
    //)
    newpages.length = 0;
    // if(allPages,allPages.filter(x=>x.attributes.type !== 'main').length>0)
    // allPages.shift()
    let data1 = {
      _id: projid,
      screencomponents: allPages.map((p, index) => {
        ;
        //
        p.attributes.type === "main" ? p.id = "home.js" : p.id = p.id;
        newpages.push(p.id)
        //setNewPages([...newpages,p.id.replace(p.id.substring(0,p.id.indexOf("PROJ")),"").replace("PROJ","")])
        //
        let component = p.getMainComponent();
        let appendData = e.getHtml({ component }) + modalData;
        //
        //
        return {
          //_id:data.replace("?_id=",""),
          scid: uuid(),
          filename: p.id,
          filepath: "src/" + p.id,
          html: appendData,
          css: e.getCss({ component })
        }
      })

    }
    //
    dispatch(putUserProjectData(data1)).then((res) => {
      //
    })

    // const pagesHtml = editor.Pages.getAll().map(page => {
    //     const component = page.getMainComponent();
    //     return {
    //       html: editor.getHtml({ component }),
    //       css: editor.getCss({ component })
    //     }
    //   });
    //return { id: projid, data, pagesHtml };

  }

  return (
    <>
      <div id="webBuilder" className='webstudioo' style={{ 'height': 'auto !important' }}></div>
      <CommitPopup
        popupshow={popupshow}
        setShowPopup={setShowPopup}
        socket={socket}
        pages={newpages}
        status={status}
      />
      {/* {loaderdiv} */}
      <ModalPopup
        show={modalShow}
        onHide={() => setModalShow(false)}

        modalInfo={modalInfo}
        id={modalBtnId}
      />
      <ComponentPopup
        handleClose={handleClose}
        show={show}
        handleShow={handleShow}
        current={0}
        setCurrent={0}
        editor={editor}

      />
      <AddPagePopup
        handleClose={handleClosePage}
        show={showPage}
        handleShow={handleShowPage}
        editor={editor}
        currentPage={currentPage}
        setCurrentPage={setCurrentPage}
        ProjectLayout={ProjectLayout}
      />
    </>
  )


}

export default WebStudio;
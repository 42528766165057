import { createAsyncThunk } from "@reduxjs/toolkit";
import themeService from "../../services/themeService";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as Edit } from "../../images/edit.svg";
//import {ReactComponent as Delete} from "../../images/delete.svg";
import { ReactComponent as EyeSlash } from "../../images/SlashEye.svg";
import { Link } from "react-router-dom";

//old
export const fetchThemeDataOld = createAsyncThunk("themestore/fetchThemeData", async (data) => {
  const fetchData = async () => {
    return themeService.getThemeGridData(data.data, data.instance)
  }

  try {
    const rawData = await fetchData();

    const themeTypeData = [];

    for (const theme of rawData) {
      const cardData = {
        id: theme?._id,
        title: theme?.title,
        description: theme?.description,
        icon: theme?.iconpath,
        projecttypeid: theme?.projecttypeid
      };
      themeTypeData.push(cardData);
    }

    //
    return {
      themedata: themeTypeData?.length !== 0 ? themeTypeData : [],
      loading: false,
    };
  }
  catch (err) {
    //
  }

}
);

export const fetchThemeData = createAsyncThunk("themestore/fetchThemeData", async (data) => {
  const fetchData = async () => {
    return themeService.getThemeGridData("?_id="+data.data, data.instance)
  }

  try {
    const rawData = await fetchData();

    const themeTypeData = [];

    for (const theme of rawData) {
      const cardData = {
        id: theme?._id,
        title: theme?.themename,
        description: theme?.themeid?.description,
        icon: theme?.themeid?.iconpath,
        projecttypeid: theme?.projecttypeid,
        isactive: theme?.isactive


        
      };
      themeTypeData.push(cardData);
    }

    
    return {
      themedata: themeTypeData?.length !== 0 ? themeTypeData?.filter(x=>x.isactive===true) : [],
      loading: false,
    };
  }
  catch (err) {
    //
  }

}
);
//post theme data
export const postThemeData = createAsyncThunk("themestore/postThemeData", async (newpackage) => {

  const postData = async () => {
    return themeService.postThemeData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await postData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);

//post themepackage data
export const postPackageThemeData = createAsyncThunk("themestore/postPackageThemeData", async (newpackage) => {

  const postData = async () => {
    return themeService.postPackageThemeData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await postData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);

const getActionsColumn = (data) => {


  //
  return {
    field: 'Actions',
    headerName: 'Actions',
    hideable: false,
    sortable: false,
    filterable: false,
    width: 200,
    renderCell: (n) => {
      let eyeicon = <Eye />
      let packthemedata
      const id = n.row.id;
      const isactive = n.row.isactive;
      if (isactive === true)
        eyeicon = <Eye />
      else
        eyeicon = <EyeSlash id="eyeOff" />
      //
      return (
        <>
          <Link className="navcustomlink" to={"/themes/editthemes?id=" + id}>
            <div
              size="large"
              className="me-4 cursor-pointer"
            >
              <Edit />
            </div></Link>

          <div
            onClick={(ev) => {
              {
                isactive === true ?
                packthemedata = {
                  "_id": id,
                  "isactive": false
                } : packthemedata = {
                  "_id": id,
                  "isactive": true
                }
              }

              data.dispatch(putPackageThemeData({ data: packthemedata, instance: data.instance })).then(() => {
                data.dispatch(fetchThemeGridData(data))

              })

              ev.stopPropagation();
              ev.preventDefault();
            }}
            size="large"
            className="cursor-pointer"
            aria-disabled="true"
          >
            {eyeicon}
          </div>


        </>
      )
    }
  }
}
//fetching grid data
export const fetchThemeGridData = createAsyncThunk("themestore/fetchThemeGridData ", async (data) => {



  let id1 = data.dispatch.name
  if (data.search !== undefined && data.search.includes("?id=")) {
    id1 = data.search
  }
  //  if(data.val.length>2 && data.val!==undefined ){
  //    id1="?search="+data.val
  //   //alert("hi")
  //  }
  //  if(data.proj.includes("?projecttype") ){
  //   id1=data.proj
  //  }else{
  //    id1=data.dispatch.name
  //  }

  //
  const fetchData = async () => {
    return themeService.getThemeGridData(id1, data.instance)
  }
  try {

    const columnsdata = [
      //  {field: "iconpath", headerName: "Icon",flex:1,minwidth: 200, renderCell: (param) => {return (<img src={param.value}/>)}},
      { field: "themename", headerName: "Theme Name", flex: 1, minWidth: 200 },
      { field: "projecttypename", headerName: "Project Type", flex: 1, minwidth: 200 },

      { field: "projectversion", headerName: "Project Version", flex: 1, width: 175 },

    ];

    const rawData = await fetchData();
    const gridTheme = [];

    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype._id,
        themename: nfrtype?.themename,
        projecttypename: nfrtype?.projecttypename,
        projecttypeid: nfrtype?.projecttypeid,
        projectversion: nfrtype?.projectversion,
        description: nfrtype?.themeid?.description,
        iconpath: nfrtype?.themeid?.iconpath,
        themeid: nfrtype?.themeid?._id,
        projectversionid: nfrtype?.projectversionid,
        isactive: nfrtype?.isactive
      };
      gridTheme.push(cardData);
    }


    const actionsColumn = getActionsColumn(data, data.instance);
    columnsdata.push(actionsColumn);
    return {
      themegrid: gridTheme?.length !== 0 ? gridTheme : [],
      loading: false,
      themegridColumnData: columnsdata,

    };
  }
  catch (err) {

  }
}
);

//fetch data for edit
export const fetchEditThemeData = createAsyncThunk("themestore/fetchEditThemeData ", async (data) => {
  // 
  //

  let newId = data?.search?.replace("?id=", "")
  //

  const fetchData = async () => {
    if (newId !== null) {
      return themeService.getEditThemeData(newId, data?.instance)
    }
  }
  try {
    const rawData = await fetchData();
    const editTheme = [];
    for (const nfrtype of rawData) {
      const cardData = {
        _id: nfrtype?._id,
        themename: nfrtype?.component.themename,
        projecttypename: nfrtype?.component.projecttypename,
        projectversion: nfrtype?.component.projectversion,
        projectversionid: nfrtype?.component?.projectversionid,

        typename: nfrtype?.typename,
        typeid: nfrtype?.typeid,
        packagecomponentid: nfrtype?.packagecomponentid,
        filename: nfrtype?.filename,
        landmark: nfrtype?.landmark,
        isafter: nfrtype?.isafter,
        isreplace: nfrtype?.isreplace,
        filepath: nfrtype?.filepath,
        codesnippet: nfrtype?.codesnippet,
      };
      editTheme.push(cardData);
    }
    //

    return {
      themeedit: editTheme?.length !== 0 ? editTheme : [],
      loading: false,

    };
    ////
  }
  catch (err) {
    //
  }
}
);

//put themepackage data
export const putThemeData = createAsyncThunk("themestore/putThemeData", async (newpackage) => {

  const putData = async () => {
    return themeService.putThemeData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await putData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);
//put putPackageThemeData data
export const putPackageThemeData = createAsyncThunk("themestore/putPackageThemeData", async (newpackage) => {

  const putData = async () => {
    return themeService.putPackageThemeData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await putData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);
//put putPackageCompThemeData data
export const putPackageCompThemeData = createAsyncThunk("themestore/putPackageCompThemeData", async (newpackage) => {

  const putData = async () => {
    return themeService.putPackageCompThemeData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await putData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);

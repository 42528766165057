import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { Modal } from "react-bootstrap";
import Loader from '../../images/loader.gif';
import { ReactComponent as Green_tick } from '../../images/green_tick_progress.svg';
import Spinner from 'react-bootstrap/Spinner';
import { getGPTResponse } from "../../services/openAIService";

let arr = []
// var progressbar = 0;

function FilesPopup({filesArr,gitLabContent}) {
    const dispatch = useDispatch();
    const location = useLocation();
    const { instance, accounts, inProgress } = useMsal();

    ////
    const data = location.search;
    const [show, setShow] = useState(true);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const[progressbar,setprogressbar] = useState(0)
    
    const [filterdFiles,setFilterdFiles] = useState([])
  
    useEffect(()=>{

        //upload files to model api
        // let temp1 = [...filesArr];
        // let temp = temp1.splice(0)
       
        
        const runPrompt = async () => {
            for(var index in filesArr){
                let file = filesArr[index]
                setprogressbar(Number(index)+1)
                //get file content
                let content = await gitLabContent(file.path)
                // let test = await getGPTResponse(content)
                //post it to model api
                if(Number(index)===filesArr.length-1)
                    handleClose();
            }
           
        };
        runPrompt()
        


    },[filesArr])
  

    //Files
    // var filesArr = [];

    return (
        <>
            <Modal show={show}
                onHide={() => { handleClose(); }}
                backdrop="static"
                keyboard={false} 
                dialogClassName="modal-50w">
                <Modal.Header className="rounded-0" closeButton={document.querySelector("#spinnerdiv")?.hidden === false ? false : true} >
                    <Modal.Title className='font_18 font_regular'>{'Loading Project'}</Modal.Title>
                </Modal.Header>
                <Modal.Body className='p-0 position-relative'>
                    <div class="h-100 w-100 bg_grey_2 resultscreen" id="spinnerdiv" hidden={false}>
                        <div class="row g-0 h-100">
                            <div class=" bg_grey_1">
                                <div class="row m-0 p-2" id="5">
                                    <div class=" pb-3 myprofile_progreebar">
                                        <div class="desktop_progress_align">
                                            <div class="d-flex pt-3 pb-2">
                                                <div className='font_14 font_regular text-white'><span style={{ color: "#72B266" }}> 
                                                {filesArr?.length > 0 ? ((progressbar * Math.ceil(100 / filesArr?.length)) > 100 ? 100 : progressbar * Math.ceil(100 / filesArr?.length)) : 0}% Update in progress</span> </div>
                                                <div className='font_14 font_regular ms-auto'><span> {progressbar}</span> / <span>{filesArr?.length > 0 ? filesArr?.length : 0}</span> Completed</div>
                                            </div>
                                            <div class="progress">
                                                <div style={{ width: `${filesArr?.length > 0 ? progressbar * Math.ceil(100 / filesArr?.length) : 0}%` }} class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-12 ">
                                        <ul class="list_progress text-center">
                                            <img className='img-fluid' src={Loader}></img>
                                        </ul>
                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>



                </Modal.Body>
            </Modal>

        </>
    );
}

export default FilesPopup;

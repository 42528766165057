import { createSlice } from "@reduxjs/toolkit";
import { fetchNfrTypeData, fetchPackagesGridData,fetchPackagesComponentData,getPackCompCategory, fetchPackagesTypeGridData, fetchEditPackageData } from "./nfr.actions";

const NfrTypeData = createSlice({
    name: "nfrstore",
    initialState: {
        nfrTypeData: { nfrdata: [], loading: true},
        gridPackage: { packagegrid:[],loading: true, packagegridColumnData:[]},
        gridComponent: { packagecomponent:[],loading: true, packageComponentColumnData:[]},
        packageTypeGrid:{ typegrid: [], loading: true,packageTypeColumnData:[]},
        Categorydata:{ categoryshow: [], loading: true},
        editPackages: { packageedit:[],loading: true},
        editPackageCategory: { packagecategoryedit:[],loading: true},
        
        currentnfrTypeData : {
            nfrtype: {
                id: "",
                name:"",
            },
        },
        currentnfrTypeDataCopy: {
            nfrtype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditNfrTypeDataName: (state, actions) => {
            state.currentnfrTypeData.nfrtype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchNfrTypeData.pending]: (state) => {
            state.nfrTypeData.loading = false;
        },
        [fetchNfrTypeData.fulfilled]: (state,actions) => {
            state.nfrTypeData.nfrdata = actions.payload?.nfrdata;
            state.nfrTypeData.loading = actions.payload?.loading;
        },
        [fetchPackagesGridData.pending]: (state) => {
            state.gridPackage.loading = false;
        },
        [fetchPackagesGridData.fulfilled]: (state,actions) => {
            state.gridPackage.packagegrid = actions.payload?.packagegrid;
            state.gridPackage.packagegridColumnData = actions.payload?.packagegridColumnData;
            state.gridPackage.loading = actions.payload?.loading;
        },
        [fetchPackagesComponentData.pending]: (state) => {
            state.gridComponent.loading = false;
        },
        [fetchPackagesComponentData.fulfilled]: (state,actions) => {
            state.gridComponent.packagecomponent = actions.payload?.packagecomponent;
            state.gridComponent.packageComponentColumnData = actions.payload?.packageComponentColumnData;
            state.gridComponent.loading = actions.payload?.loading;
        },
        [getPackCompCategory.pending]: (state) => {
            state.Categorydata.loading = false;
        },
        [getPackCompCategory.fulfilled]: (state,actions) => {
            state.Categorydata.categoryshow = actions.payload?.categoryshow;
            
            state.Categorydata.loading = actions.payload?.loading;
        },
        [fetchPackagesTypeGridData.pending]: (state) => {
            state.packageTypeGrid.loading = false;
        },
        [fetchPackagesTypeGridData.fulfilled]: (state,actions) => {
            state.packageTypeGrid.typegrid = actions.payload?.typegrid;
            state.packageTypeGrid.loading = actions.payload?.loading;
        },
        //edit reducers
        [fetchEditPackageData.pending]: (state) => {
            state.editPackages.loading = false;
        },
        [fetchEditPackageData.fulfilled]: (state,actions) => {
            state.editPackages.packageedit = actions.payload?.packageedit;
            state.editPackages.loading = actions.payload?.loading;
        },
    },
});

export const { setEditNfrTypeDataName } = NfrTypeData.actions;
export default NfrTypeData.reducer;
import React from 'react'
import { ReactComponent as Plus_menu } from '../../images/plus_menu.svg';
import { fetchProductTypeData, fetchProjectVersionData } from "../store/productType.actions";
import { fetchPackagesTypeGridData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { postThemeData, postPackageThemeData, fetchThemeData } from "../store/theme.actions"
import { memo, useEffect, useState } from 'react';
//import CustomFileUploadDnD from './CustomFileUploadDnD';
import { useLocation, useNavigate, Route } from "react-router-dom";
import { ReactComponent as Delete_icon } from "../../images/delete-icon.svg";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { ReactComponent as DeleteIcon } from '../../images/DeleteIconred.svg';
import { ReactComponent as Add_icon_plus } from '../../images/Add_icon_plus.svg';
import { ReactComponent as Upload_icon } from '../../images/upload_icon.svg';
import { postPackageLayoutData, postLayoutData, fetchEditLayoutsData, fetchLayoutsGridData, putLayoutData, putPackageCompLayoutData, putPackageLayoutData } from '../store/layout.actions';
import {
    containerName,
    cdn_path,
    callMethod,
    uploadFile,
    validateFile,
    deleteAction,
} from "../../common/BLOBStorageConfig";
import { Checkbox } from '@mui/material';
import IconUpload from '../CommanMethods/IconUpload';
import Package from '../CommanMethods/PackageMethods';


function EditLayout() {
    let myval = 0
    let success = 0
    var res1 = ""
    let flag = 1
    //

    var myindex
    const { instance, accounts, inProgress } = useMsal();
    const email = accounts[0] && accounts[0].username;

    const location = useLocation();

    const [getid, setgetid] = useState();
    const [value, setValue] = useState(0);
    const [uploadedBlob, setUploadedBlob] = useState();

    const [editdata, setEditdata] = useState("");


    const dispatch = useDispatch();
    let search = location.search;


    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProductTypeData({ data: "", instance: instance }));
            dispatch(fetchThemeData({ data: "", instance: instance }))
            dispatch(fetchPackagesTypeGridData(instance));
            dispatch(fetchEditLayoutsData({ search, instance: instance }));
            dispatch(fetchLayoutsGridData({ dispatch: dispatch, search, instance: instance })).then((res) => {
                //   
                setEditdata({
                    layoutname: res.payload.layoutsgrid[0]?.layoutname,
                    themename: res.payload.layoutsgrid[0]?.themename,
                    projecttypename: res.payload.layoutsgrid[0]?.projecttypename,
                    projectversion: res.payload.layoutsgrid[0]?.projectversion,
                    projecttypeid: res.payload.layoutsgrid[0]?.projecttypeid,
                    projectversionid: res.payload.layoutsgrid[0]?.projectversionid,
                    description: res.payload.layoutsgrid[0]?.description,
                    iconpath: res.payload.layoutsgrid[0]?.iconpath,
                    layoutid: res.payload.layoutsgrid[0]?.layoutid,
                    id: res.payload.layoutsgrid[0]?.id,
                    isexpand: res.payload.layoutsgrid[0]?.isexpand
                })
            });
        }
    }, [dispatch, instance.getAllAccounts()[0] !== undefined])
    //
    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            //dispatch(fetchProjectVersionData({data:getid,instance:instance}))
        }
    }, [getid, instance.getAllAccounts()[0] !== undefined])

    const editdata1 = useSelector(
        (state) => state.commonReducer.layoutsdata?.layoutsData?.layoutsdata);
    //


    //fetching project types
    const ProjectData = useSelector(
        (state) => state.commonReducer.prodtypedata?.prodTypeData?.prodtypedata);
    const [prodtypedata, setContentData] = useState(ProjectData);
    useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);


    //fetching project versions
    const version = useSelector(
        (state) => state.commonReducer.prodtypedata?.projVersionData?.projversiondata);
    const [projversiondata, setprojversiondata] = useState(version);
    useEffect(() => { setprojversiondata(version); }, [dispatch, version]);


    //fetching theme data
    const theme1 = useSelector(
        (state) => state.commonReducer.theme?.themeTypeData?.themedata);
    const [themedata, setthemedata] = useState(theme1);
    useEffect(() => { setthemedata(theme1); }, [dispatch, theme1]);


    const [inputFields, setInputFields] = useState([
        Object.entries(editdata1).map(
            ([key, data]) => {
                return (

                    {
                        _id: data._id,
                        packagecomponentid: data.packagecomponentid,
                        packagetypename: data.typename,
                        packagetypeid: data.typeid,
                        filename: data.filename,
                        filepath: data.filepath,
                        landmark: data.landmark,
                        isafter: data.isafter,
                        codesnippet: data.codesnippet,
                        isreplace: data.isreplace,

                    })
            }
        )
    ])

    //fetching package grid data
    const packagetype = useSelector((state) => state.commonReducer.nfr.packageTypeGrid.typegrid);
    const [packagetypeshow, setpackagetypeshow] = useState(packagetype);
    useEffect(() => { setpackagetypeshow(packagetype) }, [dispatch, packagetype])


    const handletheme = async () => {
        var themed = document.querySelector('#selectthemedata').selectedIndex;

    }

    const handleSave = async () => {





        {
            try {
                let layoutData = {
                    "_id": editdata.layoutid,

                    "projecttypeid": editdata.projecttypeid,
                    "iconpath": editdata.iconpath,
                    "title": editdata.layoutname,
                    "description": editdata.description,
                    "themeid": editdata.themeid,
                    "isExpand": editdata.isExpand,

                }

                if (instance.getAllAccounts()[0] !== undefined) {
                    dispatch(putLayoutData({ data: layoutData, instance: instance })).then((res) => {


                        if (res.payload)
                            success = 1
                        else
                            alert("Form not submitted 11")


                        let packagelayoutdata = {
                            "_id": editdata.id,
                            "projecttypename": editdata.projecttypename,
                            "projecttypeid": editdata.projecttypeid,
                            "projectversion": editdata.projectversion,
                            "projectversonid": editdata.projectversionid,
                            "themeid": editdata.themeid,
                            "themename": editdata.themename,
                            "createdby": email,
                            "layoutid": editdata.layoutid,
                            "layoutname": editdata.layoutname,
                        }
                        dispatch(putPackageLayoutData({ data: packagelayoutdata, instance: instance })).then((res) => {


                            if (res.payload)
                                success = 1
                            else
                                alert("Form not submitted")

                            inputFields.flat(1).map((data) => {
                                return (dispatch(putPackageCompLayoutData({
                                    data: {
                                        _id: editdata1[0]?._id,
                                        packagecomponentid: data.packagecomponentid,
                                        typename: data.packagetypename,
                                        typeid: data.packagetypeid,
                                        filename: data.filename,
                                        filepath: data.filepath,
                                        landmark: data.landmark,
                                        isafter: data.isafter,
                                        codesnippet: data.codesnippet,
                                        isreplace: data.isreplace,
                                    }, instance: instance
                                })
                                ).then((res) => {
                                    //
                                    if (success == 1) {
                                        if (res.payload) {
                                            alert("Layout edited successfully")
                                            window.location = "/layout"
                                        }
                                        else
                                            alert("Form not submitted")
                                    }
                                    else
                                        alert("Form not submitted")

                                }

                                ))
                            })

                        })
                    })
                }

            } catch (err) {
                //
            }


        }

    }

    useEffect(() => {
        setUploadedBlob({
            imageURL: editdata?.iconpath,
            name: editdata?.iconpath?.replace(process.env.REACT_APP_CDN_PATH + "/" + process.env.REACT_APP_BLOB_CONTAINER + "/", "")
        });
    }, [editdata])

    const handlechange1 = () => {
        let name1 = document.querySelector("#fpath").value;
        setEditdata({ ...editdata, layoutname: name1 });
    }
    const handlechange2 = () => {
        let name1 = document.querySelector("#snippet").value;
        setEditdata({ ...editdata, description: name1 });
    }
    const handleChange2 = async (event) => {
        let name1 = event.target.checked;
        //
        setEditdata({ ...editdata, isexpand: name1 });

    }

    var themeDiv = <div class="col-lg-4 text-start">
        <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Theme</label>
            <div class="select_box">

                <select id="selectthemedata" onChange={handletheme} class="form-select outlined" aria-label="Default select example">
                    <option>{editdata.themename}</option>
                    {themedata.map((themedata, index) => (
                        <option value={themedata.id} id="projectid">{themedata.title}</option>
                    ))}
                </select>

            </div>
        </div>
    </div>


    return (
        <>
            <div class="screen_two">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>Edit Layout</p>
                </div>
                <div class="file_container Addpackage">
                    <div class="row">
                        <IconUpload uploadedBlob={uploadedBlob} setUploadedBlob={setUploadedBlob} />


                        <div class="col-lg-4">
                            <div class=" mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Layout Name</label>
                                <input class="form-control outlined" id="fpath"
                                    value={editdata.layoutname}
                                    onChange={handlechange1} />
                                <div class="form-check pt-3">
                                    <input class="form-check-input"
                                        type="checkbox"
                                        id="isExpand"
                                        onChange={(event) => handleChange2(event)}
                                        checked={editdata.isexpand}

                                        name="isExpand"
                                    />
                                    <label class="form-check-label" for="flexCheckDefault">
                                        isExpand
                                    </label>
                                </div>
                            </div>

                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea class="form-control" id="snippet" rows="3"
                                value={editdata.description}
                                onChange={handlechange2}></textarea>
                        </div>


                    </div>

                    <Package handleSave={handleSave} inputFields={inputFields} setInputFields={setInputFields} editdata1={editdata1}
                        value={value} setValue={setValue} cancelLoc={"/layout"} customDiv={themeDiv} />
                </div>
            </div>


        </>

    )
}

export default EditLayout

import React from 'react'
import { fetchProductTypeData, fetchProjectVersionData,fetchAllVersions } from "../store/productType.actions";
import {  fetchPackagesTypeGridData, postNewPackageDetailData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { postThemeData, postPackageThemeData } from "../store/theme.actions"
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import {  useToasts } from 'react-toast-notifications';
import IconUpload from '../CommanMethods/IconUpload';
import Package from '../CommanMethods/PackageMethods';


function AddThemes() {
    let myval = 0
    let success = 0
    var res1 = ""
    let flag = 1
    
    const { addToast } = useToasts();

    var myindex
    const { instance, accounts } = useMsal();
    const email = accounts[0] && accounts[0].username;
    
    const [getid, setgetid] = useState();
    const [value, setValue] = useState(0);
    const [uploadedBlob, setUploadedBlob] = useState();
   
    const [mediaArr, setmediaArr] = useState([]);
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [imageurl, setimageurl] = useState("");
    const [flandmark,setFlandmark]=useState();
    
    const dispatch = useDispatch();


    // useEffect(() => {
    //     if(instance.getAllAccounts()[0] !== undefined){
    //     dispatch(fetchProductTypeData({data: "", instance: instance}));
    //     dispatch(fetchPackagesTypeGridData(instance));
    //     dispatch(fetchAllVersions(instance))
    //     dispatch(fetchProjectVersionData({data:getid,instance: instance}))
    //     }
    // }, [dispatch,getid, instance.getAllAccounts()[0] !== undefined])

 
 
  
    //fetch componentdata
    const maincontent = useSelector((state) => state.commonReducer.themegrid?.gridTheme?.themegrid);
    const [theme2, settheme2] = useState(maincontent);
    useEffect(()=>{settheme2(maincontent);},[dispatch,maincontent])
   
    

    const [inputFields, setInputFields] = useState([
        {
            packagecomponentid: "",
            packagetypename: "Open this select menu",
            packagetypeid: "",
            filename: "",
            filepath: "",
            landmark: "",
            isafter: true,
            codesnippet: "",
            isreplace: true,
            pkgtype:"",
        }
    ])
    


    var value1

    const handleSave = async () => {
        
       


        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var projecttxt = document.querySelector('#selectProjectType').options[sI].label
        var projectid = document.querySelector('#selectProjectType').options[sI].value


        var vI = document.querySelector('#selectVersion').selectedIndex;
        var versionid = document.querySelector('#selectVersion').options[vI].value
        var versiontxt = document.querySelector('#selectVersion').options[vI].label
    
       

        if (sI === 0 || vI === 0 )
        {
            value1=1
            if(sI===0)
            addToast("Please select project type", { appearance: 'error', autoDismiss: 'true' })
            else if(vI===0)
            addToast("Please select project Version", { appearance: 'error', autoDismiss: 'true' })
            else
            value1=0

        }
        inputFields.flat(1)?.map((data)=>{
                   
            if(data?.filename==="")
            {
                value1=1
            addToast("File name is mandatory",{ appearance: 'error', autoDismiss: 'true' })
            }
            else if(data?.filepath==="")
            {
                value1=1
                addToast("File path is mandatory",{ appearance: 'error', autoDismiss: 'true' })
            }
            else if(data?.codesnippet==="")
            {
                value1=1
                addToast("Code snippet is mandatory",{ appearance: 'error', autoDismiss: 'true' })
            }
            else if(data?.packagetypeid===0)
            {
                value1=1
            addToast("Select any package type",{ appearance: 'error', autoDismiss: 'true' })
            }
            else
            {
                value1=0
            }
        })


        var tname=document.querySelector('#tname').value
        var tdesc=document.querySelector('#tdesc').value
       if(tname==="" || tdesc===""){
           if(tname===""){
           value1=1
           addToast("Themename is mandatory", { appearance: 'error', autoDismiss: 'true' })
           }
           else if(tdesc===""){
            value1=1
            addToast("Description is mandatory", { appearance: 'error', autoDismiss: 'true' })
           }

       }
       theme2.map((data)=>{
           if(tname===data.themename && projecttxt===data.projecttypename ){
               value1=1
               addToast("Data already exits", { appearance: 'error', autoDismiss: 'true' })
           }

       })

        {
            try {
                let themeData = {

                    "useremail": email,
                    "projecttypeid": projectid,
                    "iconpath": uploadedBlob?.imageURL,
                    "title": name,
                    "description": desc,

                }
                if(value1===0){
                
                if(instance.getAllAccounts()[0] !== undefined){
                dispatch(postThemeData({data:themeData,instance:instance})).then((res) => {
                    
                    //
                    //
                    res1 = res.payload.data._id

                    //
                    if (res.payload)
                        success = 1
                    else
                        alert("Form not submitted")

                   

                    let packagethemedata = {

                        "projecttypename": projecttxt,
                        "projecttypeid": projectid,
                        "projectversion": versiontxt,
                        "projectversionid": versionid,
                        "themeid": res.payload.data._id,
                        "themename": name,
                        "createdby": email,
                        "layoutid": null,
                        "layoutname": null,
                    }
                    dispatch(postPackageThemeData({data:packagethemedata,instance:instance})).then((res) => {
                        
                       
                        res1 = res.payload.data._id
                        
                        if (res.payload)
                            success = 1
                        else
                            alert("Form not submitted")

                        let str = []
                        str = inputFields.map((data) => {
                            return {

                                packagecomponentid: res.payload.data._id,
                                typename: data.packagetypename,
                                typeid: data.packagetypeid,
                                filename: data.filename,
                                filepath: data.filepath,
                                landmark: data.landmark,
                                isafter: data.isafter,
                                codesnippet: data.codesnippet,
                                isreplace: data.isreplace,
                            }
                        }
                        )
                       
                        
                        dispatch(postNewPackageDetailData({data:str,instance:instance})).then((res) => {
                            //
                            if (success == 1) {
                                if (res.payload) {
                                    alert("Theme added successfully")
                                    window.location = "/themes"
                                }
                                else
                                    alert("Form not submitted1")
                            }
                            else
                                alert("Form not submitted2")

                        })

                    })
                })
            }
                }
            } catch (err) {
                
            }

        }

    }

    const handlechange1 = (event) => {
        setName(event.currentTarget.value);
    }
    const handlechange2 = (event) => {
        setDesc(event.currentTarget.value);
    }

   
    return (
        <>
            <div class="screen_two">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>Add Theme</p>
                </div>
                <div class="file_container Addpackage">
                    <div class="row">
                        <IconUpload uploadedBlob={uploadedBlob} setUploadedBlob={setUploadedBlob}/>

                        <div class="col-lg-4">
                            <div class=" mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Theme Name</label>
                                <input class="form-control outlined" id="tname" onChange={handlechange1} />
                            </div>

                        </div>
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea class="form-control" id="tdesc" rows="3" onChange={handlechange2}></textarea>
                        </div>
                       

                    </div>
                    <Package handleSave={handleSave} inputFields={inputFields} setInputFields={setInputFields}
                    value={value} setValue={setValue} cancelLoc={"/themes"} />
                </div>
            </div>


        </>

    )
}

export default AddThemes


import { Search, Plus } from 'react-bootstrap-icons';
import {  Link } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import {  fetchPackagesComponentData } from '../store/nfr.actions';
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import { fetchProductTypeData, fetchProjectVersionData } from "../store/productType.actions";
import { fetchThemeGridData } from "../store/theme.actions"
import {  useMsal } from "@azure/msal-react";
function Themes() {

    const [getid, setgetid] = useState("6372366d1b568e00d8af2e44");
    const [projectType, setprojectType] = useState("PWA React");
    const [value, setValue] = useState(0);
    let myval = 0

    const { instance } = useMsal();

    const dispatch = useDispatch();

    useEffect(() => {
        
        if (instance.getAllAccounts()[0] !== undefined) {

            dispatch(fetchThemeGridData({ dispatch: dispatch, instance: instance }));
            dispatch(fetchProjectVersionData({ data: getid, instance, instance }))
            dispatch(fetchPackagesComponentData());
            dispatch(fetchPackagesComponentData({ instance: instance, dispatch: dispatch }));
            dispatch(fetchProductTypeData({ data: "", instance: instance }));

        }
    }, [dispatch, getid, projectType, instance.getAllAccounts()[0] !== undefined])

    //fetching project types
    const ProjectData = useSelector(
        (state) => state.commonReducer.prodtypedata.prodTypeData.prodtypedata);
    const [prodtypedata, setContentData] = useState(ProjectData);
    useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);


    //fetching package grid data

    const maincontent = useSelector((state) => state.commonReducer.themegrid?.gridTheme?.themegrid);
    const [packagegrid, setpackagegrid] = useState(maincontent);

    useEffect(() => { setpackagegrid(maincontent?.filter(x => x.projecttypename?.includes(projectType))) }, [dispatch, maincontent, projectType])



    //fetching columns data
    const columncontent = useSelector((state) => state.commonReducer.themegrid?.gridTheme?.themegridColumnData);
    const [packagegridColumnData, setpackagegridColumnData] = useState(columncontent);
    useEffect(() => { setpackagegridColumnData(columncontent) }, [dispatch, columncontent])
    //

    //fetching project versions
    const version = useSelector(
        (state) => state.commonReducer.prodtypedata.projVersionData.projversiondata);
    const [projversiondata, setprojversiondata] = useState(version);
    useEffect(() => { setprojversiondata(version); }, [dispatch, version]);


    const addOptionID1 = async (e) => {

        setValue(1)
        myval = 1

    }


    //For fetching versions as per project type
    const addOptionID = async (e) => {


        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var sprojType = document.querySelector('#selectProjectType').options[sI].label
        setprojectType(sprojType)

        var getID = document.querySelector('#selectProjectType').options[sI].value

        setgetid(getID)

    }

    const Searchtheme = (e) => {
        var themeinput = document.querySelector("#themesearch").value.toLowerCase()
        setpackagegrid(packagegrid.filter(x => x.themename?.toLowerCase().includes(themeinput) || x.description?.toLowerCase().includes(themeinput)))

        if (themeinput.length === 0) {
            setpackagegrid(maincontent)
        }
    }

    return (
        <>
            <div class="screen_three">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>Themes</p>
                </div>
                <div class="file_container " id="container1">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="bg_grey_container">
                                <div class="row">
                                    <div class="col-lg-7 text-start">
                                        <div class="row ">
                                            <div class="col-lg-3 ">
                                                <div class="select_box package">


                                                    <select id="selectProjectType" onChange={() => {
                                                        addOptionID();
                                                        if (instance.getAllAccounts()[0] !== undefined) {
                                                            dispatch(fetchProjectVersionData({ data: getid, instance: instance }))
                                                        }
                                                    }}
                                                        class="form-select outlined" aria-label="Default select example">

                                                        {prodtypedata?.map((prodtypedata, index) => (
                                                            <option value={prodtypedata.id} id="projectid">{prodtypedata.title}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="select_box package">
                                                    <select onChange={addOptionID1} class="form-select outlined" aria-label="Default select example">
                                                        <option selected>Project Version</option>
                                                        {projversiondata?.map((prodtypedata, index) => (

                                                            <option value={prodtypedata.id}>{prodtypedata.version}</option>


                                                        ))}


                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-5 d-flex justify-content-end">
                                        <div class="d-flex">
                                            <div class="custom_inputbtn package">
                                                <input type="text" class="form-control bg-transparent" id="themesearch" placeholder="Search" onChange={Searchtheme} />
                                                <div class="searchicon"><Search /></div>
                                            </div>
                                        </div>
                                        <div class="ms-auto whitespace_nowrap">
                                            <Link to="/themes/addthemes">
                                                <button type="button" class="btn btn-primary btn_custom_primary">
                                                    <Plus size={20} />
                                                    Add Themes
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <div className='customdatagrid' style={{ width: '100%' }}>
                                <DataGrid autoHeight rows={packagegrid ? packagegrid : []} columns={packagegridColumnData ? packagegridColumnData : []}

                                    colWidths={[50, 100, 200, 400]}

                                    initialState={{
                                        pagination: {
                                            pageSize: 10,
                                        },
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>

        </>
    )
}

export default Themes

import { X } from 'react-bootstrap-icons';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { ReactComponent as Search_icon } from '../images/search_icon.svg';
import React, { memo, useEffect, useState, useMemo, useRef } from 'react';
import DraggableControl from './DraggableControl';
import { useDispatch, useSelector } from "react-redux";
import Button from 'react-bootstrap/Button';
import { fetchControlData, fetchControlDetailData } from "../main/store/inputControl.actions";
import { ReactComponent as File_menu } from '../images/file_menu.svg';
import { ReactComponent as Plus_menu } from '../images/plus_menu.svg';
import { ReactComponent as Api_menu } from '../images/api_menu.svg';
import Modal from 'react-bootstrap/Modal';
import { useLocation } from "react-router-dom";
import { fetchGitURL, getProjectNameGitUrl, putTreeUrl } from '../main/store/userProject.actions';
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { Octokit } from "octokit";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { fetchDropAreaData, postDataSource, fetchAllDataSource } from '../main/store/dropArea.actions';
import axios from "axios";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { TextField } from "@material-ui/core";
import InputGroup from 'react-bootstrap/InputGroup';
import Tab from 'react-bootstrap/Tab';
import Tabs from 'react-bootstrap/Tabs';
import { DataGrid, GridColDef, GridValueGetterParams } from '@mui/x-data-grid';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { Plus, XLg } from 'react-bootstrap-icons';
import { useNavigate } from "react-router-dom";
import { FaHamburger } from 'react-icons/fa';
import { ReactComponent as Edit_icon_table } from '../images/edit_icon_table.svg';
import { ReactComponent as Delete_icon_table } from '../images/delete_icon_table.svg';
import Accordion from 'react-bootstrap/Accordion';

// import {
//     Box, Table,
//     TableBody, TableCell, TableHead, TableRow
// } from "@material-ui/core";
import { makeStyles } from "@material-ui/core/styles";
import Table from 'react-bootstrap/Table';

var filename;
var folderrname
var mainURL;
var mainSubURL;
var gitur;
var totalNoOfFiles;
var decoded;
let subPathArr
var foldersfilename;
let foldernameArr = [];
var treeID = "";
var filesName;
var addFolderName
var projectName = ""
var result;
var tress;
var statusRes;



//api tab 
var dropdownValue;
var urlParam;
var setRes = [];
// var mainRes;
var dataSourceName = ""
var dataSourceToken = "";
var mainResList = [];
var dropdownKeys = [];
var selectedDropdownKeyValue;
var apiref;
var TempArr = []
var list
let CrossSymbol, search;;
let newlocation
let KEY = "";
var isMap = ""
var getTarget="",postTarget="",putTarget="",deleteTarget="",
GetUrlLink="",PostUrlLink="",putUrlLink="",deleteUrlLink=""
var CrudMicroserviceList ={}
var AddDatasourceFlag = false
// const columns: GridColDef[] = [
//     //   { field: 'id', headerName: 'Key', width: 90 ,hidden: true},
//     {
//         field: 'firstName',
//         headerName: 'Key',
//         flex: 1.0,
//         disableClickEventBubbling: true,

//         renderCell: (params) => {
//             return (
//                 <TextField
//                     onChange={(e) =>
//                         //   params.api.updateRows([{ ...params.row, firstName: e.target.value }])
//                         updateRows(e.target.value, params.row.id, params.field)
//                     }
//                 />
//             );
//         }
//     },
//     {
//         field: 'lastName',
//         headerName: 'Value',
//         flex: 1,
//         width: 150,
//         disableClickEventBubbling: true,

//         renderCell: (params) => {
//             return (
//                 <TextField
//                     onChange={(e) =>
//                         //   params.api.updateRows([{ ...params.row, lastName: e.target.value }])
//                         updateRows(e.target.value, params.row.id, params.field)
//                     }
//                 />
//             );
//         }
//     },
// ];


// const updateRows = (value, id, field) => {
//     
//     const item = Rows.find((item) => item.id === id);
//     item[field] = value;
//     
//     apiref = Rows
//     
// };



// const Rows = [
//     { id: 1, firstName: null, lastName: null },
//     { id: 2, firstName: null, lastName: null },
//     { id: 3, firstName: null, lastName: null },

// ];



// function useApiRef() {
//     const apiRef = useRef(null);
//     const _columns = useMemo(
//         () =>
//             columns.concat({
//                 field: "__HIDDEN__",
//                 width: 0,
//                 renderCell: (params) => {
//                     apiRef.current = params.api;
//                     return null;
//                 }
//             }),
//         [columns]
//     );

//     return { apiRef, columns: _columns };
// }

// const useStyles = makeStyles({
//     root: {
//         "& > *": {
//             borderBottom: "unset",
//         },
//     },
//     table: {
//         minWidth: 650,
//     },
//     snackbar: {
//         bottom: "104px",
//     },
// });
function PlusMenuRight({ addToast, pages, selectedPage, setselectedPage, openToggle, setOpenToggle, sidebarhide, setSidebarhide, isActive, setActive, Entity,setEntity }) {
    const dispatch = useDispatch();
    const navigate = useNavigate();
    //
    const { instance, accounts, inProgress } = useMsal();
    const name = accounts[0] && accounts[0].username;
    const [toggeleTab, setToggleTab] = useState();
    // const { apiRef, columns } = useApiRef();
    // const classes = useStyles();
    useEffect(() => {
        setToggleTab(openToggle.sidebarhide)
    }, [openToggle?.sidebarhide])
    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchControlData())
        dispatch(fetchControlDetailData())
        dispatch(fetchAllDataSource({data:null, instance:instance}))
        dispatch(getProjectNameGitUrl(data))
        }
    }, [dispatch,instance.getAllAccounts()[0] !== undefined])


    const [insertControlsData, setInsertControlsData] = useState([
        {
            "id": 1,
            "name": "Button"
        },
        {
            "id": 2,
            "name": "Text Input"
        },
        {
            "id": 3,
            "name": "Drop down"
        },
        {
            "id": 4,
            "name": "Date Picker"
        },
        {
            "id": 5,
            "name": "Check Box"
        },
        {
            "id": 6,
            "name": "Grid"
        }

    ]);

    //fetch control type menu
    const maincontent = useSelector(
        (state) => state.commonReducer?.controldata?.controlTypeData?.controldata);
    const [controldata, setcontroldata] = useState(maincontent);
    useEffect(() => { setcontroldata(maincontent); }, [dispatch, maincontent]);
    //

    //fetch control detail menu data
    const menucontent = useSelector(
        (state) => state.commonReducer?.controldata?.controlDetailData?.controldetaildata);
    const [controldetaildata, setcontroldetaildata] = useState(menucontent);
    useEffect(() => { setcontroldetaildata(menucontent); }, [dispatch, menucontent]);
    //

    //fetch data scorce name
    const datasource = useSelector((state) => state.commonReducer?.droparea?.datasource?.datasource);
   // 

    // const [sidebarhide, setSidebarhide] = useState("two");

    //FileCreation Methods
    const location = useLocation();
    const data = location.search;

    useEffect(() => {
        dispatch(getProjectNameGitUrl(data))
    }, [dispatch]);

    useEffect(() => {
        dispatch(getProjectNameGitUrl(data))
    }, [data]);

    const getDetails = useSelector((state) => state.commonReducer?.singleProjectName?.singleProjectName?.singleProjectName);
    const [getDetailsData, setgetDetailsData] = useState(getDetails);
    useEffect(() => { setgetDetailsData(getDetails) }, [dispatch, getDetails])
    //

    const [show, setShow] = useState(false);  // modal popup for adding file and folder name
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);

    const handleShow = () => setShow(true);
    const handleClose = () => setShow(false);

    const [content, setContent] = useState([]);
    const [FileContent, setFileContent] = useState([]);
    const [folderFileContent, setFolderFileContent] = useState([]);
    const [finalTree, setFinalTree] = useState([]);
    const [style, setStyle] = useState("100%");
    const [fileNamee, setFileNamee] = useState([]);
    const [buttonDisable, setbuttonDisable] = useState(false);
    const [urlVerbList, setUrlVerbList] =  useState([])
    const [crudList,setCrudList] = useState([])
    const [isEdit, setEdit] = useState(false)
    let finalUrl = "";
    let url = "";
    let urlpath = [];


    if (getDetailsData !== undefined) {
        if (getDetailsData[0]?.treeURL !== undefined)

            var urlll = getDetailsData[0]?.treeURL
        //
        //get main tree url
        gitur = getDetailsData[0]?.treeURL?.replace("https://api.github.com", "")
        //

        mainURL = "GET " + gitur
        //

        //for showing file contents GET call
        mainSubURL = mainURL.substring(0, mainURL.indexOf('/git'))
       // 

        //get project name
        projectName = getDetailsData[0]?.projectname
        //

    }
    //for homepage
    const getContents = async () => {
        const octokit = new Octokit({
            auth: getDetailsData[0]?.accessToken,
        });
        //       const response1 = await octokit.request(
        //         `GET /repos/RapidX-Developer-Studio/${projectName}/commits/main`
        //         );
        //   treeID = response1.data.commit.tree.sha;
        //   var repotreeurl = "https://api.github.com/repos/RapidX-Developer-Studio/"+projectName+"/git/trees/" + treeID + "?recursive=1";
        //   

        const response = await octokit.request(
            mainURL
        );
        //
        tress = response.data.tree;

        totalNoOfFiles = response.data.tree.length - 2
        //

        let newData = response.data.tree?.filter(
            (data) => !data.path.includes("/")
        );
        //
        newData = newData.map((temp) => {
            return {
                path: temp.path,
                type: temp.type,
                url: temp.url,
                subpath: []

            }
        })
        //
        setContent(newData);
    };
    useEffect(() => {
        
        if (getDetailsData !== undefined) {
            if (gitur !== undefined) {
                getContents().catch(
                   // console.error
                    );
            }
        }
    }, [gitur]);

   // 


    //display folder contents
    const getUrl = file => e => {
        
        //
        folderrname = file.path
        const octokit = new Octokit({
            auth: getDetailsData[0]?.accessToken,
        });
        const response = octokit.request(
            mainURL
        ).then((response) => {
            let tree = response.data.tree;
            //

            for (let index = 0; index < tree.length; index++) {
               // )
                if (tree[index].path === folderrname) {
                    url = tree[index].url;
                }
            }
           // 
            content.filter(x => x.path === folderrname)
           // )
            getFolderContents(url, file, folderrname);
        });

    };



    const getFolderContents = async (url, file, folderrname) => {
        const octokit = new Octokit({
            auth: getDetailsData[0]?.accessToken,

        });

        finalUrl = url;
       // 
        finalUrl = finalUrl.replace("https://api.github.com", "");
        const response = await octokit.request(`GET ${finalUrl}?recursive=1`);
       // 
        let tree = response.data.tree;
        //
        subPathArr = []
        tree = tree?.map((temp) => {
            if (temp.path.includes(".js") && !temp.path.includes(".json")) {
                subPathArr.push(temp);
            }
            //

            return {
                parent: folderrname,
                path: temp.path,
                type: temp.type,
                url: temp.url,
                subpath: []
            }
        });
        file.subpath = subPathArr
        // 
        // 

        setFinalTree(tree);

    };
    //


    //Display file content
    const showFileContent = file => e => {
        
        filename = file.path
        //

        const octokit = new Octokit({
            auth: "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",
        });
        const response = octokit.request(

            `${mainSubURL}/contents/${filename}`
        ).then((response) => {
            //
            let decoded = base64_decode(response.data.content);
            //
            setFileContent(decoded);
            setFolderFileContent("")
            document.querySelector("#activeli").hidden = false
        });



    }
   // 

    //Display Folder File Contents
    const showFolderFileContent = file => e => {
        
        folderrname = file.parent
        //
        foldersfilename = file.path
        //

        if (foldernameArr !== foldersfilename) {
            foldernameArr.push(foldersfilename)
           // 
        }

        setFileNamee([...fileNamee, foldersfilename])
        //
        // fileNamee?.map((file) => (
        //     
        // ))

        const octokit = new Octokit({
            //      auth: "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",
            auth: getDetailsData[0]?.accessToken,

        });
        //

        const response = octokit.request(
            `${mainSubURL}/contents/${folderrname}/${foldersfilename}`

        ).then((response) => {
            // 
            // 

            if (foldersfilename.includes('.png')) {
                decoded = <img src={`https://raw.githubusercontent.com/RapidX-Developer-Studio/CheckCount/main/${folderrname}/${foldersfilename}`}></img>
                //decoded = <img src={response.data.content}></img>


            } else {
                decoded = base64_decode(response.data.content);
            }
            //
            setFolderFileContent(decoded);
            setFileContent("")
            document.querySelector("#activeli").hidden = false
        });
       // 
    }
    //



    //Put call for adding new file
    const addFile = async () => {
        
        filesName = document.querySelector("#fileName").value
        //
        addFolderName = document.querySelector("#folderName").value
        //

        if (filesName === "" && addFolderName === "Select any option") {
            document.querySelector("#fileNameErr").innerText = "Please enter file name"
            document.querySelector("#fileName").className = "border-danger form-control"
            document.querySelector("#folderNameErr").innerText = "Please select folder name"
        } else if (addFolderName === "Select any option") {
            document.querySelector("#folderNameErr").innerText = "Please select folder name"
        } else if (filesName === "") {
            document.querySelector("#fileNameErr").innerText = "Please enter file name"
            document.querySelector("#fileName").className = "border-danger form-control"
        }
        else if (document.querySelector("#fileNameErr").innerText !== "") {
            alert("Invalid file name")
        }
        else if (document.querySelector("#folderNameErr").innerText !== "") {
            alert("Please select folder name")
        }
        else {
            setbuttonDisable(true)
            try {
                const octokit = new Octokit({
                    auth: getDetailsData[0]?.accessToken,
                });

                const response = await octokit.request(
                    `PUT /repos/RapidX-Developer-Studio/${projectName}/contents/${addFolderName}/${filesName}`,
                    {
                        message: "Added new file",
                        content: ""
                    }
                )
               // 
                statusRes = response.status
            } catch (error) {
                //
            }
            if (statusRes === 201) {
                updateContents();

            } else {
                alert("File name already exists")
                setbuttonDisable(false)
            }
        }
    }

    //Update file contents
    const updateContents = async () => {
        
        try {
            const octokit = new Octokit({
                auth: getDetailsData[0]?.accessToken
            });
            const response = await octokit.request(
                `GET /repos/RapidX-Developer-Studio/${projectName}/commits/main`
            );
            treeID = response.data.commit.tree.sha;
            var repotreeurl = "https://api.github.com/repos/RapidX-Developer-Studio/" + projectName + "/git/trees/" + treeID + "?recursive=1";
            //
        } catch (error) {
            //
        }
        let editTreeId = {
            "_id": getDetailsData[0]?.id,
            "repotreeurl": repotreeurl
        };
        dispatch(putTreeUrl({data:editTreeId,instance:instance})).then(() => {
            dispatch(getProjectNameGitUrl(data)).then(() => {
                handleClose()
                setbuttonDisable(false)


            })
        })

    }



    let dropdownArr = <></>
    if (fileNamee.length === 0) {
        dropdownArr = <div></div>
    } else {
        dropdownArr = <><DropdownButton variant="outline-secondary"
            title="Files"
            id="input-group-dropdown-4"
            align="end">
            {fileNamee?.map((file) => (
                <Dropdown.Item href="#">{file}</Dropdown.Item>
            ))}
        </DropdownButton></>
    }

    //

    function handleFileName() {
        var filenamee = document.querySelector("#fileName").value
        var pattern = /^[A-Za-z]{1,}\.(?:js)$/;
        result = pattern.test(filenamee);

        if (result === false) {
            document.querySelector("#fileName").className = "border-danger form-control"
            document.querySelector("#fileNameErr").innerText = "Invalid file name"
        } else {
            document.querySelector("#fileName").className = " form-control"
            document.querySelector("#fileNameErr").innerText = ""
        }
    }
    function handleFolderName() {
        if (document.querySelector("#folderName").value === "Select any option") {
            document.querySelector("#folderNameErr").innerText = "Please select folder name"
        } else {
            document.querySelector("#folderNameErr").innerText = ""
        }
    }


    //API FILE DATA 

    const [showApi, setShowApi] = useState(false);
    const handleCloseApi = () => {
        setShowApi(false)
        setshowRes([])
        setMainRes("")
        dropdownKeys = []
        setRows([])
    }
    const handleShowApi = () => setShowApi(true);
    // const [style, setStyle] = useState("100%");
    const [getData, setGetData] = useState([]);
    const [postData, setPostData] = useState([]);
    const [dataSource, setDataSource] = useState([])
    const [gridsName, setGridsName] = useState("")
    const [showRes, setshowRes] = useState([])
    const [paramsList, setparamsList] = useState([])
    const [ApiButtonDisable, setApiButtonDisable] = useState(true);
    const [disable, setDisable] = useState(true);
    const [mainRes, setMainRes] = useState("")
    const [SEARCH, setSearch] = useState("false");
    const [suggestionsActive, setSuggestionsActive] = useState(false);
    const [value, setValue] = useState("");
    const [ver, setVer] = useState("false");
    const [suggestions, setSuggestions] = useState([]);
    const [suggestionIndex, setSuggestionIndex] = useState(0);
    const [getApiAttributes, setGetApiAttributes] = useState([])
    const [hideWebStudio, setHideWebStudio] = useState(1)
    const [hideHamburger, setHideHamburger] = useState(0)
    const [datasourceDisableGetFlag, setDatasourceDisableGetFlag] = useState(false)
    const [datasourceDisablePostFlag, setDatasourceDisablePostFlag] = useState(false)
    const [datasourceDisablePutFlag, setDatasourceDisablePutFlag] = useState(false)
    const [datasourceDisableDeleteFlag, setDatasourceDisableDeleteFlag] = useState(false)


    const [rows, setRows] = useState([
        { id: 1, firstname: "", lastname: "", description: "" },
    ]);


    // Function For adding new row 
    const handleAdd = () => {
        setRows([
            ...rows,
            {
                id: rows.length + 1, firstname: "",
                lastname: "", description: ""
            },
        ]);
    };

   

    //Retrieve values of textfields of rows
    const handleInputChange = (e, index) => {
        setDisable(false);
        const { name, value } = e.target;
        list = [...rows];
        list[index][name] = value;
        var obj = {}
        list.forEach(element => {
            obj[element.firstname] = element.lastname
            //
            TempArr.push(obj)
            //

        });
        setRows(list);
    };

    //handle delete row
    const handleRemoveClick = (i) => {
        const list = [...rows];
        list.splice(i, 1);
        setRows(list);
    };


    //data of controls
    useEffect(() => {
        // dispatch(fetchDropAreaData());
       // 
    }, [dispatch])
    const dropAreaData = useSelector((state) => state.commonReducer?.droparea?.dropAreaData?.dropAreaData);
    const [controlsData, setcontrolsData] = useState(dropAreaData);
    useEffect(() => { setcontrolsData(dropAreaData); }, [dispatch, dropAreaData]);
    // 
    // 

    //send button post call
    function handleSend() {
            
        AddDatasourceFlag = true

        setApiButtonDisable(false)
        setMainRes("")
        dropdownValue = document.querySelector("#verb").value
       // 
        urlParam = document.querySelector("#url").value
        //
        dataSourceName = document.querySelector("#name").value
       // 
        dataSourceToken = document.querySelector("#token").value
        //

        if (dropdownValue === "Get") {
            setMainRes("")
            setRows([])
            const res = axios(urlParam, { headers: { "Authorization": `Bearer ${dataSourceToken}` } }).then((res) => {
                dropdownKeys = []
                //

                setRes = res.data
                //
                setGetData([...getData, res.data])
                //
                // mainRes = JSON.stringify(setRes, null, 4)
                setMainRes(JSON.stringify(setRes, null, 4))
                //
                mainResList = [{ setRes }]
                //

                for (var key in mainResList[0]?.setRes[0]) {
                    if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                        //
                        dropdownKeys.push(key)
                        //
                    }
                }
            });

        } else if (dropdownValue === "Post") {
            
            setMainRes("")
           // 
            var rowlength = rows.length
            var tempArrLen = TempArr.length
            var tempArrs = TempArr.slice(-[rowlength])
            //
            setparamsList([tempArrs[tempArrs.length - 1]])
            setshowRes(JSON.stringify([tempArrs[tempArrs?.length - 1]], null, 4))
            const res = axios.post(urlParam, {
                headers: { "Authorization": `Bearer ${dataSourceToken}` },
                body: tempArrs[tempArrs.length - 1]
            }).then((res) => {
                //
                setMainRes("Executed with status code " + res.status)

            });


        } else if (dropdownValue === "Delete") {
            
            setMainRes("")
            try {
                const res = axios.delete(urlParam, { headers: { "Authorization": `Bearer ${dataSourceToken}` } }).then((res) => {
                    //
                    setMainRes("Executed with status code " + res.status)
                    //

                })
            } catch (err) {
                //
            }
        } else if (dropdownValue === "Put") {
            
            setMainRes("")
            var rowlength = rows.length
            var tempArrLen = TempArr.length
            var tempArrs = TempArr.slice(-[rowlength])
            //
            setparamsList([tempArrs[tempArrs.length - 1]])
            setshowRes(JSON.stringify([tempArrs[tempArrs.length - 1]], null, 4))


            const res = axios.put(urlParam, {
                headers: { "Authorization": `Bearer ${dataSourceToken}` },
                body: tempArrs[tempArrs.length - 1]
            }).then((res) => {
              //  
                setMainRes("Executed with status code " + res.status)
            });
        }
        setMainRes("Not Found : Executed with status code 404")
    }
    // 
    // 



    function addDataSource() {
        

        //
        dataSourceName = document.querySelector("#name").value
       // 

        var CrudDatasourceName = document.querySelector("#CrudMicroserviceEntityName").value
        //
        // isMap = document.querySelector("#isMap").checked
        // 
        //

        if(AddDatasourceFlag === true){
            if (dataSourceName === ""  ) {
                addToast("Please enter entity name", { appearance: 'error', autoDismiss: 'true' })
            }else if(datasource?.find((x) => x.datasourcename === dataSourceName)){
            addToast("Data Source name already exists", { appearance: 'error', autoDismiss: 'true' })
            }
            else{
                SaveDataSource()
            }
        }else{
            if (CrudDatasourceName === ""){
           
                addToast("Please enter entity name ", { appearance: 'error', autoDismiss: 'true' })
             
        }
        else if(datasource?.find((x) => x.datasourcename === CrudDatasourceName)) {
            addToast("Data Source name already exists", { appearance: 'error', autoDismiss: 'true' })

        }else if(GetUrlLink === "" ||  PostUrlLink === "" || putUrlLink === "" || deleteUrlLink === ""){
            addToast("Please enter URL" ,{ appearance: 'error', autoDismiss: 'true' })
        }
        else{
            SaveDataSource()
            
        }
        } 

        function SaveDataSource() {
            setApiButtonDisable(true)
            selectedDropdownKeyValue = document.querySelector("#ids")?.value
            //
          

          var  SimpleMicroserviceArr = {
            ...SimpleMicroserviceArr,
            "httpverb":dropdownValue,
            "url":urlParam
        }

         if(CrudDatasourceName !== ""){
            setEntity(CrudDatasourceName)
         }  
        //
            // urlVerbList.push(tempArr)
            let body = {
                datasourcename: AddDatasourceFlag === true? dataSourceName : CrudDatasourceName,              
                useremail: name,
                datasourcetoken: dataSourceToken,
                index: selectedDropdownKeyValue,
                params: paramsList,
                ismap: isMap,
                httpobject :AddDatasourceFlag === true ? SimpleMicroserviceArr : crudList


            }

            if(instance.getAllAccounts()[0] !== undefined){
            dispatch(postDataSource({data:body, instance:instance})).then((res) => {
               
                dispatch(fetchAllDataSource({data:null, instance:instance})).then((res) => {
                    setShowApi(false);
                    setApiButtonDisable(false)
                    setshowRes([])
                    setMainRes("")
                    dropdownKeys = []
                    // isMap = ""
                    setRows([])
                })
           
            }) }



        }

    }


    let showGetDropdown = <></>
    if (dropdownValue === "Get" || getTarget === "Get") {

        showGetDropdown = <>
            <select id="ids">
                {dropdownKeys?.map((keyss) => (
                    <option href="#">{keyss}</option>
                ))}
            </select></>
    } else {
        showGetDropdown = <div></div>
    }

    //on cllick of cross icon
    const endsearch = () => {
        // 
        location.search = ''
        newlocation = ""
        document.querySelector('#keyWord').value = ''
        setVer("false")
        setSearch("false")
        // navigate({
        //     pathname: '/search',
        //     search: newlocation
        //     })
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchAllDataSource({data:newlocation, instance:instance}))
        }
        setValue("");
        setSuggestions([]);
        setSuggestionsActive(false);
    }
    //on change of search bar
    const handleChangeprojtype = (e) => {
        
        if ((e.target.value.length > 0) || (e.target.value.length === 1)) {
            setVer("true")
            setSearch("false")
        } else if (e.target.value.length === 0) {
            CrossSymbol = <span></span>
            setVer("false")
        }
        const query = e.target.value.toLowerCase();
        setValue(query);
        if (query.length > 2) {
            let data1 = datasource?.map(x => x.datasourcename)
            const filterSuggestions = data1.filter(
                (suggestion) => suggestion.toLowerCase().indexOf(query) > -1
            );
            setSuggestions(filterSuggestions)
            setSuggestionsActive(true)
        } else {
            setSuggestionsActive(false)
        }
    };


    const handleKeyDownprojtype = (e) => {
        // UP ARROW
        if (e.keyCode === 38) {
            if (suggestionIndex === 0) {
                return;
            }
            setSuggestionIndex(suggestionIndex - 1);
        }
        // DOWN ARROW
        else if (e.keyCode === 40) {
            if (suggestionIndex - 1 === suggestions.length) {
                // 
                return;
            }
            setSuggestionIndex(suggestionIndex + 1);
        }
        else if (e.keyCode === 13) {
            setValue(suggestions[suggestionIndex]);
            setSuggestionIndex(0);
            setSuggestionsActive(false);
            setSearch("false")
            
            let EnterKey = suggestions[suggestionIndex]
            if (EnterKey === "" || EnterKey === undefined) {
                EnterKey = document.querySelector('#keyWord').value
            }
            if (value === '') {
                newlocation = ""
            } else {
                //
                newlocation = "?search=" + EnterKey;
                //   navigate({
                //     pathname: '/search',
                //     search: newlocation
                //     })
                setSearch("true")
                if(instance.getAllAccounts()[0] !== undefined){
                dispatch(fetchAllDataSource({data:newlocation, instance:instance}))
                }
            }

        }
    }


    //on click of the search icon
    const onsearchprojtype = () => {
        
        setSearch("true")
        KEY = document.querySelector('#keyWord').value
        //
        if (value === '') {
            newlocation = ""
        } else {
            setSearch("false")
            newlocation = value;
        }
        setSearch("true")
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchAllDataSource({data:newlocation, instance:instance}))
        }
        setSuggestions([]);
        setSuggestionsActive(false);
    }

    const handleClick = (e) => {
        setSuggestions([]);
        setValue(e.target.innerText);
       // 
        setSuggestionsActive(false);
    };

    const Suggestions = () => {
        return (
            <ul className="suggestions">
                {suggestions?.map((suggestion, index) => {
                    return (
                        <li
                            className={index === suggestionIndex ? "active" : ""}
                            key={index}
                            onClick={handleClick}>
                            {suggestion}
                        </li>
                    );
                })}
            </ul>
        );
    }
    if (ver === 'true' || location.search.includes("&search=") === true) {
        CrossSymbol = <XLg className=' cursor-pointer' size={18} onClick={endsearch} />
        //  
    } else if (location.search.includes("&search=") === false) {
        CrossSymbol = <span></span>
    } else {
        CrossSymbol = <span></span>
    }

    //send put/post attributes to datasourceentryform page
    // function handleControls (data){
    //       
    //        

    //  const res = axios(data.url).then((res) => {
    // 
    // setRes = res.data
    // mainResList = [{setRes}]
    // 

    // for (var key in mainResList[0]?.setRes[0]) {
    //                     if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
    //                         
    //                         dropdownKeys.push(key)
    //                         // setGetApiAttributes([...getApiAttributes,key])
    //                         
    //                     }
    //                 }
    //     }).then((res)=>{
    //         
    //         setGetApiAttributes(dropdownKeys)
    //         

    //         navigate(`/dataSourceEntry`,{state:{dropdownKeys}});

    //     });

    //   }

    useEffect(() => {

        if (openToggle?.openToggle === true) setHideWebStudio(0)
        else setHideWebStudio(1)
        //  document.querySelector("#hideWebstudiodiv").className="Folderstructure_menu flex-fill"
    }, [openToggle?.openToggle])
   // 

  
    const handleSimpleMicroservice = (e) => {
                
        document.querySelector("#simpleMicroserviceRadio").checked = false        
        document.querySelector("#CrudMicroserviceRadio").checked = false 
        
        // 
         if (e.target.id === "simpleMicroserviceRadio") {
            document.querySelector("#simpleMicroserviceRadio").checked = true           
             document.querySelector("#CrudMicroserviceRadio").checked = false            
             document.querySelector("#CrudMicroservice").hidden = true           
              document.querySelector("#simpleMicroservice").hidden = false        
            }
         if (e.target.id === "CrudMicroserviceRadio") {
            document.querySelector("#simpleMicroserviceRadio").checked = false 
            //.checked )           
            document.querySelector("#CrudMicroserviceRadio").checked = true            
            document.querySelector("#CrudMicroservice").hidden = false            
            document.querySelector("#simpleMicroservice").hidden = true        
        }
       
    }

   
   
    function CrudMicroserviceSend(e){
              
        if(datasourceDisableGetFlag === true && datasourceDisablePostFlag===true && datasourceDisablePutFlag===true ){
            setApiButtonDisable(false)
        }
        AddDatasourceFlag = false
        // setApiButtonDisable(false)
       // 
        if(e.currentTarget.name === "Get"){
            setDatasourceDisableGetFlag(true)
            getTarget = e.currentTarget.name
             GetUrlLink = document.querySelector("#Geturl").value
            var GetAuthToken = document.querySelector("#gettoken").value
            if(GetUrlLink !== ""){
                crudList.push({
                    "url": GetUrlLink,
                    "httpverb": getTarget
                })              
            }
            setMainRes("")
            const res = axios(GetUrlLink, { headers: { "Authorization": `Bearer ${GetAuthToken}` } }).then((res) => {
                dropdownKeys = []
                //

                setRes = res.data
                //
                setGetData([...getData, res.data])
                //
                setMainRes(JSON.stringify(setRes, null, 4))
               // 
                mainResList = [{ setRes }]
                // 

                for (var key in mainResList[0]?.setRes[0]) {
                    if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                        dropdownKeys.push(key)
                    }
                }
            });
        }else if(e.currentTarget.name === "Post"){
            
            setDatasourceDisablePostFlag(true)
            setMainRes("")
            dropdownKeys=[]
            setRows([{ id: 1, firstname: "", lastname: "", description: "" }])
            postTarget = e.currentTarget.name
             PostUrlLink = document.querySelector("#Posturl").value
            var PostAuthToken = document.querySelector("#Posttoken").value
            if(PostUrlLink !== ""){  
                crudList.push({
                    "url": PostUrlLink,
                    "httpverb": postTarget
                }) 
            }
            var rowlength = rows.length
            var tempArrLen = TempArr.length
            var tempArrs = TempArr.slice(-[rowlength])
            setparamsList([tempArrs[tempArrs.length - 1]])
            setshowRes(JSON.stringify([tempArrs[tempArrs?.length - 1]], null, 4))
            const res = axios.post(PostUrlLink, {
                headers: { "Authorization": `Bearer ${PostAuthToken}` },
                body: tempArrs[tempArrs.length - 1]
            }).then((res) => {
                //
                setMainRes("Executed with status code " + res.status)

            });
        }else if(e.currentTarget.name === "Put"){
            
            setDatasourceDisablePutFlag(true)
            setMainRes("")
            setRows([{ id: 1, firstname: "", lastname: "", description: "" }])
            dropdownKeys=[]
            putTarget = e.currentTarget.name
             putUrlLink = document.querySelector("#Puturl").value
            var putAuthToken = document.querySelector("#Puttoken").value
            if(putTarget !== ""){  
                crudList.push({
                    "url": putUrlLink,
                    "httpverb": putTarget
                }) 
            }
            var rowlength = rows.length
            var tempArrLen = TempArr.length
            var tempArrs = TempArr.slice(-[rowlength])
            setparamsList([tempArrs[tempArrs.length - 1]])
            setshowRes(JSON.stringify([tempArrs[tempArrs.length - 1]], null, 4))
           // 

            const res = axios.put(putUrlLink, {
                headers: { "Authorization": `Bearer ${putAuthToken}` },
                body: tempArrs[tempArrs.length - 1]
            }).then((res) => {
               // 
                setMainRes("Executed with status code " + res.status)

            });
        }else if(e.currentTarget.name === "Delete"){
            setDatasourceDisableDeleteFlag(true)
            setshowRes([])
            setMainRes("")
            dropdownKeys = []
            deleteTarget = e.currentTarget.name  
         deleteUrlLink = document.querySelector("#Deleteurl").value
         if(deleteUrlLink !== ""){
            crudList.push({
                "url": deleteUrlLink,
                "httpverb": deleteTarget
            })  
        }
            try {
                const res = axios.delete(deleteUrlLink).then((res) => {
                 //   
                    setMainRes("Executed with status code " + res.status)
                //    

                })
            } catch (err) {
               // 
            }
        }
        setMainRes("Not Found : Executed with status code 404")
       
        
    }


    return (
        <>
            <ToastProvider placement="top-center" >
                <div class={hideWebStudio === 1 ? "Folderstructure_menu flex-fill d-none" : "Folderstructure_menu flex-fill"} id="hideWebstudiodiv"   >
                    <div className='sidebar_main d-flex flex-column h-100 ' >
                        <div class="Plusmenu_right d-flex flex-column h-100">
                            {/* <div class="d-flex inner_icons align-items-center flex-row bordercolor">
                                <div class="inner_menus_show active"></div>
                                <div class="inner_menus_show"></div>
                                

                            </div> */}

                            {/* plus menu sidebar */}
                            <div class="sidebar_nav_height" id="one" hidden={openToggle.sidebarhide === "one" ? false : true}>
                                {/* <div class="d-flex insertdiv bordercolor mb-3">
                            <div className='font_13 tabs font_regular active'>Insert</div>
                            <X className='react_icon_small ms-auto' />
                        </div> */}
                                <div class="d-flex">
                                    <div class="mb-0 custom_search_icon">
                                        <Search_icon className='search_icon' />
                                        <input type="email" class="form-control border-0 bg-transparent font_light" id="exampleFormControlInput1" placeholder="Search" />
                                    </div>
                                </div>
                                <div class="d-flex h-100 mt-3 mb-3 plus_menus explorermaindiv ">
                                    <ProSidebarProvider>
                                        <Sidebar>
                                            <Menu>
                                                {controldata?.map((controldata, index) => (
                                                    <SubMenu label={controldata.controltypename}>
                                                        {controldetaildata?.map((controldetaildata, index) => (
                                                            controldetaildata.controltypeid === controldata.id ? (
                                                                <MenuItem>
                                                                    <DraggableControl
                                                                        dragControl={controldetaildata}
                                                                        id={index}
                                                                    >
                                                                    </DraggableControl>
                                                                </MenuItem>) : null
                                                        ))}
                                                        {/* <MenuItem> Line charts </MenuItem> */}

                                                    </SubMenu>
                                                ))}
                                                {/* <SubMenu label="Input">
                            {
                                insertControlsData.map((insertItem, index) => {
                                    return (
                                        <MenuItem >
                                            <DraggableControl
                                                dragControl = {insertItem}
                                                id = {index}
                                            >
                                            </DraggableControl>
                                        </MenuItem>
                                    )
                                })
                            }
                        </SubMenu>
                        <SubMenu label="Layout">
                            <MenuItem> Vertical Gallery </MenuItem>
                            <MenuItem> Horizontal Gallery </MenuItem>
                        </SubMenu>
                        <SubMenu label="Shape">
                            <MenuItem> Back Arrow</MenuItem>
                            <MenuItem> Next Arrow </MenuItem>
                            <MenuItem> Circle</MenuItem>
                        </SubMenu>
                        <SubMenu label="Media">
                            <MenuItem> Pie charts </MenuItem>
                            <MenuItem> Line charts </MenuItem>
                        </SubMenu> */}

                                            </Menu>
                                        </Sidebar>
                                    </ProSidebarProvider>

                                </div>
                                <DropdownButton
                                    variant="outline-secondary"
                                    title={selectedPage === undefined ? "pages" : selectedPage}
                                    id="input-group-dropdown-4"
                                    align="end"
                                    className='fullwidbtn'
                                >
                                    {pages?.map((page) => (

                                        <Dropdown.Item value={page} onClick={(ev) => { setselectedPage(ev.target.text);
                                           //   
                                            }}>{page}</Dropdown.Item>
                                    ))}
                                </DropdownButton>
                            </div>
                            {/* add file side bar */}
                            < div class="sidebar_nav_height" id="two" hidden={openToggle.sidebarhide === "two" ? false : true}>

                                <div class="Filemenu_right w-100 d-flex flex-column h-100 ">
                                    {/* <div class="d-flex filemenusdiv maintabs bordercolor mb-3"> */}
                                    <div class="d-flex bordercolor insertdivss mb-3 " >
                                        <div className='font_13 tabs font_regular active'>Screens</div>
                                        <div className='font_13 tabs font_regular ms-3'>Components</div>
                                        <div class="ms-auto" >
                                            <X onClick={() => {
                                                setHideWebStudio(1);
                                                openToggle.setOpenToggle(false);
                                                let getEl = document.querySelectorAll('.gjs-pn-views, .gjs-pn-views-container, .gjs-cv-canvas, .gjs-pn-commands')
                                                getEl[2].style.display = 'block';
                                                getEl[3].style.display = 'block';
                                                getEl[0].style.width = '85%';
                                                getEl[1].style.width = '85%';
                                                openToggle.setActive("studio")
                                            }}
                                                className='react_icon_small ms-auto' />
                                        </div>

                                        {/* <X className='react_icon_small ms-auto' /> */}
                                    </div>
                                    <div class="d-flex flex-column">
                                        <Button onClick={handleShow} className='d-block primary_button font_regular mb-3 w-100' variant="primary" size="sm">New +</Button>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-3 custom_search_icon">
                                            <Search_icon className='search_icon' />
                                            <input type="email" class="form-control border-0 bg-transparent font_light" id="exampleFormControlInput1" placeholder="Search" />
                                        </div>
                                    </div>
                                    {/* <div class="d-flex pages"> */}
                                    <div class="d-flex h-100 mt-3 plus_menus explorermaindiv ">

                                        {/* <ul className='file_pages w-100'>
                                        <li className='d-flex active align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page1</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page2</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page3</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page4</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page5</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                    </ul> */}


                                        <ProSidebarProvider>
                                            <Sidebar>
                                                <Menu>
                                                    {content?.map((file) => (
                                                        file.type === "tree" ?
                                                            <SubMenu label={file.path} id="foldername" value={file.path} onClick={getUrl(file)}>
                                                                {/* {} */}
                                                                {file?.subpath?.map((file1) => (
                                                                    <MenuItem id="folderfilename" onClick={showFolderFileContent(file1)}>{file1.path}</MenuItem>
                                                                ))}
                                                            </SubMenu> :
                                                            file.path.includes(".js") && !file.path.includes(".json") ?
                                                                <MenuItem onClick={showFileContent(file)} id="filename"> {file.path}</MenuItem> : <></>
                                                    ))}

                                                </Menu>
                                            </Sidebar>
                                        </ProSidebarProvider>

                                    </div>

                                    <Modal className="custom_popupp" show={show} onHide={handleClose}>
                                        <Modal.Header closeButton>
                                            {/* <Modal.Title><p class="font_20 font_regular mb-0">Why do you think it is not useful ?</p></Modal.Title> */}
                                        </Modal.Header>
                                        <br></br>
                                        <Modal.Body className="py-0">
                                            <div class="row">
                                                <div class="col-12">
                                                    <div class="mb-0 required">
                                                        {/* <label for="exampleFormControlInput1" class="form-label"></label> */}
                                                        <label id="filename" class="">File name</label>
                                                        <input type="text" class="form-control" id="fileName" placeholder="Enter file name" onChange={handleFileName} />
                                                        <label id="fileNameErr" class="error-label"></label>

                                                        <br></br>

                                                        <label id="nameErr" >Folder name </label>

                                                        <select id="folderName" onClick={handleFolderName}>
                                                            <option>Select any option</option>

                                                            {content?.map((file) => (
                                                                file.type === "tree" ?
                                                                    <option >{file.path}</option> : ""
                                                            ))}
                                                        </select>
                                                        <br></br>
                                                        <label id="folderNameErr" class="error-label"></label>


                                                    </div>
                                                </div>
                                            </div>
                                        </Modal.Body>
                                        <br></br>
                                        <Modal.Footer>
                                            <Button className="btn btn-custom-primary-small " onClick={addFile} disabled={buttonDisable} >
                                                Submit
                                            </Button>
                                            <Button className="btn btn-custom-secondary-small" onClick={handleClose} >
                                                Cancel
                                            </Button>
                                        </Modal.Footer>
                                    </Modal>
                                </div>

                            </div>
                            {/* api file */}
                            <div class="sidebar_nav_height" id="three" hidden={openToggle.sidebarhide === "three" ? false : true}>

                                <div class="Explorermenu_right d-flex flex-column h-100 " >
                                    <div class="d-flex bordercolor insertdivss mb-3 " >
                                        <div className='font_14 font_regular '>Data</div>
                                        <div class="ms-auto" >
                                            <X onClick={() => {
                                                setHideWebStudio(1);
                                                openToggle.setOpenToggle(false);
                                                let getEl = document.querySelectorAll('.gjs-pn-views, .gjs-pn-views-container, .gjs-cv-canvas, .gjs-pn-commands')
                                                getEl[2].style.display = 'block';
                                                getEl[3].style.display = 'block';
                                                getEl[0].style.width = '85%';
                                                getEl[1].style.width = '85%';
                                                openToggle.setActive("studio")
                                            }}
                                                className='react_icon_small ms-auto' />
                                        </div>

                                        {/* <X onClick={()=>{document.querySelector("#hidediv").className="d-none";document.querySelector("#hamburger").hidden=false}} className='react_icon_small ms-auto'  /> */}
                                    </div>
                                    <div class="d-flex flex-column">
                                        <Button onClick={handleShowApi} className='d-block primary_button font_regular mb-3 w-100' variant="primary" size="sm">+ Add Data</Button>
                                    </div>
                                    <div class="d-flex">


                                        <div class="ms-auto d-flex align-items-center">
                                            {/* <div class="custom_inputbtn w-100">
                                                <InputGroup >

                                                    
                                                </InputGroup>
                                            </div> */}
                                            <div class="mb-0 d-flex  custom_search_icon apitabs position-relative">

                                                <Search_icon className='search_icon' onClick={() => { onsearchprojtype() }} />
                                                <input type="text" class="form-control flex-1 border-0 bg-transparent font_light" value={value} onChange={handleChangeprojtype}
                                                    onKeyDown={handleKeyDownprojtype}
                                                    id="keyWord" placeholder="Search" />
                                                {CrossSymbol}
                                                {suggestionsActive && <Suggestions />}
                                            </div>
                                        </div>
                                    </div>

                                    <div class="d-flex h-100 mt-3 plus_menus explorermaindiv ">
                                        <ProSidebarProvider>
                                            <Sidebar>
                                                <Menu>
                                                    {datasource?.map((data) => (
                                                   <MenuItem id="filename"  >{data.datasourcename} -   {data?.httpobject?.length > 1 ? "CRUD" : data?.httpobject[0]?.httpverb}</MenuItem>
                                                    ))}
                                                </Menu>
                                            </Sidebar>
                                        </ProSidebarProvider>
                                    </div>
                                </div>


                                <Modal
                                    show={showApi}
                                    onHide={handleCloseApi}
                                    backdrop="static"
                                    keyboard={false}
                                    aria-labelledby="contained-modal-title-vcenter"
                                    centered
                                    scrollable
                                    dialogClassName="modal-75w"
                                >
                                    <Modal.Header closeButton>
                                        <Modal.Title className='font_18 font_regular' id="contained-modal-title-vcenter">
                                            Add Data Source
                                        </Modal.Title>
                                    </Modal.Header>
                                    <Modal.Body>
                                        <div class="row mb-4">
                                            <div class="col-lg-6">
                                                <div class="d-flex flex-wrap">
                                                    <div class="form-check modalradio col form-check-inline">
                                                        {/* <input class="form-check-input" type="radio" name="inlineRadioOptions" id="simpleMicroserviceRadio" value="option1" onChange={()=>{handleSimpleMicroservice()}}  /> */}
                                                        <input  class="form-check-input" type="radio" id="simpleMicroserviceRadio" defaultChecked  onChange={(e) => { handleSimpleMicroservice(e) }} />
                                                        <label class="form-check-label" for="inlineRadio1">
                                                            <h2 class="mb-0 font_14">Simple Microservice</h2>
                                                            <p class="font_10 mb-0">Single operation of microservice that can be used for HTTP Get, Put, Post and Delete</p>
                                                        </label>
                                                    </div>
                                                    <div class="form-check col modalradio form-check-inline">
                                                        {/* <input class="form-check-input" type="radio" name="inlineRadioOptions" id="CrudMicroserviceRadio" value="option2" /> */}
                                                        <input  class="form-check-input" type="radio" id="CrudMicroserviceRadio" onChange={(e)=>{handleSimpleMicroservice(e)}} />
                                                        <label class="form-check-label" for="inlineRadio2">
                                                            <h2 class="mb-0 font_14">CRUD Microservice</h2>
                                                            <p class="mb-0 font_10">Add an one entity all CRUD operation and screen will be created out of box</p>
                                                        </label>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div class="row" id="simpleMicroservice" >
                                            <div class="col-lg-9">
                                                <div class="mb-3">
                                                    <label for="exampleFormControlInput1" class="form-label">Enter Entity Name</label>
                                                    <input type="text" class="form-control" placeholder='Enter name' id='name' ></input>

                                                </div>
                                            </div>

                                            {/* <div class="col-lg-8 pe-lg-0">
                                                <div class="mb-3">
                                                    <label for="exampleFormControlInput1" class="form-label">isMap</label>
                                                    <input type="checkbox" class="form-check-input" placeholder='Enter name' id='isMap' ></input>
                                                </div>
                                            </div> */}

                                            <div class="col-lg-9 mb-3">
                                                <div class="d-flex flex-row ">
                                                    <div class="flex-fill">
                                                        <InputGroup>
                                                            <select id="verb">
                                                                <option href="#">Get</option>
                                                                <option href="#">Post</option>
                                                                <option href="#">Delete</option>
                                                                <option href="#">Put</option>
                                                            </select>
                                                            <input type="text" class="form-control" id="url"></input>
                                                        </InputGroup>
                                                    </div>
                                                    {showGetDropdown}
                                                    <Button className='d-inline primary_button font_regular px-4 ms-3' variant="primary" size="sm" onClick={handleSend}>Send</Button>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                {/* {} */}
                                                <Tabs
                                                    defaultActiveKey="params"
                                                    id="uncontrolled-tab-example"
                                                    className="mb-3"
                                                >
                                                    <Tab eventKey="params" title="PARAMS">
                                                        <div class="d-flex">
                                                            <Table className="modal_tablee" >
                                                                <thead>
                                                                    <tr>
                                                                        <th>KEY</th>
                                                                        <th>Value</th>
                                                                        <th>Description</th>
                                                                        <th>Actions</th>
                                                                    </tr>
                                                                </thead>
                                                                <tbody>
                                                                    {rows?.map((row, i) => {
                                                                        return (
                                                                            <>
                                                                                <tr>
                                                                    
                                                                                    <td> <input type="text" class="form-control" value={row.firstname} name="firstname" id="KeyName" onChange={(e) => handleInputChange(e, i)} placeholder="Key" /></td>
                                                                                    <td> <input type="text" class="form-control" value={row.lastname} name="lastname" id="ValueName" onChange={(e) => handleInputChange(e, i)} placeholder="Value" /></td>
                                                                                    <td> <input type="text" class="form-control" value={row.description} name="description" id="description" onChange={(e) => handleInputChange(e, i)} placeholder="Description" /></td>
                                                                                    <td class="text-center">
                                                                                        {/* <Edit_icon_table className='me-3 cursor-pointer' onClick={handleEdit}/> */}
                                                                                        <Delete_icon_table className='cursor-pointer' onClick={handleRemoveClick}/></td>
                                                                                    
                                                                                </tr>
                                                                            </>
                                                                        )
                                                                    })}
                                                                </tbody>
                                                            </Table>
                                                            <Plus className="plus_Click" onClick={handleAdd}></Plus>
                                                        </div>

                                                    </Tab>
                                                    <Tab eventKey="authentication" title="AUTHENTICATION">
                                                        <label>Token</label>
                                                        <input type="text" class="form-control" placeholder='Enter token' id='token'></input>
                                                    </Tab>
                                                    <Tab eventKey="body" title="BODY">
                                                        {/* {} */}

                                                        <pre>{showRes}</pre>

                                                    </Tab>
                                                    {/* {} */}
                                                    <Tab eventKey="result" title="RESULT">
                                                        {/* {mainRes} */}
                                                        <pre id="resultID" >{mainRes}</pre>
                                                    </Tab>

                                                </Tabs>
                                            </div>
                                        </div>


                                        <div class="row" id="CrudMicroservice" hidden={true}>
                                            <div class="col-lg-9">
                                                <div class="mb-3">
                                                    <label for="exampleFormControlInput1" class="form-label">Enter Entity Name</label>
                                                    <input type="text" class="form-control" placeholder='Enter name' id='CrudMicroserviceEntityName' ></input>

                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <Accordion  >
                                                    <Accordion.Item eventKey="0">
                                                        <Accordion.Header >Get</Accordion.Header>
                                                        <Accordion.Body>
                                                            <div class="col-lg-9 mb-3">
                                                                <div class="d-flex flex-row">
                                                                    <div class="flex-fill">
                                                                    <input type="text" class="form-control" placeholder='Enter URL' id='Geturl' ></input>
                                                                    </div>
                                                                    {showGetDropdown}
                                                                    <Button className='d-inline primary_button font_regular px-4 ms-3' variant="primary" size="sm" name="Get" onClick={(e)=>{CrudMicroserviceSend(e)}}>Send</Button>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                {/* {} */}
                                                                <Tabs
                                                                    defaultActiveKey="result"
                                                                    id="uncontrolled-tab-example"
                                                                    className="mb-3"
                                                                >
                                                                    {/* <Tab eventKey="params" title="PARAMS">
                                                                        <div class="d-flex">
                                                                            <Table className="modal_tablee" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>KEY</th>
                                                                                        <th>Value</th>
                                                                                        <th>Description</th>
                                                                                        <th>Actions</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {rows?.map((row, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td> <input type="text" class="form-control" value={row.firstname} name="firstname" id="KeyName" onChange={(e) => handleInputChange(e, i)} placeholder="Key" /></td>
                                                                                                    <td> <input type="text" class="form-control" value={row.lastname} name="lastname" id="ValueName" onChange={(e) => handleInputChange(e, i)} placeholder="Value" /></td>
                                                                                                    <td> <input type="text" class="form-control" value={row.description} name="description" id="description" onChange={(e) => handleInputChange(e, i)} placeholder="Description" /></td>
                                                                                                    <td class="text-center"><Edit_icon_table className='me-3 cursor-pointer' /><Delete_icon_table className='cursor-pointer' /></td>
                                                                                                </tr>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                            <Plus className="plus_Click" onClick={handleAdd}></Plus>
                                                                        </div>

                                                                    </Tab> */}
                                                                    <Tab eventKey="authentication" title="AUTHENTICATION">
                                                                        <label>Token</label>
                                                                        <input type="text" class="form-control" placeholder='Enter token' id='gettoken'></input>
                                                                    </Tab>
                                                                    <Tab eventKey="body" title="BODY">
                                                                        {/* {} */}

                                                                        <pre>{showRes}</pre>

                                                                    </Tab>
                                                                    {/* {} */}
                                                                    <Tab eventKey="result" title="RESULT">
                                                                        {/* {mainRes} */}
                                                                        <pre id="resultID" >{mainRes}</pre>
                                                                    </Tab>

                                                                </Tabs>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="1">
                                                        <Accordion.Header>Post</Accordion.Header>
                                                        <Accordion.Body>
                                                        <div class="col-lg-9 mb-3">
                                                                <div class="d-flex flex-row">
                                                                    <div class="flex-fill">
                                                                    <input type="text" class="form-control" placeholder='Enter URL' id='Posturl' ></input>
                                                                    </div>
                                                                    {/* {showGetDropdown} */}
                                                                    <Button className='d-inline primary_button font_regular px-4 ms-3' variant="primary" size="sm" name="Post" onClick={CrudMicroserviceSend}>Send</Button>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                {/* {} */}
                                                                <Tabs
                                                                    defaultActiveKey="params"
                                                                    id="uncontrolled-tab-example"
                                                                    className="mb-3"
                                                                >
                                                                    <Tab eventKey="params" title="PARAMS">
                                                                        <div class="d-flex">
                                                                            <Table className="modal_tablee" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>KEY</th>
                                                                                        <th>Value</th>
                                                                                        <th>Description</th>
                                                                                        <th>Actions</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {rows?.map((row, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    
                                                                                                    <td> <input type="text" class="form-control" value={row.firstname} name="firstname" id="KeyName" onChange={(e) => handleInputChange(e, i)} placeholder="Key" /></td>
                                                                                                    <td> <input type="text" class="form-control" value={row.lastname} name="lastname" id="ValueName" onChange={(e) => handleInputChange(e, i)} placeholder="Value" /></td>
                                                                                                    <td> <input type="text" class="form-control" value={row.description} name="description" id="description" onChange={(e) => handleInputChange(e, i)} placeholder="Description" /></td>
                                                                                                    <td class="text-center">
                                                                                                    <Delete_icon_table className='cursor-pointer' onClick={handleRemoveClick}/></td>
                                                                                                  </tr>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                            <Plus className="plus_Click" onClick={handleAdd}></Plus>
                                                                        </div>

                                                                    </Tab>
                                                                    <Tab eventKey="authentication" title="AUTHENTICATION">
                                                                        <label>Token</label>
                                                                        <input type="text" class="form-control" placeholder='Enter token' id='Posttoken'></input>
                                                                    </Tab>
                                                                    <Tab eventKey="body" title="BODY">
                                                                        {/* {} */}

                                                                        <pre>{showRes}</pre>

                                                                    </Tab>
                                                                    {/* {} */}
                                                                    <Tab eventKey="result" title="RESULT">
                                                                        {/* {mainRes} */}
                                                                        {/* <pre id="resultID" >{mainRes}</pre> */}
                                                                    </Tab>

                                                                </Tabs>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="2">
                                                        <Accordion.Header>Put</Accordion.Header>
                                                        <Accordion.Body>
                                                        <div class="col-lg-9 mb-3">
                                                                <div class="d-flex flex-row">
                                                                    <div class="flex-fill">
                                                                    <input type="text" class="form-control" placeholder='Enter URL' id='Puturl' ></input>
                                                                    </div>
                                                                    {/* {showGetDropdown} */}
                                                                    <Button className='d-inline primary_button font_regular px-4 ms-3' variant="primary" size="sm" name="Put" onClick={CrudMicroserviceSend}>Send</Button>
                                                                </div>
                                                            </div>
                                                            <div class="col-12">
                                                                {/* {} */}
                                                                <Tabs
                                                                    defaultActiveKey="params"
                                                                    id="uncontrolled-tab-example"
                                                                    className="mb-3"
                                                                >
                                                                    <Tab eventKey="params" title="PARAMS">
                                                                        <div class="d-flex">
                                                                            <Table className="modal_tablee" >
                                                                                <thead>
                                                                                    <tr>
                                                                                        <th>KEY</th>
                                                                                        <th>Value</th>
                                                                                        <th>Description</th>
                                                                                        <th>Actions</th>
                                                                                    </tr>
                                                                                </thead>
                                                                                <tbody>
                                                                                    {rows?.map((row, i) => {
                                                                                        return (
                                                                                            <>
                                                                                                <tr>
                                                                                                    <td> <input type="text" class="form-control" value={row.firstname} name="firstname" id="KeyName" onChange={(e) => handleInputChange(e, i)} placeholder="Key" /></td>
                                                                                                    <td> <input type="text" class="form-control" value={row.lastname} name="lastname" id="ValueName" onChange={(e) => handleInputChange(e, i)} placeholder="Value" /></td>
                                                                                                    <td> <input type="text" class="form-control" value={row.description} name="description" id="description" onChange={(e) => handleInputChange(e, i)} placeholder="Description" /></td>
                                                                                                    <td class="text-center"><Edit_icon_table className='me-3 cursor-pointer' /><Delete_icon_table className='cursor-pointer' onClick={handleRemoveClick}/></td>
                                                                                                </tr>
                                                                                            </>
                                                                                        )
                                                                                    })}
                                                                                </tbody>
                                                                            </Table>
                                                                            <Plus className="plus_Click" onClick={handleAdd}></Plus>
                                                                        </div>

                                                                    </Tab>
                                                                    <Tab eventKey="authentication" title="AUTHENTICATION">
                                                                        <label>Token</label>
                                                                        <input type="text" class="form-control" placeholder='Enter token' id='Puttoken'></input>
                                                                    </Tab>
                                                                    <Tab eventKey="body" title="BODY">
                                                                        {/* {} */}

                                                                        <pre>{showRes}</pre>

                                                                    </Tab>
                                                                    {/* {} */}
                                                                    <Tab eventKey="result" title="RESULT">
                                                                        {/* {mainRes} */}
                                                                        {/* <pre id="resultID" >{mainRes}</pre> */}
                                                                    </Tab>

                                                                </Tabs>
                                                            </div>
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                    <Accordion.Item eventKey="3">
                                                        <Accordion.Header>Delete</Accordion.Header>
                                                        <Accordion.Body>
                                                        <div class="col-lg-9 mb-3">
                                                                <div class="d-flex flex-row">
                                                                    <div class="flex-fill">
                                                                    <input type="text" class="form-control" placeholder='Enter URL' id='Deleteurl' ></input>
                                                                    </div>
                                                                   
                                                                    <Button className='d-inline primary_button font_regular px-4 ms-3' variant="primary" size="sm" name="Delete" onClick={CrudMicroserviceSend}>Send</Button>
                                                                </div>
                                                            </div>
                                                           {/* {mainRes} */}
                                                        </Accordion.Body>
                                                    </Accordion.Item>
                                                </Accordion>
                                            </div>
                                        </div>
                                    </Modal.Body>
                                    <Modal.Footer>
                                        <Button onClick={addDataSource} disabled={ApiButtonDisable}>Add Data Source</Button>
                                    </Modal.Footer>
                                </Modal>

                            </div>
                        </div>
                    </div>
                </div>
            </ToastProvider>

        </>

    )
}

export default PlusMenuRight;
import React, { memo, useEffect, useState } from 'react';
import AllAppsGrid from '../../AllAppsGrid';
import { Search, Plus } from 'react-bootstrap-icons';
import { DataGrid } from '@mui/x-data-grid';
import {getAllProjectsGridData1} from '../store/userProject.actions';
import { useDispatch, useSelector } from "react-redux";

import { FaCode, FaRegCalendarAlt, FaRocketchat, FaFile } from 'react-icons/fa';
import { X } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import ModalDialog from 'react-bootstrap/ModalDialog'
import { ReactComponent as Cloud_icon } from '../../images/cloud_upload_icon.svg';
import { ReactComponent as File_menu } from '../../images/file_menu.svg';
import { ReactComponent as Plus_menu } from '../../images/plus_menu.svg';
import { ReactComponent as Api_menu } from '../../images/api_menu.svg';
import { ReactComponent as Sourcecode_menu } from '../../images/sourcedode_menu.svg';
import { ReactComponent as Message_menu } from '../../images/message_menu.svg';
import { ReactComponent as Search_icon } from '../../images/search_icon.svg';
import { ReactComponent as File_small_icon } from '../../images/file_small_icon.svg';
import { ReactComponent as Three_dots } from '../../images/three_dots.svg';
import { ReactComponent as Edit_icon } from '../../images/edit_icon.svg';
import { ReactComponent as Home_menu } from '../../images/home_icon.svg';
import { ReactComponent as Project_menu } from '../../images/projects_icon.svg';
import { ReactComponent as GreenPlus_icon } from '../../images/greenplus_icon.svg';
import { ReactComponent as Play_icon } from '../../images/play_icon.svg';
import Dotnet_icon from '../../images/dotnet_icon.png';
import React_icon from '../../images/react_icon.png';
import React_android from '../../images/react_andriod.png';
import React_ios from '../../images/react_ios.png';
import Ai_bot from '../../images/Ai_bot.png';
import Ai_botdotnet from '../../images/Ai_botdotnet.png';
import Left_mask from '../../images/left_mask.png';
import Pagination from 'react-bootstrap/Pagination';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { fetchProductTypeData, fetchProjectRepoData, fetchProjectVersionData, fetchAllVersions } from "../store/productType.actions";
import { fetchNfrTypeData } from "../store/nfr.actions";
import { fetchThemeData } from "../store/theme.actions";
import { fetchLayoutData } from "../store/layout.actions"
import PackageGrid from '../../PackageGrid';
import RecentappsGrid from '../../RecentappsGrid';
import { postUserProjectData, putUserProjectData, postUserProjectidData, fetchGitURL, getAllProjectsNames,fetchProjects5 } from "../store/userProject.actions"
import { Link, useNavigate } from 'react-router-dom';
import { CircularProgressbar } from 'react-circular-progressbar';
import 'react-circular-progressbar/dist/styles.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import axios from 'axios';
import Spinner from 'react-bootstrap/Spinner';
import { getAllProjectsGridData } from '../store/userProject.actions';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import {loginRequest} from "../../../authConfig";
import UserProjectEntryPopup from "../../UserProjectEntryPopup"
import io from 'socket.io-client'; 
import {fetchWizardData} from '../store/wizard.actions'
import { fetchDeploymentData } from '../store/deployment.actions';

const { REACT_APP_MARKETPLACE_URL } = process.env;
let status=[]
let checkparam
let count = 0;
var setTitle = []
var url = process.env.REACT_APP_BASE_URL;
// var socket = io.connect(url);
var socket = undefined;
var setTitle = []
var projidsock;

let methodcalled = false

function AllApps() {
    const {instance, accounts, inProgress} = useMsal();
    const name = accounts[0] && accounts[0].username;

    const packagetypecolumns = [
        { field: "projectname", flex: 1, headerName: "Projects",flex:1, minWidth: 100 },
        { field: "useremail", headerName: "Modified By",flex:1, width: 200 },
        { field: "updatedon", headerName: "Modified On",flex:1, width: 200 },

    ];
    useEffect(() => {
        //

        if(socket !== undefined){
            socket.on('receive_message', (data) => {
    
              
        
                     // 
        
                        if(data.projid !== undefined && data.status ==="End" && data.message === "Project End"){
                            socket.disconnect(data.projid);
                           // 
                            methodcalled = false;
                            // 
                            // 
                            setrepolink(data?.repourl + '/zipball/master')
                            setrepotreeLink(data?.repotreeurl)
                            dispatch(fetchGitURL({gitUrl:data.repotreeurl,downloadLink:data.repourl}))
                            status=[];
                            setprogressbar(0)
                            localStorage.setItem("userprojectid",data.projid)
                           // 
                            navigate('/explorer?_id='+data.projid)
                           
                        }             
        
                   
             
          
                
              });
        }

        
    })
    // useEffect(()=>{
    //     if(socket === undefined)
    //     socket = io.connect(url);
    // })
    useEffect(()=>{
        if(methodcalled)
        socket = io.connect(url);
        //

    },[methodcalled])
    useEffect(() => {
        //

        if(socket !== undefined){

         
          //  

            socket.on('receive_message', (data) => {      

              //

                if(data.projid !== undefined && data.status ==="End" && data.message === "Project End"){
                    socket.disconnect(data.projid);
                  //  
                    methodcalled = false;
                    // 
                    // 
                    setrepolink(data?.repourl + '/zipball/master')
                    setrepotreeLink(data?.repotreeurl)
                    dispatch(fetchGitURL({gitUrl:data.repotreeurl,downloadLink:data.repourl}))
                    status=[];
                    setprogressbar(0)
                    localStorage.setItem("userprojectid",data.projid)
                    //
                    navigate('/explorer?_id='+data.projid)
                   
                }             

            });
            //return () => socket.off('receive_message');

        }

     



    },[socket])
    //
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [selected, setSelected] = useState([])
    const [selectedid, setSelectedid] = useState([])
    const[progressbar,setprogressbar]=useState(0)
    const [paramsArr,setparamsArr]=useState([])
    const [paramsArr1,setparamsArr1]=useState([])
    const [paramid,setParamid] = useState("")
    const[targetid,setTargetid]=useState();
    const [getid, setgetid] = useState();
    const [projectnameval,setprojectnameval]=useState("");
    const [deploymentid,setDeploymentid]=useState();
    // 
    // 

    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchProductTypeData({data: "", instance: instance}))
        dispatch(fetchProjectRepoData(instance))
        //dispatch(fetchWizardData(projectType))
        // dispatch(fetchProjectVersionData())
        dispatch(getAllProjectsNames(instance))
        dispatch(getAllProjectsGridData1({name:name,instance:instance,"dispatch":dispatch}))
        dispatch(getAllProjectsGridData({name:name,instance:instance,"dispatch":dispatch}))
        dispatch(fetchProjects5({name:name,"dispatch":dispatch}))
        // dispatch(fetchNfrTypeData())
       // dispatch(fetchThemeData(""))
        dispatch(fetchLayoutData({data:getid, instance:instance}))
        dispatch(fetchAllVersions(instance))
        }
    }, [dispatch,getid, instance.getAllAccounts()[0] !== undefined])

    //for all projects 

    const allProjectsNames = useSelector((state) => state.commonReducer.allProjectsNames.AllProjectsNames.allProjectsNames);
    const [allProjectsNames1, setallProjectsNames1] = useState(allProjectsNames);
    useEffect(() => { setallProjectsNames1(allProjectsNames) }, [dispatch, allProjectsNames])
   

   // all versions

    const allVersionsContent = useSelector((state) => state.commonReducer.allversion.AllVersionData.allversion );
    const [allversion, setallversion] = useState(allVersionsContent);
    useEffect(() => { setallversion(allVersionsContent) }, [dispatch, allVersionsContent])
   
    //for top 5 projects by email id

    // const maincontent1 = useSelector((state) => state.commonReducer.allProjects.AllProjects.allProjects);
    // const [packagegrid1, setpackagegrid1] = useState(maincontent1);
    // useEffect(() => { setpackagegrid1(maincontent1) }, [dispatch, maincontent1])
    // 

    //all apps 
    const maincontent11 = useSelector((state) => state.commonReducer.allProjects1.AllProjects1.allProjects1);
    const [packagegrid11, setpackagegrid11] = useState(maincontent11);
    useEffect(() => { setpackagegrid11(maincontent11) }, [dispatch, maincontent11])
   
    

    // //top fetch 5 records 
    // const dataprojects = useSelector((state) => state.commonReducer?.projects5?.Projects5?.projects5);
    // const [Appgrid11, setAppgrid11] = useState(dataprojects);
    // useEffect(() => { setAppgrid11(dataprojects) }, [dispatch, dataprojects])
    // 

//columns for recent 5
    // const maincontent10 = useSelector((state)=>state.commonReducer.allProjects.AllProjects.allappscol);
    // const [allappscol, setallappscol] = useState(maincontent10);
    // useEffect(()=>{setallappscol(maincontent10)},[dispatch,maincontent10])
    // 

    const maincontent9 = useSelector((state)=>state.commonReducer?.allProjects1?.AllProjects1?.allProjects1);
    const [packagegrid2, setpackagegrid2] = useState(maincontent9);
    useEffect(()=>{setpackagegrid2(maincontent9)},[dispatch,maincontent9])

    const maincontent10 = useSelector((state)=>state.commonReducer?.allProjects1?.AllProjects1?.recentappscol);
    const [recentappscol, setrecentappscol] = useState(maincontent10);
    useEffect(()=>{setrecentappscol(maincontent10)},[dispatch,maincontent10])


    const percentage = 66;
    const completed = 100;
    //UseSelectors 

    //wizard steps
    const wizard = useSelector(
        (state) => state.commonReducer.wizard.wizardData.wizard);
    const [wizardSteps, setwizardSteps] = useState(wizard);
    useEffect(() => { setwizardSteps(wizard); }, [dispatch, wizard]);
   
    var wizardfinal = wizardSteps?.map(x=>x.steps?.map(t=>t.stepname));
   
    //product type
    const maincontent = useSelector(
        (state) => state.commonReducer?.prodtypedata?.prodTypeData?.prodtypedata);
    const [prodtypedata, setContentData] = useState(maincontent);
    useEffect(() => { setContentData(maincontent); }, [dispatch, maincontent]);
    

    const repo = useSelector(
        (state) => state.commonReducer?.prodtypedata?.projRepoData?.projrepodata);
    const [projrepodata, setprojrepodata] = useState(repo);
    useEffect(() => { setprojrepodata(repo); }, [dispatch, repo]);
   
    let projcount = prodtypedata?.length;

    const version = useSelector(
        (state) => state.commonReducer?.prodtypedata?.projVersionData?.projversiondata);
    const [projversiondata, setprojversiondata] = useState(version);
    useEffect(() => { setprojversiondata(version); }, [dispatch, version]);
    

    //NFR
    const nfrcontent = useSelector(
        (state) => state.commonReducer?.nfr?.nfrTypeData?.nfrdata);
    const [nfrdata, setnfrData] = useState(nfrcontent);
    useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);
    
    let nfrcount = nfrdata?.length;


    //Theme
    const themecontent = useSelector(
        (state) => state.commonReducer?.theme?.themeTypeData?.themedata);
    const [themedata, setthemeData] = useState(themecontent);
    useEffect(() => { setthemeData(themecontent); }, [dispatch, themecontent]);
    // 
    let themecount = themedata?.length;

    //Layout
    const layoutcontent = useSelector(
        (state) => state.commonReducer?.layout?.layoutTypeData?.layoutdata);
    const [layoutdata, setlayoutData] = useState(layoutcontent);
    useEffect(() => { setlayoutData(layoutcontent); }, [dispatch, layoutcontent]);
    // 
    let layoutcount = layoutdata?.length;

    //UseState Variable declarations
    const [show, setShow] = useState(false);  // modal popup but not being used
    const [show1, setShow1] = useState(false); // moadal popup
    const [targetrepoid, setTargetrepoid] = useState();
    const handleClose = () => {setShow(false); dispatch(fetchProductTypeData({data: "", instance: instance}))}
    const handleClose1 = () => { setShow1(false);if(userprojid !== undefined){methodcalled=false;status=[];setprogressbar(0);socket.disconnect(userprojid);}}
    const handleShow = () => setShow(true);
    const handleShow1 = () => setShow1(true);
    const closeModal = () => setShow(false);
    // offcanvas sidebar
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const handleShow3 = () => setShow3(true);
  const [pageSize, setPageSize] = useState(10);

//   var newVal = 0;
        //const [newVal,setNewval]=useState(0)
    const [show4, setShow4] = useState(false);
    const handleClose4 = () => setShow4(false);
    const handleShow4 = () => setShow4(true);


    const[arr1,setarr1] =useState([])
    const [current, setCurrent] = useState(0);   //Counter for div visibility
    const[reset,setReset]=useState(0);
    const [productid, setproductid] = useState();
    const [modalsize, setmodalsize] = useState();
    const [projectType, setprojectType] = useState();
    const [userprojid, setuserprojid] = useState();
    //
    
    const [projversion, setprojversion] = useState();
    const [val, setVal] = useState();
    const [menuvisible, setMenuVisibile] = useState(0);
    const [hiddenval, setHiddenval] = useState(0)
    const [repoid, setrepoid] = useState()
    const [themeid, setthemeid] = useState()
    const [layoutid, setlayoutid] = useState();
    const [activetheme, setactivetheme] = useState();
    const [activedeployment, setactivedeployment] = useState();
    const [activelayout, setactivelayout] = useState();
    const [repolink, setrepolink] = useState();
    const [treelink, setrepotreeLink] = useState();
    const[service,setService]=useState(false);
  
    const[figmatoken,setfigmatoken]=useState()
    const[figmafile,setfigmafile]=useState()
    const [menudisplay, setMenuDisplay] = useState();
    const [errorbox, seterrorbox] = useState();
    //const [wizarddiv,setwizarddiv] = useState();
    const [aiparam, setaiparam]=useState([]);
    const [depparam, setdepparam]=useState([]);
    const [themeselectedlist, setthemeselectedlist]=useState([]);
    const [layoutselectedlist, setlayoutselectedlist]=useState([]);

    let wizarddiv=0
    
    var newVal = 0;
    var newVal1= 0;
    var x=0;
    const [checkid,setcheckid]= useState()

   
    var new1=0;
    var set=0
    //

    const { addToast } = useToasts();

    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchWizardData({data:projectType, instance:instance}))
        dispatch(fetchProjectVersionData({data:productid,instance:instance}))
    }}, [productid,productid,instance.getAllAccounts()[0] !== undefined])


    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchNfrTypeData({data:productid,instance:instance}))
        dispatch(fetchThemeData({data:productid, instance:instance}))
        dispatch(fetchDeploymentData(getid))
        }
        }, [ productid, getid, instance.getAllAccounts()[0] !== undefined ])


    //Deployement Data
     //Layout
     const deploycontent = useSelector(
        (state) => state.commonReducer?.deploymentdata?.deploymentTypeData?.deploymentdata);
    const [deploymentdata, setDeploymentData] = useState(deploycontent);
    useEffect(() => { setDeploymentData(deploycontent); }, [dispatch, deploycontent]);

    //
    // Set Current and toggle div visiblility
    //
    //handle current data 
    const handleSlide = () => {
        //
        
        // if(current===3)
        // {
        //     .value)
        // }
        if (current === 0) {
            setmodalsize(0)
            count = 0
            //.selectedIndex)
        
        }
        var projectN=document.querySelector("#ProjectName").value;
       
        if (document.querySelector("#ProjectName").value === "" ) {
            //setwizarddiv(1)
            wizarddiv=1
            //alert("All fields are mandatory")
            //addToast("All fields are mandatory", { appearance: 'error', autoDismiss: 'true' })
            newVal=0
            document.querySelector('#modal1').hidden = false
            document.querySelector('#modal2').hidden = true
            document.querySelector('#modal3').hidden = true
            newVal=0
        }
         if(document.querySelector("#ProjectDescription").value === "") 
        {
           // setwizarddiv(1)
            wizarddiv=1
            newVal=0
            document.querySelector('#modal1').hidden = false
            document.querySelector('#modal2').hidden = true
            document.querySelector('#modal3').hidden = true
            newVal=0
        }

        if(document.querySelector('#selectversion').selectedIndex===0)
        {   
             wizarddiv=1
            newVal=0
           // addToast("Select any version", { appearance: 'error', autoDismiss: 'true' })
            newVal=0 
        }
    
        
        if(current===1)
        {newVal=1
            if(document.querySelector('#selectrepoid').selectedIndex=="")
            {
                
             setcheckid(1)
             addToast("Select any option", { appearance: 'error', autoDismiss: 'true' })
             newVal=0
             if(checkid===0)
                {
                    newVal=1
                }
            }
            else
            {
                handlePutUserProjectData()
                if(checkid===0)
                {
                    newVal=1
                }
            }
    }
    else if(current === 2 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false) {
        newVal=1
        
        // 
        if ( themeid === undefined ) {
            newVal=0
            addToast("Select a theme", { appearance: 'error', autoDismiss: 'true' })
            // newVal=0
            // 
        }
        else {
            handlePutThemeData()
            newVal=1
        }
    }
    else if(current === 3 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false) {
        newVal=1
        // 
        if (layoutid === undefined) {
            newVal=0
            addToast("Select a layout", { appearance: 'error', autoDismiss: 'true' })
            // newVal=0
        }
        else {
            handlePutLayoutData()
            newVal=1
        }
    }
    else if(current === 4 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false){
        newVal=1
        // if(selectedid.length === 0 )
        // {
        //     newVal=0 
        //     addToast("Select a Packaged component", { appearance: 'error', autoDismiss: 'true' })
        //     newVal=0
        // }
        // else 
        // if(selected.length >= 1){
        //     handlePutPackageComponentsData()
        //     
        // }
        // else {
        //     newVal=1
        //     socket.emit('join', {projid:userprojid});
        // }
        handlePutPackageComponentsData()
    }
    else if(current === 5 && wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===false){
        
        if(deploymentid!==undefined)
            handlePutDeployment()
        else
        {
            
                let editPackagedata = {
           
                    "_id": userprojid,
                    "composestageindex": 6
                };
                //)
                if(!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true){
                    newVal=1
                    document.querySelector("#spinnerdiv").hidden = false;
                    document.querySelector("#nonspinnerdiv").hidden = true;
                    dispatch(putUserProjectData({data:editPackagedata,instance:instance})).then((res) => {
                        socket.emit('join', {projid:userprojid});
                        dispatch(postUserProjectidData(userprojid)).then((res) => {
                            //checkrepoflag()
                        })
                    })
                }
        }
       
    }

    
     else if(1){
        
        if(document.querySelector("#ProjectName").value!==projectnameval)
        {
        allProjectsNames1?.map((data,index)=>(
                data.projectname===projectN?set=2:newVal=1
                ))
        }
        else
        {
            newVal=1 
        }

                //
                
                
                if (document.querySelector("#ProjectName").value === "" )  {
                    
                   wizarddiv=1
                    addToast("Please enter project name", { appearance: 'error', autoDismiss: 'true' })
                    newVal=0
                    document.querySelector('#modal1').hidden = false
                    document.querySelector('#modal2').hidden = true
                    document.querySelector('#modal3').hidden = true
                    newVal=0
                }
                 if(document.querySelector("#ProjectDescription").value === "")
                {
                    //setwizarddiv(1)
                    wizarddiv=1
                    addToast("Please enter project description", { appearance: 'error', autoDismiss: 'true' })
                    newVal=0
                    document.querySelector('#modal1').hidden = false
                    document.querySelector('#modal2').hidden = true
                    document.querySelector('#modal3').hidden = true
                    newVal=0
                }

                if(document.querySelector('#selectversion').selectedIndex===0)
                {   
                newVal=0
                 addToast("Select any version", { appearance: 'error', autoDismiss: 'true' })
                 newVal=0 
                 }
        }
        else {
               // setwizarddiv(0)
               if(checkid===0)
                {newVal=1}
               wizarddiv=0
                count = count + 1;
            
            //
            document.querySelector('#modal1').hidden = true
            document.querySelector('#modal2').hidden = false
            document.querySelector('#modal3').hidden = false
        }
        if(set===2)
        {
           // setwizarddiv(1)
           wizarddiv=1
            addToast("Project name already exist", { appearance: 'error', autoDismiss: 'true' })
            newVal=0
        }
        if(set===3)
        {
            //setwizarddiv(1)
            wizarddiv=1
            addToast("Access Token is mandatory", { appearance: 'error', autoDismiss: 'true' })
            newVal=0
        }
        if(newVal===1)
        {       
           // setwizarddiv(0)
           wizarddiv=0
            count = count + 1;
            //
            document.querySelector('#modal1').hidden = true
            document.querySelector('#modal2').hidden = false
            document.querySelector('#modal3').hidden = false
        }
        // setPrevious(current)
        //count = count + 1;
        if (count === 3) {
            setVal(1)
        }
        setCurrent(count);
        // 
        
        // 
    }
    const handlePrevious = () => {
        document.querySelector('#SelectedNFR').hidden = true
        
        // setPrevious(current)
        count = count - 1;
        if (current === 3 && activelayout==="Figma") 
        { setmodalsize(1) } 
        setCurrent(count);
       // 

    }

   
    // Method to get targetrepo id
    const addOptionID = async (e) => {
        

       // var getID = document.querySelector('#optionid').value
        var sI = document.querySelector('#selectrepoid').selectedIndex;
        var reponame = document.querySelector('#selectrepoid').options[sI].value
       // 
        setTargetid(sI)
        setrepoid(reponame)
    }
    //version id
    // Method to get targetrepo id
    const addVersionID = async (e) => {
        var getID = document.querySelector('#versionid').value

        setprojversion(getID)
    }
    

    //Do not clear Post and put methods
    //Post data for userProject
    const handlePostUserProjectData = () => {
        
        if(wizarddiv===0)
        {
        methodcalled = true;
        //
        let ProjectName = document.querySelector('#ProjectName').value;
        let ProjectDescription = document.querySelector('#ProjectDescription').value;
        if ((document.querySelector('#ProjectName').value && document.querySelector('#ProjectDescription').value) !== "" && (current === 0)) {
            let formData = {
                // "data":{
                "email": name,
                "projecttypeid": getid,
                "projectname": ProjectName,
                "description": ProjectDescription,
                "projectversionid": projversion,
                "composestageindex": 1
                // }
            }
            if(userprojid===""||userprojid===undefined)
            {
                if(instance.getAllAccounts()[0] !== undefined)
                {
            dispatch(postUserProjectData({data:formData,instance:instance})).then((res) => {
                //
                let res1 = res.payload?.data?.insertedId;
                setuserprojid(res1)
                projidsock = res1
                setprojectnameval(ProjectName)
            })
        }
            }
            else
            {
                let editdata = {
                    "_id": userprojid,
                    "projecttypeid": getid,
                    "projectname": ProjectName,
                    "description": ProjectDescription,
                    "projectversionid": projversion,
                    "composestageindex": 1
                };
                dispatch(putUserProjectData({data:editdata,instance:instance})) 
            }
         }
    }
    }

    //create a new function for put
    const handlePutUserProjectData = () => {
        
        var temp={}
        //
        var arr=[]  
    //    if(projectType==="PWA React"||projectType==="PWA .NET"||projectType==="AI Bot .NET")
    //    {
    //     if(document.querySelector("#deployment").checked===true){

    //         setSelected(["Azure Deployment"])
    //     }
    // }
      
        

      
        if(arr1.length!==0)
        {
            setarr1([])
        }
        else
        {
            if(repoid)
            {
        projrepodata?.map((data, index) => {

           if(repoid===data.id)
           {
           if(data.parameters?.length>0) 
           {
            
            for(let i = 0; i< data.parameters?.length; i++){
              var   id="#"+data.reponame+data.parameters[i]
                id=id.replace(/ /g, "")
                if(document.querySelector(id).value==="")
              {
                setcheckid(1)
                addToast(data.parameters[i]+" field is mandatory", { appearance: 'error', autoDismiss: 'true' })
                newVal=0
              }
              else
              {
                setcheckid(0)
                newVal=1
                // 
                // 
                //.value)         
                var c = data.parameters[i]
                c=c.replace(/ /g, "")
                arr.push(c)
              temp[c] = document.querySelector(id).value
             // 

              }
                
              
           }
        }
    }
    })


if(checkid===0||newVal===1)
{
    arr1.push(temp)

let edittokenrepodata = {
    "_id": userprojid,
    "targetrepoid": repoid,
    "targetParam": arr1,
    "composestageindex": 2
};
    newVal=1
    dispatch(putUserProjectData({data:edittokenrepodata,instance:instance}))
}
}  
}
    
       

    }

    //Put method for theme
    const handlePutThemeData = () => {
        
        // 
        let editthemedata = {
            "_id": userprojid,
            "themeid": themeid,
            "composestageindex": 3
        };
        dispatch(putUserProjectData({data:editthemedata,instance:instance}))

    }

    //Put for Layout
    const handlePutLayoutData = () => {
        
       var figmaf=document.querySelector("#figmafile").value
       var figmat=document.querySelector("#figmatoken").value
    

    //   var figmaf="jIIqhlDON3CZpycm2wiRwg"
    //   var figmat = " figd_3Kc-2pKHetf4gj4i1yXwY24nuBTeLIE0lw2JlV1W"
        var check =document.querySelector("#checkid").value
        var valu=0
       
         //
        let editlayoutdata = {
            "_id": userprojid,
            "layoutid":layoutdata?.filter(x=>x.id===layoutid)[0]?.title==="Figma"? layoutdata?.filter(x=>x.title==="Header and Footer with Top Navigation")[0]?.id:layoutid,
            "figmatoken":figmat,
            "figmafile":figmaf,
            "composestageindex": 4
        };
        //
        dispatch(putUserProjectData({data:editlayoutdata,instance:instance}))
    
    

    }

    const handlePutPackageComponentsData = (e) => {
        
        //methodcalled = true;
    //     if(!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true)
    //    {
    //     document.querySelector("#spinnerdiv").hidden = false;
    //     document.querySelector("#nonspinnerdiv").hidden = true;
    //    } 
    
        //
        var arr=[]   
        var category=""
        
        {nfrdata?.map((nfrdata1, index) => {
            category=nfrdata1.title
            var temp = {"packagecategoryname" : category }
            if(selected.includes(nfrdata1.title)){
                //)?.map(y=>y.param)[0])
                 checkparam=nfrdata1.parameters?.map((data)=>{
                    // 
                    // 
                    
                    
                                   
                   //temp.Tenantid = document.querySelector(id).value;
                    if(nfrdata1?.parameters?.length>0)
                       for(let i = 0; i< nfrdata1?.parameters?.length; i++){
                         var id=nfrdata1?.title+nfrdata1.parameters[i]
                         id=id.replace(/ /g, "")
                         setParamid(id)
                    //var id="#params params"
                    var id1="#"+id
                    //
                    // if(checkparam?.length!==aiparam.length&&wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true)
                    // {
                    //     
                    //     newVal=0         
                    //     setcheckid(1)
                    //     addToast("Input field mandatory"+id1, { appearance: 'error', autoDismiss: 'true' })
                    //     newVal=0
                    // }
                    // else
                     if(document.querySelector(id1).value === ""&&!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true) {  
                        // 
                        newVal=0         
                        setcheckid(1)
                        //
                        // addToast("Input field mandatory"+id1, { appearance: 'error', autoDismiss: 'true' })
                        newVal=0
                    }
                    else {
                        setcheckid(0)
                        newVal=1
                        // 
                        //                                  
                       var c = nfrdata1.parameters[i]       
                            arr.push(c)
                           // [0]?.value,selected,aiparam,id)
                         document.querySelector(id1).value===""?temp[c]=aiparam?.filter(x=>x?.id === id)[0]?.value:temp[c]=document.querySelector(id1).value
                    
                         //    
                    }
                    // 
                    //         
                    //        // .value)         
                    //    var c = checkparam[i]
                    // //    " : "${document.querySelector(id).value}"`
                    //         arr.push(c)
                    //         [0]?.value,selected,aiparam,id)
                    //      document.querySelector(id1).value===""?temp[c]=aiparam?.filter(x=>x?.id === id)[0]?.value:temp[c]=document.querySelector(id1).value
                    
                    //              
                   
                       }   
                     
                })
                if(newVal===1){
                paramsArr.push(temp) 
                }
            }
        });
        }
        if(newVal === 0) {
            addToast("Input field mandatory", { appearance: 'error', autoDismiss: 'true' })
        }
        
       // 
        
        if(newVal===1) {
            // 
            // if(selected.includes("Azure Deployment") === true){
            // // setSelectedid([...selectedid,'63f49ab9731bc50bffcc1995']);
            // if(projectType==="PWA React")
            // selectedid.push('63f49ab9731bc50bffcc1995')
            // if(projectType==="PWA .NET")
            // selectedid.push('63fc33e68263c8467dcd87ad')
            // }
            
            if(getid==="637de4a2efe9bf2ab4b102c7")
            //setSelectedid([...selectedid,"63f49f8a731bc50bffcc199a"]);
            selectedid.push('63f49f8a731bc50bffcc199a')
            let editPackagedata={}
            if(selectedid.length>0)
            {
             editPackagedata = {
           
                "_id": userprojid,
                "packagecategoryid": selectedid.join(),
                "parameters" :paramsArr,
                "composestageindex": wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true? 3 : 5
            }
        }
        else
        {
            editPackagedata = {
           
                "_id": userprojid,
                "composestageindex": wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true? 3 : 5
            };
        }
            //)
           
            // dispatch(putUserProjectData(editPackagedata))
            if(!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true && !wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Deployment")===true ){
                newVal=1
                document.querySelector("#spinnerdiv").hidden = false;
                document.querySelector("#nonspinnerdiv").hidden = true;
                dispatch(putUserProjectData({data:editPackagedata,instance:instance})).then((res) => {
                    socket.emit('join', {projid:userprojid});
                    dispatch(postUserProjectidData(userprojid)).then((res) => {
                        //checkrepoflag()
                    })
                })
            }
            else 
            {
                dispatch(putUserProjectData({data:editPackagedata,instance:instance}))
            }
        }
    }

    const handlePutDeployment=()=>{
        var arr=[]   
        var category=""
        
        {deploymentdata?.map((deploymentdata, index) => {
            category=deploymentdata.title
            var temp = {"DeploymentName":category}
            if(activedeployment.includes(deploymentdata.title)){
                //)?.map(y=>y.param)[0])
                 checkparam=deploymentdata.parameters?.map((data)=>{
                    // 
                    // 
                    
                   
                    if(deploymentdata?.parameters?.length>0)
                       for(let i = 0; i< deploymentdata?.parameters?.length; i++){
                         var id=deploymentdata?.title+deploymentdata.parameters[i]
                         id=id.replace(/ /g, "")
                         setParamid(id)
                   
                    var id1="#"+id
                   
                        //setcheckid(0)
                        //newVal=1
                        // 
                        //                                  
                       var c = deploymentdata.parameters[i]       
                            arr.push(c)
                           // [0]?.value,selected,aiparam,id)
                         temp[c]=document.querySelector(id1).value
                    
                          //   
                    
                     
                   
                       }   
                     
                })
                //if(newVal===1)
                {
                paramsArr1.push(temp) 
                }
            }
        });
        }
        // if(newVal === 0) 
        // {
        //     addToast("Input field mandatory", { appearance: 'error', autoDismiss: 'true' })
        // }
        
        //
        
        //if(newVal===1) 
        {
            if(deploymentid!==undefined)
            {
                let editPackagedata = {
           
                    "_id": userprojid,
                    "deploymentid":deploymentid ,
                    "deploymentparameters" :paramsArr1,
                    "composestageindex": 6
                };
                //)
                if(!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true){
                    newVal=1
                    document.querySelector("#spinnerdiv").hidden = false;
                    document.querySelector("#nonspinnerdiv").hidden = true;
                    dispatch(putUserProjectData({data:editPackagedata,instance:instance})).then((res) => {
                        socket.emit('join', {projid:userprojid});
                        dispatch(postUserProjectidData(userprojid)).then((res) => {
                            //checkrepoflag()
                        })
                    })
                }
            }
            else
            {
                let editPackagedata = {
           
                    "_id": userprojid,
                    "deploymentid":deploymentid ,
                    "deploymentparameters" :paramsArr1,
                    "composestageindex": 6
                };
                //)
                if(!wizardSteps?.map(x => x.steps.map(t => t.stepname))[0]?.includes("Add Dialogues")===true){
                    newVal=1
                    document.querySelector("#spinnerdiv").hidden = false;
                    document.querySelector("#nonspinnerdiv").hidden = true;
                    dispatch(putUserProjectData({data:editPackagedata,instance:instance})).then((res) => {
                        socket.emit('join', {projid:userprojid});
                        dispatch(postUserProjectidData(userprojid)).then((res) => {
                            //checkrepoflag()
                        })
                    })
                }
            }
            
          
        }
    }

    //handledeployment

     /**START for  to add Dialogues */
    
     const handlePutDialoguesData = (dialogues) => {
        document.querySelector("#spinnerdiv").hidden = false;
        document.querySelector("#nonspinnerdiv").hidden = true;
       
        let obj = {
            x: 0,
            y: 150
        }
        for(let i = 0; i < dialogues?.length; i++){
            dialogues[i].id = i+1;
            dialogues[i].type = "parentCustomNode";
            dialogues[i].position ={
                x: 0,
                y: 150
            } 
            // dialogues[i].position.y = 150;
            dialogues[i].deletable = false;
            dialogues[i].nodes = [];
            dialogues[i].edges = [];
        }
        ;
        let addDialoguedata = {
            "_id": userprojid,
            "dialogues":[...dialogues]
        };
        //
        
        dispatch(putUserProjectData({data:addDialoguedata,instance:instance})).then((res) => {
            socket.emit('join', {projid:userprojid});
            dispatch(postUserProjectidData(userprojid)).then((res) => {
                //checkrepoflag()
               
            })
        })
       
    }
    /**END for  to add Dialogues */

  
    
    const checkrepoflag = () => {
        
       
        axios.get(process.env.REACT_APP_BASE_URL + "/filteruserproject?_id=" + userprojid).then((res) => {
            //
            if (res.data[0]?.filewritten !== true)
                checkrepoflag()
            else {
                // 
                // 
                setrepolink(res.data[0]?.repourl + '/zipball/master')
                setrepotreeLink(res.data[0]?.repotreeurl)
                dispatch(fetchGitURL({gitUrl:res.data[0]?.repotreeurl,downloadLink:res.data[0]?.repourl}))
               //  navigate('/explorer?projectName='+res.data[0]?.projectname)
               navigate('/explorer?_id='+res.data[0]._id)
               // navigate('/explorer')
                document.querySelector("#downloadbtn").disabled = false;
                document.querySelector("#explorebtn").disabled = false;
            }
        })

    }
    const handledownload = () => {
        
        //
        window.open("https://www.w3schools.com");

    }
    //

    //Visibility of left menu
    let menuvisibility = <span></span>
    if (menuvisible === 1) {
        menuvisibility = (
            <>
                <li>
                    <File_menu className='react_icon_small' />
                </li>
                <li>
                    <Plus_menu className='react_icon_small' />
                </li>
                <li>
                    <Api_menu className='react_icon_small_fill' />
                </li>
                <li>
                    <Sourcecode_menu className='react_icon_small_fill' />
                </li>
                <li>
                    <Message_menu className='react_icon_small_fill' />
                </li>
            </>
        )
    }

    let projectTypeDisplay = <span></span>
    prodtypedata?.map((prodtypedata, index) => (
        projectTypeDisplay = (projectType !== prodtypedata.title) ?
            (<>
                <Modal.Header closeButton hidden={current === 0 ? false : true}>
                    <Modal.Title className='font_18 font_regular'> {projectType}</Modal.Title>
                </Modal.Header>
            </>) : (<>
                <Modal.Header closeButton hidden={current === 0 ? false : true}>
                    <Modal.Title className='font_18 font_regular'>What you want to Develop?   {projectType}</Modal.Title>
                </Modal.Header>
            </>)
    ))
    let projectVersionDisplay = <span></span>
    projversiondata?.map((prodtypedata, index) => (
        projectVersionDisplay = (getid === prodtypedata.projecttypeid) ? (<><option value={prodtypedata.id}>{prodtypedata.version}</option></>) :
            (<><option value={prodtypedata.id}></option></>)
    ))
  

    //Search Filter*******
    const onSearchproject = (e) =>{
        var searchinput = document.querySelector("#projectsearch").value.toLowerCase()
        setpackagegrid2(packagegrid2.filter(x=>x.projectname?.toLowerCase().includes(searchinput)))

        if(searchinput.length === 0){
            setpackagegrid2(maincontent9)
        }
        // 
    }

    const handleChangeProject = (e) => {
        if(e.target.value.length === 0){
            setpackagegrid2(maincontent9)
        }
    }


  return (
    <>
      <div class="AllApps_screen">
                            <div class="file_name_container">
                                <div class="d-flex align-items-center w-100">
                                    <p className='font_15 font_medium ps-2 mb-0'>Apps</p>
                                    <div class="ms-auto whitespace_nowrap"><button type="button" class="btn btn-primary btn_custom_primary" onClick={() => { handleShow1();setprojectType("What you want to Develop?")}}><Plus size={20} />New app</button></div>

                                </div>
                            </div>
                            <div class="file_container">
                                <div class="row">
                                    <div class="col-lg-12">
                                        <div class="bg_grey_container">
                                            <div class="row align-items-center">
                                                <div class="col-lg-7 text-start">
                                                    <p className='mb-0 font_14 font_medium'>Recent Apps</p>
                                                </div>
                                                <div class="col-lg-5 d-flex justify-content-end">
                                                    <div class="d-flex">
                                                        <div class="custom_inputbtn package">
                                                            <input type="text" 
                                                            class="form-control bg-transparent" 
                                                            id="projectsearch" 
                                                            placeholder="Search"
                                                            onChange={handleChangeProject}
                                                             />
                                                            <div class="searchicon" onClick={onSearchproject}><Search /></div>
                                                        </div>
                                                    </div>

                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="row">
                                    <div class="col-12">
                                    <div className='customdatagrid' style={{width: '100%' }}>
                <DataGrid autoHeight rows={packagegrid2?packagegrid2:[]}  columns={recentappscol?recentappscol:[]}
                // getRowHeight={() => 'auto'}
                initialState={{
                    pagination: {
                      pageSize: 10,
                    },
                  }}
                disableSelectionOnClick
                />
            </div>
                                        {/* <AllAppsGrid /> */}
                                    </div>
                                </div>
                            </div>
                        </div>
            
                        <UserProjectEntryPopup
                handleClose1={handleClose1}
                projectType={projectType}
                show1={show1}
                setprojectType={setprojectType}
                handleClose={handleClose}
                setCurrent={setCurrent}
                reset={reset}
                modalsize={modalsize}
                current={current}
                projectTypeDisplay={projectTypeDisplay}
                Spinner={Spinner}
                prodtypedata={prodtypedata}
                setproductid={setproductid}
                productid={productid}
                setgetid={setgetid}
                hiddenval={hiddenval}
                addVersionID={addVersionID}
                projversiondata={projversiondata}
                addOptionID={addOptionID}
                nfrcount={nfrcount}
                selected={selected}
                setSelected={setSelected}
                selectedid={selectedid}
                setSelectedid={setSelectedid}
                themecount={themecount}
                setthemeid={setthemeid}
                themedata={themedata}
                setactivetheme={setactivetheme}
                setmodalsize={setmodalsize}
                projrepodata={projrepodata}
                nfrdata={nfrdata}
                activetheme={activetheme}
                layoutcount={layoutcount}
                setactivelayout={setactivelayout}
                activelayout={activelayout}
                setlayoutid={setlayoutid}
                completed={completed}
                percentage={percentage}
                handlePrevious={handlePrevious}
                layoutdata={layoutdata}
                handleSlide={handleSlide}
                val={val}
                setfigmatoken={setfigmatoken}
                figmatoken={figmatoken}
                figmafile={figmafile}
                setfigmafile={setfigmafile}
                setService={setService}
                service={service}
                setMenuVisibile={setMenuVisibile}
                handlePostUserProjectData={handlePostUserProjectData}
                handlePutUserProjectData={handlePutUserProjectData}
                handlePutThemeData={handlePutThemeData}
                handlePutLayoutData={handlePutLayoutData}
                handlePutPackageComponentsData={handlePutPackageComponentsData}
                errorbox={errorbox}
                seterrorbox={seterrorbox}
                wizardSteps={wizardSteps}
                socket={socket}
                userprojid={userprojid}
                status={status}
                progressbar={progressbar}   
                setprogressbar={setprogressbar} 
                handlePutDialoguesData = {handlePutDialoguesData}  
                setrepoid={setrepoid}
                repoid={repoid}
                getid={getid}
                setaiparam={setaiparam}
                aiparam={aiparam}
                setthemeselectedlist={setthemeselectedlist}
                setlayoutselectedlist={setlayoutselectedlist}
                themeselectedlist={themeselectedlist}
                layoutselectedlist={layoutselectedlist}
                setTargetid={setTargetid}
                targetid={targetid}
                setContentData={setContentData}
                maincontent={maincontent}
                setthemeData={setthemeData}
                themecontent={themecontent}
                setlayoutData={setlayoutData}
                layoutcontent={layoutcontent}
                setnfrData={setnfrData}
                nfrcontent={nfrcontent}
                allversion={allversion}
                setallversion={setallversion}
                setuserprojid={setuserprojid}
                deploymentdata={deploymentdata}
                setDeploymentData={setDeploymentData}
                activedeployment={activedeployment}
                setactivedeployment={setactivedeployment}
                setDeploymentid={setDeploymentid}
                deploymentid={deploymentid}
            />
                        
    </>
  )
}

export default AllApps

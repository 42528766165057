
import { Search, Plus } from 'react-bootstrap-icons';
import { Link, useLocation } from 'react-router-dom';
import React, { useEffect, useState } from 'react';
import { fetchPackagesGridData, fetchPackagesComponentData, fetchPackagesTypeGridData } from '../store/nfr.actions';
import { useDispatch, useSelector } from "react-redux";
import { DataGrid } from '@mui/x-data-grid';
import { fetchProductTypeData, fetchProjectVersionData } from "../store/productType.actions";
import { fetchNfrTypeData } from "../store/nfr.actions";
import {  useMsal } from "@azure/msal-react";

function Packages() {
    const location = useLocation();

    const [divVal, setdivVal] = useState(1);
    const [getid, setgetid] = useState("6372366d1b568e00d8af2e44");
    const [gettypid, settypeid] = useState("6372366d1b568e00d8af2e44")
    const [getverid, setgetverid] = useState()
    //
    const { instance, accounts, inProgress } = useMsal();

    const [projectType, setprojectType] = useState("PWA React");
    //
    const [value, setValue] = useState(0);
    let myval = 0
    const packagetypecolumns = [
        { field: "name", flex: 1, headerName: "Pakage Type", minWidth: 100 },
        { field: "description", headerName: "Description", width: 550 },
    ];

    const dispatch = useDispatch();
    //UseEffect to dispatch action methods  


    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProjectVersionData({ data: getid, instance: instance }))
            dispatch(fetchNfrTypeData({ data: getid, instance: instance }))
        }
    }, [projectType, getid, instance.getAllAccounts()[0] !== undefined])
    //fetching project types
    const ProjectData = useSelector(
        (state) => state.commonReducer?.prodtypedata?.prodTypeData?.prodtypedata);
    const [prodtypedata, setContentData] = useState(ProjectData);
    useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);
    

    //fetching package grid data
    const maincontent = useSelector((state) => state.commonReducer?.nfr?.gridPackage?.packagegrid);
    const [packagegrid, setpackagegrid] = useState(maincontent);
    useEffect(() => {
        setpackagegrid(maincontent)
    }, [dispatch, maincontent, projectType])

    useEffect(() => {

        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchPackagesGridData({ data: getid, instance: instance, version: getverid, dispatch: dispatch }));
            dispatch(fetchPackagesComponentData({ instance: instance, dispatch: dispatch }));
            dispatch(fetchPackagesTypeGridData(instance));
            dispatch(fetchProductTypeData({ data: "", instance: instance }));
        }

    }, [dispatch
        , location.pathname
        , instance.getAllAccounts()[0] !== undefined])

        useEffect(() => {

            if (instance.getAllAccounts()[0] !== undefined) {
                dispatch(fetchPackagesGridData({ data: getid, instance: instance, version: getverid, dispatch: dispatch }));
                dispatch(fetchPackagesComponentData({ instance: instance, dispatch: dispatch }));
                dispatch(fetchPackagesTypeGridData(instance));
                dispatch(fetchProductTypeData({ data: "", instance: instance }));
            }
    
        }, [getverid])


    //fetching project versions
    const version = useSelector(
        (state) => state.commonReducer?.prodtypedata?.projVersionData?.projversiondata);
    const [projversiondata, setprojversiondata] = useState(version);
    useEffect(() => { setprojversiondata(version); }, [dispatch, version]);
    //

    //NFR 
    const nfrcontent = useSelector(
        (state) => state.commonReducer?.nfr?.nfrTypeData?.nfrdata);
    const [nfrdata, setnfrData] = useState(nfrcontent);

    useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent, getid]);
    //
    let nfrcount = nfrdata?.length;

    //fetching columns data
    const columncontent = useSelector((state) => state.commonReducer?.nfr?.gridPackage?.packagegridColumnData);
    const [packagegridColumnData, setpackagegridColumnData] = useState(columncontent);
    useEffect(() => { setpackagegridColumnData(columncontent) }, [dispatch, columncontent])
    //


    const maincontent1 = useSelector((state) => state.commonReducer?.nfr?.gridComponent?.packagecomponent);
    const [packagegrid1, setpackagegrid1] = useState(maincontent1);
    useEffect(() => { setpackagegrid1(maincontent1?.filter(x => x.projecttypeid?.includes(gettypid))) }, [dispatch, maincontent1, gettypid])
    //

    const columncontent1 = useSelector((state) => state.commonReducer?.nfr?.gridComponent?.packageComponentColumnData);
    const [packagegridColumnData1, setpackagegridColumnData1] = useState(columncontent1);
    useEffect(() => { setpackagegridColumnData1(columncontent1) }, [dispatch, columncontent1])
    //

    const maincontent2 = useSelector(
        (state) => state.commonReducer?.nfr?.packageTypeGrid?.typegrid);
    const [typegrid, settypegrid] = useState(maincontent2);
    useEffect(() => { settypegrid(maincontent2); }, [dispatch, maincontent2]);
    //

    const columncontent2 = useSelector((state) => state.commonReducer?.nfr?.packageTypeGrid?.packageTypeColumnData);
    const [packagegridColumnData2, setpackagegridColumnData2] = useState(columncontent2);
    useEffect(() => { setpackagegridColumnData2(columncontent2) }, [dispatch, columncontent2])
    
    const addOptionID1 = async (e) => {
        setValue(1)
        myval = 1

        setgetverid(e.target.value)

    }





    //For fetching versions as per project type
    const addOptionID = async (e) => {

        // var getID = document.querySelector('#versionid')?.value

        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var sprojType = document.querySelector('#selectProjectType').options[sI].label

        setprojectType(sprojType)

        var getID = document.querySelector('#selectProjectType').options[sI].value


        setgetid(getID)


        var index = document.querySelector('#selectProject').selectedIndex;
        var getIndex = document.querySelector('#selectProject').options[index].value
        settypeid(getIndex)

    }

    useEffect(() => {

        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProjectVersionData({ data: getid, instance: instance }));
            dispatch(fetchNfrTypeData({ data: getid, instance: instance }))
            dispatch(fetchPackagesGridData({ data: getid, instance: instance, version: getverid, dispatch: dispatch }));

        }
    }, [getid])

    const onSearchPackages = (e) => {
        var packageinput = document.querySelector("#packageid").value.toLowerCase()
        setpackagegrid(packagegrid.filter(x => x.typename?.toLowerCase().includes(packageinput) || x.categoryname?.toLowerCase().includes(packageinput)))

        if (packageinput.length === 0) {
            setpackagegrid(maincontent.filter(x => x.projecttypename?.includes(projectType)))
        }

    }

    const handleChangePackages = async (e) => {

        if (e.target.value.length === 0) {
            setpackagegrid(maincontent.filter(x => x.projecttypename?.includes(projectType)))
        } else {
            setpackagegrid(maincontent.filter(x => x.categoryid?.includes(e.target.value)))

        }
    }

   
    //Search Package Type ********

    const onSearchPackageType = (e) => {
        var packagetypeinput = document.querySelector("#packagetypeid").value.toLowerCase()
        settypegrid(typegrid.filter(x => x.name?.toLowerCase().includes(packagetypeinput) || x.description?.toLowerCase().includes(packagetypeinput)))

        if (packagetypeinput.length === 0) {
            settypegrid(maincontent2)
        }
    }

   


    // Search Package Category 

    const onSearchPackageCategory = (e) => {

        var packageCategoryinput = e.target.value
        setpackagegrid1(packagegrid1.filter(x => x.title?.toLowerCase().includes(packageCategoryinput) || x.description?.toLowerCase().includes(packageCategoryinput)))

        if (packageCategoryinput.length === 0) {
            setpackagegrid1(maincontent1.filter(x => x.projecttypeid?.includes(gettypid)))
        }
    }

   

    return (
        <>
            <div class="screen_three">
                <div class="file_name_container">
                    <div class="d-flex align-items-center w-100">
                        <ul class="tabs_package mb-0 ps-3">
                            <li class={divVal === 1 ? "active" : ""} onClick={() => { setdivVal(1) }}>Packages</li>
                            <li class={divVal === 2 ? "active" : ""} onClick={() => { setdivVal(2) }}>Package Type</li>
                            <li class={divVal === 3 ? "active" : ""} onClick={() => { setdivVal(3) }}>Package Category</li>
                        </ul>


                    </div>
                </div>
                <div class="file_container " hidden={divVal === 1 ? false : true} id="container1">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="bg_grey_container">
                                <div class="row">
                                    <div class="col-lg-7 text-start">
                                        <div class="row ">
                                            <div class="col-lg-3 ">
                                                <div class="select_box package">

                                                    <select id="selectProjectType" onChange={() => {
                                                        addOptionID();

                                                    }} class="form-select outlined" aria-label="Default select example">
                                                        {/* <option >Project Type</option> */}
                                                        {prodtypedata?.map((prodtypedata, index) => (
                                                            <option value={prodtypedata?.id} id="projectid">{prodtypedata?.title}</option>
                                                        ))}
                                                    </select>

                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="select_box package">
                                                    <select onChange={addOptionID1} id="selectversion" class="form-select outlined" aria-label="Default select example">
                                                        <option >Project Version</option>
                                                        {projversiondata?.map((prodtypedata, index) => (

                                                            <option value={prodtypedata?.id} id="versionid">{prodtypedata?.version}</option>


                                                        ))}


                                                    </select>
                                                </div>
                                            </div>
                                            <div class="col-lg-3">
                                                <div class="select_box package">
                                                    <select
                                                        onChange={handleChangePackages}

                                                        class="form-select outlined" aria-label="Default select example">
                                                        <option selected>Package Category</option>
                                                        {
                                                            divVal == 1 ? nfrdata?.map((nfrdata, index) => (
                                                                <option value={nfrdata?.id}>{nfrdata?.title}</option>
                                                            )) : <></>}
                                                        {/* {nfrDisplay} */}
                                                    </select>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                    <div class="col-lg-5 d-flex justify-content-end">
                                        <div class="d-flex">
                                            <div class="custom_inputbtn package">
                                                <input type="text"
                                                    class="form-control bg-transparent"
                                                    id="packageid"
                                                    onChange={onSearchPackages}
                                                    placeholder="Search" />
                                                <div class="searchicon" onClick={onSearchPackages}><Search /></div>
                                            </div>
                                        </div>
                                        <div class="ms-auto whitespace_nowrap">
                                            <Link to="/packages/addPackage">
                                                <button type="button" class="btn btn-primary btn_custom_primary">
                                                    <Plus size={20} />
                                                    Add Package
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">

                            <div className='customdatagrid' style={{ width: '100%' }}>
                                <DataGrid autoHeight rows={packagegrid ? packagegrid : []} columns={packagegridColumnData ? packagegridColumnData : []}
                                    initialState={{
                                        pagination: {
                                            pageSize: 10,
                                        },
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file_container " hidden={divVal === 2 ? false : true} id="container2">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="bg_grey_container">
                                <div class="row">

                                    <div class="col-lg-12 d-flex justify-content-end">
                                        <div class="d-flex">
                                            <div class="custom_inputbtn package">
                                                <input type="text"
                                                    class="form-control bg-transparent"
                                                    id="packagetypeid"
                                                    placeholder="Search"
                                                    onChange={onSearchPackageType}
                                                />
                                                <div class="searchicon" onClick={onSearchPackageType}><Search /></div>
                                            </div>
                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            {/* <PackageGrid /> */}
                            <div className='customdatagrid' style={{ width: '100%' }}>
                                <DataGrid autoHeight rows={typegrid} columns={packagetypecolumns}
                                    // getRowHeight={() => 'auto'}
                                    initialState={{
                                        pagination: {
                                            pageSize: 10,
                                        },
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>
                        </div>
                    </div>
                </div>

                <div class="file_container " hidden={divVal === 3 ? false : true} id="container3">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="bg_grey_container">
                                <div class="row">
                                    <div class="col-lg-7 text-start">
                                        <div class="row ">
                                            <div class="col-lg-3">
                                                <div class="select_box package">
                                                    <select id="selectProject" onChange={() => { addOptionID() }} class="form-select outlined" aria-label="Default select example">
                                                        {/* <option >Project Type</option> */}
                                                        {prodtypedata?.map((prodtypedata, index) => (
                                                            <option value={prodtypedata?.id} id="projectid">{prodtypedata?.title}</option>
                                                        ))}
                                                    </select>
                                                </div>
                                            </div>


                                        </div>
                                    </div>
                                    <div class="col-lg-5 d-flex justify-content-end">
                                        <div class="d-flex">
                                            <div class="custom_inputbtn package">
                                                <input type="text"
                                                    class="form-control bg-transparent"
                                                    id="packageCategoryid"
                                                    onChange={onSearchPackageCategory}
                                                    placeholder="Search" />
                                                <div class="searchicon" onClick={onSearchPackageCategory}><Search /></div>
                                            </div>
                                        </div>
                                        <div class="ms-auto whitespace_nowrap">
                                            <Link to="/packages/addpackagecategory">
                                                <button type="button" class="btn btn-primary btn_custom_primary">
                                                    <Plus size={20} />
                                                    Add Package Category
                                                </button>
                                            </Link>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="row">
                        <div class="col-12">
                            <div className='customdatagrid' style={{ width: '100%' }}>
                                <DataGrid autoHeight rows={packagegrid1} columns={packagegridColumnData1}
                                    initialState={{
                                        pagination: {
                                            pageSize: 10,
                                        },
                                    }}
                                    disableSelectionOnClick
                                />
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </>
    )
}

export default Packages
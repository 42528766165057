import React from 'react';
import { ReactComponent as Upload_icon } from '../../images/upload_icon.svg';
import { ReactComponent as Delete_icon } from '../../images/delete-icon.svg';

import {

  uploadFile,
  validateFile,
  deleteAction,
} from "../../common/BLOBStorageConfig";

function IconUpload({
    uploadedBlob,
    setUploadedBlob
}) {

 
  const onFileChange = async (event) => {
    let acceptFileType = [
      "jpeg",
      "jpg",
      "png",
      "avif",
      "gif",
      "svg+xml",
      "webp",
      "bmp",
      "x-icon",
      "tiff",
      "mp4",
      "avi",
      "wmv",
      "mov",
    ];
    let imgResolution = {
      validate: true,
      height: 31,
      width: 30,
      tempDOMID: "tempInfoImg",
      condition: "lesser",
    };
    let files = event.target.files;

    Object.values(files).map((file) => {
      validateFile(file, acceptFileType, imgResolution, 10, "image")
        .then((res) => {
          if (res.validImage) {
            setUploadedBlob();

            uploadFile(file, file.name, "infographics", file.type)
              .then((blobResponse) => {
                setUploadedBlob(blobResponse[0]);
              })
              .catch((ex) => {

              });
          }
          else {

            alert(res.message)
          }
        })
        .catch((err) => {

        });
    });
    event.target.value = "";
  };

  // Deleting the image from blob in infographics section
  const deleteImage = async (fileName) => {

    deleteAction(fileName)
      .then(() => {
        setUploadedBlob(undefined)
      })
      .catch((err) => {
        
      });
  };

 
  return (
    <>
          <div class="col-auto">
            <div class="mb-3">

              <button class="btn btn-outline-secondary  ml-0 position-relative overflow-hidden" hidden={uploadedBlob !== undefined ? true : false}>
                <span class="text-center">
                  <img id="tempInfoImg" class="d-none" />
                  <input
                    name="imagefile"
                    onChange={(event) => onFileChange(event)}
                    multiple
                    type="file"
                    id="inputFile"
                    value=""
                    style={{
                      cursor: "pointer",
                      zIndex: 99999,
                      top: 0,
                      right: 0,
                      bottom: 0,
                      left: 0,
                    }}
                    class="position-absolute opacity-0"
                  />
                  <label for="exampleFormControlInput1" class="form-label">Icon</label>
                  <div class="uploaddContainer">

                    <Upload_icon />
                  </div>


                </span>
              </button>
              <button class="btn btn-outline-secondary  ml-0 position-relative overflow-hidden" hidden={uploadedBlob === undefined ? true : false}>
                <span class="text-center">

                  <label for="exampleFormControlInput1" class="form-label">Icon</label>
                  <div class="uploaddContainer">
                    <img
                      class="img-fluid"
                      src={uploadedBlob?.imageURL}
                    />

                  </div>
                  <div class="deleteimageicon">
                    <Delete_icon
                      onClick={() =>

                        deleteImage(uploadedBlob.name)
                      }
                    />
                  </div>

                </span>
              </button>
            </div>
          </div>
          
    </>
  )
}

export default IconUpload

import { ReactComponent as AddPlus } from '../../images/Add_icon_plus.svg';
import { ReactComponent as DeleteIcon } from "../../images/delete-icon.svg";
function OptionsForm({
    item,
    handleChange,
    handleAdd,
    inputFields,
    inputFieldsCount,
    index,
    handleRemove
    }){
    return(
        <>
        
                    <input 
                        type="text" 
                        class="form-control" 
                        id= {index-"exampleFormControlInput1"}
                        placeholder="Enter option"
                        onChange={(event) => handleChange(event, index)}
                        value={item.optionname}
                        name = "optionname"
                        key = {index}
                        style = {{width: "auto", height: "auto"}}
                    /> <span><DeleteIcon class="me-2" onClick={(event) => handleRemove(event, index)}/></span>     
        </>
    )
}

export default OptionsForm;
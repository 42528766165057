
import React, { useEffect, useState } from 'react'
import { X, Plus } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { FaChevronDown, FaMinus } from 'react-icons/fa';
import { ReactComponent as Search_icon } from '../../images/search_icon.svg';
import { ReactComponent as File_small_icon } from '../../images/file_small_icon.svg';
import { ReactComponent as Three_dots } from '../../images/three_dots.svg';
import Draggable from 'react-draggable';
import Offcanvas from 'react-bootstrap/Offcanvas';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { useDispatch, useSelector } from "react-redux";
import { useLocation } from "react-router-dom";
import { fetchGitURL, getProjectNameGitUrl,putTreeUrl } from '../store/userProject.actions';
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { Octokit } from "octokit";
import { ReactComponent as New_screen_icon } from '../../images/new_screen_icon.svg';
import { ReactComponent as Desktop_icon } from '../../images/desktop_icon.svg';
import { ReactComponent as Tablet_icon } from '../../images/tablet_icon.svg';
import { ReactComponent as Mobile_icon } from '../../images/mobile_icon.svg';
import { ReactComponent as Transfer_icon } from '../../images/transfer_icon.svg';
import { ReactComponent as Publish_icon } from '../../images/publish_icon.svg';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import Modal from 'react-bootstrap/Modal';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

var  filename;
var folderrname
var mainURL;
var mainSubURL;
var gitur;
var totalNoOfFiles;
var decoded;
let subPathArr
var  foldersfilename;
let foldernameArr =[];
var treeID = "";
var filesName;
var addFolderName
var projectName =""
var result;
var tress;
var statusRes;
function FileCreationTab() {


  const dispatch = useDispatch();
  const location = useLocation();
  const data = location.search;
  const {instance, accounts, inProgress} = useMsal();
  useEffect(() => {
    dispatch(getProjectNameGitUrl(data))
  }, [dispatch]);

  useEffect(() => {
    dispatch(getProjectNameGitUrl(data))
  }, [data]);
 
  const getDetails = useSelector((state) => state.commonReducer.singleProjectName.singleProjectName.singleProjectName);
  const [getDetailsData, setgetDetailsData] = useState(getDetails);
  useEffect(() => { setgetDetailsData(getDetails) }, [dispatch, getDetails])
  //

  const [show, setShow] = useState(false);  // modal popup for adding file and folder name
  const [show3, setShow3] = useState(false);
  const handleClose3 = () => setShow3(false);

  const handleShow = () => setShow(true);
  const handleClose = () => setShow(false);

  const [content, setContent] = useState([]);
  const [FileContent, setFileContent] = useState([]);
  const [folderFileContent, setFolderFileContent] = useState([]);
  const [finalTree, setFinalTree] = useState([]);
  const [style, setStyle] = useState("100%");
  const [fileNamee,setFileNamee] = useState([]);
  const [buttonDisable, setbuttonDisable] = useState(false);


  let finalUrl = "";
  let url = "";
  let urlpath = [];


  var urlll = getDetailsData[0]?.treeURL
 // 
  if (getDetailsData[0]?.treeURL !== undefined)

  //get main tree url
  gitur = getDetailsData[0]?.treeURL.replace("https://api.github.com", "")
  //

  mainURL = "GET " + gitur
  //

  //for showing file contents GET call
  mainSubURL = mainURL.substring(0, mainURL.indexOf('/git'))
  //

  //get project name
  projectName = getDetailsData[0]?.projectname
  //

  //download project as zip
  // var linktodownload = getDetailsData[0]?.gitURL
  // var downlink = linktodownload + '/zipball/master'
  // 

  //for homepage
  const getContents = async () => {
    const octokit = new Octokit({
      auth: getDetailsData[0]?.accessToken,
    });
//       const response1 = await octokit.request(
//         `GET /repos/RapidX-Developer-Studio/${projectName}/commits/main`
//         );
//   treeID = response1.data.commit.tree.sha;
//   var repotreeurl = "https://api.github.com/repos/RapidX-Developer-Studio/"+projectName+"/git/trees/" + treeID + "?recursive=1";
//   

    const response = await octokit.request(
      mainURL
    );
    //
     tress = response.data.tree;
    
    totalNoOfFiles =response.data.tree.length  -2
    //
    
    let newData = response.data.tree?.filter(
      (data) => !data.path.includes("/")
    );
    //
    newData=newData.map((temp)=>{
      return {
      path:  temp.path,
      type: temp.type,
      url: temp.url,
      subpath: []

      }
    })
    //
    setContent(newData);
  };
  useEffect(() => {
    
    if(gitur !== undefined ){
  getContents().catch(
   // console.error
    );
    }
  },[gitur] );

//


 //display folder contents
const getUrl=file=>e=>  {
    
 //  
  folderrname = file.path
    const octokit = new Octokit({
        auth: getDetailsData[0]?.accessToken,
    });
    const response =  octokit.request(
    mainURL
    ).then((response)=>{
      let tree = response.data.tree;
      //
    
      for (let index = 0; index < tree.length; index++) {
          //)
        if (tree[index].path === folderrname) {
          url = tree[index].url;
        }
      }
      
      content.filter(x=> x.path === folderrname )
      //)
      getFolderContents(url,file,folderrname);
    });
    
  };


    
const getFolderContents = async (url,file,folderrname) => {
    const octokit = new Octokit({
     auth: getDetailsData[0]?.accessToken,
  
    });
  
    finalUrl = url;
    //
    finalUrl = finalUrl.replace("https://api.github.com", "");
    const response = await octokit.request(`GET ${finalUrl}?recursive=1`);
    //
    let tree = response.data.tree;
    //
    subPathArr = []
    tree=tree.map((temp) => {
        if (temp.path.includes(".js") && !temp.path.includes(".json")) {
             subPathArr.push(temp);
           }
    //

      return{
        parent: folderrname,
        path:  temp.path,
      type: temp.type,
      url: temp.url,
      subpath: []
      }
    });
    file.subpath = subPathArr
    // 
    // 
  
    setFinalTree(tree);
    
  };
 // 


  //Display file content
  const showFileContent = file => e => {
    
    filename = file.path
    //

    const octokit = new Octokit({
      auth: "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",
    });
    const response = octokit.request(

      `${mainSubURL}/contents/${filename}`
    ).then((response) => {
      //
      let decoded = base64_decode(response.data.content);
      //
      setFileContent(decoded);
      setFolderFileContent("")
      document.querySelector("#activeli").hidden = false
    });



  }
  //

  //Display Folder File Contents
  const showFolderFileContent = file=>e=> {
     
  folderrname = file.parent
  //
   foldersfilename = file.path
  //

  if(foldernameArr !== foldersfilename){  
  foldernameArr.push(foldersfilename)
  //
  }

  setFileNamee([...fileNamee,foldersfilename])
  //
  // fileNamee.map((file)=>(
  //   
  // ))
  
    const octokit = new Octokit({
  //      auth: "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",
          auth: getDetailsData[0]?.accessToken,
  
      });
      //
  
      const response =  octokit.request(
    `${mainSubURL}/contents/${folderrname}/${foldersfilename}`
  
      ).then((response)=>{
        // 
        // 
  
        if(foldersfilename.includes('.png')){
          decoded = <img src={`https://raw.githubusercontent.com/RapidX-Developer-Studio/CheckCount/main/${folderrname}/${foldersfilename}`}></img>
        //decoded = <img src={response.data.content}></img>
  
  
        }else{
       decoded = base64_decode(response.data.content) ;
        }
       // 
        setFolderFileContent(decoded);
        setFileContent("")
        document.querySelector("#activeli").hidden=false
  });
 //    
  }
  //



//Put call for adding new file
  const addFile = async () => {
    
   filesName = document.querySelector("#fileName").value
    //
    addFolderName = document.querySelector("#folderName").value
    //

    if(filesName === "" && addFolderName === "Select any option" ){
        document.querySelector("#fileNameErr").innerText ="Please enter file name" 
        document.querySelector("#fileName").className="border-danger form-control"
        document.querySelector("#folderNameErr").innerText = "Please select folder name"
    }else if(addFolderName === "Select any option"){
        document.querySelector("#folderNameErr").innerText = "Please select folder name"
    }else if(filesName === ""){
        document.querySelector("#fileNameErr").innerText ="Please enter file name" 
        document.querySelector("#fileName").className="border-danger form-control"
    }
   else if( document.querySelector("#fileNameErr").innerText !==""){
    alert("Invalid file name")
   }
   else if(document.querySelector("#folderNameErr").innerText !== "" ){
   alert("Please select folder name")
   }
   else {
    setbuttonDisable(true)
    try{
    const octokit = new Octokit({
      auth: getDetailsData[0]?.accessToken,
    });
  
    const response = await octokit.request(
         `PUT /repos/RapidX-Developer-Studio/${projectName}/contents/${addFolderName}/${filesName}`,
        {
            message: "Added new file",
            content: ""
        }
    )
        //
        statusRes = response.status     
    }  catch(error){
        //
    }
    if(statusRes === 201){
        updateContents();

    }else{
        alert("File name already exists")
        setbuttonDisable(false)
    }
}
  }
  
  //Update file contents
  const updateContents = async () => {
    
    try{
        const octokit = new Octokit({
            auth: getDetailsData[0]?.accessToken
          });
          const response = await octokit.request(
            `GET /repos/RapidX-Developer-Studio/${projectName}/commits/main`
            );
      treeID = response.data.commit.tree.sha;
      var repotreeurl = "https://api.github.com/repos/RapidX-Developer-Studio/"+projectName+"/git/trees/" + treeID + "?recursive=1";
      //
    }catch(error){
     // 
    }
    let editTreeId = {
        "_id": getDetailsData[0]?.id,
        "repotreeurl": repotreeurl
    };
    dispatch(putTreeUrl({data:editTreeId,instance:instance})).then(()=>{
            dispatch(getProjectNameGitUrl({data:data,instance:instance})).then(()=>{ 
    handleClose()               
                  setbuttonDisable(false)
  
            
            })
        })
        
  }
  
//   updateContents()

 
let dropdownArr=<></>
if(fileNamee.length === 0){
dropdownArr=<div></div>
}else{
dropdownArr=<><DropdownButton variant="outline-secondary"
title="Files"
id="input-group-dropdown-4"
align="end">
{fileNamee.map((file)=>(
<Dropdown.Item href="#">{file}</Dropdown.Item>
))} 
</DropdownButton></>
}



function handleFileName(){
   var filenamee = document.querySelector("#fileName").value
   var pattern = /^[A-Za-z]{1,}\.(?:js)$/;
    result = pattern.test(filenamee);

    if(result === false){
    document.querySelector("#fileName").className="border-danger form-control"
    document.querySelector("#fileNameErr").innerText="Invalid file name"
    }else{
    document.querySelector("#fileName").className=" form-control"
    document.querySelector("#fileNameErr").innerText=""
    }
}
function handleFolderName(){
    if(document.querySelector("#folderName").value === "Select any option"){
        document.querySelector("#folderNameErr").innerText = "Please select folder name"  
    }else{
        document.querySelector("#folderNameErr").innerText = ""  
    }
    }
  return (
    <>
    {/* {} */}
      <div class="d-flex h-100">
        <div class="Folderstructure_menu flex-fill ">
          <div className='sidebar_main d-flex'>
            <div class="Filemenu_right w-100 d-flex flex-column h-100 ">
              {/* <div class="d-flex filemenusdiv maintabs bordercolor mb-3"> */}
              <div class="d-flex bordercolor insertdivss mb-3 " >
                <div className='font_13 tabs font_regular active'>Screens</div>
                <div className='font_13 tabs font_regular ms-3'>Components</div>
                {/* <X className='react_icon_small ms-auto' /> */}
              </div>
              <div class="d-flex flex-column">
                <Button onClick={handleShow} className='d-block primary_button font_regular mb-3 w-100' variant="primary" size="sm">New +</Button>
              </div>
              <div class="d-flex">
                <div class="mb-3 custom_search_icon">
                  <Search_icon className='search_icon' />
                  <input type="email" class="form-control border-0 bg-transparent font_light" id="exampleFormControlInput1" placeholder="Search" />
                </div>
              </div>
              {/* <div class="d-flex pages"> */}
              <div class="d-flex h-100 mt-3 plus_menus explorermaindiv ">

                {/* <ul className='file_pages w-100'>
                                        <li className='d-flex active align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page1</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page2</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page3</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page4</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                        <li className='d-flex align-items-center flex-row'>
                                            <File_small_icon className='me-2' />
                                            <span className='font_10 font_light'>page5</span>
                                            <Three_dots className='ms-auto threedots' />
                                        </li>
                                    </ul> */}


                        <ProSidebarProvider>
                        <Sidebar>
                            <Menu>
                                          {content.map((file) => (
                                              file.type === "tree" ?
                                                  <SubMenu label={file.path} id="foldername" value={file.path} onClick={getUrl(file)}>
                                                      {/* {} */}
                                                      {file?.subpath?.map((file1) => (
                                                          <MenuItem id="folderfilename" onClick={showFolderFileContent(file1)}>{file1.path}</MenuItem>
                                                      ))}
                                                  </SubMenu> :
                                                  file.path.includes(".js") && !file.path.includes(".json") ?
                                                      <MenuItem onClick={showFileContent(file)} id="filename"> {file.path}</MenuItem> : <></>
                                          ))}                                                      
        
                        </Menu>
                </Sidebar>
                </ProSidebarProvider>
                
              </div>
               
              <Modal className="custom_popupp" show={show} onHide={handleClose}>
                        <Modal.Header closeButton>
                            {/* <Modal.Title><p class="font_20 font_regular mb-0">Why do you think it is not useful ?</p></Modal.Title> */}
                        </Modal.Header>
                        <br></br>
                        <Modal.Body className="py-0">
                            <div class="row">
                                <div class="col-12">
                                    <div class="mb-0 required">
                                        {/* <label for="exampleFormControlInput1" class="form-label"></label> */}
                                        <label id="filename" class="">File name</label>
                                        <input type="text" class="form-control" id="fileName"  placeholder="Enter file name" onChange={handleFileName}/>
                                        <label id="fileNameErr" class="error-label"></label>

                                        <br></br>

                                        <label id="nameErr" >Folder name </label>
                                        
                                        <select id="folderName" onClick={handleFolderName}>
                                            <option>Select any option</option>
                                            
                                            {content.map((file)=>(
                                                file.type === "tree"?
                                            <option >{file.path}</option>:""
                                            ))}
                                        </select>
                                        <br></br>
                                        <label id="folderNameErr" class="error-label"></label>


                                    </div>
                                </div>
                            </div>
                        </Modal.Body>
                        <br></br>
                        <Modal.Footer>
                        <Button className="btn btn-custom-primary-small " onClick={addFile} disabled ={buttonDisable} >
                                Submit
                            </Button>
                            <Button className="btn btn-custom-secondary-small" onClick={handleClose} >
                                Cancel
                            </Button>
                        </Modal.Footer>
                    </Modal>
            </div>
          </div>
        </div>


        <div class="flex-fill contentmain_container">
          {/* screen 1 */}
          <div class="screen_one">
            <div class="file_name_container">
              <ul className='topinnerul'>
                {/* <li className='active'>
                  <div class="file_name_list align-items-center d-flex">
                    <div><Plus size={20} className='react_icon_vsmall' /></div>
                    <div className='font_12 font_light pt-1 pe-4 ps-2'>Insert</div>
                    <div><FaChevronDown size={12} className='react_icon_vsmall' /></div>
                  </div>
                </li> */}
                <li className='active'>
                  <div class="file_name_list align-items-center d-flex">
                    <div><New_screen_icon className='react_icon_small_fill' /></div>
                    <div className='font_10 font_light pt-0 file_name pe-4 ps-2'>Screen 1</div>
                  </div>
                </li>
                <li>
                  <div class="file_name_list align-items-center d-flex">
                    <div><New_screen_icon className='react_icon_small_fill' /></div>
                    <div className='font_10 font_light pt-0 file_name pe-4 ps-2'>Screen 2</div>
                  </div>
                </li>
              </ul>
              <div class="right_side_iconsMenus whitespace_nowrap">
                <ul>
                  <li><Desktop_icon onClick={()=>{setStyle("100%")}}/></li>
                  <li><Tablet_icon onClick={()=>{setStyle("768px")}}/></li>
                  <li><Mobile_icon className='me-2' onClick={()=>{setStyle("400px")}}/></li>
                  <li><Transfer_icon className='ms-2' /></li>
                  <li><Publish_icon /></li>
                </ul>
              </div>
            </div>
            <div class="file_container dnd">
              <div className='Droparea_components' style={{width: style}}>

                {/* <div > */}


                {FileContent !== ""? <pre><code>{FileContent}</code></pre> :<pre><code>{folderFileContent}</code></pre>}                             

                  {/* <Draggable 
                            //bounds={{left: 0, top: 0, right: 100, bottom: 200}}
                            
                            > 
                            </Draggable> */}
                {/* </div> */}



              </div>
              <Offcanvas className="propertiescanvas" show={show3} onHide={handleClose3} backdrop={false} placement="end">

              </Offcanvas>
            </div>
            <div class="file_name_container bottom">
                            {/* <DropdownButton
                                variant="outline-secondary"
                                title="Page 1"
                                id="input-group-dropdown-4"
                                align="end"
                            >
                                
                                {}
                                {foldernameArr.map((file)=>(
                              
                                 <Dropdown.Item href="#">bvv</Dropdown.Item>
                                 
                                

                               
                                ))}  
                        
                              
                            </DropdownButton> */}

                            {dropdownArr}
                        {/* {}
                           {dropdownArr} */}
                            <div className='ms-auto whitespace_nowrap d-flex align-items-center'>
                                <FaMinus className='me-2' /><Form.Range /><Plus size={27} className='ms-1' />
                                <span className='font_10 ms-3'>40%</span>
                            </div>
                        </div>
          </div>
          {/* screen 1 ends*/}
        </div>
      </div>
    </>
  )
}

export default FileCreationTab
import React, { memo, useEffect, useState } from 'react';
import { Modal } from "react-bootstrap";
import { Search, Plus, XLg } from 'react-bootstrap-icons';
import { useDispatch, useSelector } from "react-redux";
import 'react-circular-progressbar/dist/styles.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { useNavigate, useLocation } from "react-router-dom";
import { ReactComponent as Green_tick } from '../images/green_tick_progress.svg';
import Loader from '../images/loader.gif';
import Spinner from 'react-bootstrap/Spinner';
import { postCodeGeneration } from '../main/store/userProject.actions';

var firststepstatus;
let count = 0;
var setTitle = []
let vac = 0;
let set1 = 0;
let sizeset = 0
let CrossSymbol,CrossSymboltheme, CrossSymbolnfr, CrossSymbollayout, search;;

function CommitPopup(
    { 
        popupshow, setShowPopup,socket, pages, status
    }) {
    const dispatch = useDispatch();
    const location = useLocation();
    const navigate = useNavigate();
    const[progressbar, setprogressbar] = useState(0);
    const[progStatus, setprogstatus] = useState([]);
    // 
    // 
    // )
    const gencode = () =>{
        //status=[];
        dispatch( postCodeGeneration(location.search.replace("?_id=","")) )  
        //document.querySelector("#codegenbtn").hidden = true;
        document.querySelector("#codegendiv").hidden = false;
    }
    useEffect(() => {
        if(socket !== undefined){
            socket.on('receive_message', (data) => {
    
                //
                //if(data?.filename !== undefined)
                status.push(data)
                //
                setprogstatus([...progStatus, data])
                //setprogressbar(status.filter(x=>x.status === "Completed").length)
              });
        }

        
    })
     useEffect(() => {
        
        setprogressbar(progStatus?.filter(x=>x.status === "Completed").length)

    },[progStatus])
    useEffect(()=>{
        if(progressbar !== 0 && progressbar=== pages?.length){
            //setTimeout(200000)
           // 
            setTimeout(end, 5000)


            
        }

    },[progressbar])
    const end = () =>{
       // 
        socket?.disconnect(location.search.replace("?_id=",""))
                    navigate("/")
    }
    // 
    //  > 100 ? 100:progressbar)
    return (
        <>
            <ToastProvider placement="top-center" >
                <Modal show={popupshow} onHide={() => {status=[];setprogstatus([]);socket?.disconnect(location.search.replace("?_id=",""));setShowPopup(false)}} scrollable backdrop="static"
                    //keyboard={false} centered dialogClassName={(reset !== 1) ? (modalsize === 1 && current === 0) ? "modal-85w" : (modalsize === 1 && current === 4||modalsize === 1 && current === 3||( selected.length>0) ) ? "modal-95w" : "modal-75w" : (modalsize === 1 && current === 0) ? "modal-75w" : (modalsize === 1 && current === 4) ? "modal-95w" : "modal-75w"}>
                    keyboard={false} centered dialogClassName="modal-75w">

                    {/* <Modal.Header closeButton hidden={current === 0 ? false : true}> */}
                    {/* </Modal.Header> */}
                    <Modal.Header closeButton={true}>
                        <Modal.Title className='font_18 font_regular w-100'>
                            <div class="d-flex align-items-center">
                            <p class="mb-0 ">Code Generation</p>
                            <button class="btn btn-primary ms-auto me-5" onClick={()=>{gencode();}}>Commit Code</button>
                            </div>
                        
                        </Modal.Title>
                    </Modal.Header>
                   
                    <Modal.Body id="codegendiv" className='p-0 position-relative'>
                    <div class="h-100 w-100 bg_grey_2 resultscreen" id="spinnerdiv">
                            <div class="row g-0 h-100">
                                <div class="col-lg-8 p-3 bg_grey_1">
                                    <div class="row m-0 p-2" id="5" >
                                    {/* <div class="row m-0 p-2" id="5" hidden={laststep === 5?false:true }> */}
                                       
                                        <div class="col-12 pb-3 myprofile_progreebar">
                                            <div class="desktop_progress_align">
                                                <div class="d-flex pt-3 pb-2">
                                                    <div className='font_14 font_regular text-white'><span style={{ color: "#72B266" }}> {(progressbar * Math.ceil(100/pages?.length)) > 100 ? 100 : progressbar * Math.ceil(100/pages?.length) }% Update in progress…</span> </div>
                                                    <div className='font_14 font_regular ms-auto'><span> {progressbar}</span> / <span>{pages?.length}</span> Completed</div>
                                                </div>
                                                <div class="progress">
                                                    <div style={{ width: `${progressbar * Math.ceil(100/pages?.length)> 100 ? 100 : progressbar * Math.ceil(100/pages?.length)}%` }} class="progress-bar progress-bar-striped progress-bar-animated" role="progressbar" aria-valuemin="0" aria-valuemax="100"></div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="col-12 ">
                                            <ul class="list_progress">
                                                
                                                    {pages?.sort()?.map((page)=>(
                                                        <>
                                                            
                                                            <>
                                                                <li class="mb-2">
                                                                <div class="d-flex flex-row align-items-center">
                                                            
                                                                {
                                                     
                                                                    progStatus?.filter(x => x.filename === page && x?.status === "Completed").length > 0 ?
                                                                    <>
                                                                        <div class="d-flex flex-row align-items-center">
                                                                            <div class="">
                                                                                <Green_tick />
                                                                            </div>
                                                                            <div class="ms-2 font_14 font_regular">{page}</div>
                                                                        </div>
                                                                        <div class="ms-auto font_14 text-success">Completed</div>
                                                                    </>
                                                                    :
                                                                    progStatus?.filter(x => x.filename=== page && x?.status === "In progress").length > 0 ?
                                                                        <><div class="d-flex flex-row align-items-center">
                                                                            <div class="">
                                                                                <Spinner animation="border" variant="primary" />
                                                                            </div>
                                                                            <div class="ms-2 font_14 font_regular">{page}</div>
                                                                        </div>
                                                                        {/* <div class="ms-2 font_14 font_regular">3 secs</div> */}
                                                                            <div class="ms-auto font_14 text-info">In progress</div></>
                                                                        :
                                                                        <>
                                                                            <div class="d-flex flex-row align-items-center">
                                                                                <div class="">
                                                                                    <div class="notstarted_div"></div>
                                                                                </div>
                                                                                <div class="ms-2 font_14 font_regular">{page}</div>
                                                                            </div>
                                                                            {/* <div class="ms-2 font_14 font_regular">3 secs</div> */}
                                                                            <div class="ms-auto font_14 text-warning">Not Started</div>

                                                                        </>
                                                                        
                                                                }
                                                            
                                                            </div>
                                                           </li>
                                                            </>

                                                   
                                                        </>
                                                    ))}
                                                       
                                            </ul>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-4 p-3 align-items-center d-flex">
                                    <img className='img-fluid' src={Loader}></img>
                                </div>
                            </div>

                        </div>
                    </Modal.Body>                  
                   
                </Modal>
            </ToastProvider>
        </>
    )
}

export default CommitPopup

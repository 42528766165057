import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

  
  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });

  async function generateClient(instance){ 
    const accessTokenRequest = { scopes: [process.env.REACT_APP_API_CONFIGS_SCOPE],
       account: instance.getAllAccounts()[0], };
        // 
        const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
  return `Bearer ${accessTokenResponse.accessToken}`; }
class ComponentScreenService extends RequestHandler {

    //Post for Component Data
    async postComponentData(compData,instance){
      // var heads = await generateClient(instance)
        const res = await client1.post("/screencomponents/create-screencomponent",compData
        // ,{headers: { "Authorization": heads}}
        );
        //
        return res;
    }
    async getAllComponents(instance) {
        // 
        var heads = await generateClient(instance)
        const res = await client1.get("/screencomponents",{headers: { "Authorization": heads}});
        //
        return res.data
    }    
    async postPageScreenComp(compData,instance){
      var heads = await generateClient(instance)
      const res = await client1.post("/pagescreencomp/create-pagescreencomp",compData,{headers: { "Authorization": heads}});
      //
      return res;
    }
    async postPageScreenCompCSS(compData,instance){
      var heads = await generateClient(instance)
      const res = await client1.post("/pagescreencss/create-pagescreencss",compData,{headers: { "Authorization": heads}});
      //
      return res;
    }
    async getAllPageComponents(instance,data) {
      // 
      var heads = await generateClient(instance)
      const res = await client1.get("/pagescreencomp?userprojectid="+data,{headers: { "Authorization": heads}});
      //
      return res.data
    } 
    async getAllPageComponentsCss(instance,data) {
      // 
      var heads = await generateClient(instance)
      const res = await client1.get("/pagescreencss?userprojectid="+data,{headers: { "Authorization": heads}});
      //
      return res.data
    }  
    //delete pagescreencomp 
    async deletepagescreencomp(instance,data) {
      var heads = await generateClient(instance)
      const res = await client1.delete("/pagescreencomp/delete-pagescreencomp"+data,{headers: { "Authorization": heads}});
      //
      return res.data
    }
    //update pagescreencomp 
    async updatepagescreencss(instance,data) {
      var heads = await generateClient(instance)
      const res = await client1.put("/pagescreencss/update-pagescreencss"+data,{headers: { "Authorization": heads}});
      //
      return res.data
    }
}
export default new ComponentScreenService;

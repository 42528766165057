import ReactFlow, { useNodesState, useEdgesState, addEdge, useReactFlow, getConnectedEdges, getIncomers,getOutgoers } from 'reactflow';
import React, { useCallback, useEffect, useRef, useState } from 'react';
import { putUserNodesData, getProjectDetails } from "../../main/store/userProject.actions";
import ParentCustomNode from "./ParentCustomNode";
import ButtonCustomNode from "./ButtonCustomNode";
import MessageCustomNode from "./MessageCustomNode";
import AskQuestionsNode from "./AskQuestionsNode";
import NewCustomNode from "./NewCustomNode";
import EndConversationNode from "./EndConversationNode";
import { useDispatch, useSelector } from "react-redux";
import 'reactflow/dist/style.css';
import { useLocation, useParams } from "react-router-dom";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import RedirectNode from "./RedirectNode";

//For custom nodes
const nodeTypes = { 
  newCustomNode: NewCustomNode,  
  parentCustomNode: ParentCustomNode,
  buttonCustomNode: ButtonCustomNode,
  messageCustomNode: MessageCustomNode,
  askQuestionsNode: AskQuestionsNode,
  endConversationNode: EndConversationNode,
  redirectNode: RedirectNode
};

//let id = 2;
//const getId = () => `${id++}`;
const getId = () => String(parseInt(Math.random(1000000)*100000));

// let messageNodeYPosCnt = 150;
// const getMessgeNodeYPosCnt = () => `${messageNodeYPosCnt+50}`;

const fitViewOptions = {
  padding: 3,
};

const CreateNode = () => {
  let dispatch = useDispatch();
  const {instance, accounts, inProgress} = useMsal();
  const [nodes, setNodes, onNodesChange] = useNodesState([]);
  const [edges, setEdges, onEdgesChange] = useEdgesState([]);
  let [stepNameForNode, setStepNameForNode] = useState('');
  let [addNodeFlag, setAddNodeFlag] = useState(false);
  let [currentDialogue, setCurrent] = useState(); 
  const reactFlowWrapper = useRef(null);
  const location = useLocation();
  const urlData = location.search;
  let urlDataItems = urlData.split("=");
  const { dialoguesId } = useParams();
  let id = localStorage.getItem("userprojectid");

  useEffect(() => {
    //
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch(getProjectDetails({data:id,instance:instance}))
    }
  },[dispatch, id,instance.getAllAccounts()[0] !== undefined])

const projectData = useSelector((state) => state.commonReducer.projectdetails.ProjectDetails.projectdetails);
//
const [ dialogues, setDialoguesData ] = useState(projectData?.[0]?.dialogues) 
useEffect(() => {
  setDialoguesData(projectData?.[0]?.dialogues);
},[projectData])

  useEffect(() => { 
    if(dialogues){
      //
      setNodes([]);
      setEdges([]);
      setAddNodeFlag(false);
      currentDialogue = dialogues.filter((item) => item.id == dialoguesId);
      setCurrent(currentDialogue);
      if(currentDialogue.length >  0){
        //Check if nodes and edges are there or not
        if(currentDialogue[0].nodes.length == 0){
          //When nodes and edges are empty add first default node with Dialogue name 
          setNodes((nds) => nds.concat(
            {
              id: '0',
              type: 'parentCustomNode',
              position: { x: 0, y: 0 },
              deletable: false,
              data: { 
                label: currentDialogue[0].dialoguename, 
                message: currentDialogue[0].description,
                sendData: {stepName: "",prompt: "",validate: "",options: [],retryPrompt: ""},
                nodeHeight: 79,
              },
              width: 150,
              height: 79,
              
            }
          ));

          //When nodes and edges are empty add second default node as button 
          setNodes((nds) => nds.concat(
            {
              id: '1',
              type: 'buttonCustomNode',
              position: { x: 0, y: 150 },
              deletable: true,
              data: { 
                label: 'Node', 
                message: "button node",
                sendData: {stepName: "",prompt: "",validate: "",options: [],retryPrompt: ""},
                nodeHeight: 79,
              },
              width: 150,
              height: 79
            }
          ));

          //When nodes and edges are empty add edge to connect first and second default node
          setEdges((eds) => eds.concat( 
            {
              id: 'edges-e0-1',
              source: '0',
              target: '1',
              type: "straight",
              label: 'Add Node',
              labelBgPadding: [8, 4],
              labelBgStyle: { fill: 'darkblue', color: 'darkblue', fillOpacity: 0.7 },
              style:{strokeWidth:1.5}
              }
            ));
        }
        else{
          //get the nodes from api
          let newCurrentNodes = currentDialogue[0].nodes.map((item) => {
            return{
              ...item, 
              id: String(item.id),  
              width: 150
            }
          })
          //Sets the nodes data from api
          for(let i = 0; i < newCurrentNodes.length; i++){
            // if(newCurrentNodes[i].type == "buttonCustomNode"){
            //   messageNodeYPosCnt = newCurrentNodes[i].position.y;
            //   
            // }
            
            setNodes((nds) => nds.concat(
              {
                id: newCurrentNodes[i].id,
                type: newCurrentNodes[i].type,
                position: { 
                  x: newCurrentNodes[i].position.x, 
                  y: newCurrentNodes[i].position.y 
                },
                deletable: newCurrentNodes[i].deletable,
                data: { 
                  label: newCurrentNodes[i].data.label, 
                  message: newCurrentNodes[i].data.message,
                  onDelete: (()=>{handleNodeDelete(newCurrentNodes[i].id)}),
                  onEdit: (()=>{handleNodeEdit(newCurrentNodes[i].id)}),
                  sendData: {
                      stepName: newCurrentNodes[i].stepName,
                      prompt: newCurrentNodes[i].data.sendData.prompt,
                      validate: newCurrentNodes[i].data.sendData.validate,
                      options: newCurrentNodes[i].data.sendData.options,
                      //model: newCurrentNodes[i].data.sendData.model,
                      retryPrompt: newCurrentNodes[i].data.sendData.retryPrompt
                  },
                  nodeHeight: newCurrentNodes[i].data.nodeHeight,
                },
                width: 150,
                height: newCurrentNodes[i].data.nodeHeight
              }
            ));
          }
          //get the edges from api
          let newCurrentEdges = currentDialogue[0].edges.map((item) => {
            return{
              id: item.id,
              source: String(item.source),
              target:  String(item.target),
              type: "straight",
            }
          })
          //Sets the edges data from api
          for(let i = 0; i < newCurrentEdges.length; i++){
            setEdges((eds) => eds.concat(
              {
                id: newCurrentEdges[i].id,
                source: newCurrentEdges[i].source,
                target: newCurrentEdges[i].target,
                type: "straight",
                style:{strokeWidth:1.5}
              }
            ));
          }
        }
      }
    }
  },[dialoguesId]);

  const onConnect = useCallback((params) => setEdges((eds) => addEdge(params, eds)), []);
  const [addnode, setAddnode] = useState(false);
  const [addChildeNode, setAddChildeNode] = useState(false);
  const [parentNode, setParentNode] = useState(null);
  const rectFlowInstance = useReactFlow();
  
  // 
  // 
  
  //Create the edges dynamically when new node is added
  let initialEdge;
  if(nodes.length > 1){
     initialEdge = {
      id: String(parseInt(Math.random(100000000)*1000000)),
      source: nodes[nodes.length-2].id,
      target: nodes[nodes.length-1].id,
      type: "straight",
      labelBgPadding: [8, 4],
      labelBgBorderRadius: 10,
      labelBgStyle: { fill: 'darkblue', color: 'darkblue', fillOpacity: 0 },
      style:{strokeWidth:1.5}
    }
  }
  
  useEffect(()=>{
    if(addnode){
      setEdges((eds) => eds.concat({
        ...initialEdge,
      }));
      setAddnode(false);
      setParentNode(null);
    }
    if(addChildeNode){
      setEdges((eds) => eds.concat({
        id: String(parseInt(Math.random(100000000)*1000000)),
        source:nodes[nodes.length-1].id,
        target: '1',
        type: "straight",
        style:{strokeWidth:1.5}
      }));
      setAddChildeNode(false);
      setParentNode(null);
    }
  },[nodes])

  //To add button node to the last in nodes array
    const  insertAndShift = (arr, from, to) => {
      let cutOut = arr.splice(from, 1) [0];
      arr.splice(to, 0, cutOut);            
    }

  //On Button Node click create another new custom node
  const handleNodeClick = (e, data) => {  
    let prevNode = getIncomers(data, nodes, edges);
    let yPos = 0;
    yPos = (prevNode[0].height + prevNode[0].position.y)+20;
   
    // if(nodes.length > 2){
    //   insertAndShift(nodes, 1, nodes.length-1);
    // }
    let Id = getId();
   
    //When user clicks on button node add the new node here
    if(data.type == "buttonCustomNode"){
      setAddNodeFlag(false);
      setNodes((nds) => nds.concat({
        id: '2',
        type : 'newCustomNode',
        position : { x: data.position.x, y: yPos},
        deletable: true,
        data: {
          label: "custom", 
          message: "Custom node",
          sendData: {stepName: "",prompt: "",validate: "",options: [],retryPrompt: ""},
          nodeHeight: 0,
        },
        width: 150,
        height: 205
      }));
      setAddChildeNode(true);
      setParentNode(nodes[nodes.length-2]);
      //Modify the edge whose target node is 1 to 2
      let tempEdges = [];
      setEdges((eds) => {
        tempEdges = eds;
        for(let i = 0; i < eds.length; i++){
          if(eds[i].target == '1'){
            eds[i].target = '2';
          }
        }
        return eds;
      });
      //Modify the button node x and y position
      setNodes((nds) => {
        //
        for(let i = 0; i < nds.length; i++){
          if(nds[i].type == 'buttonCustomNode'){
            // let btnprevNode = getIncomers(nds[i], nds, edges);
            // 
            // let btnYPos = 0;
            // btnYPos = (btnprevNode[0].height + btnprevNode[0].position.y)+20;
            nds[i].position.x = data.position.x;
            //nds[i].position.y = data.position.y+50;
            //nds[i].position.y = yPos;
            nds[i].position.y = (nds[nds.length-1].height + nds[nds.length-1].position.y)+20;
            
          }
        }
        return nds;
      });
    }
    //When user click on newCustom node 
    if(data.type == "newCustomNode"){
      //Add the Message node here
        if(data.data.selectedItem == "ShowMessage"){
        setNodes((nds) => nds.concat({
          id: Id,
          type : 'messageCustomNode',
          position : { x: data.position.x, y: yPos},
          deletable: true,
          data: {
            label: "Message", 
            message:"",
            sendData: {prompt: "",validate: "",options: [],retryPrompt: ""}, 
            onDelete: (()=>{handleNodeDelete(Id)}),
            onEdit: (()=>{handleNodeEdit(Id)}),
            nodeHeight: 0
          },
          height: 79
        }));
        setAddChildeNode(true);
        //Remove the newCustom node from nodes array
        setNodes((nds) => {
          for(let i = 0; i < nds.length; i++){
            if(nds[i].id == '2'){
              nds.splice(i,1);
            }
          }
          return nds;
        });
        //Remove  all the edges associated with newCustom nodes from edges array
        setEdges((eds) => {
          for(let i = 0; i < eds.length; i++){
            if(eds[i].target == '2'){
              eds[i].target = Id
            }
            if(eds[i].source == '2'){
              eds.splice(i,1);
            }  
          }
          return eds;
        });
         
        setParentNode(nodes[nodes.length-2]);
        setAddNodeFlag(true);//Flag to store the nodes and edges in database
      }
      
        //Add the RedirectNode Conversation node here
        if(data.data.selectedItem == "Redirect"){
         // 
          if(data.data.item){
            setNodes((nds) => nds.concat({
              id: Id,
              type : 'redirectNode',
              position : { x: data.position.x, y: yPos},
              deletable: true,
              data: {
                label: "Redirect", 
                message: data.data.item.dialoguename,
                id: data.data.item.id,
                actionLink:data.data.actionLink,
                sendData: {prompt: "",validate: "",options: [],retryPrompt: ""}, 
                onDelete: (()=>{handleNodeDelete(Id)}),
                onEdit: (()=>{handleNodeEdit(Id)}),
                nodeHeight: 0
              },
              height: 79
            }));
            setAddChildeNode(true);
            //Remove the newCustom node from nodes array
            setNodes((nds) => {
              for(let i = 0; i < nds.length; i++){
                if(nds[i].id == '2'){
                  nds.splice(i,1);
                }
              }
              return nds;
            });
            //Remove  all the edges associated with newCustom nodes from edges array
            setEdges((eds) => {
              for(let i = 0; i < eds.length; i++){
                if(eds[i].target == '2'){
                  eds[i].target = Id
                }
                if(eds[i].source == '2'){
                  eds.splice(i,1);
                }  
              }
              return eds;
            });
             
            setParentNode(nodes[nodes.length-2]);
            setAddNodeFlag(true);//Flag to store the nodes and edges in database
          }
       
        }

       //Add the End Conversation node here
       if(data.data.selectedItem == "EndConversation"){
        setNodes((nds) => nds.concat({
          id: Id,
          type : 'endConversationNode',
          position : { x: data.position.x, y: yPos},
          deletable: true,
          data: {
            label: "End", 
            message:"End of Conversation",
            sendData: {prompt: "",validate: "",options: [],retryPrompt: ""}, 
            onDelete: (()=>{handleNodeDelete(Id)}),
            onEdit: (()=>{handleNodeEdit(Id)}),
            nodeHeight: 0
          },
          height: 79
        }));
        setAddChildeNode(true);
        //Remove the newCustom node from nodes array
        setNodes((nds) => {
          for(let i = 0; i < nds.length; i++){
            if(nds[i].id == '2'){
              nds.splice(i,1);
            }
          }
          return nds;
        });
        //Remove  all the edges associated with newCustom nodes from edges array
        setEdges((eds) => {
          for(let i = 0; i < eds.length; i++){
            if(eds[i].target == '2'){
              eds[i].target = Id
            }
            if(eds[i].source == '2'){
              eds.splice(i,1);
            }  
          }
          return eds;
        });
         
        setParentNode(nodes[nodes.length-2]);
        setAddNodeFlag(true);//Flag to store the nodes and edges in database
      }

      //to show ask questions node
      if(data.data.selectedItem == "AskQuestions"){
        setNodes((nds) => nds.concat({
          id: Id,
          type : 'askQuestionsNode',
          position : { x: data.position.x, y: yPos},
          deletable: true,
          height: 394,
          data: {
            label: "Questions", 
            message:"", 
            sendData: {stepName: "",prompt: "",validate: "",options: [],retryPrompt: ""}, 
            onDelete: (()=>{handleNodeDelete(Id)}),
            onEdit: (()=>{handleNodeEdit(Id)}),
            nodeHeight: 0
          }
        }));
        setAddChildeNode(true);
        //Remove the newCustom node from nodes array
        setNodes((nds) => {
          //
          for(let i = 0; i < nds.length; i++){
            if(nds[i].id == '2'){
              nds.splice(i,1);
            }
          }
          return nds;
        });
        //Remove  all the edges associated with newCustom nodes from edges array
        setEdges((eds) => {
          for(let i = 0; i < eds.length; i++){
            if(eds[i].target == '2'){
              eds[i].target = Id
            }
            if(eds[i].source == '2'){
              eds.splice(i,1);
            }  
          }
          return eds;
        });
         //Modify the button node x and y position
        setNodes((nds) => {
        //
        for(let i = 0; i < nds.length; i++){
          if(nds[i].type == 'buttonCustomNode'){
            nds[i].position.x = data.position.x;
            nds[i].position.y = (nds[nds.length-1].height + nds[nds.length-1].position.y)+20;
            
          }
        }
        return nds;
      });
        setParentNode(nodes[nodes.length-2]);
        setAddNodeFlag(true);//Flag to store the nodes and edges in database
      }
    }
  }

  const handleNodeDelete = (deleteNodeId) => {
   // rectFlowInstance.setNodes((nds) => nds.filter((item) => item.id !== deleteNodeId));
    let tempTarget = {};

    rectFlowInstance.setEdges((eds) => {
      for(let i = 0; i< eds.length; i++){
        if(eds[i].source ===  deleteNodeId){
          tempTarget = eds[i];
          eds.splice(i,1);
          break;
        }  
      }
      return eds;
    })

    rectFlowInstance.setEdges((eds) => {
      for(let i = 0; i< eds.length; i++){
        //
        if(eds[i].target === deleteNodeId){
          eds[i].target = tempTarget.target;
          break;
        }
      }
      return eds;
    })

    let deleteNodePos = { x: 0, y: 0 }
    let deletePos = 0;
    rectFlowInstance.setNodes((nds) => {
      for(let i = 0; i < nds.length; i++){
        if(nds[i].id === deleteNodeId){
          deletePos = i;
          deleteNodePos.x = nds[i].position.x;
          deleteNodePos.y = nds[i].position.y;
          break;
        }
      }
      return nds;
    })

    rectFlowInstance.setNodes((nds) => {
      for(let i = 0; i < nds.length; i++){
        if(nds[i].id === tempTarget.target){
          nds[i].position.x = deleteNodePos.x;
          nds[i].position.y = deleteNodePos.y;
        }
        // else{
        //   
        //   if(nds[i].id !== '0'){
        //     nds[i].position.x = nds[i-1].position.x;
        //     nds[i].position.y = nds[i-1].position.y+100;
        //   }
        // }
      }
      return nds;
    })
    rectFlowInstance.setNodes((nds) => nds.filter((item) => item.id !== deleteNodeId));
   
    setAddNodeFlag(true);
  }

  const handleNodeEdit = (editId) => {
    rectFlowInstance.setNodes((nds) => {
      for(let i = 0; i < nds.length; i++){
        if(nds[i].id === editId){
          nds[i].height = nds[i].data.nodeHeight;
          break;
        }
      }
      return nds;
    })
    
    
    let newnode = rectFlowInstance.getNodes();
    let newEdge = rectFlowInstance.getEdges();
    //
    let btnNodeIndex = newnode.findIndex((item) => item.type === "buttonCustomNode");
    insertAndShift(newnode, btnNodeIndex, newnode.length-1);
    //
    let tempEditNodeId = editId;
    let tempNodeArray = [];
    for(let i = 0; i < newEdge.length; i++){
      if(newEdge[i].source === tempEditNodeId){
        tempNodeArray.push(newEdge[i].target);
        tempEditNodeId = newEdge[i].target
      }
    }
   
    let currentEditNode = rectFlowInstance.getNode(editId);
    for(let j = 0; j < tempNodeArray.length; j++){
      for(let i = 0; i < newnode.length; i++){
          if(newnode[i].id === tempNodeArray[j]){
            newnode[i].position.y = (currentEditNode.height+currentEditNode.position.y)+50;
            newnode[i].positionAbsolute.y = (currentEditNode.height+currentEditNode.position.y)+50;
            currentEditNode = newnode[i];
          }
        }
    }
 
    let newnode1 = rectFlowInstance.getNodes();
    //
    setAddNodeFlag(true);
  }
  
  //Store the nodes and edges in database
  useEffect(() => {
    if(instance.getAllAccounts()[0] !== undefined && addNodeFlag){
      storeDialogue();
    }
  },[nodes,instance.getAllAccounts()[0] !== undefined])

  //Api call to store nodes and edges in database
  const storeDialogue = () => {
    var stepName = stepNameForNode;
    //
    let nodesData = nodes.map((item) => {
      //let prevStepName = item.data.sendData.stepName;
      if(item.type === "askQuestionsNode"){
        setStepNameForNode(item.data.sendData.stepName);
        stepName = item.data.sendData.stepName;
        stepNameForNode = item.data.sendData.stepName;
      }
      // else{
      //   item.data.sendData.stepName = stepNameForNode;
      // }
      let obj = {
        id: item.id, 
        type: item.type, 
        position: { x: item.position.x, y: item.position.y }, 
        deletable: item.deletable,
        stepName: stepName,
        data: { 
          label: item.data.label, 
          message: item.data.message,
          actionLink:item.data.actionLink,
          sendData: {
            prompt: item.data.sendData.prompt,
            validate: item.data.sendData.validate,
            options: item.data.sendData.options,
            //model: item.data.sendData.model,
            retryPrompt: item.data.sendData.retryPrompt
          },
          nodeHeight: item.data.nodeHeight
        }
      }
      return obj;
    })
    //
    let edgesData = edges.map((item) => {
      let obj = {
        id: item.id, 
        source: item.source, 
        target: item.target, 
        nodeid: item.source
      }
      return obj;
    })
  
    let dialogueObj = {
      _id: id,
      dialogueid: dialoguesId,
      nodes: nodesData,
      edges: edgesData
    }
   //dispatch(putUserNodesData(dialogueObj));
   //
   if(instance.getAllAccounts()[0] !== undefined){
    
   
    
   dispatch(putUserNodesData({data:dialogueObj, instance: instance}));
   dispatch(getProjectDetails({data:id,instance:instance}));
   }
  }

  return (
    <div className="wrapper" ref={reactFlowWrapper} style={{width:"100%", height:"100vh"}}>
      <ReactFlow 
        nodes={nodes}
        edges={edges}
        onNodesChange={onNodesChange}
        onEdgesChange={onEdgesChange}
        onConnect={onConnect}
        onNodeClick={handleNodeClick}
        fitView
        fitViewOptions={fitViewOptions}
        nodeTypes = {nodeTypes}
        defaultPosition ={[0, 0]} 
      />
    </div>
  );
};

export default CreateNode;


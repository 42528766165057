import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

class WizardService extends RequestHandler {
    
    //fetch all product type data
    async getWizardData(data, instance) {
      //

        
        var res
        if(data===""||data===undefined||data==="undefined")
        {
         res = await this.requestHandler(instance).get("/wizard");
        }
        else
        {
         res = await this.requestHandler(instance).get("/wizard?projecttype="+data);
       
        }
        //
        return res.data

    }
}

export default new WizardService;
import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";


const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });
  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });

async function generateClient(instance){
    const accessTokenRequest = { scopes: [process.env.REACT_APP_API_CONFIGS_SCOPE], account: instance.getAllAccounts()[0], }; 
    // 
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
    return `Bearer ${accessTokenResponse.accessToken}`;
}


class UserProjectService extends RequestHandler {

    //Post for user project
    async postUserProjData(userProjdata,instance){
        // 
        const accessTokenRequest = { scopes: this.scope, account: instance.getAllAccounts()[0], }; 
         
        // const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
        // var heads = `Bearer ${accessTokenResponse.accessToken}`;
         
        const res = await client1.post("/userprojects/create-userproject",userProjdata,
        // {headers: { "Authorization": heads },}
        );
        
        return res;
    }

    async postUserProjidData(userProjiddata){
      // 
      

      const res = await client.post("/codewrite?userprojectid="+userProjiddata);
      //
      return res;
  }

    //edit for user project
    async putUserProjData(userProjdata,instance){
      
      // var heads = await generateClient(instance) 
      
        const res = await client1.put(`/userprojects/update-userprojects/${userProjdata._id}`,userProjdata,
        // {headers: { "Authorization": heads}}
        );
        
        return res.data;
      }

//fetch method for all projects by email id ...only top 5 apps
      async getAllProjectsGridData(name,instance) {
        // 
       //"+name+"limit5"
      // filteruserproject?page=1&limit=5
        const res = await  this.requestHandler(instance).get("/userprojects/filter-userprojects?useremail="+name);
        //
        return res.data
    }

    //delete project
    async deleteProject(id) {
      
      const res = await client.delete("/deleteGitProj?projectid="+id);
      
      return res.data;
  }
    //Get github link w.r.t project name
      async getProjectNameGitUrl(data, instance) {
        // 
        //
        const res = await this.requestHandler(instance).get("userprojects/filter-userprojects"+data);
        //
        return res.data
    }

//fetch method for all projects by email id 
//All Apps
    async getAllProjectsGridData1(name,instance) {
      // 
      const res = await  this.requestHandler(instance).get("/userprojects/filter-alluserprojects?useremail="+name);
      //
      return res.data
  }
  //edit tree url
  async putTreeUrl(userProjdata,instance){
    var heads = await generateClient(instance) 
        const res = await client1.put(`/userprojects/update-userprojects/${userProjdata._id}`,userProjdata,{headers: { "Authorization": heads}});
    //
    return res.data;
  }

  //fetch project details
  async getProjectDetails(name,instance) {
    // 
   //"+name+"limit5"
    const res = await this.requestHandler(instance).get("/userprojects/filter-alluserprojects?id="+name);
    //
    return res.data
}

  //START for Storing the user Nodes and edges-Bot studio Page
    async putUserNodesData(userNodedata,instance){
     var heads = await generateClient(instance)
      let sendData = {
        dialogues : { 
              "id" : userNodedata.dialogueid,
              "nodes": userNodedata.nodes,
              "edges": userNodedata.edges
          }
      }
      const res = await client1.put("/userprojects/update-nodes/"+userNodedata._id,sendData,{headers: { "Authorization": heads}});
      //
      return res.data;
    }
  //END for Storing the user Nodes and edges-Bot studio Page


  //fetch method for all projects 

    async getAllProjectsNames(instance) {
      // 
      const res = await this.requestHandler(instance).get("/userprojects/filter-alluserprojects");
      //
      return res.data
  }

  //PUT for page related controls
  async putuserpagedata(data){
    const res = await client.put("/putPageControls",data);
    //
    return res.data;
  }
  async postCodeGeneration(data){
    const res = await client.post("/codeGeneration?userprojectid="+data);
    //
    return res.data;
  }
}
export default new UserProjectService;

import CreateNode from "./CreateNode";
import { ReactFlowProvider } from 'reactflow';
function ChatBotMainScreen(){
    return (
        <div class="flex-fill contentmain_container">
        {/* screen chatbot */}
        <div class="screen_one">
            <div class="file_container dnd">
                    <ReactFlowProvider>
                        <CreateNode></CreateNode>
                    </ReactFlowProvider>
                    </div>
            </div>
            {/* screen chatbot ends*/}
        </div>
            
    )
}

export default ChatBotMainScreen;
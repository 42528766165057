import {Suspense, lazy, useState} from "react";
import {BrowserRouter, Route, Routes, Navigate} from "react-router-dom";
import {useMsal} from "@azure/msal-react";
import { ToastProvider, useToasts } from 'react-toast-notifications';

import Webdesignermain from "../Layout";
import AllApps from "../main/home/AllApps";
import Home from "../main/home/Home";
import Explorer from "../main/home/Explorer";
import Packages from "../main/home/Packages";
import AddPackages from "../main/home/AddPackages";
import InsertControl from "../main/home/InsertControl";
import MobileStudio from "../main/home/MobileStudio";
import FileCreationTab from "../main/home/FileCreationTab";
import ApiTab from "../main/home/ApiTab";
import ChatBot from "../main/home/ChatBot";
import ProjectDetails from "../ProjectDetails";
import PackageCategoryEntry from "../main/home/PackageCategoryEntry";
import AddThemes from "../main/themes/AddThemes";
import EditThemes from "../main/themes/EditThemes";
import Themes from "../main/themes/Themes";
import Layout from "../main/layout/Layout";
import AddLayout from "../main/layout/AddLayout";
import EditLayout from "../main/layout/EditLayout";
import DatasourceEntryForm from "../components/DatasourceEntryForm"
import EditPackage from "../main/home/packages/EditPackage"
import WebStudio from "../main/WebStudio";
import EditPackageCategory from "../main/home/packages/EditPackageCategory";
import Component from "../main/home/component";
import ChatBotMainScreen from "../components/ChatBotComponents/ChatBotMainScreen";
import Explorer_Existing from "../main/home/Explorer-ExistingProject";
// import {useLocation, useNavigate} from "react-router-dom";

function RouterConfig() {
    const { instance } = useMsal();
    const[openToggle,setOpenToggle]=useState(false);
    const [sidebarhide, setSidebarhide] = useState("two");
    const [isActive,setActive]=useState();
   
    //let loggedIn = localStorage.getItem("UserName") != "undefined" ? true : false;
    let loggedIn = instance.getAllAccounts().length>0 ? true : false;

    function getActivePath(handlePath) {
      setActive(handlePath);
    }

    return (
      <Suspense fallback={<>...</>}>
      <BrowserRouter>
        <Routes>
            <Route exact path="/" element={<Webdesignermain openToggle={openToggle} setOpenToggle={setOpenToggle} sidebarhide={sidebarhide}  setSidebarhide={setSidebarhide} isActive={isActive} setActive={setActive} getActivePath = {getActivePath}/>}> 
                
                <Route exact path="/" element={<ToastProvider  placement="top-right" ><Home /></ToastProvider>}/>
                <Route exact path="/allApps" element={<ToastProvider placement="top-right" ><AllApps /></ToastProvider>}/>
                <Route exact path="/explorer" element={<Explorer openToggle={openToggle} setOpenToggle={setOpenToggle}/>}/>
                <Route exact path="/explorerEP" element={<Explorer_Existing openToggle={openToggle} setOpenToggle={setOpenToggle}/>}/>

                <Route exact path="/packages" element={<Packages />}/>
                <Route exact path="/packages/addPackage" element={<AddPackages />}/>
                <Route exact path="/packages/editPackage" element={<EditPackage />}/>
                <Route exact path="/packages/editPackageCategory" element={<EditPackageCategory />}/>
                <Route exact path="/themes" element={<Themes />}/>
                <Route exact path="/themes/addthemes" element={<ToastProvider placement="top-right" ><AddThemes /></ToastProvider>}/>
                <Route exact path="/themes/editthemes" element={<EditThemes />}/>
                <Route exact path="/layout" element={<Layout />}/>
                <Route exact path="/layout/addlayout" element={<ToastProvider placement="top-right" ><AddLayout /></ToastProvider>}/>
                <Route exact path="/layout/editlayout" element={<EditLayout />}/>
                <Route       onLeave={ ()=>{
                  alert("hi")
                } }
 exact path="/webStudio" element={<ToastProvider  placement="top-center" ><InsertControl openToggle={openToggle} setOpenToggle={setOpenToggle} sidebarhide={sidebarhide}  setSidebarhide={setSidebarhide} isActive={isActive} setActive={setActive}/></ToastProvider>}/>
                <Route exact path="/mobileStudio" element={<ToastProvider  placement="top-center" ><MobileStudio sidebarhide={sidebarhide}  setSidebarhide={setSidebarhide} openToggle={openToggle} setOpenToggle={setOpenToggle} /></ToastProvider>}/>
                <Route exact path="/addFile" element={<FileCreationTab />}/>
                <Route exact path="/api" element={<ApiTab />}/>
                <Route exact path="/botStudio" element={<ChatBot />}>
                  <Route path=":dialoguesId" element={<ChatBotMainScreen />} />
                </Route>
                <Route exact path="/projectDetails" element={< ProjectDetails/>}/>
                <Route exact path="/allApps/projectDetails" element={< ProjectDetails/>}/>
                <Route exact path="/packages/addpackagecategory" element={<PackageCategoryEntry />}/>
                <Route exact path="/dataSourceEntry" element={<DatasourceEntryForm />}/>
                <Route exact path="/webstudionew" element={<WebStudio />}/>
                <Route exact path="/component" element={<ToastProvider  placement="top-right" ><Component /></ToastProvider>}/>

            </Route>
            {/* <Route exact path = "/allProjects" element = {<AllApps />} /> */}

            <Route path="*" element={<Webdesignermain />} />

        </Routes>
      </BrowserRouter>
      </Suspense>
    );
  }
  
  export default RouterConfig;
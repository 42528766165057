import React, { useState, useEffect } from "react";
import Offcanvas from 'react-bootstrap/Offcanvas';
import Button from 'react-bootstrap/Button';
import { CopyToClipboard } from 'react-copy-to-clipboard';
import { Prism as SyntaxHighlighter } from 'react-syntax-highlighter';
import { okaidia } from 'react-syntax-highlighter/dist/esm/styles/prism';
import { getGPTRespImg, getGPTResponse } from '../services/openAIService';
import './CodeExplanation.css';
import ImageUpload from '../main/CommanMethods/ImageUpload';
import 'bootstrap/dist/css/bootstrap.min.css';
import Copy from '../images/copy.png';
import Code from '../images/code.png';
import CommitCodeModal from "./FilePush";
import Tooltip from "@material-ui/core/Tooltip";


const CodeExplanation = (props) => {
    const [summary, setSummary] = useState("");
    const [code, setCode] = useState(props.data);
    const [input, setInput] = useState('');
    const [conversation, setConversation] = useState([]);
    const [isTyping, setIsTyping] = useState(false);
    const [typingMessage, setTypingMessage] = useState("");
    const [typingMessageTemp, setTypingMessageTemp] = useState("");

    const [promptImg, setPromptImg] = useState();
    const [fileContent, setFileContent] = useState('');

    const [imagePreview, setImagePreview] = useState(null);


    useEffect(() => {
        setCode(props.data);
    }, [props.data]);

    const onImageUpload = (img) => {
        setPromptImg(img);
    };

    const handleInputChange = (e) => {
        setInput(e.target.value);
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        try {
            let prompt = input;

            if (code) {
                prompt = code + "based on the given code," + input;
            }

            // Clear the input field immediately
            setConversation([...conversation, { role: 'user', content: input }]);
            setInput('');
            setIsTyping(true);
            let aiResponse;

            setImagePreview(null)
            if (promptImg) {
                let tempInput = input;
                if (props.projectDetails?.projectTypeDetails?.title)
                    tempInput = input+" with less styles" + " for " + props.projectDetails?.projectTypeDetails?.title + " based on the design";
                aiResponse = await getGPTRespImg(promptImg, tempInput);
            } else {
                aiResponse = await getGPTResponse(prompt);
            }
            let temp = extractCode(aiResponse)
            setTypingMessageTemp(temp==''?aiResponse:'```'+temp+'```');
            setFileContent(extractCode(aiResponse).replace("jsx",""));
            simulateTyping(temp==''?aiResponse:'```'+temp+'```');
            setSummary(aiResponse);
        } catch (error) {
            console.error('Error getting response', error);
        }
    };


    const handleKeyDown = (e) => {
        if (e.key === 'Enter') {
            handleSubmit(e);
        }
    };

    const simulateTyping = (text) => {
        let index = -1;
        setTypingMessage("");
        const interval = setInterval(() => {
            if (index < text?.length) {
                setTypingMessage(prev => prev + text[index]);
                index++;
            } else {
                clearInterval(interval);
                setConversation(prevConversation => [...prevConversation, { role: 'assistant', content: text }]);
                setIsTyping(false);
                setTypingMessage("");
            }
        }, 1); // Adjust typing speed by changing the interval time (in milliseconds)
    };

    const extractCode = (text) => {
        const match = text.match(/```([\s\S]*?)```/);
        return match ? match[1].trim() : '';
    };

    const extractCodeT = (text) => {
        const match = text.split('```')
        return match ? match[1].trim() : '';
    };
    const [showModal, setShowModal] = useState(false);

    const handleCodeButtonClick = () => {
        setShowModal(true);
    };

    return (
        <>
            <Offcanvas.Header closeButton>
                <Offcanvas.Title>Chat with Code Assistant</Offcanvas.Title>
            </Offcanvas.Header>
            <div>
                <div className="CodeExplanation">
                    <div className="chat-container">
                        <div className="conversation">
                            {conversation.map((message, index) => (
                                <div key={index} className={`message ${message.role}`}>
                                    <div className="message-content">
                                        {message.content.includes('```') ? (
                                            <div className="code-block">
                                                <SyntaxHighlighter language="javascript" style={okaidia}>
                                                    {extractCode(message.content)}
                                                </SyntaxHighlighter>
                                                <CopyToClipboard text={extractCode(message.content)}>
                                                <img src={Copy} title="copy code"  alt="Uploaded" className="copy-button" />
                                                </CopyToClipboard>
                                                <img  title="commit code" src={Code} alt="Uploaded" className="code-button" onClick={handleCodeButtonClick} />
                                            </div>
                                        ) : (
                                            message.content
                                        )}
                                    </div>
                                </div>
                            ))}
                            {isTyping && (
                                <div className="message assistant">
                                    {/* <span>{typingMessage}</span> */}
                                    <div className="message-content">
                                        {typingMessage.includes('```') ? (
                                            <div className="code-block">
                                                <SyntaxHighlighter language="javascript" style={okaidia}>
                                                    {extractCodeT(typingMessage)}
                                                </SyntaxHighlighter>
                                                <CopyToClipboard text={extractCodeT(typingMessage)}>
                                                <img src={Copy} title="copy code"  alt="Uploaded" className="copy-button" />
                                                </CopyToClipboard>
                                                <img title="commit code" src={Code} alt="Uploaded" className="code-button" onClick={handleCodeButtonClick} />


                                            </div>
                                        ) : (
                                            typingMessage)}
                                    </div>
                                </div>
                            )}
                        </div>
                        <form onSubmit={handleSubmit} className="input-form">
                            <input
                                type="text"
                                value={input}
                                onChange={handleInputChange}
                                onKeyDown={handleKeyDown}
                                placeholder="Enter your prompt"
                                className="chat-input"
                            />
                            <ImageUpload onImageUpload={onImageUpload} imagePreview={imagePreview} setImagePreview={setImagePreview} />
                            <button type="submit" className="send-button">Send</button>
                        </form>
                    </div>
                </div>
            </div>
            <CommitCodeModal 
                show={showModal} 
                onHide={() => setShowModal(false)} 
                fileContent={fileContent} 
                treeURL={props?.projectDetails?.treeURL}
                getContents={            props.getContents              }
                accessToken={props?.projectDetails?.accessToken}

            />
        </>
    );
};

export default CodeExplanation;

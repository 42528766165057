import { createAsyncThunk } from "@reduxjs/toolkit";
import productTypeService from "../../services/productTypeService";

export const fetchProductTypeData = createAsyncThunk("producttypestore/fetchProductTypeData", async(data) => {
    
    // 
    var StrapiSearch;
    // if(data.includes("?&search") === true){
    //     StrapiSearch = data.substring((data.indexOf("search")+7),data.split("&", data.indexOf("=")).join("=").length)
    //     }else{
    //       StrapiSearch = data.includes("&search")?data.substring((data.indexOf("search")+7),data.split("&", data.lastIndexOf("&")).join("&").length) : "";
    //     }
    const fetchData = async () => {
        return productTypeService.getProductTypeData(data.data, data.instance) 
    }

    try{
        const rawData = await fetchData();
        //
        const prodTypeData = [];

        for (const prodtype of rawData) {
            const cardData = {
                id: prodtype._id,
                title: prodtype.title,
                description: prodtype.description,
                icon: prodtype.icon,
                isCognitiveServices: prodtype.isCognitiveServices,
            };
            prodTypeData.push(cardData);
        }

        //
        return {
            prodtypedata: prodTypeData?.length !==0 ? prodTypeData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );

  export const fetchProjectRepoData = createAsyncThunk("producttypestore/fetchProjectRepoData", async(instance) => {
    // 
    const fetchData = async () => {
        return productTypeService.getProjectRepoData(instance) 
    }

    try{
        const rawData = await fetchData();
        const projRepoData = [];
        for (const projver of rawData) {
            const cardData = {
                id: projver._id,
                reponame: projver.reponame,
                parameters:projver.parameters,         
            };
            projRepoData.push(cardData);
        }
        //
        return {
            projrepodata: projRepoData?.length !==0 ? projRepoData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );

  export const fetchProjectVersionData = createAsyncThunk("producttypestore/fetchProjectVersionData", async(data) => {
 
    const fetchData = async () => {
        return productTypeService.getProjectVersionData(data.data,data.instance) 
    }

    try{
        const rawData = await fetchData();

        const projVersionData = [];

        for (const projver of rawData) {
            const cardData = {
                id: projver?._id,
                projecttypeid:projver?.projecttypeid,
                version: projver?.version,
            };
            projVersionData.push(cardData);
        }

        //
        return {
            projversiondata: projVersionData?.length !==0 ? projVersionData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );

  export const fetchAllVersions = createAsyncThunk("producttypestore/fetchAllVersions", async(instance) => {
    // 
    const fetchData = async () => {
        return productTypeService.getAllVersions(instance) 
    }

    try{
        const rawData = await fetchData();

        const projVersionData = [];

        for (const projver of rawData) {
            const cardData = {
                id: projver?._id,
                projecttypeid:projver?.projecttypeid?._id,
                version: projver?.version,
                projecttype: projver?.projecttypeid?.title,
            };
            projVersionData.push(cardData);
        }

        //
        return {
            allversion: projVersionData?.length !==0 ? projVersionData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );
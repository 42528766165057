import React, { memo, useEffect, useState } from 'react';
import { DataGrid } from '@mui/x-data-grid';
import { GridRowsProp, GridColDef } from '@mui/x-data-grid';
const rows: GridRowsProp = [
    { id: 1, col1: 'Hello', col2: 'Rutuja' },
    { id: 2, col1: 'DataGridPro', col2: 'Rutuja' },
    { id: 3, col1: 'MUI', col2: 'Rutuja' },
    { id: 4, col1: 'MUI grid', col2: 'Rutuja' }, 
    { id: 5, col1: 'MUI popup', col2: 'Rutuja' }, 
    { id: 6, col1: 'MUI canvas', col2: 'Rutuja' },
    { id: 7, col1: 'MUI offcanvas', col2: 'Rutuja' }, 
    { id: 8, col1: 'MUI dropdown', col2: 'Rutuja' }, 
    { id: 9, col1: 'MUI selectbox', col2: 'Rutuja' },
];

const columns: GridColDef[] = [
    { field: 'col1', headerName: 'Projects',flex: 1,
    minWidth: 150 },
    { field: 'col2', headerName: 'Created by',flex: 1,
    minWidth: 150, },
];

function PackageGrid({packagegrid,packagegridColumnData}) {
    
    return (
        <>

            <div className='customdatagrid' style={{width: '100%' }}>
                <DataGrid autoHeight rows={rows}  columns={columns}
                getRowHeight={() => 'auto'}
                disableSelectionOnClick
                />
            </div>
        </>
    );
}
export default PackageGrid;
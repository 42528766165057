import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });

class ComponentService extends RequestHandler {
    
    //fetch all product type data
    async getComponentData(instance) {
        // 
        //
        const res = await this.requestHandler(instance).get("/screencomponents");
        //
        return res.data

    }
}

export default new ComponentService;
import {combineReducers} from "@reduxjs/toolkit";
import prodtypedata from "./productTypeSlice"
import projrepodata from "./productTypeSlice"
import projversiondata from "./productTypeSlice"
import allversion from "./productTypeSlice"
import nfr from "./nfrSlice"
import theme from "./themeSlice"
import layout from "./layoutSlice"
import allProjects from "./userProjectSlice"
import allProjects1 from "./userProjectSlice"
import giturl from "./userProjectSlice"
import singleProjectName from "./userProjectSlice"
import wizard from "./wizardSlice"
import projectdetails from "./userProjectSlice"
import allProjectsNames from "./userProjectSlice"
import themegrid from "./themeSlice"
import themeedit from "./themeSlice"
import layoutsgrid from "./layoutSlice"
import layoutsdata from "./layoutSlice"
import controldata from "./inputControlSlice"
import droparea from "./dropAreaSlice"
import projects5 from "./userProjectSlice"
import categoryshow from "./nfrSlice"
import deploymentdata from "./deploymentSlice"
import component from "./componentSlice"
const reducer = combineReducers({
    prodtypedata,
    projrepodata,
    projversiondata,
    nfr,
    theme,
    layout,
    allProjects,
    singleProjectName,
    allProjects1,
    giturl,
    wizard,
    projectdetails,
    droparea,
    controldata,
    allProjectsNames,
    themegrid,
    themeedit,
    layoutsgrid,
    projects5,
    layoutsdata,
    categoryshow,
    allversion,
    deploymentdata,
    component,
});
export default reducer;
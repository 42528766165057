import { createAsyncThunk } from "@reduxjs/toolkit";
import layoutService from "../../services/layoutService";
import {ReactComponent as Eye} from "../../images/eye.svg";
import {ReactComponent as Edit} from "../../images/edit.svg";
import {ReactComponent as Delete} from "../../images/delete.svg";
import {ReactComponent as EyeSlash} from "../../images/SlashEye.svg";
import {Link} from "react-router-dom";

export const fetchLayoutDataOld = createAsyncThunk("layoutstore/fetchLayoutData", async(data) => {
    const fetchData = async () => {
        return layoutService.getLayoutData(data.data, data.instance) 
    }

    try{
        const rawData = await fetchData();

        const layoutData = [];

        for (const layout of rawData) {
            const cardData = {
                id: layout?._id,
                title: layout?.title,
                description: layout?.description,
                icon: layout?.iconpath,
                themeid: layout?.themeid,
                isExpand:layout?.isExpand,
                projecttypeid:layout?.projecttypeid
            };
            layoutData.push(cardData);
        }

        //
        return {
            layoutdata: layoutData?.length !==0 ? layoutData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );
  export const fetchLayoutData = createAsyncThunk("layoutstore/fetchLayoutData", async(data) => {
    const fetchData = async () => {
        return layoutService.getLayoutsGridData("?="+data.data, data.instance) 
    }

    try{
        const rawData = await fetchData();

        const layoutData = [];
        for (const layout of rawData) {
            const cardData = {
                id: layout?.layoutid?._id,
                title: layout?.layoutname,
                description: layout?.layoutid?.description,
                icon: layout?.layoutid?.iconpath,
                themeid: layout?.themeid,
                isExpand:layout?.layoutid?.isExpand,
                projecttypeid:layout?.projecttypeid,
                isactive:layout?.isactive


              
            };
            layoutData.push(cardData);
        }

        return {
            layoutdata: layoutData?.length !==0 ? layoutData?.filter(x=>x.isactive===true) : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );

  const getActionsColumn =(data) => {
    
   // 
      return {
        field: 'Actions',
        headerName: 'Actions',
        hideable: false,
        sortable: false,
        filterable: false,
        width: 200,
        renderCell: (n) => {
           const id = n.row.id;
           const isactive=n.row.isactive
           let eyeicon=<Eye/>
           let packlayoutdata
           if(isactive=== true)
      eyeicon = <Eye  />
      else
      eyeicon = <EyeSlash id="eyeOff" />
           //
          return (
             <>      
                <Link className="navcustomlink" to={"/layout/editlayout?id="+id}>     
                   <div
                 size="large"
                className="me-4 cursor-pointer"
               >           
               <Edit />            </div>
               </Link>          
                
            
            <div  onClick={(ev)=>{
              {isactive===true?
                packlayoutdata={
                 "_id":id,
                 "isactive":false
               }:packlayoutdata={
                 "_id":id,
                 "isactive":true
               }}
              
                data.dispatch(putPackageLayoutData({data:packlayoutdata,instance:data.instance})).then(()=>{
                  data.dispatch(fetchLayoutsGridData(data))
                  
                })
              
              ev.stopPropagation();
              ev.preventDefault();
            }}
              size="large"
              className="cursor-pointer"
              aria-disabled="true"
            >
              
             {eyeicon}
            </div>
            </>      
              )
        }
      }
    }
  

  //fetching grid data
export const fetchLayoutsGridData = createAsyncThunk("themestore/fetchLayoutsGridData ", async(data) => {
    
    let id=data.dispatch.name
     if(data.search!==undefined && data.search.includes("?id=") ){
     id=data.search
     }
    // if(data.dispatch.name.includes("?projecttype")){
    //   id1=data.dispatch.name
    //  }
     
    //
   const fetchData = async () => {
       return layoutService.getLayoutsGridData(id,data.instance)
   }
  try{
   const columnsdata = [
     //  {field: "iconpath", headerName: "Icon",flex:1,minwidth: 200, renderCell: (param) => {return (<img src={param.value}/>)}},
     {field: "layoutname",  headerName: "Layout Name",flex:1,minWidth:200},
     {field: "projecttypename", headerName: "Project Type",flex:1, minwidth: 200},
     {field: "projectversion", headerName: "Project Version",flex:1,width: 175},
   //   {field: "landmark", headerName: "Landmark",flex:1,width: 200},
   //   {field: "isafter", headerName: "After ?",flex:1,width: 120},
     ];
       const rawData = await fetchData();
      const gridLayouts = [];
      for (const nfrtype of rawData) {
           const cardData = {
               id: nfrtype._id,
               layoutname:nfrtype?.layoutname,
               themename:nfrtype?.themename,
               themeid:nfrtype?.themeid,
               projecttypename: nfrtype?.projecttypename,
               projecttypeid: nfrtype?.projecttypeid,
               projectversion: nfrtype?.projectversion,
              description: nfrtype?.layoutid.description,
              iconpath:nfrtype?.layoutid.iconpath,
              layoutid:nfrtype?.layoutid._id,
               isexpand:nfrtype?.layoutid.isExpand,
               isactive:nfrtype?.isactive,
           };
           gridLayouts.push(cardData);
       }
      //
       const actionsColumn = getActionsColumn(data);
     columnsdata.push(actionsColumn);
     return {
       layoutsgrid: gridLayouts?.length !==0 ? gridLayouts : [],
       loading: false,
       layoutsgridColumnData:columnsdata,
     };
   }
   catch(err) {
       //
   }
}
 );
 export const fetchEditLayoutsData = createAsyncThunk("layoutstore/fetchEditLayoutData", async(data) => {
  //
   
  let newId = data?.search?.replace("?id=","")
  //
  
   const fetchData = async () => {
    if(newId!==null){
      return layoutService.getEditLayoutData(newId,data?.instance)
      }
   }
   try{
       const rawData = await fetchData();

       const layoutsData = [];

       for (const nfrtype of rawData) {
           const cardData = {
            _id: nfrtype?._id,
            themename:nfrtype?.component.themename,
            layoutname:nfrtype?.component.layoutname,
            projecttypename: nfrtype?.component.projecttypename,
            projectversionid:nfrtype?.component?.projectversionid,
            projectversion: nfrtype?.projectversion,
            projecttypeid:nfrtype?.component?.projecttypeid,
            typename:nfrtype?.typename,
            typeid:nfrtype?.typeid,
            packagecomponentid: nfrtype?.packagecomponentid,
           //  categoryname: nfrtype?.component?.categoryname,
             filename: nfrtype?.filename,
             landmark:nfrtype?.landmark,
             isafter:nfrtype?.isafter,
             isreplace:nfrtype?.isreplace,
             filepath:nfrtype?.filepath,
             codesnippet:nfrtype?.codesnippet,
           };
           layoutsData.push(cardData);
       }

       //
       return {
           layoutsdata: layoutsData?.length !==0 ? layoutsData : [],
           loading: false,
       };
      
   }
   catch(err) {
       //
   }

 }
 );

 //post layout data
 export const postLayoutData = createAsyncThunk("layoutstore/postLayoutData", async (newpackage) => {
    
const postData = async () => {
return layoutService.postLayoutData(newpackage.data,newpackage.instance) 
}

try {
const rawData = await postData();
//
return rawData;

} catch (err) {
//
}
}
);

//post layoutpackage data
export const postPackageLayoutData = createAsyncThunk("layoutstore/postPackageLayoutData", async (newpackage) => {
    
const postData = async () => {
return layoutService.postPackageLayoutData(newpackage.data,newpackage.instance) 
}

try {
const rawData = await postData();
//
return rawData;

} catch (err) {
//
}
}
);

//put themepackage data
export const putLayoutData = createAsyncThunk("layoutstore/putLayoutData", async (newpackage) => {
    
  const putData = async () => {
  return layoutService.putLayoutData(newpackage.data,newpackage.instance) 
  }
  
  try {
  const rawData = await putData();
  //
  return rawData;
  
  } catch (err) {
  //
  }
  }
  );
  //put putPackageThemeData data
  export const putPackageLayoutData = createAsyncThunk("layoutstore/putPackageLayoutData", async (newpackage) => {
    
  const putData = async () => {
  return layoutService.putPackageLayoutData(newpackage.data,newpackage.instance) 
  }
  
  try {
  const rawData = await putData();
  //
  return rawData;
  
  } catch (err) {
  //
  }
  }
  );
  //put putPackageCompThemeData data
  export const putPackageCompLayoutData = createAsyncThunk("layoutstore/putPackageCompThemeData", async (newpackage) => {
    
  const putData = async () => {
  return layoutService.putPackageCompLayoutData(newpackage.data,newpackage.instance) 
  }
  
  try {
  const rawData = await putData();
  //
  return rawData;
  
  } catch (err) {
  //
  }
  }
  );
  
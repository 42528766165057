import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });
const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });
  
class ProductTypeService extends RequestHandler {
    
    //fetch all product type data
    async getProductTypeData(data, instance) {
        // 
        // 
        const result = await this.requestHandler(instance).get("/project-type"+data);
        // 
        return result.data
    }
    async getProjectRepoData(instance) {
        // 
        const res = await this.requestHandler(instance).get("/targetrepo");
        //
        return res.data

    }
    async getProjectVersionData(data,instance) {
        
        //var res
        const res = await client1.get("/project-version?projecttype="+data);

        // if(data===""||data===undefined||data==="undefined")
        // {
        //  res = await this.requestHandler(instance).get("/project-version");
        // }
        // else
        // {
        //  res = await this.requestHandler(instance).get("/project-version?projecttype="+data);
       
        // }
        //
        return res.data

    }
    async getAllVersions(instance) {
        
        const res = await this.requestHandler(instance).get("/project-version");
        //
        return res.data

    }
}

export default new ProductTypeService;
import { createAsyncThunk } from "@reduxjs/toolkit";
import DropAreaService from "../../services/dropAreaService";

export const fetchDropAreaData = createAsyncThunk("inputcntlstore/fetchinputcntlData", async(data) => {
    const fetchData = async () => {
        return DropAreaService.getInputControls(data) 
    }

    try{
        const rawData = await fetchData();

        const inputcntlData = [];

        for (const inputcntl of rawData) {
            const cardData = {
                id: inputcntl?._id,
                icon: inputcntl?.icon,
                name: inputcntl?.name,
                controltypeid: inputcntl?.controltypeid,
                fixedHeight: inputcntl?.fixedHeight,
            };
            inputcntlData.push(cardData);
        }

        //
        return {
            dropAreaData: inputcntlData?.length !==0 ? inputcntlData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );
  export const fetchDetailDropAreaData = createAsyncThunk("inputcntlstore/fetchDetailDropAreaData", async(projecttypeid, themeid) => {
    const fetchData = async () => {
        return DropAreaService.getInputControlsDetials(projecttypeid,themeid) 
    }

    try{
        const rawData = await fetchData();

        const inputcntlData = [];

        for (const inputcntl of rawData) {
            const cardData = {
                id: inputcntl?._id,
                // icon: inputcntl.icon,
                // name: inputcntl.name,
                // controltypeid: inputcntl.controltypeid,
                // fixedHeight: inputcntl.fixedHeight,
                control:inputcntl?.Control,
                codesnippet: inputcntl?.codesnippet
            };
            inputcntlData.push(cardData);
        }

        //
        return {
            dropAreaDataDetails: inputcntlData?.length !==0 ? inputcntlData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );

export const fetchAllDataSource = createAsyncThunk("inputcntlstore/fetchAllDataSource", async(data) => {
    const fetchData = async () => {
        return DropAreaService.getalldatasource(data.data, data.instance) 
    }

    try{
        const rawData = await fetchData();

        const datasource = [];

        for (const data of rawData) {
            const cardData = {
                id: data?._id,
                url: data?.url,
                httpverb: data?.httpverb,
                useremail: data?.useremail,
                datasourcename:data?.datasourcename,
                index:data?.index,
                httpobject: data?.httpobject
                
            };
            datasource.push(cardData);
        }

        //
        return {
            datasource: datasource?.length !==0 ? datasource : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );


//Post for postDataSource
export const postDataSource = createAsyncThunk("userprojectstore/postDataSource", async (userProjData) => {
    //    
    const postData = async () => {
      return DropAreaService.postDataSource(userProjData.data, userProjData.instance)
    }
    try {
      const rawData = await postData();
      //
      return rawData;
    } catch (err) {
      //
    }
  }
  );
import { BlobServiceClient } from "@azure/storage-blob";
import { useState } from "react";

const blobSasUrl = process.env.REACT_APP_STORAGE_ACCOUNT;
export const containerName = process.env.REACT_APP_BLOB_CONTAINER;
export const cdn_path = process.env.REACT_APP_CDN_PATH;

// Call Blob Container
export function callMethod() {
  const blobServiceClient = new BlobServiceClient(blobSasUrl);
  const containerClient = blobServiceClient.getContainerClient(containerName);
  return containerClient;
}

// Upload files into blob
export async function uploadFile(file, filename, section, fileType) {
  let blobFileInfo = [];
  let blobIconInfo;
  let getContainer = callMethod();
  let blockBlobClient;
  let blobOptions;
  let uniqueFilename = filename.substring(0, filename.lastIndexOf("."));
  let ext = filename.substring(filename.lastIndexOf("."));
  uniqueFilename =
    uniqueFilename + "_" + Math.random().toString(16).slice(2) + ext;
  blockBlobClient = getContainer.getBlockBlobClient(uniqueFilename);
  blobOptions = { blobHTTPHeaders: { blobContentType: fileType } };
  let getFilePath = `${cdn_path}/${containerName}/${uniqueFilename}`;
  let name = blockBlobClient._name;
  let respons = await blockBlobClient.uploadBrowserData(file, blobOptions);
  if (section === "infographics") {
    blobFileInfo.push({
      imageURL: getFilePath,
      name: name,
      tempID: "",
      fileType: fileType.substr(0, fileType.lastIndexOf("/")),
    });
    return blobFileInfo;
  } else {
    blobIconInfo = {
      filePath: getFilePath,
      fileName: name,
    };
    return blobIconInfo;
  }
}

export async function validateFile(
  file,
  acceptFileType,
  resolution = {},
  acceptFileSize,
  errorMsg
) {
  let tempImgUrl = URL.createObjectURL(file);
  let fileExtn =
    errorMsg === "file"
      ? file.name.substring(file.name.lastIndexOf(".") + 1)
      : file.type.split("/")[1];
  let fileSize = file.size / (1024 * 1024);
  let typeOfFile = file.type.split("/")[0];
  const checkImage = (tempImgUrl, typeOfFile) => {
    if (Object.keys(resolution).length > 0 && typeOfFile === "image") {
      return new Promise((resolve) => {
        const img = document.getElementById(resolution.tempDOMID);
        img.onload = () => resolve({ tempImgUrl, status: "ok", img });
        img.onerror = () => resolve({ tempImgUrl, status: "error" });
        img.src = tempImgUrl;
      });
    } else {
      return Promise.resolve();
    }
  };
  return checkImage(tempImgUrl, typeOfFile)
    .then((res) => {
        let obj = {validImage:true};
      if (!acceptFileType.includes(fileExtn)) {
        //alert(`Upload valid ${errorMsg}`);
        res?.img.removeAttribute("src");
        return obj =  {
            validImage:false,
            message:`Upload valid ${errorMsg}`
        };
      } else if (fileSize > acceptFileSize) {
        //alert(`Maximum size ${acceptFileSize}mb`);
        res?.img.removeAttribute("src");
        return obj =  {
            validImage:false,
            message:`Maximum size ${acceptFileSize}mb`
        };      
    }
      if (
        Object.keys(resolution).length > 0 &&
        resolution.validate &&
        res?.img
      ) {
        let sizeCheck =
          resolution.condition === "lesser"
            ? res.img.height < resolution.height ||
              res.img.width < resolution.width
            : res.img.height > resolution.height ||
              res.img.width > resolution.width;
        if (sizeCheck) {
          //alert(`Upload image resolution with ${resolution.height}x${resolution.width}`);
          res.img.removeAttribute("src");
          return obj = {validImage:false,
            message: `Upload image resolution with ${resolution.height}x${resolution.width}`
        };
        }
      }
      return obj;
    })
    .catch((error) => {
      //
    });
}

export const deleteAction = async (fileName, indexNode) => {
  
  let getContainer = callMethod();
  try {
    if (fileName !== "") {
      const options = {
        deleteSnapshots: "include", // or 'only'
      };
      const blockBlobClient = await getContainer.getBlockBlobClient(fileName);
      await blockBlobClient.delete(options);
      return true;
    } else {
      alert("No files selected.");
      return false;
    }
  } catch (error) {
    //
    return false;
  }
};

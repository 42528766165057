import React from 'react'
import { fetchProductTypeData, fetchProjectVersionData } from "../../store/productType.actions";
import { fetchNfrTypeData, fetchPackagesTypeGridData } from "../../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { fetchEditPackageData, putPackageData, putPackageDetailData } from "../../store/nfr.actions";
import Package from '../../CommanMethods/PackageMethods';

let edit = true;
let edit2 = true;
let edit4 = true;

function EditPackage() {
    let myval = 0
    let success = 0
    var res1 = ""
    let flag = 1

    const { instance, accounts } = useMsal();
    var myindex
    const name = accounts[0] && accounts[0].username;

    const location = useLocation();
    var uid = location.search.replace("?id=", "")



    const [getid, setgetid] = useState();
    const [projectType, setprojectType] = useState();
    const [flag1, setFlag] = useState(false)

    const dispatch = useDispatch();

    let search = location.search;
    var value1 = 0

    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProductTypeData({ data: "", instance: instance }));

            dispatch(fetchPackagesTypeGridData(instance));

            dispatch(fetchEditPackageData(search))
        }
    }, [dispatch, instance.getAllAccounts()[0] !== undefined])

    useEffect(() => {
        if (instance.getAllAccounts()[0] !== undefined) {
            dispatch(fetchProjectVersionData({ data: getid, instance: instance }))
            dispatch(fetchNfrTypeData({ data: getid, instance: instance }))
        }
    }, [projectType, getid, instance.getAllAccounts()[0] !== undefined])

    const editdata1 = useSelector(
        (state) => state.commonReducer.nfr?.editPackages?.packageedit);





    const [inputFields, setInputFields] = useState([
        {
            packagecomponentid: "",
            packagetypename: "Open this select menu",
            packagetypeid: "",
            filename: "",
            filepath: "",
            landmark: "",
            isafter: false,
            codesnippet: "",
            isreplace: false,
            // editdata1
        }
    ])





    const handleSave = async () => {

        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var projecttxt = document.querySelector('#selectProjectType').options[sI].label
        var projectid = document.querySelector('#selectProjectType').options[sI].value


        var vI = document.querySelector('#selectVersion').selectedIndex;
        var versionid = document.querySelector('#selectVersion').options[vI].value
        var versiontxt = document.querySelector('#selectVersion').options[vI].label

        var tI = document.querySelector('#selectType').selectedIndex;
        var typeid = document.querySelector('#selectType').options[tI].value
        var typetxt = document.querySelector('#selectType').options[tI].label


        var cI = document.querySelector('#selectCategory').selectedIndex;
        var categoryid = document.querySelector('#selectCategory').options[cI].value
        var categorytxt = document.querySelector('#selectCategory').options[cI].label

        try {
            let packageData = {

                "_id": editdata1[0]?.packagecomponentid,


                "createdby": name,

                "projecttypeid": projectid,

                "projecttypename": projecttxt,

                "projectversionid": versionid,

                "projectversion": versiontxt,

                "categoryid": categoryid,

                "categoryname": categorytxt



            }

            let str = []

            str = inputFields.map((data) => {
                return {
                    "_id": uid,
                    typename: data.packagetypename,
                    typeid: data.packagetypeid,
                    filename: data.filename,
                    filepath: data.filepath,
                    landmark: data.landmark,
                    isafter: data.isafter === false && data.isreplace === true ? null : data.isafter,
                    codesnippet: data.codesnippet,
                    isreplace: data.isreplace,
                };
            }

            )

            let packageDetailData1 = {
                "_id": uid,
                typename: inputFields[0].packagetypename,
                typeid: inputFields[0].packagetypeid,
                filename: inputFields[0].filename,
                filepath: inputFields[0].filepath,
                landmark: inputFields[0].landmark,
                isafter: inputFields[0].isafter === false && inputFields[0].isreplace === true ? null : inputFields[0].isafter,
                codesnippet: inputFields[0].codesnippet,
                isreplace: inputFields[0].isreplace,
            };



            if (inputFields[0].filename === "") {
                value1 = 1
                alert("File name is mandatory")
            }
            else if (inputFields[0].filepath === "") {
                value1 = 1
                alert("File path is mandatory")
            }
            else if (inputFields[0].codesnippet === "") {
                value1 = 1
                alert("Code snippet is mandatory")
            }
            else if (inputFields[0].packagetypename === "") {
                value1 = 1
                alert("Select any package type")
            }
            else {
                value1 = 0
            }


            if (value1 === 0) {
                if (instance.getAllAccounts()[0] !== undefined) {
                    dispatch(putPackageData({ data: packageData, instance: instance })).then((res) => {



                        if (res.payload)
                            success = 1
                        else
                            alert("Form not submitted")


                        if (value1 === 0) {

                            dispatch(putPackageDetailData({ data: packageDetailData1, instance: instance })).then((res) => {
                                //
                                if (success == 1) {
                                    if (res.payload) {
                                        alert("Package edited successfully")
                                        window.location = "/packages"
                                    }
                                    else {
                                        alert("Package edited successfully")
                                        window.location = "/packages"
                                    }
                                    //alert("Form not submitted1")
                                }
                                else
                                    alert("Form not submitted2")

                            })
                        }


                    })
                }
            }


        } catch (err) {

        }




    }

    //NFR
    const nfrcontent = useSelector(
        (state) => state.commonReducer.nfr.nfrTypeData.nfrdata);
    const [nfrdata, setnfrData] = useState(nfrcontent);
    useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);


    const [value, setValue] = useState(1);


    const PackageDiv = <div class="col-lg-4 text-start">
        <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Project Category</label>
            <div class="select_box">
                <select id="selectCategory" class="form-select outlined" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    {value == 1 ? nfrdata?.map((nfrdata, index) => (
                        <option selected={editdata1?.[0]?.categoryid === nfrdata?.id} value={nfrdata?.id} id="categoryid">{nfrdata?.title}</option>
                    )) : <></>}
                </select>
            </div>
        </div>
    </div>

    return (
        <>
            <div class="screen_two">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>
                        Edit Package</p>
                </div>
                <Package handleSave={handleSave} inputFields={inputFields} setInputFields={setInputFields} editdata1={editdata1}
                    value={value} setValue={setValue} customDiv={PackageDiv} cancelLoc={"/packages"} />
            </div>




        </>



    )
}
export default EditPackage

import React, { memo, useEffect, useState } from 'react';
import { X } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import Nav from 'react-bootstrap/Nav';
import { ReactComponent as Cloud_icon } from './images/cloud_upload_icon.svg';
import { ReactComponent as File_menu } from './images/file_menu.svg';
import { ReactComponent as Api_menu } from './images/api_menu.svg';
import { ReactComponent as Sourcecode_menu } from './images/sourcedode_menu.svg';
import { ReactComponent as Message_menu } from './images/message_menu.svg';
import { ReactComponent as Search_icon } from './images/search_icon.svg';
import { ReactComponent as File_small_icon } from './images/file_small_icon.svg';
import { ReactComponent as Three_dots } from './images/three_dots.svg';
import { ReactComponent as Home_menu } from './images/home_icon.svg';
import { ReactComponent as Plus_menu } from './images/plus_menu.svg';
import { ReactComponent as Project_mainmenu } from './images/Project_iconn.svg';
import { ReactComponent as Project_menu } from './images/projects_icon.svg';
import { ReactComponent as Hamburger_menu } from './images/hamburger.svg';
import { ReactComponent as Components } from './images/Component.svg';
import { Search, Plus } from 'react-bootstrap-icons';
import { ProSidebarProvider } from 'react-pro-sidebar';
import { Sidebar, Menu, MenuItem, SubMenu } from 'react-pro-sidebar';
import { useDispatch, useSelector } from "react-redux";
import { fetchProductTypeData ,fetchProjectRepoData} from "./main/store/productType.actions";
import { fetchThemeData } from "./main/store/theme.actions";
import { fetchLayoutData } from "./main/store/layout.actions"
import PackageGrid from './PackageGrid';
import { postUserProjectData , putUserProjectData, getAllProjectsGridData1, getAllProjectsNames, getProjectDetails} from "./main/store/userProject.actions"
import DropAreaScreen from "./components/DropAreaScreen";
import { ReactComponent as Mobile_menu } from './images/mobile_icon.svg';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import {useLocation, useNavigate} from "react-router-dom";
import Tooltip from "@material-ui/core/Tooltip";
import { NavLink, Link ,Outlet} from 'react-router-dom';

// import {useLocation, useNavigate} from "react-router-dom";


function ContentContainer(openToggle,setOpenToggle, sidebarhide, setSidebarhide, isActive, setActive) {
    
    const dispatch = useDispatch();
    const {instance, accounts, inProgress} = useMsal();
    const name = accounts[0] && accounts[0].username;
    const [select, setSelected] = useState(isActive);
    const location = useLocation();
    var  pid= location.search.includes("?_id")?location.search.replace("?_id=",""):"";
   
    useEffect(() => {
        // [0])
    if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchProductTypeData({data: "", instance: instance}))
        dispatch(fetchProjectRepoData(instance))      
        dispatch(fetchThemeData({data: "", instance: instance}))
        dispatch(fetchLayoutData({data: "", instance: instance}))
        dispatch(getAllProjectsNames(instance))
        dispatch(getAllProjectsGridData1({name:name,instance:instance,"dispatch":dispatch}))
        dispatch(getProjectDetails({data:pid,instance:instance}))
        }
    }, [dispatch, instance.getAllAccounts()[0] !== undefined,pid])


    useEffect(() => {
        setSelected(isActive);
    }, [isActive])

    useEffect(()=>{
        
        setHideHamburger(localStorage.getItem("toggle"))
        //)
    },[localStorage.getItem("toggle")])
    //)
    var userprojid = localStorage.getItem("userprojectid");
   // 

    
    // offcanvas sidebar
    
    const[hideHamburger,setHideHamburger] = useState()
   
   

    const ProjectData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails);
    const [prodtypedata, setContentData] = useState(ProjectData);
    useEffect(() => { setContentData(ProjectData) }, [dispatch, ProjectData])

    const allProjectsNames = useSelector((state) => state.commonReducer.allProjectsNames?.AllProjectsNames?.allProjectsNames);
    const [allProjectsNames1, setallProjectsNames1] = useState(allProjectsNames);
    useEffect(() => { setallProjectsNames1(allProjectsNames) }, [dispatch, allProjectsNames])
    //

    //)[0]?.ProjectType)




//Visibility of left menu
let internalmenuvisibility = <span></span>
if ( prodtypedata?.[0]?.ProjectType === 'PWA React' || 
    prodtypedata?.[0]?.ProjectType === 'PWA .NET' || 
    prodtypedata?.[0]?.ProjectType === 'Angular') {
    internalmenuvisibility = (<>
    <li className={openToggle.isActive!=="studio"?'nav-link':'nav-link active'} id="studio" 
        onClick={()=>{
            openToggle.setActive("studio");
            openToggle.setOpenToggle(false);
            let getEl = document.querySelectorAll('.gjs-pn-views, .gjs-pn-views-container, .gjs-cv-canvas, .gjs-pn-commands')
            getEl[2].style.display = 'block'; 
            getEl[3].style.display = 'block'; 
            getEl[0].style.width = '85%'; 
            getEl[1].style.width = '85%'; 
        }}>
    <span>
        <Link className='navcustomlink' to={`/webStudio?_id=${localStorage.getItem("userprojectid")}`}>
        <Tooltip title="Web Studio">
            <Project_mainmenu className='react_icon_small' />
        </Tooltip>
        </Link>
        </span>
    </li>
    {/* <li className={openToggle.isActive!=="NewScreen"?'nav-link':'nav-link active'} id="NewScreen" 
        onClick={()=>{
            openToggle.setActive("NewScreen"); 
            openToggle.setOpenToggle(true); 
            openToggle.setSidebarhide('two');
            let getEl = document.querySelectorAll('.gjs-pn-views, .gjs-pn-views-container, .gjs-cv-canvas, .gjs-pn-commands')
            getEl[2].style.display = 'none'; 
            getEl[3].style.display = 'none'; 
            getEl[0].style.width = '100%'; 
            getEl[1].style.width = '100%'; 
        }}>
        <div class="inner_menus_show"><File_menu className='react_icon_small_fill' /></div>            
    </li> */}
    <li className={openToggle.isActive!=="dataSource"?'nav-link':'nav-link active'} id="dataSource" 
        onClick={()=>{
            openToggle.setActive("dataSource"); 
            openToggle.setOpenToggle(true); 
            openToggle.setSidebarhide('three');
            let getEl = document?.querySelectorAll('.gjs-pn-views, .gjs-pn-views-container, .gjs-cv-canvas, .gjs-pn-commands')
            getEl[2].style.display = 'none'; 
            getEl[3].style.display = 'none';
            getEl[0].style.width = '100%'; 
            getEl[1].style.width = '100%';              
        }}>
        <Tooltip title="Data Source">
        <div class="inner_menus_show"><Api_menu className='react_icon_small_fill' /></div>
        </Tooltip>
    </li>
    </>)
    
}
else if (prodtypedata?.[0]?.ProjectType === 'IOS App' ||
        prodtypedata?.[0]?.ProjectType === 'Android App' || prodtypedata?.[0]?.ProjectType === 'React-Native-Expo') {
            internalmenuvisibility = ( <>
                <li className={openToggle.isActive!=="Mstudio"?'nav-link':'nav-link active'} id="Mstudio" onClick={()=>{openToggle.setActive("Mstudio")}}>
                <span>
                <Link className='navcustomlink' to={`/mobileStudio?_id=${localStorage.getItem("userprojectid")}`}>
                <Tooltip title="Mobile Studio">
                <Mobile_menu className='react_icon_small' />
                </Tooltip>
                </Link>
                </span>
                </li>
            </>)
         }
else if (prodtypedata?.[0]?.ProjectType === 'AI Bot Node.js' ||
         prodtypedata?.[0]?.ProjectType === 'AI Bot .NET') {
            internalmenuvisibility = (<>
                <li className={openToggle.isActive!=="message"?'nav-link':'nav-link active'} id="message" onClick={()=>{openToggle.setActive("message")}}>
                <span>
                {/* <Link className='navcustomlink' to={`/botStudio?_id=${localStorage.getItem("userprojectid")}`}> */}
                <Link className='navcustomlink' to="/botStudio">
                <Tooltip title="Bot Studio">
                <Message_menu className='react_icon_small' />
                </Tooltip>
                </Link>
                </span>
                </li>
            </>)
         }
let menuvisibility = <span></span>
if(location.pathname !== "/" && location.pathname !== "/allApps"){

    menuvisibility = (
    <>
    {internalmenuvisibility}
    <li className={openToggle.isActive!=="source"?'nav-link':'nav-link active'} id="source" onClick={()=>{openToggle.setActive("source")}}>
        <span>
        <Link className='navcustomlink'  to={prodtypedata?.[0]?.projecttypeid?._id==="667694ba9963e60ab8d43860"?`/explorerEP?_id=${localStorage.getItem("userprojectid")}`:`/explorer?_id=${localStorage.getItem("userprojectid")}`}>
        <Tooltip title="Explorer">
            <Sourcecode_menu className='react_icon_small_fill' />
        </Tooltip>
        </Link>
        </span>
    </li>
    </>
    )
}


//Handle Theme of page
const handleWhiteTheme = event => {
    if(document.body.classList.contains('white_theme'))
    {
        //no operations
    }
    else
    document.body.classList.add('white_theme');
    //
  };
  const handleDarkTheme = event => { 
    if(document.body.classList.contains('white_theme')) {
    document.body.classList.remove('white_theme');
    //
    }
    else 
    {
        //no operations
    }
  };


    return (
        <>
            <div class="maincontentContainer">
                <div class="d-flex h-100">
                    <div className='sidebar_main d-flex'>
                        <div class="mainSidemenu">
                            <ul>                                
                               
                                {openToggle.isActive !== 'studio' ? 
                                <li className="nav-link" hidden={openToggle.openToggle}>
                                    <span>
                                    <Tooltip title="Hamburger">
                                        <Hamburger_menu className='react_icon_small' onClick={()=>{openToggle.setOpenToggle(true);}} />
                                    </Tooltip>
                                    </span>
                                </li> : ''}
                                <li className={openToggle.isActive!=="home"?'nav-link':'nav-link active'} id="home" onClick={()=>{openToggle.setActive("home")}}>
                                    <span>
                                    <Link className='navcustomlink' to="/">
                                    <Tooltip title="Home">
                                        <Home_menu className='react_icon_small' />
                                    </Tooltip>
                                    </Link>
                                    </span>
                                </li>
                                <li className={openToggle.isActive!=="project"?'nav-link':'nav-link active'} id="project" onClick={()=>{openToggle.setActive("project")}}>
                                    <span>
                                    <Link className='navcustomlink' to="/allApps">
                                    <Tooltip title="Recent Apps">
                                        <Project_menu className='react_icon_small_fill' />
                                    </Tooltip>
                                    </Link>
                                    </span>
                                </li>
                                {menuvisibility}
                                <li className={openToggle.isActive!=="component"?'nav-link':'nav-link active'} id="component" onClick={()=>{openToggle.setActive("component")}}>
                                    <span>
                                    <Link className='navcustomlink' to="/component">
                                    <Tooltip title="Component">
                                        <Components className='react_icon_small' />
                                    </Tooltip>
                                    </Link>
                                    </span>
                                </li>                            
                            </ul>
                            <div class="theme_list d-flex">
                                <ul>
                                    <li class="dots_white" onClick={handleWhiteTheme}></li>
                                    <li class="dots_black" onClick={handleDarkTheme}></li>
                                </ul>
                            </div>
                        </div>                        
                    </div>
                    <div class="flex-fill contentmain_container">
                        <Outlet />
                    </div>
                </div>
            </div>
        </>
    );
}
export default ContentContainer;
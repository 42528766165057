import { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import { ReactComponent as Home_logo } from './images/home_logo.svg';
import { FaRegUserCircle } from 'react-icons/fa';
import { ReactComponent as Bell_icon } from './images/bell_icon.svg';
import { ReactComponent as User_icon } from './images/user_icon_static.svg'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link, Outlet } from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { loginRequest } from "../authConfig";
import Axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Person } from "react-bootstrap-icons";
import { ReactComponent as Settings_icon } from './images/settings_icon.svg';
import { ReactComponent as Info_icon } from './images/info_icon.svg';
import {useLocation} from "react-router-dom";
import Modal from 'react-bootstrap/Modal';
import Myapps from '../../src/App/images/myapps.png';
import Dotnett_icon from './images/dotnett_icon.png';
import Bootstrap_icon from './images/bootstrap_icon.png';
import Layout_icon from './images/layout_icon.png';
import { getProjectDetails } from "./main/store/userProject.actions";
import { fetchNfrTypeData } from "./main/store/nfr.actions";
export const theme = [
  { color: '#D50000', backgroundColor: '#FFEBEE' },
  { color: '#C51162', backgroundColor: '#FCE4EC' },
  { color: '#AA00FF', backgroundColor: '#F3E5F5' },
  { color: '#6200EA', backgroundColor: '#EDE7F6' },
  { color: '#304FFE', backgroundColor: '#E8EAF6' },
  { color: '#2962FF', backgroundColor: '#E3F2FD' },
  { color: '#0091EA', backgroundColor: '#E1F5FE' },
  { color: '#00B8D4', backgroundColor: '#E0F7FA' },
  { color: '#00BFA5', backgroundColor: '#E0F2F1' },
  { color: '#FFAB00', backgroundColor: '#FFF8E1' },
]

function Navbarmain() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isActive, setActive] = useState("");
  const [imageUrl, setImageUrl] = useState(null)

  // Azure B2C

  const { instance, accounts, inProgress } = useMsal();
  const isAuthAuthenticated = useIsAuthenticated();
  const [accessToken, setAccessToken] = useState(null);
  const name = accounts[0] && accounts[0].name;
  const userFirstName = name && name.charAt(0);
  const userLastName = name && name.substring(name.lastIndexOf(' ') + 1).charAt(0);
  const randomColor = name && name.split(' ').join('').length % 10;
  const useremail = accounts[0] && accounts[0].username;
  const homeAccountID = accounts[0] && accounts[0].homeAccountId;
  const userDomain = useremail?.substring(useremail.indexOf("@") + 1, useremail.indexOf(".com"))
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);


  
  if(useremail!==undefined&&!useremail?.endsWith("@dentel.ai") )
  {
    handleLogout(instance)
  }

  useEffect(() => {
    if (!isAuthAuthenticated) {
      const request = {
        scopes: loginRequest.scopes,
        account: useremail,
      };
    }
  }, [])

  useEffect(() => {
    if (accounts.length > 0) {
      RequestAccessToken()
        .then((res) => {
          setAccessToken(res);
        })
        .catch(err => {
          //
        })
    }
    if (accessToken !== null) {
      let api = process.env.REACT_APP_BASE_URL + "/auth/microsoft/callback?access_token=" + accessToken.replace(/^Bearer\s/, '')
      Axios.get(api).then(o => {
        localStorage.setItem("apiJWTToken", o.data.jwt)
      })

      Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
        headers: { 'Authorization': accessToken },
        responseType: 'blob',
      }).then(o => {
        const blobUrl = URL.createObjectURL(o.data);
        setImageUrl(blobUrl)
      })
    }
  }, [accessToken, accounts])


  localStorage.setItem("userDomain", userDomain);


  //
  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
      console.error("checkerror",e);
    })
  }

  function handleLogout(instance) {
    localStorage.setItem("apiJWTToken", "")
    localStorage.setItem('myprofilepopup', "")
    localStorage.setItem('myProfileLoggedInID', "")
    const currentAccount = instance.getAccountByHomeId(homeAccountID);
    const logoutHint = currentAccount.idTokenClaims.login_hint;
    instance.logoutRedirect({ logoutHint: logoutHint }).catch(e => {
    //  console.error(e);
    });
  }

  async function RequestAccessToken() {
    const request = {
      scopes: loginRequest.scopes,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    const accessTokenResponse = await instance.acquireTokenSilent(request);
    return `Bearer ${accessTokenResponse.accessToken}`;
  }

  var id=location.search.replace("?_id=","")
  useEffect(() => { 
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch(getProjectDetails({data:id,instance:instance}))
    }
  }, [dispatch,instance.getAllAccounts()[0] !== undefined])
  
  let infoicon = <></>;
  if( location.pathname === "/webStudio" ||
      location.pathname === "/explorer"
    )
  {
    infoicon  =  (<>
    <Info_icon onClick={() =>{
      handleShow();
      if(instance.getAllAccounts()[0] !== undefined){
      dispatch(getProjectDetails({data:id,instance:instance}))
      }
      }}
       className='react_icon_size_fill me-3' />
    </>)
  }

  var type="";
  
  useEffect(() => { 
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch(getProjectDetails({data:id,instance:instance}))

    }
  }, [id,instance.getAllAccounts()[0] !== undefined])
  
  
  //fetching project types
  const ProjectData = useSelector((state) => state.commonReducer.projectdetails.ProjectDetails.projectdetails);
  const [prodtypedata, setContentData] = useState(ProjectData);
  useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);
  //
  
  //setType(prodtypedata[0]?.ProjectType)
  type=prodtypedata[0]?.projecttypeid
  
  
 // 
  // useEffect(() => { 
  //   if(instance.getAllAccounts()[0] !== undefined)
  //   {
  //   dispatch(fetchNfrTypeData({data:type,instance,instance}))
  //   }
  // }, [type,instance.getAllAccounts()[0] !== undefined])
  // //NFR
  // const nfrcontent = useSelector((state) => state.commonReducer.nfr.nfrTypeData.nfrdata);
  // const [nfrdata, setnfrData] = useState(nfrcontent);
  // useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);
 // 
  
 let projectname = <></>
 if (prodtypedata[0]?.projectname !== undefined) {
  projectname = ( 
    <>
    <h1 className='nav-link'>{`Developer Studio - ${prodtypedata[0]?.projectname}`}</h1>
    </>
  )
 }

  return (
    <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
      <Container fluid className="p-0">
        <Navbar.Brand className="d-flex" href="#home"><Home_logo /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='ps-5' id="basic-navbar-nav">
          
          <Nav className="me-auto">
            {projectname}
            {/* <h1 className='nav-link'>{`Developer Studio - ${prodtypedata[0]?.projectname}`}</h1> */}
            {/* <Link className={isActive !== "file" ? 'nav-link' : 'nav-link active'} id="file" to="/" onClick={() => { setActive("file") }}>File</Link>
            <Link className={isActive !== "edit" ? 'nav-link' : 'nav-link active'} id="edit" to="/" onClick={() => { setActive("edit") }}>Edit</Link>
            <Link className={isActive !== "view" ? 'nav-link' : 'nav-link active'} id="view" to="/" onClick={() => { setActive("view") }}>View</Link>
            <Link className={isActive !== "find" ? 'nav-link' : 'nav-link active'} id="find" to="/" onClick={() => { setActive("find") }}>Find</Link>
            <Link className={isActive !== "packages" ? 'nav-link' : 'nav-link active'} to="/packages" id="packages" onClick={() => { setActive("packages") }}>Packages</Link>
            <Link className={isActive !== "help" ? 'nav-link' : 'nav-link active'} id="help" to="/" onClick={() => { setActive("help") }}>Help</Link> */}
          </Nav> 
          <Form className="d-flex ms-auto align-items-center">
          <DropdownButton
                        variant="outline-secondary"
                        title="Page 1"
                        id="input-group-dropdown-4"
                        align="end"
                    >
                        <Dropdown.Item href="#">Page 1</Dropdown.Item>
                        <Dropdown.Item href="#">Page 2</Dropdown.Item>
                        <Dropdown.Item href="#">Page 3</Dropdown.Item>
                    </DropdownButton>
            {/* <Info_icon onClick={handleShow} className='react_icon_size_fill me-3' /> */}
            {infoicon}
            <Bell_icon className='react_icon_size me-3' />
            {name ? (
              <Nav pullRight className="avatardropdown ps-2">
                <NavDropdown className='avatardropdown' align="end" title={
                  <div className="d-flex align-items-center">
                    {imageUrl !== null ?
                      <img
                        className="thumbnail-image smallsize mr-10 rounded-circle"
                        src={imageUrl}
                        alt="user pic"
                      />
                      :
                      <span className="userProfileImage" style={theme[randomColor]}>
                        {userFirstName}{userLastName}</span>
                    }
                    <div class="d-flex flex-column ms-2 text-end justify-content-end">
                      <span class="font_10 font_regular welcometext">
                        Welcome
                      </span>
                      <span class="font_12 font_medium usertxt">
                        {name}
                      </span>
                    </div>
                  </div>
                }
                  id="basic-nav-dropdown">
                  <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    My Profile
                  </NavDropdown.Item>
                  <NavDropdown.Item
                    onClick={() => handleLogout(instance)}
                    eventKey={1.4}
                  >
                    <i className="fa fa-sign-out"></i>{" "}
                    Logout.
                  </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            ) : (
              <Button className="w-85px" onClick={() => handleLogin(instance)} variant="secondary">
                <span>
                  <Person style={{ marginRight: "5px" }} />
                  Login
                </span>
              </Button>
            )}
          </Form>
        </Navbar.Collapse>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='font_18 font_regular' id="contained-modal-title-vcenter">
            Setting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row mb-2">
            <div class="col-12 mb-3">
              <p class="font_14 font_light mb-2">Project Name</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Myapps} /></div>
                <div>{prodtypedata[0]?.projectname}</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-9 mb-3">
              <p class="font_14 font_light mb-2">Description</p>
              <div class="d-flex flex-row align-items-center font_14">
              {prodtypedata[0]?.description}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Project Type</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prodtypedata[0]?.ProjectTypeicon} /></div>
                <div class="font_14 font_regular">{prodtypedata[0]?.ProjectType}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Theme Selection</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prodtypedata[0]?.Themeicon} /></div>
                <div class="font_14 font_regular">{prodtypedata[0]?.Theme}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Layout Selection</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prodtypedata[0]?.Layouticon} /></div>
                <div class="ps-2 font_14 font_regular">{prodtypedata[0]?.Layout}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="font_14 font_light mb-2">Packaged Capabilities</p>
            </div>
            {prodtypedata?.map((nfrdata, index) => (
                  
                  nfrdata?.PackageCategory?.map((data)=>(
            <div class="col-lg-4">
           
              <div class="d-flex flex-row align-items-center">
            
                <div><img class="img-fluid me-2" src={data?.icon} /></div>
                
                      <div class="ps-2 font_14 font_regular">{data?.title}</div>
                    
              </div>
             
            </div>
            ))
                
                
            ))}
            {/* <div class="col-lg-4">
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Layout_icon} /></div>
                <div class="ps-2 font_14 font_regular">Notifications</div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Layout_icon} /></div>
                <div class="ps-2 font_14 font_regular">Error Logging</div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}

export default Navbarmain;
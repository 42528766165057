import { IPublicClientApplication } from '@azure/msal-browser';
import axios, { AxiosInstance } from 'axios';
// import ConfigLoaderService from '../app/services/config-loader.service';
/*eslint import/no-cycle: [2, { maxDepth: 2 }]*/
// import { Services } from 'app/models';
// import store from '../app/store';

export class RequestHandler {
  scope;
  url;
  axiosInstance;

  constructor( ) {
    // this.scope = ["https://rapidx.onmicrosoft.com/rapidx-devstudio-backend/Application.All"];
    this.url = process.env.REACT_APP_BASE_URL1
    const options = {
      baseURL: process.env.REACT_APP_BASE_URL1,
      headers: {
        'Content-type': 'application/json',
      },
    };
    //
    this.axiosInstance = axios.create(options);
  }

  requestHandler(instance) {
    this.axiosInstance.interceptors.request.use(
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      
      async (req) => {
        const accessTokenRequest = {
          scopes: this.scope,
          account: instance.getAllAccounts()[0],
        };
        try {
          //
          const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
          req.headers.Authorization = `Bearer ${accessTokenResponse.accessToken}`;
          //
          // const storeState = store.getState();
          // const stTenantId = storeState.authSlice.user.tenant || storeState.authSlice.tenant._id;
          // req.headers.tenantid = stTenantId;

          // eslint-disable-next-line @typescript-eslint/no-explicit-any
        } catch (error) {
          //
          if (error?.errorMessage?.indexOf('not consented to use the application') !== -1) {
            instance.acquireTokenRedirect(accessTokenRequest).then(() => {
              // instance.handleRedirectPromise(() => {
              //     //
              // });
            });
          }
        }
        return req;
      },
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (err) => {
        return Promise.reject(err);
      }
    );
    return this.axiosInstance;
  }
}

import {combineReducers} from "@reduxjs/toolkit";
import commonReducer from "../main/store";
// import productReducer from "../main/store"

const createReducer = asyncReducers => (state, action) => {
    const combinedReducer = combineReducers({
      commonReducer,
    //   productReducer,
      ...asyncReducers,
    });
  
    /*
      Reset the redux store when user logged out
       */
    if (action.type === "auth/user/userLoggedOut") {
      state = undefined;
    }
  
    return combinedReducer(state, action);
  };
  
  export default createReducer;
import { ReactComponent as Edit_icon } from '../images/edit_icon.svg';
import Offcanvas from 'react-bootstrap/Offcanvas';
function Properties(){
    return (
        <>
            <Offcanvas.Header closeButton>
            <Offcanvas.Title>Properties</Offcanvas.Title>
            </Offcanvas.Header>
            <Offcanvas.Body>
                <div class="d-flex align-items-center">
                    <span className='me-2 font_12'>Button 1</span><Edit_icon className='cursor-pointer' />
                </div>
                <div class="mb-2 mt-3 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Text</label>
                    <div class="col-sm-8">
                        <input type="text" class="form-control outlined" id="" />
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Font</label>
                    <div class="col-sm-8">
                        <div className='select_box'>
                            <select class="form-select outlined" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Font Size</label>
                    <div class="col-sm-8">
                        <div className='select_box'>
                            <select class="form-select outlined" aria-label="Default select example">
                                <option selected>Open this select menu</option>
                                <option value="1">One</option>
                                <option value="2">Two</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                    </div>
                </div>
                <hr className='dropdown-divider my-3'></hr>
                <div class="mb-2 mt-3 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Color</label>
                    <div class="col-sm-8">
                        <input type="color" value="#005AAB" class="form-control outlined colorpallet" id="" />
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Border</label>
                    <div class="col-sm-8 d-flex align-items-center justify-content-between">
                        <div className='select_box small me-1'>
                            <select class="form-select outlined small" aria-label="Default select example">
                                <option selected>_______</option>
                                <option value="-----">-----</option>
                                <option value="......">......</option>
                                <option value="3">Three</option>
                            </select>
                        </div>
                        <input type="number" placeholder='1px' class="form-control outlined small me-1" id="" />
                        <input type="color" value="#C02a2a" class="form-control outlined colorpallet" id="" />
                    </div>
                </div>
                <hr className='dropdown-divider my-3'></hr>
                <div class="mb-2 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Position</label>
                    <div class="col-sm-8 d-flex align-items-center justify-content-between">
                        <div class="input-group outlined small me-2">
                            <span class="input-group-text " id="basic-addon1">X</span>
                            <input type="number" class="form-control outlined small" placeholder="1px" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div class="input-group outlined small">
                            <span class="input-group-text" id="basic-addon1">Y</span>
                            <input type="number" class="form-control outlined small" placeholder="1px" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                </div>
                <div class="mb-2 row">
                    <label for="" class="col-sm-4 font_10 font_light col-form-label">Size</label>
                    <div class="col-sm-8 d-flex align-items-center justify-content-between">
                        <div class="input-group outlined small me-2">
                            <span class="input-group-text " id="basic-addon1">W</span>
                            <input type="number" class="form-control outlined small" placeholder="1px" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                        <div class="input-group outlined small">
                            <span class="input-group-text" id="basic-addon1">H</span>
                            <input type="number" class="form-control outlined small" placeholder="1px" aria-label="Username" aria-describedby="basic-addon1" />
                        </div>
                    </div>
                </div>
            </Offcanvas.Body>
        </>
    )
}

export default Properties;


import { createSlice } from "@reduxjs/toolkit";
import { fetchComponentData } from "./component.actions";

const ComponentData = createSlice({
    name: "componentstore",
    initialState: {
        componentData: { component: [], loading: true},
        currentcomponentTypeData : {
            componenttype: {
                id: "",
                name:"",
            },
        },
        currentcomponentTypeDataCopy: {
            componenttype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditcomponentTypeDataName: (state, actions) => {
            state.currentcomponentTypeData.componenttype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchComponentData.pending]: (state) => {
            state.componentData.loading = false;
        },
        [fetchComponentData.fulfilled]: (state,actions) => {
            state.componentData.component = actions.payload?.component;
            state.componentData.loading = actions.payload?.loading;
        },
    },
});

export const { setEditcomponentTypeDataName } = ComponentData.actions;
export default ComponentData.reducer;
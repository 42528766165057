import { Handle, Position } from 'reactflow';

function ButtonCustomNode() {
  return (
    <div>
      <Handle type="target" position={Position.Top} />
            <button className="btn btn-primary" style={{borderRadius: "50%"}}>+</button>
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
}

export default ButtonCustomNode;


import React, { memo, useEffect, useState } from 'react';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";


import { useDispatch, useSelector } from "react-redux";

import 'react-circular-progressbar/dist/styles.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';

import { useNavigate, useLocation } from "react-router-dom";
import { useMsal } from "@azure/msal-react";
import { fetchGroups, fetchProjects } from './services/gitLabService';

import { postUserProjectData } from "./main/store/userProject.actions"



let label = <></>

function ExistingProject(
    { allProjectsNames1,
        handleClose1,
        projectType,
        show1,
        setprojectType,
        handleClose,
        setCurrent,
        current,
        projectTypeDisplay,
        addOptionID,
        projrepodata,
        errorbox,
        seterrorbox,
        repoid,
        targetid,
        setuserprojid,
        prodtypedata

    }) {

    const { instance, accounts, inProgress } = useMsal();
    const name = accounts[0] && accounts[0].username;

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [projname, setprojname] = useState();
    const [groups, setGroups] = useState([]);
    const [projects, setProjects] = useState([]);
    const [currAccessToken, setCurrAccessToken] = useState("")
    const [currProjType, setCurrProjType] = useState("")

    const [projectDetails, setprojectDetails] = useState({})
    const [currGrp, setCrrGrp] = useState("")

    /**START for Add Dialogue fields */
    const [inputFields, setInputFields] = useState([
        {
            dialoguename: "",
            description: "",
        }
    ])



    if (targetid === 1) {
        label = <>
            <div>Access Token : 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</div>
            <div>Organisation Name : CodeAssistant-Repo</div>
        </>
    }
    if (targetid === 2) {
        label = <div><div>storage connection string : DefaultEndpointsProtocol=https;AccountName=BitCraft;AccountKey=cSNe3w9LIIz7nqnIJgC+wfrJoJ9Fk2hltY4vfJ51JmZx1YChdWYfNEpDxWr+cKX7TPy3PahBkU+U+AStpYimnQ==;EndpointSuffix=core.windows.net</div><div>container name : test</div></div>
    }
    if (targetid === 3) {
        label = <>
            <div>Access Token : glpat-uoWxDxws_f8tLAH1f5vw</div>
        </>
    }
    const loadGroups = async (event) => {

        setCurrAccessToken(event.target.value)
        if (event.target.value !== "") {
            let grpss = await fetchGroups(event.target.value)

            setGroups(grpss)
        } else {
            setGroups([])
        }

    }
    const loadProjects = async (event) => {
        setCrrGrp(event.target.value)
        if (event.target.value !== "") {
            let prjss = await fetchProjects(currAccessToken, event.target.value)

            setProjects(prjss)
        } else {
            setProjects([])
        }

    }
    const projectChange = async (event) => {
        setprojectDetails(projects.filter(x => x.id === Number(event.target.value))[0])
    }
    const projectTypeChange = async (event) => {
        setCurrProjType(event.target.value)
    }
    const importProject = async () => {

        //check if project already imported

        if (allProjectsNames1.filter(x => x.projectname === projectDetails.name && x.projecttypeid._id === "667694ba9963e60ab8d43860")?.length > 0) {
            addToast("Project already imported", { appearance: 'error', autoDismiss: 'true' })

        } else {


            var repotreeurl = "https://gitlab.com/api/v4/projects/" +
                projectDetails.id + "/repository/tree?recursive=true&per_page=100"
            var downloadurl = "https://gitlab.com/api/v4/projects/" + projectDetails.id + "/repository/archive"
            var contenturl = "https://gitlab.com/api/v4/projects/" + projectDetails.id + "/repository/files/" + "path" + "/raw?ref=main"
            var repourl = projectDetails.web_url

            let data = {
                "useremail": name,
                "projecttypeid": "667694ba9963e60ab8d43860",
                "projecttypeidEP": currProjType,

                "projectname": projectDetails.name,
                "description": projectDetails.description===null?"Existing project":projectDetails.description,
                repourl: repourl,
                filewritten: true,
                repotreeurl: repotreeurl,
                composestageindex: 6,
                // deploymenturl: azureDeployedURL,
                downloadurl: downloadurl,
                contenturl: contenturl,
                targetParam: [
                    {
                        accesstoken: currAccessToken,
                        groupname: currGrp
                    }
                ]
            }
            dispatch(postUserProjectData({ data: data, instance: instance })).then((res) => {
                navigate('/explorerEP?_id=' + res.payload.data._id)

            })
        }
    }
    const { addToast } = useToasts();
    // addToast("hi")
    return (
        <>
            <ToastProvider placement="top-center" >
                <Modal show={show1} onHide={() => { handleClose1(); handleClose(); setCurrent(0); setprojectType(""); seterrorbox(0); setuserprojid("") }} scrollable backdrop="static"
                    keyboard={false} centered

                    dialogClassName="modal-30w"
                >

                    <Modal.Header closeButton>
                        <Modal.Title className='font_18 font_regular'> {'Code-Assistant'}</Modal.Title>
                    </Modal.Header>                    {/* </Modal.Header> */}

                    <Modal.Body className='p-0 position-relative'>

                        <div class="h-100" id="nonspinnerdiv" hidden={false}>


                            {/* //Target Repo */}
                            <div class="row h-100 m-0" >
                                <div class="col-12 p-0">
                                    <div class="d-flex h-100">

                                        <div class="tabs_main flex-fill">
                                            <div class="row m-0" id="modal3" >
                                                <div class="col ps-4 pe-4 py-3">
                                                    <div class="page1" id="1" >
                                                        <div class="row">
                                                            <div>
                                                                <p className='font_14 font_regular'>Target</p>
                                                                <div class="mb-3">
                                                                    <select class="form-select" aria-label="Default select example" id="selectrepoid" onChange={addOptionID}>
                                                                        <option selected>Open this select menu</option>
                                                                        {projrepodata?.map((data, index) => (
                                                                            <option id="optionid" value={data.id}>{data.reponame}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div class="mb-3">
                                                                    <select class="form-select" aria-label="Default select example" id="selectprojid" placeholder='Project Type'
                                                                    onChange={projectTypeChange}>
                                                                        <option selected>Project Type</option>

                                                                        {prodtypedata?.filter(x => x.id !== "667694ba9963e60ab8d43860").map((data, index) => (
                                                                            <option id="optionid" value={data.id}>{data.title}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div class="mb-3">
                                                                    {projrepodata?.map((data, index) => (
                                                                        data.id === repoid ?
                                                                            data.parameters?.map((param, index) => (
                                                                                param !== "group name" ?
                                                                                    <div style={{ paddingTop: '15px' }}>
                                                                                        <input type="text" class={`form-control ${errorbox === 3 ? "border-danger" : null}`}
                                                                                            id={(data.reponame + param).replace(/ /g, "")} placeholder={param} onChange={loadGroups} />
                                                                                    </div> :
                                                                                    <div style={{ paddingTop: '15px' }}>
                                                                                        <select class="form-select" aria-label="Default select example" id="selectrepoid" onChange={loadProjects}>
                                                                                            <option selected>{param}</option>
                                                                                            {groups?.map((data, index) => (
                                                                                                <option id="optionid" value={data.id}>{data.name}</option>
                                                                                            ))}
                                                                                        </select>
                                                                                    </div>
                                                                            )) : <></>

                                                                    ))}
                                                                </div>
                                                                <div style={{ paddingBottom: '15px' }}>
                                                                    <select class="form-select" aria-label="Default select example" id="selectrepoid" onChange={projectChange}>
                                                                        <option selected>project name</option>
                                                                        {projects?.map((data, index) => (
                                                                            <option id={data.id} value={data.id}>{data.name}</option>
                                                                        ))}
                                                                    </select>
                                                                </div>
                                                                <div class="mb-3" style={{ paddingTop: '15px' }}>
                                                                    <Button variant="primary me-3" onClick={importProject}
                                                                        disabled={projectDetails == {} ? true : false}>
                                                                        Import Project
                                                                    </Button>
                                                                </div>

                                                                {label}


                                                            </div>
                                                        </div>
                                                    </div>






                                                </div>









                                            </div>

                                        </div>
                                    </div>
                                </div>
                            </div>

                        </div>

                        {/* added progress screen of popup */}

                    </Modal.Body>

                </Modal>
            </ToastProvider>
        </>
    )
}

export default ExistingProject;
import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL
});

const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1
});

async function generateClient(instance) {
    const accessTokenRequest = { scopes: [process.env.REACT_APP_API_CONFIGS_SCOPE], account: instance.getAllAccounts()[0], };
    // 
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest);
    return `Bearer ${accessTokenResponse.accessToken}`;
}

class ThemeService extends RequestHandler {

    //fetch all product type data
    async getThemeData(data, instance) {

        //
        var res
        //res = await client.get("/listtheme"+data);
        if (data === "" || data === undefined || data === "undefined") {
            res = await this.requestHandler(instance).get("/theme");
        }
        else {
            res = await this.requestHandler(instance).get("/theme?projecttype=" + data);

        }
        //
        return res.data
    }

    //post theme data
    async postThemeData(newtheme, instance) {

        // var heads = await generateClient(instance)

        const res = await client1.post("/theme/create-theme", newtheme
            // ,{headers: { "Authorization": heads}}
        );
        //
        return res;
    }

    //post themepackage data
    async postPackageThemeData(newpackagetheme, instance) {

        //
        // var heads = await generateClient(instance)

        const res = await client1.post("/package-component/create-package-comp-theme", newpackagetheme
            // ,{headers: { "Authorization": heads}}
        );
        //
        return res;
    }
    // fetch all theme data grid
    async getThemeGridData(id, instance) {

        // var heads = await generateClient(instance)
        //
        const res = await client1.get("/package-component/package-component-theme" + id
            // ,{headers: { "Authorization": heads}}
        );
        //
        return res.data
    }

    //fetchcompinentdata by id
    async getEditThemeData(id, instance) {
        //
        var heads = await generateClient(instance)
        const res = await client1.get("/package-component-detail/filter-theme?id=" + id, { headers: { "Authorization": heads } });
        //
        return res.data
    }


    //put themepackage data
    async putThemeData(newpackagetheme, instance) {

        var heads = await generateClient(instance)
        const res = await client1.put(`/theme/update-theme/${newpackagetheme._id}`, newpackagetheme, { headers: { "Authorization": heads } });
        //
        return res;
    }
    //put themepackage data
    async putPackageThemeData(newpackagetheme, instance) {

        // var heads = await generateClient(instance)
        const res = await client1.put(`/package-component/update-package-component/${newpackagetheme._id}`
            , newpackagetheme,
            // {headers: { "Authorization": heads}}
        );
        //
        return res;
    }
    //put themepackage data
    async putPackageCompThemeData(newpackagetheme, instance) {

        // var heads = await generateClient(instance)
        const res = await client1.put(`/package-component-detail/update-package-component/${newpackagetheme._id}`
            // ,newpackagetheme,{headers: { "Authorization": heads}}
        );
        //
        return res;
    }
}

export default new ThemeService;
import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });

  async function generateClient(instance){
    const accessTokenRequest = { scopes: [process.env.REACT_APP_API_CONFIGS_SCOPE], account: instance.getAllAccounts()[0], }; 
    // 
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
    return `Bearer ${accessTokenResponse.accessToken}`;
}

class NfrService extends RequestHandler {
    //post meyhod for new package
    async postNewPackageData(newpackage,instance){
        
        // const accessTokenRequest = { scopes: this.scope, account: instance.getAllAccounts()[0], }; 
        //  // 
        //  const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
        //  var heads = `Bearer ${accessTokenResponse.accessToken}`;
        const res = await client1.post("/package-component/create-package-component",newpackage
        // ,
        // {headers: { "Authorization": heads}}
        );
        //
        return res;
    }
    async postNewPackageDetailData(newpackage,instance){
         
        //  const accessTokenRequest = { scopes: this.scope, account: instance.getAllAccounts()[0], }; 
        //  // 
        //  const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
        //  var heads = `Bearer ${accessTokenResponse.accessToken}`;
        const res = await client1.post("/package-component-detail/create-package-component-detail",newpackage
        // ,{headers: { "Authorization": heads}}
        );
        //
        return res;
    }
    //fetch all product type data
    async getNfrTypeData(flag,instance) {
         
         var res
         if(flag===""||flag===undefined||flag==="undefined")
         {
         res = await  client1.get("/package-category");

         }
         else
         {
         res = await  client1.get("/package-category?projecttype="+flag);

         }
        //
        return res.data

    }
    //fetch all product type data grid
    async getPackagesGridData(data,instance,version) {
        
        var res
        // if(version===undefined)
        // {
        //  res = await client1.get("/package-component-detail/filter-category?projecttype="+data);

        // }
        // else
        // {
        //  res = await client1.get("/package-component-detail/filter-category?projecttype="+data+"&projectversion="+version);

        // }
        if(version)
        data = data+"&projectversion="+version
        res = await client1.get("/package-component-detail/filter-category?projecttype="+data);

         //
        return res.data
    }
    
    async getPackagesComponentData(instance) {
        // 
        const res = await  client1.get("/package-category");
        //
        return res.data
    }
    async getPackCompCategory(id) {
        // 
        const res = await client.get("/filterpackcompcategory?category="+id);
        //
        return res.data
    }

    //fetch all product type data grid
    async getPackagesTypeGridData(instance) {
        // 
        const res = await  client1.get("/packagetype");
        //
        return res.data
    }
    //post package category data
    async postpackcategoryData(data,instance){
        // var heads = await generateClient(instance)
        const res = await client1.post("/package-category/create-package-category",data
        // ,{headers: { "Authorization": heads },}
        );
        //
        return res;
    }

   //fetch package component by id
   async getEditPackageData(id) {
    //
   const res = await client1.get("/package-component-detail/getPackageCategoryDetail?packageComponentDetailId="+id);
   
   return [res.data]
}
async putpackcategoryData(data){
    
   const res = await client.put("/putPackageCategory",data);
   //
   return res;
}


 //put package data
 async putPackageData(newpackage,instance){
    
    // var heads = await generateClient(instance)
    const res = await client1.put(`/package-component/update-package-component/${newpackage._id}`,newpackage
    // ,{headers: { "Authorization": heads}}
    );
    //
    return res;
}
//put package detail data
async putPackageDetailData(newpackagedetail,instance){

// var heads = await generateClient(instance)
const res = await client1.put(`/package-component-detail/update-package-component/${newpackagedetail._id}`,newpackagedetail
// ,{headers: { "Authorization": heads}}
);
//
}

//delete package detail data
async deletePackageDetailData(id, instance){
    
    //
   // const res = await client.delete("/deletePackageComponentDetail?id="+id);
    var heads = await generateClient(instance)

    const res = await client1.delete(`package-component-detail/delete-package-component/${id}`,{headers: { "Authorization": heads}});
    //
    return res;
    }
    
    
}
    
    export default new NfrService;
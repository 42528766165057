import axios from "axios";

export const fetchGroups = async (accessToken) => {
    try {
      const response = await axios.get('https://gitlab.com/api/v4/groups', {
        headers: {
          'PRIVATE-TOKEN': accessToken
        }
      });
      return response.data
    } catch (err) {
        return err    } 
  };

  export const fetchProjects = async (accessToken, groupId) => {
    try {
      const response = await axios.get('https://gitlab.com/api/v4/groups/'+groupId+'/projects', {
        headers: {
          'PRIVATE-TOKEN': accessToken
        }
      });
      return response.data
    } catch (err) {
        return err    } 
  };

  export const commitToGitLab = async (filePath, fileName, fileContent, treeURL, accessToken) => {
    const projectId = '60287642'; // Replace with your GitLab project ID
    const branchName = 'main'; // Replace with your branch name
    // const accessToken = 'glpat-uoWxDxws_f8tLAH1f5vw'; 

    let url = treeURL?.replace("tree?recursive=true&per_page=100","commits")
    try {
        // Define the commit data

        const commitData = {
            branch: 'main', // Specify the branch name
            commit_message: `Add ${fileName}`,
            actions: [
                {
                    action: 'create',
                    file_path: `${filePath}/${fileName}`,
                    content: fileContent,
                },
            ],
        };

        // Make the API request to create a commit
        const response = await axios.post(
          url,
          commitData,
            {
                headers: {
                    'PRIVATE-TOKEN': accessToken,
                },
            }
        );

        return response.data;
    } catch (error) {
        console.error('Error committing to GitLab:', error);
        throw error;
    }
};
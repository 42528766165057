import axios from "axios";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

class InputControlService 
{
    async getInputControlData() {
        
        const res = await client.get("/listcontroltype");
        //
        return res.data

    }

    async getInputControlDetailData() {
        
        const res = await client.get("/listcontrols");
        //
        return res.data

    }
}

export default new InputControlService

import { createSlice } from "@reduxjs/toolkit";
import { fetchWizardData } from "./wizard.actions";

const WizardData = createSlice({
    name: "wizardstore",
    initialState: {
        wizardData: { wizard: [], loading: true},
        currentwizardTypeData : {
            wizardtype: {
                id: "",
                name:"",
            },
        },
        currentwizardTypeDataCopy: {
            wizardtype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditwizardTypeDataName: (state, actions) => {
            state.currentwizardTypeData.wizardtype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchWizardData.pending]: (state) => {
            state.wizardData.loading = false;
        },
        [fetchWizardData.fulfilled]: (state,actions) => {
            state.wizardData.wizard = actions.payload?.wizard;
            state.wizardData.loading = actions.payload?.loading;
        },
    },
});

export const { setEditwizardTypeDataName } = WizardData.actions;
export default WizardData.reducer;
import React, { memo, useEffect, useState } from 'react';
import { ReactComponent as GreenPlus_icon } from '../../images/greenplus_icon.svg';
import { ReactComponent as External_link } from '../../images/external_link.svg';
import { ReactComponent as Play_icon } from '../../images/play_icon.svg';
import { ReactComponent as Components_icon } from '../../images/Component_iconnn.svg';
import { Nav } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getAllComponents } from "../store/userProject.actions";
import { DataGrid } from '@mui/x-data-grid';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

const { REACT_APP_MARKETPLACE_URL } = process.env;


var url = process.env.REACT_APP_BASE_URL;
function ComponentScreen({setStudioOpen}) {
    const [show, setShow] = useState(false);  // modal popup but not being used
    const handleShow = () => setShow(true);
    const [reset, setReset] = useState(0);
    const dispatch = useDispatch();
    const {instance, accounts, inProgress} = useMsal();
    const name = accounts[0] && accounts[0].username;

    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(getAllComponents({name:name,"dispatch":dispatch, instance:instance}))
        }
    }, [instance.getAllAccounts()[0] !== undefined])

    const allComponentsList = useSelector((state) => state?.commonReducer?.allProjectsNames?.componentData?.allComponents);
    const [packagegrid2, setRows] = useState([]);
    const [recentappscol, setColumn] = useState([]);
    
    useEffect(() => {
        setRows(allComponentsList?.allCompList);
        setColumn(allComponentsList?.column);
    }, [allComponentsList])
    let latestComp = allComponentsList?.allCompList?.length > 0 && allComponentsList?.allCompList.slice(-5);

    return (
        <>

<div class="home_screen p-4 pt-3 text-start">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="d-flex bottomcolor">
                                <p class="font_15 mb-2 font_regular prminus10"><span className='blue_colorr'><i>Build business apps, 10X speed - tools can repeat. developers can innovate.</i></span></p>
                                <div class="ms-auto blue_colorr font_15 pr_4"><Nav.Link href={REACT_APP_MARKETPLACE_URL}>
                                    Marketplace<External_link class="ms-2" />
                                </Nav.Link></div>
                            </div>

                        </div>

                    </div>
                    <div class="row mt-3 row-cols-1 row-cols-sm-2 row-cols-md-3 row-cols-lg-4 row-cols-xl-6">
                        <div class="col mb-3">
                            <div class="home_apps text-center h-100"  onClick={() => {setStudioOpen(true)}}>
                                <div class="d-flex flex-column justify-content-center">
                                    <div className='mb-2'><GreenPlus_icon /></div>
                                    <p className='font_14 mb-1 font_regular'>New Component</p>
                                    <p className='font_12 content_size mb-1 font_light'>Create an project from the available types</p>
                                    <div class="blue_colorr"><Play_icon /><span className='font_12 font_light ms-2'>Learn More</span></div>
                                </div>
                            </div>
                        </div>
                        {latestComp && latestComp?.length > 0 && latestComp?.map((list) => {
                            return (
                        <div class="col mb-3">
                        <div class="home_apps text-center h-100">
                            <div class="d-flex flex-column justify-content-center">
                                <div className='mb-3'><Components_icon /></div>
                                <p className='font_14 mb-1 font_regular'>{list.componentname}</p>
                                <p className='font_12 content_size mb-1 font_light'>{list.componentdesc}</p>
                                <div class="blue_colorr"><Play_icon /><span className='font_12 ms-2 font_light'>Learn More</span></div>
                            </div>
                        </div>
                    </div>)                  
                        })}

                    </div>
                    <div class="row mt-3">
                    <div class="col-lg-12">
                        <div class="bg_grey_container border-radius-0">
                            <div class="row">
                                <div class="col-lg-12 text-start">
                                    <p className='mb-0 font_14 font_medium'>Other Components</p>
                                </div>
                            </div>
                            <div class="row">
                                <div class="col-12">
                                    <div className='customdatagrid' style={{ width: '100%' }}>
                                        {packagegrid2 !== undefined && packagegrid2.length >0 ?
                                        <DataGrid autoHeight rows={packagegrid2} columns={recentappscol}
                                            initialState={{
                                                pagination: {
                                                    pageSize: 10,
                                                },
                                            }}
                                            disableSelectionOnClick
                                        /> : ''}
                                    </div>
                                    {/* <AllAppsGrid /> */}
                                </div>
                            </div>                            
                        </div>
                    </div>
                </div>
                </div>
        </>
    )
}
export default ComponentScreen;                
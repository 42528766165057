import React, { memo, useEffect, useState } from 'react';
import { FaCode, FaRegCalendarAlt, FaRocketchat, FaFile } from 'react-icons/fa';
import { X } from 'react-bootstrap-icons';
import Button from 'react-bootstrap/Button';
import { Modal } from "react-bootstrap";
import { Form, InputGroup, Row } from 'react-bootstrap';
import { Search, Plus, XLg } from 'react-bootstrap-icons';
import 'react-circular-progressbar/dist/styles.css';
import { ToastProvider, useToasts } from 'react-toast-notifications';
import { fetchComponentData } from '../store/component.actions';
import { useDispatch, useSelector } from "react-redux";
import componentIcon1 from '../../images/componentIcon.svg';
import { compress, decompress } from 'compress-json'
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

var firststepstatus;
let count = 0;
var setTitle = []
let vac = 0;
let hiddenval = 0
let set1 = 0;
let sizeset = 0
let SEARCH
let CrossSymbol, CrossSymboltheme, CrossSymbolnfr, CrossSymbollayout, search;;
function ComponentPopup(
    {
        handleClose,
        show,
        current,
        setCurrent,
        handleShow,
        editor
    }) {

    var value
    const dispatch = useDispatch();
    const {instance, accounts, inProgress} = useMsal();
    //)
    const insertComp = () =>{
        ;
        let temp = `<div>hello</div>`;
        let tempHtml ="", tempCss =""
        selected.forEach((x)=>{
          //  [0],x)
            tempHtml += decompress(componentAllData.filter(y=>y.id === x)[0]?.componenthtml);
            tempCss += decompress(componentAllData.filter(y=>y.id === x)[0]?.componentcss);
        })
        let component = editor.Pages?.getSelected().getMainComponent();
        let prevhtml = editor.getHtml({ component })
        let prevcss = editor.getCss({ component })
        // 
        // 
        let newcomp = prevhtml + tempHtml;
        let newcss = prevcss + tempCss;
        editor.setComponents(newcomp)
        editor.setStyle(newcss)
        setSelected([])
        handleClose();

    }
    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchComponentData(instance))
        }
    }, [instance.getAllAccounts()[0] !== undefined]);
        const [selected,setSelected]=useState([])
       // 
        const componentData1 = useSelector((state) => state.commonReducer?.component?.componentData?.component);
        const [componentAllData, setcomponentAllData] = useState(componentData1);
        useEffect(() => { setcomponentAllData(componentData1) }, [dispatch, componentData1])
        //
    const nextSlide = () => {

        setCurrent(count);
        count = count + 1;
        document.querySelector('#modal1').hidden = true
        document.querySelector('#modal2').hidden = false
        document.querySelector('#modal3').hidden = false
        setCurrent(count);
        //
    }

    const prevSlide = () => {
        count = count - 1;
        if(current===1)
        {

            document.querySelector("#Existing").checked = false
            document.querySelector("#new1").checked = false
           
            document.querySelector("#Existing").checked = true
            document.querySelector("#new1").checked = false
           
            document.querySelector("#projectdiv").hidden = false
            document.querySelector("#projectnamediv").hidden = true
           
        }
        setCurrent(count);
    }

  
    return (
        <>
            <ToastProvider placement="top-center" >
                <Modal show={show} onHide={() => { setSelected([]);handleClose(); setCurrent(0); count = 0 }} scrollable backdrop="static"
                    //keyboard={false} centered dialogClassName={(reset !== 1) ? (modalsize === 1 && current === 0) ? "modal-85w" : (modalsize === 1 && current === 4||modalsize === 1 && current === 3||( selected.length>0) ) ? "modal-95w" : "modal-75w" : (modalsize === 1 && current === 0) ? "modal-75w" : (modalsize === 1 && current === 4) ? "modal-95w" : "modal-75w"}>
                    keyboard={false} centered dialogClassName="modal-85w" >

                    <Modal.Header closeButton >
                        {current === 0 ? <><Modal.Title className="font_18 font_regular" > All Components</Modal.Title></> : <><Modal.Title className="font_18 font_regular" > PWA React</Modal.Title></>}

                    </Modal.Header>


                    {/* <Modal.Header closeButton={current === 5 ? false : true} hidden={current === 0 ? true : false}>

                        <Modal.Title className="font_18 font_regular" >Python API 1</Modal.Title>
                    </Modal.Header> */}
                    <Modal.Body className='p-0 position-relative'>

                        <div class="h-100" id="nonspinnerdiv" hidden={current === 7 ? true : false}>
                            <div class="row h-100 m-0" id="modal1" hidden={current === 0 ? false : true}>
                                <div class="col p-3 left_position_realtive">
                                    <div class="row">
                                        <div className='col-lg-12'>
                                            <div className='d-flex'>
                                                {/* <p><span>{projcount}</span> Projects</p> */}
                                                <div>
                                                    <div class="form-check form-check-inline">                                                        
                                                        <label class="form-check-label" for="inlineRadio1">{componentAllData?.length} Components</label>
                                                    </div>        
                                                </div>
                                                <div class="ms-auto d-flex align-items-center">
                                                    <div class="custom_inputbtn searchhh">
                                                    <div class="">
                                                            
                                                                <input type="text" class="form-control"

                                                                    id="keyWordproj" placeholder="Search" />
                                                            

                                                               {/* < Search className="cursor-pointer" /> */}
                                                               </div>
                                                            
                                                    </div>
                                                </div>

                                            </div>

                                        </div>
                                    </div>
                                    <div class="row px-2 g-0 row row-cols-1 row-cols-sm-2 row-cols-md-2 row-cols-lg-4 row-cols-xl-6 list_projects_div mt-4" >
                                    {componentAllData?.map((componentAllData)=>{

                                        return(
                                            <>



                                        <div class="col mb-3 px-2 " onClick={() => {
                                            ;
                                        selected.includes(componentAllData.id) ? setSelected(selected.filter(x => x !== componentAllData.id)) :
                                        setSelected([...selected,componentAllData.id])
                                         hiddenval = 1 }}>
                                      
   
                                            <div class={selected.includes(componentAllData.id) ? "projects_main h-100 text-center active" : "projects_main h-100 text-center"} >

                                                <div class="d-flex mb-3 align-items-center">
                                                <div class="ms-auto">
                                               
                                                </div>
                                                    <img src={componentIcon1}/>
                                                    <div class="ms-auto">
                                                        
                                                    </div>
                                                </div>
                                                {/* <h4 className='font_14 font_regular'>PWA .NET</h4> */}
                                                <h4 className='font_14 font_regular'>{componentAllData.componentname}</h4>
                                                {/* <p className='font_12  mb-0 font_light'>Lorem ipsum textLorem ipsum text Lorem ipsum dummy</p> */}
                                                <p className='content_size font_12  mb-0 font_light'>{componentAllData.componentdesc}</p>
                                            </div>
                                           
                                        </div>
                                        </>
                                        )  
                                       
                                    })
                                    
                                }
                                                          
                                    </div>



                                </div>
                              
                            </div>   
                        </div>
                       

                    </Modal.Body>
                    <Modal.Footer id="buttons"  >
                        <div class="" >
                            <Button variant="primary me-3" onClick={()=>{insertComp()}}>
                                Insert
                            </Button>
                                                     
                            
                            
                        </div>
                    </Modal.Footer>
                    <Modal.Footer id="buttons" hidden={current === 7? false:true} >
                        <div class="" >                        
                            <Button variant="primary me-3"
                                onClick={() => {
                                    setSelected([])
                                    handleClose();
                                    count = 0;
                                    setCurrent(0);
                                }}
                                disabled={current == 7 ? false : true} >
                                Close
                            </Button>
                        </div>
                    </Modal.Footer>
                </Modal>
            </ToastProvider>
        </>
    )
}

export default ComponentPopup;
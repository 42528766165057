import { createSlice } from "@reduxjs/toolkit";
import { fetchProductTypeData,fetchProjectRepoData,fetchProjectVersionData,fetchAllVersions } from "./productType.actions";

const ProductTypeData = createSlice({
    name: "producttypestore",
    initialState: {
        prodTypeData: { prodtypedata: [], loading: true},
        projRepoData: { projrepodata: [], loading: true},
        projVersionData: { projversiondata: [], loading: true},
        AllVersionData: { allversion: [], loading: true},
        
        currentprodTypeData : {
            prodtype: {
                id: "",
                name:"",
            },
        },
        currentprodTypeDataCopy: {
            prodtype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditProductTypeDataName: (state, actions) => {
            state.currentprodTypeData.prodtype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchProductTypeData.pending]: (state) => {
            state.prodTypeData.loading = false;
        },
        [fetchProductTypeData.fulfilled]: (state,actions) => {
            state.prodTypeData.prodtypedata = actions.payload?.prodtypedata;
            state.prodTypeData.loading = actions.payload?.loading;
        },
        [fetchProjectRepoData.pending]: (state) => {
            state.projRepoData.loading = false;
        },
        [fetchProjectRepoData.fulfilled]: (state,actions) => {
            state.projRepoData.projrepodata = actions.payload?.projrepodata;
            state.projRepoData.loading = actions.payload?.loading;
        },
        [fetchProjectVersionData.pending]: (state) => {
            state.projVersionData.loading = false;
        },
        [fetchProjectVersionData.fulfilled]: (state,actions) => {
            state.projVersionData.projversiondata = actions.payload?.projversiondata;
            state.projVersionData.loading = actions.payload?.loading;
        },
        [fetchAllVersions.pending]: (state) => {
            state.projVersionData.loading = false;
        },
        [fetchAllVersions.fulfilled]: (state,actions) => {
            state.AllVersionData.allversion = actions.payload?.allversion;
            state.AllVersionData.loading = actions.payload?.loading;
        },
    },
});

export const { setEditProductTypeDataName } = ProductTypeData.actions;
export default ProductTypeData.reducer;
import PlusMenuRight from "../../components/PlusMenuRight";
import { useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate, Route } from "react-router-dom";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import axios from "axios";
import uuid from 'react-uuid';
import grapesjs from 'grapesjs';
import myPlugin from 'grapesjs-blocks-basic';
import webPlugin from 'grapesjs-preset-webpage';
import gjsForms from 'grapesjs-plugin-forms';
import gjsNavbar from 'grapesjs-navbar';
import { addComponents } from '../pageConfig/addComponents';
import { Panels } from '../pageConfig/panels';
import {putUserProjectData , postCodeGeneration} from '../../main/store/userProject.actions';
import io from 'socket.io-client'; 
import CommitPopup from '../../components/commitPopup';
import ModalPopup from '../pageConfig/ModalPopup';
import ComponentPopup from '../home/ComponentPopup';
import AddPagePopup from '../pageConfig/AddPagePopup';
import { compress, decompress } from 'compress-json'
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { fetchAllDataSource } from "../../main/store/dropArea.actions";

// var list1 = [];

// var setfigitem = false;

var url = process.env.REACT_APP_BASE_URL;
let modalData = ``;
var lists , getPath,GetDatasourceList,ID, secondSelect="",postDataVar 
var socket = undefined;
let tdata;
let jQuery;
let status=[],dropData = []
var setRes = [], mainResList = [], dropdownKeys = [], inputArray = []
var putDataList ={},postDataList={}
var imagesArr;
var testArr=[]
var divchildren = "";
var csspage="";
const codeTemp = {
    main:`<body> content </body>`,
    col1:`  <div class="gjs-row" id="ID">
    <div class="gjs-cell">
    content
    </div>
  </div>`,
  navContainer:` <div class="navbar">
  <div class="navbar-container" data-gjs="navbar">
  content
  </div></div>`,
  navItems:`<div class="navbar-items-c" data-gjs="navbar-items">
  <nav class="navbar-menu">content</div></div>`,
  navItem:`<div id=id> <a class="navbar-menu-link">content</a></div>`,
  img:`<img id="ID" src="srclink" />`,
  div:`<div id="ID">content</div>`,
  group:`<div id="ID">group</div>`,
  map:`<iframe frameborder="0" id="ID" src="https://maps.google.com/maps?&q=Hexaware%20Technologies&z=5&t=q&output=embed"></iframe>`,
  table:`table`
  }

function InsertControl(props, openToggle, setOpenToggle,sidebarhide, setSidebarhide){

    //)
    const location = useLocation();  
    //)
    localStorage.setItem("userprojectid",location.search.replace("?_id=",""))
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const {instance, accounts, inProgress} = useMsal();
    const { addToast } = useToasts();
    const [listControls, setlistControls] = useState([]);
    const [pages, setPages] = useState([]);
    const[selectedPage, setselectedPage] = useState();
    const[figFirstPage, setFigFirstPage] = useState();
    const [show, setShow] = useState(false);
    const [setfigitem, setsetfigitem] = useState(false);
    const [editor, setEditor] = useState(null);
    const [currentPage, setCurrentPage] = useState();
    const [newpages, setNewPages] = useState([]);   
    const [modalInfo, setModalInfo] = useState([]);	
    const [modalShow, setModalShow] = useState(false);  
    const [modalBtnId, setModalBtnId] = useState(); 
    const figmatoken = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails[0]?.figmatoken);
    const figmafile = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails[0]?.figmafile)
    const remoteData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails[0]?.screencomponents);
    const data = location.search;  
    const projid = data.replace("?_id=","")
    const[ popupshow, setShowPopup] = useState(false)
    const [showPage, setShowPage] = useState(false);
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);
    const handleClosePage = () => setShowPage(false);
    const handleShowPage = () => setShowPage(true);
    //Map Data will be replaced later by Data Source
    var s="Dentel AI"
    s=  encodeURI(s)

    const ProjectData = useSelector((state) => state.commonReducer.projectdetails.ProjectDetails.projectdetails);
    //
    useEffect(() => {
      if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchAllDataSource({data:null, instance:instance}))
      }
      }, [dispatch, instance.getAllAccounts()[0] !== undefined])
      const datasource = useSelector((state) => state.commonReducer?.droparea?.datasource?.datasource);
      //
      //
      useEffect(() => {
        //
        //
        lists = datasource && datasource?.filter(x => x.httpobject?.httpverb?.toLowerCase() === "post" || x.httpobject?.httpverb?.toLowerCase() === "put").map((data, index) => {
          return `<li data-attr="${data.httpobject?.url}" data-gjs-type="PopUpli" data-verb="${data.httpobject?.httpverb}">${data.datasourcename}</li>`
          //
        })
        if(datasource?.length > 0) {
          addComponents({editor: editor, handleModal: handleModal, appendModalData: appendModalData, projectData: ProjectData, datasource: datasource});
    
        }
        //
      }, [datasource])
      useEffect(() => {
        //
        //
        GetDatasourceList = datasource && datasource.filter(x => x.httpobject?.httpverb?.toLowerCase() === "get" ).map((data, index) => {
          return `<li data-attr="${data.httpobject?.url}" data-gjs-type="DropdownGetPopup" data-verb="${data.httpobject?.httpverb}">${data.datasourcename}</li>`
        })
        //
        //?.replaceAll(',', ''))
      }, [datasource])
      //
      
      const [pageCreated, setPageCreated] = useState();
      const [pageControl, setPageControl] = useState();
      let [Responsee, setResponsee] = useState([])
      const [popupInfo, setPopupInfo] = useState();
      const [startStoring, setStartStoring] = useState(false);
      const[finalFigPages, setfinalFigPages] = useState([]);
      const[loaderdiv, setLoaderDiv] = useState(
          <> 
           <div class="spinner-border text-primary" role="status">
          </div>
          <span>Loading </span>
          </>
        
        ) 
        const [insertControlsData, setInsertControlsData] = useState([
            {
              "id": 1,
              "name": "Button"
            },
        
            {
              "id": 2,
              "name": "Text Input"
            },
        
            {
              "id": 3,
              "name": "Drop down"
            },
        
            {
              "id": 4,
              "name": "Date Picker"
            },
        
            {
              "id": 5,
              "name": "Check Box"
            }
        
          ]);
          
    const [appendData, setModalData] = useState('');
    var pagecount = 1;
    function rgbToHex(r, g, b) {
      var r = r*255
      var g = g*255
      var b = b*255
      const color = '#' + ((1 << 24) + (r << 16) + (g << 8) + b).toString(16).slice(1);
      if (color.length > 7) {
        //
    
        return color.slice(0, 7);
      }
      return color;
    };
    function rgbaToHex (r,g,b,a) {
        var outParts = [
          r.toString(16),
          g.toString(16),
          b.toString(16),
          Math.round(a * 255).toString(16).substring(0, 2)
        ];
      
        // Pad single-digit output values
        outParts.forEach(function (part, i) {
          if (part.length === 1) {
            outParts[i] = '0' + part;
          }
        })
      
        return ('#' + outParts.join(''));
      }
      const getStyles=(element,parent)=>{
        var css = "";
        var id = "g"+element.id.replace(":","");
        css = `#`+id+`{`;
        css  += `height:`+element.size.y+`px;`
        css  +=  `width:`+element.size.x+`px;`
        var fills = element.fills;
        css  += element.type!=="TEXT" && element.type!=="VECTOR" && fills.length>0 && fills[0].type === "SOLID"?
        `background:`+rgbToHex(fills[0].color.r,fills[0].color.g,fills[0].color.b,fills[0].color.a)+`;`: ""
        css  += element.type==="TEXT" && fills.length>0 && fills[0].type === "SOLID"?
        `color:`+rgbToHex(fills[0].color.r,fills[0].color.g,fills[0].color.b,fills[0].color.a)+`;`: ""
        css += `position: absolute;`
        var posx = parent!==undefined? parent.relativeTransform[0][2]-element.relativeTransform[0][2]:element.relativeTransform[0][2];
        var posy = parent!==undefined? parent.relativeTransform[1][2]-element.relativeTransform[1][2]:element.relativeTransform[1][2];
        css += element.id.includes("i")? "":`transform: translateX(`+posx+`px) translateY(`+posy+`px);`
        var dum;
        if(element?.strokes?.length>0){
          //
          dum = `border:`;
          dum += element.strokeWeight +"px "
          //
          dum += element.strokes[0].type + " ";
          //
          dum += rgbToHex(element.strokes[0].color.r,element.strokes[0].color.g,element.strokes[0].color.b,element.strokes[0].color.a)
          //
        }
        css += dum
        css  += '}'
        csspage += css;
        //
      }
      async function renderFigmaGroups(children, html, divchildren){
        //
        try{
          return new Promise(async (resolve, reject) => {
            // if(children.name.startsWith("hh") && children.type==="GROUP"){
            //   
            //   html = html.replace("content",codeTemp.col1)
            //   html = html.replace("content",codeTemp.navContainer)
            //   if(children.children.length>0)
            //   {
            //     html = html.replace("content",codeTemp.navItems)
            //     var navItemsChild=``
            //     var loop = new Promise((resolve, reject)=>{children.children.forEach((g,index,array)=>{
            //       //index===0?html = html.replace("content",codeTemp.navItem):
            //       var tempnavi = codeTemp.navItem;
            //       var replacement="";
            //       if(g.children === undefined)
            //       replacement = renderFigmaNonGroupElem(g)
            //       tempnavi = tempnavi.replace("content",replacement)
            //       navItemsChild = navItemsChild+tempnavi
            //       
      
            //       if(index === array.length - 1)
            //       resolve();
            //     })})
            //     
            //     loop.then(()=>{
            //       html = html.replace("content",navItemsChild)
            //       
            //       //return html
            //       resolve(html)
      
            //     })
            //   }
              
                
            // }else
            //await renderFigmaGroups()
            if(children.type==="GROUP" ||children.type==="FRAME" ){
              // var coltemp = codeTemp.col1.replace("ID",uuid())
              // 
              //html = html.replace("content",coltemp)
              //html = html.replace("content",codeTemp.navContainer)
              if(children.children.length>0)
              {
                //html = html.replace("content",codeTemp.navItems)
                // var divchildren=``
                var newchildren="";
                for (const [index,g] of children.children.entries()) {
                  var array = children.children;
                  //
                  //index===0?html = html.replace("content",codeTemp.navItem):
                  var tempdiv = codeTemp.div.replace("ID","g"+g.id.replace(":",""));
                  var replacement="";
                  if(g.children === undefined){
                    await renderFigmaNonGroupElem(g).then((replacement)=>{
                      //
                      tempdiv = tempdiv.replace("content",replacement)
                      //divchildren = divchildren+tempdiv
                      newchildren = newchildren + tempdiv;
                      //
          
                      if(index === array.length - 1){
                        //
                        if(divchildren.includes("group"))
                        divchildren = divchildren.replace("group",newchildren)
                        else
                        divchildren = divchildren + newchildren
                        //
                        //resolve();
  
                      }
                    })
                   
                  }
                  else{
                    //
                    var cuurentdivgroup = "";
                     //divchildren = divchildren + codeTemp.group.replace("ID",uuid());
                     //
                     await renderFigmaGroups(g, html, divchildren).then((replacement)=>{
                      //
                      tempdiv = tempdiv.replace("content",replacement)
                      //divchildren = divchildren+tempdiv
                      //divchildren = tempdiv;
                      cuurentdivgroup = cuurentdivgroup + tempdiv;
                      //
          
  
                      newchildren = newchildren + tempdiv;
                      //
          
                      if(index === array.length - 1){
                        //
                        if(divchildren.includes("group"))
                        divchildren = divchildren.replace("group",newchildren)
                        else
                        divchildren = divchildren + newchildren
                        //
                        //resolve();
  
                      }
  
  
                      // if(index === array.length - 1){
                      //   
                      //   //divchildren = newchildren + tempdiv;
                      //   divchildren = divchildren.replace("group",tempdiv)
  
                      //   //resolve();
  
                      // }else{
                      //   divchildren = tempdiv;
  
                      // }
                    })
  
                  
                  }
                }
                //
                testArr.push(divchildren)
                if(children.children.filter(x=>x.name.startsWith("filler")).length>0){
                  //
                  var e = children.children.filter(x=>x.name.startsWith("filler"))[0]
                  getStyles(e, children)
                  var tempp = codeTemp.col1.replace("ID","g"+e.id.replace(":",""))
                  divchildren = tempp.replace("content", divchildren)
                  //
                }else{
                  //
                  getStyles(children)
                  var tempp = codeTemp.col1.replace("ID","g"+children.id.replace(":",""))
                  divchildren = tempp.replace("content", divchildren)
                  //
                }
                resolve(divchildren)
                
                // var loop = new Promise((resolve, reject)=>{children.children.forEach((g,index,array)=>{
                //   
                //   //index===0?html = html.replace("content",codeTemp.navItem):
                //   var tempdiv = codeTemp.div.replace("ID",uuid());
                //   var replacement="";
                //   if(g.children === undefined){
                //     renderFigmaNonGroupElem(g).then((replacement)=>{
                //       
                //       tempdiv = tempdiv.replace("content",replacement)
                //       divchildren = divchildren+tempdiv
                //       
          
                //       if(index === array.length - 1){
                //         
                //         resolve();
  
                //       }
                //     })
                   
                //   }
                //   else{
                //     
                //      renderFigmaGroups(g, html, divchildren).then((replacement)=>{
                //       
                //       tempdiv = tempdiv.replace("content",replacement)
                //       divchildren = divchildren+tempdiv
                //       
          
                //       if(index === array.length - 1){
                //         
                //         resolve();
  
                //       }
                //     })
  
                  
                //   }
                 
                // })})
                // 
                // loop.then(()=>{
                //   var htmlbeforereolcae = html;
                //   //html = html.replace("content",divchildren)
                //   
                //   
                //   //return html
                //   testArr.push(divchildren)
                //   resolve(divchildren)
      
                // })
              }
            }else{
              resolve();
            }
            // else{
            //   resolve()
            // }
          })
        }catch(error) {
          //
        }
       
      }
      async function renderFigmaNonGroupElem(element){
        //
        getStyles(element)
        try{
          return new Promise(async (resolve, reject) => {
            var code;
            if(element.type === "RECTANGLE" && element.fills.length>0 && element.fills[0].type === "IMAGE"){
              element.id = "i"+element.id;
              //
              if(element.name.startsWith("map")){
                code = codeTemp.map.replace("ID",`g`+element.id.replace(":",""))
              }else{
              let imageRef =  element.fills[0].imageRef; 
              //
              code = codeTemp.img.replace("ID",`g`+element.id.replace(":","")).replace("srclink",imagesArr[imageRef])
              }
              getStyles(element)
              resolve(code)
  
            }else if(element.type === 'TEXT' && !element.name.startsWith("btnlbl")){
              code = element.characters
              resolve(code)
  
            }else if(element.type === 'VECTOR'){
              code = "v"
              resolve(code)
            }else if(!element.name.startsWith("filler") && element.type === "RECTANGLE"){
              //code = element.name
              code = "";
              resolve(code)
            }else{
              code = ""
              resolve(code)
            }
            //
      
            //return code;
          })}
        catch(Error){
          //
        }
        
      }
      //
      async function renderfigmaelements(figmatoken,figmafile){
        
        var list1=[];   
        var figPagedata = []; 
        finalFigPages.length = 0; 
        setsetfigitem(true)
        const options = {
  
            headers: {'X-Figma-Token': figmatoken}
        
          };
        
        axios.get('https://api.figma.com/v1/files/'+ figmafile+'?geometry=paths', options).
  
        then(response => {
  
          let main = response.data;
  
          axios.get('https://api.figma.com/v1/files/'+ figmafile +'/images', options).
  
          then(images => {
  
          imagesArr = images.data.meta.images;
            
         
          setPages(main["document"]["children"].map(x=>x.name))        
  
          
          
          var top = main["document"]["children"]
                    
          var loop = new Promise((resolve, reject)=>{
            top.forEach((subpages, index1, array1)=>{
                

                var pages1 = subpages.children.map(x=>x.children)[0].sort((a, b)=> {
                    

                    if (a.absoluteBoundingBox.y  === b.absoluteBoundingBox.y ){
        
                      return a.absoluteBoundingBox.x < b.absoluteBoundingBox.x ? -1 : 1
        
                    } else {
        
                      return a.absoluteBoundingBox.y  < b.absoluteBoundingBox.y  ? -1 : 1
        
                    }})  
        
                    var pagename = subpages.name;
    
                    //
                    var html = codeTemp.main;
                    var looppages = new Promise((resolve, reject)=>{pages1.forEach((children,index,array)=>{
                        //
        
                        //
                        if(children.children?.length > 0){
                          var divchildren=``
                          testArr = [];
                          divchildren = "";
                          csspage = "";
                          renderFigmaGroups(children,html,divchildren,subpages).then((res)=>{
  
                            
                            //html = res;
                            //
                            //html = html.replace("content",testArr.join(","))
                            html = html.replace("content",res)
                            //
  
                            let obj = {}
                            obj.name = pagename;
                            obj.html = html
                            obj.css = csspage;
                            finalFigPages.push(obj)
                            //
                            if(index === pages1.length - 1)
                            resolve(html);
                          })
                        }else{
  
                        }
                        
                      // if(index === array.length - 1)
                      // resolve(html);
                      })        
                    })
                    looppages.then((res)=>{
                      //
                      if(index1 === array1.length - 1)
                      resolve();
  
                     })
                    
              })
            
              
         }) 
  
         loop.then(()=>{
            
          //add pages
          ;
          //
                      let onj = {}
                      var select = document.getElementById("displayPage")
                      select.options.length = 0;
                      let arr = []
                    
                      finalFigPages.forEach((x)=>{
                        if( editor?.Pages?.getAll().filter(x=>x.id==="x.name+".js).length===0){
                          editor?.Pages?.add({
                            id: x.name+".js",
                            // styles:x.css,
                            // component:x.html
                            styles:x.css,
                            component:x.html
        
                          })

                          select.add(new Option(x.name+".js",x.name+".js"));
                        }

                       
                        // let main = editor.Pages.getAll().filter(y=>y.id ===  x.name+".js")[0].getMainComponent();
                        // main.setStyle(x.css);
                        // //
                      })
                     
                      editor?.Pages?.select(finalFigPages[0]?.name+".js");
                      //)
                      setStartStoring(true)
                      setuseE(true)

            
            })
       })
  
        })
  
        return list1;
  
    }
    useEffect(()=>{
        //)
        if(finalFigPages.length>0 && startStoring===true && editor!== undefined){
          //)
          //.filter(x=>x.id==="sample.js"))
          if( editor?.Pages?.getAll().filter(x=>x.id==="sample.js").length>0)
            editor?.Pages?.remove("sample.js")
          //.filter(x=>x.id==="sample.js"))
          finalFigPages.forEach((x)=>{
            if( editor?.Pages?.getAll().filter(x=>x.id==="x.name+".js).length===0){
              editor?.Pages?.add({
                id: x.name+".js",
                // styles:x.css,
                // component:x.html
                styles:x.css,
                component:x.html

              })
              // select.add(new Option(x.name+".js",x.name+".js"));
            }
          })
          editor?.Pages?.select(finalFigPages[0]?.name+".js");
          setLoaderDiv("")
          editor?.Panels?.removeButton('gjs-pn-commands', 'loader');
          //)
        }
      },[editor?.Pages?.getAll()])
      //,editor?.Pages?.getAll())

    useEffect(() => {
        var editor = grapesjs.init({
            container: '#webBuilder',
            showOffsets: 1,
            noticeOnUnload: 0,
            keepUnusedStyles: true,
            allowScripts: 1,
            pageManager: {
                pages: [
                    {
                      id: 'sample.js',
                      name:'sample.js'
                    }
                 ]
            },
            styleManager: {
                sectors: [{
                    name: 'General',
                    open: false,
                    buildProps: ['float', 'display', 'position', 'top', 'right', 'left', 'bottom']
                }, {
                    name: 'Flex',
                    open: false,
                    buildProps: ['flex-direction', 'flex-wrap', 'justify-content', 'align-items', 'align-content', 'order', 'flex-basis', 'flex-grow', 'flex-shrink', 'align-self']
                }, {
                    name: 'Dimension',
                    open: false,
                    buildProps: ['width', 'height', 'max-width', 'min-height', 'margin', 'padding'],
                }, {
                    name: 'Typography',
                    open: false,
                    buildProps: ['font-family', 'font-size', 'font-weight', 'letter-spacing', 'color', 'line-height', 'text-shadow'],
                }, {
                    name: 'Decorations',
                    open: false,
                    buildProps: ['border-radius-c', 'background-color', 'border-radius', 'border', 'box-shadow', 'background'],
                }, {
                    name: 'Extra',
                    open: false,
                    buildProps: ['transition', 'perspective', 'transform'],
                }
                ],
            },
            width: '100%',
            plugins: [myPlugin, webPlugin, gjsForms, gjsNavbar],
            dragMode: 'translate',
            canvas: {
              scripts: ['https://code.jquery.com/jquery-3.5.1.js', 'https://cdn.datatables.net/1.13.4/js/jquery.dataTables.min.js', 'https://cdn.datatables.net/1.13.4/js/dataTables.jqueryui.min.js', 'https://cdn.datatables.net/scroller/2.1.1/js/dataTables.scroller.min.js'],
              styles: ['https://code.jquery.com/ui/1.13.2/themes/base/jquery-ui.css', 'https://cdn.datatables.net/1.13.4/css/dataTables.jqueryui.min.css', 'https://cdn.datatables.net/scroller/2.1.1/css/scroller.jqueryui.min.css'],
            },
            storageManager: {
                id: 'gjs-',
                type: 'remote',
                autosave: true,
                storeComponents: true,
                storeStyles: true,
                storeHtml: true,
                storeCss: true,
                storeAssets: true,
                options: {
                    remote: {
                        // Enrich the store call
                        urlStore: ``,
                        urlLoad: ``,
                        onStore: (data, editor) => {
                            //
                            const allPages = editor.Pages.getAll();
                            //)
                            newpages.length = 0;
                            let data1 = {
                                _id: projid,
                                screencomponents: allPages.map((p, index) => {
                                    //
                                    p.attributes.type === "main" ? p.id = "sample.js" : p.id = p.id;
                                    newpages.push(p.id)
                                    //
                                    let component = p.getMainComponent();
                                    let appendData = editor.getHtml({ component }) + modalData;
                                    //
                                    const css = editor.CodeManager.getCode(component, 'css', {cssc: editor.CssComposer});
                                    //
                                    return {
                                        scid: uuid(),
                                        filename: p.id,
                                        filepath: "src/" + p.id,
                                        html : compress(appendData),
                                        css: index===allPages.length-1?compress(editor.getCss({ component })) : ""
                                    }
                                })
                            }
                            //
                            dispatch(putUserProjectData({data:data1,instance:instance})).then((res) => {
                              //
                            })
                        },
                        // onLoad: result => result.data,
                    }
                },
            },
            pluginsOpts: {
                myPlugin: {
                },
                webPlugin: {},
                gjsForms: {}
            }

        })
        //) 
        var pageDefaultt = editor?.Pages.getAll()
        var pgeNeww = pageDefaultt[1]
        //
        setEditor(editor);
        editor.Panels.removeButton('devices-c', 'set-device-desktop');
        editor.Panels.removeButton('devices-c', 'set-device-tablet');
        editor.Panels.removeButton('devices-c', 'set-device-mobile');
        editor.Devices.select('mobilePortrait');
        editor.on('load', () => {
            let panelBtn = editor.Panels.getButton('devices-c', 'set-device-mobile');
            panelBtn.set('active', 1);
        })
        //)
        const canvas = editor.Canvas;
canvas.setCoords(0,0);
        addComponents({editor: editor, handleModal: handleModal, appendModalData: appendModalData, projectData: ProjectData, datasource: datasource,dispatch:dispatch});
        Panels({editor:editor, projid:projid, codecommit:codecommit,
          newpages:newpages, dispatch:dispatch, pages:props.pages, 
          currentPage: currentPage, setCurrentPage: setCurrentPage,loaderdiv:loaderdiv, handleShow:handleShow,handleShowPage:handleShowPage    
         });  
        const domc = editor.DomComponents;
        domc.addType('map', {
            // You can update the isComponent logic or leave the one from `some-component`
            // isComponent: (el) => false,
            // Update the model, if you need
            model: {
                // The `defaults` property is handled differently
                // and will be merged with the old `defaults`
                defaults: {
                    tagName: 'iframe', // Overrides the old one
                    someNewProp: '',
                    type: 'map',
                    src: '',
                    void: false,
                    mapUrl: 'https://maps.google.com/maps',
                    tagName: 'iframe',
                    mapType: 'q',
                    address: s,
                    zoom: '5',
                    attributes: { frameborder: 0 },
                },

            },
            // Update the view, if you need
            view: {
                // ComponentMapView
            },
        });
            //Add type for form
    editor.DomComponents.addType("testlabel", {
        model: {
          defaults: {
            // other model properties
  
            toolbar: [
              {
                attributes: { class: "fa fa-plus" },
                command: "frm-plus",
              },
              {
                attributes: { class: "fa fa-clone" },
                command: "frm-clone",
              },
              {
                attributes: { class: "fa fa-arrow-up" },
                command: "frm-arrowUp",
              },
              {
                attributes: { class: "fa fa-trash-o" },
                command: "tlb-delete",
              },
            ],
          },
        },
      });
  
      //append li to ul for datasource popup
      editor.Commands.add("frm-plus", {
        run(editor) {
          ;
          //  //)
          //?.replaceAll(',', ''))
  
          //);
  
          editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${lists.toString().replaceAll(",", "")}</ul></div>`);
          editor.Css.setRule('.tableWrapper ul', {
            'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
            'list-style': 'none',
            'font-size': '16px',
            'border-radius': '5px',
            'height': '200px',
            'overflow-y': 'auto'
          })
        },
      });
      // Adding style to table and popover
      editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
    editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
    editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })
  
      //Render form onclick of popup datasource
      editor.DomComponents.addType("PopUpli", {
        isComponent: (el) => el.tagName === "LI",
        model: {
          defaults: {
            tagName: "li",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            'click': e => {
              editor.Css.setRule('.tableWrapper ul', {
                'display': 'none', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
                'list-style': 'none',
                'font-size': '16px',
                'border-radius': '5px',
                'height': '200px',
                'overflow-y': 'auto'
              })
              //);
              dropdownKeys = [];
              getPath = e.target.getAttribute("data-attr");
              let getVerb = e.target.getAttribute("data-verb");
              //
  
              if (getVerb === "Post") {
                const res = axios(getPath)
                  .then((res) => {
                    //
                    setRes = res.data;
                    mainResList = [{ setRes }];
  
                    for (var key in mainResList[0]?.setRes[0]) {
                      if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                        //
                        dropdownKeys.push(key);
                        //
                      }
                    }
                  })
                  .then((res) => {
                    var returnVar = "";
                    dropdownKeys
                      .filter((x) => !x.includes("id"))
                      .forEach((key) => {
                        //
                        var temp = uuid();
  
                        var obj = { id: temp, key: key };
                        inputArray.push(obj);
                        //
                        returnVar += `<div class="mb-3 col-lg-12">
                        <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                        <div class="d-flex align-items-center" data-gjs-type="defaultPostControl" style="display:flex;flex-direction:row;" id=${key}>
                          <input type='text' placeholder=${key} id=${temp} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key}  ></input>
                          <div data-gjs-type="editBtn" style="margin-left:15px;"  ><button data-gjs-type="buttonEdit">Edit</button></div>
                        </div>
                      </div>`;
                      });
                    //
                    var mainelem;
                    if (returnVar !== undefined) {
                      mainelem = `<div >
                ${returnVar}
                
                  <button type='button' data-gjs-type="PostsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>
               
                  </div>`;
                    }
                    // <button type='button' data-gjs-type="CancelBtn" style="margin-left:15px" className="btn btn-secondary btn_custom_secondary">Cancel</button>
  
                    //", editor.getSelected());
  
                    var parents = editor.getSelected().parents();
                    //
                    parents[2].components(mainelem);
                  });
              } else {
                const res = axios
                  .get(getPath)
                  .then((res) => {
                    //
                    Responsee.push(res.data);
                    //
  
                    for (var key in Responsee[0]) {
                      if (Responsee[0]?.hasOwnProperty(key)) {
                        //
                        dropdownKeys.push(key);
                        //
                      }
                    }
                  })
                  .then((res) => {
                    var returnVar = "";
                    {
                      Object.entries(Responsee[0])
                        .filter((x) => !x.includes("id"))
                        .map(([key, value]) => {
                          var temp = uuid();
                          var obj = { id: temp, key: key, value: value };
                          inputArray.push(obj);
                          returnVar += `<div class="mb-3 col-lg-12">
                                          <label for="exampleFormControlInput1"  class="form-label" style="margin-top:15px">${key}</label>
                                          <div class="d-flex align-items-center" style="display:flex;flex-direction:row;" id=${key}>
                                              <input type='text' placeholder=${key} id=${temp} data-name=${key} class="form-control flex-fill me-3" style="flex:1;margin-bottom:15px" name=${key} value=${value} ></input>                        
                                           <div data-gjs-type="editBtn" style="margin-left:15px;"> <button >Edit</button></div>
                                          </div>                  
                                      </div>`;
                        });
                    }
                    var mainelem;
                    if (returnVar !== undefined) {
                      mainelem = `<div >
                ${returnVar}
                
                  <button type='button' data-gjs-type="PutsubmitBtn" className="btn btn-primary btn_custom_primary" style="margin-top:15px">Submit</button>
               
                  </div>`;
                    }
                    //
                    var parents = editor.getSelected().parents();
                    //
                    parents[2].components(mainelem);
                  });
              }
            },
          },
        },
      });
  
      //Open insertcontrols popup onclick of Edit button
      editor.DomComponents.addType("editBtn", {
        isComponent: (el) => el.tagName === "div",
        model: {
          defaults: {
            tagName: "div",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
              //);
              editor.Css.setRule(".tableWrapper ul", {
                display: "none",
                padding: "10px",
                margin: "0",
                position: "absolute",
                top: 0,
                right: "100px",
                bottom: 0,
                "background-color": "#1e1e1e",
                color: "#fff",
                "list-style": "none",
                "font-size": "16px",
                "border-radius": "5px",
                height: "200px",
                "overflow-y": "auto",
              });
              var idd = uuid();
              //);
              //);
  
              var dropDownList = (
                <div class="tableWrapper">
                  <ul>
                    {insertControlsData.map((item, index) => (
                      <li
                        data-gjs-type="DropLi"
                        data-attr={item.name}
                        data-id={idd}
                      >
                        {item.name}
                      </li>
                    ))}
                  </ul>
                </div>
              );
              editor.Css.setRule(".tableWrapper ul", {
                display: "block",
                padding: "10px",
                margin: "0",
                position: "absolute",
                top: 0,
                right: "100px",
                bottom: 0,
                "background-color": "#1e1e1e",
                color: "#fff",
                "list-style": "none",
                "font-size": "16px",
                "border-radius": "5px",
                height: "200px",
                "overflow-y": "auto",
              });
  
              //.find("#" + e.target.id)[0]
             // );
              var editList = editor.getWrapper().find("#" + e.target.id)[0];
              editList.append(dropDownList);
              //.parents());
              //   editor.getSelected().components(dropDownList)
              //  .components(dropDownList))
              // )
            },
          },
        },
      });
  
      //change input control from insertcontrols popup
      editor.DomComponents.addType("DropLi", {
        isComponent: (el) => el.tagName === "li",
        model: {
          defaults: {
            tagName: "li",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
              //);
              //);
              ID = e.target.getAttribute("data-id");
              let getID = e.target.getAttribute("data-attr");
  
              if (getID === "Button") {
                var newelem = (
                  <div class="mb-3 col-lg-12">
                    <div
                      class="d-flex align-items-center"
                      style="display:flex;flex-direction:row;"
                    >
                      <button className="btn btn-primary" id={ID}>
                        Primary
                      </button>
                      <div data-gjs-type="editBtn" style="margin-left:15px">
                        <button>Edit</button>
                      </div>
                    </div>
                  </div>
                );
              } else if (getID == "Text Input") {
                var newelem = (
                  <div class="mb-3 col-lg-12">
                    <div
                      class="d-flex align-items-center"
                      data-gjs-type="inputChange"
                      style="display:flex;flex-direction:row;"
                    >
                      <input
                        type="text"
                        id={ID}
                        style="flex:1;margin-bottom:15px"
                        class="form-control flex-fill me-3"
                        name="abc"
                        placeholder="text input"
                      ></input>
                      <div data-gjs-type="editBtn" style="margin-left:15px;">
                        <button>Edit</button>
                      </div>
                    </div>
                  </div>
                );
              } else if (getID == "Drop down") {
                var newelem = `<div style="flex:1">
                <div style="display:flex;flex-direction:row;">        
                  <select class="form-select mt-3" id=${ID} data-gjs-type="selectOptins" style="flex:1;margin-bottom:15px"  >
                  <option selected>Select any option</option>
                  </select>
                  <div data-gjs-type="FirstDropdownBtn" class="fa fa-edit">+</div>
                  <div data-gjs-type="editBtn" style="margin-left:15px" ><button>Edit</button></div>
                </div>
              </div>`;
              } else if (getID == "Date Picker") {
                var newelem = (
                  <div class="mb-3 col-lg-12">
                    <div
                      class="d-flex align-items-center"
                      style="display:flex;flex-direction:row;"
                    >
                      <input
                        id={ID}
                        type="date"
                        class="form-control flex-fill me-3"
                        name="abcd"
                        style="flex:1;margin-bottom:15px"
                        placeholder="date picker"
                      />
                      <div data-gjs-type="editBtn" style="margin-left:15px;">
                        <button>Edit</button>
                      </div>
                    </div>
                  </div>
                );
              } else if (getID == "Check Box") {
                var newelem = (
                  <div class="mb-3 col-lg-12">
                    <div
                      class="d-flex align-items-center"
                      style="display:flex;flex-direction:row;"
                    >
                      <input
                        class="form-check-input"
                        type="checkbox"
                        id={ID}
                        placeholder="checkbox"
                        name="checkbox"
                        style={{ height: "1.5rem", width: "1.5rem" }}
                      ></input>
                      <div data-gjs-type="editBtn" style="margin-left:15px;">
                        <button>Edit</button>
                      </div>
                    </div>
                  </div>
                );
              }
  
              //.parents());
  
              var parents = editor.getSelected().parents();
              parents[1].remove();
              parents[2].append(newelem, { at: 1 });
            },
          },
        },
      });
      editor.onReady(() => {
        jQuery = document.querySelector('iframe').contentWindow.$;
      });
      //on click of plus button in dropdown open popup
      editor.DomComponents.addType("FirstDropdownBtn", {
        isComponent: (el) => el.tagName === "div",
        model: {
          defaults: {
            tagName: "div",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
              //.find("#" + e.target.id)[0]
              //);
              var droplists = editor
                .getSelected()
                .append(
                  `<div class="tableWrapper"><ul  id="dropdownList">${GetDatasourceList.toString().replaceAll(
                    ",",
                    ""
                  )}</ul></div>`
                );
              editor.Css.setRule(".tableWrapper ul", {
                display: "block",
                padding: "10px",
                margin: "0",
                position: "absolute",
                top: 0,
                right: "100px",
                bottom: 0,
                "background-color": "#1e1e1e",
                color: "#fff",
                "list-style": "none",
                "font-size": "16px",
                "border-radius": "5px",
                height: "200px",
                "overflow-y": "auto",
              });
              //.parents());
              var editList = editor.getWrapper().find("#" + e.target.id)[0];
              editList.append(droplists);
            },
          },
        },
      });
  
      //onclick of get datasource popup li show 1st select dropdown
      editor.DomComponents.addType("DropdownGetPopup", {
        isComponent: (el) => el.tagName === "LI",
        model: {
          defaults: {
            tagName: "li",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
              //
              dropdownKeys = [];
              var dataUrl = e.target.getAttribute("data-attr");
              //
              const res = axios(dataUrl).then((res) => {
                //
                setRes = res.data;
                mainResList = [{ setRes }];
                //
  
                for (var keyss in mainResList[0]?.setRes[0]) {
                  if (mainResList[0]?.setRes[0]?.hasOwnProperty(keyss)) {
                    //
                    dropdownKeys.push(keyss);
                    //
                  }
                }
  
                var newelem = `<div style="flex:1">
        <div style="display:flex;flex-direction:row;">        
          <select class="form-select mt-3"  data-gjs-type="AttributesList" style="flex:1;margin-bottom:15px;margin-right:15px"  >
          <option selected >Select any attribute</option>
          </select>
        </div>
        
            </div>`;
  
                //.parents());
                var parentss = editor.getSelected().parents();
                parentss[3].components(newelem);
              });
            },
          },
        },
      });
  
      //onclick of 1st select bind attributes in dropdown
      editor.DomComponents.addType("AttributesList", {
        isComponent: (el) => el.tagName === "div",
        model: {
          defaults: {
            tagName: "div",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              //);
              dropData = [];
              //.find("#" + e.target.id)[0]
              // );
              //
              var optionList = editor.getWrapper().find("#" + e.target.id)[0];
              optionList.append(
                dropdownKeys.map(
                  (item, index) =>
                    `<option data-gjs-type="AttributeValues">${item}</option>
              `
                )
              );
  
              dropdownKeys = [];
              //
              var attributeVal = [];
              attributeVal = e.target.value;
              //
              setRes.map((key) => {
                dropData.push({
                  selectedAttribute: key[attributeVal],
                  id: key.id,
                });
                //
              });
  
              //.parent().index());
              secondSelect = `<div style="flex:1">
         <div style="display:flex;flex-direction:row;">        
           <select class="form-select mt-3" data-gjs-type="AttributesValues"  style="flex:1;margin-bottom:15px"  >
           <option selected >Select any Value</option>
           </select>
         </div>
         </div>`;
              // //.parent().getChildAt(1)
              // );
  
              //    var childElem = editor.getSelected().parent().getChildAt(1)
              //  if(Object.values(childElem).length > 0){
              //   childElem.remove()
              //  }
  
              editor.getSelected().parent().append(secondSelect, { at: 1 });
            },
          },
        },
      });
  
      //Bind attribute values in 2nd dropdown
      editor.DomComponents.addType("AttributesValues", {
        isComponent: (el) => el.tagName === "div",
        model: {
          defaults: {
            tagName: "div",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
  
              //
              var optionList = editor.getWrapper().find("#" + e.target.id)[0];
              optionList.append(
                dropData.map(
                  (item, index) =>
                    `<option>${item.selectedAttribute}</option>
            `
                )
              );
            },
          },
        },
      });
  
      //Post call on click of submit button
      editor.DomComponents.addType("PostsubmitBtn", {
        isComponent: (el) => el.tagName === "button",
        model: {
          defaults: {
            tagName: "button",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
              postDataList = {};
              //
  
              //
              var tempp = e.target.parentElement;
              var tagnamee = tempp.getElementsByTagName("input");
              //);
              var arrTag = Array.from(tagnamee);
              //
  
              arrTag &&
                arrTag.length > 0 &&
                arrTag?.map((element) => {
                  postDataList = {
                    ...postDataList,
                    //  [editor.DomComponents.getWrapper().find('#'+element.id)[0].attributes.attributes.name]
                    [element.name]: element.value,
                  };
                });
  
              //
  
              const res = axios
                .post(getPath, {
                  body: postDataList,
                })
                .then((res) => {
                  if (res.status === 201) {
                    alert("Form submitted successfully");
                    //
                    //.parents());
  
                    arrTag &&
                      arrTag.length > 0 &&
                      arrTag?.map((element) => {
                        element.value = "";
                      });
                  }
                });
            },
          },
        },
      });
  
      //Put call on click of submit button
      editor.DomComponents.addType("PutsubmitBtn", {
        isComponent: (el) => el.tagName === "button",
        model: {
          defaults: {
            tagName: "button",
            draggable: false,
            removable: false,
            droppable: false,
            resizable: false,
            copyable: false,
          },
        },
        view: {
          events: {
            click: (e) => {
              ;
              //
  
              //
              var tempp = e.target.parentElement;
              var tagnamee = tempp.getElementsByTagName("input");
  
              var arrTag = Array.from(tagnamee);
              //
  
              arrTag &&
                arrTag.length > 0 &&
                arrTag?.map((element) => {
                  putDataList = {
                    ...putDataList,
                    //  [editor.DomComponents.getWrapper().find('#'+element.id)[0].attributes.attributes.name]
                    [element.name]: element.value,
                  };
                });
              //
  
              const res = axios
                .put(getPath, {
                  body: putDataList,
                })
                .then((res) => {
                  //
                  if (res.status === 200) {
                    alert("Form updated successfully");
                  }
                });
            },
          },
        },
      });
      //Add type for figma div
editor.DomComponents.addType('figmalabel', {
  model: {
    defaults: {
      // other model properties
      
      toolbar: [{
        attributes: { class: 'fa fa-plus' },
        command: 'div-plus',
      },
      {
        attributes: { class: 'fa fa-clone' },
        command: 'frm-clone',
      },
      {
        attributes: { class: 'fa fa-arrow-up' },
        command: 'frm-arrowUp',
      },
      {
        attributes: { class: 'fa fa-trash-o' },
        command: 'tlb-delete',
      },
      ],
    }
  }
})

//open datasource popup on click of plus
editor.Commands.add('div-plus', {
  run(editor) {
    
    //  //)
    //

    //)

    editor.getSelected().append(`<div class="tableWrapper"><ul  id="dropdownList">${GetDatasourceList.toString().replaceAll(',', '')}</ul></div>`);
    editor.Css.setRule('.tableWrapper ul', {
      'display': 'block', 'padding': '10px', 'margin': '0', 'position': 'absolute', 'top': 0, 'right': '100px', 'bottom': 0, 'background-color': '#1e1e1e', 'color': '#fff',
      'list-style': 'none',
      'font-size': '16px',
      'border-radius': '5px',
      'height': '200px',
      'overflow-y': 'auto'
    })
  }
})
// Adding style to table and popover
editor.Css.setRule('.tableWrapper', { 'position': 'relative' })
editor.Css.setRule('.tableWrapper ul li', { 'list-style': 'none', 'padding': '5px 10px' })
editor.Css.setRule('.tableWrapper:after', { 'content': '', 'background-color': '#1e1e1e', 'top': '5px', 'bottom': 0, 'right': '96px', 'position': 'absolute', 'width': '10px', 'height': '10px', 'transform': 'rotate(45deg)' })

//onclick of image datasource render images
// editor.DomComponents.addType('ImagesGetPopup',{
//   isComponent: el => el.tagName === 'LI' ,
//   model:{
//     defaults:{
//       tagName: 'li',
//         draggable: false,
//         removable: false,
//         droppable: false,
//         resizable: false,
//         copyable: false,
//     }
//   },
//   view:{
//     events:{
//       'click':e=>{
//         
//         var tempArr=[]
//         
//         var imageURL = e.target.getAttribute('data-img-attr')
//         const res = axios.get(imageURL).then((res)=>{
//           
         
//           for(var i=0;i<4;i++){          
//                tempArr.push(res.data[i])
//             }        
//           
//         }).then((res)=>{
//           var renderList="";
//           // tempArr.map((item,index)=>{
//            renderList =    
//               `<div style="text-align: center;display:grid;
//               grid-template-columns: 200px 200px; ">
             
//               <div style=" width: 150px; height: 100px;float:left;
//               clear:none">
//                 <div><img src=${tempArr[0].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
//                 <div>${tempArr[0].title}</div>
//               </div>

//               <div style=" width: 150px; height: 100px;float:left;
//               clear:none">
//                 <div><img src=${tempArr[1].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
//                 <div>${tempArr[1].title}</div>
//               </div>

//               <div style=" width: 150px; height: 200px;float:left;
//               clear:none">
//                 <div><img src=${tempArr[2].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
//                 <div>${tempArr[2].title}</div>
//               </div>

//               <div style=" width: 150px; height: 200px;float:left;
//               clear:none">
//                 <div><img src=${tempArr[3].thumbnailUrl} style="display: inline-block;width:100px;height:80px"></img></div>
//                 <div>${tempArr[3].title}</div>
//               </div>

//               </div>`
//             // })
         
  
//             
//           var parentss = editor.getSelected().parents();
//           parentss[3].components(renderList)
//           
//         })
       

//       }
//     }
//   }

// })
}, [])
  document?.getElementById("displayPage")?.addEventListener("change", function (e) {
  //
  let getPage = e.target.value;
  let showPage = editor.Pages.get(getPage);
  setCurrentPage(showPage);
  editor.Pages.select(showPage);
  //
  //", editor.Pages.getSelected());
});


    useEffect(() => {
        if (modalData !== "") {
          pageComponent(editor);
        }
      }, [modalData]);  
      //) 

    function handleModal(data, display, getBtnId) {	
        setModalInfo(data);	
        setModalShow(display);	
        setModalBtnId(getBtnId);
      } 
    function appendModalData(mdata) {
        //
        modalData = mdata;
        setPopupInfo(mdata);
      }         

      const codecommit = () => {
        //e.preventDefault()
        socket = io.connect(url);
        ;
        setShowPopup(true); 
        socket.emit('join', {projid:data.replace("?_id=","")});
        //dispatch( postCodeGeneration(data.replace("?_id=","")) )       
      }
      const[useE,setuseE] = useState(false)
      useEffect(() => {
        //);
        // if(useE === false){
          var select = document.getElementById("displayPage");
          if(ProjectData&&ProjectData[0]?.projecttypeid?._id !== "637de439efe9bf2ab4b102c5" 
          && ProjectData&&ProjectData[0]?.projecttypeid?._id !== "664e17f9282522e42bc9485a" ){
            // navigate("/")
              // ?alert("This is not a Mobile project");
              //navigate("/webStudio?_id=" + ProjectData.id);  
          }
        //  else if (editor !== null && remoteData?.length > 0) {
  
        //     setCurrentPage(remoteData[0].filename);
        //     //);
        //     if (select !== null) {
        //       //
        //       //setStartStoring(true)
        //       editor.Pages.remove("sample.js");
        //       //);
        //       //);
        //       select.options.length = 0;
        //       remoteData.map((element, index) => {
        //         select.add(new Option(element.filename, element.filename));
        //       });
        //       remoteData?.forEach((x) => {
        //         //editor.CssComposer.getAll().reset();
        //         // //,
        //         //   "demooo ",
        //         //   editor.CssComposer.getAll()
        //         // );
        //         // //
        //         editor.Pages.add({
        //           id: x.filename,
        //           styles :x.css && x.css!==""?decompress(x.css):"",
        //           component: decompress(x.html),
        //         });
        //         let main = editor.Pages.getAll()
        //           .filter((y) => y.id === x.filename)[0]
        //           .getMainComponent();
        //         //main.setStyle(x.css);
        //         //
        //         //);
        //       });
        //       editor.Pages.select(remoteData[0].filename);
        //       setLoaderDiv("");
        //       editor?.Panels?.removeButton("gjs-pn-commands", "loader");
        //       let getbutton = editor.Panels.getButton('options', 'sw-visibility');
        //       getbutton.set('active', 1);
        //       // editor?.Pages?.getAll().map((p)=>{
        //       //   let component = p.getMainComponent();
        //       //   )
      
        //       // })
        //     }
        //   } 
          
          else if (
            figmatoken !== undefined &&
            figmafile !== undefined &&
            figmatoken !== "" &&
            figmafile !== ""
            &&
            useE===false
          ) {
            renderfigmaelements(figmatoken, figmafile).then(()=>{
              setuseE(true)
            })
          }
        else {
            //
            if (ProjectData !== undefined) {
              //
              setLoaderDiv("");
              editor?.Panels?.removeButton("gjs-pn-commands", "loader");
            }
          }
        
        // }
        
      }, [remoteData, figmatoken, figmafile, ProjectData, editor]);
      //);
      //[0],
      //   editor?.Pages?.getSelected()
      // );

      useEffect(() => {
        //
      }, [currentPage]);

    //

    function pageComponent(e) {
        //
        const allPages = e.Pages.getAll();
        // => x.attributes.type !== "main")
        // );
        newpages.length = 0;
        // if(allPages,allPages.filter(x=>x.attributes.type !== 'main').length>0)
        // allPages.shift()
        let data1 = {
          _id: projid,
          screencomponents: allPages.map((p, index) => {
            ;
            //
            p.attributes.type === "main" ? (p.id = "sample.js") : (p.id = p.id);
            newpages.push(p.id);
            //setNewPages([...newpages,p.id.replace(p.id.substring(0,p.id.indexOf("PROJ")),"").replace("PROJ","")])
            //
            let component = p.getMainComponent();
            let appendData = e.getHtml({ component }) + modalData;
            //
            //
            return {
              //_id:data.replace("?_id=",""),
              scid: uuid(),
              filename: p.id,
              filepath: "src/" + p.id,
              html: appendData,
              css: e.getCss({ component }),
            };
          }),
        };
        //
        dispatch(putUserProjectData({data:data1,instance:instance})).then((res) => {
          //
        });
      }
    return(

        <ToastProvider  placement="top-center" >

            <div class="d-flex h-100">

               

                <PlusMenuRight addToast={addToast} pages={pages} selectedPage={selectedPage} setselectedPage={setselectedPage} openToggle={openToggle} setOpenToggle={setOpenToggle} sidebarhide={sidebarhide} setSidebarhide={setSidebarhide}/>  

               

                <div id="webBuilder" class="mobileWebStudio" style={{'height':'auto !important' }}></div>  
        <CommitPopup
                  popupshow={popupshow}
                  setShowPopup={setShowPopup}
                  socket={socket}
                  pages={newpages}
                  status = {status}
              />  
          <ModalPopup 	
          show={modalShow}	
          onHide={() => setModalShow(false)}	
          modalInfo={modalInfo}	
          id = {modalBtnId}
       />  
            <ComponentPopup
      handleClose={handleClose}
      show={show}
      handleShow={handleShow}
      current={0}
      setCurrent={0}
      editor={editor}
       />
      <AddPagePopup
      handleClose={handleClosePage}
      show={showPage}
      handleShow={handleShowPage}      
      editor={editor}
      currentPage={currentPage}
      setCurrentPage= {setCurrentPage}
      dropDown={document.getElementById("displayPage")?.options}
       />
           </div>

           </ToastProvider>


    )

}



export default InsertControl;
import { createAsyncThunk } from "@reduxjs/toolkit";
import componentService from "../../services/componentService";

export const fetchComponentData = createAsyncThunk("componentstore/fetchComponentData", async(instance) => {
    
    
    const fetchData = async () => {
        return componentService.getComponentData(instance) 
    }
    try{
        const rawData = await fetchData();
        const nfrTypeData = [];
        for (const nfrtype of rawData) {
            const cardData = {
                
                id: nfrtype._id,
                componentname: nfrtype?.componentname,
                componentdesc:nfrtype?.componentdesc,
                componenthtml:nfrtype?.componenthtml,
                componentcss:nfrtype?.componentcss
                
            };
            nfrTypeData.push(cardData);
        }
        //
        return {
            component: nfrTypeData?.length !==0 ? nfrTypeData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }
  }
);


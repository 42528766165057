import {React, useEffect, useState, useId} from "react";
import { Link, useLocation } from "react-router-dom";
import ReactDOM from 'react-dom/client';
import {ReactComponent as Edit_Icon} from '../images/edit_icon.svg'
import { X, Plus } from 'react-bootstrap-icons';
import { ReactComponent as Closee_iconn } from '../images/closee_iconn.svg';
import { ReactComponent as Datasource } from '../images/d_source.svg';
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDataSource, fetchDropAreaData } from '../main/store/dropArea.actions';
import axios from "axios";
import { ReactComponent as DeleteIcon } from "../images/delete-icon.svg";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";



var TempArr = [] , mainResList = [], dropdownKeys = [],setRes = []
var sI , getID , newelem , popid
let idCounter = 0;

const getId = () => {
    idCounter++;

    return idCounter.toString();
};
function DatasourceEntryForm(props) {

    const location = useLocation();
    const dispatch = useDispatch();
    const {instance, accounts, inProgress} = useMsal();
    var elements = location.state.dropdownKeys

    const [open, setOpen] = useState(false);
    let [children, setChildren] = useState([]);
    const [mainRes, setMainRes] = useState("")
    const [IDs,setIDs]=useState("")

    //fetch data source data
const datasource = useSelector((state) => state.commonReducer?.droparea?.datasource?.datasource);
//

    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        if(datasource !== undefined)
        dispatch(fetchAllDataSource({data:null, instance:instance}))
        }
    }, [dispatch, instance.getAllAccounts()[0] !== undefined])


    //input controls
const [insertControlsData, setInsertControlsData] = useState([
    {
        "id": 1, 
        "name": "Button"
    },
    
    {
        "id": 2 , 
        "name": "Text Input"
    },
   
    {
        "id": 3, 
        "name": "Drop down"
    },
    
    {
        "id": 4, 
        "name": "Date Picker"
    },
   
    {
        "id": 5, 
        "name": "Check Box"
    }
   
]);

//open popup on dropdown
const handlepopover = (popid) => {
    
    //
    document.querySelector("#" + popid).hidden = false;
    setOpen(true)
    //

}

const handleClosePopup = (popid) => {
    
    //setCurrentGridID(grdid)
    document.querySelector("#" + popid).hidden = true;

}
//onclick of input boxes
function handleInput(e,index){
    const obj = [e.target.value]
   TempArr.push(...obj)
   // 
}

const addData=(key,url,popid,index)=>{
    
    dropdownKeys = []
   // 
    const res = axios(url).then((res)=>{
        // 
        setRes = res.data
        mainResList = [{setRes}]
        //  

        for (var keyss in mainResList[0]?.setRes[0]){
            if (mainResList[0]?.setRes[0]?.hasOwnProperty(keyss)) {
                //
                dropdownKeys.push(keyss)
                // setGetApiAttributes([...getApiAttributes,key])
                //
            }  
        }

        var newelem = (<div id={key}>
 <select  aria-label="Default select example"  >
            <option selected>Select any option</option>
            {dropdownKeys.map((item)=>(
            <option value="1">{item}</option>
            ))}
        </select>
        <Plus  size={20} onClick={()=>{handlepopover(popid)}} className='react_icon_vsmall' />
        <DeleteIcon size={20} onClick={()=>{handleDelete(key)}} ></DeleteIcon>
        <div id={popid} class="innerpopup_open" hidden={true}>
        <div class="d-flex align-items-start flex-row">
                                <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" />
                            </div>
        <ul>
                                {datasource && datasource.filter(x=>x.httpverb.toLowerCase()=="get").map((data, index) => {
                                    return (

                                        <li className='font_14 font_regular' onClick={() => {addData(key, data.url, popid, data.index) }}>
                                            <Datasource className='me-2' />{data.datasourcename}
                                        </li>

                                    )

                                })}
                            </ul>
        </div>
        </div>

        )
        const root = ReactDOM.createRoot(document.getElementById(key))
        root.render(newelem)
        document.querySelector("#" + popid).hidden = true;

    })

}

function handleDelete(key,popid){
    
    var newelem = (<div id={key}>
        <select  aria-label="Default select example"  >
            <option selected>Dropdown Menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
        </select>
        <Plus  size={20} onClick={()=>{handlepopover(key,popid)}} className='react_icon_vsmall' />
            
        </div>
    )  
    const root = ReactDOM.createRoot(document.getElementById(key))
    root.render(newelem) 
}

function renderInput(key){
    
 sI = document.querySelector('#dropdownId'+key).selectedIndex;
  getID = document.querySelector('#dropdownId'+key).options[sI].value
//  
 const root = ReactDOM.createRoot(document.getElementById(key));

 if(getID == "Button"){
    
    var newelem = (<div id={key}>
        <button 
            className= "btn btn-primary"
            id= "1"
        >
            Primary
        </button>
        </div>
    );
    document.querySelector("#dropdownId"+key).hidden=true 
    
}
else if(getID == "Text Input" ){
    
    var newelem = (<div id={key}>
        <input 
            id= "2" 
            type= "text" 
            // class= "form-control" 
            name= "{item.id}" 
            placeholder= "Text Input" >
        </input>
        </div>
    )  
    document.querySelector("#dropdownId"+key).hidden=true
}
else if(getID == "Drop down" ){
    
    var idnum = getId();
    var open = false
     popid = "pop" + idnum

     newelem = (<div id={key}>
        <select  aria-label="Default select example"  >
            <option selected>Dropdown Menu</option>
            <option value="1">One</option>
            <option value="2">Two</option>
            <option value="3">Three</option>
        </select>
        <Plus  size={20} onClick={()=>{handlepopover(popid)}} className='react_icon_vsmall' />
        <div id={popid} class="innerpopup_open" hidden={true}>
        <div class="d-flex align-items-start flex-row">
                                <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" />
                            </div>
        <ul>
                                {datasource && datasource.filter(x=>x.httpverb.toLowerCase()=="get").map((data, index) => {
                                    return (

                                        <li className='font_14 font_regular' onClick={() => {addData(key, data.url, popid, data.index) }}>
                                            <Datasource className='me-2' />{data.datasourcename}
                                        </li>

                                    )

                                })}
                            </ul>
        </div>
        </div>
        
    )
    document.querySelector("#dropdownId"+key).hidden=true
}
else if(getID == "Date Picker" ){
    var newelem = (<div id={key}>
        <input 
            type="date" 
            // class="form-control" 
            name="date-field"  
            
        />
        </div>
    )
    document.querySelector("#dropdownId"+key).hidden=true
}
else if(getID == "Check Box" ){
    var newelem = (<div id={key}>
        <div class="form-check">
            <input 
                // class="form-check-input" 
                type="checkbox" 
                value="" 
                id="defaultCheck1"  
               
                style={{height:"1.5rem", width:"1.5rem"}}
            >
            </input>
           
      </div>
      </div>
    )
    document.querySelector("#dropdownId"+key).hidden=true
}

root.render(newelem)
//  document.querySelector("#input"+key).hidden=true;
//    )
}

function showDropdown(key){
    
    document.querySelector("#dropdownId"+key).hidden=false
    }

   
return(
    <>
    {elements.map((key)=>{
        return (
            <>
            
            <div>
            <label name="labels" >{key}</label>
            <div id={key}>
            <input type='text' placeholder={key} name="values"  onChange={(e)=>{handleInput(e,key)}}></input>
            </div>
            <Edit_Icon onClick={()=>{showDropdown(key)}} ></Edit_Icon> 
            <select id={"dropdownId"+key }onChange={()=>{renderInput(key)}} hidden={true}>
                    <option>Change input field</option>
            {insertControlsData.map((item)=>(
                        <option>{item.name}</option>
    ))}
                </select>
            </div>
            
            </>
        )
    })}
    <button>Submit</button>
    </>
)
}

export default DatasourceEntryForm
import { createSlice } from "@reduxjs/toolkit";
import { fetchThemeData,fetchThemeGridData,fetchEditThemeData } from "./theme.actions"

const ThemeTypeData = createSlice({
    name: "themestore",
    initialState: {
        themeTypeData: { themedata: [], loading: true},
        gridTheme: { themegrid:[],loading: true, themegridColumnData:[]},
        editTheme: { themeedit:[],loading: true},
        currentthemeTypeData : {
            themetype: {
                id: "",
                name:"",
            },
        },
        currentthemeTypeDataCopy: {
            themetype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditthemeTypeDataName: (state, actions) => {
            state.currentnfrTypeData.nfrtype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchThemeData.pending]: (state) => {
            state.themeTypeData.loading = false;
        },
        [fetchThemeData.fulfilled]: (state,actions) => {
            state.themeTypeData.themedata = actions.payload?.themedata;
            state.themeTypeData.loading = actions.payload?.loading;
        },
        [fetchThemeGridData.pending]: (state) => {
            state.gridTheme.loading = false;
        },
        [fetchThemeGridData.fulfilled]: (state,actions) => {
            
            state.gridTheme.themegrid = actions.payload?.themegrid;
            state.gridTheme.themegridColumnData = actions.payload?.themegridColumnData;
            state.gridTheme.loading = actions.payload?.loading;
        },
        [fetchEditThemeData.pending]: (state) => {
            state.gridTheme.loading = false;
        },
        [fetchEditThemeData.fulfilled]: (state,actions) => {
           // 
            state.editTheme.themeedit = actions.payload?.themeedit;
            state.editTheme.loading = actions.payload?.loading;
        },
    },
});

export const { setEditthemeTypeDataName } = ThemeTypeData.actions;
export default ThemeTypeData.reducer;
import React, { memo, useEffect, useState } from 'react';
import { ReactComponent as GreenPlus_icon } from '../../images/greenplus_icon.svg';
import { ReactComponent as External_link } from '../../images/external_link.svg';
import { ReactComponent as Play_icon } from '../../images/play_icon.svg';
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { loginRequest } from "../../../authConfig";
import { Nav } from "react-bootstrap";
import { ReactComponent as Components_icon } from '../../images/components_icon.svg';
import ComponentScreen from './ComponentScreen';
import ComponentStudio from './ComponentStudio';
const { REACT_APP_MARKETPLACE_URL } = process.env;

var url = process.env.REACT_APP_BASE_URL;
var socket = undefined;
let count = 0;

let methodcalled = false
function Component() {
    const { instance, accounts, inProgress } = useMsal();
    const name = accounts[0] && accounts[0].username;
    const [openStudio, setStudioOpen] = useState(false);

    const dispatch = useDispatch();
    const navigate = useNavigate();
    const [show, setShow] = useState(false);  // modal popup but not being used
    const handleShow = () => setShow(true);
    const [reset, setReset] = useState(0);
   // 
    function handleClick() {
        setStudioOpen(false)
    }
    return (
        <>
                {!openStudio ? <ComponentScreen setStudioOpen={setStudioOpen}/>
                 :
                 <div class="d-flex h-100">
                 <ComponentStudio />
                 </div>
                }
        </>
    )
}

export default Component;

//  // onClick={() => { handleShow(); setReset(1) }}
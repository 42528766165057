import {useState, useEffect} from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Dropdown from 'react-bootstrap/Dropdown';
import DropdownButton from 'react-bootstrap/DropdownButton';
import Form from 'react-bootstrap/Form';
import { ReactComponent as Home_logo} from './images/home_logo.svg';
import { FaRegUserCircle } from 'react-icons/fa';
import { ReactComponent as Three_dots_vertical } from './images/three_dots_vertical.svg';
import { ReactComponent as Bell_icon } from './images/bell_icon.svg';
import { ReactComponent as Settings_icon } from './images/settings_icon.svg';
import { ReactComponent as User_icon } from './images/user_icon_static.svg'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link ,Outlet} from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-common';
import {loginRequest} from "../authConfig";
import Axios from 'axios';
import {useDispatch, useSelector} from "react-redux";
import {Person} from "react-bootstrap-icons";
import { getAllProjectsGridData } from "./main/store/userProject.actions";
import { getProjectDetails } from "./main/store/userProject.actions";
import { fetchNfrTypeData } from "./main/store/nfr.actions";
import {useLocation} from "react-router-dom";
import { ReactComponent as Info_icon } from './images/info_icon.svg';
import Modal from 'react-bootstrap/Modal';
import Myapps from '../../src/App/images/myapps.png';
import Tooltip from "@material-ui/core/Tooltip";


export const theme = [
  {color: '#D50000', backgroundColor: '#FFEBEE'},
  {color: '#C51162', backgroundColor: '#FCE4EC'},
  {color: '#AA00FF', backgroundColor: '#F3E5F5'},
  {color: '#6200EA', backgroundColor: '#EDE7F6'},
  {color: '#304FFE', backgroundColor: '#E8EAF6'},
  {color: '#2962FF', backgroundColor: '#E3F2FD'},
  {color: '#0091EA', backgroundColor: '#E1F5FE'},
  {color: '#00B8D4', backgroundColor: '#E0F7FA'},
  {color: '#00BFA5', backgroundColor: '#E0F2F1'},
  {color: '#FFAB00', backgroundColor: '#FFF8E1'},
]

function NavbarHome() {
  const dispatch = useDispatch();
  const location = useLocation();
  const [isActive,setActive]=useState("");
  const [imageUrl, setImageUrl] = useState(null)

  // Azure B2C

  const {instance, accounts, inProgress} = useMsal();
  const isAuthAuthenticated =  useIsAuthenticated();
  const [accessToken, setAccessToken] = useState(null);
  const name = accounts[0] && accounts[0].name;
  const userFirstName = name && name.charAt(0);
  const userLastName = name && name.substring(name.lastIndexOf(' ')+1).charAt(0);
  const randomColor = name && name.split(' ').join('').length%10;
  const useremail = accounts[0] && accounts[0].username;
  const homeAccountID = accounts[0] && accounts[0].homeAccountId;
  const userDomain = useremail?.substring(useremail.indexOf("@")+1,useremail.indexOf(".com"))
  
  if(useremail!==undefined&&!useremail?.endsWith("@dentel.ai") )
  {
    handleLogout(instance)
  }
  useEffect(() => {
    
    if(!isAuthAuthenticated) {
      const request = {
        scopes: loginRequest.scopes,
        account: useremail,
      };      
    }
  }, [])

  useEffect(() => {
    if (accounts.length > 0) {
      RequestAccessToken()
      .then((res)=>{
        setAccessToken(res);
      })
      .catch(err=>{
        //
      })
    }    
    if(accessToken !== null) {
      let api = process.env.REACT_APP_BASE_URL + "/auth/microsoft/callback?access_token="+accessToken.replace(/^Bearer\s/, '')
      Axios.get(api).then(o => {
      localStorage.setItem("apiJWTToken",o.data.jwt)
    })

    Axios.get('https://graph.microsoft.com/v1.0/me/photo/$value', {
      headers: { 'Authorization': accessToken },
      responseType: 'blob',
    }).then(o => {
      const blobUrl = URL.createObjectURL(o.data);
      setImageUrl(blobUrl)
    })
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch( getAllProjectsGridData ({name:useremail,instance:instance,"dispatch":dispatch}))
    }
  }
  }, [accessToken, accounts,instance.getAllAccounts()[0] !== undefined])


  localStorage.setItem("userDomain", userDomain);

  


//
  function handleLogin(instance) {
    instance.loginRedirect(loginRequest).catch(e => {
     console.error("checkerror",e);
    })
  }  

  function handleLogout(instance) {
    localStorage.setItem("apiJWTToken","")
    localStorage.setItem('myprofilepopup',"")
    localStorage.setItem('myProfileLoggedInID',"")
    const currentAccount = instance.getAccountByHomeId(homeAccountID);
    const logoutHint = currentAccount.idTokenClaims.login_hint;
    instance.logoutRedirect({logoutHint: logoutHint}).catch(e => {
     // console.error(e);
    });
  }

  async function RequestAccessToken() {
    const request = {
      scopes: loginRequest.scopes,
      account: accounts[0],
    };
    // Silently acquires an access token which is then attached to a request for Microsoft Graph data
    const accessTokenResponse = await instance.acquireTokenSilent(request);
    return `Bearer ${accessTokenResponse.accessToken}`;
  }  
  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
 
  var  id= location.search.includes("?_id")?location.search.replace("?_id=",""):"";
  
  useEffect(() => { 
    if(instance.getAllAccounts()[0] !== undefined)
    {
    dispatch(getProjectDetails({data:id,instance:instance}))
    }
  }, [id,instance.getAllAccounts()[0] !== undefined])
  
  let infoicon = <></>;
  if( location.pathname === "/webStudio" ||
      location.pathname === "/explorer"
    )
  {
    infoicon  =  (<>
    <Tooltip title="Project Details">
    <Info_icon onClick={() =>{
      handleShow();
      if(instance.getAllAccounts()[0] !== undefined){
      dispatch(getProjectDetails({data:id,instance:instance}))
      }
      }}
       className='react_icon_size_fill me-3' />
    </Tooltip>
    </>)
  }

  var type="";
  
  useEffect(() => { 
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch(getProjectDetails({data:id,instance:instance}))
    }
  }, [id,instance.getAllAccounts()[0] !== undefined])
  
  
  //fetching project types
  //=>state.commonReducer))
  const ProjectData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails);
  const [prodtypedata, setContentData] = useState(ProjectData);
  
  useEffect(() => { if(prodtypedata===undefined){
    setContentData([])
  }else
    setContentData(ProjectData);
   }, [dispatch, ProjectData]);
  
  // 
  // 
  
  //setType(prodtypedata[0]?.ProjectType)
  if(prodtypedata !== undefined && prodtypedata[0]!==undefined){
  type=prodtypedata[0]?.projecttypeid
}

  
  
  // //
  // useEffect(() => { 
  //   if(instance.getAllAccounts()[0] !== undefined)
  //   {
  //  if(prodtypedata !== undefined && prodtypedata[0] !== undefined )
  //   dispatch(fetchNfrTypeData({data:prodtypedata[0]?.id,instance:instance}))
  //   }
  // }, [type,prodtypedata,instance.getAllAccounts()[0] !== undefined])
  // //NFR
  // const nfrcontent = useSelector((state) => state.commonReducer?.nfr?.nfrTypeData?.nfrdata);
  // const [nfrdata, setnfrData] = useState(nfrcontent);
  // useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);
  // //
  

  let projectname = <></>
  var prjName = "", prjDescription ="",prjType = "", prjTypeIcon ="",prjThemeIcon="",
  prjTheme = "", prjLayoutIcon="",prjLayout="" ;
 if ((location.pathname === "/webStudio" ||
 location.pathname === "/explorer") && prodtypedata !== undefined && prodtypedata?.length !== 0 && prodtypedata[0] !== undefined &&  prodtypedata[0]?.projectname !== undefined  ) {
  projectname = ( 
    <>
    <span className="header_text">{prodtypedata[0]?.projectname}</span>
    </>
  )
  prjName = prodtypedata[0]?.projectname
  prjDescription = prodtypedata[0]?.description
  prjType = prodtypedata[0]?.ProjectType
  prjTypeIcon = prodtypedata[0]?.ProjectTypeicon
  prjThemeIcon = prodtypedata[0]?.Themeicon
  prjTheme = prodtypedata[0]?.Theme
  prjLayoutIcon = prodtypedata[0]?.Layouticon
  prjLayout = prodtypedata[0]?.Layout
 }
  return (
    <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
      <Container fluid className="p-0">
        <Navbar.Brand href="/" className="d-flex"><Home_logo className="me-15"/>
        <span className="header_text border-0">BitCraft Studio</span>
        {projectname}
        {/* <span className="title_name">Project Demo</span> */}
        </Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='ps-5' id="basic-navbar-nav">
            <Nav className="me-auto">   
            </Nav>
          <Form className="d-flex align-items-center">
          
          {infoicon}
          <Bell_icon className='react_icon_size me-3'/>
          {name ? (
          <Nav pullRight className="avatardropdown ps-2">
          <NavDropdown className='avatardropdown'  align="end" title={  
            <div className="d-flex align-items-center">
              {imageUrl !== null ?
                <img
                  className="thumbnail-image smallsize mr-10 rounded-circle"
                  src={imageUrl}
                  alt="user pic"
                />
                :
                  <span className="userProfileImage" style={theme[randomColor]}>
                    {userFirstName}{userLastName}</span>
                  }
                  <div class="d-flex flex-column ms-2 text-end justify-content-end">
                    <span class="font_10 font_regular welcometext">
                      Welcome
                    </span>
                    <span class="font_12 font_medium usertxt">
                      {name}
                    </span>
                  </div>
                </div>
                }
                  id="basic-nav-dropdown">
                  {/* <NavDropdown.Item href="#action3">Action</NavDropdown.Item>
                  <NavDropdown.Item href="#action4">
                    My Profile
                  </NavDropdown.Item> */}
                  <NavDropdown.Item
                      onClick={() => handleLogout(instance)}
                      eventKey={1.4}
                    >
                      <i className="fa fa-sign-out"></i>{" "}
                      Logout
                    </NavDropdown.Item>
                </NavDropdown>
              </Nav>
            ) : (
              <Button className="w-85px" onClick={() => handleLogin(instance)} variant="secondary">
                <span>
                  <Person style={{ marginRight: "5px" }} />
                  Login
                </span>
              </Button>
            )}
            <DropdownButton
                        variant="outline-secondary"
                        title={
                          <Three_dots_vertical class="react_icon_small_fill"/>
                        }
                        id="input-group-dropdown-4"
                        align="end"
                        className="me-0 ms-3 dp"
                       
                    >
                        {/* <Dropdown.Item className={isActive !== "file" ? 'nav-link px-3 py-1' : 'nav-link active px-3 py-1'} id="file" to="/" onClick={() => { setActive("file") }}>File</Dropdown.Item> */}
                        {/* <Dropdown.Item className={isActive !== "edit" ? 'nav-link px-3 py-1' : 'nav-link active px-3 py-1'} id="edit" to="/" onClick={() => { setActive("edit") }}>Edit</Dropdown.Item> */}
                        <Dropdown.Item className={isActive !== "packages" ? 'nav-link px-3 py-1' : 'nav-link active px-3 py-1'} as={Link} to="/packages" id="packages" onClick={() => { setActive("packages") }}>Packages</Dropdown.Item>
                        <Dropdown.Item className={isActive !== "themes" ? 'nav-link px-3 py-1' : 'nav-link active px-3 py-1'} as={Link} to="/themes" id="themes" onClick={() => { setActive("themes") }}>Themes</Dropdown.Item>
                        <Dropdown.Item className={isActive !== "layout" ? 'nav-link px-3 py-1' : 'nav-link active px-3 py-1'} as={Link} to="/layout" id="layout" onClick={() => { setActive("layout") }}>Layout</Dropdown.Item>
                        {/* <Dropdown.Item className={isActive !== "edit" ? 'nav-link px-3 py-1' : 'nav-link active px-3 py-1'} id="edit" to="/" onClick={() => { setActive("edit") }}>Help</Dropdown.Item> */}
                    </DropdownButton>
          </Form>
        </Navbar.Collapse>
      </Container>
      <Modal
        show={show}
        onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >
        <Modal.Header closeButton>
          <Modal.Title className='font_18 font_regular' id="contained-modal-title-vcenter">
            Setting
          </Modal.Title>
        </Modal.Header>
        <Modal.Body>
          <div class="row mb-2">
            <div class="col-12 mb-3">
              <p class="font_14 font_light mb-2">Project Name</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Myapps} /></div>
                <div>{prjName}</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-9 mb-3">
              <p class="font_14 font_light mb-2">Description</p>
              <div class="d-flex flex-row align-items-center font_14">
              {prjDescription}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Project Type</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prjTypeIcon} /></div>
                <div class="font_14 font_regular">{prjType}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Theme Selection</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prjThemeIcon} /></div>
                <div class="font_14 font_regular">{prjTheme}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Layout Selection</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prjLayoutIcon} /></div>
                <div class="ps-2 font_14 font_regular">{prjLayout}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="font_14 font_light mb-2">Packaged Capabilities</p>
            </div>
            {prodtypedata?.map((nfrdata, index) => (
                  
                  nfrdata?.PackageCategory?.map((data)=>(
            <div class="col-lg-4">
           
              <div class="d-flex flex-row align-items-center">
            
                <div><img class="img-fluid me-2" src={data?.icon} /></div>
                
                      <div class="ps-2 font_14 font_regular">{data?.title}</div>
                    
              </div>
             
            </div>
            ))
                
                
            ))}
            {/* <div class="col-lg-4">
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Layout_icon} /></div>
                <div class="ps-2 font_14 font_regular">Notifications</div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Layout_icon} /></div>
                <div class="ps-2 font_14 font_regular">Error Logging</div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
    
  );
}

export default NavbarHome;
import { ReactComponent as Three_dots_vertical } from '../../images/three_dots_vertical.svg';
import { Handle, Position } from 'reactflow';
import { useState } from 'react';

function MessageCustomNode(props) {
  //
  const [message, setMessage] = useState(props.data.message);
  //const [flag, setFlag] = useState(false);
 
  const handleInputMessage = (e) => {
    setMessage(e.target.value);
    props.data.message = e.target.value;
   // setFlag(true);
  }

  
  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Top} />
            <div className="card bg-secondary text-white" style={{borderRadius: "inherit"}}>
                <div className="card-header">Message<label style={{paddingLeft: "15rem"}} onClick={props.data.onDelete}><Three_dots_vertical class="react_icon_small_fill"/></label></div>
                <div className="card-body">
                   {message === "" && <input name="inputtext" type="text" placeholder="Message" onBlur={handleInputMessage}></input>}
                   {message !== "" && message}
                </div> 
            </div>
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
}

export default MessageCustomNode;


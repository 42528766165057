import { Handle, Position } from 'reactflow';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Right_arr } from '../../images/right_arr.svg';
import { ReactComponent as Closee_iconn } from '../../images/closee_iconn.svg';
import { ReactComponent as Three_dots_vertical } from '../../images/three_dots_vertical.svg';
import { ReactComponent as AddPlus } from '../../images/Add_icon_plus.svg';
import  OptionsForm  from './OptionsForm';

function AskQuestionsNode(props) {
   // 
    
    const [ multipleChoiceFlag, setMultipleChoiceFlag ] = useState(false);
    const [ validateOption, setValidateOption ] = useState("MultipleChoice");
    const [ showOptionsForm, setShowOptionsForm ] = useState(false);
    const [inputFields, setInputFields] = useState([
        // {
        //     enterOption: ""
        // }
    ])
    const [ askQuestionFormData, setAskQuestionFormData ] = useState({
        stepName:"",
        prompt: "",
        validate: "",
        options: "",
       // model: "",
       retryPrompt: ""
    })
    let [ sendOptions, setSendOptions ] = useState([])

    useEffect(() => {
        let divHeight1 = document.getElementById('askQ').clientHeight;
        //
       setAskQuestionFormData({
            stepName: props.data.sendData.stepName,
            prompt: props.data.sendData.prompt,
            validate: props.data.sendData.validate,
            options: props.data.sendData.options,
           // model: props.data.sendData.model,
            retryPrompt: props.data.sendData.retryPrompt
        });
       
        setValidateOption(props.data.sendData.validate);
        if(props.data.sendData.options.length > 0){
            setShowOptionsForm(true); 
            let newOptions = props.data.sendData.options.map((item) => {
                return{optionname:item}
             })
             setInputFields(newOptions);
        }

    },[props])

    const handleAdd = () => {
        var clientHeight = document.getElementById('askQ').clientHeight;
       // let divElement = document.querySelector(askQ);
          
        //let elemHeight = divElement.offsetHeight;
        // 
        // 
        props.data.nodeHeight = clientHeight;
        setShowOptionsForm(true)
        setInputFields([
            ...inputFields,
            {
                optionname: ""
            }
        ])
       
        props.data.onEdit();
    }

    const handleRemove = (event, index) => {
        //
        const values = [...inputFields];
        const resultInputFields = values.filter((item,i) => (i !== index))
        //
        setInputFields(resultInputFields)
        sendOptions = resultInputFields.map((item) => item.optionname)
        //
        setAskQuestionFormData({options: sendOptions})
        props.data.sendData.options = sendOptions;
        let divHeight = document.getElementById('askQ').clientHeight;
        props.data.nodeHeight = divHeight;
        //
        props.data.onEdit();

    }

    const handleChange = (event, index) => {
        const values = [...inputFields];
        //setSendOptions([]);
        //
        values[index][event.target.name] = event.target.value;

        setInputFields(values)

        //

        //setAskQuestionFormData({options:})
        sendOptions = inputFields.map((item) => item.optionname)
       // 
        setAskQuestionFormData({options: sendOptions})
        props.data.sendData.options = sendOptions
    }

    const handleClickValidate = (e) => {
        if(multipleChoiceFlag){
            setMultipleChoiceFlag(false);
        }
        else{
            setMultipleChoiceFlag(true);
        }
    }

    const handleClose = () => {
        setMultipleChoiceFlag(false);
    }

    const handleSelectValidate = (val) => {
        setMultipleChoiceFlag(false);
        //
        if(val !== "MultipleChoice"){
            setInputFields([]);
        }
        setValidateOption(val);
        setAskQuestionFormData({validate: val});
        props.data.sendData.validate = val;
        setTimeout(() => {
            let divHeight1 = document.getElementById('askQ').clientHeight;
            props.data.nodeHeight = divHeight1;
            //
            props.data.onEdit();
        },1000)
        
    }

    const handleChangeStepName = (event) => {
        setAskQuestionFormData({stepName: event.target.value});
        props.data.sendData.stepName = event.target.value;
    }

    const handleChangePrompt = (event) => {
        // const newName = event.target.value.replace(
        //     /[^a-zA-Z\s]/g,
        //     ""
        // );
        // setAskQuestionFormData({prompt: newName});
        setAskQuestionFormData({prompt: event.target.value});
        props.data.sendData.prompt = event.target.value;
    }

    // const handleChangeModel = (event) => {
    //     setAskQuestionFormData({model: event.target.value});
    //     props.data.sendData.model = event.target.value;
    // }

    const handleChangeRetryPrompt = (event) => {
         setAskQuestionFormData({retryPrompt: event.target.value});
         props.data.sendData.retryPrompt = event.target.value;
    }

    return (
        <div className="text-updater-node" id="askQ">
            <Handle type="target" position={Position.Top} />   
                <div className="card bg-secondary text-start text-white">
                    <div className="card-header">Question<label style={{paddingLeft: "15rem"}} onClick={props.data.onDelete}><Three_dots_vertical class="react_icon_small_fill"/></label></div>
                    <div className="card-body">
                        <div>
                            <label for="stepName" class="form-label">Step Name</label>
                            <input 
                                type= "text" 
                                class= "form-control form-control-sm" 
                                id= "stepName"
                                value= { askQuestionFormData.stepName }
                                name = "stepName"
                                style= {{ height:"1rem" }}
                                onChange = { handleChangeStepName }
                            />
                        </div>
                               
                        <div>
                            <label for="Prompt" class="form-label">Prompt</label>
                            <input 
                                type= "text" 
                                class= "form-control form-control-sm" 
                                id= "Prompt"
                                value= { askQuestionFormData.prompt }
                                name = "Prompt"
                                style= {{ height:"1rem"}}
                                onChange = { handleChangePrompt }
                            />
                        </div>
                        <div>
                            <label for="retryPrompt" class="form-label">Retry Prompt</label>
                            <input 
                                type= "text" 
                                class= "form-control form-control-sm" 
                                id= "retryPrompt"
                                value= { askQuestionFormData.retryPrompt }
                                name = "retryPrompt"
                                style= {{ height:"1rem"}}
                                onChange = { handleChangeRetryPrompt }
                            />
                        </div>
                        <label>Validate</label>
                        <hr></hr>
                        <div className='position-relative chatbot_div'>
                       
                            <div class="d-flex align-items-center" onClick={() => handleClickValidate('Validate')}>
                            { validateOption }
                            <Right_arr className='ms-auto' />
                            </div>
                        
                            {multipleChoiceFlag && 
                            <div class="innerpopup_open">
                                <div class="d-flex align-items-start flex-row">
                                    <p class="font_16 mb-3 font_medium">Choose information to validate</p>
                                    <Closee_iconn className="ms-auto cursor-pointer mt-2" onClick={handleClose}/>
                                </div>
                                <ul>
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('MultipleChoice')}>
                                       Multiple Choice
                                    </li>
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('String')}>
                                        String
                                    </li>  
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('Boolean')}>
                                        Boolean
                                    </li>  
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('Integer')}>
                                        Integer
                                    </li>  
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('Float')}>
                                        Float
                                    </li> 
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('FutureDate')}>
                                        Future Date
                                    </li>
                                    <li className='font_14 font_regular' onClick={() => handleSelectValidate('PastDate')}>
                                        Past Date
                                    </li>   
                                </ul>
                            </div>
                            }
                        </div>
                        <hr></hr>
                        {
                            validateOption==="MultipleChoice" &&
                            <div>
                                <label for="Prompt" class="form-label">Options for user</label> 
                                <div class="col-lg-4">
                                    <div class="d-flex" style={{width:"8rem"}}>
                                        <button 
                                            style={{border:"none"}}
                                            onClick={handleAdd} 
                                        >
                                            <AddPlus />
                                        </button>
                                        New Option
                                    </div>
                                <br></br>
                                    <>
                                    
                                    {   showOptionsForm &&
                                        inputFields?.map((item, index) => {
                                            <br></br>
                                            return (
                                                
                                                <div key={index} class="d-flex">
                                                    
                                                    <OptionsForm
                                                        inputFields = { inputFields }
                                                        index = { index }
                                                        item = { item }
                                                        handleChange = { handleChange }
                                                        handleAdd = { handleAdd }
                                                        handleRemove = { handleRemove }
                                                        inputFieldsCount = { inputFields.length }
                                                        key="optionsFormPage"
                                                    />
                                                </div>
                                                
                                            )
                                        })
                                    }
                                    </>
                                    
                                </div>
                                
                            </div>
                        }
                        {/* <div>
                            <label for="Model" class="form-label">Model</label>
                            <input 
                                type="text" 
                                class="form-control form-control-sm" 
                                id= "Model"
                                value=  { askQuestionFormData.model }
                                name = "Model"
                                style={{height:"1rem"}}
                                onChange = { handleChangeModel }
                            />
                        </div> */}
                           
                    </div> 
                </div>
            <Handle type="source" position={Position.Bottom} id="b" />
        </div>
    );
}
export default AskQuestionsNode;
 

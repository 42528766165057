import axios from 'axios';


const endpoint = 'https://me3m8jx15g.execute-api.us-east-1.amazonaws.com/codegenprod';
const imgendpoint = 'https://m6vxymjp8l.execute-api.us-east-1.amazonaws.com/prod'
const headers = {
  'Content-Type': 'Content-Type: application/json',
  // 'api-key': apiKey
};

export const getGPTResponse = async (prompt) => {




  const data = {
    prompt: prompt,
    // max_tokens: 4000, // You can adjust the max tokens as needed
    // temperature: 0.7, // You can adjust the temperature as needed
  };

  try {
    const response = await axios.post(endpoint, data, { headers });
    return JSON.parse(response.data.body).response;
  } catch (error) {
    console.error('Error calling  OpenAI:', error);
    throw error;
  }
};


export const getGPTRespImg = async (img, prompt) => {
  let data = {
    image_data: img?.replace("data:image/png;base64,",""),
    prompt_text: prompt,
    "media_type": "image/png"
  }

  try {
    const response = await axios.post(imgendpoint, data, { headers });
    return JSON.parse(response.data.body).response;
  } catch (error) {
    console.error('Error calling Azure OpenAI Img:', error);
    throw error;
  }

}
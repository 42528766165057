import { createAsyncThunk } from "@reduxjs/toolkit";
import nfrService from "../../services/nfrService";
import { ReactComponent as Eye } from "../../images/eye.svg";
import { ReactComponent as Edit } from "../../images/edit.svg";
import { ReactComponent as Delete } from "../../images/delete.svg";
import { ReactComponent as EyeCross } from "../../images/SlashEye.svg";
import { useParams } from "react-router-dom";
import { Link } from "react-router-dom";
import { confirmAlert } from 'react-confirm-alert';
import 'react-confirm-alert/src/react-confirm-alert.css';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";



export const fetchNfrTypeData = createAsyncThunk("nfrstore/fetchNfrTypeData", async (data) => {
  
  // if(flag !== undefined){
  //   flag.includes(" ")? flag = flag.replaceAll(/\s/g,"%20"): flag = flag;
  //   }else{
  //       flag = ""
  //   }
  //
  const fetchData = async () => {
    return nfrService.getNfrTypeData(data.data, data.instance)
  }

  try {
    const rawData = await fetchData();

    const nfrTypeData = [];

    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype?._id,
        title: nfrtype?.title,
        description: nfrtype?.description,
        icon: nfrtype?.icon,
        parameters: nfrtype?.parameters,
        isactive: nfrtype?.isactive
      };
      nfrTypeData.push(cardData);
    }

    //
    return {
      nfrdata: nfrTypeData?.length !== 0 ? nfrTypeData : [],
      loading: false,
    };
  }
  catch (err) {
    //
  }

}

);

export const getPackCompCategory = createAsyncThunk("nfrstore/getPackCompCategory", async (data) => {
  

  const fetchData = async () => {
    return nfrService.getPackCompCategory(data)
  }
  //
  try {
    const rawData = await fetchData();

    const Categorydata = [];

    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype?._id,
        categoryid: nfrtype?.Category?._id,
        isactive: nfrtype?.isactive,

      };
      Categorydata.push(cardData);
    }

    //
    return {
      categoryshow: Categorydata?.length !== 0 ? Categorydata : [],
      loading: false,
    };
  }
  catch (err) {
    //
  }

}

);

//Confirmation popup for delete
const confirmAlertFun = (ev, data, id) => {

  confirmAlert({
    title: 'Confirm alert',
    message: 'Are you sure you want to delete this project?',
    buttons: [
      {
        label: 'Yes',
        onClick: (ev) => {

          data.dispatch(deletePackageDetailData({ data: id, instance: data.instance })).then((res) => {
            data.dispatch(fetchPackagesGridData(data)).then(()=>{
              window.location = "/packages"

            })


          })
          ev.stopPropagation();
          ev.preventDefault();
        }
      },
      {
        label: 'No',
        // onClick: () => alert('Click No')
      }
    ]
  });
};

const getActionsColumn = (data) => {
  //
  return {
    field: 'Actions',
    headerName: 'Actions',
    hideable: false,
    sortable: false,
    filterable: false,
    width: 200,
    renderCell: (n) => {
      const id = n.row.id;
      //
      // let eyeicon =  <Eye size={25}/>
      // const id = n.row.id
      // const visibility = n.row.visibility;
      // if(visibility===true)
      // eyeicon = <Eye size={25} />
      // else
      // eyeicon = <EyeCross id="eyeOff" size={25} />
      return (
        <>
          <Link className="navcustomlink" to={"/packages/editPackage?id=" + id}>
            <div
              size="large"
              className="me-4 cursor-pointer"
            >
              <Edit />
            </div>
          </Link>
          <div
            onClick={(ev) => {
              confirmAlertFun(ev, data, id)
            }}
            size="large"
            className="me-4 cursor-pointer"
          >
            {/* { eyeicon } */}
            <Delete />
          </div>
          <div
            size="large"
            className="cursor-pointer"
          >
            {/* { eyeicon } */}
            {/* <Eye /> */}
          </div>
        </>
      )
    }
  }
}


export const fetchPackagesGridData = createAsyncThunk("nfrstore/fetchPackagesGridData ", async (data) => {
  // 
  const fetchData = async () => {
    return nfrService.getPackagesGridData(data.data, data.instance, data.version)
  }



  try {

    const columnsdata = [
      { field: "categoryname", headerName: "Package Category", flex: 1, minWidth: 200 },
      // {field: "projecttypename", headerName: "Project type",flex:1, width: 200},
      { field: "typename", headerName: "Package Type", flex: 1, width: 175 },
      { field: "filename", headerName: "File Name", flex: 1, width: 175 },
      { field: "landmark", headerName: "Landmark", flex: 1, width: 200 },
      { field: "isafter", headerName: "After ?", flex: 1, width: 120 },


    ];

    const rawData = await fetchData();
    const gridPackage = [];

    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype._id,
        typename: nfrtype?.typename,
        categoryname: nfrtype?.component?.categoryname,
        projecttypename: nfrtype?.component?.projecttypename,
        projectversionid: nfrtype?.component?.projectversionid,
        filename: nfrtype?.filename,
        landmark: nfrtype?.landmark,
        isafter: nfrtype?.isafter,
        categoryid: nfrtype?.component?.categoryid,
        //isactive:nfrtype?.component?.i
      };
      gridPackage.push(cardData);
    }
    //


    //  getActionsColumnPackageCategory(gridPackage);

    const actionsColumn = getActionsColumn(data);
    columnsdata.push(actionsColumn);
    return {
      packagegrid: gridPackage?.length !== 0 ? gridPackage : [],
      loading: false,
      packagegridColumnData: columnsdata,

    };
  }
  catch (err) {
    //
  }
}
);


const getActionsColumnPackageCategory = (data) => {
  //


  return {
    field: 'Actions',
    headerName: 'Actions',
    hideable: false,
    sortable: false,
    filterable: false,
    width: 200,
    renderCell: (n) => {
      const id = n.row.id;
      //let catid=[];
      //  data.map((index)=>{
      //  let cat =index.categoryid===id?true:false
      //  
      //  });    

      let putpackcategory;
      let putcategory;
      let eyeicon = <Eye size={25} />
      // const id = n.row.id
      const isactive = n.row.isactive;
      // 
      if (isactive === true)
        eyeicon = <Eye size={25} />
      else
        eyeicon = <EyeCross id="eyeOff" size={25} />
      return (
        <>
          <Link className="navcustomlink" to={"/packages/editPackageCategory?id=" + id}>
            <div
              size="large"
              className="me-4 cursor-pointer"
            >
              <Edit />
            </div>
          </Link>

          <div
            onClick={(ev) => {
              {
                isactive === true ?
                putpackcategory = {
                  "_id": id,
                  "isactive": false
                } : putpackcategory = {
                  "_id": id,
                  "isactive": true
                }
              }

              data.dispatch(putpackcategoryData(putpackcategory)).then(() => {
                data.dispatch(getPackCompCategory(id)).then((res) => {
                  //
                  if (res.payload.categoryshow?.length > 0) {
                    {
                      isactive === true ?
                      putcategory = {
                        "_id": res.payload.categoryshow[0]?.id,
                        "isactive": false
                      } : putcategory = {
                        "_id": res.payload.categoryshow[0]?.id,
                        "isactive": true
                      }
                    }
                    data.dispatch(putPackageData(putcategory))
                  }
                  //if res.length>0 map array, store _id to a list named "packagecompIDs"
                  //other pput call isactive

                })

                data.dispatch(fetchPackagesComponentData(data))

              })

              ev.stopPropagation();
              ev.preventDefault();
            }}
            size="large"
            className="cursor-pointer"
          >
            {eyeicon}

          </div>
        </>
      )
    }
  }
}

export const fetchPackagesComponentData = createAsyncThunk("nfrstore/fetchPackagesComponentData ", async (data) => {
  // 
  const fetchData = async () => {
    return nfrService.getPackagesComponentData(data.instance)
  }



  try {

    const columnsdata1 = [
      { field: "icon", headerName: "Icon", minWidth: 100, renderCell: (param) => { return (<img src={param.value} />) } },
      { field: "title", headerName: "Package Category", width: 280 },
      { field: "description", headerName: "Description", width: 530 },


    ];

    const rawData = await fetchData();
    const gridPackage = [];

    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype?._id,
        description: nfrtype?.description,
        title: nfrtype?.title,
        icon: nfrtype.icon,
        projecttypeid: nfrtype?.projecttypeid,
        isactive: nfrtype?.isactive,
        parameters: nfrtype?.parameters,
      };
      gridPackage.push(cardData);
    }
    //

    const actionsColumn = getActionsColumnPackageCategory(data);
    columnsdata1.push(actionsColumn);
    return {
      packagecomponent: gridPackage?.length !== 0 ? gridPackage : [],
      loading: false,
      packageComponentColumnData: columnsdata1,

    };
  }
  catch (err) {
    //
  }
}
);


////actions file
export const fetchPackagesTypeGridData = createAsyncThunk("nfrstore/fetchPackagesTypeGridData", async (instance) => {
  
  const fetchData = async () => {
    return nfrService.getPackagesTypeGridData(instance)
  }
  try {

    const columnsdata2 = [

      { field: "name", flex: 1, headerName: "Pakage Type", minWidth: 150 },
      { field: "description", headerName: "Description", width: 300 },
    ];

    const rawData = await fetchData();
    const nfrTypeData = [];
    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype._id,
        name: nfrtype?.name,
        description: nfrtype?.description,
        dynamicfields: nfrtype?.dynamicfields
      };
      nfrTypeData.push(cardData);
    }
    //
    return {
      typegrid: nfrTypeData?.length !== 0 ? nfrTypeData : [],
      loading: false,
      packageTypeColumnData: columnsdata2
    };
  }
  catch (err) {
    //
  }
}
);

export const postNewPackageData = createAsyncThunk("userprojectstore/postNewPackageData", async (newpackage) => {
  
  const postData = async () => {
    return nfrService.postNewPackageData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await postData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);

export const postNewPackageDetailData = createAsyncThunk("userprojectstore/postNewPackageDetailData", async (newpackage) => {
  
  const postData = async () => {
    return nfrService.postNewPackageDetailData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await postData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);

//Post for Package Category
export const postPackageCategoryData = createAsyncThunk("nfrstore/postPackageCategoryData", async (data) => {
  const postData = async () => {
    return nfrService.postpackcategoryData(data.data, data.instance)
  }
  try {
    const rawData = await postData();
    //
    return rawData;
  } catch (err) {
    //
  }
}
);

//fetch data for edit
export const fetchEditPackageData = createAsyncThunk("nfrstore/fetchEditPackageData ", async (data) => {
  // 
  //

  let newId = data.replace("?id=", "")
  //

  const fetchData = async () => {
    if (newId !== null) {
      return nfrService.getEditPackageData(newId)
    }
  }
  try {
    const rawData = await fetchData();
    const editPackage = [];
    
    for (const nfrtype of rawData) {
      const cardData = {
        id: nfrtype?._id,
        projecttypename: nfrtype?.packagecomponentid?.projecttypename,
        projectversion: nfrtype?.packagecomponentid?.projectversion,
        typename: nfrtype?.typename,
        categoryname: nfrtype?.packagecomponentid?.categoryname,
        filename: nfrtype?.filename,
        landmark: nfrtype?.landmark,
        isafter: nfrtype?.isafter,
        isreplace: nfrtype?.isreplace,
        filepath: nfrtype?.filepath,
        codesnippet: nfrtype?.codesnippet,
        typeid: nfrtype?.typeid,
        packagecomponentid: nfrtype?.packagecomponentid?._id,
        projecttypeid: nfrtype?.packagecomponentid?.projecttypeid,
        projectversionid: nfrtype?.packagecomponentid?.projectversionid,
        categoryid: nfrtype?.packagecomponentid?.categoryid
      };
      editPackage.push(cardData);
    }
    

    return {
      packageedit: editPackage?.length !== 0 ? editPackage : [],
      loading: false,

    };
    ////
  }
  catch (err) {
    //
  }
}
);

//put package data
export const putPackageData = createAsyncThunk("nfrstore/putPackageData", async (newpackage) => {
  
  const putData = async () => {
    return nfrService.putPackageData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await putData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);
//put putpackcategoryData data
export const putpackcategoryData = createAsyncThunk("nfrstore/putpackcategoryData", async (newpackage) => {
  
  const putData = async () => {
    return nfrService.putpackcategoryData(newpackage)
  }

  try {
    const rawData = await putData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);
//put PackageDetailData data
export const putPackageDetailData = createAsyncThunk("nfrstore/putPackageDetailData", async (newpackage) => {
  
  const putData = async () => {
    return nfrService.putPackageDetailData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await putData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);

//delete PackageDetailData data
export const deletePackageDetailData = createAsyncThunk("nfrstore/deletePackageDetailData", async (newpackage) => {
  
  const deleteData = async () => {
    return nfrService.deletePackageDetailData(newpackage.data, newpackage.instance)
  }

  try {
    const rawData = await deleteData();
    //
    return rawData;

  } catch (err) {
    //
  }
}
);
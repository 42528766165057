import { createSlice } from "@reduxjs/toolkit";
import { fetchLayoutData,fetchLayoutsGridData,fetchEditLayoutsData } from "./layout.actions";

const LayoutTypeData = createSlice({
    name: "layoutstore",
    initialState: {
        layoutTypeData: { layoutdata: [], loading: true},
        gridLayouts: { layoutsgrid:[],loading: true, layoutsgridColumnData:[]},
       layoutsData:{ layoutsdata:[],loading:true},
        currentlayoutTypeData : {
            layouttype: {
                id: "",
                name:"",
            },
        },
        currentlayoutTypeDataCopy: {
            layouttype: {
                id: "",
                name:"",
            },
        },

        inProcessDialog: { props: {open: false, text: ""} },
    },

    reducers: {
        setEditLayoutTypeDataName: (state, actions) => {
            state.currentlayoutTypeData.layouttype.name = actions.payload;
        },
    },

    extraReducers: {
        [fetchLayoutData.pending]: (state) => {
            state.layoutTypeData.loading = false;
        },
        [fetchLayoutData.fulfilled]: (state,actions) => {
            state.layoutTypeData.layoutdata = actions.payload?.layoutdata;
            state.layoutTypeData.loading = actions.payload?.loading;
        },
        [fetchLayoutsGridData.pending]: (state) => {
            state.gridLayouts.loading = false;
        },
        [fetchLayoutsGridData.fulfilled]: (state,actions) => {
            
            state.gridLayouts.layoutsgrid = actions.payload?.layoutsgrid;
            state.gridLayouts.layoutsgridColumnData = actions.payload?.layoutsgridColumnData;
            state.gridLayouts.loading = actions.payload?.loading;
        },
        [fetchEditLayoutsData.pending]: (state) => {
            state.layoutsData.loading = false;
        },
        [fetchEditLayoutsData.fulfilled]: (state,actions) => {
            state.layoutsData.layoutsdata = actions.payload?.layoutsdata;
            state.layoutsData.loading = actions.payload?.loading;
        },
    },
    
});

export const { setEditLayoutTypeDataName } = LayoutTypeData.actions;
export default LayoutTypeData.reducer;
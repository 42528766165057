import React from 'react'
import { postNewPackageData, postNewPackageDetailData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import MasterFields from '../CommanMethods/MasterFields';
import Package from '../CommanMethods/PackageMethods';



function AddPackages() {
    let myval = 0
    let success = 0
    var res1 = ""


    const { instance, accounts } = useMsal();
    const name = accounts[0] && accounts[0].username;



    const dispatch = useDispatch();

    var value1


    const [inputFields, setInputFields] = useState([
        {
            packagecomponentid: "",
            packagetypename: "Open this select menu",
            packagetypeid: "",
            filename: "",
            filepath: "",
            landmark: "",
            isafter: false,
            codesnippet: "",
            isreplace: false,
        }
    ])





    const handleSave = async () => {


        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var projecttxt = document.querySelector('#selectProjectType').options[sI].label
        var projectid = document.querySelector('#selectProjectType').options[sI].value


        var vI = document.querySelector('#selectVersion').selectedIndex;
        var versionid = document.querySelector('#selectVersion').options[vI].value
        var versiontxt = document.querySelector('#selectVersion').options[vI].label

        var tI = document.querySelector('#selectType').selectedIndex;
        var typeid = document.querySelector('#selectType').options[tI].value
        var typetxt = document.querySelector('#selectType').options[tI].label


        var cI = document.querySelector('#selectCategory').selectedIndex;
        var categoryid = document.querySelector('#selectCategory').options[cI].value
        var categorytxt = document.querySelector('#selectCategory').options[cI].label




        if (sI === 0 || vI === 0 || cI === 0 || tI === 0) {
            value1 = 1
            if (sI === 0)
                alert("Please select Project Type");
            else if (vI === 0)
                alert("Please select Project Version");
            else if (cI === 0)
                alert("Please select Project Category")
            else if (tI === 0)
                alert("Please select Package type")
            else
                value1 = 0

        }



        {
            try {
                let packageData = {

                    "createdby": name,

                    "projecttypeid": projectid,

                    "projecttypename": projecttxt,

                    "projectversionid": versionid,

                    "projectversion": versiontxt,

                    "categoryid": categoryid,

                    "categoryname": categorytxt

                }

                inputFields?.map((data) => {

                    if (data?.filename === "") {
                        value1 = 1
                        alert("File name is mandatory")
                    }
                    else if (data?.filepath === "") {
                        value1 = 1
                        alert("File path is mandatory")
                    }
                    else if (data?.codesnippet === "") {
                        value1 = 1
                        alert("Code snippet is mandatory")
                    }
                    else if (data?.packagetypeid === 0) {
                        value1 = 1
                        alert("Select any package type")
                    }
                    else {
                        value1 = 0
                    }
                })
                if (value1 === 0) {
                    if (instance.getAllAccounts()[0] !== undefined) {
                        dispatch(postNewPackageData({ data: packageData, instance: instance })).then((res) => {



                            res1 = res.payload.data.insertedId


                            if (res.payload)
                                success = 1
                            else
                                alert("Form not submitted")


                            let str = []
                            str = inputFields.map((data) => {
                                return {

                                    packagecomponentid: res.payload.data._id,
                                    typename: data.packagetypename,
                                    typeid: data.packagetypeid,
                                    filename: data.filename,
                                    filepath: data.filepath,
                                    landmark: data.landmark,
                                    isafter: data.isafter === false && data.isreplace === true ? null : data.isafter,
                                    codesnippet: data.codesnippet,
                                    isreplace: data.isreplace,
                                }
                            }

                            )



                            let packageDetailData = {}

                            packageDetailData = {

                                "PostArray": str

                            }




                            if (value1 === 0) {
                                dispatch(postNewPackageDetailData({ data: str, instance: instance })).then((res) => {

                                    if (success == 1) {
                                        if (res.payload) {
                                            alert("Package added successfully")
                                            window.location = "/packages"
                                        }
                                        else
                                            alert("Form not submitted1")
                                    }
                                    else
                                        alert("Form not submitted2")

                                })
                            }


                        })
                    }
                }


            } catch (err) {

            }



        }

    }

    //NFR
    const nfrcontent = useSelector(
        (state) => state.commonReducer.nfr.nfrTypeData.nfrdata);
    const [nfrdata, setnfrData] = useState(nfrcontent);
    useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);


    const [value, setValue] = useState(0);



    const PackageDiv = <div class="col-lg-4 text-start">
        <div class="mb-3">
            <label for="exampleFormControlInput1" class="form-label">Project Category</label>
            <div class="select_box">
                <select id="selectCategory" class="form-select outlined" aria-label="Default select example">
                    <option selected>Open this select menu</option>
                    {value == 1 ? nfrdata?.map((nfrdata, index) => (
                        <option value={nfrdata?.id} id="categoryid">{nfrdata?.title}</option>
                    )) : <></>}
                </select>
            </div>
        </div>
    </div>


    return (
        <>
            <div class="screen_two">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>
                        Add Package</p>
                </div>
                <Package handleSave={handleSave} inputFields={inputFields} setInputFields={setInputFields}
                    value={value} setValue={setValue} customDiv={PackageDiv} cancelLoc={"/packages"} />
            </div>




        </>

    )
}

export default AddPackages

import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });
  async function generateClient(instance){
    const accessTokenRequest = { scopes: [process.env.REACT_APP_API_CONFIGS_SCOPE], account: instance.getAllAccounts()[0], }; 
    // 
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
    return `Bearer ${accessTokenResponse.accessToken}`;
}

class LayoutService extends RequestHandler{
    
    //fetch all product type data
    async getLayoutData(data, instance) {
        // 
        var res
        //const res = await client.get("/listlayout?projecttype="+data);
        if(data===""||data===undefined||data==="undefined")
        {
         res = await client1.get("/layout");
        }
        else
        {
         res = await client1.get("/layout?projecttype="+data);
       
        }
        //
        return res.data

    }
    async getLayoutsGridData(id,instance) {
          
        //  var heads = await generateClient(instance) 
         // 
         const res = await client1.get("package-component/package-component-layout"+id
        //  ,{headers: { "Authorization": heads}}
         ); 
         // 
         return res.data 
    }
   //post theme data
   async postLayoutData(newtheme,instance){
    
    var heads = await generateClient(instance) 
    const res = await client1.post("/layout/create-layout",newtheme,{headers: { "Authorization": heads}});
    //
    return res;
}

//post themepackage data
async postPackageLayoutData(newpackagetheme,instance){
    
    var heads = await generateClient(instance) 
    const res = await client1.post("/package-component/create-package-comp-layout",newpackagetheme,{headers: { "Authorization": heads}});
    //
    return res;
}
async getEditLayoutData(id,instance) {
    //
    var heads = await generateClient(instance) 
   const res = await client1.get("package-component-detail/filter-layout?id="+id,{headers: { "Authorization": heads}});
   //
   return res.data
  
}


//put putLayoutData data
async putLayoutData(newpackagetheme,instance){
    
    var heads = await generateClient(instance) 
    const res = await client1.put(`/layout/update-layout/${newpackagetheme._id}`,newpackagetheme,{headers: { "Authorization": heads}});
    //
    return res;
}
//put putPackageLayoutData data
async putPackageLayoutData(newpackagetheme,instance){

var heads = await generateClient(instance) 
const res = await client1.put(`/package-component/update-package-component/${newpackagetheme._id}`,newpackagetheme,{headers: { "Authorization": heads}});
//
return res;
}
//put putPackageCompLayoutData data
async putPackageCompLayoutData(newpackagetheme,instance){

var heads = await generateClient(instance) 
const res = await client1.put(`/package-component-detail/update-package-component/${newpackagetheme._id}`,newpackagetheme,{headers: { "Authorization": heads}});
//
return res;
}


}



export default new LayoutService;
import React from 'react'
import { postNewPackageDetailData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import {  fetchThemeData } from "../store/theme.actions"
import {  useEffect, useState } from 'react';
import {  useMsal } from "@azure/msal-react";
import {postPackageLayoutData,postLayoutData,fetchLayoutsGridData} from '../store/layout.actions';
import { useToasts } from 'react-toast-notifications';
import IconUpload from '../CommanMethods/IconUpload';
import Package from '../CommanMethods/PackageMethods';


function AddLayout() {
    let myval = 0
    let success = 0
    var res1 = ""
    const [result, setResult] = useState();
    let flag = 1
    //
    const { addToast } = useToasts();

    var myindex
    const { instance, accounts } = useMsal();
    const email = accounts[0] && accounts[0].username;
    
    const [getid, setgetid] = useState();
    const [value, setValue] = useState(0);
    const [uploadedBlob, setUploadedBlob] = useState();
    const [name, setName] = useState("");
    const [desc, setDesc] = useState("");
    const [isexpand, setIsexpand] = useState(false);
    const dispatch = useDispatch();
   

    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchThemeData({data: "", instance: instance}))
        dispatch(fetchLayoutsGridData({dispatch:dispatch,instance:instance}));
        }
    }, [dispatch,getid, instance.getAllAccounts()[0] !== undefined])

  
    const maincontent = useSelector((state) => state.commonReducer.layoutsgrid?.gridLayouts?.layoutsgrid);
    
    const [layout2, setlayout2] = useState(maincontent);
    useEffect(() => { setlayout2(maincontent) }, [dispatch, maincontent])
  
   

    //fetching theme data
    const theme1 = useSelector(
        (state) => state.commonReducer.theme?.themeTypeData?.themedata);
    const [themedata, setthemedata] = useState(theme1);
    useEffect(() => { setthemedata(theme1); }, [dispatch, theme1]);
    


    
    

    const [inputFields, setInputFields] = useState([
        {
            packagecomponentid: "",
            packagetypename: "Open this select menu",
            packagetypeid: "",
            filename: "",
            filepath: "",
            landmark: "",
            isafter: "true",
            codesnippet: "",
            isreplace: "true",
            pkgtype:"",
        }
    ])
 
   
    const handletheme= async()=>{
    var themed=document.querySelector('#selectthemedata').selectedIndex;
    var themetxt=document.querySelector('#selectthemedata').options[themed].label;
    var themeid=document.querySelector('#selectthemedata').options[themed].value;
    
    
    }
    const handleChange2=async(event)=>
    {
        setIsexpand(event.target.checked)
      
    }
    
   
    var value1;
    const handleSave = async () => {
        

        var sI = document.querySelector('#selectProjectType').selectedIndex;
        var projecttxt = document.querySelector('#selectProjectType').options[sI].label
        var projectid = document.querySelector('#selectProjectType').options[sI].value


        var vI = document.querySelector('#selectVersion').selectedIndex;
        var versionid = document.querySelector('#selectVersion').options[vI].value
        var versiontxt = document.querySelector('#selectVersion').options[vI].label

        var themed=document.querySelector('#selectthemedata').selectedIndex;
        var themetxt=document.querySelector('#selectthemedata').options[themed].label;
        var themeid=document.querySelector('#selectthemedata').options[themed].value;


        if (sI === 0 || vI === 0 ||themed === 0)
        {
            value1=1
            if(sI===0)
            addToast("Please select project type", { appearance: 'error', autoDismiss: 'true' })
            else if(vI===0)
            addToast("Please select project Version", { appearance: 'error', autoDismiss: 'true' })
            else if(themed===0)
            addToast("Please select themename", { appearance: 'error', autoDismiss: 'true' })
            
            else
            value1=0

        }

        inputFields.flat(1)?.map((data)=>{
                   
            if(data?.filename==="")
            {
                value1=1
            addToast("File name is mandatory",{ appearance: 'error', autoDismiss: 'true' })
            }
            else if(data?.filepath==="")
            {
                value1=1
                addToast("File path is mandatory",{ appearance: 'error', autoDismiss: 'true' })
            }
            else if(data?.codesnippet==="")
            {
                value1=1
                addToast("Code snippet is mandatory",{ appearance: 'error', autoDismiss: 'true' })
            }
            else if(data?.packagetypeid===0)
            {
                value1=1
            addToast("Select any package type",{ appearance: 'error', autoDismiss: 'true' })
            }
            else
            {
                value1=0
            }
        })

        var lname = document.querySelector("#lname").value;
       var ldesc = document.querySelector("#ldesc").value;
       if(lname==="" || ldesc===""){
           value1=1
        addToast("All the feilds are mandatory", { appearance: 'error', autoDismiss: 'true' })
       }
       layout2.map((data)=>{
        if(lname===data.layoutname && projecttxt===data.projecttypename ){
            value1=1
            addToast("Data already exits", { appearance: 'error', autoDismiss: 'true' })
        }

    })



        {
            try {
                let layoutData = {

                    "useremail": email,
                    "projecttypeid": projectid,
                    "iconpath": uploadedBlob?.imageURL,
                    "title": name,
                    "description": desc,
                    "themeid":themeid,
                    "isExpand":isexpand

                }
                if(value1===0){
                
                if(instance.getAllAccounts()[0] !== undefined){
                dispatch(postLayoutData({data:layoutData,instance:instance})).then((res) => {
                    
                    //
                    //
                    res1 = res.payload.data._id
                    setResult(res.payload.data._id);

                   
                    if (res.payload)
                        success = 1
                    else
                        alert("Form not submitted 11")

                    //

                    //

                    let packagelayoutdata = {

                        "projecttypename": projecttxt,
                        "projecttypeid": projectid,
                        "projectversion": versiontxt,
                        "projectversionid": versionid,
                        "themeid": themeid,
                        "themename": themetxt,
                        "createdby": email,
                        "layoutid": res.payload.data._id,
                        "layoutname": name,
                    }
                    dispatch(postPackageLayoutData({data:packagelayoutdata,instance:instance})).then((res) => {
                        
                      
                        res1 = res.payload.data._id
                     
                        if (res.payload)
                            success = 1
                        else
                            alert("Form not submitted")

                       

                        let str = []
                        str = inputFields.map((data) => {
                            return {

                                packagecomponentid: res.payload.data._id,
                                typename: data.packagetypename,
                                typeid: data.packagetypeid,
                                filename: data.filename,
                                filepath: data.filepath,
                                landmark: data.landmark,
                                isafter: data.isafter,
                                codesnippet: data.codesnippet,
                                isreplace: data.isreplace,
                            }
                        }

                        )
                        //
                       
                        
                        dispatch(postNewPackageDetailData({data:str,instance:instance})).then((res) => {
                            //
                            if (success == 1) {
                                if (res.payload) {
                                    alert("Layout added successfully")
                                    window.location = "/layout"
                                }
                                else
                                    alert("Form not submitted1")
                            }
                            else
                                alert("Form not submitted2")

                        })

                    })
                })
               
            }}
            } catch (err) {
                //
            }

        }

    }

    var themeDiv = <div class="col-lg-4 text-start">
    <div class="mb-3">
        <label for="exampleFormControlInput1" class="form-label">Theme</label>
        <div class="select_box">

            <select id="selectthemedata" onChange={handletheme} class="form-select outlined" aria-label="Default select example">
                <option >Open this select menu</option>
                {themedata?.map((themedata, index) => (
                    <option value={themedata?.id} id="projectid">{themedata?.title}</option>
                ))}
            </select>

        </div>
    </div>
</div>
    const handlechange1 = (event) => {
        setName(event.currentTarget.value);
    }
    const handlechange2 = (event) => {
        setDesc(event.currentTarget.value);
    }

    


    return (
        <>
            <div class="screen_two">
                <div class="file_name_container">
                    <p className='m-0 font_18 font_regular'>Add Layout</p>
                </div>
                <div class="file_container Addpackage">
                    <div class="row">
                    <IconUpload uploadedBlob={uploadedBlob} setUploadedBlob={setUploadedBlob}/>


                        <div class="col-lg-4">
                            <div class=" mb-3">
                                <label for="exampleFormControlInput1" class="form-label">Layout Name</label>
                                <input class="form-control outlined" id="lname" onChange={handlechange1} />
                            
                            <div class="form-check pt-3">
                                                        <input class="form-check-input"
                                                            type="checkbox"
                                                            id="isExpand"
                                                            onChange={(event) => handleChange2(event)}

                                                            name="isExpand"
                                                        />
                                                        <label class="form-check-label" for="flexCheckDefault">
                                                            isExpand
                                                        </label>
                                                    </div>
                                                    </div>

                        </div>
                        
                        <div class="mb-3">
                            <label for="exampleFormControlTextarea1" class="form-label">Description</label>
                            <textarea class="form-control" id="ldesc" rows="3" onChange={handlechange2}></textarea>
                        </div>
                        

                    </div>
                    <Package handleSave={handleSave} inputFields={inputFields} setInputFields={setInputFields}
                    value={value} setValue={setValue} cancelLoc={"/layout"} customDiv={themeDiv} />
                </div>
            </div>


        </>

    )
}

export default AddLayout

import { memo, useEffect, useState } from 'react';
import logo from './logo.png';
import './App.css';
import Webdesignermain from '../src/App/Layout';
import RouterConfig from './App/configs/routerConfig';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import LoginPopup from './App/main/LoginPopup';

function App() {
  const{ accounts}= useMsal();

  return (
    <div className="App">
      <RouterConfig />
    {accounts[0] === undefined ? <LoginPopup/> : null}
    </div>
  );
}

export default App;

import ChatBotMainScreen from "../../components/ChatBotComponents/ChatBotMainScreen";
import ChatBotSidebar from "../../components/ChatBotComponents/ChatBotSidebar";
import { useEffect, useState } from "react";
import { useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { getProjectDetails } from "../../main/store/userProject.actions";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

function ChatBot(){
    const [ dialogueId, setDialogueId] = useState();
    const dispatch = useDispatch();
   // const location = useLocation();
    //const urlData = location.search;
    //let urlDataItems = urlData.split("=");
    let id = localStorage.getItem("userprojectid");
    //let id = urlDataItems[1];
    const {instance, accounts, inProgress} = useMsal();

    useEffect(() => {
        // 
     
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(getProjectDetails({data:id,instance:instance}))
    }
    },[dispatch, id,instance.getAllAccounts()[0] !== undefined])

    const projectData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails);
    const [ dialoguesData, setDialoguesData ] = useState(projectData[0]?.dialogues) 
    useEffect(() => {
        setDialoguesData(projectData?.[0]?.dialogues);
        setDialogueId(projectData?.[0]?.dialogues[0]?.id);
    },[projectData])
   
    return(
        <>
            <div class="d-flex h-100">
                <ChatBotSidebar dialogues= {dialoguesData} setDialogue= {setDialogueId}/>
                {/* <ChatBotMainScreen  dialoguesId= {dialogueId}/> */}
            </div>
        </>
    )
}

export default ChatBot;
import React, { memo, useEffect, useState } from 'react';
import Navbarmain from './Navbar';
import ContentContainer from './ContentContainer';
import {useLocation, useNavigate} from "react-router-dom";
import NavbarHome from './NavbarHome';
import { useDispatch, useSelector } from "react-redux";
import { postUserProjectData , putUserProjectData, getAllProjectsGridData1, getAllProjectsNames} from "./main/store/userProject.actions"
import { getProjectDetails } from "./main/store/userProject.actions";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

let navbar;
function Webdesignermain({openToggle,setOpenToggle, sidebarhide, setSidebarhide, isActive, setActive, getActivePath}) {
    const location = useLocation();
    const dispatch = useDispatch();
    const {instance, accounts, inProgress} = useMsal();
    if(instance.getAllAccounts()[0] !== undefined){
    dispatch(getAllProjectsNames(instance))
    }
   

    //handle active class from path
    let path = location.pathname.replace("/","");
    const handlePath = () => {
      if(path === "") {
      return "home"
    }
    else if(path === "addFile") {
      return "file"
    }
    else if (path === "api") {
      return "api"
    }
    else if (path === "explorer") {
      return "source"
    }
    else if (path === "webStudio") {
      return "studio"
    }
    else if (path === "mobileStudio") {
      return "Mstudio"
    }
    else if (path === "allApps") {
      return "project"
    }
    else if (path === "botStudio") {
      return "message"
    }
    else {
      return "home"
    }
    }


    useEffect(() => {        
        getActivePath(handlePath);
    },[])    

    if (location.pathname === "/"){
        navbar = (
        <>
            <NavbarHome />
        </>
        )
    }
    else
    {
        navbar = (
            <>
                <Navbarmain />
            </>
        )
    }

    var  id= location.search.includes("?_id")?location.search.replace("?_id=",""):"";
    useEffect(() => { 
      if(instance.getAllAccounts()[0] !== undefined){
      dispatch(getProjectDetails({data:id,instance:instance}))
      }
    }, [id,instance.getAllAccounts()[0] !== undefined])
    return (
        <>
        <div class="web_designer_maincontainer">
            {/* <Navbarmain/> */}
           
           <NavbarHome/>
            <ContentContainer openToggle={openToggle} setOpenToggle={setOpenToggle} sidebarhide={sidebarhide} setSidebarhide={setSidebarhide}  isActive={isActive} setActive={setActive} />
        </div>
        </>
    );
  }
  export default Webdesignermain;
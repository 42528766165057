import React from 'react'

function PackageComponent() {
  return (
    <>
      <h1>PackageComponent</h1>
    </>
  )
}

export default PackageComponent

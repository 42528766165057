// import { useDrag } from "react-dnd";
import uuid from 'react-uuid';

function DraggableControl(props){

    // const [{isDragging}, dragRef] = useDrag(() => ({
    //     type: "insertControl",
    //     collect: monitor => ({
    //       isDragging: !!monitor.isDragging(),
         
    //     }),
    //     item: props.dragControl
    // }))

    return (
        <>
            <div 
                // ref={dragRef} 
                id={uuid()} 
                key={props.id}
            >
                {props.dragControl.name}
            </div>
        </>
    )
}

export default DraggableControl;
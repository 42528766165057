import { ReactComponent as AddPlus } from '../../images/Add_icon_plus.svg';
function UserProjectDialogueForm({
    index,
    item,
    handleChange,
    handleRemove,
    handleAdd,
    values,
    inputFields,
    currentVal,
    inputFieldsCount
    }){
    return(
        <div class="row align-items-center" hidden={currentVal === 3 ? false : true}>
            <div class="col-lg-5">
                <div class="mb-3">
                    <label for={index-"exampleFormControlInput1"} class="form-label">Dialogue</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        id= {index-"exampleFormControlInput1"}
                        placeholder="Enter Dialogue"
                        onChange={(event) => handleChange(event, index)}
                        value={item.dialoguename}
                        name = "dialoguename"
                        key = {index}
                    />
                </div>
            </div>
            <div class="col-lg-5">
                <div class="mb-3">
                    <label for="exampleFormControlInput2" class="form-label">Description</label>
                    <input 
                        type="text" 
                        class="form-control" 
                        id="exampleFormControlInput2" 
                        placeholder="Description" 
                        onChange={(event) => handleChange(event, index)}
                        value = {item.description}
                        name = "description"
                        key = {index}
                    />
                </div>
            </div>
            {(inputFieldsCount-1)==index && 
                <div class="col-lg-2">
                    <div class="d-flex"  >
                        <button 
                            onClick={handleAdd} 
                            disabled={!item.description || !item.dialoguename} 
                            style={{border:"none"}}
                        >
                            <AddPlus />
                        </button>
                        Add
                    </div>
                </div>
            }
        </div>
    )
}

export default UserProjectDialogueForm;
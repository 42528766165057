import React from 'react'
import { postNewPackageData, postNewPackageDetailData } from "../store/nfr.actions";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from 'react';
import { useMsal } from "@azure/msal-react";
import MasterFields from './MasterFields';



function Package({
    handleSave,inputFields,setInputFields,editdata1, value,setValue,customDiv,cancelLoc
}) {


    return (
        <>
           <div class="file_container Addpackage">
                    <MasterFields inputFields={inputFields} setInputFields={setInputFields} value={value} setValue={setValue} customDiv={customDiv} editdata1={editdata1} />
                    <div class="row mt-4">
                        <div class="col-lg-12">
                            <button type="button" class="btn btn-outline-secondary btn_custom_secondary" onClick={() => { window.location = cancelLoc }}>Cancel</button>
                            <button type="button" class="btn btn-primary btn_custom_primary" onClick={handleSave}>Save</button>

                        </div>
                    </div>
                </div>

        </>

    )
}

export default Package

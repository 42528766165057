import { ReactComponent as Three_dots_vertical } from '../../images/three_dots_vertical.svg';
import { Handle, Position } from 'reactflow';
import { useState } from 'react';

function EndConversationNode(props) {
  //
  const [message, setMessage] = useState(props.data.message);
 
  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Top} />
            <div className="card bg-secondary text-white" style={{borderRadius: "inherit"}}>
                <div className="card-header">End<label style={{paddingLeft: "15rem"}} onClick={props.data.onDelete}><Three_dots_vertical class="react_icon_small_fill"/></label></div>
                <div className="card-body">
                   End of Conversation
                </div> 
            </div>
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
}

export default EndConversationNode;


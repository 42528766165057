import PlusMenuRight from "../../components/PlusMenuRight";
import DropAreaScreen from "../../components/DropAreaScreen";
import {fetchControlData, fetchControlDetailData} from "../store/inputControl.actions";
import { useDispatch, useSelector } from "react-redux";
import React, { memo, useEffect, useState } from 'react';
import { useLocation, useNavigate, Route } from "react-router-dom";
import { ToastProvider, useToasts } from 'react-toast-notifications';
import {  fetchDropAreaData, fetchDetailDropAreaData } from '../store/dropArea.actions';
import axios from "axios";
import uuid from 'react-uuid';
import { getProjectDetails,putUserProjectData} from '../store/userProject.actions';
import WebStudio from "../WebStudio";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";


var list1 = [];

// var setfigitem = false;



function InsertControl(openToggle,setOpenToggle,sidebarhide, setSidebarhide, isActive, setActive){

    const location = useLocation();  
    //)
    localStorage.setItem("userprojectid",location.search.replace("?_id=",""))

    const dispatch = useDispatch();

    const { addToast } = useToasts();
    const {instance, accounts, inProgress} = useMsal();
    const [listControls, setlistControls] = useState([]);
    const [jsonfinal, setJsonFinal] = useState([]);
    const [pages, setPages] = useState([]);
    const[selectedPage, setselectedPage] = useState("default");
    const[figFirstPage, setFigFirstPage] = useState();
    const [show3, setShow3] = useState(false);
    const [setfigitem, setsetfigitem] = useState(false);
    const [setnonfigitem, setsetnonfigitem] = useState(false);
    let [final, setFinal] = useState([]);
    const[Entity,setEntity] = useState("");


    const ProjectData = useSelector((state) => state.commonReducer?.projectdetails?.ProjectDetails?.projectdetails);

    useEffect(()=>{

        if(ProjectData!== undefined && ProjectData?.length>0){
            // dispatch(fetchDropAreaData(ProjectData?.[0]?.projecttypeid))
            // dispatch(fetchDetailDropAreaData(ProjectData?.[0]?.projecttypeid, ProjectData?.[0]?.themeid))

        }

    },[ProjectData])

    const dropAreaData = useSelector((state) => state.commonReducer?.droparea?.dropAreaData?.dropAreaData);
    const dropAreaDataDetails = useSelector((state) => state.commonReducer?.droparea?.dropAreaDataDetails?.dropAreaDataDetails);
    //

    useEffect(()=>{
        // 
        // )

        if(dropAreaData!== undefined && dropAreaData?.length > 0 && dropAreaDataDetails?.length > 0 && setfigitem === false 
             && ProjectData?.[0]?.pagecontrols === undefined 
             && ProjectData?.[0]?.figmatoken !== undefined && ProjectData?.[0]?.figmafile !== undefined && ProjectData?.[0]?.figmafile !== "" &&
            ProjectData?.[0]?.figmatoken !== "" && ProjectData?.[0]?.figmafile !== ""
            ){
                //
                //renderfigmaelements(ProjectData[0]?.figmatoken, ProjectData[0]?.figmafile,undefined)

            }
        // else if(ProjectData[0]?.pagecontrols?.length > 0 && setnonfigitem === false){
        //     
        //     setsetnonfigitem(true)
        //     setPages(ProjectData[0]?.pagecontrols?.map(x=>x.filename).flat(1))
        //     setselectedPage(ProjectData[0]?.pagecontrols?.map(x=>x.filename).flat(1)[0])
        //     setlistControls(ProjectData[0]?.pagecontrols?.map(x=>x.controls).flat(1))
        //     setFinal(ProjectData[0]?.pagecontrols)
            
        // }
        if(ProjectData?.[0]?.screencomponents?.length > 0){
            //
            setPages(ProjectData?.[0]?.screencomponents?.map(x=>x.filename).flat(1))
            setselectedPage(ProjectData?.[0]?.screencomponents?.map(x=>x.filename).flat(1)[0])
            // setlistControls(ProjectData[0]?.pagecontrols?.map(x=>x.controls).flat(1))
            setFinal(ProjectData?.[0]?.screencomponents)
            
        }

    },[dropAreaData])

    //

    useEffect(() => {

        dispatch(fetchControlData())
        dispatch(fetchControlDetailData())

    }, [dispatch])
 
    const renderfigmaelements = (figmatoken,figmafile,pagename) =>{

        ;
        list1=[];
        // var list1 = [];
        // figmatoken = "figd_3Kc-2pKHetf4gj4i1yXwY24nuBTeLIE0lw2JlV1W"
        // figmafile = "FBxJtY0q82uOBWL6jWGzCD"
        // setfigitem = true;
        setsetfigitem(true)
        const options = {

            headers: {'X-Figma-Token': figmatoken}
        
          };
        //
        axios.get('https://api.figma.com/v1/files/'+ figmafile, options).

        then(response => {

          let main = response.data;

          axios.get('https://api.figma.com/v1/files/'+ figmafile +'/images', options).

          then(images => {

          let imagesArr = images.data.meta.images;

         
        //specific pages
        //   if(pagename !== undefined)

        //   var pages2 = main["document"]["children"].filter(x=>x.name === pagename).map(x=>x.children)[0]

        //   else{

        //     var pages = main["document"]["children"].map(x=>x.children)[0]
        //     pagename = main["document"]["children"].map(x=>x.name)[0]
        //     //[0] )

        //     setselectedPage(main["document"]["children"].map(x=>x.name)[0])

        //     setFigFirstPage(main["document"]["children"].map(x=>x.name)[0])

        //   }
          setselectedPage(main["document"]["children"].map(x=>x.name)[0])

          setFigFirstPage(main["document"]["children"].map(x=>x.name)[0])

         //all pages
         //var pages = main["document"]["children"].map(x=>x.children)

          setPages(main["document"]["children"].map(x=>x.name))

          //pagesFigma = main["document"]["children"].map(x=>x.name)

          //)

          ////
          var top = main["document"]["children"]
         var loop = new Promise((resolve, reject)=>{
            top.forEach((subpages, index, array)=>{
                //)
    
                var pages1 = subpages.children.map(x=>x.children)[0].sort((a, b)=> {
                    //
                    if (a.absoluteBoundingBox.y  === b.absoluteBoundingBox.y ){
        
                      return a.absoluteBoundingBox.x < b.absoluteBoundingBox.x ? -1 : 1
        
                    } else {
        
                      return a.absoluteBoundingBox.y  < b.absoluteBoundingBox.y  ? -1 : 1
        
                    }})  
        
                    var pagename = subpages.name;
    
                    //
    
                    pages1.forEach(children=>{
                        //
        
                        //
        
                        // setlistControls(list1)
        
                        if(children.type === 'RECTANGLE' && children.name.startsWith("txt")){
        
                            //
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Text Input")[0]])
                            //let obj = {id="",name="Text Input",}
                            //list1.push(dropAreaData.filter(x=>x.name === "Text Input")[0])
                            var name = "Text Input";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight}
                            list1.push(obj)
        
        
                        }else if(children.name.startsWith("txt")){
        
                            //
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Text Input")[0]])
                            //let obj = {id="",name="Text Input",}
                            //list1.push(dropAreaData.filter(x=>x.name === "Text Input")[0])
                            var name = "Label";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight, innertext:children.characters}
                            list1.push(obj)
        
        
                        }else if(children.name.startsWith("btn")){
        
                            //[0])
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Button")[0]])
                            // let codesnippet = <button className="btn btn-primary"  id="hello"  onClick={handleButtonClick} >
                            //     Primary
                            // </button>
                            // let obj = {item:dropAreaData.filter(x=>x.name === "Button")[0], cs : renderElem("Button")}
                            // list1.push(obj)
                            // )
                            //list1.push(dropAreaData.filter(x=>x.name === "Button")[0])
                            var name = "Button";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight,innertext: children.children.filter(x=>x.name.startsWith("btnlbl"))[0].characters }
                            list1.push(obj)
        
                        }else if(children.name.startsWith("dg")){
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Grid")[0]])
        
                            //list1.push(dropAreaData.filter(x=>x.name === "Grid")[0])
                            var name = "Grid";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight, updated:{} }
                            list1.push(obj)
        
        
                        }else if(children.name.startsWith("dd")){
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Drop Down")[0]])
        
                            //list1.push(dropAreaData.filter(x=>x.name === "Drop Down")[0])
                            var name = "Drop Down";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight }
                            list1.push(obj)
        
        
                        }else if(children.name.startsWith("cb")){
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Check Box")[0]])
        
                            //list1.push(dropAreaData.filter(x=>x.name === "Check Box")[0])
                            var name = "Check Box";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight }
                            list1.push(obj)
        
        
                        }else if(children.name.startsWith("dp")){
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Date Picker")[0]])
        
                            list1.push(dropAreaData.filter(x=>x.name === "Date Picker")[0])
                            var name = "Date Picker";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight }
                            list1.push(obj)
        
        
                        }
                        else if(children.name.startsWith("hh")){
        
                            //setlistControls([...listControls,dropAreaData.filter(x=>x.name === "Date Picker")[0]])
        
                            //list1.push(dropAreaData.filter(x=>x.name === "Horizontal Gallery")[0])
                           // let obj = {item:dropAreaData.filter(x=>x.name === "Horizontal Header")[0], cs : renderElem("Horizontal Header")}
                            // if(children.type === "GROUP")
                            // {
                            //     obj.props = {innertext: children.children.filter(x=>x.name.startsWith("btnlbl"))[0].characters}
                            // }
                            // let obj = {id:uuid(),name:"Horizontal Header",type:"Horizontal Header" }
        
                            // list1.push(obj)
                            var name = "Horizontal Header";
                            var filter = dropAreaData.filter(x=>x.name === name)[0]
                            let obj = {id:uuid(),name:name,type:name, controltypeid: filter.controltypeid,filepath:"src/"+pagename, filename:pagename, fixedHeight: filter.fixedHeight}
                            list1.push(obj)
        
                        }
                     
        
           
        
                    })
                    if(index === array.length - 1)
                    resolve();
              })
            
              
         }) 

         loop.then(()=>{
            //setlistControls(list1)
            var newArr = [];
            //)
            var pagestobeadded = main["document"]["children"].map(x=>x.name)
            var putloop = new Promise((resolve, reject)=>{
                pagestobeadded?.forEach((page1, index, array)=>{
                    let obj = {
                        //_id:data.replace("?_id=",""),
                        pcid:uuid(),
                        filename:page1,
                        filepath:"src/"+page1,
                        controls : list1.filter(y=>y.filename === page1),
                    }
                    newArr.push(obj)

                    if(index === array.length - 1)
                    resolve();
                 }) 
            })
            
            putloop.then(()=>{
                if(newArr.length > 0){
                    let data1 = {
                        _id:location.search.replace("?_id=",""),
                        pagecontrols:newArr
                    }
                    //
                    dispatch( putUserProjectData({data:data1,instance:instance}) ).then((res)=>{
                        //
                        if(instance.getAllAccounts()[0] !== undefined){
                        dispatch(getProjectDetails(location.search.replace("?_id=",""))).then((res1)=>{
                            setFinal(res1.payload.projectdetails?.[0]?.pagecontrols)
                            setlistControls(list1)
                            //.flat(1))
                        })
                    }
                    })
                }
            })
            
            })
             })

       

     

         

        })

        return list1;

    }
    //
    const renderDroppedItem = (item) => {
        //
            if (item.name == "Button") {
                return (
                    <button
                        className="btn btn-primary"
                        id={item.id}
                    >
                        Primary
                    </button>
                )
            }
            
           
    }
    useEffect(()=>{

        //
        

    },[selectedPage])
    //
    ////

    //
    return(

        <ToastProvider  placement="top-center" >

            <div class="d-flex h-100">

               

                <PlusMenuRight addToast={addToast} pages={pages} selectedPage={selectedPage} setselectedPage={setselectedPage} openToggle={openToggle} setOpenToggle={setOpenToggle}  sidebarhide={sidebarhide} setSidebarhide={setSidebarhide} isActive={isActive} setActive={setActive} Entity={Entity} setEntity={setEntity}/>  

               

                {/* <DropAreaScreen

                list1 = {listControls}
                setlistControls = {setlistControls}
                selectedPage = {selectedPage}
                pages = {pages}   
                final = {final}
                setFinal = {setFinal}  
                dropAreaDataDetails = {dropAreaDataDetails}         
                 /> */}

               <WebStudio
               list1 = {listControls}
               setlistControls = {setlistControls}
               selectedPage = {selectedPage}
               pages = {pages}   
               screencomponents = {final}
               setFinal = {setFinal}  
               dropAreaDataDetails = {dropAreaDataDetails}  
               Entity = {Entity} 
               setEntity={setEntity}
               />

               

           </div>

           </ToastProvider>

       

    )

}



export default InsertControl;
import axios from "axios";
import { RequestHandler } from "../../libs/request-handler";

const client = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL 
  });

  const client1 = axios.create({
    baseURL: process.env.REACT_APP_BASE_URL1 
  });
  async function generateClient(instance){
    const accessTokenRequest = { scopes: [process.env.REACT_APP_API_CONFIGS_SCOPE], account: instance.getAllAccounts()[0], }; 
    // 
    const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
    return `Bearer ${accessTokenResponse.accessToken}`;
}
class DropAreaService extends RequestHandler {
    
    //fetch all Input Controls data
    async getInputControls(projecttypeid) {
        // 
        //
        const res = await client.get("/listcontrols?projecttypeid="+projecttypeid);
        //
        return res.data

    }
    async getInputControlsDetials(typeid,themeid) {
        // 
        const res = await client.get("/listcontroldetails?projecttypeid="+typeid+"&themeid="+themeid);
        //
        return res.data

    }
    async getalldatasource(data, instance) {
        // 
        let api = data!== null?"/datasource?search="+data:"/datasource";
        const res = await this.requestHandler(instance).get(api);
        //
        return res.data

    }
    async postDataSource(data,instance) {
        // 
        // const accessTokenRequest = { scopes: this.scope, account: instance.getAllAccounts()[0], }; 
        // // 
        // const accessTokenResponse = await instance.acquireTokenSilent(accessTokenRequest); 
        // var heads = `Bearer ${accessTokenResponse.accessToken}`;
        // // 
        const res = await client1.post("/datasource/create-datasource",data
        // ,{headers: { "Authorization": heads },}
        );
        //
        return res.data

    }
}

export default new DropAreaService;
// src/ImageUpload.js

import React, { useState } from 'react';
import './ImageUpload.css';
import ImageIcon from '../../images/upload.png';

const ImageUpload = ({ onImageUpload, imagePreview, setImagePreview }) => {

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onloadend = () => {
      const base64String = reader.result;
      setImagePreview(base64String);
      onImageUpload(base64String);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  return (
    <div className="image-upload">
      <label htmlFor="file-upload" className="custom-file-upload">
        {imagePreview ? (
          <img src={imagePreview} alt="Uploaded" className="preview-image" />
        ) : (
          <img src={ImageIcon} alt="Uploaded" className="preview-image" />
        )}
      </label>
      <input id="file-upload" type="file" accept="image/*" onChange={handleImageUpload} />
    </div>
  );
};

export default ImageUpload;
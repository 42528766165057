import React, { useEffect, useState } from "react";
import { decode as base64_decode, encode as base64_encode } from "base-64";
import { X } from "react-bootstrap-icons";
import { ProSidebarProvider } from "react-pro-sidebar";
import { Sidebar, Menu, MenuItem, SubMenu } from "react-pro-sidebar";
import { Octokit } from "octokit";
import { FaCode, FaRegCalendarAlt, FaRocketchat, FaFile } from "react-icons/fa";
import { FaHamburger } from "react-icons/fa";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchGitURL,
  getProjectNameGitUrl,
  postDownloadZipLink,
} from "../store/userProject.actions";
import { useLocation } from "react-router-dom";
import { ReactComponent as Setting_white } from "../../images/setting_white_icon.svg";
import { ReactComponent as Download_icon } from "../../images/Icon_download.svg";
import { ReactComponent as Deploy_icon } from "../../images/Icon_deploy.svg";
import { ReactComponent as File_icon } from "../../images/Icon_feather_file.svg";
import { ReactComponent as Transfer_icon } from "../../images/transfer_icon.svg";
import { ReactComponent as Publish_icon } from "../../images/publish_icon.svg";
import Offcanvas from "react-bootstrap/Offcanvas";
import CodeExplanation from "../../components/CodeExplanation";
import axios from "axios";
import { ReactComponent as QuestionMark } from "../../images/question_mark.svg";
import Tooltip from "react-bootstrap/Tooltip";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import { SlideDown } from "react-slidedown";
import { ReactComponent as CloseWhite } from "../../images/close_white.svg";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";

// import ExplorerFolder from "./ExplorerFolder";

//var urll ='https://github.com/JaspreetAhden24/Myproject/zipball/master'
var foldersfilename;
var filename;
var folderrname;
var mainURL;
var mainSubURL;
var gitur;
var totalNoOfFiles;
var count;
var decoded;
var accesstoken;
var projectName;
var downlink;
var userProjectID;
var targetRepoGit = false;
var isExplorerLoaded = false;
var elements;
var azureDeployURL = "";

function Explorer(openToggle, setOpenToggle) {
  const dispatch = useDispatch();
  const location = useLocation();
  const {instance, accounts, inProgress} = useMsal();

  ////
  const data = location.search;
  const [show, setShow] = useState(false);
  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);
  useEffect(() => {
    dispatch(getProjectNameGitUrl({data:data,instance:instance}));
  }, [dispatch]);

  useEffect(() => {
    localStorage.getItem("toggle");
    //);
  }, [localStorage.getItem("toggle")]);

  const [content, setContent] = useState([]);
  const [FileContent, setFileContent] = useState([]);
  const [folderFileContent, setFolderFileContent] = useState([]);
  const [finalTree, setFinalTree] = useState([]);
  const [sliderClose, setsliderClose] = useState(true);
  const [ai, setai] = useState("");
  let finalUrl = "";
  let url = "";
  let urlpath = [];

  const getDetails = useSelector(
    (state) =>
      state.commonReducer?.singleProjectName?.singleProjectName
        ?.singleProjectName
  );
  const [getDetailsData, setgetDetailsData] = useState(getDetails);
  useEffect(() => {
    setgetDetailsData(getDetails);
  }, [dispatch, getDetails]);
 

  if (getDetailsData !== undefined && getDetailsData.length > 0) {
    userProjectID = getDetailsData[0]?.id;
    projectName = getDetailsData[0]?.projectname;
   // 
    projectName = projectName.replace(/\s/g, "");
    azureDeployURL = getDetailsData[0]?.deploymenturl;
    //
    if (getDetailsData[0]?.treeURL !== undefined && getDetailsData[0]?.treeURL.includes("github")) {
      targetRepoGit = true;
      var urlll = getDetailsData[0]?.treeURL;
    
      gitur = getDetailsData[0]?.treeURL?.replace("https://api.github.com", "");
      //
      mainURL = "GET " + gitur;
      //
      mainSubURL = mainURL?.substring(0, mainURL.indexOf("/git"));
      //
      //access token
      accesstoken = getDetailsData[0]?.accessToken;
      //
    }
    //
  }
  function handleDownload() {
    //
    //;
    var isGitHub = getDetailsData[0]?.treeURL.includes("github")
    
    if(isGitHub){
      axios
      .post(
        process.env.REACT_APP_BASE_URL+"downloadzip",
        {
          reponame: projectName,
          token: accesstoken,
        }
      )
      .then((res) => {
        //
        downlink = res.data;
        const anchor = document.createElement("a");
        anchor.href = res.data;
        anchor.setAttribute("download", res.data);
        document.body.appendChild(anchor);
        anchor.click();
        //
      });
    }else{
      const AuthStr = 'Bearer '.concat(getDetails[0].accessToken); 
      
      var downloadlink = getDetails[0].downloadUrl+".zip?access_token="+getDetails[0].accessToken
      // var downloadlink = res.data;
      const anchor = document.createElement("a");
      anchor.href = downloadlink;
      anchor.setAttribute("download", downloadlink);
      document.body.appendChild(anchor);
      anchor.click();
      // axios
      // .post(
      //   process.env.REACT_APP_BASE_URL+"downloadzip",
      //   {
      //     reponame: projectName,
      //     token: AuthStr,
      //     downloadUrl:getDetails[0].downloadUrl
          
      //   }
      // ).then((res) => {
      //   // downlink = "https://gitlab.com/api/v4/projects/57680727/repository/archive";
      // // const downloadlink = window.URL.createObjectURL(new Blob([res.data], { type: "application/zip" }))
      // // var url = window.URL.createObjectURL(new blob[res.data]);
      // var downloadlink = getDetails[0].downloadUrl+".zip?access_token="+AuthStr
      // // var downloadlink = res.data;
      // const anchor = document.createElement("a");
      // anchor.href = downloadlink;
      // anchor.setAttribute("download", downloadlink);
      // document.body.appendChild(anchor);
      // anchor.click();


      //   
      //   setContent(treeData);
      // });
      
    }
 
  }
  var treeData = [];
  //for homepage
  const getContents = async () => {
    var response;
    

    if (targetRepoGit) {
      //
      const octokit = new Octokit({
        // auth: "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",
        auth: getDetailsData[0]?.accessToken,
      });
      response = await octokit.request(mainURL);
      totalNoOfFiles = response.data.tree.length - 2;
      treeData = response.data.tree;
      //
      setContent(treeData);
    } else if(getDetails[0].treeURL.includes("gitlab")){
      

      const AuthStr = 'Bearer '.concat(getDetails[0].accessToken); 
      

      axios.get(getDetails[0].treeURL.replace("100","1000"),{ headers: { Authorization: AuthStr } }).then((res) => {
        totalNoOfFiles = res.data.length - 2;
        treeData = res.data;


        
        setContent(treeData);
      });
    }else {
      //
      var azureFileListURL =
        process.env.REACT_APP_BASE_URL +
        "ListAllItemsInContainer?userprojectid=" +
        userProjectID +
        "&folderPath=" +
        projectName;
      axios.get(azureFileListURL).then((res) => {
        totalNoOfFiles = res.data.length - 2;
        //
        treeData = res.data;
        //
        setContent(treeData);
      });
    }
  };
  useEffect(() => {
    if (userProjectID !== undefined) {
      getContents().catch(
       // console.error
        );
    }
  }, [userProjectID]);

  function SortJSON(prop, asc, arr) {
    arr.sort(function (a, b) {
      if (asc) {
        return a[prop] > b[prop] ? 1 : a[prop] < b[prop] ? -1 : 0;
      } else {
        return b[prop] > a[prop] ? 1 : b[prop] < a[prop] ? -1 : 0;
      }
    });
  }
 
  const GetFolderStrutureElements = (tree) => {
    if ( tree.length > 0) {
      SortJSON("type", false, tree);
      elements = GetDataElements(tree, "", 1, "");
    }
    // setfolderS(elements)
    return elements;
  };

 

  const GetDataElements = (tree, filePath, level) => {
    //alert("GetDataElements level " + level + ":" + tree);

    var newDataLevel1 = [];
    if (level === 1) {
      newDataLevel1 = tree?.filter(
        (data) =>
          !data.path.includes(filePath + "/") &&
          data.path.split("/").length === level
      );
    } else {
      newDataLevel1 = tree?.filter(
        (data) =>
          data.path.includes(filePath + "/") &&
          data.path.split("/").length === level
      );
    }
    var newDataSubs = tree?.filter((data) => data.path.includes("/"));
    let myList = newDataLevel1.map((temp, key) => {
      var fileName = temp.path;
      if (fileName !== "" && fileName.split("/").length > 0)
        fileName = fileName.split("/")[fileName.split("/").length - 1];
      if (temp.type === "tree") {
        return (
          <SubMenu
            label={fileName}
            key={fileName}
            onClick={showFileContent(temp.path, temp.type)}
          >
            {GetDataElements(
              newDataSubs,
              temp.path,
              temp.path.split("/").length + 1,
              fileName
            )}
          </SubMenu>
        );
      } else {
        return (
          <MenuItem
            key={fileName}
            onClick={showFileContent(temp.path, temp.type)}
          >
            {fileName}
          </MenuItem>
        );
      }
    });
    return myList;
  };
  ////

  //display folder contents
  const getUrl = (file) => (e) => {
    folderrname = file.path;
    const octokit = new Octokit({
      //    auth: "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",
      auth: getDetailsData[0]?.accessToken,
    });
    const response = octokit.request(mainURL).then((response) => {
      let tree = response.data.tree;
      //
      for (let index = 0; index < tree.length; index++) {
        //);
        if (tree[index].path === folderrname) {
          url = tree[index].url;
        }
      }
      //
      content.filter((x) => x.path === folderrname);
      //  => x.path === folderrname)
      // );
      getFolderContents(url, file, folderrname);
      //getFolderContentsNew(tree, file, folderrname);
    });
  };
  const getFolderContents = async (url, file, folderrname) => {
    //alert(folderrname);
    const octokit = new Octokit({
      // auth: "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",
      auth: getDetailsData[0]?.accessToken,
    });

    finalUrl = url;
    //
    finalUrl = finalUrl.replace("https://api.github.com", "");
    const response = await octokit.request(`GET ${finalUrl}?recursive=1`);
    //
    let tree = response.data.tree;
    tree = tree.map((temp) => {
      return {
        parent: folderrname,
        path: temp.path,
        type: temp.type,
        url: temp.url,
        subpath: [],
      };
    });
    file.subpath = tree;
    setFinalTree(tree);
  };
  //
  //Show file content
  const showFileContent = (filename, type) => (e) => {
    
    if (type == "tree") setFileContent("");
 
    else {
      if (targetRepoGit) {
        const octokit = new Octokit({
          //    auth: "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",
          auth: getDetailsData[0]?.accessToken,
        });
        octokit
          .request(`${mainSubURL}/contents/${filename}`)
          .then((response) => {
            showFileContentInPanel(response.data.content, filename);
          });
          
      }   else if(getDetails[0].treeURL.includes("gitlab")){
        
        const AuthStr = 'Bearer '.concat(getDetails[0].accessToken); 

        axios.get(getDetails[0].contentUrl?.replace("path",filename?.replace("/","%2F"))
        ,{ headers: { Authorization: AuthStr } }).then((res) => {
          totalNoOfFiles = res.data.length - 2;
          treeData = res.data;
          if(filename.includes("json"))
          setFileContent(JSON.stringify(treeData, null, "\t")
          .replaceAll(
              "],\n\t\"", 
              "],\n\n\t\""
          ));
          else if (filename.includes(".png")) {
            var imageData = res.data;
            
            let tes  = window.btoa(String.fromCharCode.apply(null, imageData.replace(/\r|\n/g, "").replace(/([\da-fA-F]{2}) ?/g, "0x$1 ").replace(/ +$/, "").split(" ")));
            const byteArray = new Uint8Array(imageData);
            let image = new Blob([imageData], { type: "image/png" });
            // let base64ImageString = Buffer.from(res.data, 'binary').toString('base64')
            // let srcValue = "data:image/png;base64,"+base64ImageString

            let imageUrl = URL.createObjectURL(image);
            var fileContent = <img src={imageUrl}></img>;
            setFileContent(fileContent);

          }
          else
          setFileContent(treeData);

          
          // showFileContentInPanel(res.data.content, filename);
          // var imageData = res.data;
          //   //
          //   const byteArray = new Uint8Array(imageData);
          //   let image = new Blob([byteArray], { type: "image/png" });
          //   let imageUrl = URL.createObjectURL(image);
          //   var fileContent = <img src={imageUrl}></img>;
          // setFileContent(fileContent);

        });
      } else {
        const baseURL = process.env.REACT_APP_BASE_URL;
        const azureFileContentURL = `${baseURL}GetAzureBlobContent?userprojectid=${userProjectID}&filePath=${filename}`;
        //
        axios.get(azureFileContentURL).then((res) => {
          var fileContent;
          if (filename.includes(".json"))
            fileContent = JSON.stringify(res.data);
          else if (filename.includes(".png")) {
            var imageData = res.data;
            //
            const byteArray = new Uint8Array(imageData);
            let image = new Blob([byteArray], { type: "image/png" });
            let imageUrl = URL.createObjectURL(image);
            fileContent = <img src={imageUrl}></img>;
          } else fileContent = res.data;
          setFileContent(fileContent);
        });
      }
    }
  };

  const showFileContentInPanel = (dataContent, filename) => {
    var fileContent;
    if (targetRepoGit) {
      if (filename.includes(".png")) {
        const byteCharacters = window.atob(dataContent);
        const byteNumbers = new Array(byteCharacters.length);
        for (let i = 0; i < byteCharacters.length; i++) {
          byteNumbers[i] = byteCharacters.charCodeAt(i);
        }
        const byteArray = new Uint8Array(byteNumbers);
        let image = new Blob([byteArray], { type: "image/png" });
        let imageUrl = URL.createObjectURL(image);
        fileContent = <img src={imageUrl}></img>;
      } else {
        fileContent = base64_decode(dataContent);
        setai(fileContent);
      }
    }
    
    setFileContent(fileContent);
  };
  

  //Display Folder File Contents
  const showFolderFileContent = (file) => (e) => {
    folderrname = file.parent;
    //
    foldersfilename = file.path;
    //
    const octokit = new Octokit({
      //  auth: "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",
      auth: getDetailsData[0]?.accessToken,
    });
    //

    const response = octokit
      .request(`${mainSubURL}/contents/${folderrname}/${foldersfilename}`)
      .then((response) => {
        // 
        // 

        if (foldersfilename.includes(".png")) {
          decoded = (
            <img
              src={`https://raw.githubusercontent.com/RapidX-Developer-Studio/CheckCount/main/${folderrname}/${foldersfilename}`}
            ></img>
          );
          //decoded = <img src={response.data.content}></img>
        } else {
          decoded = base64_decode(response.data.content);
        }
        //
        setFolderFileContent(decoded);
        // 
        setFileContent("");
        // document.querySelector("#activeli").hidden = false
        setai(decoded);
      });
  };
  //
  //

  // function closeFile() {
  //   document.querySelector("#activeli").hidden = "true";
  //   setFileContent("")
  //   setFolderFileContent("")
  // }
  // var
  useEffect(() => {
    if (openToggle.openToggle === true)
      document.querySelector("#hidediv").className =
        "Folderstructure_menu flex-fill";
  }, [openToggle.openToggle]);
  //
  return (
    <>
      <div class="d-flex h-100">
        <div class="Folderstructure_menu flex-fill" id="hidediv">
          <div className="sidebar_main d-flex flex-column h-100">
            <div class="Explorermenu_right d-flex flex-column h-100 ">
              <div class="d-flex bordercolor insertdivss mb-3 ">
                <div className="font_14 font_regular ">Explorer</div>
                <X
                  onClick={() => {
                    openToggle.setOpenToggle(false);
                    document.querySelector("#hidediv").className = "d-none";
                  }}
                  className="react_icon_small ms-auto"
                />
              </div>
              <div class="d-flex h-100 mt-3 plus_menus explorermaindiv ">
                {/* <ProSidebarProvider>
                            <Sidebar>
                                <Menu>
                                {content.map((file)=>(
                                file.type === "tree" ?
                                < SubMenu label={file.path}   id="foldername" onClick={getUrl}>
                                        {finalTree.map((file1)=>(
                                        <MenuItem   id="folderfilename" onClick={(e)=>props.showFileFolderHandler(e)}> {file1.path}</MenuItem> 
                                        ))}
                                    </SubMenu>  :
                                    <MenuItem onClick={(e)=>props.showFileContentHandler(e)}  id="filename"> {file.path}</MenuItem>  
                                ))}                                                  
                                    
                                    </Menu>
                            </Sidebar>
                        </ProSidebarProvider> */}

                {/* <ProSidebarProvider>
                  <Sidebar>
                    <Menu>
                      {content.map((file) =>
                        file.type === "tree" ? (
                          <SubMenu
                            label={file.path}
                            id="foldername"
                            value={file.path}
                            onClick={getUrl(file)}
                          >
                            {file?.subpath?.map((file1) => (
                              <MenuItem
                                id="folderfilename"
                                onClick={showFolderFileContent(file1)}
                              >
                                {file1.path}
                              </MenuItem>
                            ))}
                          </SubMenu>
                        ) : (
                          <MenuItem
                            onClick={showFileContent(file)}
                            id="filename"
                          >
                            {" "}
                            {file.path}
                          </MenuItem>
                        )
                      )}
                    </Menu>
                  </Sidebar>
                </ProSidebarProvider> */}
                <ProSidebarProvider>
                  <Sidebar>
                    <Menu>{GetFolderStrutureElements(content)}</Menu>
                  </Sidebar>
                </ProSidebarProvider>
              </div>
              <div className="whitespace_nowrap d-flex align-items-center">
                <div className="me-4 font_12">
                  No of files : <sapn>{totalNoOfFiles}</sapn>
                </div>
                {/* <div className='font_12'>Lines of code : <span></span></div> */}
              </div>
            </div>
          </div>
        </div>
        <div class="flex-fill contentmain_container">
          <div class="screen_two  ">
            <div class="file_name_container d-none">
              <ul className="topinnerul">
                <li id="hamburger" hidden={true}>
                  <div class="file_name_list align-items-center d-flex">
                    <FaHamburger
                      className="react_icon_small"
                      onClick={() => {
                        document.querySelector("#hidediv").className =
                          "Folderstructure_menu flex-fill";
                        document.querySelector("#hamburger").hidden = true;
                      }}
                    />
                  </div>
                </li>
                {/* <li className='active' id='activeli' hidden={true}>
                  <div class="file_name_list align-items-center d-flex">
                    <div><FaFile className='react_icon_vsmall' /></div>
                    <div className='font_10 font_light pt-1 pe-4 ps-2'>{FileContent !== "" ? filename : foldersfilename}</div>
                    <div><X className='react_icon_vsmall' onClick={closeFile} /></div>
                  </div>
                </li> */}
              </ul>

              <div className="ms-auto whitespace_nowrap d-flex flex-row align-items-center"></div>
            </div>
            <div class="file_container dnd">
              {/* {content.map((file)=>(
                              file.type === "blob"
                          ))}  */}
              {
                // FileContent !== "" ? (
                //   <pre>
                //     <code>{FileContent}</code>
                //   </pre>
                // ) : (
                //   <pre>
                //     <code>{folderFileContent}</code>
                //   </pre>
                // )
                <pre>
                  <code>{FileContent}</code>
                </pre>
              }
            </div>
          </div>
        </div>
        <Offcanvas
          className="propertiescanvas"
          show={show}
          onHide={handleClose}
          backdrop={false}
          placement="end"
        >
          <CodeExplanation data={ai} projectDetails={getDetailsData?.[0]} getContents={getContents}/>
        </Offcanvas>
        <div class="right_float_container">
          <p
            hidden={!sliderClose}
            className="set_icon mb-0"
            onClick={(e) => {
              setsliderClose(!sliderClose);
            }}
          >
            <Setting_white />
          </p>
          <p
            hidden={sliderClose}
            className="set_icon mb-0"
            onClick={(e) => {
              setsliderClose(!sliderClose);
            }}
          >
            <CloseWhite />
          </p>
          <SlideDown className={"my-dropdown-slidedown"} closed={sliderClose}>
            <ul class="m-0 p-0 float_tab_icons explorer">
              <li>
                <QuestionMark
                  class="react_icon_small_fill"
                  onClick={handleShow}
                />
              </li>
              <li>
                <Download_icon
                  className="react_icon_small_fill"
                  onClick={() => {
                    handleDownload();
                  }}
                >
                  Download Code
                </Download_icon>
              </li>
              {azureDeployURL !== "" && azureDeployURL !== undefined ? (
                <li>
                  <a href={azureDeployURL} target="_blank">
                    <Deploy_icon className="react_icon_small_fill"></Deploy_icon>
                  </a>
                </li>
              ) : (
                ""
              )}
            </ul>
          </SlideDown>
        </div>
      </div>
    </>
  );
}

export default Explorer;

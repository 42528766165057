import { createAsyncThunk } from "@reduxjs/toolkit";
import inputControlService from "../../services/inputControlService";

export const fetchControlData = createAsyncThunk("controlstore/fetchControlData", async() => {
    
    const fetchData = async () => {
        return inputControlService.getInputControlData() 
    }

    try{
        const rawData = await fetchData();

        const layoutData = [];

        for (const layout of rawData) {
            const cardData = {
                id: layout._id,
                controltypename: layout.controltypename,
            };
            layoutData.push(cardData);
        }

        //
        return {
            controldata: layoutData?.length !==0 ? layoutData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );

//control type detail menu data
export const fetchControlDetailData = createAsyncThunk("controlstore/fetchControlDetailData", async() => {
    
    const fetchData = async () => {
        return inputControlService.getInputControlDetailData() 
    }

    try{
        const rawData = await fetchData();

        const layoutData = [];

        for (const layout of rawData) {
            const cardData = {
                id: layout._id,
                icon: layout.icon,
                name: layout.name,
                controltypeid:layout.controltypeid,
            };
            layoutData.push(cardData);
        }

        //
        return {
            controldetaildata: layoutData?.length !==0 ? layoutData : [],
            loading: false,
        };
    }
    catch(err) {
        //
    }

  }
  );
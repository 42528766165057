import React, { useState, useEffect } from 'react';
import {  Plus } from 'react-bootstrap-icons';
import Offcanvas from 'react-bootstrap/Offcanvas';
import Properties from "./Properties";
// import { useDrop } from "react-dnd";
import { DataGrid } from '@mui/x-data-grid';
import { ReactComponent as Desktop_icon } from '../images/desktop_icon.svg';
import { ReactComponent as Tablet_icon } from '../images/tablet_icon.svg';
import { ReactComponent as Mobile_icon } from '../images/mobile_icon.svg';
import { ReactComponent as Transfer_icon } from '../images/transfer_icon.svg';
import { ReactComponent as Publish_icon } from '../images/publish_icon.svg';
import { ReactComponent as Datasource } from '../images/d_source.svg';
import { ReactComponent as Closee_iconn } from '../images/closee_iconn.svg';
import { ReactComponent as Setting_white } from '../images/setting_white_icon.svg';
import { ReactComponent as Check_in } from '../images/checkin.svg';
import { ReactComponent as DeleteIcon } from "../images/delete-icon.svg";
import { ReactComponent as CloseWhite } from '../images/close_white.svg';
import RGL, { WidthProvider } from "react-grid-layout";
import { useDispatch, useSelector } from 'react-redux';
import { fetchAllDataSource } from '../main/store/dropArea.actions';
import axios from "axios";
import ReactDOM from 'react-dom/client';
import {  getProjectNameGitUrl, putUserProjectData , postCodeGeneration} from '../main/store/userProject.actions';
import { useLocation } from "react-router-dom";
import { SlideDown } from 'react-slidedown'
import 'react-slidedown/lib/slidedown.css'
import uuid from 'react-uuid';
import { ReactComponent as Edit_Icon } from '../images/edit_icon.svg'
import CommitPopup from "./commitPopup"
import io from 'socket.io-client'; 
import {  useMsal } from "@azure/msal-react";
import {loginRequest} from "../../authConfig";
import { ToastProvider, useToasts } from 'react-toast-notifications';



var socket = undefined;
var url = process.env.REACT_APP_BASE_URL;
const ReactGridLayout = WidthProvider(RGL);
var griddiv, RenderForm, sI, getID, popid,  newelem , temp;
var setfigitem = false , checkFlag = false , checkDropFlag = false;
var setRes = [], mainResList = [], dropdownKeys = [], inputArray = []
var pagenamesp = "";
var postDataList={}, putDataList ={}
let status=[]
let dropp=<></>



const rows = [
    { id: 1, col1: 'column1', col2: 'column2', col3: 'column3' },
    { id: 2, col1: 'column1', col2: 'column2', col3: 'column3' },
];

const columns = [
    { field: 'id', headerName: 'Index', flex: 1, maxWidth: 70 },
    { field: 'col1', headerName: 'Column Name-1', flex: 1 },
    { field: 'col2', headerName: 'Column Name-2', flex: 1 },
];

let idCounter = 0;

const getId = () => {
    idCounter++;

    return idCounter.toString();
};

var accesstoken;
var projectName;
var downlink
var firstArr = [];

function DropAreaScreen({list1, setlistControls, selectedPage, pages, final, setFinal}) {


    const dispatch = useDispatch();
    const location = useLocation();
    const {instance, accounts, inProgress} = useMsal();

    const data = location.search;
    let [children, setChildren] = useState([]);
    const[sliderClose, setsliderClose]=useState(true);

    // offcanvas sidebar
    const [show3, setShow3] = useState(false);
    const handleClose3 = () => setShow3(false);
    const [style, setStyle] = useState("100%");
    const [show, setShow] = useState(false);
    const [active, setActive] = useState("Desk");
    const [open, setOpen] = useState(false);
    const [Dropopen, setDropOpen] = useState(false);
    const [currentGridID, setCurrentGridID] = useState();
    const [callput, setCallPut] = useState(false)
    let [Responsee,setResponsee] = useState([])
    const [responseID,setResponseID] = useState([])
    const [accessToken, setAccessToken] = useState(null);
    const [FormID, setFormID] =  useState([])
    const [formDataResponsee, setFormDataResponsee] =  useState(false)
    const [formDataRes, setFormDataRes] =  useState(false)
    const[ popupshow, setShowPopup] = useState(false)
    let [SecondDropDown, setSecondDropDown] =  useState([])
    const [dropFlag,setDropFlag] = useState(false)
    // const { addToast } = useToasts();


    //input controls
    const [insertControlsData, setInsertControlsData] = useState([
        {
            "id": 1,
            "name": "Button"
        },

        {
            "id": 2,
            "name": "Text Input"
        },

        {
            "id": 3,
            "name": "Drop down"
        },

        {
            "id": 4,
            "name": "Date Picker"
        },

        {
            "id": 5,
            "name": "Check Box"
        }

    ]);


    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchAllDataSource({data:null, instance:instance}))        
        dispatch(getProjectNameGitUrl(data))
        }
    }, [dispatch, instance.getAllAccounts()[0] !== undefined ])
    const dropAreaData = useSelector((state) => state.commonReducer.droparea.dropAreaData.dropAreaData);
    const datasource = useSelector((state) => state.commonReducer.droparea.datasource.datasource);
    // useEffect(() => {
    //     
    //     if(dropAreaData.length > 0 && setfigitem === false)
    //     renderfigmaelements()
    // }, [dropAreaData])

    //fetch data for download as zip
    const getDetails = useSelector((state) => state.commonReducer.singleProjectName.singleProjectName.singleProjectName);
    const [getDetailsData, setgetDetailsData] = useState(getDetails);
    useEffect(() => { setgetDetailsData(getDetails) }, [dispatch, getDetails])
    // useEffect(()=>{
    //     
    //     
    //     
    //     let temp = document.querySelectorAll("#dropareaitems")
    //     

    //     if(selectedPage !== undefined && temp?.length>0){
    //         let obj = {
    //             //_id:data.replace("?_id=",""),
    //             pcid:uuid(),
    //             filename:selectedPage,
    //             filepath:"src/"+selectedPage,
    //             controls : [],
    //             yposition : ""
    //         }
    //         var orderArray = [];

    //         temp.forEach((x)=>{
    //             
    //             let tempnew = list1.filter(y=>y.id === x.children[0].id)[0]
    //             var elem = document.getElementById(x.children[0].id)
    //             tempnew.yposition =  elem.getBoundingClientRect().y;
    //             obj.controls.push(tempnew)
                
    //             // 
    //             // orderArray.push(x.children[0].dataset.myId);

    //         })   
    //         obj.controls = obj.controls.sort((a,b) => a.yposition - b.yposition)
    //         
    //         
    //         var filter = final.filter(x=>x.filename === selectedPage)
    //         
    //         if(filter.length>0){
    //             filter[0].controls = obj.controls
    //         }else{
    //             setFinal([...final,obj])

    //         }
    //         setCallPut(true);
    //     }

    // },[list1],[document.querySelectorAll("#dropareaitems")])
   

    useEffect(()=>{
        // )
        // 
        // 
        // 
        let temp = document.querySelectorAll("#dropareaitems")
       // 
            if(selectedPage !== undefined && temp?.length>0){
                let obj = {
                    //_id:data.replace("?_id=",""),
                    pcid:uuid(),
                    filename:selectedPage,
                    filepath:"src/"+selectedPage,
                    controls : [],
                }
                var orderArray = [];
    
                temp.forEach((x)=>{
                   // 
                    let tempnew = list1?.filter(y=>y?.id === x.children[0]?.id)[0]
                    var elem = document.getElementById(x.children[0]?.id)
                    //tempnew.yposition =  elem.getBoundingClientRect().y;
                    obj.controls.push(tempnew)
                    
                    // 
                    // orderArray.push(x.children[0].dataset.myId);
    
                }) 
               
                obj.controls = obj.controls.sort((a,b) => a.yposition - b.yposition)
                //
                //
                var filter = final.filter(x=>x.filename === selectedPage)
                // setFinal(final.map(z=>z.filename === selectedPage?
                //     {...z, constrols : obj.controls}:z))
                
                    let dummy = []
                    dummy = final.filter(x=>x.filename !== selectedPage)
                    //
                    dummy.push(obj)
                    //
                    //setFinal(dummy)
                    firstArr = dummy;    
                //dummy1[0].controls = obj.controls
                // )
                // 
                if(firstArr?.length > 0){
                    let data1 = {
                        _id:data.replace("?_id=",""),
                        pagecontrols:firstArr
                    }
                    dispatch( putUserProjectData(data1) )
                }
                setCallPut(true);
        }
        
        
        },[document.querySelectorAll("#dropareaitems")])
    
    // useEffect(()=>{
    //     
    //     if(firstArr?.length > 0){
    //         let data1 = {
    //             _id:data.replace("?_id=",""),
    //             pagecontrols:firstArr
    //         }
    //         dispatch( putUserProjectData(data1) )
    //     }
       
    //     setCallPut(false);

    // },[callput])
    //)
    //[0])

    // const [{ isOver, canDrop }, dropRef] = useDrop(() => ({
    //     accept: "insertControl",
    //     collect: monitor => ({
    //         canDrop: monitor.canDrop(),
    //         isOver: monitor.isOver()
    //     }),
    //     drop: (item, monitor) => {
    //         )
    //         

    //         // var elem = renderDroppedItem(item)
    //         // var temp = {id:item.id, elem:elem}
    //         // 
    //         //item.id = getId();
    //         setChildren(oldArray => [...oldArray, item]);
    //         //list1.push(item)
    //         let name = item.name;
    //         let obj = { id: uuid(), name: name, type: name, controltypeid: item.controltypeid, filepath: "src/" + pagenamesp, filename: pagenamesp, fixedHeight: item.fixedHeight, updated:{} }

    //         setlistControls(oldArray => [...oldArray, obj])
    //         

    //     }
    // }))
    useEffect(() => {
       // 
    }, [list1])
    // const isActive = canDrop && isOver;

    // const _onStop = (e, data) => {
    //     
    // }

    const handleButtonClick = (gridid) => {
        
        //
        setShow3(true);
    }
    const options = {
        headers: {'X-Figma-Token': "figd_3Kc-2pKHetf4gj4i1yXwY24nuBTeLIE0lw2JlV1W"}
      };
    useEffect(()=>{
        //
         pagenamesp = selectedPage;
        // let temp = document.querySelectorAll("#dropareaitems")
        // 

        // if(selectedPage !== undefined && temp?.length>0){
        //     let obj = {
        //         //_id:data.replace("?_id=",""),
        //         pcid:uuid(),
        //         filename:selectedPage,
        //         filepath:"src/"+selectedPage,
        //         controls : [],
        //         yposition : ""
        //     }
        //     var orderArray = [];

        //     temp.forEach((x)=>{
        //         
        //         let tempnew = list1.filter(y=>y.id === x.children[0].id)[0]
        //         var elem = document.getElementById(x.children[0].id)
        //         tempnew.yposition =  elem.getBoundingClientRect().y;
        //         obj.controls.push(tempnew)
                
        //         // 
        //         // orderArray.push(x.children[0].dataset.myId);

        //     })   
        //     obj.controls = obj.controls.sort((a,b) => a.yposition - b.yposition)
        //     
        //     
        //     var filter = final.filter(x=>x.filename === selectedPage)
        //     
        //     if(filter.length>0){
        //         filter[0].controls = obj.controls
        //     }else{
        //         setFinal([...final,obj])

        //     }
        //     setCallPut(true);
        // }

    }, [selectedPage])
    const getPagename = () => {
       // 
        return selectedPage
    }
   // 
    const renderDroppedItem = (item) => {

        if (item?.name == "Button") {
            return (
                <button
                    className="btn btn-primary"
                    id={item.id}
                    onClick={handleButtonClick}
                    name="Button"
                >
                    {item.innertext ? item.innertext : "Primary"}
                </button>
            )
        }
        else if (item?.name == "Text Input") {
            return (
                <input
                    id={item.id}
                    type="text"
                    class="form-control"
                    name={item.name}
                    placeholder="Text Input"
                    onClick={handleButtonClick}>
                </input>
            )
        }
        else if (item?.name == "Drop Down") {
            return (
                <select id={item.id} class="form-select" aria-label="Default select example" onClick={handleButtonClick}>
                    <option selected>Dropdown Menu</option>
                    <option value="1">One</option>
                    <option value="2">Two</option>
                    <option value="3">Three</option>
                </select>
            )
        }
        else if (item?.name == "Date Picker") {
            return (
                <input
                    type="date"
                    class="form-control"
                    name="date-field"
                    onClick={handleButtonClick}
                    id={item.id}
                />
            )
        }
        else if (item?.name == "Check Box") {
            return (
                <div class="form-check" id={item.id}>
                    <input
                        class="form-check-input"
                        type="checkbox"
                        value=""
                        id="defaultCheck1"
                        onClick={handleButtonClick}
                        style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                    </input>

                </div>
            )

        }
        else if (item?.name == "Label") {
            return (
                <label id={item.id} name={item.name} style={{ color: "black" }}
                    for="exampleFormControlInput1" class="form-label" onClick={handleButtonClick}>
                        {item.innertext ? item.innertext : "Label"}
                        </label>
            )

        }
        else if (item?.name === "Form") {
            var formIdNum = getId();
            //var gd = "root" + idnum;
            var idnum = getId();
            var gd = item.id;
            var popid = "pop" + idnum
            RenderForm = <div class="col-lg-6" id="forDeleteId">
                
                <div class="d-flex justify-content-end" >
                    <div class="popupmaindiv2" id="FormPlusIcon" hidden={false}>
                        <Plus draggable={false}  ondragstart={(e) => { e.preventDefault(); e.stopPropagation() }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handlepopover(popid) }} onMouseDown={ e => e.stopPropagation()} size={20} className='react_icon_vsmall' />
                        <div id={popid} class="innerpopup_open" hidden={true}>
                            <div class="d-flex align-items-start flex-row">
                                <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" onMouseDown={ e => e.stopPropagation()} />
                            </div>
                            <ul>
                                {datasource && datasource.filter(x => x.httpverb.toLowerCase() === "post" || x.httpverb.toLowerCase() === "put").map((data, index) => {
                                    return (

                                        <li className='font_14 font_regular' onClick={() => { handleControls(gd, data, popid, data.index, false) }} onMouseDown={ e => e.stopPropagation()}>
                                            <Datasource className='me-2' />{data.datasourcename}
                                        </li>

                                    )

                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div id={item.id} name={item.name}>
                    <label id={item.id} name={item.name} style={{ background: "grey", color: "white", padding: "5px" }}
                        for="exampleFormControlInput1" class="form-label w-100 bg-grey">Select any Datasource</label>

                </div>
            </div>
            return RenderForm

        }
        else if (item?.name == "Grid") {
            //var grdid = "dg"+getId()
           

            // document.querySelector("#popop").hidden = true;
            // if (griddetials !== undefined)
            //     return React.createElement(griddetials.props, null)
            // else
                var temp =  getGrid(item)
                return temp




        }
        else if (item?.name === "Horizontal Header") {
            var code = <nav class="navbar navbar-expand-lg navbar-light bg-light" id={item.id} name={item.name}>
                <a class="navbar-brand" href="#">Navbar</a>
                <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#navbarSupportedContent" aria-controls="navbarSupportedContent" aria-expanded="false" aria-label="Toggle navigation">
                    <span class="navbar-toggler-icon"></span>
                </button>

                <div class="collapse navbar-collapse" id="navbarSupportedContent">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item active">
                            <a class="nav-link" href="#">Home <span class="sr-only">(current)</span></a>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link" href="#">Link</a>
                        </li>
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                Dropdown
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" href="#">Action</a>
                                <a class="dropdown-item" href="#">Another action</a>
                                <div class="dropdown-divider"></div>
                                <a class="dropdown-item" href="#">Something else here</a>
                            </div>
                        </li>
                        <li class="nav-item">
                            <a class="nav-link disabled" href="#">Disabled</a>
                        </li>
                    </ul>

                </div>
            </nav>
            return (code)
        }
    }
    function getGrid (item){
        
        //
        if(item.griddetials === undefined){
            //

            var idnum = getId();
            //var gd = "root" + idnum;
            var gd = item.id;
            var popid = "pop" + idnum
            var open = false
           // 
            //var griddetials = item.griddetials
            var griddetials = undefined
            //if(griddetials !== undefined)
           // 
            griddiv = <div id={item.id} name={item.name} >
                <div class="d-flex justify-content-end">
                    <div class="popupmaindiv">
                        <span class="cursor-pointer"><DeleteIcon class="me-2 whiteFill" /></span>
                        <Plus draggable={false} onMouseDown={ e => e.stopPropagation()} ondragstart={(e) => { e.preventDefault(); e.stopPropagation() }} onClick={(e) => { e.preventDefault(); e.stopPropagation(); handlepopover(popid) }} size={20} className='react_icon_vsmall' />
                        <div id={popid} class="innerpopup_open" hidden={true}>
                            <div class="d-flex align-items-start flex-row">
                                <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                <Closee_iconn onMouseDown={ e => e.stopPropagation()} onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" />
                            </div>
                            <div class="d-flex">
                                <div class="mb-3 custom_searchh flex-fill">
                                    <input type="text" onMouseDown={ e => e.stopPropagation()} onChange={(e) => {
                                        //   
                                    if(instance.getAllAccounts()[0] !== undefined){ dispatch(fetchAllDataSource({data:e.target.value, instance:instance})) } }} class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Search " />
                                </div>
                            </div>
                            <ul>
                                {datasource && datasource.filter(x => x.httpverb.toLowerCase() === "get").map((data, index) => {
                                    return (
    
                                        <li onMouseDown={ e => e.stopPropagation()} className='font_14 font_regular' onClick={() => { addData(gd, data.url, popid, data.index, false) }}>
                                            <Datasource className='me-2' />{data.datasourcename}
                                        </li>
    
                                    )
    
                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='customdatagrid' style={{ width: '100%' }} onClick={handleButtonClick}>
                    <DataGrid autoHeight rows={rows} columns={columns}
                        getRowHeight={() => 'auto'}
                    />
                </div>
    
            </div>
            return griddiv
        
        }
        else{
           
            var grdid = item.griddetials.gridid;
            var grdid = item.griddetials.gridid
            var url = item.griddetials.gridurl
            var popid = item.griddetials.gridpopid
            var index = item.griddetials.gridindex
            var newelem = (<div id={grdid}  >
                <div class="d-flex justify-content-end">
                    <div class="popupmaindiv">
                        <span class="cursor-pointer"><DeleteIcon class="me-2 whiteFill" /></span>
                        <Plus onMouseDown={ e => e.stopPropagation()} draggable="false" onClick={(e) => { e.stopPropagation(); handlepopover(popid) }} size={20} className='react_icon_vsmall' />
                        <div id={popid} class="innerpopup_open" hidden={true}>
                            <div class="d-flex align-items-start flex-row">
                                <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                <Closee_iconn onMouseDown={ e => e.stopPropagation()} onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" />
                            </div>
                            <div class="d-flex">
                                <div class="mb-3 custom_searchh flex-fill">
                                    <input type="text" onChange={(e) => { 
                                        //  
                                        if(instance.getAllAccounts()[0] !== undefined){ dispatch(fetchAllDataSource({data:e.target.value, instance:instance})) } }} class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Search " />
                                </div>
                            </div>
                            <ul>
                                {datasource && datasource.filter(x => x.httpverb.toLowerCase() == "get").map((data, index) => {
                                    return (

                                        <li onMouseDown={ e => e.stopPropagation()} className='font_14 font_regular' onClick={() => { addData(grdid, data.url, popid, data.index) }}>
                                            <Datasource className='me-2' />{data.datasourcename}
                                        </li>

                                    )

                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='customdatagrid' style={{ width: '100%' }} >
                    <DataGrid
                        pageSize={2}
                        autoHeight rows={item.griddetials.rows} columns={item.griddetials.columns}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>);
           
           return newelem;

        }
        // else{
        //     return <p>hellooo</p>
        // }
        // else{
        //     ;
        //     var grdid = item.griddetials.gridid
        //     var url = item.griddetials.gridurl
        //     var popid = item.griddetials.gridpopid
        //     var index = item.griddetials.gridindex
        //     index === undefined ? index = "id" : index = index;

        //     const columns = [
        //         { field: "id", headerName: 'Index', flex: 1, maxWidth: 70 },
    
        //     ];
        //     //
        //     const res =await axios(url).then((res) => {
        //         
        //         var res1;
        //         let unique;
        //         var rows;
        //         if (res.data.data !== undefined) {
        //             //rows = res.data.data
        //             var xx = res.data.data.map((x) => {
        //                 return {
        //                     id: x[index],
        //                     ...x
    
        //                 }
        //             });
        //             rows = xx;
        //             
        //             res1 = res.data?.data?.flatMap(Object.keys);
        //             unique = [...new Set(res1)];
        //         } else {
        //             rows = res.data;
        //             var xx = res.data.map((x) => {
        //                 return {
        //                     id: x[index],
        //                     ...x
        //                 }
        //             });
        //             rows = xx;
        //             
        //             res1 = res.data.flatMap(Object.keys);
        //             unique = [...new Set(res1)];
        //         }
    
        //         
        //         unique.filter(x => x !== index).forEach(element => {
        //             let obj = { field: element, headerName: element }
        //             columns.push(obj)
        //         });
        //         
    
        //         //  setGetData((oldData) => [...oldData, mainRes])
    
        //         var newelem = (<div id={grdid}  >
        //             <div class="d-flex justify-content-end">
        //                 <div class="popupmaindiv">
        //                     <span class="cursor-pointer"><DeleteIcon class="me-2 whiteFill" /></span>
        //                     <Plus draggable="false" onClick={(e) => { e.stopPropagation(); handlepopover(popid) }} size={20} className='react_icon_vsmall' />
        //                     <div id={popid} class="innerpopup_open" hidden={true}>
        //                         <div class="d-flex align-items-start flex-row">
        //                             <p class="font_16 mb-3 font_medium">Select Data Source</p>
        //                             <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" />
        //                         </div>
        //                         <div class="d-flex">
        //                             <div class="mb-3 custom_searchh flex-fill">
        //                                 <input type="text" onChange={(e) => {  if(instance.getAllAccounts()[0] !== undefined){ dispatch(fetchAllDataSource({data:e.target.value, instance:instance})) } }} class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Search " />
        //                             </div>
        //                         </div>
        //                         <ul>
        //                             {datasource && datasource.filter(x => x.httpverb.toLowerCase() == "get").map((data, index) => {
        //                                 return (
    
        //                                     <li className='font_14 font_regular' onClick={() => { addData(grdid, data.url, popid, data.index) }}>
        //                                         <Datasource className='me-2' />{data.datasourcename}
        //                                     </li>
    
        //                                 )
    
        //                             })}
        //                         </ul>
        //                     </div>
        //                 </div>
        //             </div>
        //             <div className='customdatagrid' style={{ width: '100%' }} >
        //                 <DataGrid
        //                     pageSize={2}
        //                     autoHeight rows={rows} columns={columns}
        //                     getRowHeight={() => 'auto'}
        //                 />
        //             </div>
        //         </div>);
               
        //        return newelem;
    
    
    
        //     });
        // }
       
    }
    const handleClosePopup = (popid) => {
        
        //setCurrentGridID(grdid)
        document.querySelector("#" + popid).hidden = true;

    }
    const handlepopover = (popid) => {
        
        //setCurrentGridID(grdid)
        document.querySelector("#" + popid).hidden = false;
        setOpen(true)

    }
    //)
    const addData = (grdid, url, popid, index, isrerender) => {
        
       // 
        //var grdid = currentGridID;
        var selectedGrid = list1.filter(x => x.filename === selectedPage)[0]

        // [0], selectedPage, "inside adddata")
        // 
        // 
        index === undefined ? index = "id" : index = index;

        const columns = [
            { field: "id", headerName: 'Index', flex: 1, maxWidth: 70 },

        ];
        //
        const res = axios(url).then((res) => {
            //
            var res1;
            let unique;
            var rows;
            if (res.data.data !== undefined) {
                //rows = res.data.data
                var xx = res.data.data.map((x) => {
                    return {
                        id: x[index],
                        ...x

                    }
                });
                rows = xx;
                // 
                res1 = res.data?.data?.flatMap(Object.keys);
                unique = [...new Set(res1)];
            } else {
                rows = res.data;
                var xx = res.data.map((x) => {
                    return {
                        id: x[index],
                        ...x
                    }
                });
                rows = xx;
                //
                res1 = res.data.flatMap(Object.keys);
                unique = [...new Set(res1)];
            }

            //
            unique.filter(x => x !== index).forEach(element => {
                let obj = { field: element, headerName: element }
                columns.push(obj)
            });
           // 

            //  setGetData((oldData) => [...oldData, mainRes])

            var newelem = (<div id={grdid}  >
                <div class="d-flex justify-content-end">
                    <div class="popupmaindiv">
                        <span class="cursor-pointer"><DeleteIcon class="me-2 whiteFill" /></span>
                        <Plus draggable="false" onClick={(e) => { e.stopPropagation(); handlepopover(popid) }} size={20} className='react_icon_vsmall' />
                        <div id={popid} class="innerpopup_open" hidden={true}>
                            <div class="d-flex align-items-start flex-row">
                                <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" onMouseDown={ e => e.stopPropagation()} />
                            </div>
                            <div class="d-flex">
                                <div class="mb-3 custom_searchh flex-fill">
                                    <input type="text" onChange={(e) => {
                                        //  
                                          if(instance.getAllAccounts()[0] !== undefined){ dispatch(fetchAllDataSource({data:e.target.value, instance:instance})) } }} class="form-control form-control-sm" id="exampleFormControlInput1" placeholder="Search " />
                                </div>
                            </div>
                            <ul>
                                {datasource && datasource.filter(x => x.httpverb.toLowerCase() == "get").map((data, index) => {
                                    return (

                                        <li className='font_14 font_regular' onClick={() => { addData(grdid, data.url, popid, data.index) }}>
                                            <Datasource className='me-2' />{data.datasourcename}
                                        </li>

                                    )

                                })}
                            </ul>
                        </div>
                    </div>
                </div>
                <div className='customdatagrid' style={{ width: '100%' }} >
                    <DataGrid
                        pageSize={2}
                        autoHeight rows={rows} columns={columns}
                        getRowHeight={() => 'auto'}
                    />
                </div>
            </div>);
           // 
            let temp = JSON.parse(JSON.stringify(list1))

            temp.filter(x => x.filename === selectedPage)[0].griddetials = {
                gridid: grdid,
                gridurl: url,
                gridpopid: popid,
                gridindex: index,
                newgrid: newelem,
                rows:rows,
                columns:columns
            }
            //list1.filter(x => x.filename === selectedPage)[0].updated = newelem;
            //
            //list1 = temp;
            setlistControls(temp)
            //
            const root = ReactDOM.createRoot(document.getElementById(grdid));
            root.render(newelem);
            document.querySelector("#" + popid).hidden = true;





        });
    };


    const handleDropPopover = (key) => {
        
        //setCurrentGridID(grdid)
        document.querySelector("#dropdownId" + key).hidden = false;
        setDropOpen(true)

    }

    const handleCloseDropPopup=(key)=>{
        document.querySelector('#dropdownId'+key).hidden = true
    }
//Render Form for POST/PUT call
    function handleControls(formid, data, popid, index) {
        
        var nums = getId()
        var showpopid = "pop"+nums
        dropdownKeys = []
         document.querySelector("#FormPlusIcon").className="d-none"
        //

        //POST CALL STARTS HERE
        if(data.httpverb === "Post"){
            inputArray=[]
        const res = axios(data.url).then((res) => {
            //
            setRes = res.data
            mainResList = [{ setRes }]

            for (var key in mainResList[0]?.setRes[0]) {
                if (mainResList[0]?.setRes[0]?.hasOwnProperty(key)) {
                    //
                    dropdownKeys.push(key)
                    //
                }
            }
        }).then((res) => {
            //
            setFormDataRes(true) 
            newelem = (<div id={formid}>
            
                { dropdownKeys.filter(x => !x.includes("id")).map((key,index) => {          
                     temp = uuid();
                     var obj = {id:temp,key:key}
                    inputArray.push(obj)
                    //
                    return (
                        <>
                            <div class="mb-3 col-lg-12">
                                <label for="exampleFormControlInput1" style={{ color: "black" }} class="form-label">{key}</label>
                                <div class="d-flex align-items-center" id={key}>
                                    <input type='text' placeholder={key} id={temp} class="form-control flex-fill me-3" name={key}  ></input>
                                    <div class="ms-auto cursor-pointer popupmaindiv1"  > 
                                    
                                    <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); showDropdown(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon>
                                    <div id={"dropdownId" + key} class="innerpopup_open" hidden={true}>
                        <div class="d-flex align-items-start flex-row">
                            <p class="font_16 mb-3 font_medium">Select Data Source</p>
                            <Closee_iconn onClick={() => { handleCloseDropPopup(key) }} className="ms-auto cursor-pointer mt-2" onMouseDown={ e => e.stopPropagation()} />
                        </div>

                        <ul>
                            {insertControlsData.map((item) => {
                                return (
                                    <li className='font_14 font_regular' onClick={(e) => { renderInput(key,inputArray.filter(x=>x.key === key)[0].id ,e) }} onMouseDown={ e => e.stopPropagation()}>
                                        <Datasource className='me-2' />{item.name}
                                    </li>

                                )

                })}
                        </ul>

                    </div>           
                                     </div>
                                                            
                                </div>                  
                            </div>
                        </>
                    )
               
                 })}
                <div class="row mt-4">
                <div class="col-lg-12">
                    <button type='button' className="btn btn-primary btn_custom_primary" onClick={()=>{handleSubmitPost(data,temp)}} onMouseDown={ e => e.stopPropagation()}>Submit</button>
                    <button type='button' className="btn btn-secondary btn_custom_secondary" onClick={()=>{handleCancel()}} onMouseDown={ e => e.stopPropagation()}>Cancel</button>
                 </div>
                </div>
                
            
            </div>)
            const root = ReactDOM.createRoot(document.getElementById(formid));
            root.render(newelem);
        });
         }
         
    //PUT CALL STARTS HERE
         else{  
            inputArray=[]     
        const res = axios.get(data.url).then((res)=>{
            //                      
            Responsee.push(res.data)          
           // 
           
            for (var key in Responsee[0]) {
                if (Responsee[0]?.hasOwnProperty(key)) {
                   // 
                    dropdownKeys.push(key)
                    //
                }
            }
        }).then((res) => {
             newelem = (<div id={formid}>
                <div id="formdiv">
                {Object.entries(Responsee[0]).filter(x=>!x.includes("id")).map(([key,value]) => {          
                    var temp = uuid();
                    var obj = {id:temp,key:key,value:value}
                    //
                    inputArray.push(obj)
                   // 
                    return (
                        <>
                            <div class="mb-3 col-lg-12">
                                <label for="exampleFormControlInput1" style={{ color: "black" }} class="form-label">{key}</label>
                                <div class="d-flex align-items-center" id={key}>
                                    <input type='text' placeholder={key} id={temp} class="form-control flex-fill me-3" name={key}></input>
                                    <div class="ms-auto cursor-pointer popupmaindiv1"  > 
                                    <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDropPopover(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon>
                                    <div id={"dropdownId" + key} class="innerpopup_open" hidden={true}>
                                        <div class="d-flex align-items-start flex-row">
                                            <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                            <Closee_iconn onClick={() => { handleCloseDropPopup(key) }} className="ms-auto cursor-pointer mt-2" onMouseDown={e => e.stopPropagation()} />
                                        </div>

                                        <ul>
                                            {insertControlsData.map((item) => {
                                                return (
                                                    <li className='font_14 font_regular' onClick={(e) => { renderInput(key, temp, e) }} onMouseDown={e => e.stopPropagation()}>
                                                        <Datasource className='me-2' />{item.name}
                                                    </li>

                                                )

                                            })}
                                        </ul>

                                    </div>
                                     </div>
                                    
                                </div>                  
                                {/* <select class="form-select mt-3" id={"dropdownId" + key} onChange={() => { renderInput(key,temp) }} hidden={true} onMouseDown={ e => e.stopPropagation()}>
                                    <option>Change input field</option>
                                    {insertControlsData.map((item) => (
                                        <option>{item.name}</option>
                                    ))}
                                </select> */}
                
                
                            </div>
                        </>
                    )
               
                 })}
                <div class="row mt-4">
                <div class="col-lg-12">
                    <button type='button' className="btn btn-primary btn_custom_primary" onClick={()=>{handleSubmitPut(data)}} onMouseDown={ e => e.stopPropagation()} >Submit</button>
                    <button type='button' className="btn btn-secondary btn_custom_secondary" onClick={()=>{handleCancel()}} onMouseDown={ e => e.stopPropagation()}>Cancel</button>
                 </div>
                </div>
                </div> 
            </div>)
            const root = ReactDOM.createRoot(document.getElementById(formid));
            root.render(newelem)

        });
       
    }
    }

//Show dropdown of Input fields List on click of edit
    function showDropdown(key) {
        
        document.querySelector("#dropdownId" + key).hidden = false
    }

//Function to setValues in input fields for PUT call
//)
    if(document.querySelectorAll("#formdiv").length > 0 && checkFlag === false)
    {    
    setValue();
    }
    
    function setValue(){
        
        checkFlag = true
        inputArray.forEach(element => {
        document.getElementById(element.id).value = element.value
    });
    }

    //handle submit method for POST call
    function handleSubmitPost(data,temp){
        
        //
        inputArray?.map(element => {
            // 
            // )
            // .name )
            // .value )


        postDataList={
                    ...postDataList,
                    [document.getElementById(element.id).name ] : document.getElementById(element.id).value 
                }
            }) 

       // 
        axios.post(data.url,{
            body:postDataList
        }).then((res)=>{
            //
            if(res.status === 201){
                alert("Form submitted successfully")
                inputArray.forEach(element => {
                    document.getElementById(element.id).value = ""
                    document.querySelector("#showDropdown").className="d-none"
                    document.querySelector("#DeleteIcon").hidden = true
                    
                });

            }else{
                alert("Form not submitted")
            }
        })
    }

    //handle submit method for PUT call
    function handleSubmitPut(data){
        
        //
        inputArray?.map(element => {
        putDataList={
            ...putDataList,
            [document.getElementById(element.id).name] : document.getElementById(element.id).value 
        }
    }) 
    //

        const res = axios.put(data.url,{
                body : putDataList
            }).then((res)=>{
               // 
                if(res.status === 200){
                    alert("Form submitted successfully")
                    const root = ReactDOM.createRoot(document.getElementById("forDeleteId"));
                     root.render(RenderForm);
                }else{
                    alert("Form not submitted")
                }
            })      
    }

    //CANCEL button method
    function handleCancel(){
        
            
            const root = ReactDOM.createRoot(document.getElementById("forDeleteId"));
            root.render(RenderForm);

        
    }

    
    //change input type field
    function renderInput(key,temp,e) {
        
        // sI = document.querySelector('#dropdownId' + key).selectedIndex;
        // getID = document.querySelector('#dropdownId' + key).options[sI].value
        getID = e.target.innerText
        //
        const root = ReactDOM.createRoot(document.getElementById(key));
        //)

        if (getID == "Button") {

            var newelem = (<div id={key} class="mb-3 col-lg-12">
                <div class="d-flex align-items-center">
                <button
                    className="btn btn-primary"
                    id={temp}
                    placeholder={key}
                   name={key}
                >
                    Primary
                </button>
                <div class="ms-auto cursor-pointer"  > <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); showDropdown(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon> </div>
                </div>
            </div>
            );
            document.querySelector("#dropdownId" + key).hidden = true

        }
        else if (getID == "Text Input") {

            var newelem = (<div id={key} class="mb-3 col-lg-12">
                <div class="d-flex align-items-center">
                <input
                    id={temp}
                    type="text"
                    class="form-control flex-fill me-3" 
                   name={key}
                    placeholder={key} >
                </input>
                <div class="ms-auto cursor-pointer"  > <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); showDropdown(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon> </div>
                </div>
            </div>
            )
            document.querySelector("#dropdownId" + key).hidden = true
        }
        else if (getID == "Drop down") {
            
            var idnum = getId();
            var open = false
            popid = "pop" + idnum

            newelem = (<div id={key}class="w-100 ">
                
                <div class="d-flex ">
                <div class="popupmaindiv2 ms-auto">
                        <Plus size={20} onClick={() => { handlepopover(popid) }} onMouseDown={ e => e.stopPropagation()} className='react_icon_vsmall' />
                   
                    <div id={popid} class="innerpopup_open" hidden={true}>
                        <div class="d-flex align-items-start flex-row">
                            <p class="font_16 mb-3 font_medium">Select Data Source</p>
                            <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" onMouseDown={ e => e.stopPropagation()} />
                        </div>

                        <ul>
                            {datasource && datasource.filter(x => x.httpverb.toLowerCase() == "get").map((data, index) => {
                                return (

                                    <li className='font_14 font_regular' onClick={() => { addDropdownData(key, data.url, popid, data.index,temp) }} onMouseDown={ e => e.stopPropagation()}>
                                        <Datasource className='me-2' />{data.datasourcename}
                                    </li>

                                )

                            })}
                        </ul>

                    </div>
                </div>
                </div>

                <div class="d-flex align-items-center">
                <select class="form-select mt-3" id={temp} name={key} placeholder={key} onMouseDown={ e => e.stopPropagation()} >
                    <option selected>Dropdown Menu</option>
                    <option value="One" >One</option>
                    <option value="Two">Two</option>
                    <option value="Three">Three</option>
                </select>
                <div class="ms-auto cursor-pointer"  > <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); showDropdown(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon> </div>
                </div>
                

            </div>
            

            )
             document.querySelector("#dropdownId" + key).hidden = true
        }
        else if (getID == "Date Picker") {
            //
            
            var newelem = (<div id={key} class="mb-3 col-lg-12">
                <div class="d-flex align-items-center">
                <input
                    id={temp}
                    type="date"
                    class="form-control flex-fill me-3" 
                    name={key}
                    style={{ color: "black" }}
                    placeholder={key}
                />
                <div class="ms-auto cursor-pointer"  > <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); showDropdown(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon> </div>
                </div>
            </div>
            )
            document.querySelector("#dropdownId" + key).hidden = true
        }
        else if (getID == "Check Box") {
            var newelem = (<div id={key} class="mb-3 col-lg-12">
                <div class="d-flex align-items-center">
                    <input
                         class="form-check-input" 
                        type="checkbox"
                        id={temp}
                        placeholder={key}
                        name={key}
                        style={{ height: "1.5rem", width: "1.5rem" }}
                    >
                    </input>
                <div class="ms-auto cursor-pointer"  > <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); showDropdown(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon> </div>


                </div>
            </div>
            )
            document.querySelector("#dropdownId" + key).hidden = true
        }

        root.render(newelem)
           
    }


//Bind Dropdown data on click of Datasource
    const addDropdownData=(key,url,popid,index,temp)=>{
        
        dropdownKeys = []
        
        var selectedDropVal;
        //
        const res = axios(url).then((res)=>{
            
            setRes = res.data
            mainResList = [{setRes}]
            // 
    
            for (var keyss in mainResList[0]?.setRes[0]){
                if (mainResList[0]?.setRes[0]?.hasOwnProperty(keyss)) {
                   // 
                    dropdownKeys.push(keyss)
                    //
                }  
            }

            
   
            var newelem = (<div id={key} class="w-100">
                <div class="d-flex">
                <div class="popupmaindiv ms-auto">
            <DeleteIcon size={20} onClick={()=>{handleDelete(key,temp)}} onMouseDown={ e => e.stopPropagation()} id="DeleteIcon" hidden={false}></DeleteIcon>
            <div id={popid} class="innerpopup_open" hidden={true}>
            <div class="d-flex align-items-start flex-row">
                                    <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                    <Closee_iconn onClick={() => { handleClosePopup(popid) }} className="ms-auto cursor-pointer mt-2" onMouseDown={ e => e.stopPropagation()}/>
                                </div>
            <ul>
                                    {datasource && datasource.filter(x=>x.httpverb.toLowerCase()=="get").map((data, index) => {
                                        return (
    
                                            <li className='font_14 font_regular' onClick={() => {addDropdownData(key, data.url, popid, data.index) }} onMouseDown={ e => e.stopPropagation()}>
                                                <Datasource className='me-2' />{data.datasourcename}
                                            </li>
    
                                        )
    
                                    })}
                                </ul>
            </div>
            </div>
            </div>
            <div class="d-flex align-items-center">

             <select class="form-select mt-3" id={"dropVal"+temp}  onChange={()=>{showSecondDropdown(temp,key)}} onMouseDown={ e => e.stopPropagation()} >
             <option selected >Select any option</option>
             {dropdownKeys.map((item)=>(
             <option value={item}>{item}</option>
             ))}
         </select>   
         </div>
         <div class="d-flex align-items-center d-none" id="showDropdown">
            
              <select class="form-select mt-3" id={temp} name={key} onMouseDown={ e => e.stopPropagation()} >
        <option selected >Select any option</option>
         
         
         
         </select>
          </div>         
                    
         </div>
            )
            const root = ReactDOM.createRoot(document.getElementById(key))
            root.render(newelem)
    
        })
    
    }


    //Show 2nd DROPDOWN
    function showSecondDropdown(temp){
        
        
        let dropData = []
        //
     var   sI = document.getElementById("dropVal"+temp).selectedIndex;
     var   getID = document.getElementById("dropVal"+temp).options[sI].value
    // 
     setRes.map((key)=>{
        dropData.push({"selectedAttribute":key[getID],"id":key.id})
            //
            
     })
     document.querySelector("#showDropdown").className="d-flex align-items-center"         
     var select = document.getElementById(temp)
     //
     select.options.length = 0
    dropData.map((element,index) => {
          select.add(new Option(element.selectedAttribute,element.id))

    });

    }

    

    //DELETE the binded API in dropdown
    function handleDelete(key,temp){
        
        var newelem = (<div id={key}class="w-100">
            <div class="d-flex align-items-center">
            <select  class="form-select mt-3" onMouseDown={ e => e.stopPropagation()}>
                <option selected>Dropdown Menu</option>
                <option value="1">One</option>
                <option value="2">Two</option>
                <option value="3">Three</option>
            </select>
            <div class="ms-auto cursor-pointer popupmaindiv1"  >
                 <Edit_Icon onClick={(e) => { e.preventDefault(); e.stopPropagation(); handleDropPopover(key) }} onMouseDown={ e => e.stopPropagation()}></Edit_Icon>
                 <div id={"dropdownId" + key} class="innerpopup_open" hidden={true}>
                                        <div class="d-flex align-items-start flex-row">
                                            <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                            <Closee_iconn onClick={() => { handleCloseDropPopup(key) }} className="ms-auto cursor-pointer mt-2" onMouseDown={e => e.stopPropagation()} />
                                        </div>

                                        <ul>
                                            {insertControlsData.map((item) => {
                                                return (
                                                    <li className='font_14 font_regular' onClick={(e) => { renderInput(key, temp, e) }} onMouseDown={e => e.stopPropagation()}>
                                                        <Datasource className='me-2' />{item.name}
                                                    </li>

                                                )

                                            })}
                                        </ul>

                                    </div>
             </div>
            </div>
                
            </div>
        )  
        const root = ReactDOM.createRoot(document.getElementById(key))
        root.render(newelem) 
    }

   
    //fetch control type menu
    const maincontent = useSelector(
        (state) => state.commonReducer.controldata.controlTypeData.controldata);
    const [controldata, setcontroldata] = useState(maincontent);
    useEffect(() => { setcontroldata(maincontent); }, [dispatch, maincontent]);
    //

    //fetch control detail menu data
    const menucontent = useSelector(
        (state) => state.commonReducer.controldata.controlDetailData.controldetaildata);
    const [controldetaildata, setcontroldetaildata] = useState(menucontent);
    useEffect(() => { setcontroldetaildata(menucontent); }, [dispatch, menucontent]);
    //


    //Download zip file method

    if (getDetailsData !== undefined) {
        accesstoken = getDetailsData[0]?.accessToken
        //

        projectName = getDetailsData[0]?.projectname
        //
    }
    function handleDownload() {
        //
        
        axios.post('https://app-rapidx-webdesigner-api-dev.azurewebsites.net/downloadzip', {
            "reponame": projectName,
            "token": accesstoken
        }).then((res) => {
            //
            downlink = res.data
            const anchor = document.createElement('a');
            anchor.href = res.data;
            anchor.setAttribute('download', res.data);
            document.body.appendChild(anchor);
            anchor.click();
            //
        })

    }
    //
    const codecommit = () => {
        //e.preventDefault()
        socket = io.connect(url);
        ;
        setShowPopup(true); 
        socket.emit('join', {projid:data.replace("?_id=","")});
        //dispatch( postCodeGeneration(data.replace("?_id=","")) )       

    }
    //
    // useEffect(() => {
    //     if(socket !== undefined){
    //         socket.on('receive_message', (data) => {
    
    //             
    //             //status.push(data)
                
    //           });
    //     }

        
    // })
    // 


    
    return (
        <>
            <ToastProvider placement="top-center" >

        <div class="flex-fill contentmain_container">

                
            {/* <Modal show={show} className="dropexpand_modal" fullscreen={true} onHide={() => setShow(false)}>
                <Modal.Header closeButton className='p-0 border-0'>
                    <Modal.Title></Modal.Title>
                </Modal.Header>
                <Modal.Body>
                    <div class="file_container dnd">
                        <div className={`Droparea_components ${style==="768px"?"ipad": style==="400px"?"mobile":""}`} style={{ width: style }} ref={dropRef} >
                            <ReactGridLayout cols={1} >
                                {children && children.map((dropItem, index) => {
                                    return (

                                        <div key={index} data-grid={{ x: 0, y: 0, w: 1, h: dropAreaData.filter(x => x.name.toLowerCase() === dropItem.name.toLowerCase())[0]?.fixedHeight !== undefined ? dropAreaData.filter(x => x.name === dropItem.name)[0]?.fixedHeight / 100 : 1, i: getId() }}>
                                            {renderDroppedItem(dropItem)}

                                        </div>

                                    )

                                })}
                            </ReactGridLayout>
                        </div>
                        <Offcanvas className="propertiescanvas" show={show3} onHide={handleClose3} backdrop={false} placement="end">
                            <Properties />
                        </Offcanvas>
                    </div>
                </Modal.Body>
            </Modal> */}
            {/* screen 1 */}
            <div class="screen_one">
                {/* <div class="file_name_container">
                    <ul class="topinnerul">
                        <li className='active'>
                            <div class="file_name_list align-items-center d-flex">
                                <div><New_screen_icon className='react_icon_small_fill' /></div>
                                <div className='font_10 font_light pt-0 pe-4 ps-2 file_name'>Screen 1</div>
                            </div>
                        </li>
                        <li>
                            <div class="file_name_list align-items-center d-flex">
                                <div><New_screen_icon className='react_icon_small_fill' /></div>
                                <div className='font_10 font_light pt-0 pe-4 file_name ps-2'>Screen 2</div>
                            </div>
                        </li>
                    </ul>
                    <div class="right_side_iconsMenus whitespace_nowrap">
                        <ul>
                            <li class={`${active === "Desk" ? "active" : ""}`} onClick={() => { setStyle("100%"); setActive("Desk") }}><Desktop_icon class="react_icon_small" /></li>
                            <li class={`${active === "Tab" ? "active" : ""}`} onClick={() => { setStyle("768px"); setActive("Tab") }}><Tablet_icon class="react_icon_small" /></li>
                            <li class={`${active === "Mobile" ? "active" : ""}`} onClick={() => { setStyle("400px"); setActive("Mobile") }}><Mobile_icon class="react_icon_small" /></li>
                            <li><Transfer_icon className='ms-4 react_icon_small_fill' onClick={()=>{handleDownload()}} /></li>
                            <li><Publish_icon class="react_icon_small_fill" /></li>
                        </ul>
                    </div>
                </div> */}


                <div class="file_container dnd">
                {/*  */}
                    <div id="dropdiv" name="dropdiv" className={`Droparea_components ${style === "768px" ? "ipad" : style === "350px" ? "mobile" : ""}`} style={{ width: style }}  >
                        <ReactGridLayout cols={1} >
                            {list1 && list1?.filter(x => x?.filename === selectedPage || x?.filename !== undefined)?.map((dropItem, index) => {
                                // {  }

                                return (
                                    <div id="dropareaitems" key={index} data-grid={{ x: 0, y: 0, w: 1, h: dropAreaData?.filter(x => x?.name?.toLowerCase() === dropItem?.name?.toLowerCase())[0]?.fixedHeight !== undefined ? dropAreaData?.filter(x => x?.name === dropItem?.name)[0]?.fixedHeight / 100 : 1, i: getId() }}>
                                        {
                                            renderDroppedItem(dropItem)
                                            
                                        }
{/* {formComponent} */}
                                   
                                        {/* {dropItem.elem} */}

                                    </div>


                                )

                            })}
                        </ReactGridLayout>
                    </div>
                    <Offcanvas className="propertiescanvas" show={show3} onHide={handleClose3} backdrop={false} placement="end">
                        <Properties />
                    </Offcanvas>
                </div>
                {/* <div class="file_name_container bottom">
                    <DropdownButton
                        variant="outline-secondary"
                        title="Page 1"
                        id="input-group-dropdown-4"
                        align="end"
                    >
                        <Dropdown.Item href="#">Page one</Dropdown.Item>
                        <Dropdown.Item href="#">Page 2</Dropdown.Item>
                        <Dropdown.Item href="#">Page 3</Dropdown.Item>
                    </DropdownButton>
                </div> */}
            </div>
            {/* screen 1 ends*/}
            <div class="right_float_container">
                <p hidden={!sliderClose} className='set_icon mb-0' onClick={(e) => { setsliderClose(!sliderClose) }}><Setting_white /></p>
                <p hidden={sliderClose} className='set_icon mb-0' onClick={(e)=>{setsliderClose(!sliderClose)}}><CloseWhite/></p>

                <SlideDown className={'my-dropdown-slidedown'} closed={sliderClose}>
                    <ul class="m-0 p-0 float_tab_icons">
                        <li class={`${active === "Desk" ? "active" : ""}`} onClick={() => { setStyle("100%"); setActive("Desk") }}><Desktop_icon class="react_icon_small" /></li>
                        <li class={`${active === "Tab" ? "active" : ""}`} onClick={() => { setStyle("768px"); setActive("Tab") }}><Tablet_icon class="react_icon_small" /></li>
                        <li class={`${active === "Mobile" ? "active" : ""}`} onClick={() => { setStyle("350px"); setActive("Mobile") }}><Mobile_icon class="react_icon_small" /></li>
                        <hr className='hr_new'></hr>
                        <li><Check_in onClick={(e) => { codecommit();  }} className='react_icon_small' /></li>
                        <li><Transfer_icon className='react_icon_small_fill' onClick={() => { handleDownload() }} /></li>
                        <li><Publish_icon class="react_icon_small_fill" /></li>
                    </ul>
                </SlideDown>

            </div>
            <CommitPopup
                popupshow={popupshow}
                setShowPopup={setShowPopup}
                socket={socket}
                pages={pages}
                status = {status}
            />
        </div>
        </ToastProvider>

        </>
    )
}

export default DropAreaScreen;

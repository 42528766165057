import { useState, useEffect } from "react";
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import Button from 'react-bootstrap/Button';
import Form from 'react-bootstrap/Form';
import { ReactComponent as Home_logo } from './images/home_logo.svg';
import { FaRegUserCircle } from 'react-icons/fa';
import { ReactComponent as Bell_icon } from './images/bell_icon.svg';
import { ReactComponent as User_icon } from './images/user_icon_static.svg'
import NavDropdown from 'react-bootstrap/NavDropdown';
import { NavLink, Link, Outlet } from 'react-router-dom';
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
import { InteractionRequiredAuthError } from '@azure/msal-common';
import { loginRequest } from "../authConfig";
import Axios from 'axios';
import { useDispatch, useSelector } from "react-redux";
import { Person } from "react-bootstrap-icons";
import { ReactComponent as Settings_icon } from './images/settings_icon.svg';
import Modal from 'react-bootstrap/Modal';
import Myapps from '../../src/App/images/myapps.png';
import Dotnett_icon from './images/dotnett_icon.png';
import Bootstrap_icon from './images/bootstrap_icon.png';
import Layout_icon from './images/layout_icon.png';
import {getProjectDetails} from './main/store/userProject.actions'
import { fetchNfrTypeData } from './main/store/nfr.actions';

import { useLocation, useNavigate, Route } from "react-router-dom";
import { CloseButton } from "react-bootstrap";
import { useHistory } from 'react-router-dom';

export const theme = [
  { color: '#D50000', backgroundColor: '#FFEBEE' },
  { color: '#C51162', backgroundColor: '#FCE4EC' },
  { color: '#AA00FF', backgroundColor: '#F3E5F5' },
  { color: '#6200EA', backgroundColor: '#EDE7F6' },
  { color: '#304FFE', backgroundColor: '#E8EAF6' },
  { color: '#2962FF', backgroundColor: '#E3F2FD' },
  { color: '#0091EA', backgroundColor: '#E1F5FE' },
  { color: '#00B8D4', backgroundColor: '#E0F7FA' },
  { color: '#00BFA5', backgroundColor: '#E0F2F1' },
  { color: '#FFAB00', backgroundColor: '#FFF8E1' },
]

function ProjectDetails({
  
  id,
}) {
  const dispatch = useDispatch();
  const [isActive, setActive] = useState("");
  const [imageUrl, setImageUrl] = useState(null)
  
  // Azure B2C
  //const [type,setType]=useState();
  var type="";
  const { instance, accounts, inProgress } = useMsal();
  const name = accounts[0] && accounts[0].name;
  const useremail = accounts[0] && accounts[0].username;
  const [show, setShow] = useState(false);
  const location = useLocation();
  var id=location.search.replace("?_id=","")
  
 
  const handleClose = ()=> {
   setShow(false)
   
  }
  const handleShow = () => setShow(true);
  
   
  useEffect(() => {
    handleShow() 
         
}, [])
useEffect(() => {  
  if(instance.getAllAccounts()[0] !== undefined){
  dispatch(getProjectDetails({data:id,instance:instance}))
  }
}, [id,instance.getAllAccounts()[0] !== undefined])




//fetching project types
//=>state.commonReducer))
const ProjectData = useSelector((state) => state.commonReducer.projectdetails.ProjectDetails.projectdetails);
const [prodtypedata, setContentData] = useState(ProjectData);
useEffect(() => { setContentData(ProjectData); }, [dispatch, ProjectData]);
//

//setType(prodtypedata[0]?.ProjectType)
type=prodtypedata?.[0]?.projecttypeid


// //
// useEffect(() => { 
//   if(instance.getAllAccounts()[0] !== undefined)
//   {
//   dispatch(fetchNfrTypeData({data:type,instance:instance}))
//   }
// }, [type,instance.getAllAccounts()[0] !== undefined])
// //NFR
// const nfrcontent = useSelector((state) => state.commonReducer.nfr.nfrTypeData.nfrdata);
// const [nfrdata, setnfrData] = useState(nfrcontent);
// useEffect(() => { setnfrData(nfrcontent); }, [dispatch, nfrcontent]);
// //



  return (
    <Navbar fixed="top" bg="dark" variant="dark" expand="lg">
      {/* <Container fluid>
        <Navbar.Brand href="#home"><Home_logo /></Navbar.Brand>
        <Navbar.Toggle aria-controls="basic-navbar-nav" />
        <Navbar.Collapse className='ps-5' id="basic-navbar-nav">
          
          <Form className="d-flex align-items-center">
            <Settings_icon onClick={handleShow} className='react_icon_size me-3' />
          </Form>
        </Navbar.Collapse>
      </Container> */}
      <Modal
        show={show}
       onHide={handleClose}
        backdrop="static"
        keyboard={false}
        size="lg"
        aria-labelledby="contained-modal-title-vcenter"
        centered
      >

        <Modal.Header  >
          
          <Modal.Title className='font_18 font_regular' id="contained-modal-title-vcenter">
          Project Information
          </Modal.Title>
          {location.pathname==="/allApps/projectDetails"? <Link to="/allApps"><p><CloseButton/></p> </Link>:
          <Link to="/"><p><CloseButton/></p> </Link>}
        </Modal.Header>
        <Modal.Body>
          <div class="row mb-2">
            <div class="col-12 mb-3">
              <p class="font_14 font_light mb-2">Project Name</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Myapps} /></div>
                <div>{prodtypedata[0]?.projectname}</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-lg-9 mb-3">
              <p class="font_14 font_light mb-2">Description</p>
              <div class="d-flex flex-row align-items-center font_14">
              {prodtypedata[0]?.description}
              </div>
            </div>
          </div>
          <div class="row">
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Project Type</p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prodtypedata[0]?.ProjectTypeicon} /></div>
                <div class="font_14 font_regular">{prodtypedata[0]?.ProjectType}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Theme </p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prodtypedata[0]?.Themeicon} /></div>
                <div class="font_14 font_regular">{prodtypedata[0]?.Theme}</div>
              </div>
            </div>
            <div class="col-lg-4 mb-3">
              <p class="font_14 font_light mb-2">Layout </p>
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={prodtypedata[0]?.Layouticon} /></div>
                <div class="ps-2 font_14 font_regular">{prodtypedata[0]?.Layout}</div>
              </div>
            </div>
          </div>
          <div class="row mb-3">
            <div class="col-12">
              <p class="font_14 font_light mb-2">Packaged Capabilities</p>
            </div>
            {prodtypedata?.map((nfrdata, index) => (
                  
                  nfrdata?.PackageCategory?.map((data)=>(
            <div class="col-lg-4">
           
              <div class="d-flex flex-row align-items-center">
             
                <div><img class="img-fluid me-2" src={data?.icon} /></div>
                
                      <div class="ps-2 font_14 font_regular">{data?.title}</div>
                    
              </div>
             
            </div>
            ))
                
                
            ))}
            {/* <div class="col-lg-4">
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Layout_icon} /></div>
                <div class="ps-2 font_14 font_regular">Notifications</div>
              </div>
            </div>
            <div class="col-lg-4">
              <div class="d-flex flex-row align-items-center">
                <div><img class="img-fluid me-2" src={Layout_icon} /></div>
                <div class="ps-2 font_14 font_regular">Error Logging</div>
              </div>
            </div> */}
          </div>
        </Modal.Body>
      </Modal>
    </Navbar>
  );
}

export default ProjectDetails;
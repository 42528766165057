import { ReactComponent as Three_dots_vertical } from '../../images/three_dots_vertical.svg';
import { Handle, Position } from 'reactflow';
import { useState } from 'react';
import {  Link } from 'react-router-dom';

function RedirectNode(props) {
 // 
  
  return (
    <div className="text-updater-node">
      <Handle type="target" position={Position.Top} />
            <div className="card bg-secondary text-white" style={{borderRadius: "inherit"}}>
                <div className="card-header">{props.data.label}<label style={{paddingLeft: "15rem"}} onClick={props.data.onDelete}><Three_dots_vertical class="react_icon_small_fill"/></label></div>
                <div className="card-body">
                   <h6>{props.data.message}</h6>
                   <Link to={`/botStudio/${props.data.id}`}>View topic</Link>
                  {/* <a href="#" disabled>View topic</a> */}
                </div> 
            </div>
      <Handle type="source" position={Position.Bottom} id="b" />
    </div>
  );
}

export default RedirectNode;


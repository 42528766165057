import { Handle, Position } from 'reactflow';
import { useState, useEffect } from 'react';
import { useSelector, useDispatch } from "react-redux";
import { ReactComponent as Datasource } from '../../images/d_source.svg';
import { ReactComponent as Closee_iconn } from '../../images/closee_iconn.svg';
import { ReactComponent as Right_arr } from '../../images/right_arr.svg';
import {  fetchAllDataSource } from '../../main/store/dropArea.actions';
import { getProjectDetails } from "../../main/store/userProject.actions";
import { useLocation } from "react-router-dom";
import { MsalProvider, useMsal, useIsAuthenticated } from "@azure/msal-react";
var Getlist = [];
function NewCustomNode(props) {
    const dispatch = useDispatch();
    const location = useLocation();
    const urlData = location.search;
    let urlDataItems = urlData.split("=");
    let id = urlDataItems[1];
    const [options, setOptions] = useState('');
    const [showActionsFlag, showActions] = useState(false);
    const [showRedirectFlag, showRedirect] = useState(false);
    const [searchDataSourceValue, setDataSource] = useState("");
    const [filteredDataSource, setfilteredDataSource] = useState([]);
    const {instance, accounts, inProgress} = useMsal();

    
    useEffect(() => {
        if(instance.getAllAccounts()[0] !== undefined){
        dispatch(fetchAllDataSource({data:null, instance:instance}));
        dispatch(getProjectDetails({data:id,instance:instance}))
        }
    },[dispatch, id, instance.getAllAccounts()[0] !== undefined])

    // const datasourceRes = useSelector((state) => state.commonReducer.droparea.datasource.datasource);
    // const [datasource, setDatasource] = useState(datasourceRes);
    const datasource = useSelector((state) => state.commonReducer?.droparea?.datasource?.datasource);
   

    useEffect(() => {
   
    
    datasource?.map((data)=>{
    
     //
    
     if(data.httpobject?.length === 1) {
    
     //
    
         Getlist.push(data)
    
    }
    
    // 
    
    })
   },[datasource])
 
//
    
    const projectData = useSelector((state) => state.commonReducer.projectdetails.ProjectDetails.projectdetails);
    //const [ dialoguesData, setDialoguesData ] = useState(projectData[0]?.dialogues) 
    const [ dialoguesData, setDialoguesData ] = useState( (projectData !== undefined && projectData?.[0] !== undefined &&
        projectData?.[0]?.dialogues !== undefined && projectData?.[0]?.dialogues[0] !== undefined ) ? projectData?.[0]?.dialogues : [] ) 


    useEffect(() => {
        if(projectData !== undefined && projectData?.[0] !== undefined && projectData?.[0]?.dialogues !== undefined && projectData[0]?.dialogues[0] !== undefined )
        setDialoguesData(projectData?.[0]?.dialogues);
    },[projectData])

    const handleClickQuestion = (item) => {
        props.data.selectedItem = item;
        setOptions("Ask Questions");
        if(item === "CallAnAction"){
            showRedirect(false);
            if(showActionsFlag){
                showActions(false);
            }
            if(!showActionsFlag){
                showActions(true);
            }
        }

        if(item === "Redirect"){
            showActions(false);
            if(showRedirectFlag){
                showRedirect(false);
            }
            if(!showRedirectFlag){
                showRedirect(true);
            }
        }
    }

    const handleCloseRedirect = () => {
        showRedirect(false);
    }

    const handleCloseCallAction = () => {
        showActions(false);
    }

    const handleRedirect = (item) => {
        //
        props.data.item = item;
        props.data.actionLink = window.location.origin+"/botStudio/"+item.id;
    }

    //To search datasource on call action click
    useEffect(() => {
        setfilteredDataSource(
            datasource.filter((item) =>
                item.datasourcename.toLowerCase().includes(searchDataSourceValue.toLowerCase())
            )
        );
    }, [searchDataSourceValue, datasource]);

    return (
        <div className="text-updater-node">
            <Handle type="target" position={Position.Top} />   
                <div className="card bg-secondary text-start text-white">
                    <div className="card-body">
                        <ul>
                            <ol>
                                <div onClick={() => handleClickQuestion('AskQuestions')}>
                                Ask a quetsion
                                </div>
                                
                            </ol>
                            <hr></hr>
                            {/* <ol>
                            <div onClick={handleClickQuestion}>
                                Add a Condition
                            </div>
                            </ol> 
                            <hr></hr> */}
                            <ol className='position-relative chatbot_div'>
                                <div class="d-flex align-items-center" onClick={() => handleClickQuestion('CallAnAction')}>
                                Call an action
                                <Right_arr className='ms-auto' />
                                </div>
                            
                                {showActionsFlag && 
                                <div class="innerpopup_open">
                                    <div class="d-flex align-items-start flex-row">
                                        <p class="font_16 mb-3 font_medium">Select Data Source</p>
                                        <Closee_iconn className="ms-auto cursor-pointer mt-2" onClick={handleCloseCallAction}/>
                                    </div>
                                    <div class="d-flex">
                                        <div class="mb-3 custom_searchh flex-fill">
                                            <input 
                                                type="text" 
                                                class="form-control form-control-sm" 
                                                id="exampleFormControlInput1" 
                                                placeholder="Search " 
                                                onChange={(e) => setDataSource(e.target.value)} 
                                                value={searchDataSourceValue} 
                                            />
                                        </div>
                                    </div>
                                    <ul>
                                        {Getlist.filter(x => x.httpobject[0]?.httpverb?.toLowerCase() === "get" ).map((data, index) => {
                                            return(
                                                <>
                                                    {
                                                         
                                                        <li className='font_14 font_regular' key={index}>
                                                            <Datasource className='me-2' />{data.datasourcename}
                                                        </li>  
                                                    }
                                                </> 
                                            )
                                        })}
                                    </ul>
                                </div>
                                }
                            </ol>
                            <hr></hr>
                            <ol>
                                <div onClick={() => handleClickQuestion('ShowMessage')}>
                                    Show a message
                                </div>
                            </ol>
                            <hr></hr>
                            <ol class="position-relative chatbot_div">
                                
                                <div class="d-flex align-items-center" onClick={() => handleClickQuestion('Redirect')}>
                                Redirect to another topic
                                <Right_arr className='ms-auto'/>
                                </div>
                                {showRedirectFlag && 
                                <div class="innerpopup_open">
                                    <div class="d-flex align-items-start flex-row">
                                        <p class="font_16 mb-3 font_medium">Redirect to another topic</p>
                                        <Closee_iconn className="ms-auto cursor-pointer mt-2" onClick={handleCloseRedirect} />
                                    </div>
                                    <ul>
                                    {dialoguesData && dialoguesData.map((item) => {
                                            return(
                                                <li className='font_14 font_regular' onClick={() =>handleRedirect(item)}>
                                                    <Datasource className='me-2' />{item.dialoguename}
                                                </li>  
                                            )
                                        })}
                                    </ul>
                                </div>
                                }
                            </ol>
                            <hr></hr>
                            <ol>
                                <div onClick={() => handleClickQuestion('EndConversation')}>
                                    End the conversation
                                </div>
                            </ol>
                        </ul>
                    </div> 
                </div>
            <Handle type="source" position={Position.Bottom} id="b" />
        </div>
    );
}
export default NewCustomNode;
 
